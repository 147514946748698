import { Component, OnInit } from '@angular/core';

import { ClassifierService } from './../../services/classifier.service';
import { CertificateRequest, CertificateUploadResponse } from '../../models/CertificateRequest';
import { AppService } from '../../services/app.service';
import { CertificateRequestService } from '../../services/certificate-request.service';
import { Utils } from '../../core/Utils';
import { CertificateRequestEmailPreviewComponent } from './email-preview.component';
import { CertificateRequestSendEmailsComponent } from './send-emails.component';
import { ITableColumn } from '../../shared/table/table.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-certificate-requests',
    templateUrl: './certificate-requests.component.html',
    styleUrls: ['./certificate-requests.component.scss']
})
export class CertificateRequestsComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: CertificateRequestService,
        private classifier: ClassifierService,
        private modal: NgbModal
    ) {
    }

    items: CertificateRequest[] = [];
    totalItems: number;
    searchValue: string = '';
    filterAvailableFrom: any;
    filterAvailableTo: any;
    certificateArea: string;
    isFiltering: boolean;
    userCertificateAreas: { Code: string, Text: string }[] = [];

    rights = {
        view: false,
        edit: false
    };

    readonly columns: ITableColumn[] = [
        { property: 'CertId', label: 'certRequests_lblCertId', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'CertId', label: 'certRequests_lblCertIdEmailTill', sorts: true, cssClass: 'hidden-md-up' },
        { property: 'Email', label: 'certRequests_lblEmail', cssClass: 'hidden-sm-down' },
        { property: 'AvailableTill', label: 'certRequests_lblAvailableTill', sorts: true, type: 'date', cssClass: 'hidden-sm-down' },
        { property: 'FirstName', label: 'certRequests_lblFirstName', sorts: true, cssClass: 'hidden-lg-down' },
        { property: 'LastName', label: 'certRequests_lblLastName', sorts: true, cssClass: 'hidden-lg-down' },
        { property: 'EventCode', label: 'certRequests_lblEventCode', sorts: true, cssClass: 'hidden-lg-down' },
        { property: 'Status', label: 'certRequests_lblStatus', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'EmailStatus', label: 'certRequests_lblEmailStatus', sorts: true, cssClass: 'hidden-md-down' },
        { width: '1px' }
    ];

    private expanded: any[] = [];

    ngOnInit() {
        this.rights.view = this.app.currentUser.hasRight('CERTIFICATE_REQUESTS.VIEW');
        this.rights.edit = this.app.currentUser.hasRight('CERTIFICATE_REQUESTS.EDIT');

        this.app.addLoading(this.classifier.getUDR(this.app.currentLanguage)).subscribe(data => {
            let userCertAreas = data.filter(r => r.Code.startsWith("UDR.CERT"));
            this.userCertificateAreas = this.userCertificateAreas.concat(userCertAreas);
        });

        this.load();
    }

    search() {
        this.isFiltering = true;
        this.load();
    }

    getUploadTemplate() {
        let href = this.service.getUploadTemplateUrl();
        window.open(href,'_blank');
    }

    upload(file: File, event: any) {
        this.app.addLoading(this.service.upload(file, this.certificateArea)).subscribe(res => {
            this.app.notify(this.app.translate('certRequests_uploadSuccess'));
            this.load();
        }, (err: HttpErrorResponse) => {
            const rawError = err.error;

            if (typeof rawError == 'string') {
                this.app.showError(rawError);
            } else {
                const details: CertificateUploadResponse = rawError;
                const errResponse = [`${this.app.translate('certRequests_uploadErrorTitle')}`];

                for (const rowNum in details) {
                    errResponse.push(`<br>${this.app.translate('certRequests_uploadErrorRow')} #${rowNum}: `);
                    details[rowNum].forEach(col => {
                        const colErr = col.split("_");
                        errResponse.push(this.app.translate(`certRequests_uploadError_${colErr[0]}`)
                            + " <strong>" + this.app.translate(`certRequests_lbl${colErr[1]}`) + "</strong>")
                    });
                }

                this.app.showError(errResponse.join("<br>"));
            }
        });

    }

    getExtraValues(item: CertificateRequest): any[] {
        let data = JSON.parse(item.ExtraValues || '{}');
        return Object.keys(data).map(t => {
            return {
                key: t,
                value: data[t]
            };
        });
    }

    previewEmail() {
        if(this.certificateArea == null || this.certificateArea == '') {
            this.app.showError(this.app.translate('certRequests_areaNotSelected'))
        }else {
            const ref = this.modal.open(CertificateRequestEmailPreviewComponent, {
                size: 'lg'
            });
            ref.componentInstance.certificateArea = this.certificateArea;
        }
    }

    fileSelect(fileInput: any) {
        if(this.certificateArea == null || this.certificateArea == '') {
            this.app.showError(this.app.translate('certRequests_areaNotSelected'))
        }else {
            fileInput.click();
        }
    }

    sendEmails() {
        if(this.certificateArea == null || this.certificateArea == '') {
            this.app.showError(this.app.translate('certRequests_areaNotSelected'))
        }else {
            const ref = this.modal.open(CertificateRequestSendEmailsComponent, {
                size: 'md'
            });
            ref.componentInstance.certificateArea = this.certificateArea;
        }
    }

    getDownloadUrl(item: CertificateRequest): string {
        return this.service.getDownloadUrl(item.Id, true);
    }

    toggle(row: any) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: any): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    delete(item: CertificateRequest) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.load();
            });
        });
    }

    public load() {
        let options: { area?: string, search: string, availableFrom: string, availableTo: string } = {
            search: this.searchValue || '',
            availableFrom: Utils.getInputDateString(this.filterAvailableFrom),
            availableTo: Utils.getInputDateString(this.filterAvailableTo)
        };

        if (this.certificateArea){
            options.area = this.certificateArea || '';
        }

        this.app.addLoading(this.service.get(options)).subscribe(data => {
            this.items = data.Items;
            this.totalItems = data.Total;
            this.isFiltering = false;
        });
    }
}
