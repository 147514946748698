<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'manipulation_editTitle' | translate}}</h4>
</div>

<ng-container *ngIf="fields">
    <div class="modal-body">
        <form [formGroup]="fields">
            <div class="form-field required" [hidden]="fields.value.otherManipulation">
                <label class="form-label">{{'manipulation_lblSpecialityManipulation' | translate}}</label>
                <app-select formControlName="manipulation"
                            [required]="!fields.value.otherManipulation"
                            [options]="data.specialityManipulations"
                            [displayFn]="manipulationDisplayFn"></app-select>
            </div>

            <div class="form-field required" [hidden]="!fields.value.otherManipulation">
                <label class="form-label">{{'manipulation_lblManipulationName' | translate}}</label>
                <input class="form-control" type="text" formControlName="otherManipulationName" [required]="fields.value.otherManipulation" />
            </div>

            <div class="form-field required" [hidden]="!fields.value.otherManipulation">
                <label class="form-label">{{'manipulation_lblLevel' | translate}}</label>
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <app-select formControlName="otherManipulationSkillLevel"
                                    [required]="fields.value.otherManipulation"
                                    [options]="data.skillLevels"
                                    [displayFn]="skillLevelDisplayFn">
                        </app-select>
                    </div>
                    <span class="field-info-btn ms-2" (click)="showLevelHelp = !showLevelHelp">
                        <i class="fi fi-rs-info"></i>
                    </span>
                </div>
            </div>

            <app-alert level="info" *ngIf="fields.value.otherManipulation && showLevelHelp && levelHelpText">
                <div [innerHTML]="levelHelpText | safeHtml"></div>
            </app-alert>

            <div class="form-field" [hidden]="!fields.value.otherManipulation">
                <label class="form-label">{{'manipulation_lblDescription' | translate}}</label>
                <textarea class="form-control" rows="3" formControlName="otherDescription" maxlength="4000"></textarea>
            </div>

            <div class="form-field">
                <app-checkbox formControlName="otherManipulation" (stateChange)="onOtherManipulationChange()">
                    {{'manipulation_lblOther' | translate}}
                </app-checkbox>
            </div>

            <div class="form-field required">
                <label class="form-label">{{'manipulation_lblCount' | translate}}</label>
                <input class="form-control" type="number" min="1" formControlName="count" />
            </div>

            <div class="form-field">
                <label class="form-label">{{'manipulation_lblCompetence' | translate}}</label>
                <app-select formControlName="competence"
                            [options]="competenceOptions"
                            [displayProperty]="'Value'"></app-select>
            </div>

            <div class="form-field required" [hidden]="fields.value.otherWorkplace">
                <label class="form-label">{{'manipulation_lblWorkplace' | translate}}</label>
                <app-autocomplete formControlName="workplace"
                                  limit="100"
                                  [required]="!fields.value.otherWorkplace"
                                  [options]="workplaceOptions"
                                  [displayProperty]="'Name'"
                                  (search)="filterWorkplaces($event)">
                </app-autocomplete>
                <div class="feedback">
                    {{'manipulation_workplaceFilterAlert' | translate}}
                </div>
            </div>

            <div class="form-field" [hidden]="!fields.value.otherWorkplace">
                <label class="form-label">{{'manipulation_lblWorkplace' | translate}}</label>
                <input class="form-control" type="text" name="otherWorkplaceName" formControlName="otherWorkplaceName" [required]="fields.value.otherWorkplace" />
            </div>

            <div class="form-field">
                <app-checkbox formControlName="otherWorkplace" (stateChange)="onOtherWorkplaceChange()">
                    {{'manipulation_lblOtherWorkplace' | translate}}
                </app-checkbox>
            </div>

            <div class="form-field">
                <label class="form-label">{{'manipulation_lblMedicalPerson' | translate}}</label>
                <input class="form-control" type="text" name="medicalPerson" formControlName="medicalPerson" />
            </div>

            <div class="form-field">
                <label class="form-label">{{'manipulation_lblComment' | translate}}</label>
                <textarea class="form-control" name="comment" formControlName="comment" maxlength="4000" rows="3"></textarea>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-secondary" type="button" (click)="cancel()">
            {{(data.isFinal ?  'dialog_close' : 'dialog_cancel') | translate}}
        </button>

        <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="!fields.valid" *ngIf="!data.isFinal">
            {{'dialog_ok' | translate}}
        </button>
    </div>
</ng-container>
