import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-breadcrumbs',
    template: `
    <nav class="breadcrumbs breadcrumbs-{{items.length}}">
        <a [routerLink]="['/'] | localize" class="breadcrumb breadcrumb-home">
            <i class="fi fi-rs-home"></i>
        </a>
        <ng-container *ngFor="let n of items; let i = index">
            <i class="fi fi-rs-angle-small-right breadcrumb-sep"></i>
            <a [routerLink]="[n.url] | localize" class="breadcrumb" [title]="n.text | translate" *ngIf="n.url && i < items.length - 1">{{n.text | translate}}</a>
            <span class="breadcrumb" *ngIf="!n.url || i == items.length - 1" [title]="n.text | translate">{{n.text | translate}}</span>
        </ng-container>
    </nav>
    `
})
export class BreadcrumbsComponent {
    @Input() items: {
        url: string,
        text: string
    }[] = [];
}
