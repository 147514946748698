export enum TimeTableView {
    Daily = 'Daily',
    Agenda = 'Agenda'
}

export class TimeTableProgram {
    Key: string;
    StudentId: string;
    Name: string;
    ViewType: string;
    Info: string;
}

export class TimeTableSemester {
    PeriodId: string;
    Name: string;
    FullName: string;
    IsCurrent: boolean;
}

export class TimeTableInfo {
    Date: string;
    Day: string;
    DayNumber: number;
    Time: string;
    SubjectCode: string;
    SubjectCodeLink: string;
    SubjectName: string;
    EventType: string;
    Location: string;
    LocationLink: string;
    Teacher: string;
    Comment: string;
    MoodleLink: string;
    IsSent: boolean;
    IsPublished?: boolean;
    EmployeeComment?: string;
}
