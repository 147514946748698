import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { IGrade } from '../../models/AddGrades';

import { AppService } from '../../services/app.service';
import { GradeService } from '../../services/grade.service';

@Component({
    selector: 'app-delete-grade',
    templateUrl: './delete-grade.component.html'
})
export class DeleteGradeComponent {
    constructor(
        public app: AppService,
        private service: GradeService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() grade: IGrade;

    reason: string;
    submitted: boolean;
    isSaving: boolean;

    @ViewChild('form', { static: true }) private form: NgForm;

    delete() {
        if (this.form.invalid) {
            this.submitted = true;
            return;
        }

        const success = () => {
            this.activeModal.close();
        };

        this.isSaving = true;

        this.app.addLoading(this.service.delete(this.grade.RowId, this.reason), 500)
            .pipe(finalize(() => this.isSaving = false)).subscribe(success);
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
