<form #filterForm="ngForm">
    <div class="row">
        <div class="col form-field">
            <input class="form-control"
                   [placeholder]="'hospitalDashboard_seminars_search' | translate"
                   [(ngModel)]="filters.search"
                   name="filterSearch"
                   (input)="filter()">
        </div>

        <div class="col form-field">
            <app-select [options]="filters.speciality.options"
                        [placeholder]="'hospitalDashboard_seminars_speciality' | translate"
                        [displayProperty]="'label'"
                        [valueProperty]="'value'"
                        [(ngModel)]="filters.speciality.value"
                        name="filterSpeciality"
                        (selectionChange)="filter()">
            </app-select>
        </div>

        <div class="col form-field">
            <app-select [options]="filters.year.options"
                        [placeholder]="'hospitalDashboard_seminars_studyYear' | translate"
                        [displayProperty]="'label'"
                        [valueProperty]="'value'"
                        [(ngModel)]="filters.year.value"
                        name="filterYear"
                        (selectionChange)="filter()">
            </app-select>
        </div>
    </div>
</form>

<app-table [rows]="rows" [columns]="columns">
    <ng-template #rowTemplate let-row>
        <tr>
            <td>{{row.Theme}}</td>
            <td>
                {{row.Date ? (row.Date | appDate) : ('hospitalDashboard_seminars_noDate' | translate)}}
                <ng-container *ngIf="row.Time"> {{row.Time}}</ng-container>
            </td>
            <td>{{row.Trainer}}</td>
            <td>{{row.Place}}</td>
            <td>{{row.Speciality}}</td>
            <td>{{row.StudyYear}}.</td>
            <td>
                <button *ngIf="canViewAttendance" type="button" class="btn btn-icon btn-outline" (click)="openAttendance(row)"
                        [ngbTooltip]="'hospitalDashboard_seminars_openAttendance' | translate"
                        [disabled]="!row.Date">
                    <i class="fi fi-rs-list-check"></i>
                </button>
            </td>
        </tr>
    </ng-template>
</app-table>
