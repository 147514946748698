import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';

import { PersonMainDataItem, StudentEventItem, SkillDetailItem } from '../models/Said_events';

/**
 * SAID events service
 */
@Injectable({ providedIn: "root" })
export class Said_eventsService {
    constructor(public http: HttpClient) { }

	public get apiUrl(): string { return `${ENV.apiUrl}/said_events` }
	

	getPersonMainData(email?: string): Observable<PersonMainDataItem[]> {
        return this.http.get<PersonMainDataItem[]>(`${this.apiUrl}/personMainData`, {
			params: {
				email: email
			}
		});
	}

	getStudentEvents(studentId: string): Observable<StudentEventItem[]> {
        return this.http.get<StudentEventItem[]>(`${this.apiUrl}/events`, {
			params: {
				//lang: lang,
				studentId: studentId
				//planPer: planPer,
				//PietStatuss: pietStatuss*/
			}
        });
	}

    addEventApplication(data: {
        studentId: string,
		eventId: number,
		lang: string
    }): Observable<any> {
		return this.http.post(`${this.apiUrl}/addEventsApplication`, data);
	}

	deleteEventApplication(data: {
		studentId: string,
		eventId: number,
		lang: string
	}): Observable<any> {
		return this.http.post(`${this.apiUrl}/removeEventsApplication/`, data);
    }

	//prasmes kartiņa
	getSkillDetails(skillId: string): Observable<SkillDetailItem[]> {
        return this.http.get<SkillDetailItem[]>(`${this.apiUrl}/skillCard/${skillId}`);
    }
}
