import { Component, OnInit } from '@angular/core';
import { IFinalPaperReviewListItem, IFinalPaperYear } from '../../models/FinalPaperReview';
import { AppService } from '../../services/app.service';
import { FinalPaperReviewService } from '../../services/final-paper-review.service';
import { IPersonSearchResultItem } from 'src/app/models/Person';
import { PersonType } from 'src/app/shared/person-picker/person-picker.component';
import { store } from './store';

const cache: { years?: IFinalPaperYear[] } = {};

@Component({
    selector: 'app-final-paper-review-home',
    templateUrl: './final-paper-review-home.component.html'
})
export class FinalPaperReviewHomeComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: FinalPaperReviewService
    ) { }

    selectedYear: IFinalPaperYear;
    finalPapers: IFinalPaperReviewListItem[] = [];
    showProtocols: boolean;

    personPickerOpened: boolean;
    canPickEmployee: boolean;
    person: IPersonSearchResultItem;
    readonly personPickerType: PersonType = "employee";

    readonly options: {
        year: IFinalPaperYear[]
    } = {
            year: []
        };

    ngOnInit() {
        const user = this.app.currentUser;

        this.canPickEmployee = user?.hasRight('FINAL_PAPER_REVIEW.SET_EMPLOYEE_VIEW') || user?.hasRight('FINAL_PAPER_REVIEW.SET_EMPLOYEE_ADMIN');
        this.showProtocols = this.app.currentUser.rights.includes('FINAL_PAPER_PROTOCOL.VIEW');
        
        this.loadYears();
    }

    onYearChange() {
        this.reload();
    }

    setPerson(person: IPersonSearchResultItem) {
        this.personPickerOpened = false;
        this.person = person;
        store.setPerson(person);
        this.reload();
    }

    togglePersonPicker() {
        this.personPickerOpened = !this.personPickerOpened;
    }

    private reload() {
        this.app.addLoading(this.service.get(this.selectedYear.Id, store.getPerson()?.Upn)).subscribe(data => {
            this.finalPapers = data.Items;
        });
    }

    private loadYears() {
        const go = (data: IFinalPaperYear[]) => {
            this.options.year = data;
            this.selectedYear = data.find(t => t.IsActual);

            if (this.selectedYear) {
                this.onYearChange();
            }
        };

        if (cache.years) {
            go(cache.years);
        } else {
            this.app.addLoading(this.service.getYears()).subscribe(data => {
                cache.years = data;
                go(data);
            });
        }
    }
}
