<div class="wrapper-md">
    <app-breadcrumbs [items]="[
					 { url: '/applications?type=' + type, text: 'application_title' }
					 ]" *ngIf="!isSkillsRef"></app-breadcrumbs>

    <app-breadcrumbs [items]="[
					 { url: '/skills' + (canPickStudent ? '?id=' + studentUpn : ''), text: 'skills_title' }
					 ]" *ngIf="isSkillsRef"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{title}}</h1>
        <aside *ngIf="canPickStudent && !isDenied && !isSkillsRef && !studentPickerOpened">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'application_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <div class="content-desc" *ngIf="description && !studentPickerOpened" [innerHTML]="description | safeHtml"></div>

    <app-alert level="danger" *ngIf="isDenied && !studentPickerOpened">{{'eServiceNotAvailable' | translate}}</app-alert>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="pickStudent($event)" [additionalFilter]="pickerAdditionalFilter"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened && ready && studentData">
        <div class="form-field required" *ngIf="studentData?.length >= 1 && !finished">
            <label class="form-label">{{'application_lblStudyProgramme' | translate}}</label>
            <app-select [(ngModel)]="selectedProgramme"
                        name="selectedProgramme"
                        [required]="true"
                        [disabled]="studentData.length <= 1"
                        [options]="studentData"
                        [displayProperty]="'StudyProgramme'">
            </app-select>
        </div>

        <ng-container *ngIf="type">
            <app-application #appForm
                             *ngIf="!isCustomApplication && kind === 'Application'"
                             [type]="type"
                             [studentEmail]="currentStudentEmail"
                             [studentData]="studentData"
                             [programme]="selectedProgramme"
                             (done)="finished = true"
                             (title)="onTitleChange($event)"
                             (description)="onDescriptionChange($event)"></app-application>

            <app-custom-application #appForm
                                    *ngIf="isCustomApplication"
                                    [type]="type"
                                    [studentEmail]="currentStudentEmail"
                                    [studentData]="studentData"
                                    [programme]="selectedProgramme"
                                    (done)="finished = true"
                                    (title)="onTitleChange($event)"
                                    (description)="onDescriptionChange($event)"></app-custom-application>

            <app-reference #appForm
                           *ngIf="kind === 'Reference'"
                           [type]="type"
                           [studentEmail]="currentStudentEmail"
                           [studentData]="studentData"
                           [programme]="selectedProgramme"
                           (done)="finished = true"
                           (title)="onTitleChange($event)"
                           (description)="onDescriptionChange($event)"></app-reference>
        </ng-container>
    </ng-container>
</div>
