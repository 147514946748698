import { Component, OnInit, ViewChild } from '@angular/core';
import { RecaptchaLoaderService, RecaptchaComponent } from 'ng-recaptcha';

import { PasswordService } from '../../../services/password.service';
import { AppService } from '../../../services/app.service';

import { CodeResponse } from '../../../models/Password';

@Component({
    selector: 'app-password-student-request',
    templateUrl: './request.component.html',
    styleUrls: ['../password.component.scss']
})
export class StudentPasswordRequestComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: PasswordService
    ) { }

    @ViewChild('recaptchaRef', { static: false }) recaptchaRef: RecaptchaComponent;

    lastNameValue: string;
    personIdOrCodeValue: string;
    emailPhoneValue: string;
    requestResponse: CodeResponse = null;
    reCaptchaService: RecaptchaLoaderService;
    reCaptchaResponse: string;
    reCaptchaSiteKey: string;
    isRsuEmail: boolean;

    ngOnInit() {
        this.reCaptchaSiteKey = this.app.env.reCaptchaSiteKey;
    }

    resolved(captchaResponse: string) {
        this.reCaptchaResponse = captchaResponse;
    }

    submit() {
        if ((this.emailPhoneValue || '').indexOf('@')) {
            if (this.emailPhoneValue.toLowerCase().indexOf('@rsu.') != -1) {
                this.isRsuEmail = true;
                this.showError('password_emailNotPrivate');
                return;
            }

            if (!this.validateEmail(this.emailPhoneValue)) {
                this.showError('password_emailerror');
                return;
            }
        } else {
            if (!this.validatePhone(this.emailPhoneValue)) {
                this.showError('password_phoneerror');
                return;
            }
        }

        this.app.addLoading(this.service.getSecurityCode(
            this.lastNameValue,
            this.personIdOrCodeValue,
            this.emailPhoneValue,
            this.reCaptchaResponse,
            this.app.currentLanguage
        )).subscribe((response) => {
            if (response && response.TokenCreated) {
                this.requestResponse = response;
            }
            else
                this.showError('password_requesterror');
        }, err => {
            this.showError(err);
        });
    }

    showError(message: string | any) {
        this.app.showError(typeof message == 'string' ? this.app.translate(message) : message, this.app.translate('password_notresettitle'));
        this.reCaptchaResponse = '';
        this.recaptchaRef.reset();
    }

    validateEmail(email: string) {
        if (email.length > 250) return false;

        const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;

        return re.test(email.toLowerCase());
    }

    validatePhone(phone) {
        if (phone.length !== 8)
            return false;

        let re = /^\d+$/;
        return re.test(String(phone).toLowerCase());
    }

    getMaskedEmail(email: string) {
        if (email.indexOf('@') > -1) {
            let split = email.split('@');
            let name = split[0];
            if (name.length > 3)
                return name.substring(0, 2) + '***@' + split[1];
            else
                return name + '***@' + split[1];
        }
        return email;
    }

    refreshRequest(e: Event) {
        e.preventDefault();

        this.requestResponse = null;
        this.reCaptchaResponse = '';
        this.recaptchaRef.reset();
        this.lastNameValue = '';
        this.personIdOrCodeValue = '';
        this.emailPhoneValue = '';
    }
}
