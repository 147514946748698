<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/isic', text: 'isic_applyForISICCard' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'isic_applyForISICCard'"></h1>
        <aside *ngIf="canPickStudent && !showStudentPicker">
            <button type="button" class="btn btn-outline" (click)="showStudentPicker = !showStudentPicker">
                <i class="fi fi-rs-user"></i>
                {{'isic_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="showStudentPicker" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="!showStudentPicker && isReady">
        <section class="block">
            <app-alert level="warning" *ngIf="isUnavailable">
                <div [innerHTML]="unavailableText | safeHtml"></div>
            </app-alert>

            <ng-container *ngIf="!isUnavailable">
                <div *ngIf="isicError==''" class="form-field">
                    <app-checkbox name="isicAccepted" [(ngModel)]="isicAccepted">
                        <span>{{'isic_acceptDataSend' | translate}} </span>
                        <a href="#" (click)="openRules($event)">{{'isic_isicLinkText' | translate}}</a>
                    </app-checkbox>
                </div>

                <div *ngFor="let g of studentDisplayRows" class="row">
                    <div *ngFor="let n of g" class="data-value col-sm">
                        <label>{{n.label}}</label>
                        <div>{{n.value || '-'}}</div>
                    </div>
                </div>

                <div class="profile-picture mb-3" *ngIf="showPicture">
                    <img [src]="showPicture" />
                </div>

                <app-alert level="danger" *ngIf="isicError!=''">{{isicError}}</app-alert>

                <div class="block-actions">
                    <button class="btn btn-primary" type="button" (click)="sendData()" [disabled]="!isicAccepted || !personInfoValid">
                        {{'isic_sendData' | translate}}
                    </button>
                </div>
            </ng-container>
        </section>

        <section class="block" *ngIf="!isUnavailable">
            <h5 [translate]="'isic_isicRequestHistory'"></h5>

            <app-table [rows]="history" [columns]="historyColumns" [pageSize]="pageSize" [(page)]="historyPage">
                <ng-template #rowTemplate let-row let-i="index">
                    <tr>
                        <td class="hidden-md-down">{{i + 1}}</td>
                        <td>{{row.ApplicationDate | appDate}}</td>
                        <td class="hidden-sm-down">
                            <table class="history-info">
                                <tbody>
                                    <tr *ngFor="let info of row.ApplicationInfo">
                                        <td>{{info.StatusDate | appDate}}</td>
                                        <td>{{info.StatusInfo | translate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="hidden-md-up">
                            <div class="table-actions">
                                <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'isic_showDetails' | translate">
                                    <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isExpanded(row)" class="table-row-details hidden-md-up">
                        <td colspan="9">
                            <table class="history-info">
                                <tbody>
                                    <tr *ngFor="let info of row.ApplicationInfo">
                                        <td>{{info.StatusDate | appDate}}</td>
                                        <td>{{info.StatusInfo | translate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-template>
            </app-table>
        </section>
    </ng-container>
</div>
