import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { Utils } from '../core/Utils';

import {
    Student, Application, ApplicationType, MyApplication, ApplicationKind, ApplicationResult, AbsenceInfo,
    ExtraCourseInfo, ComplaintCourse, PersonPhoto, IApplicationCustomForm
} from '../models/Application';

import { AuthService } from './auth.service';

/**
 * Application service
 */
@Injectable({ providedIn: "root" })
export class ApplicationService {
    constructor(public http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/applications` }

    /**
     * Get student data for every program.
     * @param kind Application kind to get studenta data for.
     * @param email Target student email. If not specified or insufficient permissions, current user email will be used.
     */
    getStudent(kind: ApplicationKind, email?: string): Observable<Student[]> {
        const url = `${this.apiUrl}/student?kind=${kind}${email ? `&email=${email}` : ''}`;
        return this.http.get<Student[]>(url);
    }

    /**
     * Get student absence information for every available program.
     * @param studentId
     */
    getAbsenceInfo(studentId: string): Observable<AbsenceInfo> {
        const url = `${this.apiUrl}/absenceInfo/${studentId}`;
        return this.http.get<AbsenceInfo>(url);
    }

    /**
     * Get student contracts.
     * @param studentId
     */
    getContracts(studentId: string): Observable<string[]> {
        const url = `${this.apiUrl}/contracts/${studentId}`;
        return this.http.get<string[]>(url);
    }

    /**
     * Get student complaint courses.
     * @param studentId
     */
    getComplaintCourses(studentId: string): Observable<ComplaintCourse[]> {
        const url = `${this.apiUrl}/complaintCourses/${studentId}`;
        return this.http.get<ComplaintCourse[]>(url);
    }

    /**
     * Get student extra course information.
     * @param studentId
     */
    getExtraCourseInfo(studentId: string): Observable<ExtraCourseInfo[]> {
        const url = `${this.apiUrl}/extraCourseInfo/${studentId}`;
        return this.http.get<ExtraCourseInfo[]>(url);
    }

    /**
     * Get student academic years.
     * @param studentId
     */
    getAcademicYears(studentId: string): Observable<string[]> {
        const url = `${this.apiUrl}/academicYears/${studentId}`;
        return this.http.get<string[]>(url);
    }

    /**
     * Get student person information.
     * @param studentId
     */
    getPersonInfo(email?: string): Observable<any> {
        const url = `${this.apiUrl}/personInfo${email ? `?email=${email}` : ''}`;
        return this.http.get(url);
    }

    /**
     * Get student person information.
     * @param studentId
     */
    getPersonPhoto(studentId: string): Observable<PersonPhoto> {
        const url = `${this.apiUrl}/photo/${studentId}`;
        return this.http.get<PersonPhoto>(url);
    }

    /**
     * Create an application.
     * @param application
     * @param files
     * @param studentEmail If not specified or insufficient permissions, current user email will be used.
     */
    create(application: Application, files: { data: File, name?: string }[] = [], studentEmail?: string): Observable<ApplicationResult> {
        const url = `${this.apiUrl}`;
        const form = new FormData();

        application.DateFrom = Utils.getInputDateString(application.DateFrom) as any;
        application.DateTo = Utils.getInputDateString(application.DateTo) as any;

        form.append('application', encodeURIComponent(JSON.stringify(application)));
        studentEmail && form.append('student', studentEmail);

        files.forEach(t => {
            if (t.data) {
                form.append('file[]', t.data, t.name || t.data.name);
            }
        });

        return this.http.post<ApplicationResult>(url, form);
    }

    /**
     * Get current user applications.
     */
    getMy(): Observable<MyApplication[]> {
        const url = `${this.apiUrl}/my`;
        return this.http.get<MyApplication[]>(url);
    }

    /**
     * Get application kind by type.
     * @param applicationType
     */
    getKindByType(applicationType: ApplicationType): ApplicationKind {
        switch (applicationType) {
            case ApplicationType.AcademicLeave:
            case ApplicationType.AcademicLeaveDoctor:
            case ApplicationType.Exmatriculation:
            case ApplicationType.RepeatCourse:
            case ApplicationType.CourseAlignment:
            case ApplicationType.Discount:
            case ApplicationType.Repayment:
            case ApplicationType.JustifiedAbsence:
            case ApplicationType.Complaint:
            case ApplicationType.AppealComplaint:
            case ApplicationType.ScholarshipComplaint:
            case ApplicationType.FreeForm:
            case ApplicationType.PersonData:
            case ApplicationType.PersonPhoto:
            case ApplicationType.PaymentTermExtension:
                return ApplicationKind.Application;

            case ApplicationType.Grades:
            case ApplicationType.Status:
            case ApplicationType.Applicant:
            case ApplicationType.MatriculationStatus:
            case ApplicationType.Skills:
            case ApplicationType.AcademicReference:
                return ApplicationKind.Reference;

            default:
                return undefined;
        }
    }

    /**
     * Get application file download URL.
     * @param id Application ID
     */
    getFileUrl(id: number): string {
        return `${this.apiUrl}/${id}/file?SessionKey=${this.auth.sessionKey}`;
    }

    /**
     * Get custom configuration form data.
     * @param code
     * @param studentId
     * @param programId
     */
    getCustomFormByCode(code: string, studentId: string, programId: string): Observable<IApplicationCustomForm> {
        const url = `${this.apiUrl}/forms/latest/${code}/${studentId}/${programId}`;
        return this.http.get<IApplicationCustomForm>(url);
    }

    /**
     * Get custom form choices.
     * @param code
     * @param studentId
     * @param programId
     * @param dataSource
     * @param filter
     */
    getCustomFormChoices(code: string, studentId: string, programId: string, dataSource: string, filter?: string): Observable<any[]> {
        const url = `${this.apiUrl}/forms/latest/${code}/${studentId}/${programId}/${dataSource}`;
        const params: any = filter ? { filter } : {};

        return this.http.get<any[]>(url, { params });
    }

    getAvailableTypes(): Observable<string[]> {
        const url = `${this.apiUrl}/availableTypes`;
        return this.http.get<string[]>(url);
    }
}
