import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { IStudyQualityEvaluationEditModel, IStudyQualityEvaluationResult, StudyQualityEvaluationSemester, StudyQualityEvaluationStatus } from '../../../models/StudyQualityEvaluation';
import { Classifier } from '../../../models/Classifier';


@Component({
    selector: 'app-plan-summary-quality-evaluation-form',
    templateUrl: './plan-summary-quality-evaluation-form.component.html',
    styleUrls: ['../plan-summary.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PlanSummaryQualityEvaluationFormComponent implements OnInit {
    /**
     * Original semester evaluation data
     */ 
    @Input() data: IStudyQualityEvaluationResult = <IStudyQualityEvaluationResult>{};
    /**
     * Semester evaluation edit values
     */ 
    @Input() model: IStudyQualityEvaluationEditModel = <IStudyQualityEvaluationEditModel>{};
    @Input() options: {
        result: Classifier[],
        task: Classifier[],
        achievement: Classifier[],
        decision: Classifier[]
    } = { result: [], task: [], achievement: [], decision: [] };
    @Input() isEdit: boolean = false;
    @Input() studyYear: number;
    /**
     * Previous semester evaulation data
     */ 
    @Input() previous?: IStudyQualityEvaluationResult;

    showPreviousData: boolean;

    get isSecondSemester(): boolean {
        return this.data?.Semester == StudyQualityEvaluationSemester.Second;
    }

    get unachievedId(): string {
        return this.options.achievement.find(t => t.Code == 'Unachieved')?.Id;
    }

    get moveToNextYearWithConditionId(): string {
        return this.options.decision.find(t => t.Code == 'MoveToNextYearWithCondition')?.Id;
    }

    get doNotMoveToNextYearId(): string {
        return this.options.decision.find(t => t.Code == 'DoNotMoveToNextYear')?.Id;
    }

    ngOnInit() {
        if (!this.model) {
            this.model = <IStudyQualityEvaluationEditModel>{};
        }
    }

    showResidencyDecision() {
        return this.data?.Status == StudyQualityEvaluationStatus.Residency
            || this.data?.ResidencyAccepted || this.data?.ResidencyComment || this.data?.ResidencyCondition;
    }
}
