<div class="date {{id}} datepicker fc-icons-end">
    <input class="form-control"
           [disabled]="disabled"
           [id]="id"
           [name]="id"
           (change)="onChange($event)"
           (dateSelect)="onSelect($event)"
           ngbDatepicker
           #picker="ngbDatepicker"
           #input
           [dayTemplate]="dayTemplate"
           [minDate]="ngbMinDate"
           [maxDate]="ngbMaxDate"
           [placeholder]="placeholder"
           [class.is-invalid]="(required || input.value) && !value">
    <button type="button" class="fc-icon-end" (click)="picker.toggle()">
        <i class="fi fi-rs-calendar"></i>
    </button>
</div>

<ng-template #dayTemplate
             let-date
             let-disabled="disabled"
             let-selected="selected"
             let-focused="focused">
    <div class="btn-light"
         [class.active]="focused"
         [class.bg-primary]="selected"
         [class.marked]="isMarked(date)">
        {{date.day}}
    </div>
</ng-template>
