<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/portfolio', text: 'portfolio_title' },
                     { url: '/portfolio/add', text: 'portfolio_addTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'portfolio_addTitle' | translate}}</h1>
    </header>

    <app-alert *ngIf="status == 'error'" level="danger">
        <div>{{message}}</div>
        <div>
            <a [routerLink]="['/portfolio/add'] | localize">{{'portfolio_btnRestart'}}</a>
        </div>
    </app-alert>

    <div *ngIf="status == 'success' && report">
        <app-portfolio-data [data]="report" [sessionId]="sessionId"></app-portfolio-data>

        <app-checkbox [(ngModel)]="isConfirmed" name="isConfirmed">{{'portfolio_confirmSubmit' | translate}}</app-checkbox>

        <div class="form-actions">
                <button class="btn btn-outline" type="button" (click)="cancel()">
                    {{'portfolio_btnCancel' | translate}}
                </button>

                <button class="btn btn-primary" type="button" (click)="submit()" [disabled]="!isConfirmed">
                    {{'portfolio_btnSubmit' | translate}}
                </button>
        </div>
    </div>

    <ng-container *ngIf="!status">
        <app-alert level="info">{{'portfolio_addInfo' | translate}}</app-alert>

        <div class="col-sm-6 form-field required">
            <label class="form-label">{{'portfolio_lblCountry' | translate}}</label>
            <app-select [(ngModel)]="country"
                        [options]="options.country"
                        [required]="true"
                        (selectionChange)="onCountryChange($event)"
                        name="country">
            </app-select>
        </div>

        <div class="col-sm-6 form-field required">
            <label class="form-label">{{'portfolio_lblInstitution' | translate}}</label>
            <app-select [(ngModel)]="issuer"
                        [options]="options.issuer"
                        [required]="true"
                        [disabled]="!country"
                        [displayProperty]="'Name'"
                        name="issuer">
            </app-select>
        </div>

        <div class="form-actions">
            <button class="btn btn-primary" type="button" (click)="request()" [disabled]="!issuer">
                {{'portfolio_btnRequest' | translate}}
            </button>
        </div>
    </ng-container>

    <form #requestForm style="display: none" method="post" [action]="issuer?.Url">
        <input type="hidden" name="sessionId" [value]="sessionId" />
        <input type="hidden" name="returnUrl" [value]="returnUrl" />
    </form>
</div>
