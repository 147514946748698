<ng-container *ngIf="isLoaded">
    <app-alert level="danger" *ngIf="isError">{{'application_error' | translate}}</app-alert>
    <app-alert level="success" *ngIf="isSuccess">{{'application_success' | translate}}</app-alert>
    <app-alert level="danger" *ngIf="student.IsValid === false">{{'application_studentDataNotValid' | translate}}</app-alert>

    <ng-container *ngIf="!isError && !isSuccess">
        <form class="form" #form="ngForm">
            <div class="row" *ngFor="let row of fieldRows">
                <div class="col-sm-{{f.Size}}" *ngFor="let f of row">
                    <app-application-custom-field [name]="f.Name"
                                                  [type]="f.Type"
                                                  [label]="fields[f.Name].title"
                                                  [(value)]="fields[f.Name].value"
                                                  [required]="f.Required"
                                                  [disabled]="!f.Editable"
                                                  [choices]="fields[f.Name].choices"
                                                  [choicesFn]="fields[f.Name].choicesFn"></app-application-custom-field>
                </div>
            </div>


            <div *ngFor="let a of fileFieldRows; let i = index">
                <div class="form-field" [class.required]="a.config.Required" *ngFor="let file of a.files; let j = index">
                    <label class="form-label">{{getFileFieldName(a)}}</label>
                    <app-file [(ngModel)]="file.file"
                              name="file_{{i}}-{{j}}"
                              [accept]="getFileExtensions(a)"
                              [maxSize]="a.config.MaxSize"
                              [required]="a.config.Required"
                              (fileChange)="onFileChange($event, i, j)"
                              #fileInput="ngModel"></app-file>

                    <div class="invalid-feedback" *ngIf="a.config.Required && !file.file?.size && showFileRequired">
                        {{'application_fileRequired' | translate}}
                    </div>

                    <div class="invalid-feedback" *ngIf="fileInput.errors?.maxSize">{{fileInput.errors.maxSize}}</div>
                </div>

                <div class="mb-3">
                    <button class="btn btn-outline" type="button" (click)="addAttachment(a)" [disabled]="a.files.length >= a.config.MaxFileCount"
                            *ngIf="a.config.MaxFileCount != 1">
                        <i class="fi fi-rs-plus"></i>
                        {{'application_btnAddFile' | translate}}
                    </button>
                </div>
            </div>

            <app-alert level="info" *ngIf="textAfter">
                <div [innerHTML]="textAfter | safeHtml"></div>
            </app-alert>

            <app-application-student [student]="student" [isSkillsApp]="item.Type == 'Skills'"></app-application-student>

            <div>
                <app-checkbox [(ngModel)]="agreed" name="agreed">{{'application_lblAgree' | translate}}</app-checkbox>
            </div>

            <div class="form-actions">
                <button class="btn btn-outline" type="button" (click)="cancel()">{{'application_btnCancel' | translate}}</button>
                <button class="btn btn-primary" type="button" (click)="submit()">{{'application_btnSubmit' | translate}}</button>
            </div>
        </form>
    </ng-container>
</ng-container>
