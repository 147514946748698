import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { AppService } from '../../services/app.service';
import { CertificateRequestService } from '../../services/certificate-request.service';

@Component({
    templateUrl: './email-preview.component.html',
    styleUrls: ['./certificate-requests.component.scss']
})
export class CertificateRequestEmailPreviewComponent {
    constructor(
        private app: AppService,
        private service: CertificateRequestService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() certificateArea: string;

    eventCode: string;
    email: string;
    body: string = '';
    error: string;
    isFiltering: boolean;

    load() {
        this.isFiltering = true;
        this.app.addLoading(this.service.getEmailPreview(this.eventCode, this.email, this.certificateArea))
            .pipe(finalize(() => this.isFiltering = false))
            .subscribe(result => {
            this.body = result;
            this.error = undefined;
        }, err => {
            this.body = undefined;
            this.error = this.app.getHttpResponseError(err);
        });
    }

    close() {
        this.activeModal.close();
    }
}
