import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';

import { Message } from '../../models/Message';
import { ITableColumn } from '../../shared/table/table.component';
import { MessageService } from '../../services/message.service';

@Component({
	selector: 'app-message-list',
	templateUrl: './message-list.component.html',
	styleUrls: ['./messages.component.scss']
})
export class MessageListComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: MessageService
    ) { }

	groupSearchValue: string;
	codeSearchValue: string;
	titleSearchValue: string;
	textLvSearchValue: string;
	textEnSearchValue: string;

    page = 1;
    isFiltering: boolean;
    items: Message[] = [];

    readonly columns: ITableColumn[] = [
        { property: 'Code', label: 'messages_colCode', sorts: true },
        { property: 'Group', label: 'messages_colgroup', sorts: true },
        { property: 'TextLV', label: 'messages_colTextLv' },
        { property: 'TextEN', label: 'messages_colTextEn' },
        { width: '1px' }
    ];

    get isFiltered(): boolean {
        return !!(this.groupSearchValue || this.codeSearchValue || this.titleSearchValue || this.textEnSearchValue || this.textLvSearchValue);
    }

	ngOnInit() {
        this.fetch();
	}

	filter() {
        this.isFiltering = true;
        this.fetch();
	}

    delete(item: Message) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.fetch();
            });
        });
    }

    splitMessage(text: string): string[] {
        const div = document.createElement('div');
        div.innerHTML = (text || '').replace(/<br\s?\/?>/g, '...');

        const msg = div.textContent;
        const max = 30;

        if (msg.length > max) {
            const rows = [];

            // max 3 rows
            for (let i = 0; i < 3; i++) {
                const cut = msg.substr(i * max, max);

                if (cut) {
                    rows.push(cut);
                }
            }

            if (rows.length) {
                rows[rows.length - 1] += '...';
            }

            return rows;
        }

        return [msg];
    }

    private fetch() {
        const request = this.isFiltered ? this.service.findMessages({
            group: this.groupSearchValue || '',
            code: this.codeSearchValue || '',
            title: this.titleSearchValue || '',
            textLv: this.textLvSearchValue || '',
            textEn: this.textEnSearchValue || ''
        }) : this.service.get();

        this.app.addLoading(request).subscribe(data => {
            this.isFiltering = false;
            this.page = 1;
            this.items = data;
        });
    }
}
