<div class="modal-header">
    <h4 class="modal-title" *ngIf="model">{{(model.Id ? 'edit' : 'add') | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="form-field required">
            <label class="form-label">{{'classifiers_lblCode' | translate}}</label>
            <input class="form-control" required name="code" [(ngModel)]="model.Code" pattern="^\S(.*\S)?$">
        </div>

        <div class="form-field">
            <label class="form-label">{{'classifiers_lblLegacyCode' | translate}}</label>
            <input class="form-control" name="legacyCode" [(ngModel)]="model.LegacyCode" pattern="^\S(.*\S)?$" />
        </div>

        <div class="form-field">
            <label class="form-label">{{'classifiers_lblValue' | translate}}</label>
            <input class="form-control" name="valueLV" pattern="^\S(.*\S)?$" [(ngModel)]="model.ValueLV" />
        </div>

        <div class="form-field">
            <label class="form-label">{{'classifiers_lblValueEN' | translate}}</label>
            <input class="form-control" name="valueEN" pattern="^\S(.*\S)?$" [(ngModel)]="model.ValueEN" />
        </div>

        <div class="form-field">
            <label class="form-label">{{'classifiers_lblPayload' | translate}}</label>
            <textarea class="form-control" name="payload" rows="5" [(ngModel)]="model.Payload"></textarea>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button class="btn btn-primary" (click)="save()" type="button" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'save' | translate}}
    </button>
</div>
