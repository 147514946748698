import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';
import { ExternalServiceService } from '../../services/external-service.service';
import { MessageService } from '../../services/message.service';

@Component({
    selector: 'app-external-service',
    styleUrls: ['./external-service.component.css'],
    templateUrl: './external-service.component.html'
})
export class ExternalServiceComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: ExternalServiceService,
        private messages: MessageService,
        private route: ActivatedRoute
    ) { }

    title: string = '';
    content: string = '';
    textBefore: string;
    textAfter: string;

    ngOnInit() {
        const code = this.route.snapshot.params['code'];

        this.app.addLoading(this.service.getByCode(code)).subscribe(data => {
            this.title = this.app.currentLanguage == 'en' ? data.NameEN : data.NameLV;
            this.content = data.Content;

            const codeBefore = `ExternalService${data.Code}Before`;
            const codeAfter = `ExternalService${data.Code}After`;

            this.messages.getByCodes([codeBefore, codeAfter]).subscribe(msg => {
                this.textBefore = this.messages.getTextByLanguage(msg.find(t => t.Code == codeBefore), this.app.currentLanguage);
                this.textAfter = this.messages.getTextByLanguage(msg.find(t => t.Code == codeAfter), this.app.currentLanguage);
            });

        });
    }
}
