import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserInfo } from '../../models/UserInfo';

import { AppService } from '../../services/app.service';
import { StudentBalanceService } from '../../services/student-balance.service';
import { PersonService } from '../../services/person.service';

import { PrintBalanceGetResult } from '../../models/StudentBalance';
import { IPersonSearchResultItem } from '../../models/Person';

export const numberFormat: string = '1.2-2';

@Component({
	selector: 'app-print-v2',
	templateUrl: './print-v2.component.html',
	styleUrls: ['./print-v2.component.css']
})

export class PrintV2Component implements OnInit {
    constructor(
        public app: AppService,
        private service: StudentBalanceService,
        private route: ActivatedRoute,
        private personService: PersonService
    ) { }

    currentUser: UserInfo;
    printBalance: PrintBalanceGetResult;
    student: IPersonSearchResultItem;

	public printView: boolean = false;
	public printUse: boolean = false;
	public printAccountMissing: boolean = true;
	public showStudentPicker: boolean = false;
	public canSetStudent: boolean = false;

    ngOnInit() {
		const user = this.app.currentUser;
        
		if (user) {
			this.currentUser = user;
			this.printUse = user.rights.indexOf('PRINT_V2.USE') > -1;
			this.printView = this.printUse || user.rights.indexOf('PRINT_V2.VIEW') > -1;

			this.canSetStudent = user.rights.indexOf('PRINT_V2.SET_STUDENT') > -1;
			const paymentForm = this.route.snapshot.queryParams['amount'];

			if (this.canSetStudent && !paymentForm) {
				this.showStudentPicker = true;
                const queryUpn = this.route.snapshot.queryParams['id'];

                if (queryUpn) {
                    // can only view for students other than self
                    this.printUse = false;
					this.showStudentPicker = false;

                    this.app.addLoading(this.personService.findStudents('student_id', queryUpn)).subscribe(data => {
						if (data.length) {
							this.setStudent(data[0]);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
				}
            } else {
                this.init();
            }
        }
    }

    setStudent(student: IPersonSearchResultItem) {
		this.showStudentPicker = false;
		this.student = student;
		this.init();
    }

    private init() {
        this.app.addLoading(this.service.getPrintBalanceV2(this.student ? this.student.StudentId : undefined)).subscribe(r => {
            this.printBalance = r;
            this.printAccountMissing = r.Status !== 1;
        });
    }
}
