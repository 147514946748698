<div class="wrapper-md" *ngIf="title">
    <app-breadcrumbs [items]="[{ text: title }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{title}}</h1>
    </header>

    <div class="block-body">
        <app-alert *ngIf="textBefore" level="info">
            <div [innerHTML]="textBefore | safeHtml"></div>
        </app-alert>

        <div *ngIf="content" [innerHTML]="content | safeHtml"></div>

        <div *ngIf="textAfter" class="mt-3">
            <app-alert level="info">
                <div [innerHTML]="textAfter | safeHtml"></div>
            </app-alert>
        </div>
    </div>
</div>
