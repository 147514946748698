import { Component, Input } from '@angular/core';

import { AppService } from '../../../services/app.service';

import { ITableColumn } from '../../../shared/table/table.component';
import { HospitalService } from '../../../services/hospital.service';
import { IHospitalStudent } from '../../../models/Hospital';
import { store } from '../store';

interface IOption<T> {
    value: T;
    label: string;
}

interface ICheckOption<T> extends IOption<T> {
    checked?: boolean;
}

type Place = 'workplaceAndClinicalBase' | 'workplace' | 'clinicalBase';

const cache: {
    studentsByCompany: { [companyId: string]: IHospitalStudent[] }
} = {
    studentsByCompany: {}
};

@Component({
    selector: 'app-hospital-dashboard-students',
    templateUrl: './hospital-dashboard-students.component.html'
})
export class HospitalDashboardStudentsComponent {
    constructor(
        private app: AppService,
        private service: HospitalService
    ) { }

    @Input() set companyId(value: string) {
        this._companyId = value;
        this.init(true);
    }

    @Input() set academicYearId(value: string) {
        this._academicYearId = value;
        this.init();
    }

    readonly columns: ITableColumn[] = [
        {
            label: 'hospitalDashboard_students_lblNameSurnameId', sorts: true, sortFn: (a: IHospitalStudent, b: IHospitalStudent) => {
                const as = `${a.Name} ${a.Surname} ${a.Upn}`;
                const bs = `${b.Name} ${b.Surname} ${b.Upn}`;
                return as.localeCompare(bs, this.app.currentLanguage);
            }
        },
        { property: 'Speciality', label: 'hospitalDashboard_students_lblSpeciality', sorts: true },
        { property: 'StudyYear', label: 'hospitalDashboard_students_lblStudyYear', sorts: true, type: 'number' },
        { property: 'Status', label: 'hospitalDashboard_students_lblStatus', sorts: true },
        { property: 'Workplace', label: 'hospitalDashboard_students_lblWorkplace', sorts: true },
        { property: 'ClinicalBase', label: 'hospitalDashboard_students_lblClinicalBase', sorts: true },
        { width: '1px' }
    ];

    rows: IHospitalStudent[] = [];

    filters: {
        search?: string,
        speciality: {
            options: IOption<string>[],
            value?: string
        },
        year: {
            options: IOption<number>[],
            value?: number
        },
        status: {
            options: IOption<number>[],
            value?: number
        },
        place: {
            options: ICheckOption<Place>[]
        },
        plannedRotations : ICheckOption<boolean>
    } = {
            speciality: { options: [] },
            year: { options: [] },
            status: { options: [] },
            place: {
                options: [
                    { value: 'workplace', label: 'hospitalDashboard_students_workplace' },
                    { value: 'clinicalBase', label: 'hospitalDashboard_students_clinicalBase' }
                ]
            },
            plannedRotations: { value: false, label: 'hospitalDashboard_students_plannedRotations' }
        };

    get companyId(): string {
        return this._companyId;
    }

    get academicYearId(): string {
        return this._academicYearId;
    }

    private _companyId: string;
    private _academicYearId: string;
    private data: IHospitalStudent[] = [];

    filter() {
        const places = this.filters.place.options.filter(t => t.checked).map(t => t.value);
        
        this.rows = this.data.filter(t => {
            return (!this.filters.speciality.value || t.SpecialityId == this.filters.speciality.value)
                && (!this.filters.year.value || t.StudyYear == this.filters.year.value)
                && (!this.filters.status.value || t.StatusId == this.filters.status.value)
                && (this.isPlaceMatch(t, places))
                && (!this.filters.plannedRotations.checked || t.RotationBaseId)
                && (!this.filters.search || (t.Name + ' ' + t.Surname).toLowerCase().includes(this.filters.search.toLowerCase()));
        });
    }

    openStudyPlan(row: IHospitalStudent) {
        this.app.navigate(['/plan-summary'], {
            queryParams: {
                StudentId: row.Id,
                ProgramId: row.SpecialityId
            }
        });
    }

    private init(reload?: boolean) {
        const process = (data: IHospitalStudent[]) => {
            const byYear = this.filterByAcademicYear(data);

            this.addFilters(byYear);
            this.data = byYear;
            this.filter();
        };

        if (cache.studentsByCompany[this.companyId]) {
            process(cache.studentsByCompany[this.companyId]);
        } else if (reload) {
            this.app.addLoading(this.service.getStudents(this.companyId, store.getPerson()?.Upn)).subscribe(data => {
                cache.studentsByCompany[this.companyId] = data;
                process(data);
            });
        }
    }

    private filterByAcademicYear(data: IHospitalStudent[]) {
        return data.filter(t => t.AcademicYearId == this.academicYearId);
    }

    private addFilters(data: IHospitalStudent[]) {
        const specialityOpts = data.reduce((all, n) => {
            if (!all.some(t => t.value == n.SpecialityId)) {
                all.push({
                    value: n.SpecialityId,
                    label: n.Speciality
                });
            }

            return all;
        }, ([] as IOption<string>[]));

        const yearOpts = data.reduce((all, n) => {
            if (!all.some(t => t.value == n.StudyYear)) {
                all.push({
                    value: n.StudyYear,
                    label: `${n.StudyYear}.`
                });
            }

            return all;
        }, ([] as IOption<number>[]));

        const statusOpts = data.reduce((all, n) => {
            if (!all.some(t => t.value == n.StatusId)) {
                all.push({
                    value: n.StatusId,
                    label: n.Status
                });
            }

            return all;
        }, ([] as IOption<number>[]));

        specialityOpts.sort((a, b) => a.label.localeCompare(b.label, this.app.currentLanguage));
        yearOpts.sort((a, b) => a.value > b.value ? 1 : a.value < b.value ? -1 : 0);
        statusOpts.sort((a, b) => a.label.localeCompare(b.label, this.app.currentLanguage));

        this.filters.speciality.options = specialityOpts;
        this.filters.year.options = yearOpts;
        this.filters.status.options = statusOpts;
    }

    private isPlaceMatch(student: IHospitalStudent, places: Place[]) {
        if (places.includes('workplace') && student.WorkplaceId != this.companyId) return false;
        if (places.includes('clinicalBase') && student.ClinicalBaseId != this.companyId) return false;
        return true;
    }
}
