import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RSStudent, RSSubmitHistory, RSFormOpenAuditResult } from '../../models/RSTickets';

import { AppService } from '../../services/app.service';
import { ParameterService } from '../../services/parameter.service';
import { RSTicketsService } from '../../services/rs-tickets.service';
import { PersonService } from '../../services/person.service';

import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
    selector: 'app-rs-tickets',
    templateUrl: './rs-tickets.component.html',
    styleUrls: ['./rs-tickets.component.css']
})
export class RSTicketsComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: RSTicketsService,
        private route: ActivatedRoute,
        private parameters: ParameterService,
        private personService: PersonService,
        private messages: MessageService
    ) {
    }

    item: RSStudent;
    formUrl: string;
    auditData: string;
    language: string;
    role: string;
    MAC: string;
    history: RSSubmitHistory[] = [];
    historyRowsPerPage: number = 10;
    userAgreed: boolean;
    isSubmit: boolean = false;
    isLoaded: boolean;
    showStudentPicker: boolean;
    canPickStudent: boolean;
    student: IPersonSearchResultItem;

    unavailableText: string;
    isUnavailable: boolean;

    readonly historyColumns: ITableColumn[] = [
        { label: 'rsTicketsHistory_lblRowNumber', cssClass: 'hidden-md-down' },
        { label: 'rsTicketsHistory_lblSubmitTime' },
        { label: 'rsTicketsHistory_lblExpirationDate' }
    ];

    private formOpenAuditResult: RSFormOpenAuditResult;
    @ViewChild('form', { static: false }) private form: ElementRef;

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf('RS_TICKETS.SET_STUDENT') > -1;
        }

        const isEn = this.app.currentLanguage === 'en';

        this.app.addLoading(this.messages.getByCodes(['RS_TICKETS_UNAVAILABLE'])).subscribe(data => {
            const unav = data.find(t => t.Code === 'RS_TICKETS_UNAVAILABLE');
            this.unavailableText = unav ? isEn ? unav.TextEN : unav.TextLV : undefined;
        });

        this.language = this.app.currentLanguage;
        this.role = 'student';

        const params = this.route.snapshot.params;
        const queryParams = this.route.snapshot.queryParams;

        if (params['type'] === 'submit') {
            this.isSubmit = true;

            this.app.addLoading(this.service.getStudent(queryParams['email'])).subscribe(data => {
                this.item = data.Student || null;
                this.formOpenAuditResult = data.Audit;
                this.MAC = data.MAC;
                this.formUrl = data.AuthUrl;
                this.isLoaded = true;

                this.submit();
            });
        } else {
            if (this.canPickStudent) {
                const queryUpn = this.route.snapshot.queryParams['id'];

                if (queryUpn) {
                    // can only view for students other than self
                    //this.canSubmit = false;

                    this.app.addLoading(this.personService.findStudents('student_id', queryUpn)).subscribe(data => {
                        if (data.length) {
                            this.setStudent(data[0]);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
                } else {
                    this.showStudentPicker = true;
                }
            } else {
                this.init();
            }
        }
    }

    submit() {
        this.app.addLoading(this.service.auditSubmit(this.formOpenAuditResult.Id, this.formOpenAuditResult.Password)).subscribe(() => {
            this.form.nativeElement.submit();
        });
    }

    setStudent(student: IPersonSearchResultItem) {
        this.showStudentPicker = false;
        this.student = student;
        this.init();
    }

    getUserEmail(): string {
        return this.student ? this.student.Email : undefined;
    }

    private init() {
        this.app.addLoading(this.service.getStudent(this.getUserEmail())).subscribe(data => {
            this.item = data.Student || null;
            this.isLoaded = true;

            if (this.item) {
                this.app.addLoading(this.service.getHistory(this.getUserEmail())).subscribe(data => {
                    this.history = data;
                });

                this.app.addLoading(this.parameters.getValues()).subscribe(data => {
                    this.historyRowsPerPage = this.parameters.findValue(data, 'RSTicketsHistoryPageSize', t => +t, 10);
                });
            } else {
                this.isUnavailable = true;
            }
        }, err => {
            this.isUnavailable = true;
        });
    }
}
