import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IForm } from './seminar-attendance-form.component';

@Component({
    selector: 'app-seminar-attendance-modal',
    templateUrl: './seminar-attendance-modal.component.html'
})
export class SeminarAttendanceModalComponent {
    constructor(
        private activeModal: NgbActiveModal
    ) { }

    @Input() seminarId: string;

    isSaving: boolean;

    get canEdit(): boolean {
        return this.form?.canEdit;
    }

    get hasChanges(): boolean {
        return this.form.rows.some(t => t.IsAttended != t.wasAttended);
    }

    private form: IForm;

    onLoad(event: IForm) {
        this.form = event;
    }

    save() {
        this.isSaving = true;
        this.form.save().then(() => {
            this.isSaving = false;
            this.activeModal.close();
        }, () => {
            this.isSaving = false;
        });
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
