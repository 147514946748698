import { IPersonSearchResultItem } from "../../models/Person";
import { ICourseEqualizationFile } from "../../models/CourseEqualization";

import * as FileSaver from 'file-saver';
import { CourseEqualizationService } from "../../services/course-equalization.service";
import { AppService } from "../../services/app.service";

const data: {
    student?: IPersonSearchResultItem
} = {};

export const store = {
    getStudent(): IPersonSearchResultItem {
        return data.student;
    },

    setStudent(student: IPersonSearchResultItem) {
        data.student = student;
    }
};

export const util = {
    downloadAttachment(app: AppService, service: CourseEqualizationService, attachment: ICourseEqualizationFile) {
        app.addLoading(service.getAttachment(attachment.Id)).subscribe(result => {
            FileSaver.saveAs(result, attachment.FileName);
        });
    },
    getAttachmentNameDisplay(attachment: ICourseEqualizationFile): string {
        const maxLength = 20;
        const parts = attachment.FileName.split('.');
        const ext = parts.pop();

        let name = parts.join('.');

        if (name.length > maxLength) {
            name = name.substring(0, 18) + '..';
        }

        return name + '.' + ext;
    }
};
