<div class="wrapper-md">
    <header class="content-header">
        <h1>{{'login_title' | translate}}</h1>
    </header>

    <app-alert level="info" *ngIf="action != 'error' && action != 'done'">{{'pleasewait' | translate}}</app-alert>

    <app-alert level="warning" *ngIf="noRightsWarning">
        <div [innerHTML]="noRightsWarning"></div>
    </app-alert>

    <app-alert level="danger" *ngIf="action == 'error'">
        <div><strong>{{'loginerror' | translate}}</strong></div>
        <div>
            <a href="#" (click)="logout()" [translate]="'login_logout'" *ngIf="accessDenied"></a>
            <a [routerLink]="['/login/adfs'] | localize" [translate]="'tryagain'" *ngIf="!accessDenied"></a>
        </div>
    </app-alert>
</div>
