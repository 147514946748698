import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate, Location, ViewportScroller } from '@angular/common';
import * as moment from 'moment';

import { AppService } from '../../services/app.service';
import { Said_eventsService } from '../../services/said-events.service';

import { PersonMainDataItem, StudentEventItem, SkillDetailItem, StudentEventSkill } from '../../models/Said_events';
import { EventsDialogComponent } from './events-dialog.component';

import { Utils } from '../../core/Utils';
import { Helpers } from './helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'said_events_add',
    templateUrl: './said_events-add.component.html',
    styleUrls: ['./said_events-add.component.css']
})
export class SaidEventsAddComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: Said_eventsService,
        private route: ActivatedRoute,
        private router: Router,
        private modal: NgbModal,
        //private dateAdapter: DateAdapter<Date>,
        private location: Location,
        private viewportScroller: ViewportScroller
    ) {
        this.getScreenSize();

    }

    readonly language = (this.app.currentLanguage || 'lv').toLowerCase();
    readonly hourOptions = Array.from(Array(24).keys()).map(t => ('0' + t).slice(-2));
    readonly minuteOptions = Array.from(Array(60).keys()).map(t => ('0' + t).slice(-2));

    helpers = Helpers;
    admin: boolean = false;
    Student_guid: string;
    Planning_period_GUID: string;
    studentEmail: string;
    studentFullName: string;
    filterSupervised: boolean;

    currentPersonData: PersonMainDataItem;
    pointsInfoHtml: string = null;
    events: StudentEventItem[] = [];
    isLoaded: boolean = false;

    //location: Location;
    fragment: string = null;

    backUrl: string;
    currentUrl: string;

    currentFilter = {
        All: null,
        DateFrom: null,
        DateTo: null,
        Address: null,
        SkillCategory: null,
        EventType: null,
        EventAdded: null,
        onlyAdded: false,
        onlyNonAdded: false,
        onlySupervised: false,
        onlyNonSupervised: false,
        TimeFromHours: null,
        TimeFromMinutes: null,
        TimeToHours: '23',
        TimeToMinutes: '59'
    };

    extendedFilter: boolean = false;

    //FilterLang: string[] = [];
    //FilterBlock: string[] = [];
    FilterEventType: string[] = [];
    FilterEventAddedType: string[] = [];
    FilterSkillCategory: string[] = [];

    nonAddedEvents: { [key: number]: StudentEventItem } = {}; //pieejamie, neizvēlētie notikumi
    alreadyAddedEvents: { [key: number]: StudentEventItem } = {}; //izvēlētie notikumi
    supervisedEvents: { [key: number]: StudentEventItem } = {}; //notikumi prasmju apguve attālinātā docētāja uzraudzībā
    nonSupervisedEvents: { [key: number]: StudentEventItem } = {}; //notikumi patstāvīgai prasmju pilnveidei

    showSkillInfoTable: string[] = [];

    screenHeight: number;
    screenWidth: number;
    isMobile: boolean;

    get isAdmin(): boolean {
        return this.app.currentUser?.isAdmin;//this.app.auth.CurrentUser.value.rights.some(t => t =='BC_COURSES.ADMIN');
    }

    private skillDetailCache: { [key: string]: SkillDetailItem[] } = {};

    //@ViewChild("startTime", { static: true }) sTime: NgxTimepickerFieldComponent;
    //@ViewChild("endTime", { static: true }) eTime: NgxTimepickerFieldComponent;
    @ViewChild("eventType", { static: true }) eType: any;//TODO angular material obsolete [MatSelect]

    /*pointTooMuch(id: number) {
        let pointTooMuch = false;
        let event = this.events[id - 1];
        if (event && this.currentPersonData) {
            if (event.Block == 'B')
                pointTooMuch = this.currentPersonData.Creditpoints_B + event.Credit_points > this.currentPersonData.Planned_credit_points_B;
            else
                pointTooMuch = this.currentPersonData.Creditpoints_C + event.Credit_points > this.currentPersonData.Planned_credit_points_C;
        }
        return pointTooMuch;
    }*/

    ngOnInit() {
        this.isLoaded = false;
        this.route.params.subscribe(params => {
            this.Student_guid = params['Student_guid'];
            this.Planning_period_GUID = params['Planning_period_GUID'];
            this.studentEmail = params['studentEmail'];
            this.studentFullName = params['studentFullName'];
            this.currentFilter.onlySupervised = params['filterSupervised'];

            this.currentUrl = (this.isAdmin
                ? ['/said_events_add', this.Student_guid, this.Planning_period_GUID, this.studentEmail, this.studentFullName]
                : ['/said_events_add', this.Student_guid, this.Planning_period_GUID]).join('/');

            this.backUrl = (this.isAdmin
                ? ['/said-events', this.Student_guid, this.Planning_period_GUID, this.studentEmail, this.studentFullName]
                : ['/said-events', this.Student_guid, this.Planning_period_GUID]).join('/');

            //ielasām saglabātās vērtības
            let locStorFilter = localStorage.getItem('curFilter');
            if (locStorFilter) {
                this.currentFilter = JSON.parse(locStorFilter);
                localStorage.removeItem('curFilter');
            }
            if (this.currentFilter.onlySupervised) {
                this.currentFilter.EventType = this.getEventTypeName(1);
            }
            if (!this.studentEmail || !this.studentFullName) {
                const user = this.app.currentUser;
                this.admin = user.hasRight('SAID_EVENTS.ADMIN');
                if (this.admin)
                    this.router.navigate([this.app.localizeRoute(`/said_events`)]);
                else {
                    this.studentEmail = user.email;
                    this.studentFullName = user.firstName + " " + user.lastName;
                }
            } else {
                this.admin = true;
            }

            if (this.Student_guid && this.Planning_period_GUID) {
                this.load();
            }
            this.fragment = this.route.snapshot.fragment;
        });

        // TODO angular material obsolete
        //this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
    }

    setPointsInfoHtml() {
        this.app.addLoading(this.service.getPersonMainData(this.studentEmail)).subscribe(persons => {
            this.currentPersonData = persons.find(p => p.Planning_period_GUID == this.Planning_period_GUID && p.Student_guid == this.Student_guid);

            const msg = this.app.translate('said_events_points_info');

            if (msg) {
                let val = [
                    this.currentPersonData.Planned_Skills != null ? this.currentPersonData.Planned_Skills : 0,
                    this.currentPersonData.Acquired_Skills != null ? this.currentPersonData.Acquired_Skills : 0,

                ];
                this.pointsInfoHtml = Utils.formatString(msg, val);
            }
            else {
                this.pointsInfoHtml = null;
            }
        });
    }

    load() {
        let groupedInfo: StudentEventItem[] = [];
        let alreadyAdded: StudentEventItem = null;
        let nonAdded: StudentEventItem = null;
        let supervised: StudentEventItem = null;
        let nonSupervised: StudentEventItem = null;
        let fullyBooked: boolean[] = [];

        this.FilterEventType.push(this.getEventTypeName(1));
        this.FilterEventType.push(this.getEventTypeName(2));

        this.FilterEventAddedType.push(this.getEventAddedFilterName(1))
        this.FilterEventAddedType.push(this.getEventAddedFilterName(2));

        this.app.addLoading(this.service.getStudentEvents(this.Student_guid))
            .subscribe(studentEvents => {
                if (studentEvents.length > 0) {
                    let item = studentEvents[0];
                    groupedInfo.push(item);
                    let nr = item.EventId;

                    fullyBooked.push(item.ActualStudentCount >= item.MaxStudentCount);
                    if (item.IsSelected)
                        alreadyAdded = item;
                    else
                        nonAdded = item;

                    if (item.IsSupervised)
                        supervised = item;
                    else
                        nonSupervised = item;

                    if (item.Skills) {
                        item.Skills.forEach(s => this.FilterSkillCategory.push(s.Category));
                    }
                    //ja nav kāda veida notikumi, tāpat filtra sarakstā jābūt abiem veidiem pieejamiem
                    //if (item.EventType)
                    //	this.FilterEventType.push(this.getEventTypeName(item.EventType - 1, this.app.translate.currentLang));


                    //if (item.Block)
                    //    this.FilterBlock.push(item.Block);
                    //if (item.Subject_semester_lang)
                    //    this.FilterLang.push(item.Subject_semester_lang);

                    if (studentEvents.length == 1) {
                        let couseItem = studentEvents[0];
                        this.pushToCourses(couseItem, groupedInfo, fullyBooked, nr, alreadyAdded, nonAdded, supervised, nonSupervised);
                    }
                    else {
                        studentEvents = studentEvents.sort((f, s) => <any>new Date(f.EventTimeFrom) - <any>new Date(s.EventTimeFrom));
                        for (let i = 0; i < studentEvents.length; i++) {
                            item = studentEvents[i];

                            /*if (item.EventId == nr) {
                                groupedInfo.push(item);
                                fullyBooked.push(item.ActualStudentCount >= item.MaxStudentCount);
                            }
                            else {*/
                            //let couseItem = studentEvents[i - 1];

                            groupedInfo = [item];
                            nr = item.EventId;
                            fullyBooked = [item.ActualStudentCount >= item.MaxStudentCount];
                            alreadyAdded = null;
                            //}

                            if (item.IsSelected)
                                alreadyAdded = item;
                            else
                                nonAdded = item;

                            if (item.IsSupervised)
                                supervised = item;
                            else
                                nonSupervised = item;

                            fullyBooked.push(item.ActualStudentCount >= item.MaxStudentCount);
                            this.pushToCourses(item, groupedInfo, fullyBooked, nr, alreadyAdded, nonAdded, supervised, nonSupervised);

                            let ctx = this;
                            if (item.Skills)
                                item.Skills.forEach(function (s) {
                                    if (ctx.FilterSkillCategory.findIndex(e => e == s.Category) == -1)
                                        ctx.FilterSkillCategory.push(s.Category);
                                }
                                );//this.FilterSkillCategory.push(item.SkillCategory);

                            //neatlasām notikumu veidus no saraksta, bet pirms šī cikla pievienojam abus veidus
                            //if (item.EventType && this.FilterEventType.findIndex(w => w == this.getEventTypeName(item.EventType-1, this.app.translate.currentLang)) == -1)
                            //	this.FilterEventType.push(this.getEventTypeName(item.EventType-1, this.app.translate.currentLang));

                            //if (item.Block && this.FilterBlock.findIndex(b => b == item.Block) == -1)
                            //    this.FilterBlock.push(item.Block);
                            //if (item.Subject_semester_lang && this.FilterLang.findIndex(l => l == item.Subject_semester_lang) == -1)
                            //    this.FilterLang.push(item.Subject_semester_lang);

                            /*if (i == studentEvents.length - 1) {
                                this.pushToCourses(item, groupedInfo, fullyBooked, nr, alreadyAdded, nonAdded, supervised, nonSupervised);
                            }*/
                        }
                    }
                }

                this.isLoaded = true;

                setTimeout(() => {
                    if (!!this.fragment) {
                        if (this.scroll(this.fragment)) {
                            this.fragment = null;
                        }
                    }
                }, 0);
            });
        //window.scrollTo(0, this.scrollPos);
    }

    openSkill(skill: StudentEventSkill) {
        const show = (details: SkillDetailItem[]) => {
            const ref = this.modal.open(EventsDialogComponent, {
                size: 'lg'
            });
            ref.componentInstance.data = { details };
        };

        let cached = this.skillDetailCache[skill.Id];

        if (cached && cached.length) {
            show(this.skillDetailCache[skill.Id]);
        } else {
            this.app.addLoading(this.service.getSkillDetails(skill.Id)).subscribe(details => {
                this.skillDetailCache[skill.Id] = details;
                show(details);
            });

        }
    }

    /*public getCourseDateTimeString(course: StudentEventItem, groupedInfo: StudentEventItem): string {
        if (course.detailed_planning)
            return this.app.translate.instant('BC_courses_calendar');

        if (groupedInfo.Group_day && groupedInfo.Time_from && groupedInfo.Time_to)
            return `${groupedInfo.Group_day}, ${groupedInfo.Time_from} - ${groupedInfo.Time_to}`;

        if (groupedInfo.Group_day && !groupedInfo.Time_from && !groupedInfo.Time_to)
            return groupedInfo.Group_day;

        if (!groupedInfo.Group_day && !groupedInfo.Time_from && !groupedInfo.Time_to)
            return this.app.translate.instant('BC_courses_time_info');

        return '';
    }*/

    private pushToCourses(eventItem: StudentEventItem, groupedInfo: StudentEventItem[], fullyBooked: boolean[], nr: number, alreadyAdded: StudentEventItem, nonAdded: StudentEventItem, supervised: StudentEventItem, nonSupervised: StudentEventItem) {
        let skillCode = "";
        if (eventItem.Skills && eventItem.Skills.length > 0) {
            skillCode = eventItem.Skills[0].Code;
        }

        //eventItem.groupedInfo = groupedInfo;
        //eventItem.GroupIsFull = eventItem.ActualStudentCount >= eventItem.MaxStudentCount; //fullyBooked.findIndex(b => b == false) >= 0 ? false : true;

        this.events.push(eventItem);

        this.nonAddedEvents[nr] = nonAdded != null ? nonAdded : undefined; //groupedInfo.length == 1 ? groupedInfo[0] : alreadyAdded != null ? alreadyAdded : undefined;
        this.alreadyAddedEvents[nr] = alreadyAdded != null ? alreadyAdded : undefined;
        this.supervisedEvents[nr] = supervised != null ? supervised : undefined;
        this.nonSupervisedEvents[nr] = nonSupervised != null ? nonSupervised : undefined;
    }

    get filteredEvents(): StudentEventItem[] {
        const hm = (hours: string, minutes: string) => {
            return `${hours || '00'}:${minutes || '00'}`;
        };

        let list = this.events;

        let all = (this.currentFilter.All || '').toLowerCase();
        let dateFrom = (this.currentFilter.DateFrom);
        let dateTo = (this.currentFilter.DateTo);
        let address = (this.currentFilter.Address || '').toLowerCase();;
        let timeFrom = hm(this.currentFilter.TimeFromHours, this.currentFilter.TimeFromMinutes);
        let timeTo = hm(this.currentFilter.TimeToHours, this.currentFilter.TimeToMinutes);
        let skillCategory = (this.currentFilter.SkillCategory || '').toLowerCase();
        let eventType = (this.currentFilter.EventType || '').toLowerCase();
        let eventAdded = (this.currentFilter.EventAdded || '').toLowerCase();

        if (eventAdded) {
            this.currentFilter.onlyAdded = eventAdded === this.getEventAddedFilterName(1).toLowerCase();
            this.currentFilter.onlyNonAdded = eventAdded === this.getEventAddedFilterName(2).toLowerCase();
        } else {
            this.currentFilter.onlyAdded = false;
            this.currentFilter.onlyNonAdded = false;
        }

        if (this.currentFilter.onlyAdded) {
            list = list.filter(t => this.alreadyAddedEvents[t.EventId]);
        }
        //atlasam notikumus, kuriem var pieteikties
        if (this.currentFilter.onlyNonAdded) {
            list = list.filter(t => !this.alreadyAddedEvents[t.EventId]);
            list = list.filter(t => (this.isAdmin || !t.IsSupervised || (t.StudentTestIsPassed && t.IsSupervised)));
            list = list.filter(t => !this.helpers.validateDate(t.RegistrationOpenDate, t.RegistrationCloseDate) && !t.GroupIsFull);
        }
        /*if (this.currentFilter.onlyNonAdded) {
            list = list.filter(t => !(t.GroupIsFull || this.helpers.validateDate(t.RegistrationOpenDate, t.RegistrationCloseDate)) && ((t.StudentTestIsPassed && t.IsSupervised) || !t.IsSupervised));
        }*/

        if (this.currentFilter.onlySupervised) {
            list = list.filter(t => t.IsSupervised);
            //this.eType.writeValue(this.getEventTypeName(1, this.app.translate.currentLang));
        }
        if (this.currentFilter.onlyNonSupervised) {
            list = list.filter(t => !t.IsSupervised);
            //this.currentFilter.onlySupervised = false;
        }
        if (all) {
            list = list.filter(t =>
                (t.EventAddress || '').toLowerCase().indexOf(all) > -1 ||
                (t.EventRoom || '').toLowerCase().indexOf(all) > -1 ||
                //(t.groupedInfo.findIndex(g => (g.Travel_dest_name || '').toLowerCase().indexOf(all) > -1) > -1) ||
                //(t.SkillCategory || '').toLowerCase().indexOf(all) > -1 ||
                (t.Skills.findIndex(g => (g.Category || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (t.Skills.findIndex(g => (g.Name || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (t.Skills.findIndex(g => (g.Code || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (t.Skills.findIndex(g => (g.Level || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (t.Skills.findIndex(g => (g.LearningType || '').toLowerCase().indexOf(all) > -1) > -1) ||
                //(t.groupedInfo.findIndex(g => (g.Examination_type || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (this.getEventTypeName(t.EventType - 1) || '').toLowerCase().indexOf(all) > -1
                //(t.Subject_name || '').toLowerCase().indexOf(all) > -1 ||
                //(t.Department || '').toLowerCase().indexOf(all) > -1 ||
                //(t.Credit_points ? t.Credit_points.toString() : '').toLowerCase().indexOf(all) > -1
            );
        }
        if (dateFrom) {
            list = list.filter(t => (new Date(t.EventTimeFrom)) >= dateFrom);
        }
        if (dateTo) {
            list = list.filter(t => (moment(t.EventTimeTo)) <= moment(dateTo).add(1, 'days'));
        }
        if (address) {
            //list = list.filter(t => t.groupedInfo.findIndex(g => (g.Travel_dest_name || '').toLowerCase().indexOf(address) > -1) > -1);
            list = list.filter(t =>
                (t.EventAddress || '').toLowerCase().indexOf(address) > -1 ||
                (t.EventRoom || '').toLowerCase().indexOf(address) > -1
            );
            //list = list.filter(t => (t.EventAddress || '').toLowerCase().indexOf(address) > -1);
        }
        if (timeFrom) {
            list = list.filter(t => (formatDate(t.EventTimeFrom, "HH:mm", "lv-LV") >= formatDate(new Date("0001-01-01T" + timeFrom), "HH:mm", "lv-LV")));
        }
        if (timeTo) {
            list = list.filter(t => (formatDate(t.EventTimeTo, "HH:mm", "lv-LV") <= formatDate(new Date("0001-01-01T" + timeTo), "HH:mm", "lv-LV")));
        }
        if (skillCategory) {
            list = list.filter(t => (t.Skills.findIndex(g => (g.Category || '').toLowerCase().indexOf(skillCategory) > -1) > -1));
        }
        if (eventType) {
            list = list.filter(t => (this.getEventTypeName(t.EventType - 1).toLowerCase().indexOf(eventType) > -1));
        }


        return list;
    }

    ClearFilter() {
        this.currentFilter = {
            All: null,
            DateFrom: null,
            DateTo: null,
            Address: null,
            //TimeFrom: null,
            //TimeTo: null,
            TimeFromHours: null,
            TimeFromMinutes: null,
            TimeToHours: null,
            TimeToMinutes: null,
            SkillCategory: null,
            EventType: null,
            EventAdded: null,
            onlyAdded: false,
            onlyNonAdded: false,
            onlySupervised: false,
            onlyNonSupervised: false
        };
        //if (this.sTime)
        //    this.sTime.writeValue("00:00");
        //if (this.eTime)
        //    this.eTime.writeValue("23:59");

        this.reloadComponent(null);
    }

    async enroll(eventId: number): Promise<void> {

        let event = this.nonAddedEvents[eventId];
        const ctx = this;

        if (!event) {
            this.events.find(t => t.EventId === eventId).Error = this.app.translate('said_events_mustSelect');
        }
        else {
            let applictionType = this.admin ? 'Lietvedis' : 'Students';

            this.app.addLoading(this.service.addEventApplication({
                studentId: this.Student_guid,
                eventId: eventId,
                lang: ""
            })).subscribe(result => {
                if (!result) {
                    ctx.events.find(t => t.EventId === event.EventId).Error = null;
                    //ctx.setPointsInfoHtml();
                    let currentCourse = ctx.events.find(t => t.EventId === event.EventId);//.groupedInfo.find(i => i.Group_id == event.Group_id);

                    currentCourse.IsSelected = true;//data.IsSelected;
                    currentCourse.ActualStudentCount = currentCourse.ActualStudentCount + 1;
                    ctx.alreadyAddedEvents[event.EventId] = currentCourse;
                    //this.courses.find(t => t.Nr === course.Nr).CanWithdraw = true;
                    //curentCourse.CanWithdraw = true;
                    //curentCourse.Application_statuss_code = 866560002;
                    //ctx.reloadComponent("anch_" + eventId);
                }
                else {
                    ctx.events.find(t => t.EventId === event.EventId).Error = result;
                    this.app.showError(this.app.translate("said_events_reg_failed_message") + "<br/>" + result);
                    //ctx.reloadComponent("anch_" + eventId);
                }
            });
        }



    }
    /*canRemoveProgram(program: StudentEventItem): boolean {
        return program.Application_statuss_code == 866560002 && program.CanWithdraw;
    }*/
    async removeProgram(eventId: number) {
        let course = this.alreadyAddedEvents[eventId];
        const ctx = this;
        this.app.addLoading(this.service.deleteEventApplication({
            studentId: this.Student_guid,
            eventId: eventId,
            lang: ""
        })).subscribe(result => {
            if (!result) {
                ctx.events.find(t => t.EventId === course.EventId).Error = null;
                //ctx.setPointsInfoHtml();
                let currentCourse = ctx.events.find(t => t.EventId === course.EventId);//.groupedInfo.find(i => i.Group_id == course.Group_id).Application_ID = null;

                ctx.alreadyAddedEvents[course.EventId] = undefined;
                course.IsSelected = false;
                ctx.nonAddedEvents[course.EventId] = course;
                currentCourse.ActualStudentCount = currentCourse.ActualStudentCount - 1;
                //ctx.reloadComponent("anch_" + eventId);
            }
            else {
                ctx.events.find(t => t.EventId === course.EventId).Error = result;
                this.app.showError(this.app.translate("said_events_unreg_failed_message") + "<br/>" + result);
                //ctx.reloadComponent("anch_" + eventId);
            }
        });
    }

    reloadComponent(anchor: string) {

        let currentUrl = this.router.url;// + "#" + anchor;
        let anchPos = currentUrl.indexOf("#") > 0 ? currentUrl.indexOf("#") : currentUrl.length;
        let clearUrl = currentUrl.substring(0, anchPos);
        //this.viewport.scrollToAnchor(anchor);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        if (!this.currentFilter.onlySupervised) {
            //let currentUrl = this.router.url;
            if (clearUrl.indexOf("/true") > -1) {
                clearUrl = clearUrl.replace("/true", "");
                //this.eType.writeValue("");
                this.currentFilter.EventType = null;
            }
        }
        if (this.currentFilter.EventType != this.getEventTypeName(1)) {
            this.currentFilter.onlySupervised = null;
            if (clearUrl.indexOf("/true") > -1) {
                clearUrl = clearUrl.replace("/true", "");
                //this.eType.writeValue("");
                this.currentFilter.EventType = null;
            }
        }
        //saglabajam filtru vērtības
        localStorage.removeItem('curFilter');
        localStorage.setItem('curFilter', JSON.stringify(this.currentFilter));

        this.router.navigate([decodeURIComponent(clearUrl)], { fragment: anchor });

    }

    reloadEventTypeFilter() {
        let currentUrl = this.router.url;// + "#" + anchor;
        let anchPos = currentUrl.indexOf("#") > 0 ? currentUrl.indexOf("#") : currentUrl.length;
        let clearUrl = currentUrl.substring(0, anchPos);
        if ((this.eType.value != this.getEventTypeName(1)) && this.currentFilter.onlySupervised) {
            this.currentFilter.onlySupervised = null;
            if (clearUrl.indexOf("/true") > -1) {
                clearUrl = clearUrl.replace("/true", "");
                //this.eType.writeValue("");
                this.currentFilter.EventType = this.eType.value;

            }
            //this.router.navigate([decodeURIComponent(clearUrl)]);
            this.location.go(clearUrl);
        }

    }

    getEventTypeName(eventType: number) {
        let etEn = ["", "Remote, under supervision", "Independent acquisition of skills", "Not applicable to the study process"];
        let etLv = ["", "Attālināti, docētāja uzraudzībā", "Patstāvīga prasmju apguve", "Nav attiecināms uz studiju pocesu"];
        return this.language == "en" ? etEn[eventType] : etLv[eventType];
    }

    getEventAddedFilterName(eventAddedType: number) {
        /*let etEn = ["", "Events to which I have registered", "Events I have not registered for"];
        let etLv = ["", "Notikumi, uz kuriem esmu reģistrējies", "Notikumi, uz kuriem neesmu reģistrējies"];
        */
        //let eaf = ["", "'said_events_FilterEventAddedType1' | translate", "'said_events_FilterEventAddedType2' | translate"];
        let eaf = ["", this.app.translate("said_events_FilterEventAddedType1"), this.app.translate("said_events_FilterEventAddedType2")];

        return eaf[eventAddedType];
    }

    toggleEventType(onlySupervised: boolean) {
        this.currentFilter.onlySupervised = onlySupervised;
        this.currentFilter.onlyNonSupervised = !onlySupervised;

    }

    toggleSkillInfo(siTable: string) {
        let idx = this.showSkillInfoTable.indexOf(siTable);
        if (idx > -1) {
            this.showSkillInfoTable.splice(idx, 1);
        } else {
            this, this.showSkillInfoTable.push(siTable);
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        this.isMobile = this.screenWidth <= 800;
    }

    scroll(id: string): boolean {
        const elmnt = document.getElementById(id);
        //elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        if (!!elmnt) {
            this.viewportScroller.scrollToAnchor(id);
            return true;
        } else {
            return false;
        }
    }
}
