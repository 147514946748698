<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/bc_courses', text: 'BC_courses_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{'BC_courses_title' | translate}}

        </h1>
        <aside *ngIf="canPickStudent && !studentPickerOpened && !isDenied">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'BC_courses_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="pickStudent($event)"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened">
        <app-alert level="warning" *ngIf="isUnavailable">
            <div [innerHTML]="unavailableText | safeHtml"></div>
        </app-alert>

        <section class="block" *ngIf="!isUnavailable">
            <app-alert level="danger" *ngIf="isDenied || (isLoaded && (!personDataList || !personDataList.length))">
                {{'eServiceNotAvailable' | translate}}
            </app-alert>

            <ng-container *ngIf="!isDenied && !isUnavailable">
                <app-alert level="warning" *ngIf="isLoaded && noSelectionNeeded && !isSetKPInfoTextIsOn">
                    {{'BC_courses_noSelectionNeeded' | translate}}
                </app-alert>

                <app-alert class="warning" *ngIf="isLoaded && isSetKPInfoTextIsOn && KPInfoTextIsOnText">
                    {{KPInfoTextIsOnText}}
                </app-alert>

                <ng-container *ngIf="isLoaded">
                    <div class="data-value" *ngIf="canPickStudent && studentFullName">
                        <label>{{'BC_courses_student' | translate}}</label>
                        <div>{{studentFullName}} ({{studentUpn}})</div>
                    </div>

                    <div class="form-field" *ngIf="personDataList && personDataList.length > 1">
                        <app-select [placeholder]="'BC_courses_lblDropType' | translate"
                                    [(ngModel)]="filterValue"
                                    [options]="personDataList"
                                    [displayProperty]="'Study_program'"
                                    [required]="true"
                                    name="filterValue"
                                    (selectionChange)="onFilterValueChange($event)">
                        </app-select>
                    </div>

                    <app-alert level="info" *ngIf="pointsInfoHtml">
                        <div [innerHTML]="pointsInfoHtml | safeHtml"></div>
                    </app-alert>

                    <app-alert level="warning" *ngIf="formInfoHtml">
                        <div [innerHTML]="formInfoHtml | safeHtml"></div>
                    </app-alert>
                </ng-container>
            </ng-container>
        </section>

        <section class="block" *ngIf="rights.view && isLoaded && !isUnavailable">
            <header class="block-header">
                <h5 [translate]="'BC_courses_AddedCourses_title' | translate"></h5>
                <aside>
                    <button type="button" (click)="addProgram()" class="btn btn-outline-primary"
                            *ngIf="!studentPickerOpened && isLoaded" [disabled]="!getPersonMainDataHadResult">
                        <i class="fi fi-rs-plus"></i>
                        {{'BC_courses_addCource' | translate}}
                    </button>
                </aside>
            </header>

            <app-alert level="warning" *ngIf="!selectedStudentCourses.length">
                {{'BC_courses_noProgramAdded' | translate}}
            </app-alert>

            <section class="block-em" *ngFor="let c of selectedStudentCourses; let i = index;">
                <header class="block-header">
                    <h5>
                        <strong>{{c.Block}}</strong>
                        <small>{{'BC_courses_part_lbl' | translate}}</small>
                        <a href="{{c.M3}}" target="_blank">
                            {{c.Subject_code}}, {{c.Subject_name}}
                            <i class="fi fi-rs-angle-double-small-right"></i>
                        </a>

                    </h5>
                    <aside class="align-self-start">
                        <span class="badge"
                              [class.text-bg-success]="c.Application_statuss_code == 866560004"
                              [class.text-bg-danger]="c.Application_statuss_code == 866560003"
                              [class.text-bg-warning]="c.Application_statuss_code == 866560002">
                            {{c.Application_statuss_name}}
                        </span>
                    </aside>
                </header>

                <app-alert level="danger" *ngIf="c.error">{{c.error}}</app-alert>
                <app-alert level="info" *ngIf="!helpers.canRemoveProgram(c)">{{c.CanWithdrawComment}}</app-alert>

                <div class="row">
                    <div class="col-sm data-value">
                        <label>{{'BC_courses_language_lbl' | translate}}</label>
                        <div>{{c.Subject_semester_lang}}</div>
                    </div>

                    <div class="col-sm data-value">
                        <label>{{'BC_courses_credits_lbl' | translate}}</label>
                        <div>
                            {{c.Credit_points}}
                            <span *ngIf="c.Semester_nr" class="badge text-bg-primary">{{'BC_courses_longCourseLbl' | translate}}</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm data-value">
                        <label>{{'BC_courses_Examination_typeLbl' | translate}}</label>
                        <div>{{c.Examination_type ? c.Examination_type : ' - '}}</div>
                    </div>

                    <div class="col-sm data-value">
                        <label>{{'BC_courses_Course_managerLbl' | translate}}</label>
                        <div>{{c.Course_manager ? c.Course_manager : ' - '}}</div>
                    </div>
                </div>

                <div class="data-value">
                    <label>{{'BC_courses_implemented_lbl' | translate}}</label>
                    <div>{{c.Department}}</div>
                </div>

                <div class="data-value">
                    <label class="form-label">{{'BC_courses_infoAll_lbl' | translate}}</label>
                    <div>
                        <div class="info-row mb-1">
                            <div class="info-row-data">
                                <span class="info-row-item">
                                    <i class="fi fi-rs-marker"></i>
                                    <span>
                                        {{c.Travel_dest_name}}
                                    </span>
                                </span>

                                <span class="info-row-item">
                                    <i class="fi fi-rs-clock"></i>

                                    <span *ngIf="c.detailed_planning">
                                        <a href="#" (click)="$event.preventDefault(); openEvents(c, c.groupedInfo[0], 'calendar')">
                                            {{getCourseDateTimeString(c, c.groupedInfo[0])}}
                                        </a>
                                    </span>

                                    <span *ngIf="!c.detailed_planning && c.Group_day && c.Time_from && c.Time_to">
                                        {{c.Group_day}}, {{c.Time_from}} - {{c.Time_to}}
                                    </span>

                                    <span *ngIf="!c.detailed_planning && (!c.Group_day || !c.Time_from || !c.Time_to)">
                                        {{'BC_courses_time_info' | translate}}
                                    </span>
                                </span>

                                <span class="info-row-item">
                                    <i class="fi fi-rs-user"></i>

                                    <span>
                                        {{c.Used_places}} {{'BC_courses_OutOf' | translate}} {{c.Max_students}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-actions">
                    <button type="button"
                            class="btn btn-secondary" (click)="removeProgram(c.Application_ID)" [disabled]="!isAdmin && !helpers.canRemoveProgram(c)">
                        {{'BC_course_remove' | translate}}
                    </button>
                </div>
            </section>
        </section>

        <section class="block" *ngIf="rights.view && isLoaded && formExplanationsHtml && !isUnavailable">
            <h5>{{'BC_courses_explanations' | translate}}</h5>

            <div [innerHTML]="formExplanationsHtml | safeHtml"></div>

        </section>
    </ng-container>
</div>
