import { Component, Input } from '@angular/core';

import { ITableColumn } from '../../shared/table/table.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-final-paper-protocol-files',
    templateUrl: './final-paper-protocol-files.component.html'
})
export class FinalPaperProtocolFilesComponent {
    constructor(
        private modal: NgbActiveModal
    ) { }

    @Input() title: string;
    @Input() files: { name: string, url: string }[] = [];

    readonly columns: ITableColumn[] = [
        { label: 'finalPaperProtocol_lblFile' }
    ];

    close() {
        this.modal.close();
    }
}
