<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/print-v2', text: 'print_balance_title_v2' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'print_balance_title_v2' | translate}}</h1>
        <aside *ngIf="!showStudentPicker">
            <button class="btn btn-outline" type="button" (click)="showStudentPicker = !showStudentPicker">
                <i class="fi fi-rs-user"></i>
                {{'print_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker (pick)="setStudent($event)" *ngIf="showStudentPicker"></app-person-picker>

    <ng-container *ngIf="!showStudentPicker">
        <div class="data-value" *ngIf="student && canSetStudent">
            <label>{{'print_lblStudent' | translate}}</label>
            <div>{{student.FirstName}} {{student.LastName}} ({{student.StudentId}})</div>
        </div>

        <app-print-balance-v2 [printBalance]="printBalance" [student]="student" *ngIf="printBalance && printView"></app-print-balance-v2>
        <app-print-pay-v2 [printBalance]="printBalance" *ngIf="printBalance && printUse && !printAccountMissing"></app-print-pay-v2>
        <app-print-action-v2 [printBalance]="printBalance" *ngIf="printBalance && printUse && !printAccountMissing"></app-print-action-v2>
    </ng-container>
</div>
