import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-ebus-session',
    templateUrl: './ebus-session.component.html'
})
export class EbusSessionComponent implements OnInit {
    constructor(
        private app: AppService
    ) { }

    @Input() url: string;
    @Input() theme: string;

    error: string;

    @ViewChild('container', { static: true }) private container: ElementRef;

    ngOnInit() {
        const ctr = this.container.nativeElement;

        if (this.url) {
            this.error = null;

            window['ebus'].renderEvents({
                url: this.url,
                target: ctr,
                resize: true,
                theme: this.theme || ''
            });
        } else {
            this.error = this.app.translate('ebus_sessionInitFailed');
        }
    }
}
