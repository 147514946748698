<ng-container *ngIf="resident">
    <header class="block-header">
        <h3>{{resident.Name}} {{resident.Surname}}</h3>
        <aside *ngIf="canEdit">
            <a [routerLink]="['/application'] | localize" [queryParams]="{ type: 'PersonData' }" class="btn btn-outline-primary">
                {{'planSummary_editPersonData' | translate}}
            </a>
        </aside>
    </header>

    <div class="row">
        <div class="data-value col-sm">
            <label>{{'planSummary_lblSpeciality' | translate}}</label>
            <div>
                <ng-container *ngIf="resident.SpecialityName; else noValue">
                    {{resident.SpecialityName}}
                </ng-container>
            </div>
        </div>

        <div class="data-value col-sm">
            <label>{{'planSummary_lblStatus' | translate}}</label>
            <div>
                <ng-container *ngIf="resident.Status; else noValue">
                    {{resident.Status}}
                </ng-container>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="data-value col-sm">
            <label>{{'planSummary_lblStudyYear' | translate}}</label>
            <div>
                <ng-container *ngIf="resident.Status; else noValue">
                    {{resident.StudyYear}}
                </ng-container>
            </div>
        </div>

        <div class="data-value col-sm">
            <label>{{'planSummary_lblPlannedEndDate' | translate}}</label>
            <div>
                <ng-container *ngIf="resident.StudyPlannedEndDate; else noValue">
                    {{(resident.StudyPlannedEndDate | appDate) || ('planSummary_undefined' | translate)}}
                </ng-container>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="data-value col-sm">
            <label>{{'planSummary_lblSupervisor' | translate}}</label>
            <div>
                <ng-container *ngIf="supervisorSet">
                    <ng-container *ngIf="supervisor; else noValue">
                        {{supervisor.FirstName}} {{supervisor?.LastName}}
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div class="data-value col-sm">
            <label>{{'planSummary_lblContactPerson' | translate}}</label>
            <div>
                <ng-container *ngIf="resident.ContactPersonName; else noValue">
                    {{resident.ContactPersonName}}
                </ng-container>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="data-value col-sm">
            <label>{{'planSummary_lblWorkplace' | translate}}</label>
            <div>
                <ng-container *ngIf="resident.WorkplaceName; else noValue">
                    {{resident.WorkplaceName}}
                    <a [href]="workplaceAppUrl" *ngIf="workplaceAppUrl">
                        <i class="fi fi-rs-edit"></i>
                    </a>
                </ng-container>
            </div>
        </div>

        <div class="data-value col-sm">
            <label>{{'planSummary_lblClinicalBase' | translate}}</label>
            <div>
                <ng-container *ngIf="resident.ClinicalBaseName; else noValue">
                    {{resident.ClinicalBaseName}}
                    <a [href]="clinicalBaseAppUrl" *ngIf="clinicalBaseAppUrl">
                        <i class="fi fi-rs-edit"></i>
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #noValue>{{'planSummary_undefined' | translate}}</ng-template>
