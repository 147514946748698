import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
    NgbConfig, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerConfig,
    NgbInputDatepickerConfig, NgbModalConfig, NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { BryntumSchedulerProModule } from '@bryntum/schedulerpro-angular';

import { environment } from '../environments/environment';

import localeLv from '@angular/common/locales/lv';
registerLocaleData(localeLv, environment.defaultLanguage);

import { routes } from './app.routes';

import { Location } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizeRouterModule, LocalizeParser, LocalizeRouterSettings, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';

import { EditorModule } from '@tinymce/tinymce-angular';

import { AppErrorHandler } from './core/AppErrorHandler';
import { DateInterceptor } from './core/DateInterceptor';
import { LanguageInterceptor } from './core/LanguageInterceptor';
import { AuthorizeInterceptor } from './core/AuthorizeInterceptor';
import { LoadControlInterceptor } from './core/LoadControlInterceptor';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormatStringPipe } from './pipes/format-string.pipe';
import { YearValidator } from './validators/year.validator';

import { AuthService } from './services/auth.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { StreamsComponent } from './features/streams/streams.component';
import { BC_coursesComponent } from './features/bc_courses/bc_courses.component';
import { BC_CoursesAddComponent } from './features/bc_courses/bc_courses-add.component';
import { EventsDialogComponent as BC_CoursesEventsDialogComponent } from './features/bc_courses/events-dialog.component';
import { HeaderComponent } from './features/header/header.component';
import { FooterComponent } from './features/footer/footer.component';
import { UsersComponent } from './features/users/users.component';
import { LogComponent } from './features/log/log.component';
import { HomeComponent } from './features/home/home.component';
import { PasswordRequestComponent } from './features/password/request.component';
import { StudentPasswordRequestComponent } from './features/password/student/request.component';
import { StudentPasswordResetComponent } from './features/password/student/reset.component';
import { EmployeePasswordRequestComponent } from './features/password/employee/request.component';
import { EmployeePasswordResetComponent } from './features/password/employee/reset.component';
import { IsicComponent } from './features/isic/isic.component';
import { RSTicketsComponent } from './features/rs-tickets/rs-tickets.component';
import { EmailAliasComponent } from './features/email-alias/email-alias.component';
import { MarksComponent } from './features/marks/marks.component';
import { StudentBalanceComponent } from './features/student-balance/student-balance.component';
import { StudentBalanceInvoiceComponent } from './features/student-balance/student-balance-invoice.component';
import { StudentBalancePaymentsComponent } from './features/student-balance/student-balance-payments.component';
import { SkillsComponent } from './features/skills/skills.component';
import { SkillDetailsComponent } from './features/skills/skill-details.component';
import { PrintV2Component } from './features/print-v2/print-v2.component';
import { PrintBalanceV2Component } from './features/print-v2/print-balance-v2/print-balance-v2.component';
import { PrintPayV2Component } from './features/print-v2/print-pay-v2/print-pay-v2.component';
import { PrintActionV2Component } from './features/print-v2/print-action-v2/print-action-v2.component';
import { ImpersonationComponent } from './features/impersonation/impersonation.component';
import { FormComponent as ApplicationFormComponent } from './features/application/form.component';
import { IndexComponent as ApplicationIndexComponent } from './features/application/index.component';
import { IndexBodyComponent as ApplicationIndexBodyComponent } from './features/application/index-body.component';
import { MyApplicationsComponent } from './features/application/my-applications.component';
import { ApplicationComponent } from './features/application/application.component';
import { CustomApplicationComponent } from './features/application/custom-application.component';
import { ApplicationCustomFieldComponent } from './features/application/custom-field.component';
import { ApplicationConfigListComponent } from './features/application-config/list.component';
import { ApplicationConfigFormComponent } from './features/application-config/form.component';
import { ReferenceComponent } from './features/application/reference.component';
import { StudentComponent as ApplicationStudentComponent } from './features/application/student.component';
import { PurchaseComponent } from './features/purchase/purchase.component';
import { IndexComponent as PurchaseIndexComponent } from './features/purchase/index.component';
import { IndexBodyComponent as PurchaseIndexBodyComponent } from './features/purchase/index-body.component';
import { MyPurchasesComponent } from './features/purchase/my-purchases.component';
import { OnCallShiftListComponent } from './features/on-call-shift/list.component';
import { OnCallShiftFormComponent } from './features/on-call-shift/form.component';
import { OnCallShiftEditComponent } from './features/on-call-shift/edit.component';
import { CertificateRequestsComponent } from './features/certificate-requests/certificate-requests.component';
import { CertificateRequestEmailPreviewComponent } from './features/certificate-requests/email-preview.component';
import { CertificateRequestSendEmailsComponent } from './features/certificate-requests/send-emails.component';
import { TimeTableComponent } from './features/time-table/time-table.component';
import { FinalPaperComponent } from './features/final-paper/final-paper.component';
import { SaidEventsComponent } from './features/said-events/said-events.component';
import { SaidEventsAddComponent } from './features/said-events/said_events-add.component';
import { EventsDialogComponent } from './features/said-events/events-dialog.component';
import { IticComponent } from './features/itic/itic.component';
import { ExternalServiceConfigFormComponent } from './features/external-service-config/form.component';
import { ExternalServiceConfigListComponent } from './features/external-service-config/external-service-config-list.component';
import { ExternalServiceComponent } from './features/external-service/external-service.component';
import { ClassifierEditComponent } from './features/classifiers/classifier-edit.component';
import { ClassifierTypesComponent } from './features/classifiers/classifier-types.component';
import { ClassifierListComponent } from './features/classifiers/classifier-list.component';
import { ParameterEditComponent } from './features/parameters/parameter-edit.component';
import { ParameterListComponent } from './features/parameters/parameter-list.component';
import { PermissionEditComponent } from './features/permissions/permission-edit.component';
import { PermissionListComponent } from './features/permissions/permission-list.component';
import { CustomNgbDateAdapter, CustomNgbDateParserFormatter } from './core/CustomNgbDate';
import { MessageListComponent } from './features/messages/message-list.component';
import { MessageEditComponent } from './features/messages/message-edit.component';
import { TemplateEditComponent } from './features/templates/template-edit.component';
import { TemplateListComponent } from './features/templates/template-list.component';
import { CustomLocalizeRouterService } from './core/CustomLocalizeRouterService';
import { ManipulationEditComponent } from './features/manipulations/edit.component';
import { ManipulationFormComponent } from './features/manipulations/form.component';
import { ManipulationListComponent } from './features/manipulations/list.component';
import { TileListComponent } from './features/tiles/tile-list.component';
import { TileEditComponent } from './features/tiles/tile-edit.component';
import { DateValidator } from './validators/date.validator';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { TableComponent } from './shared/table/table.component';
import { SelectComponent } from './shared/select/select.component';
import { CheckboxComponent } from './shared/checkbox/checkbox.component';
import { RadioComponent } from './shared/radio/radio.component';
import { AutocompleteComponent } from './shared/autocomplete/autocomplete.component';
import { FileComponent } from './shared/file/file.component';
import { DateComponent } from './shared/date/date.component';
import { AlertComponent } from './shared/alert/alert.component';
import { TileComponent } from './shared/tile/tile.component';
import { TilesComponent } from './shared/tiles/tiles.component';
import { AlertDialogComponent } from './shared/dialog/alert-dialog.component';
import { CommonDialogComponent } from './shared/dialog/common-dialog.component';
import { AddressComponent } from './shared/address/address.component';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog.component';
import { WaitDialogComponent } from './shared/dialog/wait-dialog.component';
import { PictureComponent } from './shared/picture/picture.component';
import { PersonPickerComponent } from './shared/person-picker/person-picker.component';
import { TreeViewComponent } from './shared/treeview/treeview.component';
import { ToggleButtonComponent } from './shared/toggle-button/toggle-button.component';
import { AppDatePipe } from './pipes/date.pipe';
import { PlanSummaryComponent } from './features/plan-summary/plan-summary.component';
import { SearchComponent } from './features/search/search.component';
import { AddGradesComponent } from './features/add-grades/add-grades.component';
import { HospitalDashboardComponent } from './features/hospital-dashboard/hospital-dashboard.component';
import { HospitalDashboardStudentsComponent } from './features/hospital-dashboard/students/hospital-dashboard-students.component';
import { HospitalDashboardSeminarsComponent } from './features/hospital-dashboard/seminars/hospital-dashboard-seminars.component';
import { HospitalDashboardDoctorsComponent } from './features/hospital-dashboard/doctors/hospital-dashboard-doctors.component';
import { PlanSummaryEventsComponent } from './features/plan-summary/events/plan-summary-events.component';
import { EbusSessionConfigListComponent } from './features/ebus-session-config/list.component';
import { EbusSessionConfigFormComponent } from './features/ebus-session-config/form.component';
import { DoctorDashboardComponent } from './features/doctor-dashboard/doctor-dashboard.component';
import { DoctorDashboardSeminarsComponent } from './features/doctor-dashboard/seminars/doctor-dashboard-seminars.component';
import { DoctorDashboardTasksComponent } from './features/doctor-dashboard/tasks/doctor-dashboard-tasks.component';
import { SeminarAttendanceComponent } from './features/seminar/attendance/seminar-attendance.component';
import { PlanSummaryCoursesComponent } from './features/plan-summary/courses/plan-summary-courses.component';
import { PlanSummaryManipulationsComponent } from './features/plan-summary/manipulations/plan-summary-manipulations.component';
import { PlanSummarySeminarsComponent } from './features/plan-summary/seminars/plan-summary-seminars.component';
import { PlanSummaryShiftsComponent } from './features/plan-summary/shifts/plan-summary-shifts.component';
import { PlanSummaryBaseInfoComponent } from './features/plan-summary/base-info/plan-summary-base-info.component';
import { EbusSessionComponent } from './features/ebus-session/ebus-session.component';
import { SeminarAttendanceFormComponent } from './features/seminar/attendance/seminar-attendance-form.component';
import { SeminarAttendanceModalComponent } from './features/seminar/attendance/seminar-attendance-modal.component';
import { HospitalDashboardRotationsComponent } from './features/hospital-dashboard/rotations/hospital-dashboard-rotations.component';
import { DoctorDashboardRotationsComponent } from './features/doctor-dashboard/rotations/doctor-dashboard-rotations.component';
import { PlanSummaryQualityEvaluationComponent } from './features/plan-summary/quality-evaluation/plan-summary-quality-evaluation.component';
import { PlanSummaryQualityEvaluationFormComponent } from './features/plan-summary/quality-evaluation/plan-summary-quality-evaluation-form.component';
import { FinalPaperReviewListComponent } from './features/final-paper-review/final-paper-review-list.component';
import { FinalPaperReviewComponent } from './features/final-paper-review/final-paper-review.component';
import { FinalPaperReviewHomeComponent } from './features/final-paper-review/final-paper-review-home.component';
import { FinalPaperProtocolListComponent } from './features/final-paper-review/final-paper-protocol-list.component';
import { CourseEqualizationListComponent } from './features/course-equalization/list/course-equalization-list.component';
import { CourseEqualizationFormComponent } from './features/course-equalization/form/course-equalization-form.component';
import { CourseEqualizationOuterComponent } from './features/course-equalization/outer/course-equalization-outer.component';
import { CourseEqualizationInnerComponent } from './features/course-equalization/inner/course-equalization-inner.component';
import { CourseEqualizationApprovalComponent } from './features/course-equalization/approval/course-equalization-approval.component';
import { DeleteGradeComponent } from './features/add-grades/delete-grade.component';
import { FinalPaperProtocolFilesComponent } from './features/final-paper-review/final-paper-protocol-files.component';
import { DoctorDashboardStudentsComponent } from './features/doctor-dashboard/students/doctor-dashboard-students.component';
import { DoctorDashboardSeminarEditComponent } from './features/doctor-dashboard/seminars/doctor-dashboard-seminar-edit.component';
import { PortfolioComponent } from './features/portfolio/portfolio.component';
import { PortfolioAddComponent } from './features/portfolio/add/portfolio-add.component';
import { PortfolioDataComponent } from './features/portfolio/data/portfolio-data.component';
import { PortfolioViewComponent } from './features/portfolio/view/portfolio-view.component';
import { PortfolioShareComponent } from './features/portfolio/share/portfolio-share.component';
import { PortfolioSharedComponent } from './features/portfolio/shared/portfolio-shared.component';
import { StudyQualityEvaluationChangeConditionComponent } from './features/plan-summary/quality-evaluation/study-quality-evaluation-change-condition/study-quality-evaluation-change-condition.component';
import { StudyQualityEvaluationRejectEvaluationComponent } from './features/plan-summary/quality-evaluation/study-quality-evaluation-reject-evaluation/study-quality-evaluation-reject-evaluation.component';
import { SurveyListComponent } from './features/survey/list/survey-list.component';
import { SurveyStartComponent } from './features/survey/form/survey-start.component';
import { SurveyQuestionGroupComponent } from './features/survey/form/survey-question-group.component';
import { SurveyQuestionGridComponent } from './features/survey/form/survey-question-grid.component';
import { SurveyQuestionFormComponent } from './features/survey/form/survey-question-form.component';
import { SurveyQuestionComponent } from './features/survey/form/survey-question.component';
import { SurveyBeginComponent } from './features/survey/form/survey-begin.component';
import { PlanSummaryAddLecturerComponent } from './features/plan-summary/add-lecturer/plan-summary-add-lecturer.component';
import { PlanSummaryRemoveLecturerComponent } from './features/plan-summary/remove-lecturer/plan-summary-remove-lecturer.component';
import { PlanSummaryDocumentsComponent } from './features/plan-summary/documents/plan-summary-documents.component';
import { ParameterService } from './services/parameter.service';

function httpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
    return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

function getDefaultLanguage(languages: string[], cachedLang?: string, browserLang?: string) {
    return environment.defaultLanguage;
}

function appInitializerFactory(auth: AuthService, params: ParameterService) {
    return () =>
        new Promise<any>((resolve: any) => {
            const lang = sessionStorage.getItem('language') || environment.defaultLanguage;
            const path = location.pathname || '/';
            const pathParts = path.split('/');
            const hasLang = pathParts[1].length === 2;

            if (!hasLang) {
                const newPath = lang + (path == '/' ? '' : path) + (location.search && location.search);
                console.log('No language supplied. Redirect to ' + newPath + '.');
                location.href = newPath;
            } else {
                // On successfull login the app receives a session ID and the cookie,
                // so try to authenticate on the client side as early as possible.
                const url = new URL(document.URL);
                auth.authenticate(url.searchParams.get('sid')).then(() => {
                    console.log('Is authenticated: ' + (auth.isAuthenticated ? 'yes' : 'no') + '.');
                    params.getValues().subscribe(() => {
                        resolve(null);
                    }, err => {
                        throw Error('Failed to fetch application parameters.');
                    });
                });
            }
        });
}

@NgModule({
    declarations: [
        SafeHtmlPipe,
        AppDatePipe,
        FormatStringPipe,
        YearValidator,
        DateValidator,
        BreadcrumbsComponent,
        TableComponent,
        SelectComponent,
        CheckboxComponent,
        RadioComponent,
        AutocompleteComponent,
        FileComponent,
        DateComponent,
        AlertComponent,
        TileComponent,
        TilesComponent,
        AppComponent,
        LoginComponent,
        ClassifierTypesComponent,
        ClassifierListComponent,
        ClassifierEditComponent,
        ParameterListComponent,
        ParameterEditComponent,
		StreamsComponent,
		BC_coursesComponent,
        BC_CoursesAddComponent,
        BC_CoursesEventsDialogComponent,
        HeaderComponent,
        FooterComponent,
        AlertDialogComponent,
        CommonDialogComponent,
        AddressComponent,
        ConfirmDialogComponent,
        WaitDialogComponent,
        PictureComponent,
        UsersComponent,
        LogComponent,
        PermissionListComponent,
        PermissionEditComponent,
        HomeComponent,
        PasswordRequestComponent,
        StudentPasswordRequestComponent,
        StudentPasswordResetComponent,
        EmployeePasswordRequestComponent,
        EmployeePasswordResetComponent,
        MessageListComponent,
        MessageEditComponent,
        RSTicketsComponent,
        IsicComponent,
        EmailAliasComponent,
        StudentBalanceComponent,
        StudentBalanceInvoiceComponent,
        StudentBalancePaymentsComponent,
		PrintBalanceV2Component,
		PrintV2Component,
		PrintPayV2Component,
		PrintActionV2Component,
        MarksComponent,
        ImpersonationComponent,
        ApplicationFormComponent,
        ApplicationIndexComponent,
        ApplicationIndexBodyComponent,
        ApplicationConfigListComponent,
        ApplicationConfigFormComponent,
        ApplicationComponent,
        CustomApplicationComponent,
        ApplicationCustomFieldComponent,
        ReferenceComponent,
        MyApplicationsComponent,
        ApplicationStudentComponent,
        TemplateListComponent,
        TemplateEditComponent,
        PersonPickerComponent,
        PurchaseComponent,
        PurchaseIndexComponent,
        PurchaseIndexBodyComponent,
        MyPurchasesComponent,
        TreeViewComponent,
        CertificateRequestsComponent,
        CertificateRequestEmailPreviewComponent,
        CertificateRequestSendEmailsComponent,
        TimeTableComponent,
        FinalPaperComponent,
        FinalPaperReviewComponent,
        FinalPaperReviewListComponent,
        FinalPaperReviewHomeComponent,
        FinalPaperProtocolListComponent,
        FinalPaperProtocolFilesComponent,
        ToggleButtonComponent,
        SkillsComponent,
        SkillDetailsComponent,
        SaidEventsComponent,
        SaidEventsAddComponent,
        EventsDialogComponent,
        IticComponent,
        ExternalServiceConfigFormComponent,
        ExternalServiceConfigListComponent,
        ExternalServiceComponent,
        OnCallShiftListComponent,
        OnCallShiftFormComponent,
        OnCallShiftEditComponent,
        ManipulationListComponent,
        ManipulationFormComponent,
        ManipulationEditComponent,
        TileListComponent,
        TileEditComponent,
        PlanSummaryComponent,
        PlanSummaryBaseInfoComponent,
        PlanSummaryCoursesComponent,
        PlanSummaryManipulationsComponent,
        PlanSummarySeminarsComponent,
        PlanSummaryShiftsComponent,
        PlanSummaryEventsComponent,
        PlanSummaryQualityEvaluationComponent,
        PlanSummaryQualityEvaluationFormComponent,
        SurveyListComponent,
        SurveyBeginComponent,
        SurveyStartComponent,
        SurveyQuestionComponent,
        SurveyQuestionGroupComponent,
        SurveyQuestionFormComponent,
        SurveyQuestionGridComponent,
        PlanSummaryAddLecturerComponent,
        PlanSummaryRemoveLecturerComponent,
        SearchComponent,
        AddGradesComponent,
        DeleteGradeComponent,
        HospitalDashboardComponent,
        HospitalDashboardStudentsComponent,
        HospitalDashboardSeminarsComponent,
        HospitalDashboardDoctorsComponent,
        HospitalDashboardRotationsComponent,
        EbusSessionComponent,
        EbusSessionConfigListComponent,
        EbusSessionConfigFormComponent,
        DoctorDashboardComponent,
        DoctorDashboardSeminarsComponent,
        DoctorDashboardTasksComponent,
        DoctorDashboardStudentsComponent,
        DoctorDashboardRotationsComponent,
        DoctorDashboardSeminarEditComponent,
        SeminarAttendanceComponent,
        SeminarAttendanceFormComponent,
        SeminarAttendanceModalComponent,
        CourseEqualizationFormComponent,
        CourseEqualizationOuterComponent,
        CourseEqualizationInnerComponent,
        CourseEqualizationListComponent,
        CourseEqualizationApprovalComponent,
        PortfolioComponent,
        PortfolioAddComponent,
        PortfolioDataComponent,
        PortfolioViewComponent,
        PortfolioShareComponent,
        PortfolioSharedComponent,
        StudyQualityEvaluationChangeConditionComponent,
        StudyQualityEvaluationRejectEvaluationComponent,
        PlanSummaryDocumentsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: function (httpClient: HttpClient) {
                    return new TranslateHttpLoader(httpClient);
                },
                deps: [HttpClient]
            }
        }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: httpLoaderFactory,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            },
            defaultLangFunction: getDefaultLanguage
        }),
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        BrowserAnimationsModule,
        ImageCropperModule,
        EditorModule,
        ImageCropperModule,
        NgbModule,
        BryntumSchedulerProModule
    ],
    exports: [
        FormsModule,
        SafeHtmlPipe,
        AppDatePipe,
        ToggleButtonComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [AuthService, ParameterService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        },
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (translateService) => {
                return translateService.currentLang || environment.defaultLanguage;
            }
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        // currently commented out - need to check all service requests
        //{ provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadControlInterceptor, multi: true },
        {
            deps: [NgbConfig],
            provide: NgbModalConfig, useFactory: (def: NgbConfig) => {
                const config = new NgbModalConfig(def);

                config.animation = true;
                config.centered = true;
                config.backdrop = 'static';

                return config;
            }
        },
        { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
        { provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
        {
            provide: NgbDatepickerConfig, useFactory: () => {
                const value = new NgbDatepickerConfig();
                value.navigation = 'arrows';
                return value;
            }
        },
        {
            provide: NgbInputDatepickerConfig, useFactory: () => {
                const value = new NgbInputDatepickerConfig();
                value.placement = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];
                return value;
            }
        },
        {
            provide: LocalizeRouterService,
            deps: [LocalizeParser, LocalizeRouterSettings, Router, ActivatedRoute],
            useClass: CustomLocalizeRouterService
        },
        AppDatePipe,
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
