import { Component, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { Classifier } from '../../models/Classifier';
import { AppService } from '../../services/app.service';
import { ClassifierService } from '../../services/classifier.service';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

interface IGroup {
    id: string;
    title: string;
    code: string;
    items: IEntry[];
    sortOrder: number;
    isHidden?: boolean;
}

interface IEntry {
    title: string;
    code: string;
    isHidden?: boolean;
}

let groupCache: Classifier[];
let typeCache: Classifier[];
let groupState: { [key: string]: boolean } = {};

export function clearCache() {
    groupCache = undefined;
    typeCache = undefined;
}

export function getGroups() {
    return groupCache || [];
}

export function getTypes() {
    return typeCache || [];
}

@Component({
  selector: 'app-classifier-types',
    templateUrl: './classifier-types.component.html'
})
export class ClassifierTypesComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ClassifierService
    ) { }

    groups: IGroup[] = [];
    openedGroupIds: string[] = [];

    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            term = (term || '').toLowerCase();

            if (term.length > 2) {
                this.groups.forEach(t => {
                    let hide = true;

                    t.items.forEach(n => {
                        n.isHidden = !(n.title || '').toLowerCase().includes(term);

                        if (!n.isHidden) {
                            hide = false;
                        }
                    });

                    t.isHidden = hide;
                });
            } else {
                this.groups.forEach(t => {
                    t.isHidden = false;
                    t.items.forEach(n => {
                        n.isHidden = false;
                    });
                });
            }
        });

        this.init();
    }

    onPanelChange(event: NgbPanelChangeEvent) {
        groupState[event.panelId] = event.nextState;
    }

    search(event) {
        this.searchSubj.next(event.target.value);
    }

    getVisibleGroups() {
        return this.groups.filter(t => !t.isHidden);
    }

    getVisibleItems(group: IGroup) {
        return group.items.filter(t => !t.isHidden);
    }

    private init() {
        this.getGroups().subscribe(groupData => {
            this.getTypes().subscribe(typeData => {
                const types = typeData.map(t => {
                    let payload: any;

                    try {
                        payload = JSON.parse(t.Payload);
                    } catch (err) { }

                    if (!payload) {
                        payload = {};
                    }

                    return {
                        code: t.Code,
                        title: t.Value,
                        group: payload.Group
                    };
                });

                const groups: IGroup[] = groupData.map(t => {
                    let payload: any;

                    try {
                        payload = JSON.parse(t.Payload);
                    } catch (err) { }

                    if (!payload) {
                        payload = { SortOrder: 0 };
                    }

                    return <IGroup>{
                        id: t.Id,
                        code: t.Code,
                        items: types.filter(k => k.group === t.Code).map(k => {
                            return {
                                code: k.code,
                                title: k.title
                            };
                        }),
                        sortOrder: +payload.SortOrder,
                        title: t.Value
                    };
                });

                groups.sort((a, b) => a.sortOrder - b.sortOrder);

                groups.forEach(t => {
                    t.items.sort((a, b) => (a.title || a.code).localeCompare(b.title || b.code));

                    if (groupState[t.id] === true) {
                        this.openedGroupIds.push(t.id);
                    }
                });

                this.groups = groups;
            });
        });
    }

    private getGroups() {
        if (groupCache) {
            return of(groupCache);
        } else {
            return this.app.addLoading(this.service.get('ClassifierGroup')).pipe(tap((data: Classifier[]) => {
                groupCache = data;
            }));
        }
    }

    private getTypes() {
        if (typeCache) {
            return of(typeCache);
        } else {
            return this.app.addLoading(this.service.get('ClassifierType')).pipe(tap(data => {
                typeCache = data;
            }));
        }
    }
}
