<div class="modal-header">
    <h4 class="modal-title">{{'planSummary_removeLecturer_title' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="data-value">
            <label>{{'planSummary_courses_lblCourse' | translate}}</label>
            <div>{{rotation?.Name}} ({{rotation?.Code}})</div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'planSummary_removeLecturer_lblLecturer' | translate}}</label>
            <app-select [(ngModel)]="selected"
                        name="selected"
                        [options]="rotation?.Lecturers || []"
                        [required]="true"
                        [displayFn]="displayFn">
            </app-select>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button class="btn btn-primary" (click)="submit()" type="button" [disabled]="isSaving || !form.valid">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'remove' | translate}}
    </button>
</div>
