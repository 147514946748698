import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SkillDetailItem } from '../../models/Said_events';

@Component({
    templateUrl: './events-dialog.component.html'
})
export class EventsDialogComponent {
    constructor(private activeModal: NgbActiveModal) {}

    @Input() set data(value: { details: SkillDetailItem[] }) {
        this.details = value.details.filter(d => d.Label.toLowerCase() != "header");

        if (value.details && value.details.length) {
            this.detail = value.details.find(d => (d.Label.toLowerCase() == "header"));
        }
    }

    details: SkillDetailItem[] = [];
    detail: SkillDetailItem;

    close() {
        this.activeModal.dismiss();
    }
}
