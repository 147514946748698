import { Component, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { AppService } from '../../../services/app.service';
import { PrintBalanceGetResult } from '../../../models/StudentBalance';
import { numberFormat } from '../print-v2.component';
import { IPersonSearchResultItem } from '../../../models/Person';

@Component({
    selector: 'app-print-balance-v2',
    templateUrl: './print-balance-v2.component.html'
})
export class PrintBalanceV2Component {
    constructor(
        public app: AppService,
        private decimalPipe: DecimalPipe
    ) { }

    private _printBalance: PrintBalanceGetResult;

    @Input() set printBalance(printBalance: PrintBalanceGetResult) {
        this._printBalance = printBalance;
        if (printBalance) {
			let amount = this.decimalPipe.transform(printBalance.Amount, this.numberFormat);
            this.remainingBalance = amount == null ? this.decimalPipe.transform(0, this.numberFormat).replace(',', '.') : amount.replace(',', '.');
			this.printAccountMissing = printBalance.Status !== 1;
        }
    }

    @Input() student: IPersonSearchResultItem;

    get printBalance(): PrintBalanceGetResult {
        return this._printBalance;
    }

    remainingBalance: string;
    numberFormat = numberFormat;
    printAccountMissing: boolean = false;
}
