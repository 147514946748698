<div class="modal-header">
    <h4 class="modal-title">{{'edit' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'doctorDashboard_seminars_lblDate' | translate}}</label>
                <app-date [(ngModel)]="model.Date" name="date" [required]="true"></app-date>
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'doctorDashboard_seminars_lblTime' | translate}}</label>
                <input class="form-control" [(ngModel)]="time" name="time" [required]="true" maxlength="5"
                       placeholder="hh:mm" pattern="^([01][0-9]|2[0-3]):[0-5][0-9]$" />
            </div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'doctorDashboard_seminars_lblPlace' | translate}}</label>
            <app-autocomplete [(ngModel)]="model.CompanyId"
                              name="companyId"
                              limit="100"
                              [options]="companies"
                              [displayProperty]="'Name'"
                              [valueProperty]="'Id'"
                              [required]="true"
                              (search)="filterCompanies($event)">
            </app-autocomplete>
            <div class="feedback">
                {{'doctorDashboard_seminars_companyHint' | translate}}
            </div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'doctorDashboard_seminars_lblTheme' | translate}}</label>
            <input class="form-control" name="topic" [(ngModel)]="model.Topic" required maxlength="500" />
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button class="btn btn-primary" (click)="save()" type="button" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'save' | translate}}
    </button>
</div>
