import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IOnCallShiftReportListItem } from '../../models/OnCallShift';
import { AppService } from '../../services/app.service';
import { OnCallShiftService } from '../../services/on-call-shift.service';
import { ITableColumn } from '../../shared/table/table.component';
import { Utils } from '../../core/Utils';
import { IPersonSearchResultItem } from '../../models/Person';
import { userRights } from './edit.component';
import { store } from './store';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PersonService } from '../../services/person.service';

interface IShiftGroup {
    name: string;
    month: number;
    hours: number;
    dates: NgbDate[];
    isOpened?: boolean;
}

interface IReportRow extends IOnCallShiftReportListItem {
    shiftsByMonth: IShiftGroup[];
}

@Component({
    selector: 'app-on-call-shift-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.scss']
})
export class OnCallShiftListComponent implements OnInit, OnDestroy {
    constructor(
        private app: AppService,
        private service: OnCallShiftService,
        private persons: PersonService,
        private route: ActivatedRoute
    ) { }

    items: IReportRow[] = [];
    canPickStudent: boolean;
    studentPickerOpened: boolean;
    student: IPersonSearchResultItem;

    readonly canEdit = this.app.currentUser?.rights.includes('ON_CALL_SHIFT.EDIT');

    readonly columns: ITableColumn[] = [
        { property: 'Status', label: 'onCallShift_lblStatus', sorts: true },
        { property: 'StudyYear', label: 'onCallShift_lblStudyYear', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'Speciality', label: 'onCallShift_lblSpeciality', sorts: true, cssClass: 'hidden-md-down' },
        { label: 'onCallShift_lblDates', cssClass: 'hidden-sm-down' },
        { property: 'PeriodFrom', label: 'onCallShift_lblPeriod', sorts: true, type: 'date' },
        { property: 'TotalHours', label: 'onCallShift_lblTotalHours', sorts: true, type: 'number', cssClass: 'hidden-xs-down' },
        { width: '1px', cssClass: 'hidden-sm-down' }
    ];

    private readonly monthShortNames = this.app.translate('monthShortNames').split(',');
    private clickSub: Subscription;

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf(userRights.setStudent) > -1;

            if (this.canPickStudent) {
                const queryUpn = this.route.snapshot.queryParams['id'];

                if (queryUpn) {
                    this.app.addLoading(this.persons.findStudents('student_id', queryUpn)).subscribe(data => {
                        if (data.length) {
                            this.setStudent(data[0]);
                            this.loadItems();
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
                } else {
                    this.student = store.getStudent();

                    if (this.student) {
                        this.loadItems();
                    } else {
                        this.toggleStudentPicker();
                    }
                }
            } else {
                this.loadItems();
            }
        }

        this.clickSub = this.app.onDocumentClick().subscribe(event => {
            if (!event.target.closest('.datepicker')) {
                this.items.forEach(t => {
                    t.shiftsByMonth.forEach(s => s.isOpened = false);
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.clickSub) {
            this.clickSub.unsubscribe();
        }
    }

    toggleStudentPicker() {
        if (this.studentPickerOpened) {
            this.studentPickerOpened = false;
        } else {
            this.studentPickerOpened = true;
            this.items = [];
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.loadItems();

        store.setStudent(student);
    }

    delete(item: IOnCallShiftReportListItem) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.loadItems();
            });
        });
    }

    isDateSelected(date: NgbDate, dates: NgbDate[]) {
        return dates.some(t => t.year == date.year && t.month == date.month && t.day == date.day);
    }

    toggleShiftsByMonth(group: IShiftGroup) {
        const state = group.isOpened;

        this.items.forEach(t => {
            t.shiftsByMonth.forEach(s => s.isOpened = false);
        });

        group.isOpened = !state;
    }

    private loadItems() {
        this.app.addLoading(this.service.get(this.student?.Email)).subscribe(data => {
            data.forEach(t => {
                t.PeriodFrom = Utils.getInputDate(t.PeriodFrom);
                t.PeriodTo = Utils.getInputDate(t.PeriodTo);
            });

            this.items = data.map(t => {
                const shiftsByMonth = this.getShiftsByMonth(t);
                return {
                    ...t,
                    shiftsByMonth
                }
            });
        });
    }

    private getShiftsByMonth(item: IOnCallShiftReportListItem) {
        const groups: IShiftGroup[] = [];

        item.Shifts.forEach(t => {
            const date = Utils.ensureDate(t.Date);
            const month = date.getMonth() + 1;

            let group = groups.find(t => t.month == month);

            if (!group) {
                group = {
                    month,
                    name: this.monthShortNames[month - 1],
                    hours: 0,
                    dates: [],
                    isOpened: false
                };
                groups.push(group);
            }

            group.dates.push(new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()));
            group.hours += t.Hours;
        });

        groups.sort((a, b) => a.month - b.month);

        return groups;
    }
}
