import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IIssuer, IPortfolio, IPortfolioListItem, IPortfolioShare } from '../models/Portfolio';
import { map } from 'rxjs/operators';
import { Utils } from '../core/Utils';

/**
 * Portfolio service
 */
@Injectable({ providedIn: "root" })
export class PortfolioService {
    constructor(private http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/portfolio` }

    /**
     * Get available issuers to import data from.
     */
    getIssuers(): Observable<IIssuer[]> {
        const url = `${this.apiUrl}/issuers`;
        return this.http.get<IIssuer[]>(url);
    }

    /**
     * Get saved portfolio list.
     */
    get(): Observable<IPortfolioListItem[]> {
        const url = `${this.apiUrl}`;
        return this.http.get<IPortfolioListItem[]>(url);
    }

    /**
     * Get specific portofio data.
     * @param id
     */
    getById(id: number): Observable<IPortfolio> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<IPortfolio>(url);
    }

    /**
     * Download portfolio attachment file.
     * @param id
     * @param index
     */
    downloadAttachment(id: number, index: string): Observable<any> {
        const url = `${this.apiUrl}/${id}/attachments/${index}/download`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }

    /**
     * Download portfolio XML file.
     * @param id
     */
    downloadXml(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/xml/download`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }

    /**
     * Intialize new portfolio session.
     */
    initSession(): Observable<{ sessionId: string, returnUrl: string }> {
        const url = `${this.apiUrl}/sessions`;
        return this.http.post<{ sessionId: string, returnUrl: string }>(url, null);
    }

    /**
     * Get pending portfolio data.
     */
    getPending(sessionId: string): Observable<IPortfolio> {
        const url = `${this.apiUrl}/sessions/${sessionId}`;
        return this.http.get<IPortfolio>(url);
    }

    /**
     * Submit pending portfolio.
     */
    submitPending(sessionId: string): Observable<number> {
        const url = `${this.apiUrl}/sessions/${sessionId}`;
        return this.http.post<number>(url, null);
    }

    /**
     * Cancel the pending portfolio.
     */
    cancelPending(sessionId: string): Observable<any> {
        const url = `${this.apiUrl}/sessions/${sessionId}`;
        return this.http.delete(url);
    }

    /**
     * Download pending portfolio attachment file.
     * @param index
     */
    downloadPendingAttachment(sessionId: string, index: string): Observable<any> {
        const url = `${this.apiUrl}/sessions/${sessionId}/attachments/${index}/download`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }

    /**
     * Download pending portfolio XML file.
     * @param sessionId
     */
    downloadPendingXml(sessionId: string): Observable<any> {
        const url = `${this.apiUrl}/sessions/${sessionId}/xml/download`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }

    /**
     * Share portfolio data.
     * @param options
     */
    share(options: {
        portfolioId: number,
        purpose: string,
        email: string,
        attachmentIndexes: string[],
        expiresInDays: number
    }): Observable<{ Url: string, Expires: Date }> {
        const url = `${this.apiUrl}/share`;
        return this.http.post<{ Url: string, Expires: Date }>(url, options).pipe(map(data => {
            data.Expires = Utils.ensureDate(data.Expires);
            return data;
        }));
    }

    /**
     * Get shared protfolio data.
     * @param id
     */
    getShare(id: string): Observable<IPortfolioShare> {
        const url = `${this.apiUrl}/share/${id}`;
        return this.http.get<IPortfolioShare>(url);
    }

    /**
     * Download shared portfolio attachments.
     * @param id
     */
    downloadShare(id: string): Observable<any> {
        const url = `${this.apiUrl}/share/${id}/download`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }
}
