<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/password/request', text: 'password_restore' },
                     { url: '/password/reset-employee', text: 'password_employeeRestore' }
                     ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{(responseSuccess ? 'password_restoresuccess' : responseError ? 'password_notresettitle' : 'password_employeeRestore') | translate}}
        </h1>
    </header>

    <form name="form" (ngSubmit)="f.valid && submit()" #f="ngForm" novalidate autocomplete="off">
        <ng-container class="success-container" *ngIf="responseSuccess">
            <app-alert level="success">
                <div [innerHTML]="successText | safeHtml"></div>
            </app-alert>

            <div class="mb-3">
                <a [routerLink]="['/password/request-employee'] | localize">{{'password_Reset' | translate}}</a>
            </div>
        </ng-container>

        <ng-container *ngIf="responseError">
            <app-alert level="danger">
                <div [innerHTML]="responseError | safeHtml"></div>
            </app-alert>

            <div class="mb-3">
                <a [routerLink]="['/password/request-employee'] | localize">{{'password_Reset' | translate}}</a>
            </div>
        </ng-container>

        <ng-container *ngIf="canReset && !responseSuccess && !responseError">
            <app-alert level="info" *ngIf="userInfo">
                <div [innerHTML]="userInfo | safeHtml"></div>
            </app-alert>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-field">
                        <label class="form-label">{{'password_newpassword' | translate}}</label>
                        <div class="fc-icons-end">
                            <input class="form-control"
                                   autocomplete="off"
                                   [type]="newPasswordVisible ? 'text' : 'password'"
                                   name="newPassword"
                                   [(ngModel)]="newPasswordValue"
                                   #newPassword="ngModel"
                                   title="{{'password_requirementsnotmet' | translate}}"
                                   required />
                            <button type="button" class="fc-icon-end" (click)="newPasswordVisible = !newPasswordVisible">
                                <i class="fi fi-rs-eye{{newPasswordVisible ? '' : '-crossed'}}"></i>
                            </button>
                        </div>
                        <div class="invalid-feedback" *ngIf="newPasswordValue && !isValidPassword">
                            {{'password_requirementsnotmet' | translate}}
                        </div>
                    </div>

                    <div class="form-field">
                        <label class="form-label">{{'password_newpasswordagain' | translate}}</label>
                        <div class="fc-icons-end">
                            <input class="form-control"
                                   autocomplete="off"
                                   [type]="newPasswordRepeatVisible ? 'text' : 'password'"
                                   name="newPasswordRepeat"
                                   [(ngModel)]="newPasswordRepeatValue"
                                   #newPasswordRepeat="ngModel"
                                   title="{{'password_requirementsnotmet' | translate}}"
                                   required
                                   [pattern]="newPasswordRepeatValue && newPasswordRepeatValue != newPasswordValue ? '$^' : '.*'" />
                            <button type="button" class="fc-icon-end" (click)="newPasswordRepeatVisible = !newPasswordRepeatVisible">
                                <i class="fi fi-rs-eye{{newPasswordRepeatVisible ? '' : '-crossed'}}"></i>
                            </button>
                        </div>
                        <div class="invalid-feedback" *ngIf="newPasswordRepeatValue && newPasswordRepeatValue != newPasswordValue">
                            {{'passwordsDontMatch' | translate}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-actions">
                <button class="btn btn-primary" type="submit" [disabled]="!f.valid">
                    {{'password_Reset' | translate}}
                </button>
            </div>

            <app-alert level="info">
                <div [innerHTML]="'password_requirements' | translate | safeHtml"></div>
            </app-alert>
        </ng-container>

        <div class="mb-3" [innerHTML]="'password_contactfooter' | translate | safeHtml"></div>
    </form>
</div>
