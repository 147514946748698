<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: isAuthenticated ? '/surveys' : undefined, text: 'surveys_title' },
                     { text: 'survey_fillTitle' }
                     ]"></app-breadcrumbs>

    <app-alert level="danger" *ngIf="error">{{error}}</app-alert>

    <form #form="ngForm" *ngIf="data" [class.was-validated]="isSubmitted">
        <header class="content-header">
            <h1>{{data.Survey.Title}}</h1>
        </header>

        <app-alert level="success" *ngIf="isFinished">
            <span [innerHTML]="'survey_success' | translate  | safeHtml"></span>
        </app-alert>

        <ng-container *ngIf="!isFinished">
            <app-survey-question *ngFor="let n of singles"
                                 [question]="n"
                                 [model]="model"
                                 [validate]="isSubmitted"></app-survey-question>

            <ng-container *ngFor="let n of groups">
                <app-survey-question-group *ngIf="groupIsVisible(n.group)"
                                           [group]="n.group"
                                           [questions]="n.questions"
                                           [model]="model"
                                           [validate]="isSubmitted"></app-survey-question-group>
            </ng-container>

            <div class="form-actions">
                <button class="btn btn-outline" type="button" (click)="cancel()">
                    {{'survey_btnCancel' | translate}}
                </button>

                <button class="btn btn-primary" type="button" (click)="submit()">
                    {{'survey_btnSubmit' | translate}}
                </button>
            </div>
        </ng-container>
    </form>
</div>
