export class Skill {
    Number: number;
    Date: Date;
    PersonName: string;
    PersonSurname: string;
    PersonBirthday: Date;
    PersonCode: string;
    Code: string;
    Name: string;
    Level: string;
    Category: string;
    SubjectCode: string;
    SubjectName: string;
    Value?: string;
    ValuePositive: boolean;
    LearnDate?: Date;
    ShowOnlyHighestLevel: boolean;
    IsPlanned: boolean;
    Id: string;
    ValueIsPositive: boolean;
	MoodleLink: string;
    IsAcquirable: boolean;
	SelectedNumber: string;
}

export class SkillDetails {
    Code: string;
    Name: string;
    Description: string;
    CategoryName: string;
    Keywords?: string;
    Level: string;
    Criteria: string[] = [];
    Resources: string;
    From: string;
	To: string;
}