import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IExternalServiceConfig, IExternalServiceConfigListItem, IExternalServiceConfigSaveModel } from '../models/ExternalServiceConfig';

/**
 * External service configuration service
 */
@Injectable({ providedIn: "root" })
export class ExternalServiceConfigService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/externalServiceConfig` }

    get(): Observable<IExternalServiceConfigListItem[]> {
        return this.http.get<IExternalServiceConfigListItem[]>(this.apiUrl);
    }

    getById(id: number): Observable<IExternalServiceConfig> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<IExternalServiceConfig>(url);
    }

    save(item: IExternalServiceConfigSaveModel): Observable<number> {
        return this.http.put<number>(this.apiUrl, item);
    }

    publish(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/publish`;
        return this.http.post(url, null);
    }

    unpublish(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/unpublish`;
        return this.http.post(url, null);
    }

    activate(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/activate`;
        return this.http.post(url, null);
    }

    deactivate(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/deactivate`;
        return this.http.post(url, null);
    }

    delete(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }
}
