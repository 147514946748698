import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ClassifierService } from '../../services/classifier.service';

import { UserListItem } from '../../models/UserInfo';
import { Classifier } from '../../models/Classifier';
import { ITableColumn } from '../../shared/table/table.component';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: UserService,
        private classifierService: ClassifierService
    ) { }

    readonly now = new Date();

    readonly columns: ITableColumn[] = [
        { property: 'UserName', label: 'userslist_lblUserName', sorts: true },
        { property: 'Name', label: 'userslist_lblName', sorts: true },
        { property: 'LastName', label: 'userslist_lblLastName', sorts: true },
        { property: 'Roles', label: 'userslist_lblRoles', sorts: true },
        { property: 'Created', label: 'userslist_lblCreatedDate', sorts: true, type: 'date' },
        { property: 'LastLogin', label: 'userslist_lblLastLoginDate', sorts: true, type: 'date' }
    ];

    items: UserListItem[] = [];
    userRoles: Classifier[];
    filterAccountType: string;
    filterRole: Classifier;
    searchValue: string;

    ngOnInit() {
        this.app.addLoading(this.classifierService.get('UserRole')).subscribe(roles => {
            roles.sort((a, b) => {
                return (a.Value || '').localeCompare(b.Value || '');
            });

            this.userRoles = roles;
            this.loadItems();
        });
    }

    filter() {
        this.loadItems();
    }

    getRoleByCode(code: string) {
        return this.userRoles.find(r => r.Code == code);
    }

    private loadItems() {
        this.app.addLoading(this.service.findUsers({
            Role: this.filterRole ? this.filterRole.Code : '',
            Search: this.searchValue || ''
        })).subscribe(data => {
            this.items = data;
        });
    }
}
