<form #filterForm="ngForm">
    <div class="row">
        <div class="col-md form-field">
            <app-select [options]="views"
                        [placeholder]="'hospitalDashboard_rotations_lblView' | translate"
                        [displayFn]="viewDisplayFn"
                        [required]="true"
                        [ngModel]="view"
                        name="view"
                        (selectionChange)="changeView($event)">
            </app-select>
        </div>

        <div class="col-md form-field">
            <app-select [options]="filterOptions.studyYear"
                        [placeholder]="'hospitalDashboard_rotations_lblStudyYear' | translate"
                        [(ngModel)]="filterValues.studyYear"
                        name="filterStudyYear"
                        (selectionChange)="filter()">
            </app-select>
        </div>

        <div class="col-md form-field">
            <app-select [options]="filterOptions.programName"
                        [placeholder]="'hospitalDashboard_rotations_lblSpeciality' | translate"
                        [(ngModel)]="filterValues.programName"
                        name="filterProgramName"
                        (selectionChange)="filter()">
            </app-select>
        </div>

        <div class="col-md form-field">
            <input class="form-control"
                   [placeholder]="'hospitalDashboard_rotations_lblSearch' | translate"
                   [(ngModel)]="filterValues.text"
                   name="filterText"
                   (input)="filter()">
        </div>
    </div>
</form>

<bryntum-scheduler-pro [columns]="schedulerConfig.columns"
                       [features]="schedulerConfig.features"
                       [startDate]="schedulerConfig.startDate"
                       [endDate]="schedulerConfig.endDate"
                       [autoHeight]="schedulerConfig.autoHeight"
                       [readOnly]="schedulerConfig.readOnly"
                       [weekStartDay]="schedulerConfig.weekStartDay"
                       [resources]="schedulerModel.resources"
                       [events]="schedulerModel.events"
                       [assignments]="schedulerModel.assignments"></bryntum-scheduler-pro>
