import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Classifier } from '../../models/Classifier';
import { ApplicationKind, Student } from '../../models/Application';
import { Skill } from '../../models/Skill';

import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ClassifierService } from '../../services/classifier.service';
import { ParameterService } from '../../services/parameter.service';
import {
    ApplicationComponentBase,
    addresseeTypeOther,
    addresseeTypeNone,
    typeOfReceiptEmail,
    refLanguageLv,
    refLanguageEn
} from './ApplicationComponentBase';
import { getSelectedSkills } from '../skills/skills.component';
import { Observable, of } from 'rxjs';
import { MessageService } from '../../services/message.service';
import { ITableColumn } from '../../shared/table/table.component';

interface ITypeOfReceiptPayload {
    AllowEditAddress: boolean;
    DefaultAddress: string;
}

interface IAddresseeType extends Classifier {
    language?: string;
    commentsRequired?: boolean;
}

@Component({
  selector: 'app-reference',
    templateUrl: './reference.component.html',
    styleUrls: ['./application.component.scss']
})
export class ReferenceComponent extends ApplicationComponentBase implements OnInit {
    constructor(
        public app: AppService,
        protected service: ApplicationService,
        protected classifiers: ClassifierService,
        protected messages: MessageService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected parameters: ParameterService
    ) {
        super(app, service, classifiers, messages, route, router);
    }

    get kind(): ApplicationKind {
        return ApplicationKind.Reference;
    }

	get isSmallWindow(): boolean {
        return window.innerWidth < 990;
    }

    addresseeType: IAddresseeType;
    addressee: string;
    semester: number;
    refLanguage: Classifier;
    typeOfReceipt: Classifier;
    numberOfCopies: number = 1;
    receiptAddress: string;
    personalReceiptAddress: string = '';
    comments: string;
    semesters: number[] = [];

    commentsRequired: boolean;
    languageLocked: boolean;

    addresseeTypeItems: IAddresseeType[] = [];
    refLanguageItems: Classifier[] = [];
    typeOfReceiptItems: Classifier[] = [];
    semesterItems: number[] = [];

    selectedSkills: Skill[] = [];
    studentUpn: string;

    readonly semesterOptionDisplayFn = (item: number) => {
        return item == -1
            ? this.app.translate('application_allStudyPeriod')
            : this.app.translate('application_semesterOption').replace('{0}', item.toString());
    };

    readonly skillColumns: ITableColumn[] = [
        { property: 'SelectedNumber', label: 'skills_lblNumber', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Name', label: 'skills_lblName', sorts: true },
        { property: 'Category', label: 'skills_lblCategory', sorts: true, type: 'date', cssClass: 'hidden-sm-down' },
        { property: 'Level', label: 'skills_lblLevel', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'LearnDate', label: 'skills_lblAcquiredDate', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { width: '1px', cssClass: 'hidden-lg-up' }
    ];

    get showAddresseeField(): boolean {
        return this.item.Type === 'Status';
    }

    get showSemesterField(): boolean {
        return this.item.Type === 'Grades';
    }

    get isSkillsRef(): boolean {
        return this.item.Type === 'Skills';
    }

    private typeOfReceiptPayloadCache: { [key: string]: ITypeOfReceiptPayload } = {};
    private expanded: any[] = [];

    ngOnInit() {
        super.ngOnInit();

        this.studentUpn = this.route.snapshot.queryParams['id'];

        if (this.isSkillsRef) {
            this.selectedSkills = getSelectedSkills();
			let count = 1;
			this.selectedSkills.forEach(skill => {
				skill.SelectedNumber = count + ".";
				count++;
			});
		}

        this.app.addLoading(this.classifiers.get('ApplicationRefLanguage,ApplicationAddresseeType,ApplicationTypeOfReceipt')).subscribe(data => {
            this.refLanguageItems = data.filter(t => t.Type === 'ApplicationRefLanguage');
            this.typeOfReceiptItems = data.filter(t => t.Type === 'ApplicationTypeOfReceipt');
            this.addresseeTypeItems = data.filter(t => t.Type === 'ApplicationAddresseeType').map(t => {
                const val: IAddresseeType = {
                    ...t
                };

                if (t.Payload) {
                    try {
                        const payload = JSON.parse(t.Payload);
                        val.language = payload.Language;
                        val.commentsRequired = payload.CommentsRequired;
                    } catch (e) { }
                }

                return val;
            });

            this.refLanguage = this.refLanguageItems.find(t => t.Code === (this.langEn ? refLanguageEn : refLanguageLv));
            this.typeOfReceipt = this.typeOfReceiptItems.find(t => t.Code === typeOfReceiptEmail);
        });       

        this.app.addLoading(this.parameters.getValues()).subscribe(data => {
            const para = data.find(t => t.Code == `ApplicationPersonalReceiptAddress${this.app.currentLanguage == 'en' ? 'En' : 'Lv'}`);

            if (para) {
                this.personalReceiptAddress = para.Value;
            }
        });
    }

    protected onSetStudentData(data: Student[]): Observable<Student[]> {
        if (data.length) {
            const st = data[0];
            this.receiptAddress = st.Email;
            this.setSemesters(st);
        }

        return of(data);
    }

    protected onSetProgramme(data: Student) {
        this.receiptAddress = data.Email;
        this.setSemesters(data);
    }

    protected onSubmit() {
        this.item.Addressee = this.addressee;
        this.item.AddresseeType = this.addresseeType && this.addresseeType.Code;
        this.item.Comments = this.comments;
        this.item.NumberOfCopies = this.numberOfCopies;
        this.item.ReceiptAddress = this.receiptAddress;
        this.item.RefLanguage = this.refLanguage && this.refLanguage.Code;
        this.item.TypeOfReceipt = this.typeOfReceipt && this.typeOfReceipt.Code;
        this.item.Semester = this.semester;

        if (this.isSkillsRef)
            this.item.SkillIds = this.selectedSkills.map(t => t.Id);

        return true;
    }

    protected getFormErrors() {
        const errors = super.getFormErrors();

        if (this.isSkillsRef && !this.selectedSkills.length) {
            errors.push({ error: this.app.translate('application_skillRequired') });
        }

        return errors;
    }

    cancel() {
        if (this.isSkillsRef) {
            const para: any = {};

            if (this.studentUpn)
                para.id = this.studentUpn;

            this.router.navigate([this.app.localizeRoute('/skills')], {
                queryParams: para
            });
        } else {
            super.cancel();
        }
    }

    getAddresseeTypeDisplayValue(addresseeType: Classifier): string {
        const isEn = this.refLanguage && this.refLanguage.Code === refLanguageEn;

        if (addresseeType.Code === addresseeTypeOther || addresseeType.Code === addresseeTypeNone)
            return isEn ? addresseeType.ValueEN : addresseeType.Value;

        return (isEn ? addresseeType.LegacyCode : addresseeType.Code).split('_').pop();
    }

    onAddresseeTypeChange(event) {
        const value: IAddresseeType = event;

        if (value.Code === addresseeTypeNone || value.Code === addresseeTypeOther)
            this.addressee = '';
        else if (value.Code !== addresseeTypeOther)
            this.addressee = this.refLanguage && this.refLanguage.Code === refLanguageEn ? value.ValueEN : value.Value;

        this.commentsRequired = value.commentsRequired;

        if (value.language) {
            this.refLanguage = this.refLanguageItems.find(t => t.Code == value.language);
            this.languageLocked = true;
        } else {
            this.languageLocked = false;
        }
    }

    onTypeOfReceiptChange(event) {
        this.receiptAddress = '';

        const defaultVal = this.getTypeOfReceiptPayload(this.typeOfReceipt).DefaultAddress;

        switch (defaultVal) {
            case '@declared': this.receiptAddress = this.selectedStudent.DeclaredAddress; break;
            case '@email': this.receiptAddress = this.selectedStudent.Email; break;
            case '@personally': this.receiptAddress = this.personalReceiptAddress; break;
        }
    }

    onRefLanguageChange(event) {
        const value: Classifier = event;
        const isEn = value.Code === refLanguageEn;

        if (this.addresseeType && this.addresseeType.Code !== addresseeTypeOther) {
            this.addressee = isEn ? this.addresseeType.ValueEN : this.addresseeType.Value;
        }
    }

    validateSemester(value: string): boolean {
        return /^[0-9]*$/.test(value) && this.semester >= this.selectedStudent.FirstSemester && this.semester <= this.selectedStudent.CurrentSemester;
    }

    isReceiptAddressEnabled(): boolean {
        if (this.typeOfReceipt) {
            return this.getTypeOfReceiptPayload(this.typeOfReceipt).AllowEditAddress;
        }

        return false;
    }

    toggle(row: any) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: any): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    private getTypeOfReceiptPayload(value: Classifier): ITypeOfReceiptPayload {
        if (this.typeOfReceiptPayloadCache[value.Id])
            return this.typeOfReceiptPayloadCache[value.Id];

        let payload: ITypeOfReceiptPayload;

        try {
            payload = <ITypeOfReceiptPayload>JSON.parse(value.Payload);
        } catch (err) {
            console.error('Failed to parse ApplicationTypeOfReceipt payload.');

            payload = {
                AllowEditAddress: false,
                DefaultAddress: ''
            };
        }

        this.typeOfReceiptPayloadCache[value.Id] = payload;

        return payload;
    }

    private setSemesters(data: Student) {
        const values = [];

        for (let i = data.FirstSemester || 0; i <= data.CurrentSemester || 0; i++)
            (i > 0) && values.push(i);

        this.semesters = values;
        this.semesterItems = [...values].concat(-1);
    }
}
