import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment as ENV } from '../../environments/environment';
import { IAuthUser } from '../models/Auth';
import { ImpersonationFoundStudent } from '../models/Impersonation';
import { AuthService } from './auth.service';

@Injectable({ providedIn: "root" })
export class ImpersonationService {
    constructor(private http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/impersonation` }

    start(user: ImpersonationFoundStudent): Observable<any> {
        return this.http.post(this.apiUrl, {
            UserId: user.Email,
            FirstName: user.FirstName,
            LastName: user.LastName
        }).pipe(tap((data: IAuthUser) => {
            this.auth.setUser(data);
        }));
    }

    end(): Observable<any> {
        return this.http.delete(this.apiUrl).pipe(tap((data: IAuthUser) => {
            this.auth.setUser(data);
        }));
    }
}
