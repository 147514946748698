<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/purchases', text: 'purchases_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'purchases_title'"></h1>
        <aside>
            <a class="btn btn-primary btn-icon-last" [routerLink]="['/purchases/my'] | localize">
                {{'myPurchases_title' | translate}}
                <i class="fi fi-rs-angle-right"></i>
            </a>
        </aside>
    </header>

    <div class="body-fix">
        <app-purchase-index-body [showNoItemAvailable]="true"></app-purchase-index-body>
    </div>
</div>
