<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/portfolio', text: 'portfolio_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'portfolio_title' | translate}}</h1>
        <aside>
            <a class="btn btn-outline-primary" [routerLink]="['/portfolio/add'] | localize" target="_blank">
                <i class="fi fi-rs-plus"></i>
                {{'portfolio_btnAdd' | translate}}
            </a>
        </aside>
    </header>

    <app-table [columns]="columns" [rows]="rows">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>{{row.Title}}</td>
                <td>{{row.Added | appDate:'datetime'}}</td>
                <td>
                    <div class="table-actions">
                        <button type="button"
                                class="btn btn-outline btn-icon"
                                [ngbTooltip]="'open' | translate"
                                [routerLink]="['/portfolio', row.Id] | localize">
                            <i class="fi fi-rs-angle-right"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
