import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddressFilter, IAddressResponse, IAddressUnit, ICountry } from '../models/Address';
import { environment as ENV } from '../../environments/environment';

/**
 * Address service
 */
@Injectable({ providedIn: "root" })
export class AddressService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/address` }

    get(filter: AddressFilter): Observable<IAddressResponse> {
        const params: any = filter || {};

        for (const k in params) {
            if (params[k] === undefined || params[k] === null) {
                params[k] = '';
            }
        }

        return this.http.get(this.apiUrl, { params }).pipe(map((res: string) => {
            const data = JSON.parse(res);

            const model: IAddressResponse = {
                Id: data.addr_elem_id,
                Apartments: this.toUnitArray(data.addr_elem_appartment),
                Cities: this.toUnitArray(data.addr_elem_city),
                Districts: this.toUnitArray(data.addr_elem_county),
                FullText: data.addr_elem_text,
                Houses: this.toUnitArray(data.addr_elem_house),
                Parishes: this.toUnitArray(data.addr_elem_parish),
                PostCodes: data.addr_elem_postcode,
                Streets: this.toUnitArray(data.addr_elem_street),
                Villages: this.toUnitArray(data.addr_elem_village)
            };

            return model;
        }));
    }

    getCountries(): Observable<ICountry[]> {
        const url = `${this.apiUrl}/countries`;
        return this.http.get<ICountry[]>(url);
    }

    private toUnitArray(units: { [key: string]: string } | string[]): IAddressUnit[] {
        if (!units) return null;

        const arr: IAddressUnit[] = [];

        if (units instanceof Array) {
            units.forEach(t => {
                arr.push({ Id: undefined, Value: t });
            });
        } else {
            for (const k in units) {
                arr.push({ Id: +k, Value: units[k] });
            }
        }

        return arr;
    }
}
