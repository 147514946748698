<div class="file {{id}}"
     [class.fc-icons-end]="!disabled"
     [class.fc-icons-start]="!!fileName"
     [class.file-disabled]="disabled"
     (click)="add()">
    <i class="fi fi-rs-file fc-icon-start" *ngIf="!!fileName"></i>

    <input readonly
           autocomplete="off"
           class="form-control text-truncate file-input"
           [class.is-invalid]="(required && !fileName) || isOversized || isUnsupported"
           [id]="id"
           [name]="id"
           [placeholder]="placeholder"
           [title]="fileName || ''"
           [value]="fileName || ''"
           [disabled]="disabled">

    <input #fileInput
           type="file"
           [name]="id + '-ref'"
           [accept]="acceptString"
           (change)="onChange($event)"
           style="display: none">

    <i class="fi fi-rs-plus fc-icon-end" *ngIf="!fileName && !disabled"></i>

    <button *ngIf="fileName && !disabled" type="button" (click)="$event.stopPropagation(); remove()" class="fc-icon-end"
            [ngbTooltip]="'fileInputRemove' | translate">
        <i class="fi fi-rs-trash"></i>
    </button>
</div>
