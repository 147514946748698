import { Component, OnInit } from '@angular/core';

import { StreamsService } from '../../services/streams.service';
import { ClassifierService } from '../../services/classifier.service';
import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';
import { Classifier } from '../../models/Classifier';

interface IStream extends Classifier {
    title?: string;
    isActive?: boolean;
}

@Component({
	selector: 'app-parameters',
	templateUrl: './streams.component.html',
	styleUrls: ['./streams.component.css']
})
export class StreamsComponent implements OnInit {
	constructor(public app: AppService, private messagesService: MessageService,
		private streamService: StreamsService, private ClassifierService: ClassifierService,
		private route: ActivatedRoute) { }

    
    shownLiveStreams: IStream[] = [];
    stream: any = {};
    streamsOpened: boolean = false;
	msgClass: string = null;
	infoMsg: string = null;
    selectedStream: IStream;

    private allLiveStreams: IStream[] = [];
    private selectedStreamCode: string = null;

    ngOnInit() {
		this.app.addLoading(this.ClassifierService.get('Streams')).subscribe(data => {
			if (data.length > 0) {
                data.sort(this.orderBy("LegacyCode"));

				this.allLiveStreams = data;
				this.populateShownLiveStreams();

				this.route.params.subscribe(params => {
					let code = params['code'];
					if (code) {
						this.streamSelect(code);
					}
				});
			} else {
				this.msgClass = 'streams-info';
				this.getMsg('STREAM_EMPTY');
			}
		});
	}

	orderBy(prop) {
		return function (a, b) {
			if (a[prop] > b[prop]) {
				return 1;
			} else if (a[prop] < b[prop]) {
				return -1;
			}
			return 0;
		}
	}

	getMsg(code: string) {
		this.app.addLoading(this.messagesService.getByCode(code)).subscribe(msg => {
			if (msg) {
                this.infoMsg = this.app.currentLanguage == 'lv' ? msg.TextLV : msg.TextEN;
			} else {
				this.infoMsg = null;
			}
		});
	}

	populateShownLiveStreams() {
        for (let i = 0; i < this.allLiveStreams.length; i++) {
			let json = JSON.parse(this.allLiveStreams[i].Payload);
            if (json.DateTimeFrom && json.DateTimeTo) {
                let DateTimeFrom = this.dateFromString(json.DateTimeFrom);
                let DateTimeTo = this.dateFromString(json.DateTimeTo);
                let now = new Date();

                if (DateTimeFrom < now && now < DateTimeTo) {
                    this.shownLiveStreams.push(this.allLiveStreams[i]);
                }
            }
		}
	}

    onStreamSelect(stream: IStream) {
        if (this.selectedStream != stream) {
            this.streamSelect(stream.Code);
        }

        this.selectedStream = stream;
    }

    //toggleStreams() {
    //    this.streamsOpened = !this.streamsOpened;
    //}

    dateFromString(dateString: string) {
        var splitted: string[];
        if (dateString.indexOf('/')>-1)
            splitted = dateString.split("/", 3);
        else if (dateString.indexOf('-'))
            splitted = dateString.split("-", 3);
        else if (dateString.indexOf('.'))
            splitted = dateString.split(".", 3);
		var year = +splitted[0];
		var month = +splitted[1] - 1;

		var splitted2 = splitted[2].split(" ", 2);
		var date = +splitted2[0];

		var splitted3 = splitted2[1].split(":", 3);
		var hour = +splitted3[0];
		var minutes = +splitted3[1];
		var seconds = +splitted3[2];

		return new Date(year, month, date, hour, minutes, seconds);
    }

    private streamSelect(code: string) {
        this.selectedStreamCode = code;
        for (let i = 0; i < this.allLiveStreams.length; i++) {
            if (this.allLiveStreams[i].Code == this.selectedStreamCode) {
                this.app.addLoading(this.streamService.logStreamView(this.allLiveStreams[i].Code)).subscribe();

                let json = JSON.parse(this.allLiveStreams[i].Payload);

                let DateTimeFrom = this.dateFromString(json.DateTimeFrom);
                let DateTimeTo = this.dateFromString(json.DateTimeTo);
                let now = new Date();

                if (DateTimeFrom < now && now < DateTimeTo) {
                    this.infoMsg = null;
                    this.stream = json;
                }
                else if (DateTimeFrom > now) {
                    this.msgClass = 'streams-warning';
                    this.getMsg('STREAM_WILL_START_SOON');
                    this.stream = null;
                }
                else if (now > DateTimeTo) {
                    this.msgClass = 'streams-danger';
                    this.getMsg('STREAM_ENDED');
                    this.stream = null;
                }

                return;
            }
        }

        this.msgClass = 'streams-danger';
        this.getMsg('STREAM_NOT_FOUND');
    }
}
