import { Component, OnInit } from '@angular/core';

import { IApplicationConfigListItem } from '../../models/ApplicationConfig';

import { AppService } from '../../services/app.service';
import { ApplicationConfigService } from '../../services/application-config.service';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
    selector: 'app-application-config-list',
    templateUrl: './list.component.html'
})
export class ApplicationConfigListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: ApplicationConfigService
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'Code', label: 'applicationConfig_lblCode', sorts: true },
        { property: 'Status', label: 'applicationConfig_lblStatus', sorts: true },
        { property: 'Version', label: 'applicationConfig_lblVersion', sorts: true, type: 'number' },
        { property: 'Name', label: 'applicationConfig_lblName' },
        { width: '1px' }
    ];

    items: IApplicationConfigListItem[] = [];

    ngOnInit() {
        this.loadItems();
    }

    delete(item: IApplicationConfigListItem) {
        this.app.confirm(this.app.translate('applicationConfig_deleteConfirm').replace('{name}', item.Name), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.loadItems();
            });
        });
    }

    canDelete(item: IApplicationConfigListItem): boolean {
        return item.Status === 'Draft';
    }

    private loadItems() {
        this.app.addLoading(this.service.get()).subscribe(data => {
            this.items = data;
        });
    }
}
