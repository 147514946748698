import { Component, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { SurveyService } from '../../../services/survey.service';
import {
    ISurveyStart, ISurveyQuestion, ISurveyQuestionGroup,
    SurveyGroupsOperation, SurveyQuestionBlockType, SurveyQuestionGroupType
} from '../../../models/Survey';
import { ActivatedRoute } from '@angular/router';
import { SurveyUtils } from '../SurveyUtils';

@Component({
    selector: 'app-survey-start',
    templateUrl: './survey-start.component.html',
    styleUrls: ['../survey.component.scss']
})
export class SurveyStartComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: SurveyService,
        private route: ActivatedRoute
    ) { }

    data: ISurveyStart;
    error: string;
    isSubmitted: boolean;
    isFinished: boolean;
    isAuthenticated: boolean;

    readonly model: Record<string, any> = {};

    readonly groups: {
        group: ISurveyQuestionGroup,
        questions: ISurveyQuestion[]
    }[] = [];

    readonly singles: ISurveyQuestion[] = [];

    private id: string;
    private token: string;

    ngOnInit() {
        this.isAuthenticated = !!this.app.currentUser;

        this.id = this.route.snapshot.params.id;
        this.token = this.route.snapshot.queryParams.token;

        this.app.addLoading(this.service.start(this.id, this.token)).subscribe(data => {
            this.data = data;

            data.QuestionBlocks.forEach(qb => {
                if (qb.Type == SurveyQuestionBlockType.GroupedQuestions) {
                    const group = this.data.QuestionGroups.find(t => t.ID == qb.ID);
                    const questions = this.data.Questions.filter(t => group.Questions.includes(t.ID));

                    this.groups.push({
                        group,
                        questions
                    });
                } else {
                    const question = this.data.Questions.find(t => t.ID == qb.ID);
                    this.singles.push(question);
                }
            });
        }, err => {
            this.error = this.app.getHttpResponseError(err);
        });
    }

    submit() {
        this.isSubmitted = true;

        const answers = this.getAnswers();

        if (answers.some(t => !t.valid)) {
            this.app.alert.warning(this.app.translate('survey_requiredFieldsEmpty'));
            return;
        }

        const values = {};

        answers.forEach(t => {
            values[t.key] = t.value;
        });

        this.app.addLoading(this.service.submit(this.id, this.token, values)).subscribe(() => {
            this.isFinished = true;
        });
    }

    cancel() {
        this.app.navigateByUrl('/surveys');
    }

    groupIsVisible(group: ISurveyQuestionGroup): boolean {
        let result = true;

        if (group.Type == SurveyQuestionGroupType.Dependent) {
            group.DependencyRules.forEach(dr => {
                const values = [];

                if (this.model[dr.TestingAnswer] != undefined) {
                    values.push(...[].concat(this.model[dr.TestingAnswer]));
                }

                const success = SurveyUtils.checkCondition(dr.TestingOperator, values, dr.TestingValue);

                if (dr.GroupsOperation == SurveyGroupsOperation.And) {
                    result = result && success;
                } else {
                    result = result || success;
                }
            });
        }

        return result;
    }

    private getAnswers(): {
        questionId: string,
        key: string,
        value: any,
        valid: boolean
    }[] {
        const result: {
            questionId: string,
            key: string,
            value: any,
            valid: boolean
        }[] = [];

        const answersByQuestion: Record<string, { key: string, value: any }[]> = {};

        Object.keys(this.model).forEach(t => {
            const qId = SurveyUtils.parseQuestionKey(t)[0];

            if (!answersByQuestion[qId]) {
                answersByQuestion[qId] = [];
            }

            answersByQuestion[qId].push({
                key: t,
                value: this.model[t]
            });
        });

        for (let i = 0; i < this.data.Questions.length; i++) {
            const question = this.data.Questions[i];
            const group = this.groups.find(t => t.questions.some(n => n.ID == question.ID));

            if (!group || this.groupIsVisible(group.group)) {
                const answers = answersByQuestion[question.ID] || [];

                if (!answers.length) {
                    // each question should contain at least an empty answer to validate
                    answers.push(undefined);
                }

                for (let j = 0; j < answers.length; j++) {
                    const answer = answers[j];

                    result.push({
                        questionId: question.ID,
                        key: answer?.key,
                        value: answer?.value,
                        valid: SurveyUtils.validateAnswer(question, answer?.value)
                    });
                }
            }
        }

        return result;
    }
}
