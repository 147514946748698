<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/marks', text: 'marks_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'marks_title'"></h1>
        <aside *ngIf="canPickStudent && !showStudentPicker">
            <button type="button" class="btn btn-outline" (click)="showStudentPicker = !showStudentPicker">
                <i class="fi fi-rs-user"></i>
                {{'marks_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <ng-container *ngIf="isUnavailable && !showStudentPicker">
        <app-alert level="warning">
            <div [innerHTML]="unavailableText | safeHtml"></div>
        </app-alert>

        <app-person-picker *ngIf="showStudentPicker" (pick)="setStudent($event)"></app-person-picker>
    </ng-container>

    <ng-container *ngIf="!isUnavailable">
        <app-alert level="danger" *ngIf="isDenied">{{'eServiceNotAvailable' | translate}}</app-alert>

        <app-person-picker *ngIf="showStudentPicker" (pick)="setStudent($event)"></app-person-picker>

        <ng-container *ngIf="!isDenied && !showStudentPicker">
            <div class="data-value" *ngIf="student">
                <label>{{'marks_student' | translate}}</label>
                <div>{{student.FirstName}} {{student.LastName}} ({{student.StudentId}})</div>
            </div>

            <form #filterForm="ngForm">
                <div class="row">
                    <div class="col-md-6" *ngIf="studentPrograms && studentPrograms.length > 0">
                        <div class="form-field">
                            <app-select [placeholder]="'marks_studentPrograms' | translate"
                                        [(ngModel)]="selectedStudentProgram"
                                        name="StudentProgram"
                                        [required]="true"
                                        [options]="studentPrograms"
                                        [displayProperty]="'DisplayName'"
                                        (selectionChange)="filterByProgram()">
                            </app-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-field">
                            <app-select [placeholder]="'marks_semester' | translate"
                                        [(ngModel)]="selectedSemesterPlan"
                                        name="SemesterPlan"
                                        [required]="true"
                                        [options]="semesterPlans"
                                        [displayFn]="semesterPlanDisplayFn"
                                        (selectionChange)="filter()">
                                <ng-template #optionTemplate let-option>
                                    <small>{{option.Number}}. {{'marks_semestris' | translate}}</small>
                                    <div>{{option.DateFrom | appDate}} - {{option.DateTo | appDate}} {{option.Iscurrentsemester}}</div>
                                </ng-template>
                            </app-select>
                        </div>
                    </div>
                </div>
            </form>

            <app-table [rows]="items" [columns]="columns">
                <ng-template #rowTemplate let-row>
                    <tr>
                        <td>
                            <div [innerHTML]="getFormatedSubjectName(row) | safeHtml" class="hidden-sm-down"></div>
                            <div class="hidden-md-up subject-and-mark">
                                <div [innerHTML]="getFormatedSubjectName(row) | safeHtml"></div>
                                <div class="final-mark-container">
                                    <span class="final-mark" *ngIf="row.FinalMark && row.FinalMark.MarkValue && row.FinalMark.MarkValue!=''">
                                        {{getTranslatedMarkValue(row.FinalMark.MarkValue)}}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td class="hidden-sm-down">
                            <a *ngIf="row.SubjectCode && row.SubjectCode!=''" href="{{getMarksWiewUrl(row)}}"
                               target="_blank" [title]="'marks_opendescription' | translate" class="text-nowrap">
                                {{row.SubjectCode}}
                            </a>
                        </td>
                        <td class="hidden-lg-down">{{row.Block}}</td>
                        <td class="hidden-sm-down">{{row.TestType}}</td>
                        <td class="hidden-lg-down">{{row.Points}}</td>
                        <td class="hidden-sm-down">
                            <div *ngIf="row.FinalMark && row.FinalMark.MarkValue && row.FinalMark.MarkValue!=''">
                                <div>
                                    <strong>
                                        {{getTranslatedMarkValue(row.FinalMark.MarkValue)}}
                                    </strong>
                                </div>

                                <div *ngIf="row.FinalMark.MarkDate">
                                    {{row.FinalMark.MarkDate | appDate}}
                                </div>

                                <div *ngIf="row.FinalMark.Teacher && row.FinalMark.Teacher!=''" class="text-sub">
                                    {{row.FinalMark.Teacher}}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="table-actions">
                                <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'marks_showDetails' | translate">
                                    <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isExpanded(row)" class="table-row-details">
                        <td colspan="9">
                            <div class="dl">
                                <div class="hidden-md-up">
                                    <span>{{'marks_kursa_kods' | translate}}</span>
                                    <span>
                                        <a *ngIf="row.SubjectCode && row.SubjectCode!=''" href="{{getMarksWiewUrl(row)}}"
                                           target="_blank" [title]="'marks_opendescription' | translate" class="text-nowrap">
                                            {{row.SubjectCode}}
                                        </a>
                                    </span>
                                </div>

                                <div class="hidden-xl-up">
                                    <span>{{'marks_category' | translate}}</span>
                                    <span>{{row.Block}}</span>
                                </div>

                                <div class="hidden-md-up">
                                    <span>{{'marks_kontroles_veids' | translate}}</span>
                                    <span>{{row.TestType}}</span>
                                </div>

                                <div class="hidden-xl-up">
                                    <span>{{'marks_kp_sem' | translate}}</span>
                                    <span>{{row.Points}}</span>
                                </div>

                                <div class="hidden-md-up">
                                    <span>{{'marks_gala_vertejums' | translate}}</span>
                                    <span>
                                        <strong>
                                            {{getTranslatedMarkValue(row.FinalMark?.MarkValue)}}
                                        </strong>
                                    </span>
                                </div>

                                <div>
                                    <span>{{'marks_datums' | translate}}</span>
                                    <span>{{row.FinalMark?.MarkDate | appDate}}</span>
                                </div>

                                <div>
                                    <span>{{'marks_lecturer' | translate}}</span>
                                    <span>{{row.FinalMark?.Teacher}}</span>
                                </div>

                                <div *ngIf="row.PreviousMarks && row.PreviousMarks.length">
                                    <div>{{'marks_otherMarks' | translate}}</div>
                                    <div>
                                        <div *ngFor="let mark of row.PreviousMarks" class="prev-mark">
                                            <div><strong>{{mark.MarkValue}}</strong></div>
                                            <div><small>{{mark.MarkDate | appDate}}</small></div>
                                            <div class="text-sub">{{mark.Teacher}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </app-table>
        </ng-container>
    </ng-container>
</div>
