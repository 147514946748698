<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/purchases', text: 'purchases_title' },
                     { url: '/purchases/' + type, text: purchaseFormTitleDisplay }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{purchaseFormTitleDisplay}}</h1>
    </header>

    <app-alert level="danger" *ngIf="isError">{{'purchase_error' | translate}}</app-alert>
    <app-alert level="success" *ngIf="isSuccess">{{'purchase_success' | translate}}</app-alert>
    <app-alert level="warning" *ngIf="warningText">{{warningText | translate}}</app-alert>

    <form #form="ngForm" *ngIf="!isError && !isSuccess">
        <div class="block-body">
            <div *ngFor="let field of fieldTypeMapping" class="cols">
                <ng-container *ngIf="field.showInTypes.indexOf(type)>-1 && hiddenFields.indexOf(field.field)==-1">
                    <ng-container *ngIf="field.field=='Quantity'; then quantity; else byType">
                    </ng-container>

                    <ng-template #quantity>
                        <div class="row">
                            <div class="form-field col-md-4 col-6 required">
                                <label class="form-label">{{'purchase_labelquantity' | translate}}</label>
                                <input class="form-control" required type="number" min="0" step="0.01" name="QuantityInput"
                                       [(ngModel)]="purchase.Quantity" (keyup)="onNumericKey($event)" (blur)="onNumericLeave($event,field)"
                                       [pattern]="field.errorMatcher.isErrorState(quantityInput) ? '^$' : '.*'" #quantityInput="ngModel" />
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                                <div class="invalid-feedback" *ngIf="field.errorMatcher.isErrorState(quantityInput)">
                                    <div [innerHTML]="'purchase_numberValueToSmall' | translate:{min:field.min}"></div>
                                </div>
                            </div>

                            <div class="form-field col-md-2 col-6 required">
                                <label class="form-label">{{'purchase_labelmeasurment' | translate}}</label>
                                <app-select [(ngModel)]="purchase.Measurment"
                                            [name]="'MeasurmentSelect'"
                                            [required]="true"
                                            [options]="measurments">
                                    <ng-template #optionTemplate let-option>
                                        {{'purchase_measurments_' + option | translate}}
                                    </ng-template>
                                </app-select>
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template #byType>
                        <div class="row" *ngIf="field.type=='text'">
                            <div class="form-field col-md-6" [class.required]="field.required">
                                <label class="form-label">{{getTextForField(field)}}</label>
                                <input class="form-control" [required]="field.required" [name]="field.field+'Input'"
                                       [(ngModel)]="purchase[field.field]" [pattern]="field.errorMatcher.isErrorState(textInput) ? '^$' : '.*'"
                                       #textInput="ngModel" />
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                                <div class="invalid-feedback" *ngIf="field.errorMatcher.isErrorState(textInput)">
                                    <div [innerHTML]="'purchase_fieldinvalidlength' | translate:{field:getTextForField(field),max:field.maxLength}"></div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="field.type=='textarea'">
                            <div class="form-field col-md-6" [class.required]="field.required">
                                <label class="form-label">{{getTextForField(field)}}</label>
                                <textarea class="form-control" [required]="field.required" [name]="field.field+'Input'"
                                       [(ngModel)]="purchase[field.field]" [pattern]="field.errorMatcher.isErrorState(textareaInput) ? '^$' : '.*'"
                                       #textareaInput="ngModel" rows="5"></textarea>
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                                <div class="invalid-feedback" *ngIf="field.errorMatcher.isErrorState(textareaInput)">
                                    <div [innerHTML]="'purchase_fieldinvalidlength' | translate:{field:getTextForField(field),max:field.maxLength}">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="field.type=='number'">
                            <div class="form-field col-md-6" [class.required]="field.required">
                                <label class="form-label">{{getTextForField(field)}}</label>
                                <input class="form-control" type="number" [min]="field.min" [step]="field.format" [required]="field.required"
                                       [name]="field.field+'Input'" [(ngModel)]="purchase[field.field]"
                                       (keyup)="onNumericKey($event)" (blur)="onNumericLeave($event,field)"
                                       [pattern]="field.errorMatcher.isErrorState(numberInput) ? '^$' : '.*'"
                                       #numberInput="ngModel" />
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                                <div class="invalid-feedback" *ngIf="field.errorMatcher.isErrorState(numberInput)">
                                    <div [innerHTML]="'purchase_numberValueToSmall' | translate:{min:field.min.toString()}"></div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="field.type=='label' && purchase[field.field] && purchase[field.field]!=''">
                            <div class="form-field col-md-6">
                                <label class="form-label">{{getTextForField(field)}}</label>
                                <input class="form-control" disabled [name]="field.field+'Input'" [(ngModel)]="purchase[field.field]" />
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                            </div>
                        </div>

                        <div class="form-field" [class.required]="field.required" *ngIf="field.type=='tree'">
                            <label class="form-label">{{getTextForField(field)}}</label>
                            <app-treeview #treeViewUnit
                                          [data]="field.options"
                                          [parentAttr]="field.parentIdAttribute"
                                          [allowMultiple]="false"
                                          [required]="field.required"
                                          [nameAttr]="field.displayProps[0]"
                                          [idAttr]="field.IdAttribute"
                                          [selectAll]="selectAll"
                                          (onChange)="selectionChanged($event,field)">
                            </app-treeview>
                            <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                        </div>

                        <div class="row" *ngIf="field.type=='select'">
                            <div class="form-field col-md-6" [class.required]="field.required">
                                <label class="form-label">{{getTextForField(field)}}</label>
                                <app-select [(ngModel)]="purchase[field.field]"
                                            [name]="field.field+'Select'"
                                            [required]="field.required"
                                            [options]="field.options"
                                            [displayFn]="getDisplayPropValueFn(field)"
                                            (selectionChange)="selectionChanged($event,field)">
                                </app-select>
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                            </div>
                        </div>

                        <div class="row" *ngIf="field.type=='autocomplete'">
                            <div class="form-field col-md-6" [class.required]="field.required">
                                <label class="form-label">{{getTextForField(field)}}</label>
                                <app-autocomplete [(ngModel)]="purchase[field.field]"
                                                  [name]="field.field + 'Autocomplete'"
                                                  [required]="field.required"
                                                  [options]="field.options"
                                                  [displayFn]="field.getOptionDisplay"
                                                  (search)="filterAutoComplete($event,field)">
                                </app-autocomplete>
                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                            </div>
                        </div>

                        <div class="row" *ngIf="field.type=='choice'">
                            <div class="form-field">
                                <div *ngIf="field.field=='UseExtraBudgetCode'">
                                    <app-checkbox [(ngModel)]="purchase.UseExtraBudgetCode" name="UseExtraBudgetCode" (stateChange)="changeBudgetCode()">
                                        {{'purchase_labelmoveFromOtherBudgetCode' | translate}}
                                    </app-checkbox>
                                </div>

                                <div *ngIf="field.field!='UseExtraBudgetCode'">
                                    <app-checkbox [(ngModel)]="purchase[field.field]" name="NotExtraBudgetCode">
                                        {{getTextForField(field)}}
                                    </app-checkbox>
                                </div>

                                <div class="feedback" *ngIf="field.hint">{{field.hint}}</div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>

            <section class="block">
                <h5>{{'purchase_attachments' | translate}}</h5>

                <div class="row" *ngFor="let f of files; let i = index">
                    <div class="form-field col-lg-6">
                        <label class="form-label">{{'purchase_labeltechspec' | translate}}</label>
                        <app-file [accept]="fileAccept"
                                  name="file-{{i}}"
                                  [(ngModel)]="f.file"
                                  [immediate]="f.immediate"
                                  (fileChange)="onFileChange($event, f)"></app-file>
                    </div>
                </div>

                <app-alert level="danger" *ngIf="!validateFileSize()">
                    {{getFileSizeError()}}
                </app-alert>

                <div *ngIf="!isReadonly">
                    <button type="button" class="btn btn-outline" (click)="addFile()">
                        <i class="fi fi-rs-plus"></i>
                        {{'purchase_labeltechspec' | translate}}
                    </button>
                </div>
            </section>

            <div class="form-actions">
                <button type="button" class="btn btn-outline" [routerLink]="['/' | localize]">
                    {{'application_btnCancel' | translate}}
                </button>

                <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!canSubmit()">
                    {{'application_btnSubmit' | translate}}
                </button>
            </div>
        </div>
    </form>
</div>
