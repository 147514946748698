import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';
import { PersonService } from '../../services/person.service';
import { EmployeeFilterType, IPersonSearchResultItem, StudentFilterType } from '../../models/Person';
import { UserType } from '../../models/UserInfo';
import { ITableColumn } from '../table/table.component';
import { finalize } from 'rxjs/operators';

export type PersonType = 'student' | 'employee';

type AllFilterTypes = StudentFilterType | EmployeeFilterType;

@Component({
    selector: 'app-person-picker',
    templateUrl: './person-picker.component.html',
    styleUrls: ['./person-picker.component.scss']
})
export class PersonPickerComponent implements OnInit {
    constructor(private app: AppService, private service: PersonService) { }

    @Input() set personType(value: PersonType) {
        this._personType = value;
        this.filterTypes = value == 'employee' ? this.employeeFilterTypes : this.studentFilterTypes;
        this.filterType = this.filterTypes[0];
    }
    @Input() pickButtonText: string;
    @Input() additionalFilter: string = '';

    @Output('pick') pickEvent = new EventEmitter<IPersonSearchResultItem>();

    items: IPersonSearchResultItem[] = [];
    allowed: boolean;
    isFiltering: boolean;
    filterValue: string;
    filterType: AllFilterTypes;
    filterTypes: AllFilterTypes[] = [];

    readonly studentColumns: ITableColumn[] = [
        { property: 'StudentId', label: 'personPicker_lblStudentId', sorts: true },
        { property: 'FirstName', label: 'personPicker_lblFirstName', sorts: true },
        { property: 'LastName', label: 'personPicker_lblLastName', sorts: true },
        { property: 'Email', label: 'personPicker_lblEmail', sorts: true },
        { label: 'personPicker_lblFacultyGroupProgramme' },
        { property: 'Status', label: 'personPicker_lblStatus', sorts: true },
        { width: '1px' }
    ];

    readonly employeeColumns: ITableColumn[] = [
        { property: 'FirstName', label: 'personPicker_lblFirstName', sorts: true },
        { property: 'LastName', label: 'personPicker_lblLastName', sorts: true },
        { property: 'Email', label: 'personPicker_lblEmail', sorts: true },
        { property: 'StructureName', label: 'personPicker_lblDepartment', sorts: true },
        { property: 'WorkloadName', label: 'personPicker_lblPosition', sorts: true },
        { property: 'ProgrammeCode', label: 'personPicker_lblProgrammeCode', sorts: true },
        {}
    ];

    readonly filterTypeDisplayFn = (item: AllFilterTypes) => this.app.translate('personPicker_filterType_' + item);

    get personType(): PersonType {
        return this._personType;
    }

    get findButtonText(): string {
        return this.personType == 'employee' ? 'personPicker_btnFilterEmployees'
            : this.personType == 'student' ? 'personPicker_btnFilterStudents'
                : 'personPicker_btnFilter';
    }

    private _personType: PersonType;

    private readonly studentFilterTypes: AllFilterTypes[] = [
        'student_id',
        'personal_no',
        'name_surname',
        'name_surname_study_programm_code',
        'name',
        'personal_email',
        'rsu_email',
        'student_group',
        'study_programm_code',
        'mobile'
    ];

    private readonly employeeFilterTypes: AllFilterTypes[] = [
        'name_surname',
        'surname',
        'rsu_email',
        'structure'
    ];

    ngOnInit() {
        this.allowed = this.app.currentUser?.hasRight('ES_MAIN.STUDENTS.FIND');

        if (!this.personType) {
            this.personType = 'student';
        }
    }

    select(person: IPersonSearchResultItem) {
        this.pickEvent.emit(person);
    }

    search() {
        const userType = this.personType == 'employee' ? UserType.Employee : UserType.Student;

        this.isFiltering = true;

        const call = this.service.find(userType, this.filterType, this.filterValue, this.additionalFilter)
            .pipe(finalize(() => { this.isFiltering = false }));

        this.app.addLoading(call).subscribe(data => {
            this.items = data || [];
        });
    }
}
