import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IExternalService, IExternalServiceListItem } from '../models/ExternalService';

/**
 * External service/application service
 */
@Injectable({ providedIn: "root" })
export class ExternalServiceService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/externalServices` }

    get(): Observable<IExternalServiceListItem[]> {
        return this.http.get<IExternalServiceListItem[]>(this.apiUrl);
    }

    getById(id: number): Observable<IExternalService> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<IExternalService>(url);
    }

    getByCode(code: string): Observable<IExternalService> {
        const url = `${this.apiUrl}/getByCode(${code})`;
        return this.http.get<IExternalService>(url);
    }
}
