import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { MyApplication, ApplicationKind } from '../../models/Application';
import { Classifier } from '../../models/Classifier';
import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ClassifierService } from '../../services/classifier.service';
import { ParameterService } from '../../services/parameter.service';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
  selector: 'app-my-applications',
    templateUrl: './my-applications.component.html',
    styleUrls: ['./application.component.scss']
})
export class MyApplicationsComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private classifiers: ClassifierService,
        private parameters: ParameterService
    ) { }

    references: MyApplication[] = [];
    applications: MyApplication[] = [];
    rowsPerPage: number = 10;

    readonly referenceColumns: ITableColumn[] = [
        { property: 'Type', label: 'myApplications_lblReferenceName', sorts: true },
        { property: 'Created', label: 'myApplications_lblSubmitDate', sorts: true, type: 'date', cssClass: 'hidden-sm-down' },
        { property: 'Registered', label: 'myApplications_lblReferencePrepared', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { property: 'FileId', label: 'myApplications_lblReferenceFile', cssClass: 'hidden-xs-down' },
        { width: '1px', cssClass: 'hidden-lg-up' }
    ];

    readonly applicationColumns: ITableColumn[] = [
        { property: 'Type', label: 'myApplications_lblApplicationName', sorts: true },
        { property: 'Created', label: 'myApplications_lblSubmitDate', sorts: true, type: 'date', cssClass: 'hidden-sm-down' },
        { property: 'Registered', label: 'myApplications_lblApplicationRegistered', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { property: 'DvsStatus', label: 'myApplications_lblDecisionSigned', sorts: true, cssClass: 'hidden-xs-down' },
        { width: '1px', cssClass: 'hidden-lg-up' }
    ];

    private types: Classifier[] = [];
    private langEn: boolean;
    private expanded: MyApplication[] = [];

    ngOnInit() {
        this.langEn = this.app.currentLanguage === 'en';

        this.app.addLoading(forkJoin(
            this.service.getMy(),
            this.classifiers.get('ApplicationType'),
            this.parameters.getValues()
        )).subscribe(data => {
            let [items, types, para] = data;

            let apps: MyApplication[] = [];
            let refs: MyApplication[] = [];

            items.forEach(t => {
                if (t.Kind === ApplicationKind.Application)
                    apps.push(t);
                else if (t.Kind === ApplicationKind.Reference)
                    refs.push(t);
            });

            this.applications = apps;
            this.references = refs;
            this.types = types;

            let pageSizeItem = para.find(t => t.Code === 'ApplicationHistoryPageSize');

            if (pageSizeItem) {
                this.rowsPerPage = parseInt(pageSizeItem.Value) || 10;
            }
        });
    }

    getTypeDisplayName(applicationType: string): string {
        let item = this.types.find(t => t.Code === `ApplicationType${applicationType}`);

        if (!item)
            return applicationType;

        return this.langEn ? item.ValueEN : item.Value;
    }

    getFileUrl(applicationId: number) {
        return this.service.getFileUrl(applicationId);
    }

    toggle(row: MyApplication) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: MyApplication): boolean {
        return this.expanded.indexOf(row) > -1;
    }
}
