<div class="picture">
    <div class="picture-select" [class.is-invalid]="isError">
        <image-cropper class="cropper" *ngIf="imageChangedEvent"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="3/4"
                       format="jpeg"
                       [imageQuality]="80"
                       [imageChangedEvent]="imageChangedEvent"
                       (imageCropped)="onImageCropped($event)"></image-cropper>
        <input #profilePhotoChangeInput type="file" class="photo-input" (change)="onFileChange($event)" [accept]="accept"
               style="display: none" />
        <div class="mt-2">
            <button class="btn btn-outline" type="button" (click)="profilePhotoChangeInput.click()">
                <span *ngIf="!currentCroppedImage">{{labels.add | translate}}</span>
                <span *ngIf="currentCroppedImage">{{labels.change | translate}}</span>
            </button>
        </div>
    </div>

    <div class="invalid-feedback" *ngIf="isError">
        <div *ngIf="maxSizeError">
            {{'fileMaxSizeErrorSizes' | translate | formatString:[prettySize(size), prettySize(maxSize)]}}
        </div>
        <div *ngIf="wrongExtensionError">
            {{'wrongExtensionError' | translate | formatString:accept}}
        </div>
    </div>
</div>
