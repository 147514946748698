import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ISeminarAttendace, ISeminarAttendaceStudent } from '../../../models/Seminar';
import { AppService } from '../../../services/app.service';
import { SeminarService } from '../../../services/seminar.service';
import { ITableColumn } from '../../../shared/table/table.component';

export interface IRow extends ISeminarAttendaceStudent {
    fullName: string;
    wasAttended: boolean;
}

export interface IForm {
    data: ISeminarAttendace;
    rows: IRow[];
    canEdit: boolean;
    /*
     * Save form data.
     * @returns A promise indicating whether the data was saved or the save wasn't needed at all (no changes)
     */
    save: () => Promise<boolean>;
}

@Component({
    selector: 'app-seminar-attendance-form',
    templateUrl: './seminar-attendance-form.component.html'
})
export class SeminarAttendanceFormComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: SeminarService
    ) { }

    /**
     * Seminar ID to view attendance for
     */ 
    @Input() set seminarId(value: string) {
        this._seminarId = value;
        this.load();
    };

    get seminarId(): string {
        return this._seminarId;
    }

    /**
     * An event triggered when seminar attendance data has been loaded
     */ 
    @Output('load') loadEvent: EventEmitter<IForm> = new EventEmitter<IForm>();

    data: ISeminarAttendace;
    rows: IRow[] = [];
    canEdit: boolean;
    allChecked: boolean;

    readonly columns: ITableColumn[] = [
        { width: '1px' },
        { property: 'fullName', label: 'seminar_attendance_lblStudent', sorts: true },
        { property: 'StudyProgramName', label: 'seminar_attendance_lblProgram', sorts: true },
        { property: 'StudyYear', label: 'seminar_attendance_lblStudyYear', sorts: true, type: 'number' }
    ];

    get hasChanges(): boolean {
        return this.allRows.some(t => t.IsAttended != t.wasAttended);
    }

    private _seminarId: string;
    protected allRows: IRow[] = [];
    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            if (!term) {
                this.rows = [...this.allRows];
            } else {
                const search = term.toLowerCase();

                this.rows = this.allRows.filter(t => {
                    return t.fullName.toLowerCase().includes(search)
                        || t.StudyProgramName.toLowerCase().includes(search)
                        || t.StudyYear.toString() == search;
                });
            }
        });
    }

    search(event) {
        this.searchSubj.next(event.target.value);
    }

    checkAll(check: boolean) {
        if (check) {
            this.rows.forEach(t => {
                t.IsAttended = true;
            });
            this.allChecked = true;
        } else {
            this.rows.forEach(t => {
                t.IsAttended = false;
                this.allChecked = false;
            });
        }
    }

    getPlanSummaryUrl(student: IRow): string {
        return `/${this.app.currentLanguage}/plan-summary?StudentId=${student.Id}`;
    }

    private save() {
        const payload = {
            students: this.allRows.filter(t => t.IsAttended != t.wasAttended).map(t => {
                return {
                    id: t.Id,
                    isAttended: t.IsAttended
                };
            })
        };

        if (payload.students.length) {
            return this.service.saveAttendance(this.seminarId, payload).pipe(map(() => true));
        } else {
            return of(false);
        }
    }

    private load() {
        if (this.seminarId) {
            this.app.addLoading(this.service.getAttendance(this.seminarId)).subscribe(data => {
                this.data = data;

                this.rows = data.Students.map(t => {
                    return {
                        ...t,
                        wasAttended: t.IsAttended,
                        fullName: `${t.Name} ${t.Surname}`
                    };
                });

                this.allRows = [...this.rows];

                this.canEdit = data.IsEditable && this.app.currentUser.rights.includes('SEMINAR.EDIT_ATTENDANCE');

                this.loadEvent.emit({
                    data,
                    rows: this.allRows,
                    canEdit: this.canEdit,
                    save: () => {
                        return this.save().toPromise();
                    }
                });
            });
        }
    }
}
