import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertMessage, AlertMessageType } from '../../services/alert.service';

@Component({
    template: `
    <a href="#" style="display: none"></a>
    <div class="modal-icon" *ngIf="isError"><span class="alert-icon alert-icon-danger"><i class="fi fi-rs-exclamation"></i></span></div>
    <div class="modal-icon" *ngIf="isWarning"><span class="alert-icon alert-icon-warning"><i class="fi fi-rs-exclamation"></i></span></div>
    <div class="modal-icon" *ngIf="isSuccess"><span class="alert-icon alert-icon-success"><i class="fi fi-rs-check"></i></span></div>
    <div class="modal-header">
        <h4 class="modal-title">{{hasTitle ? message?.title : ('alertDialog_title' | translate)}}</h4>
    </div>
    <div class="modal-body">
	    <div [innerHTML]="message?.text | safeHtml"></div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-outline-secondary" (click)="close()">{{'alertDialog_ok' | translate}}</button>
    </div>
    `
})
export class AlertDialogComponent {
    constructor(private modal: NgbActiveModal) { }

    @Input() message: AlertMessage;

    get isError(): boolean {
        return this.message?.type == AlertMessageType.Error;
    }

    get isWarning(): boolean {
        return this.message?.type == AlertMessageType.Warning;
    }

    get isSuccess(): boolean {
        return this.message?.type == AlertMessageType.Success;
    }

    get isInfo(): boolean {
        return this.message?.type == AlertMessageType.Info;
    }

    get hasTitle(): boolean {
        return this.message?.title !== undefined;
    }

    close() {
        this.modal.close();
    }
}
