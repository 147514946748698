export class Util {
    private static expanded: any[] = [];

    static toggle(row: any) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    static isExpanded(row: any): boolean {
        return this.expanded.indexOf(row) > -1;
    }
}
