<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/hospital-dashboard', text: 'hospitalDashboard_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'hospitalDashboard_title' | translate}}</h1>
        <!-- Izslēgts līdz RSU sagatavo api/PersonData
        <aside>
            <button type="button" class="btn btn-outline" (click)="togglePersonPicker()" *ngIf="canPickEmployee && tabsReady">
                <i class="fi fi-rs-badge"></i>
                {{'hospitalDashboard_btnPickEmployee' | translate}}
            </button>
        </aside> -->
    </header>

    <app-alert level="warning" *ngIf="tabsReady && !tabs.length">
        {{'hospitalDashboard_unavailable' | translate}}
    </app-alert>

    <app-person-picker *ngIf="personPickerOpened" [personType]="personPickerType" (pick)="setPerson($event)"></app-person-picker>

    <ng-container *ngIf="tabs.length && !personPickerOpened">
        <form #filterForm="ngForm" class="was-validated">
            <div class="row">
                <div class="col-6 form-field">
                    <app-select [options]="companies"
                                [placeholder]="'hospitalDashboard_lblCompany' | translate"
                                [displayProperty]="'Name'"
                                [valueProperty]="'Id'"
                                [required]="true"
                                [(ngModel)]="selectedCompanyId"
                                name="selectedCompanyId">
                    </app-select>
                </div>

                <div class="col-6 form-field">
                    <app-select [options]="academicYears"
                                [placeholder]="'hospitalDashboard_lblAcademicYear' | translate"
                                [displayProperty]="'Name'"
                                [valueProperty]="'Id'"
                                [required]="true"
                                [(ngModel)]="selectedYearId"
                                name="selectedYearId">
                    </app-select>
                </div>
            </div>
        </form>

        <ng-container *ngIf="selectedCompanyId && selectedYearId">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" (navChange)="onTabChange($event)" class="nav-tabs">
                <li [ngbNavItem]="i + 1" *ngFor="let tab of tabs; let i = index">
                    <a ngbNavLink>{{tab.title | translate}}</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngSwitch]="tab.id">
                            <div class="mt-3">
                                <app-hospital-dashboard-students *ngSwitchCase="'students'"
                                                                 [companyId]="selectedCompanyId"
                                                                 [academicYearId]="selectedYearId"></app-hospital-dashboard-students>

                                <app-hospital-dashboard-seminars *ngSwitchCase="'seminars'"
                                                                 [companyId]="selectedCompanyId"
                                                                 [academicYearId]="selectedYearId"
                                                                 [trainer]="seminarTrainer"
                                                                 [openAttendanceFor]="seminarId"></app-hospital-dashboard-seminars>

                                <app-hospital-dashboard-doctors *ngSwitchCase="'doctors'"
                                                                [companyId]="selectedCompanyId"
                                                                [academicYearId]="selectedYearId"
                                                                (openSeminars)="openSeminars($event)"></app-hospital-dashboard-doctors>

                                <app-hospital-dashboard-rotations *ngSwitchCase="'rotations'"
                                                                  [companyId]="selectedCompanyId"
                                                                  [academicYearId]="selectedYearId"></app-hospital-dashboard-rotations>
                            </div>
                        </ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>
    </ng-container>
</div>
