import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '../../../services/app.service';

import { ITableColumn } from '../../../shared/table/table.component';
import { HospitalService } from '../../../services/hospital.service';
import { IHospitalSeminar } from '../../../models/Hospital';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SeminarAttendanceModalComponent } from '../../seminar/attendance/seminar-attendance-modal.component';
import { Utils } from '../../../core/Utils';
import { store } from '../store';

interface IOption<T> {
    value: T;
    label: string;
}

const cache: {
    seminarsByCompany: { [companyId: string]: IHospitalSeminar[] }
} = {
    seminarsByCompany: {}
};

@Component({
    selector: 'app-hospital-dashboard-seminars',
    templateUrl: './hospital-dashboard-seminars.component.html'
})
export class HospitalDashboardSeminarsComponent implements OnInit, OnDestroy {
    constructor(
        private app: AppService,
        private service: HospitalService,
        private modal: NgbModal
    ) { }

    @Input() set companyId(value: string) {
        this._companyId = value;
        this.companyIdSubj.next(value);
    }

    @Input() set academicYearId(value: string) {
        this._academicYearId = value;
        this.academicYearIdSubj.next(value);
    }

    @Input() set trainer(value: string) {
        if (value) {
            this.filters.search = value;
            this.filterByTrainer();
        }
    }

    /**
     * Seminar ID to automatically open attendace for
     */ 
    @Input() openAttendanceFor: string;

    readonly columns: ITableColumn[] = [
        { property: 'Theme', label: 'hospitalDashboard_seminars_lblTheme', sorts: true },
        { property: 'DateTime', label: 'hospitalDashboard_seminars_lblDateTime', sorts: true, type: 'date' },
        { property: 'Trainer', label: 'hospitalDashboard_seminars_lblTrainer', sorts: true, type: 'number' },
        { property: 'Place', label: 'hospitalDashboard_seminars_lblPlace', sorts: true },
        { property: 'Speciality', label: 'hospitalDashboard_students_lblSpeciality', sorts: true },
        { property: 'StudyYear', label: 'hospitalDashboard_students_lblStudyYear', sorts: true, type: 'number' },
        { width: '1px' }
    ];

    rows: IHospitalSeminar[] = [];
    canViewAttendance: boolean;

    filters: {
        search?: string,
        speciality: {
            options: IOption<string>[],
            value?: string
        },
        year: {
            options: IOption<number>[],
            value?: number
        }
    } = {
            speciality: { options: [] },
            year: { options: [] }
        };

    get companyId(): string {
        return this._companyId;
    }

    get academicYearId(): string {
        return this._academicYearId;
    }

    private _companyId: string;
    private _academicYearId: string;
    private data: IHospitalSeminar[] = [];
    private prevCompanyId: string;

    private companyIdSubj = new BehaviorSubject<string>(this.companyId);
    private academicYearIdSubj = new BehaviorSubject<string>(this.academicYearId);
    private argsSub: Subscription;

    ngOnInit() {
        this.canViewAttendance = this.app.currentUser.rights.includes('SEMINAR.VIEW_ATTENDANCE');

        this.argsSub = combineLatest([
            this.companyIdSubj.pipe(filter(t => !!t)),
            this.academicYearIdSubj.pipe(filter(t => !!t))
        ]).subscribe(args => {
            const reload = this.companyId != this.prevCompanyId;
            this.prevCompanyId = this.companyId;

            this.init(reload);
        });
    }

    ngOnDestroy() {
        if (this.argsSub) {
            this.argsSub.unsubscribe();
        }
    }

    filter() {
        const search = this.filters.search?.toLowerCase();

        this.rows = this.data.filter(t => {
            return (!this.filters.speciality.value || t.SpecialityId == this.filters.speciality.value)
                && (!this.filters.year.value || t.StudyYear == this.filters.year.value)
                && (!search || (
                    t.Theme.toLowerCase().includes(search)
                    || t.Trainer.toLowerCase().includes(search)
                    || t.Place.toLowerCase().includes(search))
                );
        });
    }

    openAttendance(seminar: IHospitalSeminar) {
        const ref = this.modal.open(SeminarAttendanceModalComponent, { size: 'lg' });
        ref.componentInstance.seminarId = seminar.Id;
        ref.result.then(() => {
            // do nothing
        }, () => { });
    }

    private init(reload?: boolean) {
        const process = (data: IHospitalSeminar[]) => {
            const byYear = this.filterByAcademicYear(data);

            byYear.forEach(t => {
                t.DateTime = Utils.ensureDate(t.DateTime);
            });

            this.addFilters(byYear);
            this.data = byYear;
            this.rows = byYear;

            this.filterByTrainer();

            if (this.canViewAttendance && this.openAttendanceFor) {
                const seminar = this.rows.find(t => t.Id == this.openAttendanceFor);

                if (seminar) {
                    this.openAttendance(seminar);
                }
            }
        };

        if (cache.seminarsByCompany[this.companyId]) {
            process(cache.seminarsByCompany[this.companyId]);
        } else if (reload) {
            this.app.addLoading(this.service.getSeminars(this.companyId, store.getPerson()?.Upn)).subscribe(data => {
                cache.seminarsByCompany[this.companyId] = data;
                process(data);
            });
        }
    }

    private filterByAcademicYear(data: IHospitalSeminar[]) {
        return data.filter(t => t.AcademicYearId == this.academicYearId);
    }

    private filterByTrainer() {
        if (this.filters.search) {
            this.rows = this.data.filter(t => t.Trainer == this.filters.search);
        }
    }

    private addFilters(data: IHospitalSeminar[]) {
        const specialityOpts = data.reduce((all, n) => {
            if (!all.some(t => t.value == n.SpecialityId)) {
                all.push({
                    value: n.SpecialityId,
                    label: n.Speciality
                });
            }

            return all;
        }, ([] as IOption<string>[]));

        const yearOpts = data.reduce((all, n) => {
            if (!all.some(t => t.value == n.StudyYear)) {
                all.push({
                    value: n.StudyYear,
                    label: `${n.StudyYear}.`
                });
            }

            return all;
        }, ([] as IOption<number>[]));

        specialityOpts.sort((a, b) => a.label.localeCompare(b.label, this.app.currentLanguage));
        yearOpts.sort((a, b) => a.value > b.value ? 1 : a.value < b.value ? -1 : 0);

        this.filters.speciality.options = specialityOpts;
        this.filters.year.options = yearOpts;
    }
}
