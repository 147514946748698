import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';

import { Purchase, PurchaseResult, MyPurchase,Manager } from '../models/Purchase';

/**
 * Purchase service
 */
@Injectable({ providedIn: "root" })
export class PurchaseService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/purchases` }

    /**
     * Create a purchase.
     * @param application
     * @param files
     */
    create(purchase: Purchase, files: File[] = null): Observable<PurchaseResult> {
        const url = `${this.apiUrl}`;

        let form = new FormData();
        form.append('purchase', encodeURIComponent(JSON.stringify(purchase)));

        for (let i in files)
            form.append('file[]', files[i], files[i].name);

        return this.http.post<PurchaseResult>(url, form);
    }
    /**
     * Get filtered manager values.
     */
    filterManagers(search: string): Observable<Manager[]> {
        const url = `${this.apiUrl}/managers?filter=${search}`;
        return this.http.get<Manager[]>(url);
    }
    /**
     * Get unit values.
     */
    getUnits(type:string): Observable<any[]> {
        const url = `${this.apiUrl}/${type}/units`;
        return this.http.get<any[]>(url);
    }
    /**
     * Get unit values.
     */
    getBudgetCodes(structureId:string,type: string): Observable<any[]> {
        const url = `${this.apiUrl}/${type}/units/${structureId}/codes`;
        return this.http.get<any[]>(url);
    }
    /**
     * Get current user purchases.
     */
    getMy(): Observable<MyPurchase[]> {
        const url = `${this.apiUrl}/my`;
        return this.http.get<MyPurchase[]>(url);
    }
}
