import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
    selector: 'app-student-balance-invoice',
    templateUrl: './student-balance-invoice.component.html',
    styleUrls: ['./student-balance.component.css']
})
export class StudentBalanceInvoiceComponent {
    //constructor(
    //    private modal: NgbActiveModal
    //) {
    //    this.item = data.invoice;
    //    this.status = data.status;
    //    this.numberFormat = data.numberFormat;
    //    this.smallWindowWidth = data.smallWindowWidth;

    //    if (!this.item.Payments)
    //        this.item.Payments = [];

    //    this.item.Payments.sort((a, b) => b.Date - a.Date); 
    //}

    constructor(
        private activeModal: NgbActiveModal
    ) { }

    @Input() item: any = {};
    @Input() status: string;
    @Input() numberFormat: string;

    readonly columns: ITableColumn[] = [
        { property: 'Number', label: 'studentBalance_lblPaymentNumberDate', sorts: true, cssClass: 'hidden-md-up' },
        { property: 'Number', label: 'studentBalance_lblPaymentNumber', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Date', label: 'studentBalance_lblPaymentDate', sorts: true, type: 'date', cssClass: 'hidden-sm-down' },
        { property: 'Amount', label: 'studentBalance_lblPaymentAmount', sorts: true, type: 'number', cssClass: 'hidden-sm-down' },
        { property: 'AssignedAmount', label: 'studentBalance_lblPaymentAssignedAmount', sorts: true, type: 'number', cssClass: 'hidden-md-down' },
        { property: 'Payer', label: 'studentBalance_lblPayer', sorts: true, cssClass: 'hidden-md-down' },
        { width: '1px' }
    ];

    private expanded: any[] = [];

    toggle(row: any) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: any): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    close() {
        this.activeModal.close();
    }
}
