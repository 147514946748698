<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/on-call-shifts', text: 'onCallShift_listTitle' },
                     {
                        url: '/on-call-shifts/' + (isNew ? 'add' : report?.Id),
                        text: isNew ? 'onCallShift_newTitle' : report ? isFinal ? 'onCallShift_viewTitle' : 'onCallShift_editTitle' : '...'
                     }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{(isNew ? 'onCallShift_newTitle' : report ? isFinal ? 'onCallShift_viewTitle' : 'onCallShift_editTitle' : '...') | translate}}
            <span class="badge" *ngIf="report"
                  [class.text-bg-secondary]="report.Status == 'Submitted'"
                  [class.text-bg-light]="report.Status == 'Draft'"
                  [class.text-bg-success]="report.Status == 'APST'"
                  [class.text-bg-warning]="report.Status == 'ATC'"
                  [class.text-bg-danger]="report.Status == 'NOR'">
                {{report.StatusName || ('onCallShift_status' + report.Status | translate)}}
            </span>
        </h1>
        <aside *ngIf="canPickStudent && !studentPickerOpened">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'onCallShift_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <form #form="ngForm" *ngIf="isReady">
        <app-alert level="danger" *ngIf="!studentPickerOpened && !resident">{{'eServiceNotAvailable' | translate}}</app-alert>

        <app-person-picker (pick)="setStudent($event)" *ngIf="studentPickerOpened"></app-person-picker>

        <ng-container *ngIf="!studentPickerOpened && resident">
            <section class="block">
                <div>
                    <div class="row">
                        <div class="col-sm">
                            <div class="data-value">
                                <label>{{'onCallShift_lblNameSurname' | translate}}</label>
                                <div>{{resident.Name}} {{resident.Surname}}</div>
                            </div>

                            <div class="data-value">
                                <label>{{'onCallShift_lblSpeciality' | translate}}</label>
                                <div>{{resident.SpecialityName}}</div>
                            </div>
                        </div>

                        <div class="col-sm">
                            <div class="data-value">
                                <label>{{'onCallShift_lblStudyYear' | translate}}</label>
                                <div>{{resident.StudyYear}}</div>
                            </div>

                            <div class="data-value">
                                <label>{{'onCallShift_lblPeriod' | translate}}</label>
                                <div>{{periodFrom | appDate}} - {{periodTo | appDate}}</div>
                            </div>
                        </div>

                        <div class="data-value" *ngIf="report.FileDate">
                            <label>{{'onCallShift_lblSubmitDate' | translate}}</label>
                            <div>{{ report.FileDate | appDate:'datetime' }}</div>
                        </div>
                    </div>

                    <div class="row" *ngIf="!isFinal && residentInfo.length > 1">
                        <div class="col-sm-6 form-field">
                            <label class="form-label">{{'onCallShift_lblChangeSpeciality' | translate}}</label>
                            <app-select [(ngModel)]="specialityId"
                                        [options]="residentInfo"
                                        [valueProperty]="'SpecialityId'"
                                        [displayFn]="specialityDisplayFn"
                                        [required]="true"
                                        (selectionChange)="onSpecialityChange()"
                                        name="specialityId">
                            </app-select>
                        </div>
                    </div>
                </div>
            </section>

            <section class="block">
                <app-table [rows]="shifts">
                    <ng-template #headTemplate>
                        <tr>
                            <th class="hidden-md-up" colspan="2">{{'onCallShift_lblDateHoursWorkplace' | translate}}</th>
                            <th class="hidden-sm-down">{{'onCallShift_lblDate' | translate}}</th>
                            <th class="hidden-sm-down">{{'onCallShift_lblHours' | translate}}</th>
                            <th class="hidden-sm-down">{{'onCallShift_lblWorkplace' | translate}}</th>
                            <th class="hidden-md-down">{{'onCallShift_lblComments' | translate}}</th>
                            <th class="hidden-sm-down"></th>
                        </tr>
                    </ng-template>
                    <ng-template #rowTemplate let-row>
                        <tr>
                            <td class="hidden-md-up">
                                <div>{{row.Date | appDate}} / {{row.Hours}}</div>
                                <div class="text-sub">{{row.WorkplaceName}}</div>
                            </td>
                            <td class="hidden-sm-down">
                                {{row.Date | appDate}}

                            </td>
                            <td class="hidden-sm-down">{{row.Hours}}</td>
                            <td class="hidden-sm-down">{{row.WorkplaceName}}</td>
                            <td class="hidden-md-down">{{row.Comments}}</td>
                            <td>
                                <div class="table-actions">
                                    <button (click)="editShift(row)" type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'edit' | translate" *ngIf="!isFinal">
                                        <i class="fi fi-rs-pencil"></i>
                                    </button>

                                    <button (click)="removeShift(row)" type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'delete' | translate" *ngIf="!isFinal">
                                        <i class="fi fi-rs-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="9" class="error-row" *ngIf="getTotalHoursByDate(row.Date) > 24">
                                <div class="alert alert-danger">{{'onCallShift_dailyHoursExceeded' | translate}}</div>
                            </td>
                        </tr>
                    </ng-template>
                </app-table>

                <div class="block-actions" *ngIf="!isFinal">
                    <button class="btn btn-outline" type="button" (click)="addShift()">
                        <i class="fi fi-rs-plus"></i>
                        {{'add' | translate}}
                    </button>
                </div>
            </section>

            <div class="form-field">
                <label class="form-label">{{'onCallShift_lblNotes' | translate}}</label>
                <textarea class="form-control" [(ngModel)]="notes" name="notes" maxlength="4000" [disabled]="isFinal" rows="5"></textarea>
            </div>

            <div class="form-field">
                <label class="form-label">{{'onCallShift_attachments' | translate}}</label>
                <div *ngFor="let a of attachments; let i = index" class="d-flex mb-2">
                    <div class="flex-grow-1">
                        <app-file [(ngModel)]="a.file"
                                  [accept]="attachmentExtensions"
                                  [maxSize]="attachmentMaxSize"
                                  name="attachment{{i}}"
                                  [immediate]="!a.id"
                                  [disabled]="isFinal"
                                  (fileChange)="onFileChange($event, a)"
                                  #attachmentInput="ngModel"></app-file>
                        <div class="invalid-feedback" *ngIf="attachmentInput.errors?.maxSize">{{attachmentInput.errors?.maxSize}}</div>
                        <div class="invalid-feedback" *ngIf="checkDuplicateFileName(a)">{{'onCallShift_duplicateFileName' | translate}}</div>
                    </div>

                    <button class="btn btn-outline btn-icon align-self-end ms-3" *ngIf="a.id"
                            (click)="downloadAttachment(a)" [ngbTooltip]="'download' | translate">
                        <i class="fi fi-rs-download"></i>
                    </button>
                </div>

                <div *ngIf="!isFinal">
                    <button class="btn btn-outline" type="button" (click)="addAttachment()"
                            [disabled]="attachments.length >= attachmentLimit || (attachments.length > 0 && !attachments[attachments.length - 1].file)">
                        <i class="fi fi-rs-plus"></i>
                        {{'onCallShift_btnAddFile' | translate}}
                    </button>
                </div>
            </div>

            <div class="form-field">
                <label class="form-label">{{'onCallShift_lblReviewer' | translate}}</label>
                <app-autocomplete [(ngModel)]="reviewer"
                                  name="reviewer"
                                  limit="10"
                                  [options]="reviewerOptions"
                                  [displayFn]="reviewerDisplayFn"
                                  [placeholder]="'onCallShift_reviewerFilterPlaceholder' | translate"
                                  [disabled]="isFinal"
                                  (search)="filterReviewers($event)">
                    <ng-template #optionTemplate let-option>
                        <div>{{option.Name}} {{option.Surname}}</div>
                        <div class="text-sub text-truncate">{{option.Position}} ({{option.Organization}})</div>
                    </ng-template>
                </app-autocomplete>
                <div class="feedback" *ngIf="reviewer">
                    {{reviewer.Position}} ({{reviewer.Organization}})
                </div>
            </div>

            <div class="form-actions">
                <span class="form-actions-group">
                    <button class="btn btn-secondary" type="button" (click)="downloadReport()" *ngIf="isFinal">
                        <i class="fi fi-rs-download"></i>
                        {{'onCallShift_btnDownload' | translate}}
                    </button>

                    <button class="btn btn-outline-primary" type="button" (click)="submit()" *ngIf="resident && !isFinal">
                        <i class="fi fi-rs-paper-plane"></i>
                        {{'onCallShift_btnSubmit' | translate}}
                    </button>
                </span>

                <span class="form-actions-group">
                    <button class="btn btn-outline" type="button" [routerLink]="['/on-call-shifts'] | localize">
                        {{'onCallShift_btnClose' | translate}}
                    </button>

                    <button class="btn btn-primary" type="button" (click)="save()" *ngIf="resident && !isFinal">
                        {{'onCallShift_btnSave' | translate}}
                    </button>
                </span>
            </div>
        </ng-container>
    </form>
</div>
