<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/password/request', text: 'password_restore' },
                     { url: '/password/request-employee', text: 'password_employeeRestore' }
                     ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{'password_employeeRestore' | translate}}
        </h1>
    </header>

    <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate autocomplete="off">
        <app-alert level="warning" *ngIf="consentBody">
            <div [innerHTML]="consentBody | safeHtml"></div>
        </app-alert>

        <div class="form-field" *ngIf="consentCheckbox">
            <app-checkbox [(ngModel)]="consent" name="consent">{{consentCheckbox}}</app-checkbox>
        </div>

        <div class="form-actions employee-request-actions">
            <div class="employee-request-submit-info" [innerHTML]="'password_signInLatvijaLv' | translate | safeHtml"></div>
            <button type="submit" class="btn btn-outline employee-request-submit-btn" [disabled]="!f.valid || !consent">
                <img src="/assets/img/latvija-logo.png" />
            </button>
        </div>

        <div class="mb-3">
            <a [routerLink]="['/password/request-student'] | localize">{{'password_restoreStudent' | translate}}</a>
        </div>

        <div class="mb-3" [innerHTML]="'password_contactfooter' | translate | safeHtml"></div>
    </form>
</div>
