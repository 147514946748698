<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/users', text: 'userslist_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'userslist_title'"></h1>
    </header>

    <form #form="ngForm">
        <div class="row">
            <div class="form-field col-6">
                <app-select [options]="userRoles"
                            [placeholder]="'userslist_lblRole' | translate"
                            [displayProperty]="'Value'"
                            [(ngModel)]="filterRole"
                            name="filterRole">
                </app-select>
            </div>

            <div class="form-field col-4">
                <input class="form-control" [(ngModel)]="searchValue" name="searchValue" [placeholder]="'log_lblSearch' | translate" maxlength="50">
            </div>

            <div class="form-field col-2">
                <button type="button" (click)="filter()" class="btn btn-outline" [disabled]="!form.valid">
                    <i class="fi fi-rs-filter"></i>
                    {{'filter' | translate}}
                </button>
            </div>
        </div>
    </form>

    <app-table [rows]="items" [columns]="columns" [pageSize]="20">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>{{row.UserName}}</td>
                <td>{{row.Name}}</td>
                <td>{{row.LastName}}</td>
                <td>{{row.Roles}}</td>
                <td>{{row.Created | appDate}}</td>
                <td>{{row.LastLogin | appDate}}</td>
            </tr>
        </ng-template>
    </app-table>
</div>
