import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { IticResponse, ITICData, IticHistory } from '../models/Itic';

@Injectable({ providedIn: "root" })
export class IticService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/itic` }

    getITICData(): Observable<ITICData> {
        const url = `${this.apiUrl}/actions/getiticdata`;
        return this.http.get<ITICData>(url);
    }

    getHistory(): Observable<IticHistory[]> {
        const url = `${this.apiUrl}/actions/gethistory`;
        return this.http.get<IticHistory[]>(url);
    }

    sendData(): Observable<IticResponse> {
        const url = `${this.apiUrl}/actions/send`;
        return this.http.post<IticResponse>(url, null);
    }
}
