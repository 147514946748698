<div class="autocomplete {{id}}"
     [title]="displayValue"
     [class.autocomplete-pos-after]="!alignBefore"
     [class.autocomplete-pos-before]="alignBefore"
     [class.autocomplete-menu-in]="isMenuReady"
     [class.is-disabled]="disabled"
     (keydown.arrowup)="!disabled && onArrowUp($event)"
     (keydown.arrowdown)="!disabled && onArrowDown($event)"
     (keydown.enter)="!disabled && onEnter($event)"
     #wrapper>
    <div class="autocomplete-input fc-icons-end">
        <input #trigger
               autocomplete="off"
               class="form-control text-truncate"
               [name]="id"
               [id]="id"
               [class.is-invalid]="required && !displayValue"
               [placeholder]="placeholder"
               [disabled]="disabled"
               [value]="displayValue"
               [attr.maxlength]="maxlength"
               (keyup)="onTextChange($event)"
               (focus)="onTriggerFocus()"
               (blur)="onTriggerBlur()" />
        <button (click)="clear()" (keydown.enter)="clear()" type="button" class="fc-icon-end autocomplete-clear" [ngbTooltip]="'clear' | translate"
                *ngIf="!!displayValue && !disabled">
            <i class="fi fi-rs-cross"></i>
        </button>
    </div>
    <div *ngIf="isOpened && options.length"
         class="autocomplete-menu autocomplete-menu-{{menuSize}}"
         tabindex="-1">

        <div class="autocomplete-options">
            <div *ngFor="let option of options | slice:0:limit; let i = index"
                 class="autocomplete-option autocomplete-option-{{i}}"
                 id="autocomplete-{{id}}-option-{{i}}"
                 [title]="getOptionDisplay(option)"
                 [class.autocomplete-option-hover]="option == currentOption"
                 [class.autocomplete-option-selected]="isSelected(option)"
                 (mousedown)="$event.preventDefault(); select(option)"
                 (keydown.tab)="onOptionTab($event)"
                 tabindex="0">

                <div *ngIf="optionTemplate; else defaultOptionTemplate">
                    <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
                </div>

                <ng-template #defaultOptionTemplate>
                    {{getOptionDisplay(option)}}
                </ng-template>
            </div>
        </div>
    </div>
</div>
