<div class="index-body">
    <main>
        <app-alert level="info" *ngIf="noItemAvailable && showNoItemAvailable">{{'applications_noItemAvailable' | translate}}</app-alert>

        <ngb-accordion #acc="ngbAccordion" (panelChange)="onPanelChange($event)" [activeIds]="openedGroupIds">
            <ng-container *ngFor="let g of groups">
                <ngb-panel [title]="g.title" [id]="g.id" *ngIf="g.visible">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <button class="accordion-button accordion-button-custom" ngbPanelToggle [class.collapsed]="!opened">
                                <i class="{{g.icon}}" *ngIf="g.icon"></i> {{g.title}}
                        </button>
                    </ng-template>

                    <ng-template ngbPanelContent>
                        <ul class="accordion-list">
                            <ng-container *ngFor="let n of g.items">
                                <li *ngIf="n.visible">
                                    <a [routerLink]="[n.url.route] | localize" [queryParams]="n.url.params">{{n.title}}</a>
                                    <a *ngIf="n.customUrl" [routerLink]="[n.customUrl] | localize">{{n.title}}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-template>
                </ngb-panel>
            </ng-container>
        </ngb-accordion>
    </main>

    <aside *ngIf="showNav">
        <a [routerLink]="['/applications/my'] | localize" class="btn btn-primary btn-icon-last">
            {{'myApplications_title' | translate}}
            <i class="fi fi-rs-angle-right"></i>
        </a>
    </aside>
</div>
