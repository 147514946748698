<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/purchases', text: 'purchases_title' },
                     { url: '/purchases/my', text: 'myPurchases_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'myPurchases_title'"></h1>
    </header>

    <app-table [rows]="items" [columns]="columns" [pageSize]="rowsPerPage">
        <ng-template #rowTemplate let-row>
            <tr>
                <td class="hidden-sm-down">{{getTypeDisplayName(row.Type)}}</td>
                <td><a href="{{getDocumentUrl(row)}}" target="_blank">{{row.Title}}</a></td>
                <td class="hidden-md-down">{{row.Created | appDate}}</td>
                <td class="hidden-md-down">{{row.Approved | appDate}}</td>
                <td class="hidden-md-down">{{row.RegNumber}}</td>
                <td class="hidden-md-down">{{row.SIK}}</td>
                <td class="hidden-lg-up">
                    <div class="table-actions">
                        <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'myPurchases_details' | translate">
                            <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                        </button>
                    </div>
                </td>
            </tr>
            <tr *ngIf="isExpanded(row)" class="table-row-details hidden-lg-up">
                <td colspan="9">
                    <div class="dl">
                        <div class="hidden-md-up">
                            <span>{{'myPurchases_lblCode' | translate}}</span>
                            <span>{{getTypeDisplayName(row.Type)}}</span>
                        </div>

                        <div>
                            <span>{{'myPurchases_lblSubmitDate' | translate}}</span>
                            <span>{{row.Created | appDate}}</span>
                        </div>

                        <div>
                            <span>{{'myPurchases_lblApproved' | translate}}</span>
                            <span>{{row.Approved | appDate}}</span>
                        </div>

                        <div>
                            <span>{{'myPurchases_lblRegNumber' | translate}}</span>
                            <span>{{row.RegNumber}}</span>
                        </div>

                        <div>
                            <span>{{'myPurchases_lblSIK' | translate}}</span>
                            <span>{{row.SIK}}</span>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
