<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">
        {{(disabled ? 'courseEqualization_viewCourseTitle' : model.Id ? 'courseEqualization_editCourseTitle' : 'courseEqualization_addCourseTitle') | translate}}
    </h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="form-field required">
            <label class="form-label">{{'courseEqualization_lblInnerCourse' | translate}}</label>
            <app-select [(ngModel)]="innerCourse"
                        [options]="innerCourseOptions"
                        [displayFn]="courseDisplayFn"
                        [required]="true"
                        [disabled]="disabled"
                        name="innerCourse"
                        [filter]="true"
                        [filterFn]="courseFilterFn">
            </app-select>
            <div class="feedback">{{'courseEqualization_hintInnerCourse' | translate}}</div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'courseEqualization_lblCourseType' | translate}}</label>
            <app-select [(ngModel)]="type"
                        [options]="typeOptions"
                        [displayProperty]="'Value'"
                        [required]="true"
                        [disabled]="disabled"
                        (selectionChange)="onTypeChange($event)"
                        name="type">
            </app-select>
            <div class="feedback">{{'courseEqualization_hintCourseType' | translate}}</div>
        </div>

        <div class="form-field required" *ngIf="universityVisible">
            <label class="form-label">{{'courseEqualization_lblUniversity' | translate}}</label>
            <input class="form-control" required [(ngModel)]="model.University" name="university" [disabled]="disabled" />
        </div>

        <div class="form-field required" *ngIf="programmeVisible">
            <label class="form-label">{{'courseEqualization_lblProgrammeName' | translate}}</label>
            <input class="form-control" required [(ngModel)]="model.Programme" name="programme" [disabled]="disabled" />
        </div>

        <div class="form-field required">
            <label class="form-label">{{'courseEqualization_lblCourseName' | translate}}</label>
            <input class="form-control" required [(ngModel)]="model.Name" name="name" [disabled]="disabled" />
            <div class="feedback">{{'courseEqualization_hintCourseName' | translate}}</div>
        </div>

        <div class="form-field" *ngIf="notesVisible">
            <label class="form-label">{{'courseEqualization_lblNotes' | translate}}</label>
            <textarea class="form-control" [(ngModel)]="model.Notes" rows="5" name="notes" [disabled]="disabled"></textarea>
        </div>

        <div class="col-sm form-field">
            <label class="form-label">{{'courseEqualization_lblWeeks' | translate}}</label>
            <input class="form-control" [(ngModel)]="model.Weeks" name="weeks" pattern="^[1-9][0-9]{0,2}$" [disabled]="disabled" />
            <div class="feedback">{{'courseEqualization_hintWeeks' | translate}}</div>
        </div>

        <div class="row">
            <div class="col-sm form-field required">
                <label class="form-label">{{'courseEqualization_lblEcts' | translate}}</label>
                <input class="form-control" [(ngModel)]="model.Ects" name="ects" required [pattern]="pointValidationPattern" [disabled]="disabled" />
                <div class="feedback">{{'courseEqualization_hintEcts' | translate}}</div>
            </div>

            <div class="col-sm form-field">
                <label class="form-label">{{'courseEqualization_lblFurtherEducationPoints' | translate}}</label>
                <input class="form-control" [(ngModel)]="model.FurtherEducationPoints" name="furtherEducationPoints" [pattern]="pointValidationPattern" [disabled]="disabled" />
            </div>
        </div>

        <div class="row">
            <div class="col-sm form-field">
                <label class="form-label">{{'courseEqualization_lblTheory' | translate}}</label>
                <app-select [(ngModel)]="model.TheoryGradeId"
                            [options]="gradeOptions"
                            [valueProperty]="'Id'"
                            [displayProperty]="'Value'"
                            [disabled]="disabled"
                            name="theory">
                </app-select>
                <div class="feedback">{{'courseEqualization_hintTheory' | translate}}</div>
            </div>

            <div class="col-sm form-field required">
                <label class="form-label">{{'courseEqualization_lblPractice' | translate}}</label>
                <app-select [(ngModel)]="model.PracticeGradeId"
                            [options]="gradeOptions"
                            [valueProperty]="'Id'"
                            [displayProperty]="'Value'"
                            [required]="true"
                            [disabled]="disabled"
                            name="practice">
                </app-select>
                <div class="feedback">{{'courseEqualization_hintPractice' | translate}}</div>
            </div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'courseEqualization_lblAttachments' | translate}}</label>

            <div *ngFor="let a of model.Attachments; let i = index" class="d-flex mb-2">
                <div class="flex-grow-1">
                    <app-file [(ngModel)]="a.File"
                              [accept]="attachmentExtensions"
                              [maxSize]="attachmentMaxSize"
                              name="attachment{{i}}"
                              [immediate]="!a.Id && !a.File && !a.FileName"
                              [disabled]="disabled"
                              (fileChange)="onFileChange($event, a)"
                              #attachmentInput="ngModel"></app-file>
                    <div class="invalid-feedback" *ngIf="!a.FileName">{{'courseEqualization_emptyAttachment' | translate}}</div>
                    <div class="invalid-feedback" *ngIf="attachmentInput.errors?.maxSize">{{attachmentInput.errors?.maxSize}}</div>
                    <div class="invalid-feedback" *ngIf="attachmentInput.errors?.accept">{{attachmentInput.errors?.accept}}</div>
                    <div class="invalid-feedback" *ngIf="checkDuplicateFileName(a)">{{'courseEqualization_duplicateFileName' | translate}}</div>
                </div>

                <button class="btn btn-outline btn-icon align-self-end ms-3" *ngIf="a.Id"
                        (click)="downloadAttachment(a)" [ngbTooltip]="'download' | translate">
                    <i class="fi fi-rs-download"></i>
                </button>
            </div>

            <div *ngIf="!disabled">
                <button class="btn btn-outline" type="button" (click)="addAttachment()">
                    <i class="fi fi-rs-plus"></i>
                    {{'courseEqualization_btnAddFile' | translate}}
                </button>
            </div>

            <div class="feedback">{{'courseEqualization_hintAttachments' | translate}}</div>
            <div class="invalid-feedback" *ngIf="model.Attachments.length < 1">{{'courseEqualization_attachmentRequired' | translate}}</div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()">
        {{(disabled ? 'dialog_close' : 'dialog_cancel') | translate}}
    </button>

    <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="!isValid" *ngIf="!disabled">
        {{'dialog_ok' | translate}}
    </button>
</div>
