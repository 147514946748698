import { Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../services/app.service';
import { MessageService } from '../../services/message.service';

export interface IWaitDialogData {
    onRetry?: () => void;
    waitWhen?: () => boolean;
    cancelWhen?: () => boolean;
    retryWhen?: () => boolean;
}

const messageCodes = {
    timeout: 'LOAD_CONTROL_TIMEOUT',
    waiting: 'LOAD_CONTROL_WAITING'
};

const cache = {
    messages: undefined
};

@Component({
    template: `
    <a href="#" style="display: none"></a>
    <div class="modal-body">
        <div class="progress" *ngIf="showSpinner">
            <div class="progress-bar"></div>
        </div>
        <div [innerHTML]="showRetry ? timeoutMessage : waitingMessage"></div>
    </div>
    <div class="modal-footer" *ngIf="showCancel || showRetry">
        <button type="button" class="btn btn-outline" (click)="cancel()" *ngIf="showCancel">{{'cancel' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="retry()" *ngIf="showRetry">{{'retry' | translate}}</button>
    </div>
    `,
    styles: [`
    .modal-body { text-align: center; }
    .progress { margin-bottom: 12px; }
    `]
})
export class WaitDialogComponent implements OnInit {
    constructor(
        private app: AppService,
        private messages: MessageService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() data: IWaitDialogData;

    timeoutMessage: string;
    waitingMessage: string;

    get onRetry(): () => void {
        return this.data?.onRetry;
    }

    get waitWhen(): () => boolean {
        return this.data?.waitWhen || (() => true)
    }

    get cancelWhen(): () => boolean {
        return this.data?.cancelWhen || (() => false);
    }

    get retryWhen(): () => boolean {
        return this.data?.retryWhen || (() => false);
    }

    get showSpinner(): boolean {
        return this.waitWhen();
    }

    get showCancel(): boolean {
        return this.cancelWhen();
    }

    get showRetry(): boolean {
        return this.retryWhen();
    }

    ngOnInit() {
        if (cache.messages) {
            this.timeoutMessage = cache.messages.timeout;
            this.waitingMessage = cache.messages.waiting;
        } else {
            const loading = this.app.showLoading();

            this.messages.getForLoadControl(this.app.currentLanguage).subscribe(data => {
                this.app.hideLoading(loading);

                this.timeoutMessage = data.find(t => t.Code == messageCodes.timeout)?.Text;
                this.waitingMessage = data.find(t => t.Code == messageCodes.waiting)?.Text;

                cache.messages = {
                    timeout: this.timeoutMessage,
                    waiting: this.waitingMessage
                };
            });
        }
    }

    retry() {
        if (this.onRetry) {
            this.onRetry();
        }
    }

    cancel() {
        this.activeModal.close({ cancelled: true });
    }
}
