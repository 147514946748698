<div class="modal-header">
    <h4 class="modal-title">{{ 'studyQualityEvaluation_rejectEvaluationTitle' | translate }}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="form-field required">
            <label class="form-label">{{ 'studyQualityEvaluation_lblResidencyComment' | translate }}</label>
            <textarea class="form-control" name="residencyComment" [(ngModel)]="model.ResidencyComment" required  maxlength="4000"></textarea>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button class="btn btn-primary" (click)="save()" type="button" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'save' | translate}}
    </button>

    <button class="btn btn-outline-primary" (click)="save(true)" type="button" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSubmitting"></span>
        <i class="fi fi-rs-paper-plane" *ngIf="!isSubmitting"></i>
        {{'submit' | translate}}
    </button>
</div>
