import { Component, OnInit } from '@angular/core';

import { MyPurchase } from '../../models/Purchase';
import { Classifier } from '../../models/Classifier';
import { AppService } from '../../services/app.service';
import { PurchaseService } from '../../services/purchase.service';
import { ClassifierService } from '../../services/classifier.service';
import { ParameterService } from '../../services/parameter.service';
import { Utils } from '../../core/Utils';
import { ITableColumn } from '../../shared/table/table.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-my-purchases',
    templateUrl: './my-purchases.component.html',
    styleUrls: ['./purchase.component.scss']
})
export class MyPurchasesComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: PurchaseService,
        private classifiers: ClassifierService,
        private parameters: ParameterService
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'Type', label: 'myPurchases_lblCode', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Title', label: 'myPurchases_lblTitle', sorts: true },
        { property: 'Created', label: 'myPurchases_lblSubmitDate', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { property: 'Approved', label: 'myPurchases_lblApproved', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { property: 'RegNumber', label: 'myPurchases_lblRegNumber', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'SIK', label: 'myPurchases_lblSIK', sorts: true, cssClass: 'hidden-md-down' },
        { width: '1px', cssClass: 'hidden-lg-up' }
    ];

    items: MyPurchase[] = [];
    rowsPerPage: number = 10;

    private shortNames: Classifier[] = [];
    private langEn: boolean;
    private dvsDocUrl: string = '';
    private expanded: MyPurchase[] = [];

    ngOnInit() {
        this.langEn = this.app.currentLanguage === 'en';

        this.app.addLoading(forkJoin(
            this.service.getMy(),
            this.classifiers.get('PurchaseShortName'),
            this.parameters.getValues()
        )).subscribe(data => {
            let [items, shortNames, para] = data;

            this.shortNames = shortNames;
            this.items = items;

            let pageSizeItem = para.find(t => t.Code === 'PurchaseHistoryPageSize');

            if (pageSizeItem)
                this.rowsPerPage = parseInt(pageSizeItem.Value) || 10;

            let dvsDocUrlItem = para.find(t => t.Code === 'PurchaseDvsDocUrl');

            if (dvsDocUrlItem)
                this.dvsDocUrl = dvsDocUrlItem.Value;
        });
    }

    getTypeDisplayName(applicationType: string): string {
        let item = this.shortNames.find(t => t.Code === `PurchaseShortName${applicationType}`);

        if (!item)
            return applicationType;

        return this.langEn ? item.ValueEN : item.Value;
    }

    getDocumentUrl(row: MyPurchase): string {
        return Utils.formatString(this.dvsDocUrl, [row.DvsDocumentId]);
    }

    toggle(row: MyPurchase) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: MyPurchase): boolean {
        return this.expanded.indexOf(row) > -1;
    }
}
