import { StudentCoursesItem } from '../../models/BC_courses';

export class Helpers {
    static canAddProgram(program: StudentCoursesItem): boolean {
        return program.IsAvailable;
    }

    static canRemoveProgram(program: StudentCoursesItem): boolean {
        return program.Application_statuss_code == 866560002 && program.CanWithdraw;
    }
}
