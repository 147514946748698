<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'portfolio_shareTitle' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm" [class.was-validated]="isSubmitted">
        <div class="form-field required">
            <label class="form-label">{{'portfolio_lblAttachments' | translate}}</label>
            <div>
                <div class="mt-1" *ngFor="let n of attachments">
                    <app-checkbox [(ngModel)]="attachmentsModel[n.Index]" name="attachment-{{n.Index}}">
                        {{prettyPath(n.Path)}}
                    </app-checkbox>
                </div>
            </div>
            <div class="invalid-feedback" *ngIf="isSubmitted && !includedAttachments.length">
                {{'portfolio_shareAttachmentRequired' | translate}}
            </div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'portfolio_lblShareExpiry' | translate}}</label>
            <app-select [(ngModel)]="expiry"
                        [options]="options.expiry"
                        [required]="true"
                        [displayProperty]="'text'"
                        [valueProperty]="'value'"
                        name="expiry">
            </app-select>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'portfolio_lblShareEmail' | translate}}</label>
            <input class="form-control" email [(ngModel)]="email" name="email" required />
            <div class="invalid-feedback" *ngIf="isSubmitted && form.controls.email?.errors?.email">
                {{'fieldError_email' | translate}}
            </div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'portfolio_lblSharePurpose' | translate}}</label>
            <textarea class="form-control"
                      [(ngModel)]="purpose"
                      rows="5"
                      name="purpose"
                      maxlength="1000"
                      required>
            </textarea>
            <div class="invalid-feedback" *ngIf="isSubmitted && form.controls.purpose?.errors?.maxlength">
                {{'fieldError_lengthMinMax' | translate | formatString:[0, 1000]}}
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isLoading">{{'dialog_cancel' | translate}}</button>
    <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="isLoading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>
        {{'dialog_ok' | translate}}
    </button>
</div>
