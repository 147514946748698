import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IGradeSaveModel, IGradesViewModel } from '../models/AddGrades';

/**
 * Study course grade service
 */
@Injectable({ providedIn: "root" })
export class GradeService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/grades` }

    getByStudent(studentId: string, subjectId: string): Observable<IGradesViewModel> {
        return this.http.get<IGradesViewModel>(this.apiUrl, { params: { studentId, subjectId } });
    }

    save(studentId: string, subjectId: string, data: {
        lecturerId: string,
        rowId?: string,
        studyYear: number,
        semester: number,
        grades: IGradeSaveModel[],
        isFinal: boolean
    }): Observable<any> {
        return this.http.post<any>(this.apiUrl, { ...data, studentId, subjectId });
    }

    delete(gradeRowId: string, reason: string): Observable<any> {
        const url = `${this.apiUrl}/${gradeRowId}`;
        return this.http.delete(url, { body: { reason } });
    }
}
