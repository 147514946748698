import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

export enum AlertMessageType {
    Info = 'info',
    Success = 'success',
    Error = 'error',
    Warning = 'warning'
}

export class AlertMessage {
    constructor(
        public text: string,
        public type: AlertMessageType = AlertMessageType.Info,
        public title?: string
    ) { }
}

/**
 * Alert service
 * @class
 * @desc Used to show alerts.
 */
@Injectable({ providedIn: "root" })
export class AlertService {
    private subject = new Subject<any>();

    success(message: string, title?: string) {
        this.setMessage(message, AlertMessageType.Success, title);
    }

    error(message: string, title?: string) {
        this.setMessage(message, AlertMessageType.Error, title);
    }

    info(message: string, title?: string) {
        this.setMessage(message, AlertMessageType.Info, title);
    }

    warning(message: string, title?: string) {
        this.setMessage(message, AlertMessageType.Warning, title);
    }

    clear() {
        this.setMessage(null);
    }

    getMessage(): Observable<AlertMessage> {
        return this.subject.asObservable();
    }

    private setMessage(text: string, type: AlertMessageType = AlertMessageType.Info, title?: string) {
        if (text !== undefined) {
            this.subject.next(new AlertMessage(text, type, title));
        }
    }
}
