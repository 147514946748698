import { Component, OnInit } from '@angular/core';
import { ClassifierService } from '../../services/classifier.service';
import { Classifier } from '../../models/Classifier';
import { MessageService } from '../../services/message.service';
import { AppService } from '../../services/app.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	constructor(
		private app: AppService,
		private classifierService: ClassifierService,
        private messages: MessageService
	) {}

    footerText: string;
    columns: { items: Classifier[] }[];

	ngOnInit() {
		const prefix = `Footer-${this.app.currentLanguage}-`;

        this.classifierService.getForFooter(this.app.currentLanguage).subscribe(data => {
			this.columns = [];
			for (let i = 1; i <= 4; i++) {
				let column = { items: [] };
				column.items = data
					.filter(classifier => classifier.Type === prefix + i)
					.sort((classifier1, classifier2) =>
						classifier1.Code.localeCompare(classifier2.Code)
					);
				this.columns.push(column);
			}
		});

        this.messages.getFooterCopyright(this.app.currentLanguage).subscribe(data => {
            const currDate = new Date();
            this.footerText = data.replace('{{year}}', '' + currDate.getFullYear());
        });
	}
}
