import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { IPortfolioShare } from '../../../models/Portfolio';
import { AppService } from '../../../services/app.service';
import { PortfolioService } from '../../../services/portfolio.service';

import * as FileSaver from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../../../core/Utils';

@Component({
    selector: 'app-portfolio-shared',
    templateUrl: './portfolio-shared.component.html'
})
export class PortfolioSharedComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PortfolioService,
        private route: ActivatedRoute
    ) { }

    data: IPortfolioShare;
    intro: string;
    error: string;
    isConfirmed: boolean;
    isDownloaded: boolean;

    private id: string;

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];

        this.app.addLoading(this.service.getShare(this.id)).subscribe(data => {
            this.data = data;

            this.intro = this.app.translate('portfolio_shareIntro')
                .replace('{{name}}', data.PersonName)
                .replace('{{surname}}', data.PersonSurname);
        }, err => {
            this.error = this.app.getHttpResponseError(err);
        });
    }

    download() {
        this.app.addLoading(this.service.downloadShare(this.id)).subscribe(result => {
            this.isDownloaded = true;
            FileSaver.saveAs(result, `${this.data.PersonName} ${this.data.PersonSurname} portfolio.zip`);
        });
    }
}
