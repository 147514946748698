import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IManipulationModel, IManipulationSkillLevel, IManipulationWorkplace, ISpecialityManipulation } from "../../models/Manipulation";
import { AppService } from "../../services/app.service";
import { Subject, Subscription } from 'rxjs';
import { ManipulationService } from "../../services/manipulation.service";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { MessageService } from "../../services/message.service";
import { ClassifierService } from "../../services/classifier.service";
import { Classifier } from "../../models/Classifier";

export const userRights = {
    setStudent: 'MANIPULATION.SET_STUDENT'
};

export interface IManipulationEditData {
    model: IManipulationModel;
    skillLevels: IManipulationSkillLevel[];
    specialityManipulations: ISpecialityManipulation[];
    residentWorkplaceId: string;
    residentWorkplaceName: string;
    isFinal: boolean;
}

let levelHelpTextCached: string = undefined;

@Component({
    selector: 'app-manipulation-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./shared.css']
})
export class ManipulationEditComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: ManipulationService,
        private classifiers: ClassifierService,
        private messages: MessageService,
        private activeModal: NgbActiveModal,
        private formBuilder: FormBuilder
    ) { }

    @Input() set data(value: IManipulationEditData) {
        this.manipulationData = value;

        if (this.data.model.Workplace) {
            this.workplaceOptions.push(this.data.model.Workplace);
        }

        let competence: Classifier;

        if (this.data.model.Competence) {
            competence = <Classifier>{
                Value: this.data.model.Competence,
                Id: this.data.model.CompetenceId
            };
            this.competenceOptions.push(competence);
        }

        this.fields = this.formBuilder.group({
            comment: [this.data.model.Notes, [Validators.maxLength(4000)]],
            count: [this.data.model.Count, [Validators.required]],
            manipulation: [this.data.model.SpecialityManipulation, []],
            medicalPerson: [this.data.model.MedicalPerson, []],
            otherManipulation: [this.data.model.OtherSpecialityManipulation, []],
            otherDescription: [this.data.model.OtherSpecialityManipulationDescription, []],
            otherManipulationSkillLevel: [this.data.model.OtherSpecialityManipulationSkillLevel, []],
            otherManipulationName: [this.data.model.OtherSpecialityManipulationName, []],
            otherWorkplace: [this.data.model.OtherWorkplace, []],
            otherWorkplaceName: [this.data.model.OtherWorkplaceName, []],
            workplace: [this.data.model.Workplace, []],
            competence: [competence]
        });
    };

    fields: FormGroup;
    workplaceOptions: IManipulationWorkplace[] = [];
    competenceOptions: Classifier[] = [];
    showLevelHelp: boolean;

    readonly manipulationDisplayFn = (option: ISpecialityManipulation) => `${option.SkillLevel.Code} - ${this.getSpecialityManipulationName(option)}`;
    readonly skillLevelDisplayFn = (option: IManipulationSkillLevel) => `${option.Code} (${option.Name})`;

    private manipulationData: IManipulationEditData = {
        model: null,
        skillLevels: [],
        specialityManipulations: [],
        residentWorkplaceId: null,
        residentWorkplaceName: null,
        isFinal: false
    };
    private workplacesSubscription: Subscription;
    private readonly workplacesSubject = new Subject<string>();

    get data(): IManipulationEditData {
        return this.manipulationData;
    }

    get levelHelpText(): string {
        return levelHelpTextCached;
    }

    ngOnInit() {
        this.workplacesSubscription = this.workplacesSubject.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value =>
            this.loadWorkplaceOptions(value?.toString())
        );

        if (this.data.model.SpecialityManipulation)
            this.fields.controls.manipulation.setValue(
                this.data.specialityManipulations.find(t => t.SkillAcquirableId == this.data.model.SpecialityManipulation.SkillAcquirableId)
            );

        if (this.data.model.OtherSpecialityManipulationSkillLevel)
            this.fields.controls.otherManipulationSkillLevel.setValue(
                this.data.skillLevels.find(t => t.Id == this.data.model.OtherSpecialityManipulationSkillLevel.Id)
            );

        if (!this.data.model.Workplace && this.data.residentWorkplaceId) {
            const workplace = <IManipulationWorkplace> {
                Id: this.data.residentWorkplaceId,
                Name: this.data.residentWorkplaceName
            };

            this.workplaceOptions = [workplace];

            this.fields.controls.workplace.setValue(workplace);
        }

        if (this.data.isFinal) {
            this.fields.disable();
        } else {
            if (levelHelpTextCached == undefined) {
                this.app.addLoading(this.messages.getByCode('MANIPULATION_LEVEL_HELP')).subscribe(data => {
                    if (data) {
                        levelHelpTextCached = this.app.currentLanguage == 'en' ? data.TextEN : data.TextLV;
                    } else {
                        levelHelpTextCached = '';
                    }
                });
            }

            this.app.addLoading(this.classifiers.get('ManipulationCompetence')).subscribe(data => {
                const selected = data.find(t => t.Id == this.data.model.CompetenceId);

                if (selected) {
                    this.fields.get('competence').setValue(selected);
                }

                this.competenceOptions = data;
            });
        }
    }

    ngOnDestroy() {
        this.workplacesSubscription.unsubscribe();
    }

    getSpecialityManipulationName(specialityManipulation: ISpecialityManipulation) {
        if (this.app.currentLanguage.toLowerCase() != 'en')
            return specialityManipulation?.Name;
        else
            return specialityManipulation?.NameEn;
    }

    filterWorkplaces(value: string) {
        if (value?.length < 3)
            this.workplaceOptions = [];
        else {
            this.fields.controls.workplace.setValue(null);
            this.workplacesSubject.next(value);
        }
    }

    ok() {
        if (!this.fields.valid)
            return;

        this.data.model.Notes = this.fields.value.comment;
        this.data.model.SpecialityManipulation = this.fields.value.manipulation;
        this.data.model.MedicalPerson = this.fields.value.medicalPerson;
        this.data.model.OtherSpecialityManipulation = this.fields.value.otherManipulation;
        this.data.model.OtherSpecialityManipulationDescription = this.fields.value.otherDescription;
        this.data.model.OtherSpecialityManipulationSkillLevel = this.fields.value.otherManipulationSkillLevel;
        this.data.model.OtherSpecialityManipulationName = this.fields.value.otherManipulationName;
        this.data.model.OtherWorkplace = this.fields.value.otherWorkplace;
        this.data.model.OtherWorkplaceName = this.fields.value.otherWorkplaceName;
        this.data.model.Count = this.fields.value.count;
        this.data.model.Workplace = this.fields.value.workplace;
        this.data.model.Competence = this.fields.value.competence?.Value;
        this.data.model.CompetenceId = this.fields.value.competence?.Id;

        this.activeModal.close();
    }

    cancel() {
        this.activeModal.dismiss();
    }

    onOtherManipulationChange() {
        this.fields.controls.manipulation.setValue(null);
        this.fields.controls.otherManipulationName.setValue(null);
        this.fields.controls.otherDescription.setValue(null);
        this.fields.controls.otherManipulationSkillLevel.setValue(null);
    }

    onOtherWorkplaceChange() {
        this.fields.controls.workplace.setValue(null);
        this.fields.controls.otherWorkplaceName.setValue(null);
    }

    private loadWorkplaceOptions(filter: string) {
        this.app.addLoading(this.service.getWorkplaces(filter)).subscribe(data => this.workplaceOptions = data);
    }
}
