import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';
import { FinalPaperProtocolService } from '../../services/final-paper-protocol.service';
import { ITableColumn } from '../../shared/table/table.component';
import { IFinalPaperProtocol, IFinalPaperProtocolFile } from '../../models/FinalPaperProtocol';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FinalPaperProtocolFilesComponent } from './final-paper-protocol-files.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { store } from './store';

interface IRow extends IFinalPaperProtocol {
    typeDisplay: string;
}

@Component({
    selector: 'app-final-paper-protocol-list',
    templateUrl: './final-paper-protocol-list.component.html'
})
export class FinalPaperProtocolListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: FinalPaperProtocolService,
        private modal: NgbModal
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'AcademicYearName', label: 'finalPaperProtocol_lblAcademicYear', sorts: true },
        { property: 'Name', label: 'finalPaperProtocol_lblName', sorts: true },
        { property: 'Type', label: 'finalPaperProtocol_lblType', sorts: true },
        { property: 'RegNumber', label: 'finalPaperProtocol_lblRegNumber', sorts: true },
        { width: '1px' }
    ];

    items: IRow[] = [];
    searchValue: string;
    selectedYear: string;
    selectedType: string;

    readonly options: {
        year: string[],
        type: { text: string, value: string }[]
    } = {
            year: [],
            type: []
        };

    private allItems: IRow[] = [];
    private readonly files: { [protocolId: number]: IFinalPaperProtocolFile[] } = {};
    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            this.filter();
        });

        this.app.addLoading(this.service.get(store.getPerson()?.Upn)).subscribe(data => {
            this.items = this.allItems = data.map(t => {
                const typeKey = `finalPaperProtocol_type${t.TypeGroupCode}`;
                const typeText = this.app.translate(`finalPaperProtocol_type${t.TypeGroupCode}`);

                const typeDisplay = typeText == typeKey ? t.TypeGroupCode : typeText;

                return {
                    ...t,
                    typeDisplay
                };
            });

            const years: string[] = [];
            const types: { text: string, value: string }[] = [];

            this.items.forEach(t => {
                if (!years.includes(t.AcademicYearName)) years.push(t.AcademicYearName);

                if (!types.some(n => n.value == t.TypeGroupCode)) {
                    types.push({
                        text: t.typeDisplay,
                        value: t.TypeGroupCode
                    });
                }
            });

            this.options.year = years;
            this.options.type = types;
        });
    }

    search() {
        this.searchSubj.next(this.searchValue);
    }

    filter() {
        if (this.filterEmpty()) {
            this.items = this.allItems;
        } else {
            const sv = this.searchValue?.toLowerCase();

            this.items = this.allItems.filter(t => {
                if (this.selectedType && t.TypeGroupCode != this.selectedType) return false;
                if (this.selectedYear && t.Type != this.selectedYear) return false;

                if (sv) {
                    return (t.AcademicYearName.toLowerCase().includes(sv)
                        || t.Name.toLowerCase().includes(sv)
                        || t.RegNumber.toLowerCase().includes(sv)
                        || t.typeDisplay.toLowerCase().includes(sv)
                    );
                }

                return true;
            });
        }
    }

    open(protocol: IFinalPaperProtocol) {
        this.getFiles(protocol).subscribe(files => {
            const rows = files.map(t => {
                return {
                    name: t.Name,
                    url: this.service.getFileDownloadUrl(protocol.Id, t.Id, store.getPerson()?.Upn)
                };
            });

            const ref = this.modal.open(FinalPaperProtocolFilesComponent);
            ref.componentInstance.title = protocol.Name;
            ref.componentInstance.files = rows;
            ref.result.then(() => { }, () => { });
        });
    }

    private getFiles(protocol: IFinalPaperProtocol) {
        if (this.files[protocol.Id]) {
            return of(this.files[protocol.Id]);
        }

        return this.app.addLoading(this.service.getFiles(protocol.Id, store.getPerson()?.Upn)).pipe(tap(files => {
            this.files[protocol.Id] = files;
        }));
    }

    private filterEmpty() {
        return !this.searchValue && !this.selectedType && !this.selectedYear;
    }
}
