<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/messages', text: 'messages_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'messages_title'"></h1>
        <aside>
            <button class="btn btn-outline-primary" type="button" [routerLink]="['/messages/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <form #filterForm="ngForm">
        <div class="row">
            <div class="col-2 form-field">
                <input class="form-control" [(ngModel)]="codeSearchValue" name="codeSearchValue" [placeholder]="'messages_code' | translate" maxlength="50">
            </div>

            <div class="col-2 form-field">
                <input class="form-control" [(ngModel)]="groupSearchValue" name="groupSearchValue" [placeholder]="'messages_colgroup' | translate" maxlength="50">
            </div>

            <div class="col-2 form-field">
                <input class="form-control" [(ngModel)]="titleSearchValue" name="titleSearchValue" [placeholder]="'messages_coltitle' | translate" maxlength="50">
            </div>

            <div class="col-2 form-field">
                <input class="form-control" [(ngModel)]="textLvSearchValue" name="textLvSearchValue" [placeholder]="'messages_colTextLv' | translate" maxlength="50">
            </div>

            <div class="col-2 form-field">
                <input class="form-control" [(ngModel)]="textEnSearchValue" name="textEnSearchValue" [placeholder]="'messages_colTextEn' | translate" maxlength="50">
            </div>

            <div class="col-2 form-field">
                <button type="button" class="btn btn-outline" (click)="filter()" [disabled]="!filterForm.valid || isFiltering">
                    <i class="fi fi-rs-filter" *ngIf="!isFiltering"></i>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isFiltering"></span>
                    {{'filter' | translate}}
                </button>
            </div>
        </div>
    </form>

    <app-table [rows]="items" [columns]="columns" [sticky]="'right'" [(page)]="page">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    {{row.Code}}
                    <div class="text-sub">{{row.Title}}</div>
                </td>
                <td>{{row.Group}}</td>
                <td [title]="row.TextLV">
                    <div *ngFor="let n of splitMessage(row.TextLV); let i = index" class="text-nowrap">
                        {{n}}
                    </div>
                </td>
                <td [title]="row.TextEN">
                    <div *ngFor="let n of splitMessage(row.TextEN); let i = index" class="text-nowrap">
                        {{n}}
                    </div>
                </td>
                <td>
                    <div class="table-actions">
                        <button type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'edit' | translate"
                                [routerLink]="['/messages', row.Id] | localize">
                            <i class="fi fi-rs-pencil"></i>
                        </button>

                        <button type="button" (click)="delete(row)" class="btn btn-outline btn-icon" [ngbTooltip]="'delete' | translate">
                            <i class="fi fi-rs-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
