import { formatDate } from '@angular/common';
import { StudentEventItem } from '../../models/Said_events';

export class Helpers {
    static canAddProgram(program: StudentEventItem): boolean {
        return !program.GroupIsFull;
    }

    static canRemoveProgram(program: StudentEventItem): boolean {
        return !Helpers.validateDate(program.RegistrationOpenDate, program.RegistrationCloseDate);//program.Application_statuss_code == 866560002 //&& program.CanWithdraw; //??? jāmaina nosacījumi[disabled]="validateDate(c.RegistrationOpenDate, c.RegistrationCloseDate)"
    }

    static validateDate(startDate: Date, endDate: Date) {
        //return (formatDate(new Date(startDate), "dd.MM.yyyy", "lv-LV") > formatDate(new Date(), "dd.MM.yyyy", "lv-LV")) || (formatDate(new Date(endDate), "dd.MM.yyyy", "lv-LV") < formatDate(new Date(), "dd.MM.yyyy", "lv-LV"));
        return (this.removeTime(new Date(startDate)) > this.removeTime(new Date())) || (this.removeTime(new Date(endDate)) < this.removeTime(new Date()));
    }
    
    static removeTime(dt: Date) {
        let newDt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        return newDt;
    }
}
