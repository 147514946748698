<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'said_eventsDialog_title' | translate}}</h4>
</div>

<div class="modal-body">
    <div class="data-value" *ngFor="let d of details; let i = index">
        <label>{{d.Label}}</label>
        <div [innerHTML]="d.Value || '-'"></div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
        {{'dialog_close' | translate}}
    </button>
</div>
