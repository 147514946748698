import {
    Component, EventEmitter, Input, Output, ViewChild,
    ElementRef, HostListener, ContentChild, TemplateRef, OnInit, forwardRef
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';

let counter = 0;

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        }
    ]
})
export class AutocompleteComponent<T> implements OnInit, ControlValueAccessor {
    constructor() {
        this.id = `autocomplete-${counter++}`;
    }

    @Input() placeholder: string = '';
    @Input() options: T[] = [];
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() maxlength: number = 50;
    @Input() limit: number = 10;
    @Input() valueFn: (item: T) => any;
    @Input() valueProperty: string;
    @Input() displayFn: (item: T) => string;
    @Input() displayProperty: string;
    @Input() menuSize: 'fit' | 'full' | 'auto' = 'auto';

    @Output() valueChange = new EventEmitter<T | T[] | any>();
    @Output('search') searchEvent = new EventEmitter<string>();

    @ContentChild('optionTemplate', { static: true }) optionTemplate: TemplateRef<any>;

    readonly id: string;

    currentOption: T;
    searchValue: string;
    alignBefore: boolean;
    isMenuReady: boolean;

    get displayValue(): string {
        const selected = (this.options || []).find(t => this.getOptionValue(t) == this._value);
        return this.getOptionDisplay(selected) || '';
    }

    get isOpened(): boolean {
        return this._isOpened;
    }

    @ViewChild('wrapper', { static: true }) private wrapperElement: ElementRef;
    @ViewChild('trigger', { static: true }) private triggerElement: ElementRef;
    @ViewChild('menu', { static: false }) private menuElement: ElementRef;

    private _onChange = (obj: T | T[] | any) => { };
    private _value: T | T[] | any;
    private _isOpened: boolean;

    ngOnInit() {
        if (this._value) {
            if (this.triggerElement) {
                this.triggerElement.nativeElement.value = this.getOptionDisplay(this._value);
            }
        }
    }

    //ControlValueAccessor
    writeValue(obj: T | T[] | any): void {
        this._value = obj;
    }

    registerOnChange(fn: (obj: T | T[] | any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //ControlValueAccessor

    toggle() {
        if (this.isOpened) {
            this.close();
        } else {
            this.open();
        }
    }

    open() {
        if (this.isOpened) {
            return;
        }

        const rect = this.wrapperElement.nativeElement.getBoundingClientRect();
        const spaceBefore = rect.top;
        const spaceAfter = document.body.clientHeight - rect.bottom;

        this._isOpened = true;

        setTimeout(() => {
            const menuRect = this.menuElement?.nativeElement.getBoundingClientRect();

            if (menuRect) {
                this.alignBefore = spaceAfter < menuRect.height && spaceBefore > spaceAfter && spaceBefore > menuRect.height;
            }

            this.isMenuReady = true;
        }, 10);
        
        this.currentOption = undefined;
    }

    close() {
        if (!this.isOpened) {
            return;
        }

        this._isOpened = false;
        this.currentOption = undefined;
    }

    clear() {
        this._value = undefined;

        this.triggerElement.nativeElement.value = '';
        this.triggerElement.nativeElement.focus();

        this.close();

        this.emitChange();
    }

    select(option: T) {
        this.currentOption = option;

        const val = this.getOptionValue(option);

        this._value = val;

        this.close();

        this.triggerElement.nativeElement.value = this.searchValue = this.getOptionDisplay(option);

        this.emitChange();
    }

    isSelected(option: T) {
        return option == this._value;
    }

    onTextChange(event) {
        if (event.keyCode === 13)
            return;

        const term = event.target.value;

        if (term != this.searchValue) {
            this.searchValue = term;
            this.searchEvent.emit(term || '');
            this.open();
        }
    }

    onEnter(event: KeyboardEvent) {
        this.cancelEvent(event);

        if (this.isOpened) {
            if (this.currentOption) {
                this.select(this.currentOption);
                this.emitChange();
            }
        }
    }

    onArrowDown(event) {
        this.cancelEvent(event);

        this.open();

        const opts = this.options.slice(0, this.limit);
        const ix = opts.indexOf(this.currentOption);

        // last option
        if (ix == opts.length - 1) {
            return;
        }

        // wait for the panel to be opened
        setTimeout(() => {
            this.currentOption = opts[ix + 1];
        }, 1);
    }

    onArrowUp(event) {
        this.cancelEvent(event);

        const opts = this.options.slice(0, this.limit);
        const ix = opts.indexOf(this.currentOption);

        if (ix > 0) {
            this.currentOption = opts[ix - 1];
        }
    }

    onOptionTab(event) {
        this.close();
    }

    onTriggerFocus() {
        this.open();
    }

    onTriggerBlur() {
        this.close();
    }

    getOptionDisplay(option: T): string {
        let fn = this.displayFn;

        if (!fn) {
            if (this.displayProperty) {
                fn = (item: T) => item ? item[this.displayProperty] : '';
            }
        }

        if (!fn) {
            fn = (item: T) => item?.toString();
        }

        return fn(option);
    }

    getOptionValue(option: T): any {
        let value: any;

        if (this.valueFn) {
            value = this.valueFn(option);
        } else if (this.valueProperty) {
            value = option ? option[this.valueProperty] : null;
        } else {
            value = option;
        }

        return value;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event) {
        if (this.isOpened) {
            if (!event.target.closest(`.${this.id}`)) {
                this.close();
            }
        }
    }

    private cancelEvent(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    private emitChange() {
        this._onChange(this._value);
        this.valueChange.emit(this._value);
    };
}
