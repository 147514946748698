<div class="container">
    <div class="wrapper-md">
        <app-breadcrumbs [items]="[{ url: '/', text: 'home_title' }]"></app-breadcrumbs>

        <app-alert level="info" *ngIf="showWaitMessage != ''">{{showWaitMessage | translate}}</app-alert>

        <app-tiles [items]="tiles" [show]="'primary'" *ngIf="isAuthenticated"></app-tiles>

        <ng-container *ngIf="!isAuthenticated">
            <p>
                <a [href]="loginUrl" [translate]="'loginlabel'"></a>
            </p>
            <p>
                <a [routerLink]="['/password/request'] | localize" [translate]="'home_resetPassword'"></a>
            </p>
        </ng-container>
    </div>
</div>

<div class="secondary" *ngIf="isAuthenticated">
    <div class="ribbon">
        <div class="container">
            <div class="wrapper-md">
                <div class="ribbon-text">{{'home_categories_title' | translate}}</div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="wrapper-md">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
                <li [ngbNavItem]="i + 1" [class.hidden-sm-down]="i > 1" *ngFor="let n of linksByCategory; let i = index">
                    <a ngbNavLink [attr.data-anchor]="'#' + n.code.toLowerCase()">{{n.category}}</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngSwitch]="n.code">
                            <app-application-index-body *ngSwitchCase="'Applications'" [showNav]="true"></app-application-index-body>
                            <app-purchase-index-body *ngSwitchCase="'Purchases'" [showNav]="true"></app-purchase-index-body>
                            <ul class="link-list" *ngSwitchDefault>
                                <li *ngFor="let k of n.links">
                                    <a *ngIf="k.Url && !isExternalUrl(k.Url)" [routerLink]="[k.Url] | localize">
                                        {{k.Title}}
                                    </a>

                                    <a *ngIf="k.Url && isExternalUrl(k.Url)" href="{{k.Url}}" target="_blank">
                                        {{k.Title}}
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                    </ng-template>
                </li>
                <li ngbDropdown class="nav-item hidden-md-up" placement="bottom-end" *ngIf="linksByCategory.length > 2">
                    <a class="nav-link" [class.active]="activeTabId > 2" ngbDropdownToggle>
                        <ng-container *ngIf="activeTabId > 2">{{selectedCategory}}</ng-container>
                        <ng-container *ngIf="activeTabId < 3">{{'home_category_more' | translate}}</ng-container>
                    </a>
                    <div ngbDropdownMenu class="more-categories-menu">
                        <button ngbDropdownItem *ngFor="let n of linksByCategory; let i = index"
                                (click)="nav.select(i + 1)" [hidden]="i < 2">
                            {{n.category}}
                        </button>
                    </div>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>
