<div class="modal-header">
    <h4 class="modal-title" *ngIf="model">{{'edit' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="form-field">
            <label class="form-label">{{'parameters_lblCode' | translate}}</label>
            <input class="form-control" disabled name="code" [value]="model.Code">
        </div>

        <div class="form-field">
            <label class="form-label">{{'parameters_lblValue' | translate}}</label>
            <input class="form-control" name="value" [(ngModel)]="model.Value" />
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'save' | translate}}
    </button>
</div>
