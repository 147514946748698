import { Component, Input, OnInit } from '@angular/core';

import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Utils } from '../../../core/Utils';
import { IPlanSummarySeminar } from '../../../models/PlanSummary';
import { AppDatePipe } from '../../../pipes/date.pipe';
import { AppService } from '../../../services/app.service';
import { PlanSummaryService } from '../../../services/plan-summary.service';
import { ITableColumn } from '../../../shared/table/table.component';
import { store, currentRequestParams } from '../plan-summary.component';
import { Util } from '../util';

interface ISeminarRow extends IPlanSummarySeminar {
    attendancyStatusName?: string;
}

interface ISeminarsByYear {
    year: number;
    seminars: ISeminarRow[];
};

@Component({
    selector: 'app-plan-summary-seminars',
    templateUrl: './plan-summary-seminars.component.html',
    styleUrls: ['../plan-summary.component.scss']
})
export class PlanSummarySeminarsComponent implements OnInit {
    constructor(
        private app: AppService,
        private appDate: AppDatePipe,
        private service: PlanSummaryService
    ) { }

    @Input() studyYear: number;

    seminarsByYear: ISeminarsByYear[] = [];
    total: number;
    error: string;
    isLoaded: boolean;

    readonly util = Util;

    readonly columns: ITableColumn[] = [
        // xs
        { property: 'Theme', label: 'planSummary_seminars_lblThemeDateInstructorLocation', sorts: true, cssClass: 'hidden-md-up' },
        // md
        { property: 'Theme', label: 'planSummary_seminars_lblThemeDate', sorts: true, cssClass: 'hidden-lg-up hidden-sm-down' },
        { property: 'Instructor', label: 'planSummary_seminars_lblInstructorLocation', sorts: true, cssClass: 'hidden-lg-up hidden-sm-down' },
        // lg
        { property: 'Theme', label: 'planSummary_seminars_lblTheme', sorts: true, cssClass: 'hidden-md-down' },
        {
            property: 'DateTime', label: 'planSummary_seminars_lblDateTime', sorts: true, type: 'date', cssClass: 'hidden-md-down', sortFn: (a: ISeminarRow, b: ISeminarRow) => {
                const ad = a.DateTime;
                const bd = b.DateTime;

                return ad ? bd ? ad.getTime() - bd.getTime() : [-1] : [1];
            }
        },
        { property: 'Instructor', label: 'planSummary_seminars_lblInstructor', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'Location', label: 'planSummary_seminars_lblLocation', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'attendancyStatusName', label: 'planSummary_seminars_lblAttendancyStatus', sorts: true }
    ];

    private seminars: IPlanSummarySeminar[] = [];
    private allSeminarsByYear: ISeminarsByYear[] = [];

    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            if (!term) {
                this.seminarsByYear = [...this.allSeminarsByYear];
            } else {
                const search = term.toLowerCase();
                const byYear: ISeminarsByYear[] = [];

                this.allSeminarsByYear.forEach(t => {
                    const year = { ...t };

                    year.seminars = t.seminars.filter(s => {
                        return s.Theme?.toLowerCase().includes(search)
                            || s.Instructor?.toLowerCase().includes(search)
                            || s.Location?.toLowerCase().includes(search)
                            || s.attendancyStatusName?.toLowerCase().includes(search)
                            || (s.Date && this.appDate.transform(s.Date)?.includes(search));
                    });

                    if (year.seminars.length) {
                        this.util.toggle(year);
                        byYear.push(year);
                    }
                });

                this.seminarsByYear = byYear;
            }
        });

        this.getData().subscribe(data => {
            this.isLoaded = true;
            this.seminars = data;
            this.allSeminarsByYear = [];

            this.seminars.forEach(t => {
                let g = this.allSeminarsByYear.find(g => g.year == t.StudyYear);

                if (!g) {
                    g = { year: t.StudyYear, seminars: [] };
                    this.allSeminarsByYear.push(g);
                }

                const row: ISeminarRow = {
                    ...t,
                    attendancyStatusName: t.AttendancyStatus != null
                        ? this.app.translate('planSummary_seminars_attendancyStatus' + (t.AttendancyStatus ? 'True' : 'False'))
                        : ''
                };

                g.seminars.push(row);
            });

            this.seminarsByYear = [...this.allSeminarsByYear];

            if (this.seminarsByYear.length) {
                const currentYear = this.seminarsByYear.find(t => t.year == this.studyYear);
                this.util.toggle(currentYear || this.seminarsByYear[0]);
            }

            this.total = this.allSeminarsByYear.length;
        }, err => {
            this.error = this.app.getHttpResponseError(err);
        });
    }

    search(event) {
        this.searchSubj.next(event.target.value);
    }

    private getData() {
        if (store.seminars) {
            return of(store.seminars);
        } else {
            return this.app.addLoading(this.service.getSeminars(currentRequestParams)).pipe(tap(data => {
                data.forEach(t => {
                    t.DateTime = Utils.ensureDate(t.DateTime);
                });
                store.seminars = data;
            }));
        }
    }
}
