<div class="form-field" [class.required]="question.Mandatory">
    <app-survey-question-grid *ngIf="isGrid; else nonGrid"
                              [questions]="[question]"
                              [keyFn]="getQuestionKeyBuilder()"
                              [model]="model"
                              [validate]="validate"></app-survey-question-grid>

    <ng-template #nonGrid>
        <label class="form-label" [class.invalid]="validate && !util.validateAnswer(question, model[key])">
            {{question.Title}}
            <i class="fi fi-rs-info text-info" *ngIf="question.Description" [ngbTooltip]="question.Description"></i>
        </label>

        <div>
            <app-select *ngIf="isCombo"
                        [options]="options"
                        [(ngModel)]="model[key]"
                        [clear]="false"
                        displayProperty="text"
                        valueProperty="value"
                        name="question-{{key}}">
            </app-select>

            <div *ngIf="isRadio" [class.d-md-flex]="options.length < 4">
                <div *ngFor="let n of options" class="mb-1" [class.me-2]="options.length < 4">
                    <app-radio [(ngModel)]="model[key]"
                               [value]="n.value"
                               name="question-{{key}}">
                        {{n.text}}
                    </app-radio>
                </div>
            </div>

            <textarea *ngIf="isText"
                      class="form-control"
                      [(ngModel)]="model[key]"
                      name="question-{{key}}"
                      rows="3"></textarea>

            <input *ngIf="isNumber"
                   type="number"
                   class="form-control"
                   [(ngModel)]="model[key]"
                   maxlength="3"
                   name="question-{{key}}"
                   placeholder="1-100"
                   min="1"
                   max="100"
                   step="1" />
        </div>
    </ng-template>
</div>
