<div class="table-container">
    <div [class.table-wrapper]="wrap" #wrapper (scroll)="onScroll($event)">
        <table class="table"
               [class.table-sticky-left]="sticky == 'left'"
               [class.table-sticky-right]="sticky == 'right'"
               [class.scrolls]="scrolls">
            <thead *ngIf="columns?.length || headTemplate">
                <ng-container *ngIf="headTemplate">
                    <ng-container *ngTemplateOutlet="headTemplate"></ng-container>
                </ng-container>

                <tr *ngIf="!headTemplate">
                    <th *ngFor="let col of columns; let i = index"
                        (click)="sort(col)"
                        class="{{col.cssClass}}"
                        [class.sorts]="col.sorts"
                        style="{{col.width ? 'width: ' + col.width : '' }}">
                        <div>
                            <ng-container *ngIf="i == 0 && firstHeadTemplate">
                                <ng-container *ngTemplateOutlet="firstHeadTemplate"></ng-container>
                            </ng-container>

                            <ng-container *ngIf="i == columns.length - 1 && lastHeadTemplate">
                                <ng-container *ngTemplateOutlet="lastHeadTemplate"></ng-container>
                            </ng-container>

                            <span *ngIf="(i > 0 || !firstHeadTemplate) && (i < columns.length - 1 || !lastHeadTemplate)">{{col.label | translate}}</span>

                            <span *ngIf="col.sorts && col.property == sortBy" class="sort-dir sort-{{sortDir}}"></span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let row of (rows || []) | slice: (page - 1) * pageSize : page * pageSize; let i = index">
                    <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: row, index: i }"></ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
    
    <div class="table-total" *ngIf="showTotal">{{rows?.length}} {{'gridTotal' | translate}}</div>

    <div class="pagination-wrapper" *ngIf="rows?.length > pageSize">
        <ngb-pagination [(page)]="page"
                        (pageChange)="onPageChange()"
                        [pageSize]="pageSize"
                        [maxSize]="3"
                        [rotate]="true"
                        [collectionSize]="rows.length"></ngb-pagination>
    </div>
</div>
