import { IPersonSearchResultItem } from "../../models/Person";

const data: {
    person?: IPersonSearchResultItem
} = {};

export const store = {
    getPerson(): IPersonSearchResultItem {
        return data.person;
    },

    setPerson(person: IPersonSearchResultItem) {
        data.person = person;
    }
};
