import { AfterViewInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { ControlContainer, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AppService } from '../../services/app.service';
import { Utils } from '../../core/Utils';

let counter = 0;

/**
 * Create a File stub to display the FileComponent with an existing/saved file information.
 * @param fileName
 */
export function createFileStub(fileName: string) {
    return <File>{ size: 0, name: fileName };
}

@Component({
    selector: 'app-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: FileComponent
        }
    ]
})
export class FileComponent implements AfterViewInit, ControlValueAccessor, Validator {
    constructor(private app: AppService) {
        this.id = `file-${counter++}`;
        this.placeholder = this.app.translate('fileInputPlaceholder');
    }

    @ViewChild('fileInput', { static: true }) fileInput: ElementRef<any>;

    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() immediate: boolean;
    @Input() accept: string | string[];
    @Input() maxSize: number;
    @Input() onRemove: () => Observable<boolean> = () => of(true);
        
    @Output() fileChange = new EventEmitter<File>();

    readonly id: string;

    fileName: string;

    get value(): File {
        return this._value;
    }

    get acceptString(): string {
        const fs = [].concat(typeof this.accept == 'string' ? this.accept.split(',') : []);
        return fs.length ? fs.join(',') : '';
    }

    get extension(): string {
        return this.getExtension(this.value);
    }

    get isOversized(): boolean {
        return this.checkOversize(this.value);
    }

    get isUnsupported(): boolean {
        return this.checkUnsupported(this.value);
    }

    private _onChange = (obj: File) => { };
    private _value: File;

    ngAfterViewInit() {
        if (this.immediate) {
            this.add();
        }
    }

    //ControlValueAccessor
    writeValue(obj: File): void {
        this._value = obj;
        this.fileName = obj?.name;
    }

    registerOnChange(fn: (obj: File) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //ControlValueAccessor

    //Validator
    validate(control: AbstractControl): ValidationErrors | null {
        const file: File = control.value;

        if (!file) return null;

        let invalid = false;
        const result: any = {};

        if (this.checkOversize(file)) {
            invalid = true;

            result.maxSize = this.app.translate('fileMaxSizeErrorSizes')
                .replace('{0}', Utils.prettyFileSize(file.size))
                .replace('{1}', Utils.prettyFileSize(this.maxSize));
        }

        if (this.checkUnsupported(file)) {
            invalid = true;

            result.accept = this.app.translate('fileExtensionErrorFormats')
                .replace('{0}', this.getExtension(file))
                .replace('{1}', this.acceptString.replace(/,/g, ', '));
        }

        if (invalid) {
            return result;
        }
    }
    //Validator

    add() {
        if (this.disabled) return;

        this.fileInput.nativeElement.click();
    }

    remove() {
        if (this.disabled) return;

        this.onRemove().subscribe(result => {
            if (result) {
                this._value = null;
                this.fileName = undefined;
                this.fileInput.nativeElement.value = '';

                this.emitChange();
            }
        });
    }

    onChange(event) {
        const file: File = event.target.files[0];

        if (file) {
            this._value = file;
            this.fileName = file.name;

            this.emitChange();
        }
    }

    private emitChange() {
        this._onChange(this._value);
        this.fileChange.emit(this._value);
    }

    private getExtension(file: File): string {
        return '.' + file?.name.split('.').pop().toLowerCase();
    }

    private checkOversize(file: File): boolean {
        return file && this.maxSize && file.size > this.maxSize;
    }

    private checkUnsupported(file: File): boolean {
        if (!file) return false;

        const allowed = this.acceptString.split(',').filter(t => !!t);

        if (!allowed.length) return false;

        return !allowed.includes(this.getExtension(file));
    }
}
