<app-table [rows]="payments" [columns]="columns" [pageSize]="rowsPerPage">
    <ng-template #rowTemplate let-row>
        <tr>
            <td>{{row.Number}}</td>
            <td>{{row.Date | appDate}}</td>
            <td class="hidden-sm-down">{{row.Amount | number:numberFormat}}</td>
            <td class="hidden-md-down">{{row.Payer}}</td>
            <td>
                <div class="table-actions">
                    <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'studentBalance_showDetails' | translate">
                        <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                    </button>
                </div>
            </td>
        </tr>
        <tr *ngIf="isExpanded(row)" class="table-row-details">
            <td colspan="9">
                <div class="dl">
                    <div class="hidden-md-up">
                        <span [translate]="'studentBalance_lblInvoiceAmount'"></span>
                        <span>{{row.Amount | number:numberFormat}}</span>
                    </div>

                    <div class="hidden-lg-up">
                        <span [translate]="'studentBalance_lblPayer'"></span>
                        <span>{{row.Payer}}</span>
                    </div>

                    <div>
                        <span [translate]="'studentBalance_lblInvoiceReason'"></span>
                        <span>{{row.Reason}}</span>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</app-table>
