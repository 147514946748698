<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/templates', text: 'templates_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'templates_title' | translate}}</h1>
        <aside>
            <span class="me-1">
                <input class="form-control"
                       (input)="search($event)"
                       [(ngModel)]="searchValue"
                       [placeholder]="'templates_search' | translate"
                       maxlength="50">
            </span>

            <span class="me-1">
                <app-select [options]="typeOptions"
                            [(ngModel)]="selectedTypes"
                            [placeholder]="'templates_lblType' | translate"
                            [multiple]="true"
                            [displayFn]="typeDisplayFn"
                            (selectionChange)="onTypeFilterChange($event)">
                </app-select>
            </span>

            <button type="button" class="btn btn-outline-primary" [routerLink]="['/templates/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-table [rows]="items" [columns]="columns" [sticky]="'right'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    {{row.TemplateCode}}
                    <div class="text-sub">{{row.Title}}</div>
                </td>
                <td>{{typeDisplayFn(row.Type)}}</td>
                <td>{{row.UseCode}}</td>
                <td>{{row.DateFrom | appDate}}</td>
                <td>{{row.DateTo | appDate}}</td>
                <td>
                    <div class="table-actions">
                        <a href="{{getFileUrl(row, 'lv')}}" target="_blank" class="btn btn-outline btn-icon"
                           [ngbTooltip]="'download' | translate">
                            LV
                        </a>

                        <a href="{{getFileUrl(row, 'en')}}" target="_blank" class="btn btn-outline btn-icon"
                           [ngbTooltip]="'download' | translate">
                            EN
                        </a>

                        <button type="button" *ngIf="canEdit" [routerLink]="['/templates', row.Id] | localize" class="btn btn-outline btn-icon"
                                [ngbTooltip]="'edit' | translate">
                            <i class="fi fi-rs-pencil"></i>
                        </button>

                        <button type="button" *ngIf="canDelete" (click)="delete(row)" class="btn btn-outline btn-icon"
                                [ngbTooltip]="'delete' | translate">
                            <i class="fi fi-rs-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
