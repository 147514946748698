<div class="wrapper-lg">
    <ng-container *ngIf="error; else noError">
        <app-breadcrumbs [items]="[{ url: '/final-paper-reviews', text: 'finalPaperReview_title' }]"></app-breadcrumbs>

        <header class="content-header">
            <h1>{{'finalPaperReview_title' | translate}}</h1>
        </header>

        <app-alert level="danger">{{error}}</app-alert>
    </ng-container>

    <ng-template #noError>
        <app-breadcrumbs [items]="[
                     { url: '/final-paper-reviews', text: 'finalPaperReview_title' },
                     { url: '/final-paper-reviews/' + data?.Id, text: data?.Title }]"></app-breadcrumbs>

        <app-alert *ngIf="notApprovedCantReview">{{'finalPaperReview_notApprovedCantReview' | translate}}</app-alert>
        <app-alert *ngIf="notSubmittedCantApprove">{{'finalPaperReview_notSubmittedCantApprove' | translate}}</app-alert>

        <header class="content-header">
            <h1>{{title}}</h1>
        </header>

        <div class="row">
            <div class="col">
                <div class="data-value">
                    <label>{{'finalPaperReview_lblStudent' | translate}}</label>
                    <div>{{data?.Name}} {{data?.Surname}} ({{data?.StudentUpn}})</div>
                </div>
            </div>

            <div class="col">
                <div class="data-value">
                    <label>{{'finalPaperReview_lblStudyProgramme' | translate}}</label>
                    <div>{{programme}}</div>
                </div>

            </div>

            <div class="col">
                <div class="data-value">
                    <label>{{'finalPaperReview_lblAcademicYear' | translate}}</label>
                    <div>{{data?.AcademicYear || emptyValue}}</div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="tabs.length">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeNavId" class="nav-tabs">
                <li [ngbNavItem]="i + 1" [disabled]="isEditing" *ngFor="let tab of tabs; let i = index">
                    <a ngbNavLink>{{tab.title | translate}}</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngSwitch]="tab.id">
                            <div class="mt-3">
                                <div *ngSwitchCase="'finalPaper'">
                                    <div class="data-value">
                                        <label>{{'finalPaperReview_lblTitle' | translate}}</label>
                                        <div>{{data?.Title}}</div>
                                    </div>

                                    <div class="data-value">
                                        <label>{{'finalPaperReview_lblAnnotation' | translate}}</label>
                                        <div>{{data?.Annotation}}</div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblTags' | translate}}</label>
                                                <div>{{data?.Tags || emptyValue}}</div>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblMainFile' | translate}}</label>
                                                <div>
                                                    <a *ngIf="mainFile" href="{{getFinalPaperFileDownloadUrl(mainFile.Id)}}" target="_blank">
                                                        {{mainFile.FileName}}
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblAuxFiles' | translate}}</label>
                                                <ng-container *ngIf="auxFiles.length">
                                                    <div *ngFor="let n of auxFiles">
                                                        <a href="{{getFinalPaperFileDownloadUrl(n.Id)}}" target="_blank">
                                                            {{n.FileName}}
                                                        </a>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="!auxFiles.length">{{emptyValue}}</ng-container>
                                            </div>
                                        </div>

                                        <div class="col">
                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblThesisType' | translate}}</label>
                                                <div>{{data?.ThesisType || emptyValue}}</div>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblSupervisors' | translate}}</label>
                                                <div *ngFor="let n of supervisors">{{getUserDisplayString(n) || emptyValue}}</div>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblReviewers' | translate}}</label>
                                                <div *ngFor="let n of reviewers">{{getUserDisplayString(n) || emptyValue}}</div>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblStatus' | translate}}</label>
                                                <div *ngIf="data?.Status">{{'finalPaper_status' + data.Status | translate}}</div>
                                            </div>

                                            <div class="data-value" *ngIf="data?.OverallMatchPercentage">
                                                <label>{{'finalPaperReview_lblOverallMatchpercentage' | translate}}</label>
                                                <div>{{data.OverallMatchPercentage}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="block-actions">
                                        <button *ngIf="dspaceCardVisible" type="button" class="btn btn-outline" (click)="openDspaceCard()">
                                            <i class="fi fi-rs-document"></i>
                                            {{'finalPaperReview_openDspaceCard' | translate}}
                                        </button>
                                        <button *ngIf="data?.OverallMatchPercentage" type="button" class="btn btn-outline" (click)="openReport(n)">
                                            <i class="fi fi-rs-document"></i>
                                            {{'finalPaperReview_openReport' | translate}}
                                        </button>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'approval'">
                                    <div *ngFor="let n of approvals" class="feedback block-em">
                                        <ng-container *ngIf="!n.isEditing">
                                            <div class="data-value author">
                                                <label>{{('finalPaperReview_lbl' + n.author.Role) | translate}}</label>
                                                <div>{{n.author.Name}} {{n.author.Surname}}</div>
                                                <span *ngIf="n.isObsolete" class="obsolete" [ngbTooltip]="'finalPaperReview_userIsObsolete' | translate">
                                                    <i class="fi fi-rs-delete-user"></i>
                                                </span>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblFeedback' | translate}}</label>
                                                <div>{{n.content || emptyValue}}</div>
                                            </div>

                                            <div class="data-value" *ngIf="n.fileName">
                                                <label>{{'finalPaperReview_lblAttachment' | translate}}</label>
                                                <div>
                                                    <a href="{{getFeedbackFileDownloadUrl(n.id)}}" *ngIf="n.fileName" target="_blank">
                                                        {{n.fileName}}
                                                    </a>
                                                    <ng-container *ngIf="!n.fileName">{{emptyValue}}</ng-container>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <form #approvalForm="ngForm" *ngIf="n.isEditing">
                                            <div class="form-field">
                                                <label class="form-label">{{('finalPaperReview_lbl' + n.author.Role) | translate}}</label>
                                                <input class="form-control" [ngModel]="n.author.Name + ' ' + n.author.Surname" name="author" disabled />
                                            </div>

                                            <div class="form-field required">
                                                <label class="form-label">{{'finalPaperReview_lblFeedback' | translate}}</label>
                                                <textarea class="form-control" [(ngModel)]="editModel.Content" name="content" maxlength="4000" rows="4" required #approvalInput="ngModel"></textarea>
                                                <div class="invalid-feedback" *ngIf="approvalInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 4000]}}</div>
                                            </div>

                                            <div class="form-field">
                                                <label class="form-label">{{'finalPaperReview_lblAttachment' | translate}}</label>
                                                <app-file name="file" [(ngModel)]="editFile" [accept]="fileAccept" [maxSize]="fileMaxSize" #fileInput="ngModel"></app-file>
                                                <div class="invalid-feedback" *ngIf="fileInput.errors?.maxSize">{{fileInput.errors.maxSize}}</div>
                                            </div>

                                            <div class="block-actions">
                                                <button type="button" class="btn btn-outline" (click)="cancel(n)">{{'cancel' | translate}}</button>
                                                <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!approvalForm.valid">{{'save' | translate}}</button>
                                            </div>
                                        </form>

                                        <div class="block-actions" *ngIf="!n.isReadOnly">
                                            <button type="button" class="btn btn-outline" (click)="edit(n)" *ngIf="!isEditing">{{'edit' | translate}}</button>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="decision.visible">
                                        <app-alert [level]="decision.level" *ngIf="decision.message">{{decision.message}}</app-alert>

                                        <div class="form-actions" *ngIf="decision.enabled">
                                            <span class="form-actions-group">
                                                <button type="button" class="btn btn-secondary" (click)="reject()" [disabled]="isEditing">
                                                    {{'finalPaperReview_btnReject' | translate}}
                                                </button>
                                                <button type="button" class="btn btn-primary" (click)="approve()" [disabled]="isEditing">
                                                    {{'finalPaperReview_btnApprove' | translate}}
                                                </button>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngSwitchCase="'review'">
                                    <div *ngFor="let n of reviews" class="block-em">
                                        <ng-container *ngIf="!n.isEditing">
                                            <div class="data-value author">
                                                <label>{{('finalPaperReview_lbl' + n.author.Role) | translate}}</label>
                                                <div>{{n.author.Name}} {{n.author.Surname}}</div>
                                                <span *ngIf="n.isObsolete" class="obsolete" [ngbTooltip]="'finalPaperReview_userIsObsolete' | translate">
                                                    <i class="fi fi-rs-delete-user"></i>
                                                </span>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblReview' | translate}}</label>
                                                <div>{{n.content || emptyValue}}</div>
                                            </div>

                                            <div class="data-value" *ngIf="n.fileName">
                                                <label>{{'finalPaperReview_lblAttachment' | translate}}</label>
                                                <div>
                                                    <a href="{{getFeedbackFileDownloadUrl(n.id)}}" *ngIf="n.fileName" target="_blank">
                                                        {{n.fileName}}
                                                    </a>
                                                    <ng-container *ngIf="!n.fileName">{{emptyValue}}</ng-container>
                                                </div>
                                            </div>

                                            <div class="data-value">
                                                <label>{{'finalPaperReview_lblRecommendedGrade' | translate}}</label>
                                                <div>{{n.grade || emptyValue}}</div>
                                            </div>
                                        </ng-container>

                                        <form #reviewForm="ngForm" *ngIf="n.isEditing">
                                            <div class="form-field">
                                                <label class="form-label">{{('finalPaperReview_lbl' + n.author.Role) | translate}}</label>
                                                <input class="form-control" [ngModel]="n.author.Name + ' ' + n.author.Surname" name="author" disabled />
                                            </div>

                                            <div class="form-field required">
                                                <label class="form-label">{{'finalPaperReview_lblReview' | translate}}</label>
                                                <textarea class="form-control" [(ngModel)]="editModel.Content" name="content" maxlength="4000" rows="4" required #reviewInput="ngModel"></textarea>
                                                <div class="invalid-feedback" *ngIf="reviewInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 4000]}}</div>
                                            </div>

                                            <div class="form-field">
                                                <label class="form-label">{{'finalPaperReview_lblAttachment' | translate}}</label>
                                                <app-file name="file" [(ngModel)]="editFile" [accept]="fileAccept" [maxSize]="fileMaxSize" #fileInput="ngModel"></app-file>
                                                <div class="invalid-feedback" *ngIf="fileInput.errors?.maxSize">{{fileInput.errors.maxSize}}</div>
                                            </div>

                                            <div class="form-field required">
                                                <label class="form-label">{{'finalPaperReview_lblRecommendedGrade' | translate}}</label>
                                                <app-select [(ngModel)]="editModel.RecommendedGradeId"
                                                            name="recommendedGrade"
                                                            [required]="true"
                                                            [options]="gradeOptions"
                                                            [valueProperty]="'Id'"
                                                            [displayProperty]="'Value'"></app-select>
                                            </div>

                                            <div class="block-actions">
                                                <button type="button" class="btn btn-outline" (click)="cancel(n)">{{'cancel' | translate}}</button>
                                                <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!reviewForm?.valid">{{'save' | translate}}</button>
                                            </div>
                                        </form>

                                        <div class="block-actions" *ngIf="!n.isReadOnly">
                                            <button type="button" class="btn btn-outline" (click)="edit(n)" *ngIf="!isEditing">{{'edit' | translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>
    </ng-template>
</div>
