import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';

import { HospitalService } from '../../../services/hospital.service';
import { IHospitalRotation } from '../../../models/Hospital';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SchedulerProConfig } from '@bryntum/schedulerpro';
import { AppDatePipe } from '../../../pipes/date.pipe';
import { Utils } from '../../../core/Utils';
import { store } from '../store';

const cache: {
    rotationsByCompany: { [companyId: string]: IHospitalRotation[] }
} = {
    rotationsByCompany: {}
};

type ViewType = 'Student' | 'Lecturer';

@Component({
    selector: 'app-hospital-dashboard-rotations',
    templateUrl: './hospital-dashboard-rotations.component.html'
})
export class HospitalDashboardRotationsComponent implements OnInit, OnDestroy {
    constructor(
        private app: AppService,
        private service: HospitalService,
        private appDate: AppDatePipe
    ) { }

    @Input() set companyId(value: string) {
        this._companyId = value;
        this.companyIdSubj.next(value);
    }

    @Input() set academicYearId(value: string) {
        this._academicYearId = value;
        this.academicYearIdSubj.next(value);
    }

    readonly schedulerModel = {
        resources: [],
        events: [],
        assignments: []
    };

    readonly schedulerConfig: Partial<SchedulerProConfig> = {
        columns: [
            { text: 'Name', field: 'name', width: 160 },
            { text: 'Surname', field: 'surname', width: 160 }
        ],
        features: {
            cellMenu: false,
            eventDrag: false,
            eventCopyPaste: false,
            eventEdit: false,
            eventMenu: false,
            eventResize: false,
            taskEdit: false,
            dependencies: false,
            scheduleMenu: false,
            eventTooltip: {
                template: data => {
                    const rec: IHospitalRotation = data.eventRecord['originalData'].data;

                    const df = this.appDate.transform(rec.DateFrom);
                    const dt = this.appDate.transform(rec.DateTo);
                    const people = this.view == 'Lecturer'
                        ? (rec.Student.Name + ' ' + rec.Student.Surname)
                        : (rec.Lecturers || []).map(t => t.Name + ' ' + t.Surname).join(', ');

                    return `<div><small>${rec.SubjectCode}</small></div>`
                        + `<div class="mb-2">${rec.SubjectName}</div>`
                        + `<div>${df} - ${dt}</div>`
                        + `<div class="mb-2">${rec.WeekCount} ${this.app.translate('hospitalDashboard_weeksPostfix')}</div>`
                        + `<div><i class="fi fi-rs-user"></i> ${people}</div>`
                        + `<div><i class="fi fi-rs-marker"></i> ${rec.CompanyName || ''}</div>`;
                }
            }
        },
        autoHeight: true,
        readOnly: true,
        weekStartDay: 1
    };

    readonly views: ViewType[] = ['Student', 'Lecturer'];
    readonly viewDisplayFn = (option: ViewType) => this.app.translate(`hospitalDashboard_rotations_view${option}`);

    readonly filterOptions: {
        studyYear: number[],
        programName: string[]
    } = {
            studyYear: [],
            programName: []
        };

    readonly filterValues: {
        text?: string,
        studyYear?: number,
        programName?: string
    } = {};

    view: ViewType = 'Student';

    get companyId(): string {
        return this._companyId;
    }

    get academicYearId(): string {
        return this._academicYearId;
    }

    private _companyId: string;
    private _academicYearId: string;

    private rotations: IHospitalRotation[] = [];
    private prevCompanyId: string;

    private readonly schedulerFilterSubj = new Subject();

    private companyIdSubj = new BehaviorSubject<string>(this.companyId);
    private academicYearIdSubj = new BehaviorSubject<string>(this.academicYearId);
    private argsSub: Subscription;

    private readonly eventColors = ['#669F2A', '#15B79E', '#0BA5EC', '#2970FF', '#6172F3', '#7A5AF8', '#D444F1', '#F63D68', '#EF6820', '#EAAA08'];

    ngOnInit() {
        (this.schedulerConfig.columns as any[]).forEach(t => {
            t.text = this.app.translate(`hospitalDashboard_rotations_lbl${t.text}`);
        });

        this.schedulerFilterSubj.pipe(debounceTime(300)).subscribe(() => {
            this.buildScheduler(this.rotations);
        });

        this.argsSub = combineLatest([
            this.companyIdSubj.pipe(filter(t => !!t)),
            this.academicYearIdSubj.pipe(filter(t => !!t))
        ]).subscribe(args => {
            const reload = this.companyId != this.prevCompanyId;

            this.prevCompanyId = this.companyId;
            this.init(reload);
        });
    }

    ngOnDestroy() {
        if (this.argsSub) {
            this.argsSub.unsubscribe();
        }
    }

    filter() {
        this.schedulerFilterSubj.next();
    }

    changeView(view: ViewType) {
        this.view = view;
        this.buildScheduler(this.rotations);
    }

    private init(reload?: boolean) {
        const process = (data: IHospitalRotation[]) => {
            const byYear = this.filterByAcademicYear(data);

            this.rotations = byYear;

            const studyYears: number[] = [];
            const programNames: string[] = [];

            byYear.forEach(t => {
                if (!studyYears.includes(t.Student.StudyYear)) studyYears.push(t.Student.StudyYear);
                if (!programNames.includes(t.Student.ProgramName)) programNames.push(t.Student.ProgramName);
            });

            studyYears.sort();
            programNames.sort();

            this.filterOptions.studyYear = studyYears;
            this.filterOptions.programName = programNames;

            this.buildScheduler(byYear);
        };

        if (cache.rotationsByCompany[this.companyId]) {
            process(cache.rotationsByCompany[this.companyId]);
        } else if (reload) {
            this.app.addLoading(this.service.getRotations(this.companyId, store.getPerson()?.Upn)).subscribe(data => {
                cache.rotationsByCompany[this.companyId] = data;
                process(data);
            });
        }
    }

    private filterByAcademicYear(data: IHospitalRotation[]) {
        return data.filter(t => t.AcademicYearId == this.academicYearId);
    }

    private buildScheduler(data: IHospitalRotation[]) {
        let minDate: Date;
        let maxDate: Date;

        const events = [];
        const assignments = [];
        const resources = [];

        let currentColorIndex = 0;

        const noValue = this.app.translate('doctorDashboard_rotations_undefined');
        const filter = this.filterValues;

        const applyFilter = (item: IHospitalRotation) => {
            if (filter) {
                if (filter.studyYear && item.Student.StudyYear != filter.studyYear) return false;
                if (filter.programName && item.Student.ProgramName != filter.programName) return false;
                if (filter.text) {
                    const term = filter.text.toLowerCase();

                    if (!`${item.SubjectCode} ${item.SubjectName}`.toLowerCase().includes(term)
                        && !`${item.Student.Name} ${item.Student.Surname}`.toLowerCase().includes(term)
                        && !`${item.Student.ProgramCode} ${item.Student.ProgramName}`.toLowerCase().includes(term)
                        && !item.Lecturers.some(t => `${t.Name} ${t.Surname}`.toLowerCase().includes(term))
                    ) return false;
                }
            }

            return true;
        };

        data.forEach(t => {
            const subjectName = `${t.SubjectCode} ${t.SubjectName}`;
            const studentName = `${t.Student.Name} ${t.Student.Surname}`;

            if (applyFilter(t)) {
                const bd = Utils.ensureDate(t.DateFrom);
                const ad = Utils.ensureDate(t.DateTo);

                if (!minDate || bd < minDate) {
                    minDate = bd;
                }

                if (!maxDate || ad > maxDate) {
                    maxDate = ad;
                }

                events.push({
                    id: t.Id,
                    startDate: bd,
                    endDate: ad,
                    name: subjectName,
                    data: t,
                    eventColor: this.eventColors[currentColorIndex]
                });

                if (this.view == 'Lecturer') {
                    const lecturers = t.Lecturers || [];

                    if (!lecturers.length) {
                        lecturers.push({ Id: '', Name: noValue, Surname: noValue });
                    }

                    lecturers.forEach(l => {
                        if (!resources.some(n => n.id == l.Id)) {
                            resources.push({
                                id: l.Id,
                                name: l.Name,
                                surname: l.Surname,
                                fullName: `${l.Name} ${l.Surname}`
                            });
                        }

                        assignments.push({
                            event: t.Id,
                            resource: l.Id
                        });
                    });
                } else {
                    if (!resources.some(n => n.id == t.Student.Id)) {
                        resources.push({
                            id: t.Student.Id,
                            name: t.Student.Name,
                            surname: t.Student.Surname,
                            fullName: studentName
                        });
                    }

                    assignments.push({
                        event: t.Id,
                        resource: t.Student.Id
                    });
                }

                currentColorIndex++;

                if (currentColorIndex == this.eventColors.length) {
                    currentColorIndex = 0;
                }
            }
        });

        this.schedulerConfig.startDate = minDate;
        this.schedulerConfig.endDate = maxDate;

        this.schedulerModel.resources = resources;
        this.schedulerModel.assignments = assignments;
        this.schedulerModel.events = events;
    }
}
