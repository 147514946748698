<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/surveys', text: 'surveys_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'surveys_title' | translate}}</h1>
        <aside>
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()" *ngIf="!canPickStudent || student">
                <i class="fi fi-rs-user"></i>
                {{'surveys_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened">
        <div class="data-value" *ngIf="canPickStudent && student">
            <label>{{'surveys_lblStudent' | translate}}</label>
            <div>{{student.FirstName}} {{student.LastName}}, {{student.Email}}</div>
        </div>

        <section class="block" *ngFor="let g of groups">
            <h5>
                <ng-container *ngIf="g.type == 'CourseSurvey'">{{'surveys_studyCourseSurveys' | translate}}</ng-container>
                <ng-container *ngIf="g.type == 'ProgrammeSurvey'">{{'surveys_studyProgrammeSurveys' | translate}}</ng-container>
            </h5>

            <div class="row" *ngIf="g.type == 'CourseSurvey'">
                <div class="col-md-6 mb-3">
                    <div class="btn-group" role="group">
                        <button type="button"
                                class="btn btn-outline"
                                (click)="switchPeriodView('actual')"
                                [class.is-active]="periodView == 'actual'">
                            {{currentPeriodName || ('surveys_btnCurrentPeriod' | translate)}}
                        </button>

                        <button type="button"
                                class="btn btn-outline"
                                (click)="switchPeriodView('archive')"
                                [class.is-active]="periodView == 'archive'">
                            {{'surveys_btnArchive' | translate}}
                        </button>
                    </div>
                </div>

                <div class="col-md-6 mb-3" *ngIf="periodView == 'archive'">
                    <app-select [options]="pastPeriods"
                                [displayProperty]="'name'"
                                [valueProperty]="'id'"
                                [(ngModel)]="selectedPeriodId"
                                [required]="true"
                                name="selectedPeriodId"
                                (selectionChange)="onPeriodChange()"></app-select>
                </div>
            </div>

            <app-table [rows]="g.rows" [columns]="g.columns">
                <ng-template #rowTemplate let-row>
                    <tr>
                        <td>
                            <strong>
                                <a href="{{row.SurveyLink}}" target="_blank">{{row.SurveyName}}</a>
                            </strong>
                            <div class="text-sub">{{row.StudyProgramme}}</div>
                            <div class="text-sub" *ngIf="g.type == 'ProgrammeSurvey'">{{row.Faculty}}</div>
                            <div class="text-sub" *ngIf="g.type == 'ProgrammeSurvey'">{{row.StudyProgrammeDirector}}</div>
                        </td>
                        <td class="hidden-md-down text-nowrap" *ngIf="g.type == 'CourseSurvey'">
                            <div>
                                <a href="{{row.FeedbackLink}}" target="_blank" class="text-mute">
                                    {{row.PlanningPeriodName}}
                                    <i class="fi fi-rs-angle-small-right"></i>
                                </a>
                            </div>

                            <div *ngIf="row.PreviousFeedbackLink">
                                <a href="{{row.PreviousFeedbackLink}}" target="_blank" class="text-mute">
                                    {{row.PreviousFeedbackLinkName}}
                                    <i class="fi fi-rs-angle-small-right"></i>
                                </a>
                            </div>
                        </td>
                        <td class="hidden-sm-down">
                            <div class="filling filling-col">
                                <div>{{row.Filling * 100 | number: '1.0-0'}}%</div>
                                <div class="filling-bar">
                                    <div [style.width]="row.Filling * 100 + '%'"></div>
                                </div>
                            </div>
                        </td>
                        <td class="hidden-sm-down">{{row.StatusName}}</td>
                        <td>
                            <div class="table-actions">
                                <a class="btn btn-outline hidden-xs-down"
                                   href="{{row.StatusCode == 'CLOSED' || row.StatusCode == 'HASRESULTS' ? row.FeedbackLink : row.SurveyLink}}"
                                   target="_blank"
                                   [class.disabled]="row.IsActual && (row.StatusCode == 'CLOSED' || row.StatusCode == 'NOTBEGUN')">
                                    <ng-container *ngIf="row.StatusCode == 'HASRESULTS' || row.StatusCode == 'CLOSED'; else fillText">
                                        {{'surveys_btnView' | translate}}
                                    </ng-container>
                                    <ng-template #fillText>{{'surveys_btnFillSurvey' | translate}}</ng-template>
                                </a>

                                <a class="btn btn-outline btn-icon hidden-sm-up"
                                   href="{{row.StatusCode == 'CLOSED' || row.StatusCode == 'HASRESULTS' ? row.FeedbackLink : row.SurveyLink}}"
                                   target="_blank"
                                   [class.disabled]="row.IsActual && (row.StatusCode == 'CLOSED' || row.StatusCode == 'NOTBEGUN')"
                                   [ngbTooltip]="(row.StatusCode == 'HASRESULTS' ? 'surveys_btnView' : 'surveys_btnFillSurvey') | translate">
                                    <ng-container *ngIf="row.StatusCode == 'HASRESULTS' || row.StatusCode == 'CLOSED'; else fillIcon">
                                        <i class="fi fi-rs-eye"></i>
                                    </ng-container>
                                    <ng-template #fillIcon><i class="fi fi-rs-pencil"></i></ng-template>
                                </a>

                                <div class="hidden-md-down text-sub btn-feedback" *ngIf="row.IsActual && (row.StatusCode == 'OPEN' || row.StatusCode == 'CLOSED')">
                                    {{'surveys_lblEndDate' | translate}} {{row.EndDate | appDate}}
                                </div>

                                <div class="hidden-md-down text-sub btn-feedback" *ngIf="row.IsActual && (row.StatusCode == 'NOTBEGUN' || row.StatusCode == 'SUBMITTED')">
                                    {{'surveys_lblStartDate' | translate}} {{row.StartDate | appDate}}
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr class="hidden-lg-up table-row-details">
                        <td colspan="9">
                            <div class="dl">
                                <div *ngIf="g.type == 'CourseSurvey'">
                                    <span>{{'surveys_lblFeedback' | translate}}</span>
                                    <div>
                                        <div>
                                            <a href="{{row.FeedbackLink}}" target="_blank">
                                            {{row.PlanningPeriodName}}
                                                <i class="fi fi-rs-angle-small-right"></i>
                                            </a>
                                        </div>

                                        <div *ngIf="row.PreviousFeedbackLink">
                                            <a href="{{row.PreviousFeedbackLink}}" target="_blank">
                                                {{row.PreviousFeedbackLinkName}}
                                                <i class="fi fi-rs-angle-small-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="hidden-md-up">
                                    <span>{{'surveys_lblFilling' | translate}}</span>
                                    <div class="filling filling-row">
                                        <div>
                                            {{row.Filling * 100 | number: '1.0-0'}}%
                                        </div>

                                        <div class="filling-bar">
                                            <div [style.width]="row.Filling * 100 + '%'"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="hidden-md-up">
                                    <span>{{'surveys_lblStatus' | translate}}</span>
                                    <span>{{row.StatusName}}</span>
                                </div>

                                <div *ngIf="row.StatusCode == 'OPENED' || row.StatusCode == 'CLOSED'">
                                    <span>{{'surveys_lblEndDate' | translate}}</span>
                                    <span>{{row.EndDate | appDate}}</span>
                                </div>

                                <div *ngIf="row.StatusCode == 'NOTBEGUN'">
                                    <span>{{'surveys_lblStartDate' | translate}}</span>
                                    <span>{{row.StartDate | appDate}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </app-table>
        </section>
    </ng-container>
</div>
