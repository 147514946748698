import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of } from 'rxjs';

import {
    Classifier, ClassifierType, IApplicationTopicAcademicLeavePayload,
    IApplicationTopicJustifiedAbsenceField, IApplicationTopicJustifiedAbsencePayload
} from '../../models/Classifier';
import { ApplicationKind, ApplicationType, BankAccount, ExtraCourseInfo, PaymentScheduleEntry, Student } from '../../models/Application';
import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ClassifierService } from '../../services/classifier.service';
import { ParameterService } from '../../services/parameter.service';
import { ApplicationComponentBase } from './ApplicationComponentBase';
import { AddressComponent } from '../../shared/address/address.component';
import { Address } from '../../models/Address';
import { MessageService } from '../../services/message.service';
import { ITableColumn } from '../../shared/table/table.component';
import { IFieldFile } from '../../models/IFieldFile';
import { IPictureLabels, IPictureOutput } from '../../shared/picture/picture.component';
import { Utils } from '../../core/Utils';

export const parameterCodes = {
    attachmentExtensions: 'ApplicationFileExtensions',
    attachmentMaxSize: 'ApplicationFileMaxSize',
    attachmentLimit: 'ApplicationFileLimit',
    maxComplaintAppeals: 'ComplaintMaxAppeals',
    personPhotoFileExtensions: 'PersonPhotoFileExtensions',
    personPhotoFileMaxSize: 'PersonPhotoFileMaxSize',
};

const academicLeaveTopics = {
    leave: 'AcademicLeave',
    extend: 'ExtendAcademicLeave',
    resume: 'ResumeStudies'
};

const personDataFieldGroups: { name: string, fields: IPersonDataFieldModel[] }[] = [
    {
        name: 'main', fields: [
            { code: 'Name', source: 'pName', name: '', type: 'Text' },
            { code: 'Surname', source: 'pSurname', name: '', type: 'Text' },
            { code: 'ContactAddress', source: 'contact_street_home', name: '', type: 'Address' },
            { code: 'PersonCode', source: 'pCode', name: '', type: 'PersonCode', required: true },
            { code: 'Email', source: 'eEmail', name: '', type: 'Email' },
            { code: 'DeclaredAddress', source: 'decl_street_home', name: '', type: 'Address' },
            { code: 'Phone', source: 'pMobile', name: '', type: 'Phone' }
        ]
    },
    {
        name: 'id', fields: [
            { code: 'PassportNumber', source: 'pPassportNr', name: '', type: 'Text' },
            { code: 'PassportExpiryDate', source: 'pPassportExpiryDate', name: '', type: 'Date' },
            { code: 'IdCardNumber', source: 'pIDCardNr', name: '', type: 'Text' },
            { code: 'IdCardExpiryDate', source: 'pIDCardExpiryDate', name: '', type: 'Date' }
        ]
    }
];

interface IField {
    visible?: boolean;
    required?: boolean;
    disabled?: boolean;
    label?: string;
    length?: number;
}

interface IFields {
    addressee: () => IField;
    topic: () => IField;
    reason: () => IField;
    dateFrom: () => IField;
    dateTo: () => IField;
    attachments: () => IField;
    comments: () => IField;
    documentNumber: () => IField;
    absenceType: () => IField;
    closingSheet: () => IField;
    prolongsPeriod: () => IField;
    studyCourse: () => IField;
    appealCounter: () => IField;
    scholarship: () => IField;
    academicYear: () => IField;
    courseRound: () => IField;
    contractNumber: () => IField;
    bankAccount: () => IField;
    latePayments: () => IField;
    latePaymentSchedule: () => IField;
    extraCourses: () => IField;
    personData: () => IField;
    personPhoto: () => IField;
}

interface IPersonDataFieldModel {
    type: string;
    code: string;
    name: string;
    source: string;
    required?: boolean;
    value?: string;
    previousValue?: string;
    displayValue?: string;
    changed?: boolean;
    maxLength?: number;
    pattern?: string;
    hint?: string;
}

@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss']
})
export class ApplicationComponent extends ApplicationComponentBase {
    constructor(
        public app: AppService,
        protected service: ApplicationService,
        protected classifiers: ClassifierService,
        protected messages: MessageService,
        protected parameters: ParameterService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected modal: NgbModal
    ) {
        super(app, service, classifiers, messages, route, router);

        personDataFieldGroups.forEach(g => {
            g.fields.forEach(f => {
                f.name = this.app.translate(`application_personData_${f.code}`);
            });
        });

        this.personDataGroups = personDataFieldGroups;
    }

    get kind(): ApplicationKind {
        return ApplicationKind.Application;
    }

    topic: Classifier;
    topicItems: Classifier[] = [];

    reason: Classifier;
    reasonItems: Classifier[] = [];

    absenceType: Classifier;
    absenceTypeItems: Classifier[] = [];

    scholarship: Classifier;
    scholarshipItems: Classifier[] = [];

    courseRound: Classifier;
    courseRoundItems: Classifier[] = [];

    personData: IPersonDataFieldModel[];
    personDataItems: Classifier[] = [];

    readonly personDataGroups: { name: string, fields: IPersonDataFieldModel[] }[];

    extraCourseInfo: ExtraCourseInfo[] = [];
    extraCourses: ExtraCourseInfo[];
    selectedExtraCourse: ExtraCourseInfo;

    appealCounterItems: number[] = [];
    academicYearItems: string[] = [];
    contractNumberItems: string[] = [];
    studyCourseItems: string[] = [];

    addressee: string;
    dateFrom: Date;
    dateTo: Date;
    comments: string;
    documentNumber: string;
    closingSheet: string;
    appealCounter: number;
    academicYear: string;
    studyCourse: string;
    latePayments: string;
    latePaymentSchedule: PaymentScheduleEntry[];
    bankAccount: BankAccount;
    contractNumber: string;

    private defaultProlongsPeriod = true;
    prolongsPeriod: boolean = this.defaultProlongsPeriod;

    attachments: IFieldFile[] = [];
    attachmentExtensions: string;
    attachmentMaxSize: number;
    attachmentLimit: number;

    isEmptyAbsences: boolean;
    isEmptyExtraCourses: boolean;

    bankAccountInLatviaText: string;
    bankAccountOutOfLatviaText: string;

    personPhotoLabels: IPictureLabels = {
        add: 'application_personPhotoBtnAdd',
        change: 'application_personPhotoBtnChange'
    };

    personPhotoChange: IPictureOutput;
    personPhotoFileExtensions: string;
    personPhotoFileMaxSize: number;
    personPhoto: string;

    readonly anyPattern = '.*';
    readonly personCodePattern = '^[0-9]{6}-[0-9]{5}$';
    readonly phonePattern = '^[+]?[0-9]{8,30}$';

    readonly extraCourseColumns: ITableColumn[] = [
        { label: 'application_lblExtraCourseAllColumns', cssClass: 'hidden-md-up' },
        { label: 'application_lblExtraCourseCodeName', cssClass: 'hidden-lg-up hidden-sm-down' },
        { label: 'application_lblExtraCourseCode', cssClass: 'hidden-md-down' },
        { label: 'application_lblExtraCourseName', cssClass: 'hidden-md-down' },
        { label: 'application_lblExtraCourseSemester', cssClass: 'hidden-sm-down' },
        { label: 'application_lblExtraCourseCreditPoints', cssClass: 'hidden-sm-down' },
        { width: '1px' }
    ];

    private get knownType(): ApplicationType {
        return <ApplicationType>this.item.Type;
    }

    readonly fields: IFields = {
        addressee: () => {
            const cfg: IField = { visible: true, required: true, disabled: true, label: 'application_lblAddressee', length: 200 };

            if ([
                ApplicationType.PersonData,
                ApplicationType.PersonPhoto,
                ApplicationType.PaymentTermExtension,
                ApplicationType.Repayment,
                ApplicationType.Complaint
            ].indexOf(this.knownType) !== -1) {
                this.extend(cfg, { visible: false });
            } else if (this.item.Type === ApplicationType.FreeForm) {
                this.extend(cfg, { required: false, disabled: false });
            }

            return cfg;
        },
        bankAccount: () => {
            const cfg: IField = { visible: this.item.Type === ApplicationType.Repayment, label: 'application_repayTo' };
            return cfg;
        },
        topic: () => {
            const cfg: IField = { visible: true, required: true, label: 'application_lblTopic' };

            if ([
                ApplicationType.Complaint,
                ApplicationType.AppealComplaint,
                ApplicationType.ScholarshipComplaint,
                ApplicationType.Discount,
                ApplicationType.Repayment,
                ApplicationType.FreeForm,
                ApplicationType.PersonData,
                ApplicationType.PersonPhoto,
                ApplicationType.PaymentTermExtension,
                ApplicationType.RepeatCourse
            ].indexOf(this.knownType) !== -1) {
                this.extend(cfg, { visible: false });
            }

            return cfg;
        },
        reason: () => {
            const cfg: IField = {
                visible: this.topic && [academicLeaveTopics.leave, academicLeaveTopics.extend].indexOf(this.topic.Code) > -1,
                required: true,
                label: 'application_lblReason'
            };

            if (this.item.Type === ApplicationType.Discount) {
                this.extend(cfg, { visible: true, label: 'application_lblDiscountReason' });
            } else if (this.item.Type === ApplicationType.Repayment) {
                this.extend(cfg, { visible: true, required: true, label: 'application_lblRepaymentReason' });
            }

            return cfg;
        },
        dateFrom: () => {
            const cfg: IField = { visible: true, required: true, label: 'application_lblDateFrom' };

            if (this.item.Type === ApplicationType.JustifiedAbsence) {
                this.extend(cfg, this.getJustifiedAbsenceFieldConfig(this.getJustifiedAbsenceTopicPayload().DateFrom));
            } else if ([
                ApplicationType.Complaint,
                ApplicationType.AppealComplaint,
                ApplicationType.ScholarshipComplaint,
                ApplicationType.Discount,
                ApplicationType.Repayment,
                ApplicationType.FreeForm,
                ApplicationType.PersonData,
                ApplicationType.PersonPhoto,
                ApplicationType.PaymentTermExtension,
                ApplicationType.RepeatCourse
            ].indexOf(this.knownType) !== -1) {
                this.extend(cfg, { visible: false });
            }

            return cfg;
        },
        dateTo: () => {
            const cfg: IField = { visible: false, label: 'application_lblDateTo' };

            if (this.item.Type === ApplicationType.AcademicLeave) {
                this.extend(cfg, {
                    visible: this.getTopicPayload<IApplicationTopicAcademicLeavePayload>().ShowDateTo
                });
            } else if (this.item.Type === ApplicationType.JustifiedAbsence) {
                this.extend(cfg, this.getJustifiedAbsenceFieldConfig(this.getJustifiedAbsenceTopicPayload().DateTo));
            }

            return cfg;
        },
        attachments: () => {
            const cfg: IField = {
                visible: [
                    ApplicationType.AcademicLeave,
                    ApplicationType.Exmatriculation,
                    ApplicationType.FreeForm,
                    ApplicationType.Repayment,
                    ApplicationType.Discount,
                    ApplicationType.PaymentTermExtension,
                    ApplicationType.Complaint,
                    ApplicationType.AppealComplaint,
                    ApplicationType.ScholarshipComplaint,
                    ApplicationType.RepeatCourse,
                    ApplicationType.PersonData].indexOf(this.knownType) > -1,
                label: 'application_attachments'
            };

            if (this.item.Type === ApplicationType.JustifiedAbsence) {
                this.extend(cfg, this.getJustifiedAbsenceFieldConfig(this.getJustifiedAbsenceTopicPayload().Attachments));
            }

            return cfg;
        },
        comments: () => {
            const cfg: IField = { visible: false, label: 'application_lblComments', length: 4000 };

            if (this.item.Type === ApplicationType.JustifiedAbsence) {
                this.extend(cfg, this.getJustifiedAbsenceFieldConfig(this.getJustifiedAbsenceTopicPayload().Comments));
            } else if (this.item.Type === ApplicationType.FreeForm) {
                this.extend(cfg, { visible: true, required: true, label: 'application_lblApplicationText' });
            } else if ([ApplicationType.Complaint, ApplicationType.AppealComplaint, ApplicationType.ScholarshipComplaint].indexOf(this.knownType) !== -1) {
                this.extend(cfg, { visible: true, required: true, label: 'application_lblComplaintNature', length: 3000 });
            } else if ([ApplicationType.Discount, ApplicationType.Repayment, ApplicationType.PaymentTermExtension].indexOf(this.knownType) !== -1) {
                this.extend(cfg, { visible: true });
            }

            if (this.item.Type === ApplicationType.Discount)
                this.extend(cfg, { required: true });

            return cfg;
        },
        absenceType: () => {
            const cfg: IField = { visible: false, label: 'application_lblAbsenceType' };

            if (this.item.Type === ApplicationType.JustifiedAbsence) {
                this.extend(cfg, this.getJustifiedAbsenceFieldConfig(this.getJustifiedAbsenceTopicPayload().AbsenceType));
            }

            return cfg;
        },
        closingSheet: () => {
            const cfg: IField = { visible: false, label: 'application_lblClosingSheet', length: 100 };

            if (this.item.Type === ApplicationType.JustifiedAbsence) {
                this.extend(cfg, this.getJustifiedAbsenceFieldConfig(this.getJustifiedAbsenceTopicPayload().ClosingSheet));
            }

            return cfg;
        },
        documentNumber: () => {
            const cfg: IField = { visible: false, label: 'application_lblDocumentNumber', length: 100 };

            if (this.item.Type === ApplicationType.JustifiedAbsence) {
                this.extend(cfg, this.getJustifiedAbsenceFieldConfig(this.getJustifiedAbsenceTopicPayload().DocumentNumber));
            }

            return cfg;
        },
        prolongsPeriod: () => ({ visible: false, label: 'application_lblProlongsPeriod' }),
        studyCourse: () => {
            const cfg: IField = { visible: false, label: 'application_lblStudyCourse' };

            if (this.item.Type === ApplicationType.AppealComplaint) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        },
        appealCounter: () => {
            const cfg: IField = { visible: false, label: 'application_lblAppealCounter' };

            if (this.item.Type === ApplicationType.AppealComplaint) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        },
        scholarship: () => {
            const cfg: IField = { visible: false, label: 'application_lblScholarship' };

            if (this.item.Type === ApplicationType.ScholarshipComplaint) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        },
        academicYear: () => {
            const cfg: IField = { visible: false, label: 'application_lblAcademicYear' };

            if (this.item.Type === ApplicationType.Discount) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        },
        courseRound: () => {
            const cfg: IField = { visible: false, label: 'application_lblCourseRound' };

            if (this.item.Type === ApplicationType.RepeatCourse) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        },
        extraCourses: () => {
            const cfg: IField = { visible: false, label: 'application_courseList' };

            if (this.item.Type === ApplicationType.RepeatCourse) {
                this.extend(cfg, { visible: true });
            }

            return cfg;
        },
        contractNumber: () => {
            const cfg: IField = { visible: false, label: 'application_lblContractNumber' };

            if (this.item.Type === ApplicationType.PaymentTermExtension) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        },
        latePayments: () => {
            const cfg: IField = { visible: false, label: 'application_lblLatePayments' };

            if (this.item.Type === ApplicationType.PaymentTermExtension) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        },
        latePaymentSchedule: () => {
            const cfg: IField = { visible: false, label: 'application_latePaymentSchedule' };

            if (this.item.Type === ApplicationType.PaymentTermExtension) {
                this.extend(cfg, { visible: true });
            }

            return cfg;
        },
        personData: () => {
            const cfg: IField = { visible: false };

            if (this.item.Type === ApplicationType.PersonData) {
                this.extend(cfg, { visible: true });
            }

            return cfg;
        },
        personPhoto: () => {
            const cfg: IField = { visible: false };

            if (this.item.Type === ApplicationType.PersonPhoto) {
                this.extend(cfg, { visible: true, required: true });
            }

            return cfg;
        }
    };

    get showAttachmentRequired(): boolean {
        return this.fields.attachments().required && !this.attachments.some(t => !!t.file);
    }

    readonly extraCourseInfoDisplayFn = (option: ExtraCourseInfo) => {
        return `[${option.Code}] ${option.Name} (${option.CreditPoints}${this.app.translate('application_creditPointsAbbr')})`;
    }

    private allTopicItems: Classifier[] = [];
    private allAbsenceTypeItems: Classifier[] = [];

    ngOnInit() {
        super.ngOnInit();

        this.addAttachment();

        this.app.addLoading(this.parameters.getValues()).subscribe(data => {
            this.attachmentMaxSize = this.parameters.findValue(data, parameterCodes.attachmentMaxSize, t => +t, 0);
            this.attachmentLimit = this.parameters.findValue(data, parameterCodes.attachmentLimit, t => +t, 0);
            this.attachmentExtensions = this.parameters.findValue(data, parameterCodes.attachmentExtensions,
                t => '.' + t.split(',').join(',.'), '');

            const maxComplaintAppeals = this.parameters.findValue(data, parameterCodes.maxComplaintAppeals, t => +t, 1);
            for (let i = 0; i < maxComplaintAppeals; i++)
                this.appealCounterItems.push(i + 1);


            this.personPhotoFileMaxSize = this.parameters.findValue(data, parameterCodes.personPhotoFileMaxSize, t => +t, 0);
            this.personPhotoFileExtensions = this.parameters.findValue(data, parameterCodes.personPhotoFileExtensions,
                t => '.' + t.split(',').join(',.'), '');
        });

        const topicTypeCode = `ApplicationTopic${this.item.Type}`;
        const reasonTypeCode = `ApplicationReason${this.item.Type}`;
        const refLang = `AppRefLang${this.app.currentLanguage === 'en' ? 'En' : 'Lv'}`;
        const classifierCodes = [];

        // set default language
        this.item.RefLanguage = refLang;

        switch (this.item.Type) {
            case ApplicationType.AcademicLeave:
                classifierCodes.push(topicTypeCode);
                classifierCodes.push(reasonTypeCode);
                break;

            case ApplicationType.Exmatriculation:
                classifierCodes.push(topicTypeCode);
                break;

            case ApplicationType.JustifiedAbsence:
                classifierCodes.push(topicTypeCode);
                classifierCodes.push(ClassifierType.JustifiedAbsenceType);
                break;

            case ApplicationType.ScholarshipComplaint:
                classifierCodes.push(ClassifierType.ScholarshipType);

            case ApplicationType.Discount:
                classifierCodes.push(reasonTypeCode);
                break;

            case ApplicationType.Repayment:
                this.bankAccount = new BankAccount();
                classifierCodes.push(reasonTypeCode);
                {
                    const codeIn = 'BANK_ACCOUNT_LATVIA_IN';
                    const codeOut = 'BANK_ACCOUNT_LATVIA_OUT';
                    const lang = this.app.currentLanguage;

                    this.app.addLoading(this.messages.getByCodes([codeIn, codeOut])).subscribe(data => {
                        this.bankAccountInLatviaText = this.messages.getTextByLanguage(data.find(t => t.Code === codeIn), lang);
                        this.bankAccountOutOfLatviaText = this.messages.getTextByLanguage(data.find(t => t.Code === codeOut), lang);
                    });
                }
                break;

            case ApplicationType.RepeatCourse:
                classifierCodes.push(ClassifierType.CourseRound);
                this.extraCourses = [];
                break;

            case ApplicationType.PersonData:
                this.personData = [];
                break;

            case ApplicationType.PaymentTermExtension:
                this.latePaymentSchedule = [];
                this.addLatePayment();
                break;

            case ApplicationType.AppealComplaint:
                this.studyCourseItems = [];
                break;

            case ApplicationType.FreeForm:
                this.addressee = 'Rīgas Stradiņa Universitāte';
                break;
        }

        const getClassifiers = classifierCodes.length ? this.classifiers.get(classifierCodes.join(',')) : of([]);

        getClassifiers.subscribe(data => {
            this.topicItems = data.filter(t => t.Type === topicTypeCode);
            this.reasonItems = data.filter(t => t.Type === reasonTypeCode);

            this.scholarshipItems = data.filter(t => t.Type === ClassifierType.ScholarshipType);
            this.courseRoundItems = data.filter(t => t.Type === ClassifierType.CourseRound);

            this.allAbsenceTypeItems = data.filter(t => t.Type === ClassifierType.JustifiedAbsenceType);
            this.absenceTypeItems = this.allAbsenceTypeItems.filter(t => t.Code !== 'Medical');

            this.topicItems.forEach(t => this.parsePayload(t));
            this.allTopicItems = [...this.topicItems];

            if (this.personData) {
                const personFields: IPersonDataFieldModel[] = this.personDataGroups.reduce((arr, g) => {
                    arr.push(...g.fields);
                    return arr;
                }, []);

                {
                    this.app.addLoading(this.service.getPersonInfo(this.studentEmail)).subscribe(data => {
                        personFields.forEach(t => {
                            let value = data && data[t.source];

                            switch (t.type) {
                                case 'Date':
                                    value = Utils.ensureDate(value);
                                    break;

                                case 'Email':
                                    t.maxLength = 150;
                                    break;

                                case 'Phone':
                                    t.maxLength = 20;
                                    t.pattern = this.phonePattern;
                                    break;

                                case 'PersonCode':
                                    t.maxLength = 12;
                                    t.pattern = this.personCodePattern;
                                    break;

                                default:
                                    t.maxLength = 50;
                                    t.pattern = this.anyPattern;
                                    break;
                            }

                            t.value = value;
                            t.displayValue = value;
                            t.previousValue = value;
                        });

                        this.personData = [...personFields];
                    });
                }
            }

            if (this.selectedStudent) {
                this.onSetProgramme(this.selectedStudent);
            }
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (!this.isSuccess && this.itemChanged()) {
            const subj = new Subject<boolean>();
            this.app.confirm(this.app.translate('application_confirmUnsaved'), result => subj.next(result));
            return subj.asObservable();
        }

        return of(true);
    }

    protected onSetStudentData(data: Student[]): Observable<Student[]> {
        const st = data[0];

        if (this.item.Type === ApplicationType.Discount) {
            this.app.addLoading(this.service.getAcademicYears(st.StudentId)).subscribe(years => {
                this.academicYearItems = years;
            });
        } else if (this.item.Type === ApplicationType.PersonPhoto) {
            this.app.addLoading(this.service.getPersonPhoto(st.StudentId)).subscribe(d => {
                if (d.Photo && d.Photo != '') {
                    this.personPhoto = 'data:image/jpg;base64,' + d.Photo;
                } else {
                    this.personPhoto = 'assets/img/noprofile.png';
                }
            }, (err) => {
                this.personPhoto = 'assets/img/noprofile.png';
            });
        }

        if (st) {
            if (this.item.Type !== ApplicationType.FreeForm) {
                this.addressee = st.Addressee;
            }

            if (this.bankAccount) {
                this.bankAccount.isForeign = st.IsForeign;
            }
        }

        return of(data);
    }

    protected getFormErrors() {
        const errors = super.getFormErrors();

        if (this.fields.attachments().required && !this.attachments.some(t => !!t.file)) {
            errors.push({ error: this.app.translate('application_fileRequired') });
        }

        if (this.extraCourses && !this.extraCourses.length) {
            errors.push({ error: this.app.translate('application_courseRequired') });
        }

        if (this.fields.personPhoto().required && !this.personPhotoChange) {
            errors.push({
                error: this.app.translate('application_personPhotoRequired')
            });
        }

        return errors;
    }

    protected onSubmit() {
        const subj = new Subject<boolean>();

        this.confirm('application_confirmSubmit', ok => {
            if (ok) {
                if (this.fields.bankAccount().visible && this.bankAccount.isForeign) {
                    this.bankAccount.personCode = undefined;
                }

                if (this.fields.addressee().visible) this.item.Addressee = this.addressee;
                if (this.fields.topic().visible) this.item.TopicId = this.topic.Id;
                if (this.fields.reason().visible) this.item.ReasonId = this.reason ? this.reason.Id : undefined;
                if (this.fields.dateFrom().visible) this.item.DateFrom = this.dateFrom;
                if (this.fields.dateTo().visible) this.item.DateTo = this.dateTo;
                if (this.fields.documentNumber().visible) this.item.DocumentNumber = this.documentNumber;
                if (this.fields.comments().visible) this.item.Comments = this.comments;
                if (this.fields.absenceType().visible) this.item.AbsenceTypeId = this.absenceType ? this.absenceType.Id : undefined;
                if (this.fields.closingSheet().visible) this.item.ClosingSheet = this.closingSheet;
                if (this.fields.prolongsPeriod().visible) this.item.ProlongsPeriod = this.prolongsPeriod;
                if (this.fields.studyCourse().visible) this.item.StudyCourse = this.studyCourse;
                if (this.fields.appealCounter().visible) this.item.AppealCounter = this.appealCounter;
                if (this.fields.scholarship().visible) this.item.ScholarshipId = this.scholarship ? this.scholarship.Id : undefined;
                if (this.fields.courseRound().visible) this.item.CourseRoundId = this.courseRound ? this.courseRound.Id : undefined;
                if (this.fields.contractNumber().visible) this.item.ContractNumber = this.contractNumber;
                if (this.fields.latePayments().visible) this.item.LatePayments = this.latePayments;
                if (this.fields.academicYear().visible) this.item.AcademicYear = this.academicYear;
                if (this.fields.attachments().visible) this.files = this.attachments.filter(t => t.file).map(t => t.file);
                if (this.fields.bankAccount().visible) this.item.BankAccount = this.bankAccount;
                if (this.fields.latePaymentSchedule().visible) this.item.LatePaymentSchedule = this.latePaymentSchedule;

                if (this.fields.personData().visible) {
                    this.item.PersonData = this.personData.map(t => {
                        return {
                            Code: t.code,
                            Name: t.name,
                            Type: t.type,
                            DisplayValue: t.displayValue,
                            PreviousValue: t.previousValue,
                            Value: t.value
                        };
                    });
                }

                if (this.fields.extraCourses().visible) this.item.ExtraCourses = this.extraCourses;
                if (this.fields.personPhoto().visible) this.item.Photo = this.personPhotoChange;

                if (this.item.Type === ApplicationType.JustifiedAbsence) {
                    if (!this.item.AbsenceTypeId) {
                        this.item.AbsenceTypeId = this.allAbsenceTypeItems.find(t => t.Code === 'Medical').Id;
                    }
                }
            }

            subj.next(ok);
        });

        return subj.asObservable();
    }

    protected onSetProgramme(data: Student) {
        if (this.item.Type !== ApplicationType.FreeForm) {
            this.addressee = data.Addressee;
        }

        if (this.item.Type === ApplicationType.JustifiedAbsence) {
            this.topic = undefined;
            this.topicItems = [];

            this.app.addLoading(this.service.getAbsenceInfo(data.StudentId)).subscribe(abs => {
                if (!abs) {
                    this.isEmptyAbsences = true;
                    return of(data);
                }

                const topics = [];

                const addIf = (code: string) => {
                    if (abs.AvailableTypes.indexOf(code) > -1) {
                        const topic = this.allTopicItems.find(t => t.Code === code);
                        topic && topics.push(topic);
                    }
                };

                addIf('CloseLeave');
                addIf('OpenLeave');
                addIf('PlanAbsence');
                addIf('RegisterLeave');

                this.topicItems = topics;
                this.closingSheet = abs.Absence;

                const prolongsPeriodCfg = this.fields.prolongsPeriod();
                this.fields.prolongsPeriod = () => this.extend(prolongsPeriodCfg, ({ visible: abs.IsResident }));
            });
        } else if (this.item.Type === ApplicationType.RepeatCourse) {
            this.app.addLoading(this.service.getExtraCourseInfo(data.StudentId)).subscribe(info => {
                if (info.length) {
                    this.extraCourseInfo = info;
                } else {
                    this.isEmptyExtraCourses = true;
                }
            });
        } else if (this.item.Type === ApplicationType.PaymentTermExtension) {
            this.app.addLoading(this.service.getContracts(data.StudentId)).subscribe(contracts => {
                this.contractNumberItems = contracts;

                if (contracts.length === 1)
                    this.contractNumber = contracts[0];
            });
        } else if (this.item.Type === ApplicationType.AppealComplaint) {
            this.app.addLoading(this.service.getComplaintCourses(data.StudentId)).subscribe(data => {
                this.studyCourseItems = data.map(t => `(${t.Code}) ${t.Name}`);
            });
        }
    }

    addAttachment() {
        this.attachments.push(<IFieldFile>{});
    }

    removeAttachment(file: IFieldFile) {
        let i = this.attachments.indexOf(file);

        if (i > -1)
            this.attachments.splice(i, 1);
    }

    onFileChange(event: File, file: IFieldFile) {
        if (!event) {
            this.removeAttachment(file);
        }
    }

    changeNewProfilePhoto = (result: IPictureOutput) => {
        this.personPhotoChange = result;
    }

    editPersonAddress(field: IPersonDataFieldModel) {
        const ref = this.modal.open(AddressComponent, {
        });

        ref.closed.subscribe((data: Address) => {
            if (data) {
                field.value = JSON.stringify(data);
                field.displayValue = data.FullText;
            }
        });
    }

    onPersonFieldInput(field: IPersonDataFieldModel, value: string) {
        field.changed = true;

        if (field.type == 'Phone') {
            field.hint = this.app.translate('application_personData_phoneFeedback');
        }
    }

    onPersonDateChange(field: IPersonDataFieldModel, value: Date) {
        field.changed = true;
        field.value = Utils.getInputDateString(value);
    }

    addLatePayment() {
        this.latePaymentSchedule.push(new PaymentScheduleEntry());
    }

    removeLatePayment(payment: PaymentScheduleEntry) {
        const index = this.latePaymentSchedule.indexOf(payment);

        if (index > -1)
            this.latePaymentSchedule.splice(index, 1);
    }

    addExtraCourse() {
        if (!this.extraCourseAdded(this.selectedExtraCourse)) {
            this.extraCourses.push(this.selectedExtraCourse);
        }

        this.selectedExtraCourse = undefined;
    }

    removeExtraCourse(course: ExtraCourseInfo) {
        const index = this.extraCourses.indexOf(course);

        if (index > -1)
            this.extraCourses.splice(index, 1);
    }

    extraCourseAdded(course: ExtraCourseInfo) {
        return this.extraCourses.indexOf(course) > -1;
    }

    getAmountErrorPattern(obj: any, property: string): string {
        return obj[property] !== undefined && obj[property] !== '' && !/^(0|[1-9][0-9]*)([\.,][0-9]{1,2})?$/.test(obj[property])
            ? '^$' : '.*';
    }

    onAmountChange(input: any, obj: any, property: string) {
        let val = input.value;

        if (val === '') {
            obj[property] = undefined;
            return;
        }

        if (val === '.' || val === ',') {
            input.value = '0' + val;
            obj[property] = input.value;
            return;
        }

        let sep: string;

        for (let i = 0; i < val.length; i++) {
            if (val[i] === ',' || val[i] === '.') {
                sep = val[i];
                break;
            }
        }

        val = val
            .replace(/,/g, '.')
            .replace(/[^0-9\.]/g, '');

        const parts = val.split('.');

        if (parts.length > 1) {
            val = parts[0] + sep;

            const dec = parts[1];

            if (dec.length > 2) {
                val += dec.substring(0, 2);
            } else {
                val += dec;
            }
        }

        input.value = val;
        obj[property] = val;
    }

    validatePersonData(): string[] {
        const errors = [];

        if (this.personData.length) {
            const passportNr = this.personData.find(t => t.code == 'PassportNumber');
            const passportEd = this.personData.find(t => t.code == 'PassportExpiryDate');

            if (!passportNr.value || !passportEd.value) {
                const idNr = this.personData.find(t => t.code == 'IdCardNumber');
                const idEd = this.personData.find(t => t.code == 'IdCardExpiryDate');

                if (!idNr.value || !idEd.value) {
                    errors.push('application_personDataPassportOrIdCardRequired');
                }
            }
        }

        return errors;
    }

    private parsePayload(classifier: Classifier) {
        const payload = classifier.Payload ? JSON.parse(classifier.Payload) : {};
        classifier['_payload'] = payload;
    }

    private getPayload<T>(classifier: Classifier): T {
        return classifier['_payload'];
    }

    private getTopicPayload<T>(): T {
        return this.topic ? this.getPayload<T>(this.topic) : <T>{};
    }

    private getJustifiedAbsenceTopicPayload(): IApplicationTopicJustifiedAbsencePayload {
        return this.getTopicPayload<IApplicationTopicJustifiedAbsencePayload>();
    }

    private getJustifiedAbsenceFieldConfig(field: IApplicationTopicJustifiedAbsenceField): IField {
        return {
            visible: !!field,
            required: field && field.Required
        };
    }

    private confirm(text: string, callback: (ok: boolean) => any) {
        this.app.confirm({
            text: this.app.translate(text)
        }, ok => callback(ok));
    }

    private itemChanged(): boolean {
        return !!(this.addressee
            || this.topic
            || this.reason
            || this.dateFrom
            || this.dateTo
            || this.documentNumber
            || this.comments
            || this.absenceType
            || this.closingSheet
            || (this.prolongsPeriod !== this.defaultProlongsPeriod)
            || this.studyCourse
            || this.appealCounter
            || this.scholarship
            || this.contractNumber
            || this.academicYear
            || this.courseRound
            || (this.extraCourses && this.extraCourses.length)
            || (this.personData && this.personData.some(t => t.value != t.previousValue))
            || (this.latePaymentSchedule && this.latePaymentSchedule.length)
            || (this.bankAccount
                && (this.bankAccount.bank || this.bankAccount.holder || this.bankAccount.number || this.bankAccount.swift || this.bankAccount.personCode))
            || this.attachments.some(t => !!t.file));
    }

    private extend<T>(source: T, target: T): T {
        return Object.assign(source, target);
    }
}
