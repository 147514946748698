import { Component, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { SurveyService } from '../../../services/survey.service';
import { ISurveyBegin } from '../../../models/Survey';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-survey-begin',
    templateUrl: './survey-begin.component.html',
    styleUrls: ['../survey.component.scss']
})
export class SurveyBeginComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: SurveyService,
        private route: ActivatedRoute
    ) { }

    data: ISurveyBegin;
    error: string;
    isAuthenticated: boolean;

    ngOnInit() {
        this.isAuthenticated = !!this.app.currentUser;

        const token = this.route.snapshot.params.token;

        this.app.addLoading(this.service.begin(token)).subscribe(data => {
            this.data = data;
        }, err => {
            this.error = this.app.getHttpResponseError(err);
        });
    }

    begin() {
        this.app.navigateByUrl(`/surveys/start/${this.data.Survey.ID}?token=${this.data.Token.Key}`);
    }

    cancel() {
        this.app.navigateByUrl('/surveys');
    }
}
