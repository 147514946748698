<div class="row">
    <div class="form-field col-3">
        <label class="form-label">{{'finalPaperReview_lblSearch' | translate}}</label>
        <input name="search" class="form-control" [(ngModel)]="searchValue" (input)="search()">
    </div>

    <div class="form-field col-3">
        <label class="form-label">{{'finalPaperReview_lblFaculty' | translate}}</label>
        <app-select [(ngModel)]="selectedFaculty"
                    [options]="options.faculty"
                    (selectionChange)="filter()"
                    name="faculty">
        </app-select>
    </div>

    <div class="form-field col-3">
        <label class="form-label">{{'finalPaperReview_lblStudyProgramme' | translate}}</label>
        <app-select [(ngModel)]="selectedProgram"
                    [options]="options.program"
                    [displayProperty]="'name'"
                    (selectionChange)="filter()"
                    name="program">
        </app-select>
    </div>

    <div class="form-field col-3">
        <label class="form-label">{{'finalPaperReview_lblThesisType' | translate}}</label>
        <app-select [(ngModel)]="selectedType"
                    [options]="options.type"
                    (selectionChange)="filter()"
                    name="thesisType">
        </app-select>
    </div>
</div>

<div class="form-field">
    <label class="form-label">{{'finalPaperReview_lblRole' | translate}}</label>
    <span class="ms-3">
        <app-checkbox [ngModel]="selectedRole === 'all'" (stateChange)="changeRole('all')" [disabled]="selectedRole === 'all'">
            {{'finalPaperReview_lblRoleAll' | translate}}
        </app-checkbox>
    </span>
    <span class="ms-3">
        <app-checkbox [ngModel]="selectedRole === 'supervisor'" (stateChange)="changeRole('supervisor')" [disabled]="selectedRole === 'supervisor'">
            {{'finalPaperReview_lblRoleSupervisor' | translate}}
        </app-checkbox>
    </span>
    <span class="ms-3">
        <app-checkbox [ngModel]="selectedRole === 'reviewer'" (stateChange)="changeRole('reviewer')" [disabled]="selectedRole === 'reviewer'">
            {{'finalPaperReview_lblRoleReviewer' | translate}}
        </app-checkbox>
    </span>
</div>

<app-table [rows]="items" [columns]="columns" [(page)]="page" [sticky]="'right'" [pageSize]="50">
    <ng-template #rowTemplate let-row>
        <tr>
            <td>{{row.student}}</td>
            <td>
                {{row.Faculty}}
                <div class="text-sub">{{row.programme}}</div>
            </td>
            <td>{{row.Title}}</td>
            <td>{{row.supervisor}}</td>
            <td>{{row.ThesisType}}</td>
            <td>{{'finalPaper_status' + row.Status | translate}}</td>
            <td>
                <div class="table-actions">
                    <a [routerLink]="['/final-paper-reviews/', row.Id] | localize" class="btn btn-outline btn-icon" [ngbTooltip]="'open' | translate">
                        <i class="fi fi-rs-pencil"></i>
                    </a>
                </div>
            </td>
        </tr>
    </ng-template>
</app-table>
