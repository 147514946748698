import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';

import { IPersonSearchResultItem } from '../../models/Person';
import { PlanSummaryService } from '../../services/plan-summary.service';
import {
    IPlanSummaryManipulation, IPlanSummaryResident, IPlanSummaryRotations,
    IPlanSummarySeminar, IPlanSummaryShifts, IPlanSummarySupervisor
} from '../../models/PlanSummary';

export const currentRequestParams: {
    studentId?: string,
    studentEmail?: string,
    programId?: string
} = {};

export const store: {
    resident?: IPlanSummaryResident,
    shifts?: IPlanSummaryShifts,
    seminars?: IPlanSummarySeminar[],
    manipulations?: IPlanSummaryManipulation[],
    rotations?: IPlanSummaryRotations,
    supervisor?: IPlanSummarySupervisor
} = {};

interface ITab {
    id: string;
    title: string
}

@Component({
    selector: 'app-plan-summary',
    templateUrl: './plan-summary.component.html',
    styleUrls: ['./plan-summary.component.scss']
})
export class PlanSummaryComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PlanSummaryService,
        private route: ActivatedRoute
    ) { }

    readonly tabs: ITab[] = [
        { id: 'base', title: 'planSummary_baseInfo' },
        { id: 'courses', title: 'planSummary_courses' },
        { id: 'manipulations', title: 'planSummary_manipulations' },
        { id: 'shifts', title: 'planSummary_shifts' },
        { id: 'seminars', title: 'planSummary_seminars' }
    ];

    readonly visibleTabs = 4;

    activeTabId: number = 0;
    residentInfo: IPlanSummaryResident[] = [];
    resident: IPlanSummaryResident;
    notAvailable: boolean;
    studentPickerOpened: boolean;
    canPickStudent: boolean;
    specialityId: string;
    studentId: string;
    studentEmail: string;
    programId: string;
    supervisor: IPlanSummarySupervisor;

    readonly specialityDisplayFn = (option: IPlanSummaryResident) => `${option.SpecialityCode} - ${option.SpecialityName}`;
    readonly getFileUrlFn = (fileId: string) => {
        return this.service.getFileUrl(fileId, {
            studentEmail: this.studentEmail,
            studentId: this.studentId
        });
    };

    ngOnInit() {
        this.studentId = this.route.snapshot.queryParams['StudentId'];
        this.studentEmail = this.route.snapshot.queryParams['StudentEmail'];
        this.programId = this.route.snapshot.queryParams['ProgramId'];

        if (this.studentId) {
            currentRequestParams.studentEmail = '';
            currentRequestParams.programId = '';
            currentRequestParams.studentId = this.studentId;
        } else {
            currentRequestParams.studentEmail = this.studentEmail;
            currentRequestParams.programId = this.programId;
            currentRequestParams.studentId = '';
        }

        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf('PLAN_SUMMARY.SET_STUDENT') > -1;
            
            if (user.hasRight('PLAN_SUMMARY.DOCUMENTS')) {
                this.tabs.push({ id: 'documents', title: 'planSummary_documents'});
            }

            if (user.rights.indexOf('STUDY_QUALITY_EVALUATION.VIEW') > -1) {
                this.tabs.push({ id: 'evaluation', title: 'planSummary_qualityEvaluation' });
            }

            if (user.rights.indexOf('PLAN_SUMMARY.EVENTS') > -1) {
                this.tabs.push({ id: 'events', title: 'planSummary_events' });
            }
        }

        const queryTab = this.route.snapshot.queryParams['tab'];

        if (queryTab) {
            const tab = this.tabs.find(t => t.id == queryTab);

            if (tab) {
                this.activeTabId = this.tabs.indexOf(tab);
            }
        }

        if (this.studentId || this.studentEmail) {
            this.init();
        } else if (this.canPickStudent) {
            this.toggleStudentPicker();
        } else {
            this.init();
        }
    }

    onSpecialityChange() {
        this.resident = this.residentInfo.find(t => t.SpecialityId == this.specialityId);
        this.studentEmail = currentRequestParams.studentEmail = this.resident.Email;
        this.programId = currentRequestParams.programId = this.resident.ProgramId;

        this.init();
    }

    toggleStudentPicker() {
        this.studentPickerOpened = !this.studentPickerOpened;

        if (this.studentPickerOpened) {
            this.studentId = undefined;
            this.studentEmail = undefined;
            this.resident = undefined;
            this.residentInfo = [];
            this.notAvailable = false;
        }
    }

    pickStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.programId = null;
        this.studentId = currentRequestParams.studentId = null;
        this.studentEmail = currentRequestParams.studentEmail = student.Email;
        this.init();
    }

    private init() {
        store.rotations = null;
        store.manipulations = null;
        store.seminars = null;
        store.shifts = null;
        store.supervisor = null;
        store.resident = null;

        this.app.addLoading(this.service.getResident({
            studentId: this.studentId,
            studentEmail: this.studentEmail
        })).subscribe(data => {
            this.residentInfo = data;

            if (data.length) {
                this.resident = this.programId ? data.find(t => t.ProgramId == this.programId) : data[0];

                store.resident = this.resident;

                if (this.resident) {
                    this.programId = currentRequestParams.programId = this.resident.ProgramId;
                    this.specialityId = this.resident.SpecialityId;

                    this.app.addLoading(this.service.getSupervisor(currentRequestParams)).subscribe(data => {
                        this.supervisor = store.supervisor = data || null;
                    });
                } else {
                    this.notAvailable = true;
                }
            } else {
                this.notAvailable = true;
            }
        }, err => {
            this.notAvailable = true;
            throw err;
        });
    }
}
