import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, Input } from '@angular/core';
import { IPublishedTile } from '../../models/Tile';

export type TileViewType = 'desktop-tile' | 'desktop-tile-always' | 'desktop-text' | 'mobile-tile' | 'mobile-tile-always' | 'mobile-text';

@Component({
    selector: 'app-tile',
    templateUrl: './tile.component.html',
    host: { 'class': 'tile' }
})
export class TileComponent {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    @Input() item: IPublishedTile;
    @Input() view: TileViewType[] = ['desktop-tile', 'mobile-tile'];

    @HostBinding('class') get cssClass(): string {
        return `tile ${this.getViewCssClass()} tile-size-${this.item?.Size} tile-type-${this.item?.Image ? 'image' : 'text'}`;
    }

    getViewCssClass(): string {
        return this.view.join(' ');
    }

    isInternalUrl(url: string): boolean {
        return url.startsWith('/');
    }

    isExternalUrl(url: string): boolean {
        return url.toLowerCase().startsWith('http');
    }

    isAnchor(url: string): boolean {
        return url.startsWith('#');
    }

    onAnchor(item: IPublishedTile) {
        const el: HTMLAnchorElement = this.document.querySelector(`[data-anchor="${item.Url.toLowerCase()}"]`);
        
        if (el) {
            el.click();

            let scrollToEl: HTMLElement = el;

            if (!scrollToEl.offsetParent) {
                while (scrollToEl && !scrollToEl.offsetParent) {
                    scrollToEl = scrollToEl.parentElement;
                }
            }

            if (scrollToEl) {
                this.document.defaultView.scrollTo({ top: scrollToEl.offsetTop, behavior: 'smooth' });
            }
        }
    }
}
