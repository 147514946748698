import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { AppService } from '../../services/app.service';
import { CertificateRequestService } from '../../services/certificate-request.service';

@Component({
    templateUrl: './send-emails.component.html',
    styleUrls: ['./certificate-requests.component.scss']
})
export class CertificateRequestSendEmailsComponent {
    constructor(
        private app: AppService,
        private service: CertificateRequestService,
        private activeModal: NgbActiveModal
    ) {}

    @Input() certificateArea: string;

    eventCode: string;
    submitted: boolean;
    isSaving: boolean;

    @ViewChild('form', { static: true }) private form: NgForm;

    submit() {
        if (!this.form.valid) {
            this.submitted = true;
            this.app.alert.warning(this.app.translate('invalidFormWarning'));
            return;
        }

        this.isSaving = true;
        this.app.addLoading(this.service.sendEmails(this.eventCode, this.certificateArea), 500)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(result => {
            this.app.notify(this.app.translate('certRequests_sendEmailsSuccess'));
            this.activeModal.close();
        });
    }

    close() {
        this.activeModal.close();
    }
}
