<div class="wrapper-lg">
    <app-breadcrumbs [items]="[
                 { url: '/ebus/session-config', text: 'ebusSessionConfig_listTitle' },
                 { url: '/ebus/session-config/' + item?.Id, text: item?.Code }
                 ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'ebusSessionConfig_editTitle' | translate}}</h1>
    </header>

    <form #form="ngForm" [class.was-validated]="submitted">
        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblCode' | translate}}</label>
                <input class="form-control" disabled [value]="item?.Code" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblDescription' | translate}}</label>
                <textarea class="form-control" [(ngModel)]="model.Description" name="description" maxlength="500" rows="5" #descInput="ngModel"></textarea>
                <div class="invalid-feedback" *ngIf="descInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 500]}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field required">
                <label class="form-label">{{'ebusSessionConfig_lblDefaultTimeInterval' | translate}}</label>
                <input class="form-control" [(ngModel)]="model.DefaultTimeInterval" name="defaultTimeInterval" required pattern="^[1-9][0-9]{0,2}$" #intervalInput="ngModel" />
                <div class="feedback">{{'ebusSessionConfig_hintDefaultTimeInterval' | translate}}</div>
                <div class="invalid-feedback" *ngIf="intervalInput.errors?.pattern">{{'fieldInvalid' | translate}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field required">
                <label class="form-label">{{'ebusSessionConfig_lblPageSize' | translate}}</label>
                <input class="form-control" [(ngModel)]="sessionParams.PageSize" name="pageSize" required pattern="^[1-9][0-9]{0,2}$" #pageSizeInput="ngModel" />
                <div class="feedback">{{'ebusSessionConfig_hintPageSize' | translate}}</div>
                <div class="invalid-feedback" *ngIf="pageSizeInput.errors?.pattern">{{'fieldInvalid' | translate}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblDefaultContexts' | translate}}</label>
                <textarea class="form-control" [(ngModel)]="sessionParams.DefaultContexts" name="defaultContexts" maxlength="4000" rows="5" required #defaultContextsInput="ngModel"></textarea>
                <div class="feedback">{{defaultContextsHint}}</div>
                <div class="invalid-feedback" *ngIf="defaultContextsInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 4000]}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblDefaultTagFilterValues' | translate}}</label>
                <input class="form-control" [(ngModel)]="sessionParams.DefaultTagFilterValues" name="defaultTagFilterValues" maxlength="200" required #defaultTagFilterValuesInput="ngModel">
                <div class="feedback">{{defaultTagFilterValuesHint}}</div>
                <div class="invalid-feedback" *ngIf="defaultTagFilterValuesInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 200]}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblReturnMessageFormat' | translate}}</label>
                <input class="form-control" [(ngModel)]="sessionParams.ReturnMessageFormat" maxlength="50" name="returnMessageFormat" #returnMessageFormatInput="ngModel" />
                <div class="invalid-feedback" *ngIf="returnMessageFormatInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 50]}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblContextFilterValues' | translate}}</label>
                <input class="form-control" [(ngModel)]="sessionParams.ContextFilterValues" name="contextFilterValues" maxlength="200" />
                <div class="feedback">{{'ebusSessionConfig_hintContextFilterValues' | translate}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblTagFilterValues' | translate}}</label>
                <input class="form-control" [(ngModel)]="sessionParams.TagFilterValues" name="tagFilterValues" maxlength="200" />
                <div class="feedback">{{'ebusSessionConfig_hintTagFilterValues' | translate}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field">
                <label class="form-label">{{'ebusSessionConfig_lblNoEventsMessage' | translate}}</label>
                <input class="form-control" [(ngModel)]="sessionParams.NoEventsMessage" maxlength="200" name="noEventsMessage" #noEventsMessageInput="ngModel" />
                <div class="invalid-feedback" *ngIf="noEventsMessageInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 200]}}</div>
            </div>
        </div>

        <div class="mb-1">
            <app-checkbox [(ngModel)]="sessionParams.ShowEventFilter" name="showEventFilter">{{'ebusSessionConfig_lblShowEventFilter' | translate}}</app-checkbox>
        </div>

        <div class="mb-1">
            <app-checkbox [(ngModel)]="sessionParams.ShowTagFilter" name="showTagFilter">{{'ebusSessionConfig_lblShowTagFilter' | translate}}</app-checkbox>
        </div>

        <div class="mb-3">
            <app-checkbox [(ngModel)]="sessionParams.ShowContextFilter" name="showContextFilter">{{'ebusSessionConfig_lblShowContextFilter' | translate}}</app-checkbox>
        </div>

        <div class="row">
            <div class="col-md-6 form-field required">
                <label class="form-label">{{'ebusSessionConfig_lblTableFormat' | translate}}</label>
                <textarea class="form-control" [(ngModel)]="sessionParams.TableFormat" name="tableFormat" maxlength="4000" rows="10" required #tableFormatInput="ngModel"></textarea>
                <div class="feedback">{{tableFormatHint}}</div>
                <div class="invalid-feedback" *ngIf="tableFormatInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 4000]}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-field required">
                <label class="form-label">{{'ebusSessionConfig_lblDetailsFormat' | translate}}</label>
                <textarea class="form-control" [(ngModel)]="sessionParams.DetailsFormat" name="detailsFormat" maxlength="4000" rows="10" required #detailsFormatInput="ngModel"></textarea>
                <div class="feedback">{{detailsFormatHint}}</div>
                <div class="invalid-feedback" *ngIf="detailsFormatInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 4000]}}</div>
            </div>
        </div>

        <div class="form-actions">
            <button type="button" class="btn btn-outline" [routerLink]="['/ebus/session-config'] | localize">
                {{'ebusSessionConfig_btnCancel' | translate}}
            </button>

            <button class="btn btn-primary" type="button" (click)="save()">
                {{'ebusSessionConfig_btnSave' | translate}}
            </button>
        </div>
    </form>
</div>
