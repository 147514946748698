import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';

import {
    ApplicationConfig, ApplicationDataSource, ApplicationDataSourceResultType, ApplicationDataSourceType,
    ApplicationFormFieldType, ApplicationOrderProcessType, ApplicationFormField, ApplicationConfigSaveModel, ApplicationFormFileField
} from '../../models/ApplicationConfig';
import { Classifier } from '../../models/Classifier';

import { AppService } from '../../services/app.service';
import { ApplicationConfigService } from '../../services/application-config.service';
import { ClassifierService } from '../../services/classifier.service';
import { MessageService } from '../../services/message.service';
import { TemplateService } from '../../services/template.service';
import { IFieldFile } from '../../models/IFieldFile';
import { ITableColumn } from '../../shared/table/table.component';
import { createFileStub } from '../../shared/file/file.component';

type GridType = 'dataSource' | 'formField' | 'formFileField';

interface IDataSourceCheck {
    error?: boolean;
    columns: string[];
}

interface IRow<T> {
    id: number;
    data: T;
}

@Component({
    selector: 'app-application-config-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class ApplicationConfigFormComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        private app: AppService,
        private service: ApplicationConfigService,
        private classifierService: ClassifierService,
        private messageService: MessageService,
        private templateService: TemplateService,
        private route: ActivatedRoute
    ) { }

    item = new ApplicationConfig();
    model = new ApplicationConfigSaveModel();

    readonly orderProcessItems: ApplicationOrderProcessType[] = ['None', 'DVS', 'DocPort'];
    readonly dataSourceResultTypeItems: ApplicationDataSourceResultType[] = ['List', 'Dictionary'];
    readonly formFieldTypeItems: ApplicationFormFieldType[] = ['Date', 'Integer', 'Select', 'Autocomplete', 'Text', 'Multiline', 'Money'];
    readonly formFieldSizeItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    readonly dataSourceColumns: ITableColumn[] = [
        { width: '100px !important' },
        { label: 'applicationConfig_dataSource_lblName', width: '20%' },
        { label: 'applicationConfig_dataSource_lblDatabase', width: '20%' },
        { label: 'applicationConfig_dataSource_lblProcedure', width: '20%' },
        { label: 'applicationConfig_dataSource_lblResultType', width: '20%' },
        {}
    ];

    readonly formFieldColumns: ITableColumn[] = [
        { width: '100px !important' },
        { label: 'applicationConfig_formField_lblName', width: '10%' },
        { label: 'applicationConfig_formField_lblRow', width: '10%' },
        { label: 'applicationConfig_formField_lblSize', width: '10%' },
        { label: 'applicationConfig_formField_lblNameLV', width: '10%' },
        { label: 'applicationConfig_formField_lblNameEN', width: '10%' },
        { label: 'applicationConfig_formField_lblType', width: '10%' },
        { label: 'applicationConfig_formField_lblSource', width: '10%' },
        { label: 'applicationConfig_formField_lblRequiredEditable', width: '10%' },
        {}
    ];

    readonly formFileFieldColumns: ITableColumn[] = [
        { width: '100px !important' },
        { label: 'applicationConfig_formFileField_lblName', width: '10%' },
        { label: 'applicationConfig_formFileField_lblRow', width: '10%' },
        { label: 'applicationConfig_formFileField_lblNameLV', width: '10%' },
        { label: 'applicationConfig_formFileField_lblNameEN', width: '10%' },
        { label: 'applicationConfig_formFileField_lblExtensions', width: '10%' },
        { label: 'applicationConfig_formFileField_lblMaxSize', width: '10%' },
        { label: 'applicationConfig_formFileField_lblMaxFileCount', width: '10%' },
        { label: 'applicationConfig_formFileField_lblRequired', width: '10%' },
        {}
    ];

    isNew: boolean;
    title: string;
    howTo: string;
    submitted: boolean;
    dataSourceDbItems: string[] = [];
    groupItems: Classifier[] = [];
    dataSourceCheck: { [key: string]: IDataSourceCheck } = {};
    formFields: IRow<ApplicationFormField>[] = [];
    formFileFields: IRow<ApplicationFormFileField>[] = [];

    templateLV: IFieldFile = {};
    templateEN: IFieldFile = {};
    orderTemplateLV: IFieldFile = {};
    orderTemplateEN: IFieldFile = {};

    readonly attachmentRows: { model: IFieldFile, label: string }[][] = [
        [
            { model: this.templateLV, label: 'applicationConfig_lblTemplateLV' },
            { model: this.templateEN, label: 'applicationConfig_lblTemplateEN' }
        ],
        [
            { model: this.orderTemplateLV, label: 'applicationConfig_lblOrderTemplateLV' },
            { model: this.orderTemplateEN, label: 'applicationConfig_lblOrderTemplateEN' }
        ]
    ];

    get isDraft(): boolean {
        return this.item.Status === 'Draft';
    }

    get isPublished(): boolean {
        return this.item.Status === 'Published';
    }

    get isDeactivated(): boolean {
        return this.item.Status === 'Deactivated';
    }

    get hasUnsavedGridRows(): boolean {
        return this.rowEditing.dataSource.length > 0 || this.rowEditing.formField.length > 0;
    }

    @ViewChild('form', { static: true }) private form: NgForm;

    private baseTemplateTags: string[] = [];
    private origModel: ApplicationConfigSaveModel;
    private unsavedConfirmation?: boolean;
    private dataSaved: boolean;
    private currentUniqueRowIndex: number = 0;

    private readonly rowEditing = {
        dataSource: [],
        formField: [],
        formFileField: []
    };

    ngOnInit() {
        const isEn = this.app.currentLanguage === 'en';

        this.model.Template = undefined;
        this.model.OrderTemplate = undefined;

        this.route.params.subscribe(para => {
            const id = para['id'];

            this.clearEditing();

            if (!id) {
                this.isNew = true;

                this.item.Status = 'Draft';
                this.item.Version = 1;

                this.origModel = JSON.parse(JSON.stringify(this.model));
            } else {
                this.isNew = false;

                this.app.addLoading(this.service.getById(+id)).subscribe(data => {
                    this.title = data.Code;
                    this.item = data;

                    this.model.Id = data.Id;
                    this.model.Code = data.Code;
                    this.model.DataSources = data.DataSources;

                    this.formFields = data.FormFields.map(t => {
                        return {
                            data: t,
                            id: ++this.currentUniqueRowIndex
                        };
                    });

                    if (data.FormFileFields == null) {
                        this.formFileFields = [];
                    } else {
                        this.formFileFields = data.FormFileFields.map(t => {
                            return {
                                data: t,
                                id: ++this.currentUniqueRowIndex
                            };
                        });
                    }


                    this.model.GroupId = data.GroupId;
                    this.model.NameEN = data.NameEN;
                    this.model.NameLV = data.NameLV;
                    this.model.OrderProcess = data.OrderProcess;

                    if (data.OrderTemplate) {
                        this.orderTemplateEN.file = createFileStub(data.OrderTemplate.FileNameEN);
                        this.orderTemplateLV.file = createFileStub(data.OrderTemplate.FileNameLV);

                        this.orderTemplateEN.url = this.orderTemplateEN.fileName && this.templateService.getFileUrl(data.OrderTemplate.Id, 'en');
                        this.orderTemplateLV.url = this.orderTemplateLV.fileName && this.templateService.getFileUrl(data.OrderTemplate.Id, 'lv');
                    }

                    if (data.Template) {
                        this.templateEN.file = createFileStub(data.Template.FileNameEN);
                        this.templateLV.file = createFileStub(data.Template.FileNameLV);

                        this.templateEN.url = this.templateEN.fileName && this.templateService.getFileUrl(data.Template.Id, 'en');
                        this.templateLV.url = this.templateLV.fileName && this.templateService.getFileUrl(data.Template.Id, 'lv');
                    }

                    this.checkDataSources().subscribe(() => {
                        this.model.FormFields.forEach(t => {
                            if (t.Source) {
                                this.validateFormFieldSource(t.Source);
                            }
                        });
                    });

                    this.origModel = JSON.parse(JSON.stringify(this.model));
                });
            }
        });

        this.app.addLoading(this.service.getDatabases()).subscribe(data => {
            this.dataSourceDbItems = data;
        });

        this.app.addLoading(this.classifierService.get('ApplicationGroup')).subscribe(data => {
            this.groupItems = data.filter(t => t.Type === 'ApplicationGroup');
        });

        this.app.addLoading(this.service.getBaseTemplateTags()).subscribe(data => {
            this.baseTemplateTags = data;
        });

        this.app.addLoading(this.messageService.getByCode('APPLICATION_CONFIG_HOW_TO')).subscribe(data => {
            this.howTo = data && (isEn ? data.TextEN : data.TextLV);
        });
    }

    canDeactivate(): Observable<boolean> {
        if (!this.dataSaved) {
            if (this.unsavedConfirmation !== undefined) {
                return of(this.unsavedConfirmation);
            } else if (this.hasChanges()) {
                const subj = new Subject<boolean>();

                this.app.confirm(this.app.translate('applicationConfig_confirmUnsaved'), result => {
                    // for some reason, canDeactivate fires twice if there are changes
                    // store user confirmation result for a short period of time to prevent duplicate popups
                    this.unsavedConfirmation = result;
                    setTimeout(() => {
                        this.unsavedConfirmation = undefined;
                    }, 100);

                    subj.next(result);
                });

                return subj.asObservable();
            }
        }

        return of(true);
    }

    editGridRow(row: any, grid: GridType) {
        const snapshot = JSON.stringify(row);
        this.rowEditing[grid].push({
            row,
            snapshot
        });
    }

    cancelGridRow(row: any, grid: GridType) {
        const index = this.rowEditing[grid].findIndex(t => t.row == row);

        if (index > -1) {
            const snapshot = JSON.parse(this.rowEditing[grid][index].snapshot);

            this.rowEditing[grid].splice(index, 1);

            for (const key in snapshot) {
                row[key] = snapshot[key];
            }
        }
    }

    saveGridRow(row: any, grid: GridType) {
        const index = this.rowEditing[grid].findIndex(t => t.row == row);

        if (index > -1) {
            this.rowEditing[grid].splice(index, 1);
        }

        if (grid === 'formField') {
            const curr = row as IRow<ApplicationFormField>;
            const lastInRow = this.formFields.filter(t => t !== curr && t.data.Row === curr.data.Row).pop();

            if (lastInRow) {
                const currIndex = this.formFields.indexOf(curr);

                if (currIndex > 0) {
                    const prevItem = this.formFields[currIndex - 1];

                    if (curr.data.Row !== prevItem.data.Row) {
                        const lastInRowIndex = this.formFields.indexOf(lastInRow);

                        this.formFields.splice(currIndex, 1);
                        this.formFields.splice(lastInRowIndex + 1, 0, curr);
                    }
                }
            }
        } else if (grid === 'dataSource') {
            this.checkDataSource(row as ApplicationDataSource);
        }
    }

    isGridRowEditing(row: any, grid: GridType) {
        return this.rowEditing[grid].findIndex(t => t.row == row) > -1;
    }

    moveFormFieldUp(item: IRow<ApplicationFormField>) {
        const array = [...this.formFields];
        const index = array.indexOf(item);

        if (index > 0) {
            const sub = array[index - 1];

            if (sub.data.Row !== item.data.Row) {
                this.moveFormFieldsUp(item.data.Row);
            } else {
                array[index - 1] = item;
                array[index] = sub;

                this.formFields = array;
            }
        }
    }

    moveFormFieldDown(item: IRow<ApplicationFormField>) {
        const array = [...this.formFields];
        const index = array.indexOf(item);

        if (index > -1 && index < array.length - 1) {
            const sub = array[index + 1];

            if (sub.data.Row !== item.data.Row) {
                this.moveFormFieldsDown(item.data.Row);
            } else {
                array[index + 1] = item;
                array[index] = sub;

                this.formFields = array;
            }
        }
    }

    moveFormFieldsUp(row: string) {
        const array = [...this.formFields];
        const items = array.filter(t => t.data.Row === row);

        if (items.length) {
            const firstIndex = array.indexOf(items[0]);

            if (firstIndex > 0) {
                const prevRow = array[firstIndex - 1].data.Row;
                const prevItems = array.filter(t => t.data.Row === prevRow);
                const moveIndex = array.indexOf(prevItems[0]);

                array.splice(firstIndex, items.length);
                array.splice(moveIndex, 0, ...items);
            }
        }

        this.formFields = [...array];
    }

    moveFormFieldsDown(row: string) {
        const array = [...this.formFields];
        const items = array.filter(t => t.data.Row === row);

        if (items.length) {
            const firstIndex = array.indexOf(items[0]);
            const lastIndex = array.indexOf(items[items.length - 1]);

            if (lastIndex < array.length - 1) {
                const nextRow = array[lastIndex + 1].data.Row;
                const nextItems = array.filter(t => t.data.Row === nextRow);
                const moveIndex = array.indexOf(nextItems[nextItems.length - 1]);

                array.splice(firstIndex, items.length);
                array.splice(moveIndex, 0, ...items);
            }
        }

        this.formFields = [...array];
    }

    moveFormFileFieldUp(item: IRow<ApplicationFormFileField>) {
        const array = [...this.formFileFields];
        const index = array.indexOf(item);

        if (index > 0) {
            const sub = array[index - 1];

            if (sub.data.Row !== item.data.Row) {
                this.moveFormFileFieldsUp(item.data.Row);
            } else {
                array[index - 1] = item;
                array[index] = sub;

                this.formFileFields = array;
            }
        }
    }

    moveFormFileFieldDown(item: IRow<ApplicationFormFileField>) {
        const array = [...this.formFileFields];
        const index = array.indexOf(item);

        if (index > -1 && index < array.length - 1) {
            const sub = array[index + 1];

            if (sub.data.Row !== item.data.Row) {
                this.moveFormFileFieldsDown(item.data.Row);
            } else {
                array[index + 1] = item;
                array[index] = sub;

                this.formFileFields = array;
            }
        }
    }

    moveFormFileFieldsUp(row: string) {
        const array = [...this.formFileFields];
        const items = array.filter(t => t.data.Row === row);

        if (items.length) {
            const firstIndex = array.indexOf(items[0]);

            if (firstIndex > 0) {
                const prevRow = array[firstIndex - 1].data.Row;
                const prevItems = array.filter(t => t.data.Row === prevRow);
                const moveIndex = array.indexOf(prevItems[0]);

                array.splice(firstIndex, items.length);
                array.splice(moveIndex, 0, ...items);
            }
        }

        this.formFileFields = [...array];
    }

    moveFormFileFieldsDown(row: string) {
        const array = [...this.formFileFields];
        const items = array.filter(t => t.data.Row === row);

        if (items.length) {
            const firstIndex = array.indexOf(items[0]);
            const lastIndex = array.indexOf(items[items.length - 1]);

            if (lastIndex < array.length - 1) {
                const nextRow = array[lastIndex + 1].data.Row;
                const nextItems = array.filter(t => t.data.Row === nextRow);
                const moveIndex = array.indexOf(nextItems[nextItems.length - 1]);

                array.splice(firstIndex, items.length);
                array.splice(moveIndex, 0, ...items);
            }
        }

        this.formFileFields = [...array];
    }

    addDataSource() {
        const item = new ApplicationDataSource();

        this.model.DataSources.push(item);
        this.editGridRow(item, 'dataSource');
    }

    removeDataSource(item: ApplicationDataSource) {
        const index = this.model.DataSources.indexOf(item);

        if (index > -1) {
            this.model.DataSources.splice(index, 1);

            this.model.FormFields.forEach(t => {
                if ((t.Source || '').startsWith(`${item.Name}.`)) {
                    t.Source = undefined;
                }
            });

            delete this.dataSourceCheck[item.Name];
        }

        const ei = this.rowEditing.dataSource.findIndex(t => t.row == item);

        if (ei > -1) {
            this.rowEditing.dataSource.splice(ei, 1);
        }
    }

    testDataSource(item: ApplicationDataSource) {
        const check: IDataSourceCheck = this.dataSourceCheck[item.Name] = {
            columns: []
        };

        this.app.addLoading(this.service.testDataSource(item.Database, item.Procedure)).subscribe(rows => {
            check.error = !rows || !rows.length;

            if (!rows) {
                this.app.alert.error(this.app.translate('applicationConfig_dataSourceError').replace('{name}', item.Name));
            } else if (rows.length) {
                check.columns = Object.keys(rows[0]);

                let table = '<table class="table"><thead><tr>';

                for (let h in rows[0])
                    table += `<th style="text-transform: none">${h}</th>`;

                table += '</tr></thead><tbody>';

                for (let i = 0; i < rows.length; i++) {
                    const r = rows[i];

                    table += '<tr>';

                    for (let d in r)
                        table += `<td>${r[d]}</td>`;

                    table += '</tr>';
                }

                table += '</tbody></table>';

                this.app.openDialog({
                    content: table,
                    title: this.app.translate('applicationConfig_dataSourceResult').replace('{name}', item.Name)
                }, 'lg');
            } else {
                this.app.alert.warning(this.app.translate('applicationConfig_dataSourceEmpty').replace('{name}', item.Name));
            }
        }, err => {
            check.error = false;
            this.app.alert.error(this.app.translate('applicationConfig_dataSourceError').replace('{name}', item.Name));
        });
    }

    addFormField() {
        const item = {
            data: new ApplicationFormField(),
            id: ++this.currentUniqueRowIndex
        };

        this.formFields.push(item);
        this.editGridRow(item, 'formField');
    }

    removeFormField(item: IRow<ApplicationFormField>) {
        const index = this.formFields.indexOf(item);

        if (index > -1) {
            this.formFields.splice(index, 1);
        }

        const ei = this.rowEditing.formField.findIndex(t => t.row == item);

        if (ei > -1) {
            this.rowEditing.formField.splice(ei, 1);
        }
    }


    addFormFileField() {
        const item = {
            data: new ApplicationFormFileField(),
            id: ++this.currentUniqueRowIndex
        };

        this.formFileFields.push(item);
        this.editGridRow(item, 'formFileField');
    }

    removeFormFileField(item: IRow<ApplicationFormFileField>) {
        const index = this.formFileFields.indexOf(item);

        if (index > -1) {
            this.formFileFields.splice(index, 1);
        }

        const ei = this.rowEditing.formFileField.findIndex(t => t.row == item);

        if (ei > -1) {
            this.rowEditing.formFileField.splice(ei, 1);
        }
    }

    validateDataSource(item: ApplicationDataSource) {
        return this.getDataSourceErrors(item).length === 0;
    }

    getDataSourceErrors(item: ApplicationDataSource): string[] {
        const errors = [];

        if (!(item.Name && item.Database && item.Procedure && item.ResultType))
            errors.push(this.app.translate('applicationConfig_requiredFieldsError'));

        if (item.Name && this.model.DataSources.filter(t => (t.Name || '').toLowerCase() === item.Name.toLowerCase()).length > 1)
            errors.push(this.app.translate('applicationConfig_dataSourceNameTaken').replace('{name}', item.Name));

        return errors;
    }

    getDataSourceInvalidError(item: ApplicationDataSource): string {
        return this.app.translate('applicationConfig_dataSourceError').replace('{name}', item.Name);
    }

    validateFormField(item: ApplicationFormField) {
        return this.getFormFieldErrors(item).length === 0;
    }

    getFormFieldErrors(item: ApplicationFormField): string[] {
        const errors = [];

        if (!(item.Name && item.Row && item.Size && item.NameLV && item.NameEN && item.Type))
            errors.push(this.app.translate('applicationConfig_requiredFieldsError'));

        if (item.Name && this.model.FormFields.filter(t => (t.Name || '').toLowerCase() === item.Name.toLowerCase()).length > 1)
            errors.push(this.app.translate('applicationConfig_formFieldNameTaken').replace('{name}', item.Name));

        if (!this.validateFormFieldSource(item.Source))
            errors.push(this.app.translate('applicationConfig_formFieldSourceError').replace('{name}', item.Source));

        return errors;
    }

    validateFormFieldSource(source: string) {
        if (!source) return true;

        const ds = source.split('.')[0];

        if (!this.model.DataSources.filter(t => !this.isGridRowEditing(t, 'dataSource')).some(t => t.Name === ds))
            return false;

        if (!/^\w+\.\w+(:\w+)?$/.test(source))
            return false;

        const dsCheck = this.dataSourceCheck[ds];

        if (dsCheck) {
            if (dsCheck.error)
                return false;

            const fields = source.split('.').pop().split(':');
            const internal = fields[0];
            const display = fields[1] || internal;

            if (dsCheck.columns.indexOf(internal) === -1 || dsCheck.columns.indexOf(display) === -1)
                return false;
        }

        return true;
    }

    validateFormFileField(item: ApplicationFormFileField) {
        return this.getFormFileFieldErrors(item).length === 0;
    }


    getFormFileFieldErrors(item: ApplicationFormFileField): string[] {
        const errors = [];

        if (!(item.Name && item.NameLV && item.NameEN && item.Extensions && (item.MaxSize && item.MaxSize != 0) && (item.MaxFileCount && item.MaxFileCount != 0)))
            errors.push(this.app.translate('applicationConfig_requiredFieldsError'));

        if (item.Name && this.model.FormFileFields.filter(t => (t.Name || '').toLowerCase() === item.Name.toLowerCase()).length > 1)
            errors.push(this.app.translate('applicationConfig_formFieldNameTaken').replace('{name}', item.Name));

        return errors;
    }

    async save() {
        await this.saveInternal(id => {
            this.dataSaved = true;
            this.app.alert.success(this.app.translate('applicationConfig_saved'));
            this.app.navigate(['/applications/config', id]);
        });
    }

    export() {
        const data = JSON.stringify({
            DataSources: this.model.DataSources,
            //FormFields: this.model.FormFields,
            FormFields: this.formFields.map(t => t.data),
            FormFileFields: this.formFileFields.map(t => t.data),
            GroupId: this.model.GroupId,
            NameEN: this.model.NameEN,
            NameLV: this.model.NameLV,
            OrderProcess: this.model.OrderProcess
        });
        const a = document.createElement('a');
        a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`;
        a.download = `application-config.${this.model.Code}.json`;
        a.click();
    }

    import(file: File) {
        const onError = (err) => {
            console.error(err);
            this.app.showError(this.app.translate('applicationConfig_importFailed'));
        };

        const reader = new FileReader();
        reader.onload = e => {
            try {
                const json = JSON.parse(<string>e.target.result) as ApplicationConfig;

                this.dataSourceCheck = {};
                this.clearEditing();

                this.model.Code = json.Code;
                this.model.DataSources = json.DataSources;

                this.formFields = json.FormFields.map(t => {
                    return {
                        data: t,
                        id: ++this.currentUniqueRowIndex
                    };
                });

                this.formFileFields = json.FormFileFields.map(t => {
                    return {
                        data: t,
                        id: ++this.currentUniqueRowIndex
                    };
                });

                this.model.GroupId = json.GroupId;
                this.model.NameEN = json.NameEN;
                this.model.NameLV = json.NameLV;
                this.model.OrderProcess = json.OrderProcess;

                this.app.alert.info(this.app.translate('applicationConfig_importSucceeded'));

                this.checkDataSources().subscribe(() => {
                    this.model.FormFields.forEach(t => {
                        if (t.Source) {
                            this.validateFormFieldSource(t.Source);
                        }
                    });
                });
            } catch (err) {
                onError(err);
            }
        };
        reader.onerror = e => onError(e);
        reader.readAsText(file);
    }

    async publish() {
        await this.saveInternal(id => {
            this.app.addLoading(this.service.publish(id)).subscribe(() => {
                this.app.navigate(['/applications/config', id]);
                this.app.alert.success(this.app.translate('applicationConfig_publishSucceeded'));
            }, this.handleHttpError);
        });
    }

    unpublish() {
        this.app.confirm({
            text: this.app.translate('applicationConfig_confirmUnpublish')
        }, result => {
            if (!result) return;

            this.app.addLoading(this.service.unpublish(this.item.Id)).subscribe(() => {
                this.item.Status = 'Draft';
                this.app.alert.success(this.app.translate('applicationConfig_unpublishSucceeded'));
            }, this.handleHttpError);
        });
    }

    activate() {
        this.app.addLoading(this.service.activate(this.item.Id)).subscribe(() => {
            this.item.Status = 'Published';
            this.app.alert.success(this.app.translate('applicationConfig_activateSucceeded'));
        }, this.handleHttpError);
    }

    deactivate() {
        this.app.addLoading(this.service.deactivate(this.item.Id)).subscribe(() => {
            this.item.Status = 'Deactivated';
            this.app.alert.success(this.app.translate('applicationConfig_deactivateSucceeded'));
        }, this.handleHttpError);
    }

    getTemplateTags(): { tag: string, source: string }[] {
        const dbSource = this.app.translate('applicationConfig_tagSourceDb');
        const tagName = (prop: string) => `{{${prop}}}`;

        const arr: { tag: string, source: string }[] = [...this.baseTemplateTags.map(t => {
            return {
                tag: tagName(t),
                source: dbSource
            };
        })];

        if (this.formFields) {
            const source = this.app.translate('applicationConfig_formFields');

            this.formFields.forEach(t => {
                arr.push({
                    tag: tagName(t.data.Name),
                    source
                });
            });
        }

        if (this.model.DataSources) {
            this.model.DataSources.forEach(t => {
                const ds = t.Name;

                if (this.dataSourceCheck[ds]) {
                    this.dataSourceCheck[ds].columns.forEach(t => {
                        arr.push({
                            tag: tagName(`${ds}_${t}`),
                            source: ds
                        });
                    });
                }
            });
        }

        return arr;
    }

    private clearEditing() {
        this.rowEditing.dataSource = [];
        this.rowEditing.formField = [];
    }

    private async fileToBase64(file: File) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                const res = <string>reader.result;
                resolve(res.substring(res.lastIndexOf(',') + 1));
            };
            reader.onerror = e => {
                this.app.showError(this.app.translate('applicationConfig_fileReadFailed').replace('{filename}', file.name));
                reject(e);
            };
        });
    }

    private async saveInternal(callback: (id: number) => void) {
        if (!this.form.valid || this.hasUnsavedGridRows) {
            this.submitted = true;
            this.app.alert.warning(this.app.translate('invalidFormWarning'));
            return;
        }

        const getBase64 = async (template: IFieldFile, currentFile?: string) => {
            if (template.file?.size) {
                // new file added
                return { Base64: await this.fileToBase64(template.file) };
            } else if (currentFile && !template.file?.name) {
                // file removed
                return { Base64: null };
            } else {
                // file not touched
                return null;
            }
        };

        let currentTemplateId: number;
        let currentTemplateLV: string;
        let currentTemplateEN: string;

        if (this.item.Template) {
            currentTemplateId = this.item.Template.Id;
            currentTemplateLV = this.item.Template.FileNameLV;
            currentTemplateEN = this.item.Template.FileNameEN;
        }

        let currentOrderTemplateId: number;
        let currentOrderTemplateLV: string;
        let currentOrderTemplateEN: string;

        if (this.item.OrderTemplate) {
            currentOrderTemplateId = this.item.OrderTemplate.Id;
            currentOrderTemplateLV = this.item.OrderTemplate.FileNameLV;
            currentOrderTemplateEN = this.item.OrderTemplate.FileNameEN;
        }

        this.model.Template = {
            Id: currentTemplateId,
            EN: await getBase64(this.templateEN, currentTemplateEN),
            LV: await getBase64(this.templateLV, currentTemplateLV)
        };

        this.model.OrderTemplate = {
            Id: currentOrderTemplateId,
            EN: await getBase64(this.orderTemplateEN, currentOrderTemplateEN),
            LV: await getBase64(this.orderTemplateLV, currentOrderTemplateLV)
        };

        this.model.FormFields = this.formFields.map(t => t.data);
        this.model.FormFileFields = this.formFileFields.map(t => t.data);

        this.app.addLoading(this.service.save(this.model)).subscribe(id => {
            callback(id);
        }, this.handleHttpError);
    }

    private checkDataSource(ds: ApplicationDataSource) {
        const subj = new Subject<boolean>();

        const check: IDataSourceCheck = this.dataSourceCheck[ds.Name] = {
            columns: []
        };

        this.app.addLoading(this.service.testDataSource(ds.Database, ds.Procedure)).subscribe(rows => {
            check.error = !rows || !rows.length;

            if (!check.error) {
                check.columns = Object.keys(rows[0]);
            }

            subj.next(!check.error);
        }, err => {
            check.error = true;
            subj.next(false);
        });

        return subj.asObservable();
    }

    private checkDataSources(): Observable<boolean[]> {
        const reqs: Observable<boolean>[] = this.model.DataSources.map(t => this.checkDataSource(t));
        return forkJoin(...reqs);
    }

    private hasChanges(): boolean {
        if (!this.origModel) return false;

        const m = this.model;
        const o = this.origModel;

        const currentTemplate = this.item.Template;
        const currentOrderTemplate = this.item.OrderTemplate;

        return m.Code !== o.Code
            || m.GroupId !== o.GroupId
            || m.NameEN !== o.NameEN
            || m.NameLV !== o.NameLV
            || m.OrderProcess !== o.OrderProcess
            || !!(this.templateEN.file?.size || (currentTemplate && currentTemplate.FileNameEN && !this.templateEN.fileName))
            || !!(this.templateLV.file?.size || (currentTemplate && currentTemplate.FileNameLV && !this.templateLV.fileName))
            || !!(this.orderTemplateEN.file?.size || (currentOrderTemplate && currentOrderTemplate.FileNameEN && !this.orderTemplateEN.fileName))
            || !!(this.orderTemplateLV.file?.size || (currentOrderTemplate && currentOrderTemplate.FileNameLV && !this.orderTemplateLV.fileName))
            || JSON.stringify(this.formFields.map(t => t.data)) !== JSON.stringify(o.FormFields)
            || JSON.stringify(m.DataSources) !== JSON.stringify(o.DataSources)
    }

    private readonly handleHttpError = (err: any) => {
        let handled = false;

        if (err.json) {
            const text: string = err.json();
            const parts = text.split(':')

            if (parts.length === 2 && parts[0] === 'applicationConfig_codeTaken') {
                handled = true;
                this.app.showError(this.app.translate(parts[0]).replace('{code}', parts[1]));
                return;
            }

            if (parts.length === 2 && parts[0] === 'applicationConfig_dataSourceError') {
                handled = true;
                this.app.showError(this.app.translate(parts[0]).replace('{name}', parts[1]));
                return;
            }

            if (parts.length === 2 && parts[0] === 'applicationConfig_formFieldSourceError') {
                handled = true;
                this.app.showError(this.app.translate(parts[0]).replace('{name}', parts[1]));
                return;
            }
        }

        if (!handled) {
            throw err;
        }
    }
}
