import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';

import { AppService } from '../../services/app.service';
import { IEbusSessionConfig, IEbusSessionConfigSaveModel } from '../../models/Ebus';
import { EbusService } from '../../services/ebus.service';

@Component({
    selector: 'app-ebus-session-config-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class EbusSessionConfigFormComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        private app: AppService,
        private service: EbusService,
        private route: ActivatedRoute
    ) { }

    item: IEbusSessionConfig;
    submitted: boolean;

    readonly model: IEbusSessionConfigSaveModel = {
        DefaultTimeInterval: 6,
        Parameters: '{}',
        Description: ''
    };

    readonly sessionParams = {
        PageSize: 50,
        TableFormat: '{}',
        DetailsFormat: '{}',
        ReturnMessageFormat: '',
        ShowEventFilter: false,
        ShowTagFilter: false,
        ShowContextFilter: false,
        TagFilterValues: '',
        ContextFilterValues: '',
        DefaultContexts: '[]',
        DefaultTagFilterValues: '[]',
        NoEventsMessage: ''
    };

    readonly defaultContextsHint = '[{"Name": "...","Id": "..."}]';
    readonly defaultTagFilterValuesHint = 'task:completed,information,...';
    readonly tableFormatHint = '{"Columns":[{"Title":...,"Values":["...", "...", ...]}';
    readonly detailsFormatHint = '{"Rows":[{"Title":...,"Values":["...", "...", ...]}';

    @ViewChild('form', { static: true }) private form: NgForm;

    private origModel: IEbusSessionConfigSaveModel;
    private unsavedConfirmation?: boolean;
    private dataSaved: boolean;

    ngOnInit() {
        this.route.params.subscribe(para => {
            const id = para['id'];

            this.app.addLoading(this.service.getSessionConfig(+id)).subscribe(data => {
                this.item = data;

                this.model.DefaultTimeInterval = data.DefaultTimeInterval;
                this.model.Description = data.Description;
                this.model.Parameters = data.Parameters;

                const session = JSON.parse(data.Parameters || '{}');

                for (const k in this.sessionParams) {
                    this.sessionParams[k] = session[k];
                }

                this.sessionParams.ContextFilterValues = (session.ContextFilterValues || []).join(',');
                this.sessionParams.TagFilterValues = (session.TagFilterValues || []).join(',');
                this.sessionParams.DefaultContexts = JSON.stringify(session.DefaultContexts || []);
                this.sessionParams.DefaultTagFilterValues = (session.DefaultTagFilterValues || []).join(',');
                this.sessionParams.TableFormat = JSON.stringify(session.TableFormat || { Columns: [] });
                this.sessionParams.DetailsFormat = JSON.stringify(session.DetailsFormat || { Rows: [] });

                this.origModel = JSON.parse(JSON.stringify(this.model));
            });
        });
    }

    canDeactivate(): Observable<boolean> {
        if (!this.dataSaved) {
            if (this.unsavedConfirmation !== undefined) {
                return of(this.unsavedConfirmation);
            } else if (this.hasChanges()) {
                const subj = new Subject<boolean>();

                this.app.confirm(this.app.translate('ebusSessionConfig_confirmUnsaved'), result => {
                    // for some reason, canDeactivate fires twice if there are changes
                    // store user confirmation result for a short period of time to prevent duplicate popups
                    this.unsavedConfirmation = result;
                    setTimeout(() => {
                        this.unsavedConfirmation = undefined;
                    }, 100);

                    subj.next(result);
                });

                return subj.asObservable();
            }
        }

        return of(true);
    }

    save() {
        let jsonErr: any;

        try {
            this.model.Parameters = this.sessionParamsToJson();
        } catch (err) {
            jsonErr = err;
        }

        if (!this.form.valid || jsonErr) {
            this.submitted = true;
            this.app.alert.warning(this.app.translate('invalidFormWarning'));
            return;
        }

        this.app.addLoading(this.service.updateSessionConfig(this.item.Id, this.model)).subscribe(() => {
            this.dataSaved = true;
            this.app.alert.success(this.app.translate('ebusSessionConfig_saved'));
            this.app.navigate(['/ebus/session-config']);
        });
    }

    private hasChanges(): boolean {
        if (!this.origModel) return false;

        const m = this.model;
        const o = this.origModel;

        if (m.Description != o.Description || m.DefaultTimeInterval != o.DefaultTimeInterval) return true;

        try {
            m.Parameters = this.sessionParamsToJson();
        } catch (err) {
            return true;
        }

        return m.Parameters !== o.Parameters;
    }

    private sessionParamsToJson() {
        return JSON.stringify({
            ...this.sessionParams,
            TagFilterValues: (this.sessionParams.TagFilterValues || '').split(',').map(t => t.trim()).filter(t => !!t),
            ContextFilterValues: (this.sessionParams.ContextFilterValues || '').split(',').map(t => t.trim()).filter(t => !!t),
            DefaultContexts: JSON.parse(this.sessionParams.DefaultContexts),
            DefaultTagFilterValues: (this.sessionParams.DefaultTagFilterValues || '').split(',').map(t => t.trim()).filter(t => !!t),
            TableFormat: JSON.parse(this.sessionParams.TableFormat),
            DetailsFormat: JSON.parse(this.sessionParams.DetailsFormat),
        });
    }
}
