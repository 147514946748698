<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/course-equalizations', text: 'courseEqualization_listTitle' },
                     {
                        url: '/course-equalizations/' + (isNew ? 'add' : model?.Id),
                        text: isNew ? 'courseEqualization_newTitle' : isReady ? canEdit ? 'courseEqualization_editTitle' : 'courseEqualization_viewTitle' : '...'
                     }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{'courseEqualization_title' | translate}}

            <span *ngIf="model?.Status"
                  class="badge"
                  [class.text-bg-primary]="model.Status == 'Project'"
                  [class.text-bg-success]="model.Status == 'Approved'"
                  [class.text-bg-dark]="model.Status == 'Submitted'"
                  [class.text-bg-secondary]="model.Status == 'Draft'">
                {{'courseEqualization_status' + model.Status | translate}}
            </span>
        </h1>
        <aside *ngIf="canPickStudent && !studentPickerOpened">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'courseEqualization_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="pickStudent($event)"></app-person-picker>

    <app-alert level="danger" *ngIf="isUnavailable && !studentPickerOpened">
        {{'eServiceNotAvailable' | translate}}
    </app-alert>

    <form #form="ngForm" [class.was-validated]="formSubmitted" *ngIf="isReady && !studentPickerOpened">
        <app-alert level="warning" *ngIf="notAvailable">{{'eServicesNotAvailable' | translate}}</app-alert>

        <div class="row" *ngIf="residentInfo.length > 1">
            <div class="col-sm-6 form-field">
                <label class="form-label">{{'courseEqualization_lblChangeSpeciality' | translate}}</label>
                <app-select [(ngModel)]="model.SpecialityId"
                            [options]="residentInfo"
                            [valueProperty]="'SpecialityId'"
                            [displayFn]="specialityDisplayFn"
                            [required]="true"
                            (selectionChange)="onSpecialityChange()"
                            name="specialityId">
                </app-select>
            </div>
        </div>

        <ng-container *ngIf="resident">
            <div class="row">
                <div class="data-value col-sm-4">
                    <label>{{'courseEqualization_lblStudent' | translate}}</label>
                    <div>
                        <div>{{resident.Name}} {{resident.Surname}}</div>
                        <div class="text-sm">{{resident.Email}}</div>
                    </div>
                </div>

                <div class="data-value col-sm-4">
                    <label>{{'courseEqualization_lblStatus' | translate}}</label>
                    <div>
                        <ng-container *ngIf="resident.Status; else noValue">
                            {{resident.Status}}
                        </ng-container>
                    </div>
                </div>

                <div class="data-value col-sm-4">
                    <label>{{'courseEqualization_lblStudyYear' | translate}}</label>
                    <div>
                        <ng-container *ngIf="resident.StudyYear; else noValue">
                            {{resident.StudyYear}}
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="data-value col-sm-4">
                    <label>{{'courseEqualization_lblSpeciality' | translate}}</label>
                    <div>
                        <ng-container *ngIf="resident.SpecialityName; else noValue">
                            {{resident.SpecialityName}}
                        </ng-container>
                    </div>
                </div>

                <div class="data-value col-sm-4">
                    <label>{{'courseEqualization_lblSupervisor' | translate}}</label>
                    <div>
                        <ng-container *ngIf="supervisorLoaded">
                            <ng-container *ngIf="supervisor; else noValue">
                                {{supervisor.FirstName}} {{supervisor?.LastName}}
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <div class="data-value col-sm-4">
                    <label>{{'courseEqualization_lblContactPerson' | translate}}</label>
                    <div>
                        <ng-container *ngIf="resident.ContactPersonName; else noValue">
                            {{resident.ContactPersonName}}
                        </ng-container>
                    </div>
                </div>
            </div>

            <app-alert *ngIf="model.ApprovalDecision" level="info">
                <label>{{'courseEqualization_lblApprovalDecision' | translate}}</label>
                <div>{{model.ApprovalDecision}}</div>
                <div class="text-sub" *ngIf="model.ApprovalComment">{{model.ApprovalComment}}</div>
            </app-alert>

            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li [ngbNavItem]="'courses'">
                    <a ngbNavLink>
                        {{'courseEqualization_tabCourses' | translate}}
                        <ng-container *ngIf="selectedCourseCount"> ({{selectedCourseCount}})</ng-container>
                    </a>
                    <ng-template ngbNavContent>
                        <section class="block-em" *ngFor="let inner of model.Courses">
                            <header class="block-header">
                                <h5>{{inner.Code}} {{inner.Name}}</h5>
                                <aside *ngIf="canEdit">
                                    <button type="button" (click)="removeCourse(inner)" class="btn btn-outline-primary">
                                        <i class="fi fi-rs-trash"></i>
                                        {{'remove' | translate}}
                                    </button>

                                    <button type="button" (click)="editCourse(inner)" class="btn btn-outline-primary" *ngIf="isPowerUser">
                                        <i class="fi fi-rs-pencil"></i>
                                        {{'courseEqualization_btnEdit' | translate}}
                                    </button>
                                </aside>
                            </header>

                            <div class="row">
                                <div class="col-sm-3 data-value">
                                    <label>{{'courseEqualization_lblEcts' | translate}}</label>
                                    <div>{{inner.Ects | number:'1.0-2':'en-US'}}</div>
                                </div>

                                <div class="col-sm-3 data-value">
                                    <label>{{'courseEqualization_lblWeeks' | translate}}</label>
                                    <div>{{inner.Weeks}}</div>
                                </div>

                                <div class="col-sm-3 data-value">
                                    <label>{{'courseEqualization_lblStudyYear' | translate}}</label>
                                    <div>{{inner.StudyYear}}</div>
                                </div>

                                <div class="col-sm-3 data-value">
                                    <label [title]="'courseEqualization_lblEnrollYear' | translate">{{'courseEqualization_lblEnrollYearShort' | translate}}</label>
                                    <div>
                                        <ng-container *ngIf="inner.EnrollYear; else noValue">
                                            {{inner.EnrollYear}}
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3 data-value">
                                    <label>{{'courseEqualization_lblAcquireEcts' | translate}}</label>
                                    <div>
                                        <ng-container *ngIf="inner.AcquireEcts != undefined; else noResidencyValue">
                                            {{inner.AcquireEcts}}
                                            <ng-container *ngIf="inner.IsFullCredit"> ({{'courseEqualization_fullCredit' | translate}})</ng-container>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 data-value">
                                    <label>{{'courseEqualization_lblAcquireWeeks' | translate}}</label>
                                    <div>
                                        <ng-container *ngIf="inner.AcquireWeeks != undefined; else noResidencyValue">
                                            {{inner.AcquireWeeks}}
                                            <ng-container *ngIf="inner.IsFullCredit"> ({{'courseEqualization_fullCredit' | translate}})</ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            
                            <p>{{'courseEqualization_lblCourseRatings' | translate}}</p>
                            <div class="row">
                                <div class="col-sm-3 data-value">
                                    <label>{{'courseEqualization_lblTheory' | translate}}</label>
                                    <div>
                                        <ng-container *ngIf="inner.TheoryGrade || inner.IsEqualizedWithNoGrade; else noResidencyValue">
                                            {{inner.IsEqualizedWithNoGrade ? ('courseEqualization_equalizedWithNoGrade' | translate) :  inner.TheoryGrade}} 
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 data-value">
                                    <label>{{'courseEqualization_lblPractice' | translate}}</label>
                                    <div>
                                        <ng-container *ngIf="inner.PracticeGrade || inner.IsEqualizedWithNoGrade; else noResidencyValue">
                                            {{inner.IsEqualizedWithNoGrade ? ('courseEqualization_equalizedWithNoGrade' | translate) :  inner.PracticeGrade}}
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3">
                                <p>{{'courseEqualization_lblCoursePreviousResults' | translate}}</p>
                                <app-table [rows]="inner.Courses" [columns]="outerColumns">
                                    <ng-template #rowTemplate let-row>
                                        <tr>
                                            <td>
                                                {{row.Name}}
                                                <div class="text-mute" *ngIf="row.University">{{row.University}}</div>
                                                <div class="text-mute" *ngIf="row.Programme">{{row.Programme}}</div>
                                            </td>
                                            <td>{{row.Weeks}}</td>
                                            <td>{{row.Ects | number:'1.0-2':'en-US'}}</td>
                                            <td>{{row.TheoryGrade}} - {{row.PracticeGrade}}</td>
                                            <td>
                                                <div *ngFor="let a of row.Attachments" class="attachment-row" [title]="a.FileName">
                                                    <a *ngIf="a.Id" href="#" (click)="$event.preventDefault(); downloadAttachment(a)">
                                                        {{getAttachmentNameDisplay(a)}}
                                                    </a>
                                                    <ng-container *ngIf="!a.Id">{{a.FileName}}</ng-container>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="table-actions">
                                                    <button (click)="viewCourse(inner, row)" type="button" class="btn btn-outline btn-icon" *ngIf="!canEdit"
                                                            [ngbTooltip]="'view' | translate">
                                                        <i class="fi fi-rs-angle-right"></i>
                                                    </button>

                                                    <button (click)="editCourse(inner, row)" type="button" class="btn btn-outline btn-icon" *ngIf="canEdit"
                                                            [ngbTooltip]="'edit' | translate">
                                                        <i class="fi fi-rs-pencil"></i>
                                                    </button>

                                                    <button (click)="removeCourse(inner, row)" type="button" class="btn btn-outline btn-icon" *ngIf="canEdit"
                                                            [ngbTooltip]="'delete' | translate">
                                                        <i class="fi fi-rs-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </app-table>

                                <div class="block-actions" *ngIf="canEdit">
                                    <button class="btn btn-outline" type="button" (click)="addCourse(inner)"
                                            [ngbTooltip]="'courseEqualization_hintAddInnerCourse' | translate">
                                        <i class="fi fi-rs-plus"></i>
                                        {{'courseEqualization_btnAddInnerCourse' | translate}}
                                    </button>
                                </div>
                            </div>
                        </section>

                        <div class="form-actions" *ngIf="canEdit">
                            <button class="btn btn-outline" type="button" (click)="addCourse()"
                                    [ngbTooltip]="'courseEqualization_hintAddOuterCourse' | translate">
                                <i class="fi fi-rs-plus"></i>
                                {{'courseEqualization_btnAddOuterCourse' | translate}}
                            </button>
                        </div>
                    </ng-template>
                </li>

                <!--seminar functionality not available yet-->
                <!--<li [ngbNavItem]="'seminars'">
            <a ngbNavLink>
                {{'courseEqualization_tabSeminars' | translate}}
                <ng-container *ngIf="selectedSeminarCount"> ({{selectedSeminarCount}})</ng-container>
            </a>
            <ng-template ngbNavContent>
                <div class="mt-3">
                    <app-table [rows]="seminars" [columns]="seminarColumns" pageSize="100">
                        <ng-template #rowTemplate let-row>
                            <tr>
                                <td>
                                    <app-checkbox name="seminarEqualize_{{row.Id}}" [(ngModel)]="row.selected" [disabled]="!canEdit"></app-checkbox>
                                </td>
                                <td>{{row.Theme}}</td>
                                <td>{{row.Instructor}}</td>
                                <td>{{row.Location}}</td>
                            </tr>
                        </ng-template>
                    </app-table>
                </div>
            </ng-template>
        </li>-->
            </ul>
            <div [ngbNavOutlet]="nav"></div>

            <!--currently not available-->
            <!--<div class="form-field">
        <label class="form-label">{{'courseEqualization_attachments' | translate}}</label>
        <div *ngFor="let a of model.Attachments; let i = index" class="d-flex mb-2">
            <div class="flex-grow-1">
                <app-file [(ngModel)]="a.File"
                          [accept]="attachmentExtensions"
                          [maxSize]="attachmentMaxSize"
                          name="attachment{{i}}"
                          [immediate]="!a.Id && !a.File && !a.FileName"
                          [disabled]="!canEdit"
                          (fileChange)="onFileChange($event, a)"
                          #attachmentInput="ngModel"></app-file>
                <div class="invalid-feedback" *ngIf="attachmentInput.errors?.maxSize">{{attachmentInput.errors?.maxSize}}</div>
                <div class="invalid-feedback" *ngIf="checkDuplicateFileName(a)">{{'courseEqualization_duplicateFileName' | translate}}</div>
            </div>

            <button class="btn btn-outline btn-icon align-self-end ms-3" *ngIf="a.Id"
                    (click)="downloadAttachment(a)" [ngbTooltip]="'download' | translate">
                <i class="fi fi-rs-download"></i>
            </button>
        </div>

        <div *ngIf="canEdit">
            <button class="btn btn-outline" type="button" (click)="addAttachment()">
                <i class="fi fi-rs-plus"></i>
                {{'onCallShift_btnAddFile' | translate}}
            </button>
        </div>
    </div>-->

            <app-alert level="danger" *ngIf="canEdit && totalAttachments > attachmentLimit">
                {{'courseEqualization_maxFileCountExceeded' | translate | formatString:attachmentLimit}}
            </app-alert>
        </ng-container>

        <div class="form-actions">
            <span class="form-actions-group">
                <button class="btn btn-outline-primary" type="button" (click)="submit()" *ngIf="canSubmit">
                    <i class="fi fi-rs-paper-plane"></i>
                    {{'courseEqualization_btnSubmit' | translate}}
                </button>

                <button class="btn btn-outline-primary" type="button" (click)="approve()" *ngIf="canApprove">
                    <i class="fi fi-rs-list-check"></i>
                    {{'courseEqualization_btnApprove' | translate}}
                </button>

                <button class="btn btn-outline-primary" type="button" (click)="createProject()" *ngIf="canCreateProject">
                    <i class="fi fi-rs-shield-check"></i>
                    {{'courseEqualization_btnProject' | translate}}
                </button>
            </span>

            <span class="form-actions-group">
                <button class="btn btn-outline" type="button" [routerLink]="['/course-equalizations'] | localize">
                    {{'courseEqualization_btnClose' | translate}}
                </button>

                <button class="btn btn-primary" type="button" (click)="save()" *ngIf="canEdit">
                    {{'courseEqualization_btnSave' | translate}}
                </button>
            </span>
        </div>
    </form>
</div>

<ng-template #noValue>{{'courseEqualization_undefined' | translate}}</ng-template>
<ng-template #noResidencyValue>
    <ng-container *ngIf="!isPowerUser else noValue">
        {{'courseEqualization_undefinedResidencyValue' | translate}}
    </ng-container>
</ng-template>
