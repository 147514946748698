import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IDoctorCompany, IDoctorCourse, IDoctorCourseStudent, IDoctorRotation, IDoctorSeminar, IDoctorSeminarUpdateModel, IDoctorStudent } from '../models/Doctor';
import { IAcademicYear } from '../models/AcademicYear';
import { IEbusEventSession } from '../models/Ebus';
import { Utils } from '../core/Utils';

/**
 * Doctor service
 */
@Injectable({ providedIn: "root" })
export class DoctorService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/doctors` }

    getCourses(): Observable<IDoctorCourse[]> {
        const url = `${this.apiUrl}/courses`;
        return this.http.get<IDoctorCourse[]>(url);
    }

    getStudents(upn?: string): Observable<IDoctorStudent[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/students`;
        return this.http.get<IDoctorStudent[]>(url, { params });
    }

    getCourseStudentsByYear(courseId: string, academicYearId: string): Observable<IDoctorCourseStudent[]> {
        const url = `${this.apiUrl}/courses/${courseId}/students`;
        return this.http.get<IDoctorCourseStudent[]>(url, { params: { academicYearId } });
    }

    getSeminars(upn?: string): Observable<IDoctorSeminar[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/seminars`;
        return this.http.get<IDoctorSeminar[]>(url, { params });
    }

    getAcademicYears(upn?: string): Observable<IAcademicYear[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/academicYears`;
        return this.http.get<IAcademicYear[]>(url, { params });
    }

    getRotations(upn?: string): Observable<IDoctorRotation[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/rotations`;
        return this.http.get<IDoctorRotation[]>(url, { params });
    }

    getCompanies(filter: string): Observable<IDoctorCompany[]> {
        const url = `${this.apiUrl}/companies`;
        return this.http.get<IDoctorCompany[]>(url, { params: { filter } });
    }

    updateSeminar(seminarId: string, data: IDoctorSeminarUpdateModel, upn?: string): Observable<any> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/seminars/${seminarId}`;
        return this.http.put<any>(url, {
            ...data,
            Date: Utils.getInputDateTimeString(data.Date),
        }, { params });
    }

    /**
     * Initialize task session.
     * @param options
     */
    initTaskSession(upn?: string): Observable<IEbusEventSession> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/taskSession`;
        return this.http.post<IEbusEventSession>(url, { params });
    }
}
