import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { IPlanSummaryLecturer, IPlanSummaryRotation } from '../../../models/PlanSummary';
import { AppService } from '../../../services/app.service';
import { PlanSummaryService } from '../../../services/plan-summary.service';
import { currentRequestParams } from '../plan-summary.component';

@Component({
    selector: 'app-plan-summary-remove-lecturer',
    templateUrl: './plan-summary-remove-lecturer.component.html'
})
export class PlanSummaryRemoveLecturerComponent {
    constructor(
        public app: AppService,
        private service: PlanSummaryService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() set rotation(value: IPlanSummaryRotation) {
        this._rotation = value;

        if (value.Lecturers.length == 1) {
            this.selected = value.Lecturers[0];
        }
    }

    get rotation() {
        return this._rotation;
    }

    selected: IPlanSummaryLecturer;
    isSaving: boolean;

    readonly displayFn = (item: IPlanSummaryLecturer) => item ? `${item.Name} ${item.Surname}` : '';

    private _rotation: IPlanSummaryRotation;

    submit() {
        this.isSaving = true;

        this.app.addLoading(this.service.removeLecturer(this.selected.Id, this.selected.Name, this.selected.Surname, this.rotation.Id, currentRequestParams), 500)
            .pipe(finalize(() => this.isSaving = false)).subscribe(() => {
                this.activeModal.close();
            });
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
