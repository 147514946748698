import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';

import { RSGetStudentResult, RSSubmitHistory } from '../models/RSTickets';

@Injectable({ providedIn: "root" })
export class RSTicketsService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/rstickets` }

    getStudent(userEmail?: string): Observable<RSGetStudentResult> {
        const url = `${this.apiUrl}/student`;
        return this.http.get<RSGetStudentResult>(url, { params: { userEmail } });
    }

    getHistory(userEmail?: string): Observable<RSSubmitHistory[]> {
        const url = `${this.apiUrl}/history`;
        return this.http.get<RSSubmitHistory[]>(url, { params: { userEmail } });
    }

    auditSubmit(id: number, password: string): Observable<any> {
        const url = `${this.apiUrl}/auditSubmit`;
        return this.http.post(url, {
            id,
            password
        });
    } 
}
