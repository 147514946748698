import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { AuthService } from './auth.service';
import { AlertService } from './alert.service';

import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { delay, finalize } from 'rxjs/operators';
import { ConfirmDialogOptions } from '../shared/dialog/confirm-dialog.component';
import { UserInfo } from '../models/UserInfo';

export class ConfirmArguments {
    constructor(
        public dialogOptions: ConfirmDialogOptions,
        public callback: (result: boolean) => any
    ) { }
}

/**
 * App common service
 */
@Injectable({ providedIn: "root" })
export class AppService {
    constructor(
        public alert: AlertService,
        private auth: AuthService,
        private translateService: TranslateService,
        private localizeRouter: LocalizeRouterService,
        private router: Router
    ) { }

    readonly env = environment;

    isError404: boolean;
    returnUrl: string;
    /** This property indicates whether "not allowed" request was received */
    accessDenied: boolean;

    /**
     * Current router URL
     */ 
    get currentUrl(): string {
        return this.router.url;
    }

    /**
     * Current language
     */ 
    get currentLanguage(): string {
        return this.translateService.currentLang;
    }

    /**
     * Current user
     */ 
    get currentUser(): UserInfo | null {
        return this.auth.currentUser;
    }

    private readonly loadings: any[] = [];
    private readonly confirmSubject = new Subject<ConfirmArguments>();
    private readonly notificationSubject = new Subject<string>();
    private readonly documentClickSubject = new Subject<any>();
    private readonly commonDialogSubject = new Subject<{
        content: string,
        title?: string,
        button?: string,
        size: 'sm' | 'lg' | 'xl'
    }>();

    /**
     * Get a translated string.
     * @param key
     * @param interpolateParams
     */
    translate(key: string | Array<string>, interpolateParams?: Object): string | any {
        return this.translateService.instant(key, interpolateParams);
    }

    /**
     * Navigate to a route.
     * @param commands
     * @param extras
     */
    navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(this.localizeRoute(commands) as any[], extras);
    }

    /**
     * Get a localized route.
     * @param path
     */
    localizeRoute(path: string | any[]): string | any[] {
        return this.localizeRouter.translateRoute(path);
    }

    /**
     * Show a loading animation.
     * @param delay
     */
    showLoading(delay: number = 0): number {
        const data = {
            id: Date.now(),
            active: undefined
        };

        this.loadings.push(data);

        setTimeout(() => {
            if (data.active !== false)
                data.active = true;
        }, delay);

        return data.id;
    }

    /**
     * Hide a loading animation.
     * @param id If not provided, all animations will be hidden.
     * @param delay
     */
    hideLoading(id?: number, delay: number = 500) {
        setTimeout(() => {
            if (id) {
                const i = this.loadings.findIndex(t => t.id === id);
                if (i > -1) {
                    this.loadings[i].active = false;
                    this.loadings.splice(i, 1);
                }
            } else {
                this.loadings.forEach(t => this.hideLoading(t.id));
            }
        }, delay);
    }

    /**
     * Remove all loading animations.
     * */
    clearLoading() {
        this.loadings.forEach(t => this.hideLoading(t.id));
    }

    /**
     * Check if there a loading animation in progress.
     */
    isLoading(): boolean {
        return this.loadings.some(t => t.active);
    }

    /**
     * Add a loading animation to an observable.
     * @param request
     * @param delayMs Delay in milliseconds
     */
    addLoading<T>(request: Observable<T>, delayMs?: number) {
        const loading = this.showLoading();

        if (delayMs > 0) {
            request = request.pipe(delay(delayMs));
        }

        return request.pipe(finalize(() => {
            this.hideLoading(loading);
        }));
    }

    /**
     * Show an error dialog.
     * @param error Error message or failed HTTP response
     * @param title Custom dialog title
     */
    showError(error: string | HttpErrorResponse, title?: string) {
        const unknown = this.translate('unexpectedErrorContactAdmin');
        let text = unknown;
        let key: string;

        if (error instanceof HttpErrorResponse) {
            key = error.error;

            if (!key) {
                key = `httpStatus_${error.status}`;
            }

            try {
                if (key) {
                    text = this.translate(key);
                }
            } catch (ex) {
                text = unknown;
            }
        } else {
            text = error || unknown;
        }

        this.alert.error(text, title || this.translate('appErrorDialogTitle'));
    }

    /**
     * Get an error message from HTTP response.
     * @param response
     */
    getHttpResponseError(response: any): string {
        if (!response)
            return '';

        if (typeof response === 'string' || typeof response === 'number')
            return this.translate(response + '');

        this.isError404 = response.status === 404;

        let text: string;
        let httpStatus: string;

        if (response.json) {
            try {
                const body = response.json();
                text = body.ExceptionMessage || body.Message || body;
            } catch (e) {
                text = typeof response.error == 'string' ? response.error : response.error?.Message;
            }
        } else {
            text = typeof response.error == 'string' ? response.error : response.error?.Message;
        }

        if (!text)
            text = httpStatus = `httpStatus_${response.status}`;

        try {
            if (text)
                text = this.translate(text);

            if (text === httpStatus)
                text = '';
            else if (!text)
                text = this.translate('unknownError');
        } catch (err) { }

        return text;
    }

    /**
     * Redirect to a route, causing a page reload.
     * @param route
     */
    redirect(route: string) {
        location.assign(this.normalizeRoute(route));
    }

    /**
     * Redirect to the start route, causing a page reload.
     * */
    redirectToStart() {
        this.redirect(environment.startRoute);
    }

    /**
     * Navigate to a route without causing a page reload.
     * @param route
     */
    navigateByUrl(route: string) {
        const [path, query] = this.normalizeRoute(route).split('?');

        if (query) {
            const params = query.split('&').reduce((list, p) => {
                const [k, v] = p.split('=');
                list[k] = v;
                return list;
            }, {});

            this.router.navigate([path], { queryParams: params });
        } else {
            this.router.navigate([path]);
        }
    }

    /**
     * Navigate to the start route without causing a page reload.
     */
    navigateToStart() {
        this.navigateByUrl(environment.startRoute);
    }

    /**
     * Open a confirmation dialog.
     * @param dialogOptions Dialog options or message
     * @param callback A function to execute after closing the dialog.
     * If user agreed to proceed, the result will be true, otherwise - false.
     */
    confirm(dialogOptions: ConfirmDialogOptions | string, callback: (result: boolean) => any) {
        if (typeof dialogOptions === 'string') {
            const opts = new ConfirmDialogOptions();
            opts.text = dialogOptions;
            dialogOptions = opts;
        }

        const args = new ConfirmArguments(dialogOptions, callback);

        this.confirmSubject.next(args);
    }

    getConfirm(): Observable<ConfirmArguments> {
        return this.confirmSubject.asObservable();
    }

    notify(text: string) {
        this.notificationSubject.next(this.translate(text || ''));
    }

    getNotification() {
        return this.notificationSubject.asObservable();
    }

    changeLanguage(language: string) {
        const route = this.router.url.split('/');

        if (!route.length) {
            route.push(language);
        } else {
            route[1] = language;
        }

        // must reload because of pipes and LOCALE_ID
        location.href = route.join('/');
    }

    /**
     * Html document mouse click event
     * @param event Mouse click event
     */
    documentClick(event) {
        this.documentClickSubject.next(event);
    }

    /**
     * Html document mouse click event observable
     */
    onDocumentClick() {
        return this.documentClickSubject.asObservable();
    }

    openDialog(dialog: { content: string, title?: string,  button?: string }, size: 'sm' | 'lg' | 'xl' = 'lg') {
        this.commonDialogSubject.next({ ...dialog, size });
    }

    getDialog() {
        return this.commonDialogSubject.asObservable();
    }

    getAlert() {
        return this.alert.getMessage();
    }

    addPageContainer() {
        document.getElementById('app-container')?.classList.add('container');
    }

    removePageContainer() {
        document.getElementById('app-container')?.classList.remove('container');
    }

    normalizeRoute(route: string): string {
        route = (route || '').replace('//', '/');

        const start = '/' + this.currentLanguage;

        if (!route.startsWith(start) && (!route[start.length] || !['?', '/'].includes(route[start.length]))) {
            route = start + route;
        }

        return route;
    }
}
