<div class="wrapper-lg">
    <app-breadcrumbs [items]="[
                     { url: '/tiles', text: 'tiles_listTitle' },
                     {
                        url: '/tiles/' + (isNew ? 'add' : tile?.Id),
                        text: isNew ? 'tiles_newTitle' : 'tiles_editTitle'
                     }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{(isNew ? 'tiles_newTitle' : 'tiles_editTitle') | translate}}
        </h1>
    </header>

    <form #form="ngForm">
        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'tiles_lblTitle' | translate}}</label>
                <input class="form-control" required name="Title" [(ngModel)]="model.Title" maxlength="50">
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'tiles_lblTitleEn' | translate}}</label>
                <input class="form-control" required name="TitleEn" [(ngModel)]="model.TitleEn" maxlength="50">
            </div>
        </div>

        <div class="row">
            <div class="col form-field">
                <label class="form-label">{{'tiles_lblDescription' | translate}}</label>
                <textarea class="form-control" name="Description" [(ngModel)]="model.Description" rows="3" maxlength="300"></textarea>
            </div>

            <div class="col form-field">
                <label class="form-label">{{'tiles_lblDescriptionEn' | translate}}</label>
                <textarea class="form-control" name="DescriptionEn" [(ngModel)]="model.DescriptionEn" rows="3" maxlength="300"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'tiles_lblSize' | translate}}</label>
                <app-select [(ngModel)]="model.Size"
                            [options]="sizes"
                            [valueProperty]="'value'"
                            [displayProperty]="'label'"
                            [required]="true"
                            (selectionChange)="onSizeChange()"
                            name="Size"></app-select>
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'tiles_lblSortOrder' | translate}}</label>
                <input class="form-control" required name="SortOrder" [(ngModel)]="model.SortOrder" type="number" min="0" step="1">
            </div>
        </div>

        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'tiles_lblStatus' | translate}}</label>
                <app-select [(ngModel)]="model.Status"
                            [options]="statuses"
                            [valueProperty]="'value'"
                            [displayProperty]="'label'"
                            [required]="true"
                            name="Status"></app-select>
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'tiles_lblPermission' | translate}}</label>
                <app-select [(ngModel)]="model.PermissionId"
                            [options]="permissions"
                            [valueProperty]="'Id'"
                            [displayProperty]="'Value'"
                            [required]="true"
                            [filter]="true"
                            [filterFn]="permissionFilterFn"
                            name="Permission">
                    <ng-template #optionTemplate let-option>
                        <div>{{option.Value}}</div>
                        <div class="text-sub">{{option.Code}}</div>
                    </ng-template>
                </app-select>
            </div>
        </div>

        <div class="row">
            <div class="col form-field">
                <label class="form-label">{{'tiles_lblCategory' | translate}}</label>
                <app-select [(ngModel)]="model.CategoryId"
                            [options]="categories"
                            [valueProperty]="'Id'"
                            [displayProperty]="'Value'"
                            name="Category">
                </app-select>
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'tiles_lblUrl' | translate}}</label>
                <input class="form-control" required name="Url" [(ngModel)]="model.Url" maxlength="150">
            </div>
        </div>

        <div class="form-field">
            <label class="form-label">{{'tiles_lblImage' | translate}}</label>
            <app-file name="Image" [(ngModel)]="imageFile" [accept]="imageFormats" [maxSize]="maxImageSize"
                      (fileChange)="onImageChange($event)" #image="ngModel"></app-file>
            <div class="invalid-feedback" *ngIf="image.errors?.maxSize">{{image.errors.maxSize}}</div>
        </div>

        <app-alert level="warning" *ngIf="imageDimensionsWarning">{{imageDimensionsWarning}}</app-alert>

        <div class="row" *ngIf="model.Size">
            <div class="data-value col">
                <label>{{'tiles_previewDesktop' | translate}}</label>
                <div class="tile-preview">
                    <app-tile [item]="getPreviewModel()" [view]="['desktop-tile-always']"></app-tile>
                </div>
            </div>

            <div class="data-value col">
                <label>{{'tiles_previewMobile' | translate}}</label>
                <div class="tile-preview">
                    <app-tile [item]="getPreviewModel()" [view]="['mobile-tile-always']"></app-tile>
                </div>
            </div>
        </div>

        <div class="form-actions">
            <button class="btn btn-outline" type="button" [routerLink]="['/tiles'] | localize">
                {{'tiles_btnCancel' | translate}}
            </button>

            <button class="btn btn-primary" type="button" (click)="save()">
                {{'tiles_btnSave' | translate}}
            </button>
        </div>
    </form>
</div>
