<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/parameters', text: 'parameters_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'parameters_title'"></h1>
    </header>

    <app-table [rows]="items" [columns]="columns" [pageSize]="100" [sticky]="'right'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>{{row.Code}}</td>
                <td>{{row.Value}}</td>
                <td>
                    <div class="table-actions">
                        <button type="button" (click)="edit(row)" class="btn btn-outline btn-icon" [ngbTooltip]="'edit' | translate">
                            <i class="fi fi-rs-pencil"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
