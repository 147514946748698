import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SemesterPlan, MarksItem, Program } from '../models/Marks';
import { environment as ENV } from '../../environments/environment';

/**
 * Marks service
 */
@Injectable({ providedIn: "root" })
export class MarksService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/marks` }

    getStudentPrograms(email?: string): Observable<Program[]> {
        const url = `${this.apiUrl}/studentprograms${email ? `?email=${email}` : ''}`;
        return this.http.get<Program[]>(url);
    }

    getSemesterPlans(studentId: string, email?: string): Observable<SemesterPlan[]> {
        const url = `${this.apiUrl}/semesterplans/${studentId}${email ? `?email=${email}` : ''}`;
        return this.http.get<SemesterPlan[]>(url);
    }

    getMarks(semesterPlanId: string, studentId: string): Observable<MarksItem[]> {
        const url = `${this.apiUrl}/getBySemesterPlan(${semesterPlanId})/${studentId}`;
        return this.http.get<MarksItem[]>(url);
    }
}
