import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Classifier } from '../../models/Classifier';
import { AppService } from '../../services/app.service';
import { ClassifierService } from '../../services/classifier.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClassifierEditComponent } from './classifier-edit.component';
import { clearCache, getTypes } from './classifier-types.component';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
  selector: 'app-classifier-list',
    templateUrl: './classifier-list.component.html'
})
export class ClassifierListComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ClassifierService,
        private route: ActivatedRoute,
        private modal: NgbModal
    ) { }

    items: Classifier[] = [];
    type: string;
    title: string;
    page = 1;
    pageSize = 10;
    sortBy: string;
    sortDir: 'asc' | 'desc';

    readonly columns: ITableColumn[] = [
        { property: 'Code', label: 'classifiers_lblCode', sorts: true },
        { property: 'LegacyCode', label: 'classifiers_lblLegacyCode', sorts: true },
        { property: 'ValueLV', label: 'classifiers_lblValue', sorts: true },
        { property: 'ValueEN', label: 'classifiers_lblValueEN', sorts: true },
        { property: 'Payload', label: 'classifiers_lblPayload' },
        { width: '1px' }
    ];

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.type = params['type'];

            if (this.type) {
                this.init();
            }
        });
    }

    add() {
        const model = new Classifier();
        model.Type = this.type;

        this.edit(model);
    }

    edit(item: Classifier) {
        if (item.Id) {
            item = JSON.parse(JSON.stringify(item));
        }

        const ref = this.modal.open(ClassifierEditComponent);
        ref.componentInstance.model = item;
        ref.result.then(() => {
            this.clearCache();
            this.loadItems();
        }, () => { });
    }

    delete(item: Classifier) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.clearCache();
                this.loadItems();
            });
        });
    }

    private init() {
        const type = getTypes().find(t => t.Code === this.type);

        if (type) {
            this.title = type.Value;
        } else {
            this.app.addLoading(this.service.get('ClassifierType')).subscribe(data => {
                const type = data.find(t => t.Code === this.type);

                if (type) {
                    this.title = type.Value;
                }
            });
        }

        this.loadItems();
    }

    private loadItems() {
        this.app.addLoading(this.service.get(this.type)).subscribe(data => {
            this.items = data;
        });
    }

    private clearCache() {
        if (['ClassifierGroup', 'ClassifierType'].indexOf(this.type) > -1) {
            clearCache();
        }
    }
}
