import { Component, OnInit, ViewChild } from '@angular/core';

import { Log } from '../../models/Log';
import { LogService } from '../../services/log.service';
import { AppService } from '../../services/app.service';

import { ITableColumn } from '../../shared/table/table.component';
import { AppDatePipe } from '../../pipes/date.pipe';
import { NgForm } from '@angular/forms';

const filterLevels = ['INFO', 'DEBUG', 'ERROR', 'WARN', 'FATAL'];
const fitlerRequestMethods = ['GET', 'POST', 'PUT', 'DELETE'];

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: LogService,
        private appDate: AppDatePipe
    ) { }

    readonly now: Date = new Date();

    items: Log[] = [];
    page = 1;

    readonly columns: ITableColumn[] = [
        { width: '1px' },
        { property: 'Date', label: 'log_lblDate', sorts: true, type: 'date' },
        { property: 'Message', label: 'log_lblMessage', sorts: true },
        { property: 'Level', label: 'log_lblLevel', sorts: true },
        { property: 'UserName', label: 'log_lblUserName' },
        { property: 'RequestUrl', label: 'log_lblRequestUrl' }
    ];

    filterLevelOptions = filterLevels;
    filterRequestMethodOptions = fitlerRequestMethods;

    filterDate: Date = this.now;
    filterTimeFrom: string = '00:00';
    filterTimeTo: string = '23:59';
    filterLevel: string[] = [];
    filterRequestMethod: string[] = [];

    readonly filterMaxDate = this.now;

    searchValue: string;
    isFiltering: boolean;

    @ViewChild('filterForm', { static: true }) filterForm: NgForm;

    ngOnInit() {
        this.loadItems();
    }

    filter() {
        this.isFiltering = true;
        this.loadItems();
    }

    toggleFilterLevel(option: string) {
        const ix = this.filterLevel.indexOf(option);

        if (ix > -1) {
            this.filterLevel.splice(ix, 1);
        } else {
            this.filterLevel.push(option);
        }
    }

    toggleRequestMethod(option: string) {
        const ix = this.filterRequestMethod.indexOf(option);

        if (ix > -1) {
            this.filterRequestMethod.splice(ix, 1);
        } else {
            this.filterRequestMethod.push(option);
        }
    }

    showFullMessage(row: Log) {
        const add = (label, value) => {
            return `<div class="data-value mb-3"><label>${this.app.translate(label)}</label><div>${value}</div></div>`;
        };

        this.app.openDialog({
            content: add('log_lblDate', this.appDate.transform(row.Date))
                + add('log_lblMessage', `<div class="text-break">${row.Message}</div>`)
                + add('log_lblLevel', row.Level)
                + add('log_lblLogger', row.Logger)
                + add('log_lblUserName', row.UserName)
                + add('log_lblImpersonator', row.Impersonator || '-')
                + add('log_lblRequestUrl', `<div class="text-break">${row.RequestUrl}</div>`)
                + add('log_lblRequestMethod', row.RequestMethod)
        });
    }

    shouldSplitMessage(item: Log): boolean {
        return item.Message?.length > 50;
    }

    splitMessage(item: Log): string[] {
        const msg = item.Message || '';
        const max = 50;

        if (msg.length > max) {
            const rows = [];

            // max 3 rows
            for (let i = 0; i < 3; i++) {
                const cut = msg.substr(i * max, max);

                if (cut) {
                    rows.push(cut);
                }
            }

            if (rows.length) {
                rows[rows.length - 1] += '...';
            }

            return rows;
        }

        return [msg];
    }

    private loadItems() {
        this.app.addLoading(this.service.get({
            date: this.filterDate,
            timeFrom: this.filterTimeFrom || '00:00',
            timeTo: this.filterTimeTo || '23:59',
            level: this.filterLevel.join(','),
            requestMethod: this.filterRequestMethod.join(','),
            search: this.searchValue || ''
        })).subscribe(data => {
            this.isFiltering = false;
            this.items = data;
            this.page = 1;
        });
    }
}
