<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/student-balance', text: 'studentBalance_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'studentBalance_title' | translate"></h1>
        <aside *ngIf="canPickStudent && !studentPickerOpened && !isDenied">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'studentBalance_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <ng-container *ngIf="!isAction">
        <ng-container *ngIf="isUnavailable">
            <app-alert level="warning">
                <div [innerHTML]="unavailableText | safeHtml"></div>
            </app-alert>
            <app-person-picker *ngIf="studentPickerOpened" (pick)="pickStudent($event)"></app-person-picker>
        </ng-container>

        <ng-container *ngIf="!isUnavailable">
            <div class="btn-group mb-3" role="group" *ngIf="!studentPickerOpened">
                <button type="button" class="btn btn-outline" [class.is-active]="view == 'Contracts'" (click)="switchView('Contracts')">
                    {{'studentBalance_btnShowContracts' | translate}}
                </button>

                <button type="button" class="btn btn-outline" [class.is-active]="view == 'Payments'" (click)="switchView('Payments')">
                    {{'studentBalance_btnShowPaymentHistory' | translate}}
                </button>
            </div>

            <app-alert level="danger" *ngIf="horizonIdError">{{horizonIdError}}</app-alert>
            <app-alert level="danger" *ngIf="isDenied">{{'eServiceNotAvailable' | translate}}</app-alert>

            <app-person-picker *ngIf="studentPickerOpened" (pick)="pickStudent($event)"></app-person-picker>

            <ng-container *ngIf="!studentPickerOpened && isLoaded">
                <ng-container *ngIf="view === 'Contracts'">
                    <div class="data-value" *ngIf="showCustomer && student">
                        <label>{{'studentBalance_student' | translate}}</label>
                        <div>{{student.FirstName}} {{student.LastName}} ({{student.StudentId}})</div>
                    </div>

                    <div class="data-value">
                        <label>
                            {{(data.Balance > 0 ? 'studentBalance_lblOvercharge' : 'studentBalance_lblSumToPay') | translate }}
                            {{'studentBalance_onDatePrefix' | translate}} {{today | appDate}}
                        </label>
                        <div>{{(data.Balance < 0 ? -data.Balance : data.Balance) | number:numberFormat}}</div>
                    </div>

                    <div class="data-value" *ngIf="data.Debt">
                        <label>
                            {{'studentBalance_includingDebt1' | translate}}
                            <span class="sb-debt">{{'studentBalance_includingDebt2' | translate}}</span>
                        </label>
                        <div>
                            {{data.Debt | number:numberFormat}}
                        </div>
                    </div>
                </ng-container>

                <app-alert level="warning" *ngIf="info">{{info}}</app-alert>
                <app-alert level="danger" *ngIf="!data.Contracts.length">{{'studentBalance_noContracts' | translate}}</app-alert>

                <ng-container *ngIf="view === 'Contracts'">
                    <p *ngIf="!actualContractCount">
                        <strong>{{'studentBalance_noActiveContracts' | translate}}</strong>
                    </p>

                    <section class="block" *ngFor="let contract of data.Contracts" [class]="'sb-contract sb-contract-'
                + (isActualContract(contract) ? 'primary' : 'secondary')
                + (contract.Number ? ' sb-contract-spec' : ' sb-contract-other')">
                        <h5>
                            <ng-container *ngIf="contract.Number">{{'studentBalance_contractTitlePrefix' | translate}} {{contract.Number}} {{contract.Subject}}</ng-container>
                            <ng-container *ngIf="!contract.Number">{{'studentBalance_otherInvoices' | translate}}</ng-container>
                        </h5>

                        <div class="row" *ngIf="contract.Number">
                            <div class="col-sm data-value">
                                <label>{{'studentBalance_lblContractType' | translate}}</label>
                                <div>{{contract.TypeName}}</div>
                            </div>

                            <div class="col-sm data-value">
                                <label>{{'studentBalance_lblContractStatus' | translate}}</label>
                                <div>{{'studentBalance_contractStatus' + contract.Status | translate}}</div>
                            </div>
                        </div>

                        <div class="row" *ngIf="contract.Number">
                            <div class="col-sm data-value">
                                <label>{{'studentBalance_lblContractFromDate' | translate}}</label>
                                <div>{{contract.FromDate | appDate}}</div>
                            </div>

                            <div class="col-sm data-value">
                                <label>{{'studentBalance_lblContractToDate' | translate}}</label>
                                <div>{{(contract.ToDate | appDate) || '-'}}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm data-value">
                                <label>{{(contract.Balance > 0 ? 'studentBalance_lblOvercharge' : 'studentBalance_lblSumToPay') | translate}}</label>
                                <div>{{(contract.Balance < 0 ? -contract.Balance : contract.Balance) | number:numberFormat}}</div>
                            </div>

                            <div class="col-sm data-value" *ngIf="contract.Debt > 0">
                                <label>
                                    {{'studentBalance_includingDebt1' | translate}}
                                    <span class="sb-debt">{{'studentBalance_includingDebt2' | translate}}</span>
                                </label>
                                <div>{{contract.Debt | number:numberFormat}}</div>
                            </div>
                        </div>

                        <div class="mb-3 text-end" *ngIf="contract.Balance < 0 && !contract._paid">
                            <a [routerLink]="['/student-balance/pay'] | localize"
                               [queryParams]="{ contractId: contract.Id, customerId: customerId, customerIdType: customerIdType, customerEmail: customerEmail }"
                               href="#"
                               target="_blank"
                               class="btn btn-primary btn-icon-last">
                                {{'studentBalance_btnPay' | translate}}
                                <i class="fi fi-rs-angle-right"></i>
                            </a>
                        </div>

                        <app-table [rows]="getContractInvoices(contract)" [columns]="invoiceColumns">
                            <ng-template #rowTemplate let-row>
                                <tr>
                                    <td class="hidden-md-up">
                                        <a href="#" (click)="$event.preventDefault(); openInvoice(row);">{{row.Number}}</a>
                                        <div>{{getInvoiceStatus(row)}}</div>
                                    </td>
                                    <td class="hidden-sm-down">
                                        <a href="#" (click)="$event.preventDefault(); openInvoice(row);">{{row.Number}}</a>
                                    </td>
                                    <td class="hidden-md-down">{{row.Date | appDate}}</td>
                                    <td class="hidden-md-down">{{row.DueDate | appDate}}</td>
                                    <td class="hidden-md-down">{{row.Amount | number:numberFormat}}</td>
                                    <td class="hidden-md-down">{{(row.Status === 0 ? null : value) | appDate}}</td>
                                    <td class="hidden-sm-down">{{getInvoiceStatus(row)}}</td>
                                    <td>
                                        <div class="table-actions">
                                            <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'studentBalance_showDetails' | translate">
                                                <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isExpanded(row)" class="table-row-details">
                                    <td colspan="9">
                                        <div class="dl">
                                            <div class="hidden-lg-up">
                                                <span [translate]="'studentBalance_lblInvoiceDate'"></span>
                                                <span>{{row.Date | appDate}}</span>
                                            </div>
                                            <div class="hidden-lg-up">
                                                <span [translate]="'studentBalance_lblInvoiceDueDate'"></span>
                                                <span>{{row.DueDate | appDate}}</span>
                                            </div>
                                            <div>
                                                <span [translate]="'studentBalance_lblInvoiceReason'"></span>
                                                <span>{{row.Reason}}</span>
                                            </div>
                                            <div class="hidden-lg-up">
                                                <span [translate]="'studentBalance_lblInvoiceAmount'"></span>
                                                <span>{{row.Amount | number:numberFormat}}</span>
                                            </div>
                                            <div class="hidden-lg-up">
                                                <span [translate]="'studentBalance_lblPaymentDate'"></span>
                                                <span>{{row.PaymentDate | appDate}}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </app-table>

                        <p class="sb-saldo" *ngIf="contract.Saldo">
                            {{(contract.saldo.Amount < 0 ? 'studentBalance_saldoWarnNegative' : 'studentBalance_saldoWarnPositive') | translate | formatString:[contract.saldo.Amount | number:numberFormat]}}
                        </p>
                    </section>

                    <p *ngIf="hasHiddenContracts">
                        <a href="#" (click)="$event.preventDefault(); showOtherContracts();">
                            <span class="sb-other-contracts-in">{{'studentBalance_btnShowOtherContracts' | translate}}</span>
                            <span class="sb-other-contracts-out">{{'studentBalance_btnShowActualContracts' | translate}}</span>
                        </a>
                    </p>
                </ng-container>

                <app-student-balance-payments *ngIf="view === 'Payments'"
                                              [payments]="data.Payments"
                                              [rowsPerPage]="paymentsRowsPerPage"></app-student-balance-payments>

                <app-alert level="warning" *ngIf="info">{{info}}</app-alert>
            </ng-container>
        </ng-container>
    </ng-container>

    <form [action]="payFormUrl" method="post" #payForm>
        <input name="payment_data" type="hidden" [value]="payData">
    </form>
</div>
