<div class="wrapper-lg">
    <app-breadcrumbs [items]="[
                 { url: '/templates', text: 'templates_title' },
                 { url: '/templates/' + (isNew ? 'add' : item.Id), text: isNew ? ('templates_newTemplate' | translate) : title }
                 ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{(isNew ? 'templates_newTemplate' : 'templates_editTemplate') | translate}}</h1>
    </header>

    <form #form="ngForm">
        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'templates_lblCode' | translate}}</label>
                <input class="form-control" [(ngModel)]="item.TemplateCode" name="TemplateCode" required maxlength="128">
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'templates_lblTitle' | translate}}</label>
                <input class="form-control" [(ngModel)]="item.Title" name="Title" required maxlength="256">
            </div>
        </div>

        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'templates_lblType' | translate}}</label>
                <app-select [options]="types"
                            name="type"
                            [required]="true"
                            [displayFn]="typeDisplayFn"
                            [(ngModel)]="item.Type">
                    <ng-template #optionTemplate let-option>
                        {{typeDisplayFn(option)}}
                    </ng-template>
                </app-select>
            </div>

            <div class="col form-field">
                <label class="form-label">{{'templates_lblUseCode' | translate}}</label>
                <input class="form-control" [(ngModel)]="item.UseCode" name="UseCode" maxlength="128">
            </div>
        </div>

        <div class="row">
            <div class="col-3 form-field required">
                <label class="form-label">{{'log_lblDate' | translate}}</label>
                <app-date [(ngModel)]="item.DateFrom" [required]="true" name="dateFrom"></app-date>
            </div>

            <div class="col-3 form-field">
                <label class="form-label">{{'templates_lblDateTo' | translate}}</label>
                <app-date [(ngModel)]="item.DateTo" name="dateTo"></app-date>
            </div>

            <div class="col-6 form-field">
                <label class="form-label">{{'templates_lblUserRights' | translate}}</label>
                <app-select [options]="userRights"
                            name="userRights"
                            [displayProperty]="'Text'"
                            [valueProperty]="'Code'"
                            [(ngModel)]="item.UserRights">
                </app-select>
            </div>
        </div>

        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'templates_lblFileLv' | translate}}</label>
                <app-file name="fileLv"
                          [required]="true"
                          [(ngModel)]="fileLv"
                          [accept]="fileFormats"
                          (fileChange)="onFileChange($event, 'lv')"></app-file>
                <div class="feedback">{{'fileFormatFeedback' | translate | formatString:[fileFormatsDisplay]}}</div>
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'templates_lblFileEn' | translate}}</label>
                <app-file name="fileEn"
                          [required]="true"
                          [(ngModel)]="fileEn"
                          [accept]="fileFormats"
                          (fileChange)="onFileChange($event, 'en')"></app-file>
                <div class="feedback">{{'fileFormatFeedback' | translate | formatString:[fileFormatsDisplay]}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-6 form-field required">
                <label class="form-label">{{'templates_lblFileName' | translate}}</label>
                <input class="form-control" name="FileName" id="FileName" [(ngModel)]="item.FileName" required />
            </div>
        </div>

        <div class="form-actions">
            <button type="button" class="btn btn-outline" [routerLink]="['/templates'] | localize">
                {{'cancel' | translate}}
            </button>

            <button class="btn btn-primary" type="button" (click)="save()">
                {{'templates_Add' | translate}}
            </button>
        </div>
    </form>
</div>
