<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/seminars/' + seminarId + '/attendance', text: 'seminar_attendance_title' }
                     ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{'seminar_attendance_title' | translate}}
        </h1>
    </header>

    <app-seminar-attendance-form [seminarId]="seminarId" (load)="onLoad($event)"></app-seminar-attendance-form>

    <div class="form-actions">
        <ng-container *ngIf="canEdit">
            <button class="btn btn-outline-secondary" type="button" (click)="cancel()">{{'cancel' | translate}}</button>

            <button class="btn btn-primary" (click)="save()" type="button" [disabled]="!hasChanges">
                {{'save' | translate}}
            </button>
        </ng-container>
    </div>
</div>
