<div *ngIf="!item.Url" class="tile-block" [ngbTooltip]="item.Description">
    <div class="tile-image" *ngIf="item.Image">
        <img src="{{item.Image}}" />
    </div>
    <div class="tile-content">{{item.Title}}</div>
</div>

<a *ngIf="item.Url && isInternalUrl(item.Url)" [routerLink]="[item.Url] | localize" class="tile-block" [ngbTooltip]="item.Description">
    <div class="tile-image" *ngIf="item.Image">
        <img src="{{item.Image}}" />
    </div>
    <div class="tile-content">{{item.Title}}</div>
</a>

<a *ngIf="item.Url && isExternalUrl(item.Url)" href="{{item.Url}}" class="tile-block" target="_blank" [ngbTooltip]="item.Description">
    <div class="tile-image" *ngIf="item.Image">
        <img src="{{item.Image}}" />
    </div>
    <div class="tile-content">{{item.Title}}</div>
</a>

<a *ngIf="item.Url && isAnchor(item.Url)" href="{{item.Url}}" class="tile-block" [ngbTooltip]="item.Description"
   (click)="$event.preventDefault(); onAnchor(item)">
    <div class="tile-image" *ngIf="item.Image">
        <img src="{{item.Image}}" />
    </div>
    <div class="tile-content">{{item.Title}}</div>
</a>
