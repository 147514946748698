import { Routes } from '@angular/router';

import { DeactivateGuardService } from './core/CanDeactivateGuard';
import { UserRoleGuard } from './core/UserRoleGuard';
import { HomeComponent } from './features/home/home.component';
import { LoginComponent } from './features/login/login.component';
import { PermissionListComponent } from './features/permissions/permission-list.component';
import { StreamsComponent } from './features/streams/streams.component';
import { BC_coursesComponent } from './features/bc_courses/bc_courses.component';
import { BC_CoursesAddComponent } from './features/bc_courses/bc_courses-add.component';
import { PasswordRequestComponent } from './features/password/request.component';
import { StudentPasswordRequestComponent } from './features/password/student/request.component';
import { StudentPasswordResetComponent } from './features/password/student/reset.component';
import { EmployeePasswordRequestComponent } from './features/password/employee/request.component';
import { EmployeePasswordResetComponent } from './features/password/employee/reset.component';
import { ClassifierListComponent } from './features/classifiers/classifier-list.component';
import { UsersComponent } from './features/users/users.component';
import { LogComponent } from './features/log/log.component';
import { RSTicketsComponent } from './features/rs-tickets/rs-tickets.component';
import { IsicComponent } from './features/isic/isic.component';
import { EmailAliasComponent } from './features/email-alias/email-alias.component';
import { MarksComponent } from './features/marks/marks.component';
import { ImpersonationComponent } from './features/impersonation/impersonation.component';
import { SkillsComponent } from './features/skills/skills.component';
import { StudentBalanceComponent } from './features/student-balance/student-balance.component';
import { FormComponent as ApplicationFormComponent } from './features/application/form.component';
import { MyApplicationsComponent } from './features/application/my-applications.component';
import { PurchaseComponent } from './features/purchase/purchase.component';
import { MyPurchasesComponent } from './features/purchase/my-purchases.component';
import { CertificateRequestsComponent } from './features/certificate-requests/certificate-requests.component';
import { PrintV2Component } from './features/print-v2/print-v2.component';
import { TimeTableComponent } from './features/time-table/time-table.component';
import { FinalPaperComponent } from './features/final-paper/final-paper.component';
import { SaidEventsComponent } from './features/said-events/said-events.component';
import { SaidEventsAddComponent } from './features/said-events/said_events-add.component';
import { IticComponent } from './features/itic/itic.component';
import { ApplicationConfigListComponent } from './features/application-config/list.component';
import { ApplicationConfigFormComponent } from './features/application-config/form.component';
import { ExternalServiceConfigListComponent } from './features/external-service-config/external-service-config-list.component';
import { ExternalServiceConfigFormComponent } from './features/external-service-config/form.component';
import { ExternalServiceComponent } from './features/external-service/external-service.component';
import { OnCallShiftListComponent } from './features/on-call-shift/list.component';
import { OnCallShiftFormComponent } from './features/on-call-shift/form.component';
import { ClassifierTypesComponent } from './features/classifiers/classifier-types.component';
import { ParameterListComponent } from './features/parameters/parameter-list.component';
import { MessageListComponent } from './features/messages/message-list.component';
import { MessageEditComponent } from './features/messages/message-edit.component';
import { TemplateListComponent } from './features/templates/template-list.component';
import { TemplateEditComponent } from './features/templates/template-edit.component';
import { ManipulationListComponent } from './features/manipulations/list.component';
import { ManipulationFormComponent } from './features/manipulations/form.component';
import { TileListComponent } from './features/tiles/tile-list.component';
import { TileEditComponent } from './features/tiles/tile-edit.component';
import { PlanSummaryComponent } from './features/plan-summary/plan-summary.component';
import { AddGradesComponent } from './features/add-grades/add-grades.component';
import { HospitalDashboardComponent } from './features/hospital-dashboard/hospital-dashboard.component';
import { EbusSessionConfigListComponent } from './features/ebus-session-config/list.component';
import { EbusSessionConfigFormComponent } from './features/ebus-session-config/form.component';
import { IndexComponent as PurchaseIndexComponent } from './features/purchase/index.component';
import { IndexComponent as ApplicationIndexComponent } from './features/application/index.component';
import { DoctorDashboardComponent } from './features/doctor-dashboard/doctor-dashboard.component';
import { SeminarAttendanceComponent } from './features/seminar/attendance/seminar-attendance.component';
import { FinalPaperReviewComponent } from './features/final-paper-review/final-paper-review.component';
import { FinalPaperReviewHomeComponent } from './features/final-paper-review/final-paper-review-home.component';
import { CourseEqualizationFormComponent } from './features/course-equalization/form/course-equalization-form.component';
import { CourseEqualizationListComponent } from './features/course-equalization/list/course-equalization-list.component';
import { PortfolioComponent } from './features/portfolio/portfolio.component';
import { PortfolioAddComponent } from './features/portfolio/add/portfolio-add.component';
import { PortfolioViewComponent } from './features/portfolio/view/portfolio-view.component';
import { PortfolioSharedComponent } from './features/portfolio/shared/portfolio-shared.component';
import { SurveyListComponent } from './features/survey/list/survey-list.component';
import { SurveyStartComponent } from './features/survey/form/survey-start.component';
import { SurveyBeginComponent } from './features/survey/form/survey-begin.component';

export const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'login/:type',
		component: LoginComponent
	},
	{
		path: 'login/:type/:confirmKey',
		component: LoginComponent
	},
	{
        path: 'password/request',
        component: PasswordRequestComponent
    },
    {
        path: 'password/reset-student',
        component: StudentPasswordResetComponent
    },
    {
        path: 'password/request-student',
        component: StudentPasswordRequestComponent
    },
    {
        path: 'password/request-employee',
        component: EmployeePasswordRequestComponent
    },
    {
        path: 'password/reset-employee',
        component: EmployeePasswordResetComponent
    },
	{
		path: 'permissions',
        component: PermissionListComponent,
		canActivate: [UserRoleGuard],
		data: { roles: ['Administrator'] }
	},
	{
		path: 'permissions/:role',
        component: PermissionListComponent,
		canActivate: [UserRoleGuard],
		data: { roles: ['Administrator'] }
	},
	{
		path: 'classifiers',
        component: ClassifierTypesComponent,
        canActivate: [UserRoleGuard],
        data: { roles: ['Administrator'] }
	},
	{
		path: 'classifiers/:type',
		component: ClassifierListComponent,
		canActivate: [UserRoleGuard],
		data: { roles: ['Administrator'] }
	},
	{
		path: 'parameters',
		component: ParameterListComponent,
		canActivate: [UserRoleGuard],
		data: { roles: ['Administrator'] }
	},
	{
		path: 'users',
		component: UsersComponent,
		canActivate: [UserRoleGuard],
		data: { roles: ['Administrator'] }
	},
	{
		path: 'log',
		component: LogComponent,
		canActivate: [UserRoleGuard],
        data: { roles: ['Administrator'] }
	},
	{
		path: 'messages',
		component: MessageListComponent,
		canActivate: [UserRoleGuard],
		data: { roles: ['Administrator'], rights: ['UDR.CERT.*'] }
    },
    {
        path: 'messages/add',
        component: MessageEditComponent,
        canActivate: [UserRoleGuard],
        data: { roles: ['Administrator'], rights: ['UDR.CERT.*'] }
    },
	{
		path: 'messages/:id',
		component: MessageEditComponent,
		canActivate: [UserRoleGuard],
        data: { roles: ['Administrator'], rights: ['UDR.CERT.*'] }
	},
	{
		path: 'rs-tickets',
        component: RSTicketsComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['RS_TICKETS.APPLY.VIEW'] }
	},
	{
		path: 'rs-tickets/:type',
        component: RSTicketsComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['RS_TICKETS.APPLY.VIEW'] }
	},
	{
		path: 'isic',
        component: IsicComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['ISIC.APPLY.VIEW'] }
	},
	{
		path: 'itic',
        component: IticComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['ITIC.VIEW'] }
	},
	{
		path: 'email-alias',
        component: EmailAliasComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['EMAIL_ALIAS.VIEW'] }
	},
	{
		path: 'marks',
        component: MarksComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['MARKS.MY.VIEW'] }
	},
	{
		path: 'student-balance',
        component: StudentBalanceComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['STUDENT_BALANCE.VIEW'] }
    },
    {
        path: 'student-balance/:action',
        component: StudentBalanceComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['STUDENT_BALANCE.VIEW'] }
    },
	{
		path: 'print-v2',
		component: PrintV2Component,
		canActivate: [UserRoleGuard],
		data: { rights: ['PRINT_V2.VIEW', 'PRINT_V2.USE'] }
	},
	{
		path: 'print-v2/:action',
		component: PrintV2Component,
		canActivate: [UserRoleGuard],
		data: { rights: ['PRINT_V2.VIEW', 'PRINT_V2.USE'] }
    },
    {
        path: 'applications',
        component: ApplicationIndexComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['APPLICATION.VIEW'] }
    },
	{
		path: 'applications/my',
        component: MyApplicationsComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['APPLICATION.VIEW'] }
    },
    {
        path: 'applications/config',
        component: ApplicationConfigListComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['APPLICATION.CONFIG'] }
    },
    {
        path: 'applications/config/add',
        component: ApplicationConfigFormComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['APPLICATION.CONFIG'] }
    },
    {
        path: 'applications/config/:id',
        component: ApplicationConfigFormComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['APPLICATION.CONFIG'] }
    },
	{
		path: 'application',
        component: ApplicationFormComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['APPLICATION.VIEW'] }
    },
    {
        path: 'external/config',
        component: ExternalServiceConfigListComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['EXTERNAL_SERVICE.CONFIG'] }
    },
    {
        path: 'external/config/add',
        component: ExternalServiceConfigFormComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['EXTERNAL_SERVICE.CONFIG'] }
    },
    {
        path: 'external/config/:id',
        component: ExternalServiceConfigFormComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['EXTERNAL_SERVICE.CONFIG'] }
    },
    {
        path: 'external/:code',
        component: ExternalServiceComponent,
        canActivate: [UserRoleGuard]
    },
	{
		path: 'impersonation',
		component: ImpersonationComponent
	},
	{
		path: 'templates',
		component: TemplateListComponent,
		canActivate: [UserRoleGuard],
        data: { rights: ['ES_MAIN.TEMPLATES.VIEW', 'UDR.CERT.*'] }
	},
	{
		path: 'templates/add',
		component: TemplateEditComponent,
		canActivate: [UserRoleGuard],
        data: { rights: ['ES_MAIN.TEMPLATES.EDIT', 'UDR.CERT.*'] }
	},
	{
		path: 'templates/:id',
        component: TemplateEditComponent,
		canActivate: [UserRoleGuard],
        data: { rights: ['ES_MAIN.TEMPLATES.VIEW', 'UDR.CERT.*'] }
	},
	{
		path: 'streams',
		component: StreamsComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['STREAMS.VIEW'] }
	},
	{
		path: 'streams/:code',
		component: StreamsComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['STREAMS.VIEW'] }
	},
	{
		path: 'bc_courses',
		component: BC_coursesComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['BC_COURSES.VIEW'] }
	},
	{
		path: 'bc_courses/:Student_guid/:Planning_period_GUID',
		component: BC_coursesComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['BC_COURSES.VIEW'] }
	},
	{
		path: 'bc_courses/:Student_guid/:Planning_period_GUID/:studentEmail/:studentFullName',
		component: BC_coursesComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['BC_COURSES.ADMIN'] }
	},
	{
		path: 'bc_courses_add/:Student_guid/:Planning_period_GUID',
		component: BC_CoursesAddComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['BC_COURSES.VIEW'] }
	},
	{
		path: 'bc_courses_add/:Student_guid/:Planning_period_GUID/:studentEmail/:studentFullName',
		component: BC_CoursesAddComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['BC_COURSES.ADMIN'] }
    },
    {
        path: 'purchases',
        component: PurchaseIndexComponent
    },
	{
		path: 'purchases/my',
		component: MyPurchasesComponent
	},
	{
		path: 'purchases/:type',
		component: PurchaseComponent
	},
	{
		path: 'certificate-requests',
		component: CertificateRequestsComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['CERTIFICATE_REQUESTS.VIEW'] }
    },
    {
        path: 'timetable',
        component: TimeTableComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['TIME_TABLE.VIEW'] }
    },
    {
        path: 'final-paper',
        component: FinalPaperComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['FINAL_PAPER.USE'] }
    },
    {
        path: 'final-paper-reviews',
        component: FinalPaperReviewHomeComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['FINAL_PAPER_REVIEW.VIEW'] }
    },
    {
        path: 'final-paper-reviews/:id',
        component: FinalPaperReviewComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['FINAL_PAPER_REVIEW.VIEW'] }
    },
    {
        path: 'skills',
        component: SkillsComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['SKILLS.USE'] }
    },
	{
		path: 'said-events',
		component: SaidEventsComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['SAID_EVENTS.VIEW'] }
	},
	{
		path: 'said-events/:Student_guid/:Planning_period_GUID',
		component: SaidEventsComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['SAID_EVENTS.VIEW'] }
	},
	{
		path: 'said-events/:Student_guid/:Planning_period_GUID/:studentEmail/:studentFullName',
		component: SaidEventsComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['SAID_EVENTS.ADMIN'] }
	},
	{
		path: 'said_events_add/:Student_guid/:Planning_period_GUID',
		component: SaidEventsAddComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['SAID_EVENTS.VIEW'] }
	},
	{
		path: 'said_events_add/:Student_guid/:Planning_period_GUID/:studentEmail/:studentFullName/:filterSupervised',
		component: SaidEventsAddComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['SAID_EVENTS.ADMIN'] }
	},
	{
		path: 'said_events_add/:Student_guid/:Planning_period_GUID/:filterSupervised',
		component: SaidEventsAddComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['SAID_EVENTS.VIEW'] }
	},
	{
		path: 'said_events_add/:Student_guid/:Planning_period_GUID/:studentEmail/:studentFullName',
		component: SaidEventsAddComponent,
		canActivate: [UserRoleGuard],
		data: { rights: ['SAID_EVENTS.ADMIN'] }
    },
    {
        path: 'on-call-shifts',
        component: OnCallShiftListComponent,
        canActivate: [UserRoleGuard],
        data: { rights: ['ON_CALL_SHIFT.VIEW'] }
    },
    {
        path: 'on-call-shifts/add',
        component: OnCallShiftFormComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['ON_CALL_SHIFT.EDIT'] }
    },
    {
        path: 'on-call-shifts/:id',
        component: OnCallShiftFormComponent,
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        data: { rights: ['ON_CALL_SHIFT.VIEW'] }
    },
    {
        path: 'manipulations',
        canActivate: [UserRoleGuard],
        component: ManipulationListComponent,
        data: { rights: ['MANIPULATION.VIEW'] }
    },
    {
        path: 'manipulations/add',
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        component: ManipulationFormComponent,
        data: { rights: ['MANIPULATION.EDIT'] }
    },
    {
        path: 'manipulations/:id',
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        component: ManipulationFormComponent,
        data: { rights: ['MANIPULATION.VIEW'] }
    },
    {
        path: 'tiles',
        canActivate: [UserRoleGuard],
        component: TileListComponent,
        data: { rights: ['ES_MAIN.TEMPLATES.VIEW'] }
    },
    {
        path: 'tiles/add',
        canActivate: [UserRoleGuard],
        component: TileEditComponent,
        data: { rights: ['ES_MAIN.TILES.EDIT'] }
    },
    {
        path: 'tiles/:id',
        canActivate: [UserRoleGuard],
        component: TileEditComponent,
        data: { rights: ['ES_MAIN.TILES.EDIT'] }
    },
    {
        path: 'plan-summary',
        canActivate: [UserRoleGuard],
        component: PlanSummaryComponent,
        data: { rights: ['PLAN_SUMMARY.USE'] }
    },
    {
        path: 'surveys',
        canActivate: [UserRoleGuard],
        component: SurveyListComponent,
        data: { rights: ['SURVEYS.USE'] }
    },
    {
        path: 'surveys/begin/:token',
        component: SurveyBeginComponent
    },
    {
        path: 'surveys/start/:id',
        component: SurveyStartComponent
    },
    {
        path: 'add-grades',
        canActivate: [UserRoleGuard],
        component: AddGradesComponent,
        data: { rights: ['GRADES.ADD'] }
    },
    {
        path: 'hospital-dashboard',
        canActivate: [UserRoleGuard],
        component: HospitalDashboardComponent,
        data: { rights: ['HOSPITAL.VIEW'] }
    },
    {
        path: 'doctor-dashboard',
        canActivate: [UserRoleGuard],
        component: DoctorDashboardComponent,
        data: { rights: ['DOCTOR.VIEW'] }
    },
    {
        path: 'ebus/session-config',
        canActivate: [UserRoleGuard],
        component: EbusSessionConfigListComponent,
        data: { rights: ['EBUS.SESSION_CONFIG'] }
    },
    {
        path: 'ebus/session-config/:id',
        canActivate: [UserRoleGuard],
        canDeactivate: [DeactivateGuardService],
        component: EbusSessionConfigFormComponent,
        data: { rights: ['EBUS.SESSION_CONFIG'] }
    },
    {
        path: 'seminars/:id/attendance',
        canActivate: [UserRoleGuard],
        component: SeminarAttendanceComponent,
        data: { rights: ['SEMINAR.VIEW_ATTENDANCE'] }
    },
    {
        path: 'course-equalizations',
        canActivate: [UserRoleGuard],
        component: CourseEqualizationListComponent,
        data: { rights: ['COURSE_EQUALIZATION.VIEW'] }
    },
    {
        path: 'course-equalizations/add',
        canActivate: [UserRoleGuard],
        component: CourseEqualizationFormComponent,
        data: { rights: ['COURSE_EQUALIZATION.EDIT'] }
    },
    {
        path: 'course-equalizations/:id',
        canActivate: [UserRoleGuard],
        component: CourseEqualizationFormComponent,
        data: { rights: ['COURSE_EQUALIZATION.VIEW'] }
    },
    {
        path: 'portfolio',
        component: PortfolioComponent,
        canActivate: [UserRoleGuard]
    },
    {
        path: 'portfolio/add',
        component: PortfolioAddComponent,
        canActivate: [UserRoleGuard]
    },
    {
        path: 'portfolio/:id',
        component: PortfolioViewComponent,
        canActivate: [UserRoleGuard]
    },
    {
        path: 'portfolio/share/:id',
        component: PortfolioSharedComponent
    }
].map(t => {
    if (t.path) {
        const segments = t.path.split('/');

        segments.forEach((s, i) => {
            // add translation escape to every static segment
            if (s[0] != ':') {
                segments[i] = '!' + s;
            }
        });

        t.path = segments.join('/');
    }

    return t;
});
