<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/password/request', text: 'password_restore' },
                     { url: '/password/reset-student', text: 'password_studentRestore' }
                     ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{(resetResponse ? 'password_restoresuccess' : 'password_studentRestore') | translate}}
        </h1>
    </header>

    <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate autocomplete="off">

        <ng-container *ngIf="resetResponse">
            <app-alert level="success">
                <div [innerHTML]="'password_resetSuccess' | translate:{linkText:linkText}"></div>
            </app-alert>

            <div class="mb-1">
                <a [routerLink]="['/password','request'] | localize" [translate]="'password_getCode'"></a>
            </div>

            <div class="mb-3" [innerHTML]="'password_contactfooter' | translate | safeHtml"></div>
        </ng-container>

        <div class="row" *ngIf="!resetResponse">
            <div class="col-sm-6">
                <div class="form-field">
                    <label class="form-label">{{'password_userNameOrEmail' | translate}}</label>
                    <input class="form-control" type="text" name="userName" [(ngModel)]="userNameValue" #userName="ngModel" required />
                    <div class="invalid-feedback" *ngIf="f.submitted && !userNameValue">{{'password_userNameError' | translate}}</div>
                    <div class="invalid-feedback" *ngIf="f.submitted && !isRsuEmail">{{'password_externalEmailError' | translate}}</div>
                </div>

                <div class="form-field">
                    <label class="form-label">{{'password_securityCode' | translate}}</label>
                    <input class="form-control" type="text" name="securityCode" [(ngModel)]="securityCodeValue" #securityCode="ngModel" required />
                    <div class="invalid-feedback" *ngIf="newPasswordValue && !securityCodeValue">{{'password_securityCodeError' | translate}}</div>
                </div>

                <div class="form-field">
                    <label class="form-label">{{'password_newpassword' | translate}}</label>
                    <div class="fc-icons-end">
                        <input class="form-control"
                               autocomplete="off"
                               [type]="newPasswordVisible ? 'text' : 'password'"
                               name="newPassword"
                               [(ngModel)]="newPasswordValue"
                               #newPassword="ngModel"
                               title="{{'password_requirementsnotmet' | translate}}"
                               required />
                        <button type="button" class="fc-icon-end" (click)="newPasswordVisible = !newPasswordVisible">
                            <i class="fi fi-rs-eye{{newPasswordVisible ? '' : '-crossed'}}"></i>
                        </button>
                    </div>
                    <div class="invalid-feedback" *ngIf="newPasswordValue && !isValidPassword">{{'password_requirementsnotmet' | translate}}</div>
                </div>

                <div class="form-field">
                    <label class="form-label">{{'password_newpasswordagain' | translate}}</label>
                    <div class="fc-icons-end">
                        <input class="form-control"
                               autocomplete="off"
                               [type]="newPasswordRepeatVisible ? 'text' : 'password'"
                               name="newPasswordRepeat"
                               [(ngModel)]="newPasswordRepeatValue"
                               #newPasswordRepeat="ngModel"
                               title="{{'password_requirementsnotmet' | translate}}"
                               [pattern]="newPasswordRepeatValue && newPasswordRepeatValue != newPasswordValue ? '$^' : '.*'"
                               required />
                        <button type="button" class="fc-icon-end" (click)="newPasswordRepeatVisible = !newPasswordRepeatVisible">
                            <i class="fi fi-rs-eye{{newPasswordRepeatVisible ? '' : '-crossed'}}"></i>
                        </button>
                    </div>
                    <div class="invalid-feedback" *ngIf="newPasswordRepeatValue && newPasswordRepeatValue != newPasswordValue">
                        {{'passwordsDontMatch' | translate}}
                    </div>
                </div>

                <div class="recaptcha">
                    <re-captcha (resolved)="resolved($event)" #recaptchaRef required siteKey="{{reCaptchaSiteKey}}"></re-captcha>
                </div>
            </div>

            <div class="form-actions">
                <button class="btn btn-primary" type="submit" [disabled]="!reCaptchaResponse">
                    {{'password_Reset' | translate}}
                </button>
            </div>

            <app-alert level="info">
                <div [innerHTML]="'password_requirements' | translate | safeHtml"></div>
            </app-alert>

            <div class="mb-1">
                <a [routerLink]="['/password','request'] | localize" [translate]="'password_getCode'"></a>
            </div>

            <div class="mb-3">
                <div [innerHTML]="'password_contactfooter' | translate | safeHtml"></div>
            </div>
        </div>
    </form>
</div>
