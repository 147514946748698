<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/applications/config', text: 'applicationConfig_listTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 class="h-md" [translate]="'applicationConfig_listTitle'"></h1>
        <aside>
            <button class="btn btn-outline-primary" type="button" [routerLink]="['/applications/config/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-table [rows]="items" [columns]="columns" [sticky]="'right'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>{{row.Code}}</td>
                <td>
                    <span class="badge"
                          [class.text-bg-success]="row.Status == 'Published'"
                          [class.text-bg-secondary]="row.Status == 'Draft'"
                          [class.text-bg-danger]="row.Status == 'Deactivated'">{{'applicationConfig_status' + row.Status | translate}}</span>
                </td>
                <td>{{row.Version}}</td>
                <td>{{row.Name}}</td>
                <td>
                    <div class="table-actions">
                        <button type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'edit' | translate"
                                [routerLink]="['/applications/config', row.Id] | localize">
                            <i class="fi fi-rs-pencil"></i>
                        </button>

                        <button type="button" (click)="delete(row)" class="btn btn-outline btn-icon" [ngbTooltip]="'delete' | translate"
                                [disabled]="!canDelete(row)">
                            <i class="fi fi-rs-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
