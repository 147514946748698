<div class="row">
    <div class="form-field col-md-5">
        <input name="search" class="form-control" placeholder="{{'planSummary_documents_lblSearch' | translate}}" [(ngModel)]="searchValue" (input)="search()">
    </div>
</div>

<app-table [rows]="documents" [columns]="columns" sortBy="Date" sortDir="asc" sticky="right" *ngIf="isLoaded">
    <ng-template #rowTemplate let-row>
        <tr class="table-row {{ row.Expanded ? 'expanded' : ''}}">
            <td>{{row.Date | appDate}}</td>
            <td>{{row.RegNr}} <span class="text-sub">{{row.TypeName}}</span></td>
            <td>
                <a href="{{row.DvsUrl}}" *ngIf="row.DvsUrl; else noDvsUrl">{{row.Name}}</a>
                <ng-template #noDvsUrl>
                    {{row.Name}}
                </ng-template>
            </td>
            <td>{{row.StartDate | appDate}} - {{ (row.EndDate | appDate)}}</td>
            <td>{{row.StatusName}}</td>
            <td>{{row.Source}}</td>
            <td>
                <div class="table-actions">
                    <button type="button" class="btn btn-outline btn-icon" (click)="getDocumentFiles(row.Id); row.Expanded = !row.Expanded"
                        [ngbTooltip]="'planSummary_documents_dropdown' | translate"
                        [disabled]="row.IsLoading || !row.Id">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="row.IsLoading"></span>
                        <i class="fi fi-rs-document" *ngIf="!row.IsLoading"></i>
                    </button>
                </div>
            </td>
        </tr>
        <tr *ngIf="row.Expanded && !row.IsLoading" class="expandable">
            <td colspan="30">
                <span *ngIf="!row.IsLoading && !row.Files">{{ 'planSummary_documents_noDocuments' | translate }}</span>
                <ul>
                    <li *ngFor="let file of row.Files">
                        <a href="{{getFileDownloadUrl(row.Id, file.Id, file.Name)}}" target="_blank">{{file.Name}}</a>
                    </li>
                </ul>
            </td>
        </tr>
    </ng-template>
</app-table>
