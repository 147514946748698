import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DoctorService } from '../../../services/doctor.service';
import { IDoctorCompany, IDoctorSeminar, IDoctorSeminarUpdateModel } from '../../../models/Doctor';
import { AppService } from '../../../services/app.service';
import { Subject, Subscription } from 'rxjs';
import { store } from '../store';

@Component({
  selector: 'app-doctor-dashboard-seminar-edit',
    templateUrl: './doctor-dashboard-seminar-edit.component.html'
})
export class DoctorDashboardSeminarEditComponent {
    constructor(
        private app: AppService,
        private service: DoctorService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() set data(value: IDoctorSeminar) {
        this.id = value.Id;
        this.model.Topic = value.Theme;

        if (value.DateTime) {
            this.model.Date = value.DateTime;
        } else {
            this.model.Date = undefined;
        }

        if (value.Time) {
            const [hh, mm] = value.Time.split(':');
            this.time = `${hh}:${mm}`;
        } else {
            this.time = undefined;
        }

        if (value.Place) {
            this.app.addLoading(this.service.getCompanies(value.Place)).subscribe(place => {
                if (place.length && place[0].Name.length == value.Place.length) {
                    this.model.CompanyId = place[0].Id;
                    this.companies = place;
                }
            });
        }
    }

    readonly model: IDoctorSeminarUpdateModel = <IDoctorSeminarUpdateModel>{};

    companies: IDoctorCompany[] = [];
    time: string;
    submitted: boolean;
    isSaving: boolean;

    private _companiesSubscription: Subscription;
    private readonly _companiesSubject = new Subject<string>();

    private id: string;

    @ViewChild('form', { static: true }) private form: NgForm;

    ngOnInit() {
        this._companiesSubscription = this._companiesSubject.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value =>
            this.loadCompanies(value?.toString())
        );
    }

    ngOnDestroy() {
        this._companiesSubscription.unsubscribe();
    }

    save() {
        if (this.form.invalid) {
            this.submitted = true;
            return;
        }

        const [hh, mm] = this.time.split(':');

        this.isSaving = true;

        this.model.Date.setHours(+hh);
        this.model.Date.setMinutes(+mm);

        this.app.addLoading(this.service.updateSeminar(this.id, this.model, store.getPerson()?.Upn), 500)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(() => this.activeModal.close());
    }

    cancel() {
        this.activeModal.dismiss();
    }

    filterCompanies(value: string) {
        if (value?.length < 3)
            this.companies = [];
        else {
            this.model.CompanyId = null;
            this._companiesSubject.next(value);
        }
    }

    private loadCompanies(filter: string) {
        this.service.getCompanies(filter).subscribe(data => this.companies = data);
    }
}
