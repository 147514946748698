import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlContainer, NgForm } from '@angular/forms';

let counter = 0;

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true
        }
    ]
})
export class RadioComponent implements ControlValueAccessor {
    constructor() {
        this.id = `radio-${counter++}`;
    }

    @Input() value: any;
    @Input() disabled: boolean;

    @Output() stateChange = new EventEmitter<any>();

    readonly id: string;

    get isChecked(): boolean {
        return this._value === this.value;
    }

    private _onChange = (obj: any) => { };
    private _value: any;

    //ControlValueAccessor
    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: (obj: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //ControlValueAccessor

    onChange() {
        if (this.disabled) return;

        this._value = this.value;

        this.emitChange();
    }

    private emitChange() {
        this._onChange(this._value);
        this.stateChange.emit(this._value);
    }
}
