import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export class ConfirmDialogOptions {
    text: string;
    title?: string;
    okText?: string;
    cancelText?: string;
}

@Component({
    template: `
    <a href="#" style="display: none"></a>
    <div class="modal-icon"><span class="alert-icon alert-icon-warning"><i class="fi fi-rs-exclamation"></i></span></div>
    <div class="modal-header" *ngIf="title">
        <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body">
	    <div [innerHTML]="body | safeHtml"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" *ngIf="cancelText !== ''">
            <ng-container *ngIf="!cancelText">{{'confirmDialog_cancel' | translate}}</ng-container>
            <ng-container *ngIf="cancelText">{{cancelText}}</ng-container>
        </button>
        <button type="button" class="btn btn-primary" (click)="ok()">
            <ng-container *ngIf="!okText">{{'confirmDialog_ok' | translate}}</ng-container>
            <ng-container *ngIf="okText">{{okText}}</ng-container>
        </button>
    </div>
    `
})
export class ConfirmDialogComponent {
    constructor(private modal: NgbActiveModal) { }

    @Input() title: string;
    @Input() body: string;
    @Input() okText: string;
    @Input() cancelText: string;

    ok() {
        this.modal.close(true);
    }

    cancel() {
        this.modal.close(false);
    }
}
