import { Component, Input } from '@angular/core';
import { IPublishedTile } from '../../models/Tile';

@Component({
    selector: 'app-tiles',
    templateUrl: './tiles.component.html',
    host: { 'class': 'tiles' }
})
export class TilesComponent {
    @Input() items: IPublishedTile[] = [];
    @Input() show: 'all' | 'primary' = 'all';

    readonly desktopTileMaxLength = 18;
    readonly mobileTileMaxLength = 12;

    /**
     * Get maximum possible tiles which are not text-type.
     */
    getPrimaryTiles(): IPublishedTile[] {
        const arr = [];
        const max = Math.max(this.desktopTileMaxLength, this.mobileTileMaxLength);

        let length = 0;

        for (let i = 0; i < this.items.length; i++) {
            length += this.items[i].Size;

            if (length <= max) {
                arr.push(this.items[i]);
            } else {
                break;
            }
        }

        return arr;
    }

    /**
     * Get tile index considering its size.
     * @param item
     */
    getTileIndex(item: IPublishedTile): number {
        const ix = this.items.indexOf(item);
        let length = 0;

        for (let i = 0; i < ix; i++) {
            length += this.items[i].Size;
        }

        return length;
    }

    /**
     * Get tile CSS view classes based on its size.
     * @param item
     */
    getTileView(item: IPublishedTile): string[] {
        const length = this.getTileIndex(item) + item.Size;
        const textOrHidden = this.show == 'primary' ? 'tile-hidden' : 'tile-text';

        return [
            `desktop-${length <= this.desktopTileMaxLength ? 'tile' : textOrHidden}`,
            `mobile-${length <= this.mobileTileMaxLength ? 'tile' : textOrHidden}`
        ];
    }
}
