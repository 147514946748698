<div class="wrapper-lg">
    <app-breadcrumbs [items]="[
                 { url: '/applications/config', text: 'applicationConfig_listTitle' },
                 { url: '/applications/config/' + (isNew ? 'add' : item.Id), text: isNew ? 'applicationConfig_newTitle' : title }
                 ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{(isNew ? 'applicationConfig_newTitle' : 'applicationConfig_editTitle') | translate}}</h1>
    </header>

    <form #form="ngForm">
        <section class="block">
            <h5>{{'applicationConfig_baseData' | translate}}</h5>

            <div class="d-flex">
                <div class="data-value">
                    <label>{{'applicationConfig_lblStatus' | translate}}</label>
                    <div>
                        <span *ngIf="item.Status">{{'applicationConfig_status' + item.Status | translate}}</span>
                    </div>
                </div>

                <div class="data-value ms-5">
                    <label>{{'applicationConfig_lblVersion' | translate}}</label>
                    <div>
                        <span *ngIf="item.Version">{{item.Version}}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 form-field required">
                    <label class="form-label">{{'applicationConfig_lblCode' | translate}}</label>
                    <input class="form-control" required name="Code" [(ngModel)]="model.Code" [disabled]="!isDraft" />
                </div>

                <div class="col-3 form-field required">
                    <label class="form-label">{{'applicationConfig_lblGroup' | translate}}</label>
                    <app-select [options]="groupItems"
                                [name]="'GroupId'"
                                [valueProperty]="'Id'"
                                [displayProperty]="'Value'"
                                [(ngModel)]="model.GroupId"
                                name="groupId"
                                [required]="true">
                    </app-select>
                </div>

                <div class="col-3 form-field required">
                    <label class="form-label">{{'applicationConfig_lblOrderProcess' | translate}}</label>
                    <app-select [options]="orderProcessItems"
                                [name]="'OrderProcess'"
                                [(ngModel)]="model.OrderProcess"
                                name="orderProcess"
                                [required]="true">
                    </app-select>
                </div>
            </div>

            <div class="row">
                <div class="col form-field required">
                    <label class="form-label">{{'applicationConfig_lblNameLV' | translate}}</label>
                    <input class="form-control" required name="NameLV" [(ngModel)]="model.NameLV" [disabled]="!isDraft" />
                </div>

                <div class="col form-field required">
                    <label class="form-label">{{'applicationConfig_lblNameEN' | translate}}</label>
                    <input class="form-control" required name="NameEN" [(ngModel)]="model.NameEN" [disabled]="!isDraft" />
                </div>
            </div>
        </section>

        <section class="block">
            <h5>{{'applicationConfig_dataSources' | translate}}</h5>

            <app-table [rows]="model.DataSources" [columns]="dataSourceColumns" [wrap]="false">
                <ng-template #rowTemplate let-row let-i="index">
                    <tr>
                        <td>
                            <span class="d-flex">
                                <i class="fi fi-rs-check text-success" *ngIf="validateDataSource(row) && !dataSourceCheck[row.Name]?.error"></i>
                                <i class="fi fi-rs-exclamation text-danger" *ngIf="!validateDataSource(row) || dataSourceCheck[row.Name]?.error"
                                   [ngbTooltip]="dataSourceCheck[row.Name]?.error ? getDataSourceInvalidError(row) : getDataSourceErrors(row).join('\n')"></i>
                            </span>
                        </td>

                        <td *ngIf="isGridRowEditing(row, 'dataSource')" colspan="4">
                            <div class="row">
                                <div class="col mb-3 required">
                                    <label class="form-label">{{'applicationConfig_dataSource_lblName' | translate}}</label>
                                    <input class="form-control"
                                           autofocus
                                           required
                                           [(ngModel)]="row.Name"
                                           name="dataSourceName{{i}}"
                                           pattern="^[a-zA-Z0-9]+$" />
                                </div>

                                <div class="col mb-3 required">
                                    <label class="form-label">{{'applicationConfig_dataSource_lblDatabase' | translate}}</label>
                                    <app-select [options]="dataSourceDbItems"
                                                [(ngModel)]="row.Database"
                                                [required]="true"
                                                name="dataSourceDb{{i}}">
                                    </app-select>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col mb-3 required">
                                    <label class="form-label">{{'applicationConfig_dataSource_lblProcedure' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.Procedure"
                                           name="dataSourceProcedure{{i}}" />
                                </div>

                                <div class="col mb-3 required">
                                    <label class="form-label">{{'applicationConfig_dataSource_lblResultType' | translate}}</label>
                                    <app-select [options]="dataSourceResultTypeItems"
                                                [(ngModel)]="row.ResultType"
                                                name="dataSourceResultType{{i}}"
                                                [required]="true">
                                    </app-select>
                                </div>
                            </div>
                        </td>

                        <ng-container *ngIf="!isGridRowEditing(row, 'dataSource')">
                            <td>{{row.Name}}</td>
                            <td>{{row.Database}}</td>
                            <td>{{row.Procedure}}</td>
                            <td>{{row.ResultType}}</td>
                        </ng-container>

                        <td>
                            <div class="table-actions">
                                <button type="button" (click)="testDataSource(row)" class="btn btn-outline btn-icon"
                                        *ngIf="!isGridRowEditing(row, 'dataSource')" [ngbTooltip]="'applicationConfig_testDataSource' | translate">
                                    <i class="fi fi-rs-shield-check"></i>
                                </button>

                                <button type="button" (click)="editGridRow(row, 'dataSource')" class="btn btn-outline btn-icon"
                                        *ngIf="!isGridRowEditing(row, 'dataSource')" [ngbTooltip]="'edit' | translate">
                                    <i class="fi fi-rs-pencil"></i>
                                </button>

                                <button type="button" (click)="saveGridRow(row, 'dataSource')" class="btn btn-outline btn-icon"
                                        *ngIf="isGridRowEditing(row, 'dataSource')" [ngbTooltip]="'save' | translate"
                                        [disabled]="!validateDataSource(row)">
                                    <i class="fi fi-rs-disk"></i>
                                </button>

                                <button type="button" (click)="cancelGridRow(row, 'dataSource')" class="btn btn-outline btn-icon"
                                        *ngIf="isGridRowEditing(row, 'dataSource')" [ngbTooltip]="'cancel' | translate">
                                    <i class="fi fi-rs-cross"></i>
                                </button>

                                <button type="button" (click)="removeDataSource(row)" class="btn btn-outline btn-icon" [ngbTooltip]="'delete' | translate">
                                    <i class="fi fi-rs-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </app-table>

            <div class="block-actions">
                <button type="button" class="btn btn-outline-secondary" (click)="addDataSource()">
                    <i class="fi fi-rs-add"></i>
                    {{'add' | translate}}
                </button>
            </div>
        </section>

        <section class="block">
            <h5>{{'applicationConfig_formFields' | translate}}</h5>

            <app-table [rows]="formFields" [columns]="formFieldColumns" [wrap]="false">
                <ng-template #rowTemplate let-row let-i="index">
                    <tr>
                        <td>
                            <span class="d-flex">
                                <i class="fi fi-rs-check text-success" *ngIf="validateFormField(row.data)"></i>
                                <i class="fi fi-rs-exclamation text-danger" *ngIf="!validateFormField(row.data)"
                                   [ngbTooltip]="getFormFieldErrors(row.data).join('\n')"></i>
                            </span>
                        </td>
                        <td colspan="8" *ngIf="isGridRowEditing(row, 'formField')">
                            <div class="row">
                                <div class="col-6 form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblName' | translate}}</label>
                                    <input class="form-control"
                                           autofocus
                                           required
                                           [(ngModel)]="row.data.Name"
                                           name="formFieldName{{row.id}}"
                                           pattern="^[a-zA-Z0-9]+$" />
                                </div>

                                <div class="col-3 form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblRow' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.Row"
                                           name="formFieldRow{{row.id}}"
                                           size="2"
                                           maxlength="2" />
                                </div>

                                <div class="col-3 form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblSize' | translate}}</label>
                                    <app-select [options]="formFieldSizeItems"
                                                [(ngModel)]="row.data.Size"
                                                [name]="'formFieldSize' + row.id"
                                                [required]="true">
                                    </app-select>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblNameLV' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.NameLV"
                                           name="formFieldNameLV{{row.id}}" />
                                </div>

                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblNameEN' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.NameEN"
                                           name="formFieldNameEN{{row.id}}" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblType' | translate}}</label>
                                    <app-select [options]="formFieldTypeItems"
                                                [(ngModel)]="row.data.Type"
                                                [name]="'formFieldType' + row.id"
                                                [required]="true">
                                    </app-select>
                                </div>

                                <div class="col form-field">
                                    <label class="form-label">{{'applicationConfig_formField_lblSource' | translate}}</label>
                                    <input class="form-control"
                                           [(ngModel)]="row.data.Source"
                                           pattern="^[a-zA-Z0-9]+\.[a-zA-Z0-9_\-]+(:[a-zA-Z0-9_\-]+?)$"
                                           name="formFieldSource{{row.id}}"
                                           [title]="'applicationConfig_formField_sourceHint' | translate" />
                                </div>
                            </div>

                            <div class="mb-2">
                                <app-checkbox [(ngModel)]="row.data.Required" name="required{{row.id}}">
                                    {{'applicationConfig_formField_lblRequired' | translate}}
                                </app-checkbox>
                            </div>

                            <div class="mb-3">
                                <app-checkbox [(ngModel)]="row.data.Editable" name="editable{{row.id}}">
                                    {{'applicationConfig_formField_lblEditable' | translate}}
                                </app-checkbox>
                            </div>
                        </td>

                        <ng-container *ngIf="!isGridRowEditing(row, 'formField')">
                            <td>{{row.data.Name}}</td>
                            <td>{{row.data.Row}}</td>
                            <td>{{row.data.Size}}</td>
                            <td>{{row.data.NameLV}}</td>
                            <td>{{row.data.NameEN}}</td>
                            <td>{{row.data.Type}}</td>
                            <td>{{row.data.Source}}</td>
                            <td>
                                <div class="mb-1">
                                    <app-checkbox [ngModel]="row.data.Required"  [disabled]="true" name="required{{row.id}}">
                                        {{'applicationConfig_formField_lblRequired' | translate}}
                                    </app-checkbox>
                                </div>

                                <div>
                                    <app-checkbox [ngModel]="row.data.Editable" [disabled]="true" name="disabled{{row.id}}">
                                        {{'applicationConfig_formField_lblEditable' | translate}}
                                    </app-checkbox>
                                </div>
                            </td>
                        </ng-container>

                        <td>
                            <div class="table-actions">
                                <button type="button" (click)="moveFormFieldUp(row);" class="btn btn-outline btn-icon"
                                        *ngIf="!isGridRowEditing(row, 'formField')"
                                        [disabled]="i === 0" [ngbTooltip]="'moveUp' | translate">
                                    <i class="fi fi-rs-arrow-up"></i>
                                </button>

                                <button type="button" (click)="moveFormFieldDown(row);" class="btn btn-outline btn-icon"
                                        *ngIf="!isGridRowEditing(row, 'formField')"
                                        [disabled]="i === (formFields.length - 1)" [ngbTooltip]="'moveDown' | translate">
                                    <i class="fi fi-rs-arrow-down"></i>
                                </button>

                                <button type="button" (click)="editGridRow(row, 'formField')" class="btn btn-outline btn-icon"
                                        *ngIf="!isGridRowEditing(row, 'formField')" [ngbTooltip]="'edit' | translate">
                                    <i class="fi fi-rs-pencil"></i>
                                </button>

                                <button type="button" (click)="saveGridRow(row, 'formField')" class="btn btn-outline btn-icon"
                                        *ngIf="isGridRowEditing(row, 'formField')" [ngbTooltip]="'save' | translate"
                                        [disabled]="!validateFormField(row.data)">
                                    <i class="fi fi-rs-disk"></i>
                                </button>

                                <button type="button" (click)="cancelGridRow(row, 'formField')" class="btn btn-outline btn-icon"
                                        *ngIf="isGridRowEditing(row, 'formField')" [ngbTooltip]="'cancel' | translate">
                                    <i class="fi fi-rs-cross"></i>
                                </button>

                                <button type="button" (click)="removeFormField(row)" class="btn btn-outline btn-icon" [ngbTooltip]="'delete' | translate">
                                    <i class="fi fi-rs-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </app-table>

            <div class="block-actions">
                <button type="button" class="btn btn-outline-secondary" (click)="addFormField()">
                    <i class="fi fi-rs-add"></i>
                    {{'add' | translate}}
                </button>
            </div>
        </section>

        <section class="block">
            <h5>{{'applicationConfig_formFileFields' | translate}}</h5>

            <app-table [rows]="formFileFields" [columns]="formFileFieldColumns" [wrap]="false">
                <ng-template #rowTemplate let-row let-i="index">
                    <tr>
                        <td>
                            <span class="d-flex">
                                <i class="fi fi-rs-check text-success" *ngIf="validateFormFileField(row.data)"></i>
                                <i class="fi fi-rs-exclamation text-danger" *ngIf="!validateFormFileField(row.data)"
                                   [ngbTooltip]="getFormFileFieldErrors(row.data).join('\n')"></i>
                            </span>
                        </td>

                        <td colspan="8" *ngIf="isGridRowEditing(row, 'formFileField')">
                            <div class="row">
                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formFileField_lblName' | translate}}</label>
                                    <input class="form-control"
                                           autofocus
                                           required
                                           [(ngModel)]="row.data.Name"
                                           name="formFileFieldName{{row.id}}"
                                           pattern="^[a-zA-Z0-9]+$" />
                                </div>

                                <div class="col-3 form-field required">
                                    <label class="form-label">{{'applicationConfig_formFileField_lblRow' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.Row"
                                           name="formFileFieldRow{{row.id}}"
                                           size="2"
                                           maxlength="2" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblNameLV' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.NameLV"
                                           name="formFileFieldNameLV{{row.id}}" />
                                </div>

                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formField_lblNameEN' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.NameEN"
                                           name="formFileFieldNameEN{{row.id}}" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formFileField_lblExtensions' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.Extensions"
                                           name="formFileFieldExtensions{{row.id}}"
                                           pattern="^[a-zA-Z0-9]+$" />
                                </div>

                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formFileField_lblMaxSize' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.MaxSize"
                                           name="formFileFieldMaxSize{{row.id}}" />
                                </div>

                                <div class="col form-field required">
                                    <label class="form-label">{{'applicationConfig_formFileField_lblMaxFileCount' | translate}}</label>
                                    <input class="form-control"
                                           required
                                           [(ngModel)]="row.data.MaxFileCount"
                                           name="formFileFieldMaxFileCount{{row.id}}" />
                                </div>
                            </div>

                            <div class="mb-3">
                                <app-checkbox [(ngModel)]="row.data.Required" name="formFieldFileRequired{{row.id}}">
                                    {{'applicationConfig_formFileField_lblRequired' | translate}}
                                </app-checkbox>
                            </div>
                        </td>

                        <ng-container *ngIf="!isGridRowEditing(row, 'formFileField')">
                            <td>{{row.data.Name}}</td>
                            <td>{{row.data.Row}}</td>
                            <td>{{row.data.NameLV}}</td>
                            <td>{{row.data.NameEN}}</td>
                            <td>{{row.data.Extensions}}</td>
                            <td>{{row.data.MaxSize}}</td>
                            <td>{{row.data.MaxFileCount}}</td>
                            <td>
                                <app-checkbox [ngModel]="row.data.Required" [disabled]="true" name="formFieldFileRequired{{row.id}}">
                                    {{'applicationConfig_formFileField_lblRequired' | translate}}
                                </app-checkbox>
                            </td>
                        </ng-container>

                        <td>
                            <div class="table-actions">
                                <button (click)="moveFormFileFieldUp(row);" class="btn btn-outline btn-icon" type="button"
                                        [disabled]="i === 0 || isGridRowEditing(row, 'formFileField')" [ngbTooltip]="'moveUp' | translate">
                                    <i class="fi fi-rs-arrow-up"></i>
                                </button>

                                <button (click)="moveFormFileFieldDown(row);" class="btn btn-outline btn-icon" type="button"
                                        [disabled]="i === (formFields.length - 1) || isGridRowEditing(row, 'formFileField')"
                                        [ngbTooltip]="'moveDown' | translate">
                                    <i class="fi fi-rs-arrow-down"></i>
                                </button>

                                <button (click)="editGridRow(row, 'formFileField')" class="btn btn-outline btn-icon" type="button"
                                        *ngIf="!isGridRowEditing(row, 'formFileField')" [ngbTooltip]="'edit' | translate">
                                    <i class="fi fi-rs-pencil"></i>
                                </button>

                                <button (click)="saveGridRow(row, 'formFileField')" class="btn btn-outline btn-icon" type="button"
                                        *ngIf="isGridRowEditing(row, 'formFileField')" [ngbTooltip]="'save' | translate"
                                        [disabled]="!validateFormFileField(row.data)">
                                    <i class="fi fi-rs-disk"></i>
                                </button>

                                <button (click)="removeFormFileField(row)" class="btn btn-outline btn-icon" type="button"
                                        [ngbTooltip]="'delete' | translate">
                                    <i class="fi fi-rs-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </app-table>

            <div class="block-actions">
                <button type="button" class="btn btn-outline-secondary" (click)="addFormFileField()">
                    <i class="fi fi-rs-add"></i>
                    {{'add' | translate}}
                </button>
            </div>
        </section>

        <section class="block">
            <h5>{{'applicationConfig_documentTemplates' | translate}}</h5>

                <div class="row" *ngFor="let ar of attachmentRows; let i = index">
                    <div class="col form-field" *ngFor="let at of ar; let j = index">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <label class="form-label">{{at.label | translate}}</label>
                                <app-file [(ngModel)]="at.model.file" [accept]="'.docx'" name="file-{{i}}-{{j}}"></app-file>
                            </div>

                            <a class="btn btn-outline btn-icon align-self-end ms-3" *ngIf="at.model.url" href="{{at.model.url}}"
                               [ngbTooltip]="'download' | translate">
                                <i class="fi fi-rs-download"></i>
                            </a>
                        </div>
                    </div>
                </div>
        </section>

        <app-alert level="info">
            <div><strong>{{'applicationConfig_documentTags' | translate}}</strong></div>
            <div class="template-tags">
                <ng-container *ngFor="let n of getTemplateTags()">
                    <span class="template-tag" [title]="n.source">{{n.tag}}</span>
                    &nbsp;
                </ng-container>
            </div>
        </app-alert>

        <app-alert level="warning" *ngIf="howTo">
            <div [innerHTML]="howTo | safeHtml"></div>
        </app-alert>

        <div class="form-actions">
            <span class="form-actions-group">
                <button type="button" class="btn btn-secondary" (click)="export()" [disabled]="hasUnsavedGridRows">
                    <i class="fi fi-rs-download"></i>
                    {{'applicationConfig_btnExport' | translate}}
                </button>

                <button type="button" class="btn btn-secondary" (click)="importFile.click()">
                    <i class="fi fi-rs-upload"></i>
                    {{'applicationConfig_btnImport' | translate}}
                </button>
            </span>

            <span class="form-actions-group" *ngIf="isDeactivated || isPublished || (isDraft && !isNew)">
                <button type="button" class="btn btn-outline-primary" (click)="activate()" *ngIf="isDeactivated">
                    <i class="fi fi-rs-check"></i>
                    {{'applicationConfig_btnActivate' | translate}}
                </button>

                <button type="button" class="btn btn-outline-primary" (click)="deactivate()" *ngIf="isPublished">
                    <i class="fi fi-rs-ban"></i>
                    {{'applicationConfig_btnDeactivate' | translate}}
                </button>

                <button type="button" class="btn btn-outline-primary" (click)="publish()" *ngIf="isDraft && !isNew"
                        [disabled]="!form.valid || hasUnsavedGridRows">
                    <i class="fi fi-rs-paper-plane"></i>
                    {{'applicationConfig_btnPublish' | translate}}
                </button>

                <button type="button" class="btn btn-outline-primary" (click)="unpublish()" *ngIf="isPublished">
                    <i class="fi fi-rs-undo"></i>
                    {{'applicationConfig_btnUnpublish' | translate}}
                </button>
            </span>

            <span class="form-actions-group">
                <button type="button" class="btn btn-outline" [routerLink]="['/applications/config'] | localize">
                    {{'applicationConfig_btnCancel' | translate}}
                </button>

                <button class="btn btn-primary" type="button" (click)="save()">
                    {{'applicationConfig_btnSave' | translate}}
                </button>
            </span>
        </div>

        <input #importFile name="importFile" type="file" accept=".json,.txt" style="display: none;"
               (change)="import($event.target.files[0]); importFile.value = ''" />
    </form>
</div>
