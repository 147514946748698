import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MarksItem, SemesterPlan, Program } from '../../models/Marks';

import { MarksService } from '../../services/marks.service';
import { AppService } from '../../services/app.service';
import { ParameterService } from '../../services/parameter.service';
import { PersonService } from '../../services/person.service';

import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { AppDatePipe } from '../../pipes/date.pipe';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
    selector: 'app-marks',
    templateUrl: './marks.component.html',
    styleUrls: ['./marks.component.scss']
})
export class MarksComponent implements OnInit {
    constructor(
        public app: AppService,
        private param: ParameterService,
        private service: MarksService,
        private studentService: PersonService,
        private route: ActivatedRoute,
        private messages: MessageService,
        private appDate: AppDatePipe
    ) {
    }

    items: MarksItem[] = [];
    semesterPlans: SemesterPlan[];
    selectedSemesterPlan: SemesterPlan;
    marksViewLinkCode: string = 'MarksViewLinkLv';
    marksViewLink: string = '';
    marksEcourseCode = 'MarksEcourseLinkLv';
    marksEcourseLink: string = '';
    selectedStudentProgram: Program;
    studentPrograms: Program[];
    student: IPersonSearchResultItem;
    isDenied: boolean;
    showStudentPicker: boolean;
    canPickStudent: boolean;

    unavailableText: string;
    isUnavailable: boolean;

    readonly columns: ITableColumn[] = [
        { property: 'SubjectName', label: 'marks_studiju_prieksmets', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'SubjectName', label: 'marks_studiju_prieksmetsVertejums', sorts: true, cssClass: 'hidden-md-up' },
        { property: 'SubjectCode', label: 'marks_kursa_kods', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Block', label: 'marks_category', sorts: true, cssClass: 'hidden-lg-down' },
        { property: 'TestType', label: 'marks_kontroles_veids', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Points', label: 'marks_kp_sem', sorts: true, type: 'number', cssClass: 'hidden-lg-down' },
        { property: 'FinalMarkValue', label: 'marks_gala_vertejums', sorts: true, cssClass: 'hidden-sm-down' },
        { width: '1px' }
    ];

    readonly semesterPlanDisplayFn = (option: SemesterPlan) => {
        if (option) {
            const label = this.app.translate('marks_semestris');
            const df = this.appDate.transform(option.DateFrom);
            const dt = this.appDate.transform(option.DateTo);

            return `${option.Number}. ${label} ${df} - ${dt} ${option.Iscurrentsemester || ''}`;
        }

        return '';
    };

    private expanded: MarksItem[] = [];

    ngOnInit() {
        const isEn = this.app.currentLanguage === 'en';

        if (isEn) {
            this.marksViewLinkCode = 'MarksViewLinkEn';
            this.marksEcourseCode = 'MarksEcourseLinkEn';
        }

        this.app.addLoading(this.messages.getByCodes(['MARKS_UNAVAILABLE'])).subscribe(data => {
            const unav = data.find(t => t.Code === 'MARKS_UNAVAILABLE');
            this.unavailableText = unav ? isEn ? unav.TextEN : unav.TextLV : undefined;
        });

        const user = this.app.currentUser;

        if (user) {
            const id = this.route.snapshot.queryParams['id'];
            this.canPickStudent = user.rights.indexOf('MARKS.MY.SET_STUDENT') > -1;

            if (id && !this.canPickStudent) {
                this.isDenied = true;
                return;
            }

            if (this.canPickStudent) {
                if (id) {
                    this.app.addLoading(this.studentService.findStudents('student_id', id)).subscribe(data => {
                        if (data.length) {
                            this.setStudent(data[0]);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
                } else {
                    this.showStudentPicker = true;
                }
            } else {
                this.init();
            }

            this.app.addLoading(this.param.getValues()).subscribe((params) => {
                this.marksViewLink = this.param.findValue(params, this.marksViewLinkCode);
                this.marksEcourseLink = this.param.findValue(params, this.marksEcourseCode);
            });
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.showStudentPicker = false;
        this.student = student;
        this.init();
    }

    toggle(row: MarksItem) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: MarksItem): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    getSemesters(studentEmail?: string) {
        if (this.selectedStudentProgram) {
            this.app.addLoading(this.service.getSemesterPlans(this.selectedStudentProgram.StudentId, studentEmail)).subscribe(plans => {
                this.semesterPlans = plans;
                if (plans.length > 0) {
                    let now = new Date();
                    let activeSemester = plans.find(p => {
                        return ((p.Iscurrentsemester == 1) || (p.DateTo && p.DateFrom && now > new Date(p.DateFrom) && now < new Date(p.DateTo)));
                    });
                    if (activeSemester)
                        this.selectedSemesterPlan = activeSemester;
                    else {
                        this.selectedSemesterPlan = plans[plans.length - 1];
                    }

                    this.filter();
                }
            });
        }
    }

    filterByProgram() {
        this.items = [];
        this.getSemesters(this.student ? this.student.Email : undefined);
    }

    filter() {
        this.app.addLoading(this.service.getMarks(this.selectedSemesterPlan.Id, this.selectedStudentProgram.StudentId)).subscribe(marks => {
            this.items = marks;
        });
    }

    //showError(message: string, error?: any) {
    //    this.app.showError(message, error, this.app.translate.instant('marks_cantuse'));
    //}

    getTranslatedMarkValue(testType: string) {
        if (testType?.indexOf('translate_marks_') > -1)
            return this.app.translate(testType.replace('translate_marks_', 'marks_markvalue_'));
        else
            return testType;
    }

    getFormatedSubjectName(item: MarksItem, includeMark?: boolean) {
        let result = '';

        if (item.eCourseId)
            result = '<a target="_blank" href="' + this.marksEcourseLink.replace('{ecourseId}', item.eCourseId)
                + '" title="' + this.app.translate('marks_openbook') + '">' + item.SubjectName + '&nbsp;<i class="fa fa-external-link"></i></a>';
        else
            result = item.SubjectName;

        if (includeMark && item.FinalMark?.MarkValue) {
            result += ` - <strong>${this.getTranslatedMarkValue(item.FinalMark.MarkValue)}</strong>`;
        }

        return result;
    }

    getMarksWiewUrl(item: MarksItem) {
        return this.marksViewLink.replace('{CourseCode}', item.SubjectCode);
    }

    private init() {
        this.loadPrograms(this.student ? this.student.Email : undefined);
    }

    private loadPrograms(studentEmail?: string) {
        this.app.addLoading(this.service.getStudentPrograms(studentEmail)).subscribe(programs => {
            if (programs.length > 0) {
                this.selectedStudentProgram = programs[0];

                if (programs.length > 1)
                    this.studentPrograms = programs;

                this.getSemesters(studentEmail);
            } else {
                this.isUnavailable = true;
            }
        }, err => {
            this.isUnavailable = true;
            this.app.showError(err);
        });
    }
}
