import {
    SurveySatisifedType, SurveyLevel5Type, SurveyAnswerEvaluationType, ISurveyQuestion,
    YesValue, NoValue, NoOpinionValue, SurveyTestingOperator
} from "../../models/Survey";

export class SurveyUtils {
    static readonly satisfiedTypes = Object.keys(SurveySatisifedType).filter(t => +t >= 0).map(t => ({ key: SurveySatisifedType[t], value: t }));
    static readonly level5Types = Object.keys(SurveyLevel5Type).filter(t => +t >= 0).map(t => ({ key: SurveyLevel5Type[t], value: t }));

    static sequence(size: number, start: number = 0) {
        return Array.from({ length: size }, (_, i) => start + i);
    }

    static isChoiceAnswer(type: SurveyAnswerEvaluationType): boolean {
        return [
            SurveyAnswerEvaluationType.MultipleFromScope,
            SurveyAnswerEvaluationType.OneFromScope,
            SurveyAnswerEvaluationType.OneToFive,
            SurveyAnswerEvaluationType.OneToTen,
            SurveyAnswerEvaluationType.Satisfaction,
            SurveyAnswerEvaluationType.YesNo,
            SurveyAnswerEvaluationType.Level5
        ].includes(type);
    }

    static isGridAnswer(type: SurveyAnswerEvaluationType): boolean {
        return [
            //SurveyAnswerEvaluationType.MultipleFromScope,
            //SurveyAnswerEvaluationType.OneFromScope,
            SurveyAnswerEvaluationType.OneToFive,
            SurveyAnswerEvaluationType.OneToTen,
            SurveyAnswerEvaluationType.Satisfaction,
            //SurveyAnswerEvaluationType.YesNo,
            SurveyAnswerEvaluationType.Level5
        ].includes(type);
    }

    static isTextAnswer(type: SurveyAnswerEvaluationType): boolean {
        return type == SurveyAnswerEvaluationType.FreeFormText;
    }

    static isNumberAnswer(type: SurveyAnswerEvaluationType): boolean {
        return type == SurveyAnswerEvaluationType.OneToHundred;
    }

    static validateAnswer(question: ISurveyQuestion, answer: any): boolean {
        if (question.Mandatory && answer == undefined) return false;
        return true;
    }

    static buildAnswerOptions(question: ISurveyQuestion, translate: (key: string) => string): { text: string, value: any }[] {
        const opts: { text: string, value: any }[] = [];

        switch (question.AnswerEvaluationType) {
            case SurveyAnswerEvaluationType.YesNo:
                opts.push({ text: translate('survey_valueYes'), value: YesValue });
                opts.push({ text: translate('survey_valueNo'), value: NoValue });
                break;

            case SurveyAnswerEvaluationType.OneFromScope:
            case SurveyAnswerEvaluationType.MultipleFromScope:
                opts.push(...question.Answers.map(t => ({ text: t.Title, value: t.ID })));
                break;

            case SurveyAnswerEvaluationType.OneToFive:
                opts.push(...SurveyUtils.sequence(5, 1).map(t => ({ text: t.toString(), value: t })));
                break;

            case SurveyAnswerEvaluationType.OneToTen:
                opts.push(...SurveyUtils.sequence(10, 1).map(t => ({ text: t.toString(), value: t })));
                break;

            case SurveyAnswerEvaluationType.Satisfaction:
                opts.push(...SurveyUtils.satisfiedTypes.map(t => ({ text: translate(`survey_satisfiedType${t.key}`), value: t.value })));
                break;

            case SurveyAnswerEvaluationType.Level5:
                opts.push(...SurveyUtils.level5Types.map(t => ({ text: translate(`survey_level5Type${t.key}`), value: t.value })));
                break;
        }

        if (question.HasNoOpinion) {
            opts.push({ text: translate('survey_valueNoOpinion'), value: NoOpinionValue });
        }

        return opts;
    }

    static checkCondition(operator: SurveyTestingOperator, values: any[], testingValue: any): boolean {
        if (values.length) {
            switch (operator) {
                case SurveyTestingOperator.DecimalValueEqual: return values.some(t => t.toString() == testingValue);
                case SurveyTestingOperator.DecimalValueGreaterThan: return values.some(t => parseFloat(t) > parseFloat(testingValue));
                case SurveyTestingOperator.DecimalValueGreaterThan: return values.some(t => parseFloat(t) < parseFloat(testingValue));
                case SurveyTestingOperator.TextValueContains: return values.some(t => (t + '').includes(testingValue));
                case SurveyTestingOperator.TextValueEndsWith: return values.some(t => (t + '').endsWith(testingValue));
                case SurveyTestingOperator.TextValueEqual: return values.some(t => (t + '') == testingValue);
                case SurveyTestingOperator.TextValueStartWith: return values.some(t => (t + '').startsWith(testingValue));
            }
        }

        return false;
    }

    static buildQuestionKey(...parts: string[]): string {
        return parts.filter(t => !!t).join('_');
    }

    static parseQuestionKey(key: string): string[] {
        return (key || '').split('_');
    }
}
