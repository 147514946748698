import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ElementRef } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SearchService } from '../../services/search.service';

const cache: {
    data?: ISearchable[]
} = {};

interface IMatchPart {
    text: string;
    isMatch?: boolean;
}

interface ISearchable {
    source: string[];
    title: string;
    description?: string;
    category?: string;
    url: string;
    match?: {
        title: IMatchPart[],
        description: IMatchPart[]
    }
}

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: SearchService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    @Output('go') goEvent = new EventEmitter();

    results: { category: string, items: ISearchable[] }[] = [];

    private data: ISearchable[] = [];
    private readonly searchSubj = new Subject<string>();
    @ViewChild('control', { static: true }) private control: ElementRef;

    ngOnInit() {
        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            const results = this.data.filter(t => t.source.some(n => n.indexOf(term.toLowerCase()) > -1));

            results.forEach(t => {
                t.match = {
                    title: this.service.getTextMatchParts(t.title, term),
                    description: this.service.getTextMatchParts(t.description, term)
                };
            });

            this.results = results.reduce((arr, n) => {
                let cat = arr.find(t => t.category == n.category);

                if (!cat) {
                    cat = { category: n.category, items: [] };
                    arr.push(cat);
                }

                cat.items.push(n);

                return arr;
            }, []);
        });

        this.init();
    }

    search() {
        const term = this.control.nativeElement.value;
        if (!term) {
            this.results = [];
        } else if (term.length > 2) {
            this.searchSubj.next(term);
        }
    }

    clear() {
        this.control.nativeElement.value = '';
        this.search();
    }

    isExternalUrl(url: string): boolean {
        return url?.toLowerCase().startsWith('http');
    }

    go(result: ISearchable) {
        if (result.url) {
            this.goEvent.emit();

            if (this.isExternalUrl(result.url)) {
                this.document.defaultView.open(result.url, '_blank');
            } else {
                this.app.navigateByUrl(result.url);
            }
        }
    }

    private init() {
        if (cache.data) {
            this.data = cache.data;
            this.focus();
        } else {
            this.app.addLoading(this.service.getQuickSearchItems()).subscribe(data => {
                this.data = cache.data = data.filter(t => t.Url && t.Url[0] != '#').map(t => {
                    return {
                        title: t.Title,
                        description: t.Description,
                        category: t.Category,
                        source: [t.Title?.toLowerCase(), t.Description?.toLowerCase()].filter(t => !!t),
                        url: t.Url
                    }
                });

                this.focus();
            });
        }
    }

    private focus() {
        this.document.defaultView.setTimeout(() => {
            this.control.nativeElement.focus();
        });
    }
}
