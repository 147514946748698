export enum ClassifierType {
    UserRole = 'UserRole',
    Permissions = 'Permissions',
    FooterLv1='Footer-lv-1',
    FooterLv2 ='Footer-lv-2',
    FooterLv3 ='Footer-lv-3',
    FooterLv4 ='Footer-lv-4',
    FooterEn1 ='Footer-en-1',
    FooterEn2 ='Footer-en-2',
    FooterEn3 ='Footer-en-3',
    FooterEn4 = 'Footer-en-4',
    ApplicationType = 'ApplicationType',
    ApplicationRefLanguage = 'ApplicationRefLanguage',
    ApplicationAddresseeType = 'ApplicationAddresseeType',
    ApplicationTypeOfReceipt = 'ApplicationTypeOfReceipt',
    ApplicationDvsAcademicLeave = 'ApplicationDvsAcademicLeave',
    ApplicationDvsAcademicLeaveDoctor = 'ApplicationDvsAcademicLeaveDoctor',
    ApplicationDvsExmatriculation = 'ApplicationDvsExmatriculation',
    ApplicationDvsRepeatCourse = 'ApplicationDvsRepeatCourse',
    ApplicationDvsGrades = 'ApplicationDvsGrades',
    ApplicationDvsCourseAlignment = 'ApplicationDvsCourseAlignment',
    ApplicationDvsDiscount = 'ApplicationDvsDiscount',
    ApplicationDvsRepayment = 'ApplicationDvsRepayment',
    ApplicationDvsStatus = 'ApplicationDvsStatus',
    ApplicationDvsApplicant = 'ApplicationDvsApplicant',
    ApplicationDvsMatriculationStatus = 'ApplicationDvsMatriculationStatus',
    ApplicationDvsSkills = 'ApplicationDvsSkills',
    ApplicationDvsJustifiedAbsence = 'ApplicationDvsJustifiedAbsence',
    ApplicationDvsComplaint = 'ApplicationDvsComplaint',
    ApplicationDvsAppealComplaint = 'ApplicationDvsAppealComplaint',
    ApplicationDvsScholarshipComplaint = 'ApplicationDvsScholarshipComplaint',
    ApplicationDvsFreeForm = 'ApplicationDvsFreeForm',
    ApplicationDvsPersonData = 'ApplicationDvsPersonData',
    ApplicationDvsPaymentTermExtension = 'ApplicationDvsPaymentTermExtension',
    ApplicationDvsPersonPhoto = 'ApplicationDvsPersonPhoto',
    ApplicationShortName = 'ApplicationShortName',
    ApplicationGroup = 'ApplicationGroup',
    PurchaseType = 'PurchaseType',
    PurchaseDvsIE11 = 'PurchaseDvsIE11',
    PurchaseDvsIE12 = 'PurchaseDvsIE12',
    PurchaseDvsIE13 = 'PurchaseDvsIE13',
    PurchaseDvsIE14 = 'PurchaseDvsIE14',
    PurchaseDvsIE15 = 'PurchaseDvsIE15',
    PurchaseDvsIE16 = 'PurchaseDvsIE16',
    PurchaseShortName = 'PurchaseShortName',
	PurchaseDvsInitialId = 'PurchaseDvsInitialId',
    Streams = 'Streams',
    ApplicationDvsInitialId = 'ApplicationDvsInitialId',
    PrintEmailContent = 'PrintEmailContent',
    PrintTransactionContent = 'PrintTransactionContent',
    FinalPaperLanguage = 'FinalPaperLanguage',
    FinalPaperLanguageOther = 'FinalPaperLanguageOther',
    FinalPaperThesisType = 'FinalPaperThesisType',
    ApplicationTopicAcademicLeave = 'ApplicationTopicAcademicLeave',
    ApplicationTopicExmatriculation = 'ApplicationTopicExmatriculation',
    ApplicationReasonAcademicLeave = 'ApplicationReasonAcademicLeave',
    ApplicationReasonDiscount = 'ApplicationReasonDiscount',
    ApplicationReasonRepayment = 'ApplicationReasonRepayment',
    ApplicationDvsAcademicLeaveOrder = 'ApplicationDvsAcademicLeaveOrder',
    ApplicationDvsExmatriculationOrder = 'ApplicationDvsExmatriculationOrder',
    ApplicationDvsRepeatCourseOrder = 'ApplicationDvsRepeatCourseOrder',
    ApplicationDvsGradesOrder = 'ApplicationDvsGradesOrder',
    ApplicationDvsCourseAlignmentOrder = 'ApplicationDvsCourseAlignmentOrder',
    ApplicationDvsDiscountOrder = 'ApplicationDvsDiscountOrder',
    ApplicationDvsRepaymentOrder = 'ApplicationDvsRepaymentOrder',
    ApplicationDvsStatusOrder = 'ApplicationDvsStatusOrder',
    ApplicationDvsApplicantOrder = 'ApplicationDvsApplicantOrder',
    ApplicationDvsMatriculationStatusOrder = 'ApplicationDvsMatriculationStatusOrder',
    ApplicationDvsSkillsOrder = 'ApplicationDvsSkillsOrder',
    ApplicationDvsJustifiedAbsenceOrder = 'ApplicationDvsJustifiedAbsenceOrder',
    ApplicationDvsComplaintOrder = 'ApplicationDvsComplaintOrder',
    ApplicationDvsAppealComplaintOrder = 'ApplicationDvsAppealComplaintOrder',
    ApplicationDvsScholarshipComplaintOrder = 'ApplicationDvsScholarshipComplaintOrder',
    ApplicationDvsFreeFormOrder = 'ApplicationDvsFreeFormOrder',
    ApplicationDvsPersonDataOrder = 'ApplicationDvsPersonDataOrder',
    ApplicationDvsPaymentTermExtensionOrder = 'ApplicationDvsPaymentTermExtensionOrder',
    ApplicationDocPort = 'ApplicationDocPort',
    PaymentType = 'PaymentType',
    ApplicationTopicJustifiedAbsence = 'ApplicationTopicJustifiedAbsence',
    JustifiedAbsenceType = 'JustifiedAbsenceType',
    ScholarshipType = 'ScholarshipType',
    CourseRound = 'CourseRound',
    TileCategory = 'TileCategory',
    Grade = 'Grade'
}

export class Classifier {
    Id: string;
    Code: string;
    LegacyCode: string;
    Type: string;
    Value: string;
    ValueEN: string;
	ValueLV: string;
    Payload: string;
}

export interface IApplicationTopicAcademicLeavePayload {
    ShowDateTo?: boolean;
}

export interface IApplicationTopicJustifiedAbsenceField {
    Required?: boolean;
}

export interface IApplicationTopicJustifiedAbsencePayload {
    DateFrom?: IApplicationTopicJustifiedAbsenceField,
    DateTo?: IApplicationTopicJustifiedAbsenceField,
    DocumentNumber?: IApplicationTopicJustifiedAbsenceField,
    Attachments?: IApplicationTopicJustifiedAbsenceField,
    Comments?: IApplicationTopicJustifiedAbsenceField,
    AbsenceType?: IApplicationTopicJustifiedAbsenceField,
    ClosingSheet?: IApplicationTopicJustifiedAbsenceField,
    ProlongsPeriod?: IApplicationTopicJustifiedAbsenceField
}
