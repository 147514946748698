<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/portfolio', text: 'portfolio_title' },
                     { url: '/portfolio/' + data?.Id, text: data?.Title }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'portfolio_title' | translate}}</h1>
        <aside>
            <button type="button" class="btn btn-outline-primary" (click)="share()" *ngIf="data">
                <i class="fi fi-rs-share"></i>
                {{'portfolio_btnShare' | translate}}
            </button>
        </aside>
    </header>

    <app-alert level="success" *ngIf="shared">
        <div [innerHTML]="shared | safeHtml"></div>
    </app-alert>

    <app-portfolio-data [data]="data" *ngIf="data"></app-portfolio-data>

    <div class="form-actions" *ngIf="data">
        <button class="btn btn-outline" type="button" [routerLink]="['/portfolio'] | localize">
            {{'close' | translate}}
        </button>
    </div>
</div>
