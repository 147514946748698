import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';

import { PortfolioService } from '../../services/portfolio.service';
import { ITableColumn } from '../../shared/table/table.component';
import { IPortfolioListItem } from '../../models/Portfolio';

const cache: { items?: IPortfolioListItem[] } = {};

export function reset() {
    cache.items = undefined;
}

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PortfolioService
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'Title', label: 'portfolio_lblTitle', sorts: true },
        { property: 'Added', label: 'portfolio_lblAdded', sorts: true, type: 'date' },
        { width: '1px' }
    ];

    rows: IPortfolioListItem[] = [];

    ngOnInit() {
        if (cache.items) {
            this.rows = cache.items;
        } else {
            this.app.addLoading(this.service.get()).subscribe(data => {
                this.rows = data;
                cache.items = data;
            });
        }
    }
}
