import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { CodeResponse, ResetResponse } from '../models/Password';
import { UserCrypto } from '../models/UserInfo';
import { IOAuthToken } from '../models/Auth';

type CanEmployeeResetPasswordResponseType = {
    CanReset?: boolean,
    Username?: string,
    Email?: string,
    Error: string
};

@Injectable({ providedIn: "root" })
export class PasswordService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/password` }

    getSecurityCode(
        lastName: string,
        personIdOrCode: string,
        emailPhoneValue: string,
        reCaptchaResponse: string,
        lang: string
    ): Observable<CodeResponse> {
        let url = `${this.apiUrl}/actions/request`;
        let body = {
            LastName: lastName,
            PersonIdOrCode: personIdOrCode,
            EmailPhoneValue: emailPhoneValue,
            ReCaptchaResponse: reCaptchaResponse,
            SelectedLanguage: lang
        };
        
        return this.http.post<CodeResponse>(url, body);
    }

    getCrypto(cryptoKey: string): Observable<UserCrypto> {
        const url = `${this.apiUrl}/actions/getCrypto?cryptoKey=${cryptoKey}`;
        return this.http.get<UserCrypto>(url);
    }

    resetPassword(
        userName: string,
        newPassword: string,
        securityCode: string,
        userCryptoKey: string,
        reCaptchaResponse: string,
        lang: string
    ): Observable<ResetResponse> {
        let url = `${this.apiUrl}/actions/reset`;
        let body = {
            Username: userName,
            Password: newPassword,
            SecurityCode: securityCode,
            UserCryptoKey: userCryptoKey,
            ReCaptchaResponse: reCaptchaResponse,
            SelectedLanguage: lang
        };

        return this.http.post<ResetResponse>(url, body);
    }

    requestEmployeePasswordReset(language: string): Observable<{ HasError: boolean, RedirectUrl: string }> {
        const url = `${this.apiUrl}/requestEmployeeReset?language=${language}`;
        return this.http.post<{ HasError: boolean, RedirectUrl: string }>(url, null);
    }

    canEmployeeResetPassword(token: IOAuthToken): Observable<CanEmployeeResetPasswordResponseType> {
        const url = `${this.apiUrl}/canEmployeeReset`;
        const body = { AccessToken: token.access_token, TokenType: token.token_type, State: token.state };

        return this.http.post<CanEmployeeResetPasswordResponseType>(url, body);
    }

    resetEmployeePassword(token: IOAuthToken, newPassword: string, cryptoKey: string, language: string)
        : Observable<{ Succeeded: boolean, Error: string }> {
        const url = `${this.apiUrl}/resetForEmployee`;
        const body = {
            Token: { AccessToken: token.access_token, TokenType: token.token_type, State: token.state },
            Password: newPassword,
            CryptoKey: cryptoKey,
            Language: language
        };

        return this.http.post<{ Succeeded: boolean, Error: string }>(url, body);
    }
}
