<form #filterForm="ngForm">
    <div class="row">
        <div class="col form-field">
            <input class="form-control"
                   [placeholder]="'hospitalDashboard_students_search' | translate"
                   [(ngModel)]="filters.search"
                   name="filterSearch"
                   (input)="filter()">
        </div>

        <div class="col form-field">
            <app-select [options]="filters.speciality.options"
                        [placeholder]="'hospitalDashboard_students_speciality' | translate"
                        [displayProperty]="'label'"
                        [valueProperty]="'value'"
                        [(ngModel)]="filters.speciality.value"
                        name="filterSpeciality"
                        (selectionChange)="filter()">
            </app-select>
        </div>

        <div class="col form-field">
            <app-select [options]="filters.year.options"
                        [placeholder]="'hospitalDashboard_students_studyYear' | translate"
                        [displayProperty]="'label'"
                        [valueProperty]="'value'"
                        [(ngModel)]="filters.year.value"
                        name="filterYear"
                        (selectionChange)="filter()">
            </app-select>
        </div>

        <div class="col form-field">
            <app-select [options]="filters.status.options"
                        [placeholder]="'hospitalDashboard_students_status' | translate"
                        [displayProperty]="'label'"
                        [valueProperty]="'value'"
                        [(ngModel)]="filters.status.value"
                        name="filterStatus"
                        (selectionChange)="filter()">
            </app-select>
        </div>
    </div>

    <div class="form-field">
        <span *ngFor="let n of filters.place.options; let i = index" [class.ms-3]="i > 0">
            <app-checkbox [(ngModel)]="n.checked" name="filterPlace_{{n.value}}" (stateChange)="filter()">
                {{n.label | translate}}
            </app-checkbox>
        </span>

        <span class="ms-3">
            <app-checkbox [(ngModel)]="filters.plannedRotations.checked" name="filterPlannedRotations" (stateChange)="filter()">
                {{filters.plannedRotations.label | translate}}
            </app-checkbox>
        </span>
    </div>
</form>

<app-table [rows]="rows" [columns]="columns">
    <ng-template #rowTemplate let-row>
        <tr>
            <td>{{row.Name}} {{row.Surname}} ({{row.Upn}})</td>
            <td>{{row.Speciality}}</td>
            <td>{{row.StudyYear}}.</td>
            <td>{{row.Status}}</td>
            <td>{{row.Workplace}}</td>
            <td>{{row.ClinicalBase}}</td>
            <td>
                <button type="button" class="btn btn-icon btn-outline" (click)="openStudyPlan(row)"
                        [ngbTooltip]="'hospitalDashboard_students_openStudyPlan' | translate">
                    <i class="fi fi-rs-document"></i>
                </button>
            </td>
        </tr>
    </ng-template>
</app-table>
