import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'appDate'
})
export class AppDatePipe extends DatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) locale: string) {
        super(locale);
        this.localeId = locale;
    }

    private localeId: string;

    transform(value: any, args?: any): any {
        let format: string;
        let dateFormat: string;
        const timeFormat = 'HH:mm';

        switch ((this.localeId || '').toLowerCase().split('-')[0]) {
            case 'lv':
            case 'en': dateFormat = 'dd.MM.yyyy'; break;
            // other formats...
            default: dateFormat = 'yyyy-MM-dd';
        }

        const kind: 'date' | 'time' | 'datetime' = args || 'date';

        args = null;

        switch (kind) {
            case 'date': format = dateFormat; break;
            case 'time': format = timeFormat; break;
            case 'datetime': format = `${dateFormat} ${timeFormat}`; break;
        }

        return super.transform(value, format);
    }
}
