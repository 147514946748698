import { Component, Input } from '@angular/core';

import { Student } from '../../models/Application';

@Component({
    selector: 'app-application-student',
    templateUrl: './student.component.html',
    styleUrls: ['./application.component.scss']
})
export class StudentComponent {
    @Input() student: Student;
    @Input() isSkillsApp: boolean;
}
