<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/doctor-dashboard', text: 'doctorDashboard_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'doctorDashboard_title' | translate}}</h1>
        <!-- Izslēgts līdz RSU sagatavo api/PersonData
        <aside>
            <button type="button" class="btn btn-outline" (click)="togglePersonPicker()" *ngIf="canPickEmployee && tabsReady">
                <i class="fi fi-rs-badge"></i>
                {{'doctorDashboard_btnPickEmployee' | translate}}
            </button>
        </aside> -->
    </header>

    <app-alert level="warning" *ngIf="tabsReady && !tabs.length">
        {{'doctorDashboard_unavailable' | translate}}
    </app-alert>

    <app-person-picker *ngIf="personPickerOpened" [personType]="personPickerType" (pick)="setPerson($event)"></app-person-picker>

    <ng-container *ngIf="tabs.length && !personPickerOpened">
        <form #filterForm="ngForm" class="was-validated">
            <div class="row">
                <div class="col-6 form-field">
                    <app-select [options]="academicYears"
                                [placeholder]="'doctorDashboard_lblAcademicYear' | translate"
                                [displayProperty]="'Name'"
                                [valueProperty]="'Id'"
                                [required]="true"
                                [(ngModel)]="selectedYearId"
                                name="selectedYearId">
                    </app-select>
                </div>
            </div>
        </form>

        <ng-container *ngIf="selectedYearId">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
                <li [ngbNavItem]="i + 1" *ngFor="let tab of tabs; let i = index">
                    <a ngbNavLink>{{tab.title | translate}}</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngSwitch]="tab.id">
                            <div class="mt-3">
                                <app-doctor-dashboard-tasks *ngSwitchCase="'tasks'"></app-doctor-dashboard-tasks>

                                <app-doctor-dashboard-students *ngSwitchCase="'students'"
                                                              [academicYearId]="selectedYearId"></app-doctor-dashboard-students>

                                <app-doctor-dashboard-seminars *ngSwitchCase="'seminars'"
                                                               [academicYearId]="selectedYearId"
                                                               [openAttendanceFor]="seminarId"></app-doctor-dashboard-seminars>

                                <app-doctor-dashboard-rotations *ngSwitchCase="'rotations'"
                                                                [academicYearId]="selectedYearId"></app-doctor-dashboard-rotations>
                            </div>
                        </ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>
    </ng-container>
</div>
