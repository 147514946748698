<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: isAuthenticated ? '/surveys' : undefined, text: 'surveys_title' },
                     { text: 'survey_fillTitle' }
                     ]"></app-breadcrumbs>

    <app-alert level="danger" *ngIf="error">{{error}}</app-alert>

    <ng-container *ngIf="data">
        <header class="content-header">
            <h1>{{data.Survey.Title}}</h1>
        </header>

        <app-alert level="info" *ngIf="data.Survey.Description">{{data.Survey.Description}}</app-alert>

        <div class="form-actions">
            <button class="btn btn-outline" type="button" (click)="cancel()">
                {{'survey_btnCancel' | translate}}
            </button>

            <button class="btn btn-primary" type="button" (click)="begin()">
                {{'survey_btnBegin' | translate}}
            </button>
        </div>
    </ng-container>
</div>
