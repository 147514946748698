import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IHospitalDoctor } from 'src/app/models/Hospital';
import { AppService } from 'src/app/services/app.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { ITableColumn } from 'src/app/shared/table/table.component';
import { store } from '../store';

const cache: {
    doctorsByCompany: { [companyId: string]: IHospitalDoctor[] }
} = {
    doctorsByCompany: {}
};

@Component({
    selector: 'app-hospital-dashboard-doctors',
    templateUrl: './hospital-dashboard-doctors.component.html'
})
export class HospitalDashboardDoctorsComponent {
    constructor(
        private app: AppService,
        private service: HospitalService
    ) {}

    @Input() set companyId(value: string) {
        this._companyId = value;
        this.init(true);
    }

    @Input() set academicYearId(value: string) {
        this._academicYearId = value;
        this.init();
    }

    @Output('openSeminars') openSeminarsEvent = new EventEmitter<{ trainer: string }>();

    readonly columns: ITableColumn[] = [
        {
            label: 'hospitalDashboard_doctors_lblNameSurname', sorts: true, sortFn: (a: IHospitalDoctor, b: IHospitalDoctor) => {
                const as = `${a.Name} ${a.Surname}`;
                const bs = `${b.Name} ${b.Surname}`;
                return as.localeCompare(bs, this.app.currentLanguage);
            }
        },
        { property: 'Email', label: 'hospitalDashboard_doctors_lblEmail', sorts: true },
        { property: 'Hospital', label: 'hospitalDashboard_doctors_lblHospital', sorts: true },
        { width: '1px' }
    ];

    rows: IHospitalDoctor[] = [];

    filters: {
        search?: string
    } = {};

    get companyId(): string {
        return this._companyId;
    }

    get academicYearId(): string {
        return this._academicYearId;
    }

    private _companyId: string;
    private _academicYearId: string;
    private data: IHospitalDoctor[] = [];

    filter() {
        const search = this.filters.search?.toLowerCase();

        this.rows = this.data.filter(t => {
            return !search || (
                    (t.Name + ' ' + t.Surname).toLowerCase().includes(this.filters.search.toLowerCase())
                    || t.Email.toLowerCase().includes(search)
                    || t.Hospital.toLowerCase().includes(search));
        });
    }

    openSeminars(row: IHospitalDoctor) {
        this.openSeminarsEvent.emit({ trainer: row.Name + ' ' + row.Surname });
    }

    private init(reload?: boolean) {
        const process = (data: IHospitalDoctor[]) => {
            const byYear = this.filterByAcademicYear(data);

            this.data = byYear;
            this.rows = byYear;
        };

        if (cache.doctorsByCompany[this.companyId]) {
            process(cache.doctorsByCompany[this.companyId]);
        } else if (reload) {
            this.app.addLoading(this.service.getDoctors(this.companyId, store.getPerson()?.Upn)).subscribe(data => {
                cache.doctorsByCompany[this.companyId] = data;
                process(data);
            });
        }
    }

    private filterByAcademicYear(data: IHospitalDoctor[]) {
        return data.filter(t => t.AcademicYearId == this.academicYearId);
    }
}
