export enum PurchaseType {
    IE11 = 'IE11',
    IE12 = 'IE12',
    IE13 = 'IE13',
    IE14 = 'IE14',
    IE15 = 'IE15',
    IE16 = 'IE16'
}

//export class PurchaseFieldMapping {
//    Title: PurchaseFieldMappingInfo;
//    Item: PurchaseFieldMappingInfo;
//    Quantity: PurchaseFieldMappingInfo;
//    Measurment: PurchaseFieldMappingInfo;
//    Value: PurchaseFieldMappingInfo;
//    Evaluation: PurchaseFieldMappingInfo;
//    Unit: PurchaseFieldMappingInfo;
//    BudgetCode: PurchaseFieldMappingInfo;
//    Manager: PurchaseFieldMappingInfo;
//    ManagerDisplay: PurchaseFieldMappingInfo;
//    UseExtraBudgetCode: PurchaseFieldMappingInfo;
//    CustomUnit: PurchaseFieldMappingInfo;
//    Term: PurchaseFieldMappingInfo;
//    ValueShortTerm: PurchaseFieldMappingInfo;
//    ValueNeto: PurchaseFieldMappingInfo;
//    ContractContact: PurchaseFieldMappingInfo;
//}
export class PurchaseFieldMappingInfo {
    showInTypes: string[];
    labelsForTypes: {};
    required: boolean;
    type: string;
    field: string;
    format?: string;
    options?: any[];
    displayProps?: string[];
    getOptionDisplay?: any;
    parentIdAttribute?: string;
    IdAttribute?: string;
    selectAll?: boolean;
    min?: number;
    isNotValid?: boolean;
    notallowed?: any;
    maxLength?: number;
    errorMatcher?: any;
    hint?: string;
}
export class Purchase {
    Type: string;
    Title: string;
    Item: string;
    Quantity: number;
    Measurment: string;
    Value: number;
    Evaluation: string;
    Unit: Structure;
    BudgetCode: BudgetCode;
    Manager: Manager;
    UnitManager: string;
    UseExtraBudgetCode: boolean;
    CustomUnit: string;
    Term: number;
    ValueShortTerm: number;
    ValueNeto: number;
    ContractContact: Manager;
}
export class Structure {
    StructureId: string;
    ParentId: string;
    Title: string;
    ManagerId: string;
    ManagerName: string;
    ManagerSurname: string;
    ManagerDvsId: number;
    StructureDvsId: number;
    StructureUnitCode: string;
}

export class PurchaseResult {
    Purchase: Purchase;
    DvsResult: DvsResult;
}

export class DvsResult {
    Success: boolean;
    Error: string;
}

export class MyPurchase {
    Id: number;
    Type: string;
    Title: string;
    Created: Date;
    Approved: Date;
    RegNumber: string;
    SIK: string;
    DvsDocumentId: number;
}

export class Manager {
    Id: string;
    Name: string;
    DvsId: number;
    HorizonId: number;
    Surname: string;
}

export class BudgetCode {
    Code: string;
    DisplayTitle: string;
    DvsSikId: number;
    HorizonSikId: number;
    SikDisplayTitle: string;
    AccountName: string;
}
