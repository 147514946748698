<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/skills', text: 'skills_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'skills_title' | translate}}</h1>
        <aside *ngIf="canPickStudent && !showStudentPicker">
            <button type="button" class="btn btn-outline" (click)="showStudentPicker = !showStudentPicker">
                <i class="fi fi-rs-user"></i>
                {{'onCallShift_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="showStudentPicker" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="!showStudentPicker">
        <div class="data-value" *ngIf="student">
            <label>{{'skills_lblStudent' | translate}}</label>
            <div>{{student.FirstName}} {{student.LastName}} ({{student.StudentId}})</div>
        </div>

        <app-alert level="info" *ngIf="infoTextTop && !showStudentPicker">
            <div [innerHTML]="infoTextTop | safeHtml"></div>
        </app-alert>

        <section class="block">
            <form #filterForm="ngForm">
                <div class="form-field">
                    <div>
                        <app-checkbox [(ngModel)]="showOnlyHighestLevel" name="showOnlyHighestLevel" (stateChange)="filter()">
                            {{'skills_filterShowOnlyHighestLevel' | translate}}
                        </app-checkbox>
                    </div>

                    <div class="mt-1">
                        <app-checkbox [(ngModel)]="showOnlyAcquired" name="showOnlyAcquired" (stateChange)="filter()">
                            {{'skills_filterShowOnlyAcquired' | translate}}
                        </app-checkbox>
                    </div>

                    <div class="mt-1">
                        <app-checkbox (stateChange)="selectAllRows($event)" name="allRowsSelected" [ngModel]="allRowsSelected">
                            {{'skills_selectAllSkills' | translate}}
                        </app-checkbox>
                    </div>
                </div>

                <div class="row hidden-md-down">
                    <div class="col">
                        <app-select [(ngModel)]="filterCategory" [placeholder]="'skills_filterCategory' | translate" name="filterCategory"
                                    (selectionChange)="filter()" [options]="categories">
                        </app-select>
                    </div>

                    <div class="col">
                        <app-select [(ngModel)]="filterLevel" [placeholder]="'skills_filterLevel' | translate" name="filterLevel"
                                    (selectionChange)="filter()" [options]="levels">
                        </app-select>
                    </div>

                    <div class="col">
                        <button type="button" class="btn btn-outline" (click)="clearFilters()">
                            <i class="fi fi-rs-cross"></i>
                            {{'skills_btnClearFilters' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </section>

        <section class="block">
            <app-table [rows]="skills" [columns]="columns">
                <ng-template #firstHeadTemplate>
                    <app-checkbox (stateChange)="selectAllRows($event)" [ngModel]="allRowsSelected"></app-checkbox>
                </ng-template>

                <ng-template #rowTemplate let-row>
                    <tr>
                        <td>
                            <app-checkbox (stateChange)="selectRow(row)" [ngModel]="rowSelected(row)" [disabled]="!row.IsAcquirable"></app-checkbox>
                        </td>
                        <td>{{row.Name}}</td>
                        <td class="hidden-sm-down">
                            <a href="#" (click)="$event.preventDefault(); showDetails(row);">{{row.Level}}</a>
                        </td>
                        <td class="hidden-sm-down">{{row.Code}}</td>
                        <td class="hidden-md-down">{{row.Category}}</td>
                        <td class="hidden-md-down">
                            <a target="_blank" href="{{row.MoodleLink}}">{{row.SubjectCode}}</a>
                        </td>
                        <td>
                            <div class="table-actions">
                                <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'skills_showDetails' | translate">
                                    <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isExpanded(row)" class="table-row-details">
                        <td colspan="9">
                            <div class="dl">
                                <div class="hidden-md-up">
                                    <span>{{'skills_lblLevel' | translate}}</span>
                                    <span><a href="#" (click)="$event.preventDefault(); showDetails(row);">{{row.Level}}</a></span>
                                </div>

                                <div class="hidden-md-up">
                                    <span>{{'skills_lblSkillCode' | translate}}</span>
                                    <span>{{row.Code}}</span>
                                </div>

                                <div class="hidden-lg-up">
                                    <span>{{'skills_lblCategory' | translate}}</span>
                                    <span>{{row.Category}}</span>
                                </div>

                                <div class="hidden-lg-up">
                                    <span>{{'skills_lblSubjectCode' | translate}}</span>
                                    <span><a target="_blank" href="{{row.MoodleLink}}">{{row.SubjectCode}}</a></span>
                                </div>

                                <div>
                                    <span>{{'skills_lblSubjectName' | translate}}</span>
                                    <span>{{row.SubjectName}}</span>
                                </div>

                                <div>
                                    <span>{{'skills_lblValue' | translate}}</span>
                                    <span>{{row.Value}}</span>
                                </div>

                                <div>
                                    <span>{{'skills_lblDate' | translate}}</span>
                                    <span>{{row.LearnDate | appDate}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </app-table>

            <div class="block-actions">
                <button type="button" class="btn btn-primary btn-icon-last" (click)="createReference()" [disabled]="!hasSelected">
                    {{'skills_btnReference' | translate}}
                    <i class="fi fi-rs-angle-right"></i>
                </button>
            </div>
        </section>

        <app-alert level="info" *ngIf="infoTextBottom && !showStudentPicker">
            <div [innerHTML]="infoTextBottom | safeHtml"></div>
        </app-alert>
    </ng-container>
</div>

