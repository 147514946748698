import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';

import { EmailEntry, EmailAliasConfig } from '../models/EmailAlias';

/**
 * Email Alias service
 */
@Injectable({ providedIn: "root" })
export class EmailAliasService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/emailalias` }

    /**
     * Get user email alias list.
     * @param userEmail User email to authorize with
     */
    get(userEmail?: string): Observable<EmailEntry[]> {
        return this.http.get<EmailEntry[]>(this.apiUrl, { params: { userEmail: userEmail } });
    } 

    /**
     * Add a new email alias for the current user.
     * @param email
     * @param isPrimary
     * @param userEmail User email to authorize with
     */
    add(email: string, isPrimary?: boolean, userEmail?: string): Observable<EmailEntry> {
        const body = {
            email: email,
            isPrimary: isPrimary
        };
        return this.http.post<EmailEntry>(this.apiUrl, body, {
            params: {
                userEmail: userEmail
            }
        });
    }

    /**
     * Set email address as primary.
     * @param email
     * @param userEmail User email to authorize with
     */
    setPrimary(email: string, userEmail?: string): Observable<boolean> {
        const url = `${this.apiUrl}/setPrimary?email=${email}`;
        return this.http.put<boolean>(url, { email: email }, {
            params: {
                userEmail: userEmail
            }
        });
    }

    /**
     * Check if email address is available.
     * @param email
     * @param userEmail User email to authorize with
     */
    isAvailabile(email: string, userEmail?: string): Observable<boolean> {
        const url = `${this.apiUrl}/isAvailable`;
        return this.http.get<boolean>(url, {
            params: {
                email: email,
                userEmail: userEmail
            }
        });
    }

    /**
     * Validate an email address.
     * @param email
     * @param userEmail User email to authorize with
     */
    validate(email: string, userEmail?: string): Observable<string[]> {
        const url = `${this.apiUrl}/validate`;
        return this.http.get<string[]>(url, {
            params: {
                email: email,
                userEmail: userEmail
            }
        });
    }

    /**
     * Get the configuration.
     */
    getConfig(): Observable<EmailAliasConfig> {
        const url = `${this.apiUrl}/config`;
        return this.http.get<EmailAliasConfig>(url);
    }

    /**
     * Get email alias rules text.
     */
    getRules(): Observable<string> {
        const url = `${this.apiUrl}/rules`;
        return this.http.get<string>(url);
    }
}
