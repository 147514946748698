<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body">
    <app-table [rows]="files" [columns]="columns">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    <a href="{{row.url}}" target="_blank">{{row.name}}</a>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="close()">{{'close' | translate}}</button>
</div>
