<div class="checkbox {{id}}"
     [id]="id"
     [class.is-checked]="isChecked"
     [class.is-disabled]="disabled"
     (click)="onChange()"
     (keydown.space)="$event.stopPropagation(); $event.preventDefault(); onChange()"
     tabindex="0">
    <span class="checkbox-icon">
        <i class="fi fi-rs-check" *ngIf="isChecked"></i>
    </span>
    <span class="checkbox-label">
        <ng-content></ng-content>
    </span>
</div>
