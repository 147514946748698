import { Component, Input, OnInit } from '@angular/core';

import { ITableColumn } from '../../shared/table/table.component';
import { IFinalPaperReviewListItem, IFinalPaperYear } from '../../models/FinalPaperReview';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface IRow extends IFinalPaperReviewListItem {
    student: string;
    programme: string;
    supervisor: string;
}

type Role = 'all' | 'supervisor' | 'reviewer';

@Component({
    selector: 'app-final-paper-review-list',
    templateUrl: './final-paper-review-list.component.html'
})
export class FinalPaperReviewListComponent implements OnInit {
    @Input() set data(items: IFinalPaperReviewListItem[]) {
        const faculties: string[] = [];
        const programs: { code: string, name: string }[] = [];
        const types: string[] = [];

        this.allItems = items.map(t => {
            const program = t.StudyProgram + (t.StudyProgramCode ? ', ' + t.StudyProgramCode : '');

            if (!types.includes(t.ThesisType)) types.push(t.ThesisType);
            if (!faculties.includes(t.Faculty)) faculties.push(t.Faculty);
            if (!programs.some(n => n.code == t.StudyProgramCode)) programs.push({ code: t.StudyProgramCode, name: program });

            return {
                ...t,
                programme: program,
                student: `${t.Name} ${t.Surname}`,
                supervisor: `${t.SupervisorName} ${t.SupervisorSurname}`
            };
        });

        this.options.faculty = faculties;
        this.options.program = programs;
        this.options.type = types;

        if (this.selectedFaculty && !faculties.includes(this.selectedFaculty)) this.selectedFaculty = undefined;
        if (this.selectedType && !types.includes(this.selectedType)) this.selectedType = undefined;
        if (this.selectedProgram && !programs.some(t => t.code == this.selectedProgram.code)) this.selectedProgram = undefined;

        this.filter();
    }

    readonly columns: ITableColumn[] = [
        { property: 'student', label: 'finalPaperReview_lblStudent', sorts: true },
        { property: 'Faculty', label: 'finalPaperReview_lblFacultyStudyProgramme', sorts: true },
        { property: 'Title', label: 'finalPaperReview_lblTitle', sorts: true },
        { property: 'supervisor', label: 'finalPaperReview_lblSupervisor', sorts: true },
        { property: 'ThesisType', label: 'finalPaperReview_lblThesisType', sorts: true },
        { property: 'Status', label: 'finalPaperReview_lblStatus', sorts: true },
        { width: '1px' }
    ];

    items: IRow[] = [];

    selectedYear: IFinalPaperYear;
    selectedFaculty: string;
    selectedProgram: { code: string, name: string };
    selectedType: string;
    selectedRole: Role = 'all';
    searchValue: string;
    page: number = 1;


    readonly options: {
        year: IFinalPaperYear[],
        program: { code: string, name: string }[],
        type: string[],
        faculty: string[]
    } = {
            year: [],
            program: [],
            type: [],
            faculty: []
        }; 

    private allItems: IRow[] = [];
    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            this.filter();
        });
    }

    search() {
        this.searchSubj.next(this.searchValue);
    }

    filter() {
        this.page = 1;

        if (this.filterEmpty()) {
            this.items = this.allItems;
        } else {
            const sv = this.searchValue?.toLowerCase();

            this.items = this.allItems.filter(t => {
                if (this.selectedFaculty && t.Faculty != this.selectedFaculty) return false;
                if (this.selectedProgram && t.StudyProgramCode != this.selectedProgram.code) return false;
                if (this.selectedType && t.ThesisType != this.selectedType) return false;

                if (this.selectedRole === 'supervisor' && !t.IsSupervisor) return false;
                if (this.selectedRole === 'reviewer' && !t.IsReviewer) return false;

                if (sv) {
                    return (t.Faculty.toLowerCase().includes(sv)
                        || t.Name.toLowerCase().includes(sv)
                        || t.Status.toLowerCase().includes(sv)
                        || t.programme.toLowerCase().includes(sv)
                        || t.supervisor.toLowerCase().includes(sv)
                        || t.student.toLowerCase().includes(sv)
                        || t.ThesisType.toLowerCase().includes(sv)
                        || t.Title.toLowerCase().includes(sv)
                    );
                }

                return true;
            });
        }
    }

    changeRole(role: Role) {
        this.selectedRole = role;
        this.filter();
    }

    private filterEmpty() {
        return !this.searchValue && !this.selectedFaculty && !this.selectedProgram && !this.selectedType && this.selectedRole === 'all'
    }

}
