<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'BC_courses_eventsDialog_title' | translate}}</h4>
</div>

<div class="modal-body">
    <ng-container *ngIf="singleAddress && events.length && type=='calendar'">
        <div class="data-value">
            <label>{{'BC_courses_location_lbl' | translate}}</label>
            <div>{{event.Address}}</div>
        </div>

        <div class="data-value">
            <label>{{'BC_courses_time_lbl' | translate}}</label>
            <div>
                <div *ngFor="let e of events; let i = index">
                    <ng-container *ngIf="e.DateTime">{{e.Date | date:'EEEE' | titlecase }}, {{e.Date | appDate}}, {{e.TimeFrom | slice: 0 : 5}} - {{e.TimeTo | slice: 0 : 5}}</ng-container>
                </div>
            </div>
        </div>
    </ng-container>

    <table class="table" *ngIf="!singleAddress && events.length && type=='calendar'">
        <thead>
            <tr>
                <th>{{'BC_courses_time_lbl' | translate}}</th>
                <th>{{'BC_courses_location_lbl' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of events; let i = index">
                <td>{{e.Date | date:'EEEE' | titlecase }}, {{e.Date | appDate}}, {{e.TimeFrom}} - {{e.TimeTo | slice: 0 : 5}}</td>
                <td>{{e.Address}}</td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="info">{{info}}</div>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" (click)="close()" type="button">{{'dialog_close' | translate}}</button>
</div>
