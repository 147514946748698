<section class="block">
    <h5>{{'print_lblPrint' | translate}}</h5>

    <p>
        <a href="{{printPortalURL}}" target="_blank" (click)="showInfoMsg()">
            {{'print_lblRedirectToPrintPortal' | translate}}
            <i class="fi fi-rs-arrow-alt-circle-right"></i>
        </a>
    </p>

    <app-alert level="info" *ngIf="infoMsg">
        <div [innerHTML]="infoMsg | safeHtml"></div>
    </app-alert>
</section>
