import { Component, Input, OnInit } from '@angular/core';

import {
    ISurveyQuestion, ISurveyQuestionGroup, SurveyQuestionDisplayType,
    SurveyQuestionEvaluationType, SurveyAnswerEvaluationType, ISurveyQuestionObject
} from '../../../models/Survey';
import { SurveyUtils } from '../SurveyUtils';

@Component({
    selector: 'app-survey-question-group',
    templateUrl: './survey-question-group.component.html',
    styleUrls: ['../survey.component.scss']
})
export class SurveyQuestionGroupComponent implements OnInit {
    @Input() group: ISurveyQuestionGroup;
    @Input() questions: ISurveyQuestion[] = [];
    @Input() model: Record<string, any> = {};
    @Input() validate: boolean;

    gridQuestions: ISurveyQuestion[] = [];
    textQuestions: ISurveyQuestion[] = [];
    comboQuestions: ISurveyQuestion[] = [];
    firstQuestion: ISurveyQuestion;

    isGrid: boolean;

    readonly noObjectId = 'undefined';
    readonly util = SurveyUtils;
    readonly questionTypes = SurveyQuestionEvaluationType;
    readonly answerTypes = SurveyAnswerEvaluationType;

    ngOnInit() {
        if (this.questions.length) {
            this.firstQuestion = this.questions[0];
            this.isGrid = this.group.ComposeAllInOne && SurveyUtils.isChoiceAnswer(this.firstQuestion.AnswerEvaluationType);
        }

        this.questions.forEach(t => {
            if (SurveyUtils.isChoiceAnswer(t.AnswerEvaluationType)) {
                this.gridQuestions.push(t);
            } else if (t.AnswerEvaluationType == SurveyAnswerEvaluationType.FreeFormText) {
                this.textQuestions.push(t);
            }
        });

        this.comboQuestions = this.questions.filter(t => t.DisplayType == SurveyQuestionDisplayType.Combo);
    }

    getQuestionObjectByTitle(question: ISurveyQuestion, title: string) {
        return question.Objects.find(t => t.Title == title);
    }

    getQuestionKeyBuilder(obj?: ISurveyQuestionObject) {
        return (question: ISurveyQuestion) => {
            const extra = [];

            if (obj) {
                const objId = question.Objects.find(t => t.Title == obj.Title)?.ID || this.noObjectId;
                extra.push(objId);
            }

            return SurveyUtils.buildQuestionKey(question.ID, ...extra);
        }
    }
}
