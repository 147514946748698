import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Classifier } from '../../models/Classifier';
import { ClassifierService } from '../../services/classifier.service';
import { Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppService } from '../../services/app.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-classifier-edit',
    templateUrl: './classifier-edit.component.html'
})
export class ClassifierEditComponent {
    constructor(
        private app: AppService,
        private service: ClassifierService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() model: Classifier;

    submitted: boolean;
    isSaving: boolean;

    @ViewChild('form', { static: true }) private form: NgForm;

    save() {
        if (this.form.invalid) {
            this.submitted = true;
            return;
        }

        const success = () => {
            this.activeModal.close();
        };

        this.isSaving = true;

        const call = this.model.Id
            ? this.service.update(this.model)
            : this.service.create(this.model);

        this.app.addLoading(call, 500).pipe(finalize(() => this.isSaving = false)).subscribe(success);
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
