<div class="row">
    <div class="data-value col">
        <label>{{'seminar_attendance_lblSeminar' | translate}}</label>
        <div>
            {{data?.Topic}}
        </div>
    </div>

    <div class="data-value col">
        <label>{{'seminar_attendance_lblDate' | translate}}</label>
        <div>
            {{data?.Date ? (data.Date | appDate) : ('seminar_attendance_noDate' | translate)}}
        </div>
    </div>

    <div class="data-value col">
        <label>{{'seminar_attendance_lblManager' | translate}}</label>
        <div>
            {{data?.ManagerName}} {{data?.ManagerSurname}}
        </div>
    </div>
</div>

<section class="block">
    <header class="block-header">
        <h5>{{'seminar_attendance_students' | translate}}</h5>
        <aside>
            <input class="form-control"
                   (input)="search($event)"
                   [placeholder]="'seminar_attendance_search' | translate"
                   maxlength="50">
        </aside>
    </header>

    <form #form="ngForm">
        <app-table [rows]="rows" [columns]="columns" pageSize="50">
            <ng-template #firstHeadTemplate>
                <div [ngbTooltip]="'seminar_attendance_lblIsAttended' | translate">
                    <app-checkbox (stateChange)="checkAll($event)" [ngModel]="allChecked" name="checkAll" [disabled]="!canEdit"></app-checkbox>
                </div>
            </ng-template>
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        <div [ngbTooltip]="'seminar_attendance_lblIsAttended' | translate">
                            <app-checkbox name="isAttended_{{row.Id}}" [(ngModel)]="row.IsAttended" [disabled]="!canEdit"></app-checkbox>
                        </div>
                    </td>
                    <td>
                        <a href="{{getPlanSummaryUrl(row)}}" target="_blank">{{row.fullName}}</a>
                    </td>
                    <td>{{row.StudyProgramName}}</td>
                    <td>{{row.StudyYear}}</td>
                </tr>
            </ng-template>
        </app-table>
    </form>
</section>
