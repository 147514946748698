<h5>{{'studyQualityEvaluation_title' | translate}}</h5>

<div class="row">
    <div class="data-value col-sm">
        <label>{{'planSummary_lblResident' | translate}}</label>
        <div>
            {{resident.Name}} {{resident.Surname}}, {{residentNumber}}
        </div>
    </div>

    <div class="data-value col-sm">
        <label>{{'planSummary_lblSpeciality' | translate}}</label>
        <div>
            <ng-container *ngIf="resident.SpecialityName; else noValue">
                {{resident.SpecialityName}}
            </ng-container>
        </div>
    </div>

    <div class="data-value col-sm">
        <label>{{'planSummary_lblSupervisor' | translate}}</label>
        <div>
            <ng-container *ngIf="supervisor; else noValue">
                {{supervisor.FirstName}} {{supervisor?.LastName}}
            </ng-container>
        </div>
    </div>
</div>

<div class="year-selector">
    <div>
        <label class="form-label">{{'studyQualityEvaluation_lblStudyYear' | translate}}</label>
        <button *ngFor="let y of yearOptions"
                class="btn btn-outline{{y == selectedYear ? '-primary' : ''}}"
                type="button"
                [disabled]="isEdit && y != selectedYear"
                (click)="changeYear(y)">{{y}}</button>
    </div>
</div>

<form #form="ngForm" class="was-validated">
    <ng-container *ngIf="selectedYear">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeNavId" class="nav-tabs">
            <li [ngbNavItem]="i + 1" [disabled]="isEdit" *ngFor="let tab of tabs; let i = index">
                <a ngbNavLink class="hidden-sm-down">{{tab.title | translate}}</a>
                <ng-template ngbNavContent>
                    <ng-container [ngSwitch]="tab.id">
                        <div class="mt-3">
                            <app-plan-summary-quality-evaluation-form *ngSwitchCase="'firstHalf'"
                                                                      [data]="currentEvaluationFirstHalf"
                                                                      [model]="model.firstHalf"
                                                                      [options]="options"
                                                                      [studyYear]="selectedYear"
                                                                      [previous]="model.prevYearHalf"
                                                                      [isEdit]="isEdit"></app-plan-summary-quality-evaluation-form>

                            <app-plan-summary-quality-evaluation-form *ngSwitchCase="'secondHalf'"
                                                                      [data]="currentEvaluationSecondHalf"
                                                                      [model]="model.secondHalf"
                                                                      [options]="options"
                                                                      [studyYear]="selectedYear"
                                                                      [previous]="model.firstHalf"
                                                                      [isEdit]="isEdit"></app-plan-summary-quality-evaluation-form>
                        </div>
                    </ng-container>
                </ng-template>
            </li>
            <li ngbDropdown class="nav-item hidden-md-up" placement="bottom-start">
                <a class="nav-link" [class.active]="true" ngbDropdownToggle>
                    {{tabs[activeNavId - 1].title | translate}}
                </a>
                <div ngbDropdownMenu class="more-tab-menu">
                    <button ngbDropdownItem *ngFor="let n of tabs; let i = index" (click)="nav.select(i + 1)" [disabled]="i == activeTabId - 1">
                        {{n.title | translate}}
                    </button>
                </div>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

        <div class="mt-3" *ngIf="activeTab.info()">
            <app-alert level="info">{{activeTab.info()}}</app-alert>
        </div>

        <div class="form-actions" *ngIf="isSupervisor">
            <button class="btn btn-outline" type="button" (click)="edit()" *ngIf="!isEdit && activeTab.canEdit()">
                <i class="fi fi-rs-pencil"></i>
                {{'studyQualityEvaluation_btnEdit' | translate}}
            </button>

            <button class="btn btn-outline" type="button" (click)="cancel()" *ngIf="isEdit">
                {{'studyQualityEvaluation_btnCancel' | translate}}
            </button>

            <button class="btn btn-primary" type="button" (click)="save()" *ngIf="isEdit" [disabled]="!isValid">
                {{'studyQualityEvaluation_btnSave' | translate}}
            </button>

            <button class="btn btn-outline-primary" type="button" (click)="save(true)" *ngIf="isEdit && activeTab.canSubmit()" [disabled]="!isValid">
                <i class="fi fi-rs-paper-plane"></i>
                {{'studyQualityEvaluation_btnSaveAndSubmit' | translate}}
            </button>
        </div>
    </ng-container>
</form>

<div class="form-actions" *ngIf="isForResidencyReview">
    <button class="btn btn-outline" (click)="rejectEvaluation()" type="button">
        {{ 'studyQualityEvaluation_btnReject' | translate}}
    </button>

    <button class="btn btn-outline-primary" *ngIf="movedToNextYear" (click)="changeCondition()" type="button">
        {{ 'studyQualityEvaluation_btnChangeCondition' | translate}}
    </button>

    <button class="btn btn-primary" (click)="acceptEvaluation()" type="button">
        {{ 'studyQualityEvaluation_btnAccept' | translate}}
    </button>
</div>

<ng-template #noValue>{{'planSummary_undefined' | translate}}</ng-template>
