<app-alert level="danger" *ngIf="error">{{error}}</app-alert>

<ng-container *ngIf="isLoaded">
    <div class="row">
        <div class="col-6 form-field">
            <input class="form-control"
                   (input)="search($event)"
                   [placeholder]="'planSummary_lblSearch' | translate"
                   maxlength="50"
                   [disabled]="total < 1">
        </div>

        <div class="col-6 mb-3 text-end">
            <a *ngIf="canAdd"
               [routerLink]="['/on-call-shifts', 'add'] | localize"
               [queryParams]="residentEmail ? { email: residentEmail } : {}"
               [ngbTooltip]="'planSummary_shifts_add' | translate"
               class="btn btn-outline-primary">
                <span class="hidden-lg-up">
                    <i class="fi fi-rs-plus me-0"></i>
                </span>
                <i class="fi fi-rs-plus hidden-md-down"></i>
                <span class="hidden-md-down">
                    {{'planSummary_shifts_add' | translate}}
                </span>
            </a>

            <a *ngIf="canView"
               [routerLink]="['/on-call-shifts'] | localize"
               [queryParams]="residentUpn ? { id: residentUpn } : {}"
               [ngbTooltip]="'planSummary_shifts_list' | translate"
               class="btn btn-outline-primary ms-1">
                <span class="hidden-md-up">
                    <i class="fi fi-rs-list me-0"></i>
                </span>
                <i class="fi fi-rs-list hidden-sm-down"></i>
                <span class="hidden-sm-down">
                    {{'planSummary_shifts_list' | translate}}
                </span>
            </a>
        </div>
    </div>

    <app-alert level="info" *ngIf="total == 0">{{'planSummary_shifts_empty' | translate}}</app-alert>

    <div *ngFor="let y of shiftsByYear">
        <div class="text-top mb-2">
            <span (click)="util.toggle(y)" class="shift-year-label">
                {{y.yearName}}
                <i class="fi fi-rs-angle-circle-{{util.isExpanded(y) ? 'down' : 'right'}}"></i>
            </span>
            <span class="shift-year-stat">
                {{'planSummary_shifts_plannedHours' | translate}}: {{y.hoursPlanned}}
            </span>
            <span class="shift-year-stat">
                {{'planSummary_shifts_approvedHours' | translate}}: {{y.hoursApproved}}
            </span>
        </div>

        <app-table [columns]="columns" [rows]="y.shifts" pageSize="20" sortBy="month" *ngIf="util.isExpanded(y)">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        <strong (click)="util.toggle(row)" class="shift-month-label text-nowrap">
                            {{row.monthName}}
                            <i class="fi fi-rs-angle-circle-{{util.isExpanded(row) ? 'down' : 'right'}}"></i>
                        </strong>
                    </td>
                    <td>
                        <strong [ngbTooltip]="row.companiesApproved.join('; ')" class="month-hours">
                            {{row.hoursApproved}}
                        </strong>
                    </td>
                    <td class="hidden-sm-down"></td>
                </tr>
                <ng-container *ngIf="util.isExpanded(row)">
                    <tr *ngFor="let s of row.shifts" class="shift-row">
                        <td>
                            <div class="ps-2">
                                <a href="{{getFileUrl(s)}}" target="_blank">{{s.Date | appDate}}</a>
                                <div class="hidden-md-up text-sub">{{s.CompanyName}}</div>
                            </div>
                        </td>
                        <td>
                            <div class="status-hours text-nowrap">
                                <span class="hours-status hours-status-{{s.HoursStatusCode | lowercase}}" [ngbTooltip]="s.HoursStatusName"></span>
                                {{s.Hours}}
                            </div>
                        </td>
                        <td class="hidden-sm-down">{{s.CompanyName}}</td>
                    </tr>
                </ng-container>
            </ng-template>
        </app-table>
    </div>
</ng-container>
