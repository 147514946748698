import { Component, OnInit } from '@angular/core';

import { IParameter } from '../../models/Parameter';
import { ParameterService } from '../../services/parameter.service';
import { AppService } from '../../services/app.service';

import { ITableColumn } from '../../shared/table/table.component';
import { ParameterEditComponent } from './parameter-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-parameter-list',
    templateUrl: './parameter-list.component.html'
})
export class ParameterListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: ParameterService,
        private modal: NgbModal
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'Code', label: 'parameters_lblCode', sorts: true },
        { property: 'Value', label: 'parameters_lblValue', sorts: true },
        { width: '1px' }
    ];

    items: IParameter[] = [];

    ngOnInit() {
        this.loadItems();
    }

    edit(item: IParameter) {
        item = JSON.parse(JSON.stringify(item));

        const ref = this.modal.open(ParameterEditComponent);
        ref.componentInstance.model = item;
        ref.result.then(() => {
            this.loadItems();
        }, () => { });
    }

    private loadItems() {
        this.app.addLoading(this.service.get()).subscribe(data => {
            this.items = data;
        });
    }
}
