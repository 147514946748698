<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/streams', text: 'streams_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'streams_title' | translate}}</h1>
    </header>

    <div class="form-field">
        <label class="form-label">{{'streams_selectStream' | translate}}</label>
        <app-select [ngModel]="selectedStream"
                    [options]="shownLiveStreams"
                    [required]="true"
                    [displayProperty]="'Value'"
                    (selectionChange)="onStreamSelect($event)"></app-select>
    </div>

    <div class="mb-3" *ngIf="!infoMsg && stream && selectedStream && stream.LogoImageUrl">
        <img [src]="stream.LogoImageUrl" *ngIf="!stream.LogoLinkToOpen">
        <a *ngIf="stream.LogoLinkToOpen"
           href="{{stream.LogoLinkToOpen}}"
           target="_blank"
           [title]="app.translate.currentLang == 'lv' ? stream.LogoAltTextLV : stream.LogoAltTextEN">
            <img [src]="stream.LogoImageUrl">
        </a>
    </div>

    <ng-container *ngIf="!infoMsg && stream">
        <section class="block" *ngFor="let item of stream.Items">
            <h5>{{app.translate.currentLang == 'lv' ? item.ItemTitleLV : item.ItemTitleEN}}</h5>
            <div [innerHTML]="item.ElemHtml | safeHtml"></div>
        </section>
    </ng-container>

    <app-alert level="info" *ngIf="infoMsg">
        <div [innerHTML]="infoMsg | safeHtml"></div>
    </app-alert>
</div>
