import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { IPublishedTile } from '../../models/Tile';
import { TileService } from '../../services/tile.service';
import { ClassifierService } from '../../services/classifier.service';
import { forkJoin } from 'rxjs';
import { ClassifierType } from '../../models/Classifier';
import { getAvailablePurchaseTypes } from '../purchase/index-body.component';
import { AuthService } from '../../services/auth.service';

const cache: {
    tiles?: IPublishedTile[],
    linksByCategory?: {
        category: string,
        code: string,
        links: IPublishedTile[]
    }[]
} = {};

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private tileService: TileService,
        private classifierService: ClassifierService,
        private route: ActivatedRoute
    ) {
        this.app.removePageContainer();
    }

    readonly isEn = this.app.currentLanguage == 'en';

    showWaitMessage: string = '';
    loginUrl: string;
    isAuthenticated: boolean;
    tiles: IPublishedTile[] = [];
    linksByCategory: {
        category: string,
        code: string,
        links: IPublishedTile[]
    }[] = [];
    activeTabId: number = 1;

    get selectedCategory(): string {
        return this.linksByCategory[this.activeTabId - 1]?.category;
    }

    ngOnInit() {
        this.loginUrl = this.app.env.adfsApiRoute + '?redirecturl=/' + this.app.currentLanguage;

        this.route.queryParams.subscribe(query => {
            this.showWaitMessage = query['showWaitMessage'] || '';
        });

        this.auth.user.subscribe(user => {
            if (user) {
                this.isAuthenticated = true;

                const showPurchases = getAvailablePurchaseTypes(user).length > 0;

                if (cache.tiles) {
                    this.tiles = cache.tiles;
                    this.linksByCategory = cache.linksByCategory;
                } else {
                    const toLoad = forkJoin(
                        this.tileService.getPublished(),
                        this.classifierService.get(ClassifierType.TileCategory)
                    );

                    this.app.addLoading(toLoad).subscribe(data => {
                        const [tiles, cats] = data;

                        cats.forEach(t => {
                            try {
                                const payload = JSON.parse(t.Payload);
                                t['SortOrder'] = +payload.SortOrder;
                            } catch (err) {
                                t['SortOrder'] = -1;
                            }
                        });

                        cats.sort((a, b) => a['SortOrder'] - b['SortOrder']);

                        this.tiles = cache.tiles = tiles;

                        this.linksByCategory = cache.linksByCategory = cats.map(t => {
                            return {
                                category: t.Value,
                                code: t.Code,
                                links: tiles.filter(n => n.CategoryId == t.Id)
                            }
                        }).filter(t => t.links.length > 0 || t.code == 'Applications' || (t.code == 'Purchases' && showPurchases));
                    });
                }
            } else {
                this.isAuthenticated = false;

                cache.tiles = undefined;
                cache.linksByCategory = undefined;
            }
        });
    }

    isExternalUrl(url: string): boolean {
        return url?.toLowerCase().startsWith('http');
    }
}
