import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as ENV } from '../../environments/environment';

import { CertificateRequest } from '../models/CertificateRequest';

import { AuthService } from './auth.service';

type GetResultType = { Items: CertificateRequest[], Total: number };

/**
 * Certificate request service
 */
@Injectable({ providedIn: "root" })
export class CertificateRequestService {
    constructor(public http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/CertificateRequests` }

    get(options: { area?: string, search: string, availableFrom: string, availableTo: string }): Observable<GetResultType> {
        return this.http.get<GetResultType>(this.apiUrl, { params: options });
    }

    getEmailPreview(eventCode: string, email: string, areaCode: string): Observable<string> {
        const url = `${this.apiUrl}/emailPreview`;

        return this.http.post<string>(url, {
            EventCode: eventCode,
            Email: email,
            Area: areaCode
        });
    }

    getUploadTemplateUrl(): string {
        return `${this.apiUrl}/UploadTemplate?SessionKey=${this.auth.sessionKey}`;
    }

    upload(file: File, areaCode: string): Observable<boolean> {
        const url = `${this.apiUrl}/upload`;

        let formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('areaCode', areaCode);

        return this.http.post<boolean>(url, formData);
    }

    sendEmails(eventCode: string, certificateArea: string): Observable<any> {
        const url = `${this.apiUrl}/sendEmails`;
        return this.http.post(url, {
            EventCode: eventCode,
            Area: certificateArea
        });
    }

    download(id: string, fromDb?: boolean): Observable<any> {
        const url = `${this.apiUrl}/download/${id}`;
        const params: any = { fromDb };
        return this.http.get(url, {
            responseType: 'blob' as 'json',
            params
        }).pipe(map((t: Response) => t.blob()));
    }

    getDownloadUrl(id: string, fromDb?: boolean): string {
        return `${this.apiUrl}/download/${id}?SessionKey=${this.auth.sessionKey}&fromDb=${fromDb}`;
    }

    delete(id: string): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url).pipe(map(t => t));
    }
}
