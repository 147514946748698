<div class="modal-header">
    <h4 class="modal-title">{{'address_modalTitle' | translate}}</h4>
</div>

<div class="modal-body">
    <div class="form-field required">
        <label class="form-label">{{'address_lblCountry' | translate}}</label>
        <app-select [(ngModel)]="country"
                    name="country"
                    [required]="true"
                    [options]="countries"
                    [filter]="true"
                    [filterFn]="countryFilterFn"
                    [displayProperty]="'Name'"
                    (selectionChange)="onCountryChange()">
        </app-select>
    </div>

    <ng-container *ngIf="country && !model.IsForeign">
        <div class="form-field">
            <label class="form-label">{{'address_lblCity' | translate}}</label>
            <app-select [(ngModel)]="model.City"
                        name="city"
                        [options]="cities.items"
                        [displayFn]="addressUnitDisplayFn"
                        [filter]="true"
                        [filterFn]="cities.filterFn"
                        (selectionChange)="onUnitChange('city')">
            </app-select>
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblDistrict' | translate}}</label>
            <app-select [(ngModel)]="model.District"
                        name="district"
                        [options]="districts.items"
                        [displayFn]="addressUnitDisplayFn"
                        [filter]="true"
                        [filterFn]="districts.filterFn"
                        (selectionChange)="onUnitChange('district')">
            </app-select>
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblParish' | translate}}</label>
            <app-select [(ngModel)]="model.Parish"
                        name="parish"
                        [options]="parishes.items"
                        [displayFn]="addressUnitDisplayFn"
                        [filter]="true"
                        [filterFn]="parishes.filterFn"
                        (selectionChange)="onUnitChange('parish')">
            </app-select>
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblVillage' | translate}}</label>
            <app-select [(ngModel)]="model.Village"
                        name="village"
                        [options]="villages.items"
                        [displayFn]="addressUnitDisplayFn"
                        [filter]="true"
                        [filterFn]="villages.filterFn"
                        (selectionChange)="onUnitChange('village')">
            </app-select>
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblStreet' | translate}}</label>
            <app-select [(ngModel)]="model.Street"
                        name="street"
                        [options]="streets.items"
                        [displayFn]="addressUnitDisplayFn"
                        [filter]="true"
                        [filterFn]="streets.filterFn"
                        (selectionChange)="onUnitChange('street')">
            </app-select>
        </div>

        <div class="row">
            <div class="form-field col">
                <label class="form-label">{{'address_lblHouse' | translate}}</label>
                <app-select [(ngModel)]="model.House"
                            name="house"
                            [options]="houses.items"
                            [displayFn]="addressUnitDisplayFn"
                            [filter]="true"
                            [filterFn]="houses.filterFn"
                            (selectionChange)="onUnitChange('house')">
                </app-select>
            </div>

            <div class="form-field col">
                <label class="form-label">{{'address_lblApartment' | translate}}</label>
                <app-select [(ngModel)]="model.Apartment"
                            name="apartment"
                            [options]="apartments.items"
                            [displayFn]="addressUnitDisplayFn"
                            [filter]="true"
                            [filterFn]="apartments.filterFn"
                            (selectionChange)="onUnitChange('apartment')">
                </app-select>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="country && model.IsForeign">
        <div class="form-field">
            <label class="form-label">{{'address_lblRegion' | translate}}</label>
            <input class="form-control" [(ngModel)]="model.Region.Value" name="region" maxlength="30" />
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblCity' | translate}}</label>
            <input class="form-control" [(ngModel)]="model.City.Value" name="city" maxlength="30" />
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblHouseStreet' | translate}}</label>
            <input class="form-control" [(ngModel)]="model.Street.Value" name="street" maxlength="50" />
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblApartment' | translate}}</label>
            <input class="form-control" [(ngModel)]="model.Apartment.Value" name="apartment" maxlength="10" />
        </div>

        <div class="form-field">
            <label class="form-label">{{'address_lblPostCode' | translate}}</label>
            <input class="form-control" [(ngModel)]="model.PostCode" name="postCode" maxlength="10" />
        </div>
    </ng-container>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">{{'dialog_cancel' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="ok()" [disabled]="!isValid">{{'dialog_ok' | translate}}</button>
</div>
