export enum FinalPaperStatus {
    Draft = 'Draft',
    Submitted = 'Submitted',
    UploadedDspace = 'UploadedDspace',
    Failed = 'Failed',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

export enum FinalPaperFileType {
    FinalPaper = 'FinalPaper',
	Appendix = 'Appendix',
	Permission = "Permission",
    Affirmation = 'Affirmation'
}

export class FinalPaper {
    Id: number;
    Status: FinalPaperStatus = FinalPaperStatus.Draft;
    Name: string;
    Surname: string;
    StudentId: string;
    StudyDirection: string;
    Email: string;
    City: string;
    Faculty: string; 
    StudyProgram: string;
    StudyProgramCode: string;
    StudySubjectId: string;
    Language: string;
    LanguageOther: string;
    ThesisType: string;
    DefenseDate?: Date;
    UploadDueDate?: Date;
    TitleLV: string;
    TitleEN: string;
    TitleOther: string;
    AnnotationLV: string;
    AnnotationEN: string;
    AnnotationOther: string;
    TagsLV: string;
    TagsEN: string;
    TagsOther: string;
    IsAuthentic: boolean;
    IsRestricted: boolean;
    IsPublic: boolean;
    IsPlagiarismCheckAccepted: boolean;
    AuthenticityText: string;
    RestrictionText: string;
    PublicText: string;
    Files: FinalPaperFile[] = [];
    Supervisors: FinalPaperUser[] = [];
    SecondSupervisors: FinalPaperUser[] = [];
    Consultants: FinalPaperUser[] = [];
}

export class FinalPaperUser {
    Id: string;
    Name: string;
    Surname: string;
}

export class FinalPaperFile {
    Id: number;
    FileName: string;
    ContentLength: number;
    Uploaded: Date;
    Type: FinalPaperFileType;
    File: File;
}

export class StudentData {
    PersonId: string;
    Name: string;
    Surname: string;
    Email: string;
    StudentId: string;
    StudentUpn: string;
    ProgramCode: string;
    ProgramName: string;
    ProgramNameEN: string;
    FacultyName: string;
    FacultyNameEN: string;
    StudyDirection: string;
    StudyDirectionEN: string;
    StudyProgramCity: string;
    StudyProgramCityEN: string;
    ProgramLanguage: string;
    StudySubjectId: string;
    FinalExamType: string;
    FinalExamTypeEN: string;
    FinalWorkName: string;
    FinalWorkNameEN: string;
    FinalWorkNameOther: string;
    DefenseDate: Date;
    SupervisorId: string;
    SupervisorName: string;
    SupervisorSurname: string;
    SecondSupervisorId?: string;
    SecondSupervisorName?: string;
    SecondSupervisorSurname?: string;
    ConsultantId?: string;
    ConsultantName?: string;
    ConsultantSurname?: string;
    FinalWorkLanguage: string;
    FinalWorkLanguageOther: string;
    CanEdit: boolean;
    CanEditOtherLanguage: boolean;
}
