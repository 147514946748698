import { ClassifierService } from './../../services/classifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentTemplate, DocumentTemplateType } from '../../models/Template';

import { AppService } from '../../services/app.service';
import { TemplateService } from '../../services/template.service';
import { NgForm } from '@angular/forms';
import { createFileStub } from '../../shared/file/file.component';

interface IUserRight {
    Code: string;
    Text: string;
}

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplateEditComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: TemplateService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private classifiers: ClassifierService
    ) { }

    item: DocumentTemplate = new DocumentTemplate();
    types: DocumentTemplateType[] = [];
    title: string;
    isNew: boolean;
    submitted: boolean;
    userRights: IUserRight[] = [];
    fileLv: File;
    fileEn: File;

    readonly fileFormats = ['.docx', '.xlsx'];
    readonly fileFormatsDisplay = this.fileFormats.map(t => t.substr(1).toUpperCase()).join(', ');

    readonly typeDisplayFn = (type: DocumentTemplateType) => {
        return type ? this.app.translate(`documentTemplateType_${type}`) : '';
    }

    @ViewChild('form', { static: false }) private form: NgForm;

    ngOnInit() {
        this.route.params.subscribe(para => {
            const id = para['id'];

            if (!id) {
                this.isNew = true;
            } else {
                this.app.addLoading(this.service.get(id)).subscribe(data => {
                    this.item = data;
                    this.title = data.Title;
                    this.fileLv = createFileStub(data.FileName);
                    this.fileEn = createFileStub(data.FileName);
                });
            }
        });

        const user = this.app.currentUser;

        if (user) {
            if (user.hasRight('ES_MAIN.TEMPLATES.APPLICATION'))
                this.types.push(DocumentTemplateType.Application);

            if (user.hasRight('ES_MAIN.TEMPLATES.PURCHASE'))
                this.types.push(DocumentTemplateType.Purchase);

            if (user.hasRight('ES_MAIN.TEMPLATES.CERT_REQUEST') || user.hasRight('ES_MAIN.TEMPLATES.CERT_REQUEST_BY_UDR'))
                this.types.push(DocumentTemplateType.CertificateRequest);

            if (user.hasRight('ES_MAIN.TEMPLATES.FINAL_PAPER_AFFIRMATION'))
                this.types.push(DocumentTemplateType.FinalPaperAffirmation);

            if (user.hasRight('ES_MAIN.TEMPLATES.ON_CALL_SHIFT_REPORT'))
                this.types.push(DocumentTemplateType.OnCallShiftReport);

            if (user.hasRight('ES_MAIN.TEMPLATES.MANIPULATION_REPORT'))
                this.types.push(DocumentTemplateType.ManipulationReport);

            if (user.hasRight('ES_MAIN.TEMPLATES.ADD_GRADES_REPORT'))
                this.types.push(DocumentTemplateType.AddGradesReport);
        } else {
            this.types = [];
        }

        this.app.addLoading(this.classifiers.getUDR(this.translate.currentLang)).subscribe(data => {
            this.userRights = data.filter(r => r.Code.startsWith("UDR"));
        });
    }

    onFileChange(file: File, language: 'lv' | 'en') {
        let filename = file?.name;

        if (language == 'en') {
            if (!file) {
                this.item.FileEn = undefined;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    const res = <string>reader.result;
                    this.item.FileEn = <any>res.substring(res.lastIndexOf(',') + 1);
                };
            }
        } else {
            if (!file) {
                this.item.FileLv = undefined;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    const res = <string>reader.result;
                    this.item.FileLv = <any>res.substring(res.lastIndexOf(',') + 1);
                };
            }
        }

        if (!this.item.FileName && filename) {
            this.item.FileName = filename;
        } else if (!this.fileLv?.name && !this.fileEn?.name) {
            this.item.FileName = undefined;
        }
    }

    getFileUrl(item: DocumentTemplate, language: string) {
        return this.service.getFileUrl(item.Id, language);
    }

    save() {
        if (!this.form.valid) {
            this.submitted = true;
            this.app.alert.warning(this.app.translate('invalidFormWarning'));
            return;
        }

        const call = this.item.Id
            ? this.service.update(this.item)
            : this.service.add(this.item);

        this.app.addLoading(call).subscribe(() => {
            this.app.navigateByUrl('/templates');
        });
    }
}
