import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Skill, SkillDetails } from '../models/Skill';

import { environment as ENV } from '../../environments/environment';

/**
 * Student skill service
 */
@Injectable({ providedIn: "root" })
export class SkillService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/skills` }

    /**
     * Get student skill list.
     * @param studentEmail If not specified, current user email will be used.
     */
    getSkills(studentEmail?: string): Observable<Skill[]> {
        const query: any = {};

        if (studentEmail)
            query.email = studentEmail;

        return this.http.get<Skill[]>(this.apiUrl, { params: query });
    }

    /**
     * Get student skill details.
     * @param skillId
     * @param studentEmail If not specified, current user email will be used.
     */
    getDetails(skillId: string, studentEmail?: string): Observable<SkillDetails> {
        const url = `${this.apiUrl}/${skillId}`;
        const query: any = {};

        if (studentEmail)
            query.email = studentEmail;

        return this.http.get<SkillDetails>(url, { params: query });
    }
}
