import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '../../../services/app.service';

import { ITableColumn } from '../../../shared/table/table.component';
import { DoctorService } from '../../../services/doctor.service';
import { IDoctorSeminar } from '../../../models/Doctor';
import { SeminarAttendanceModalComponent } from '../../seminar/attendance/seminar-attendance-modal.component';
import { Utils } from '../../../core/Utils';
import { DoctorDashboardSeminarEditComponent } from './doctor-dashboard-seminar-edit.component';
import { store } from '../store';

interface IOption<T> {
    value: T;
    label: string;
}

interface IRow extends IDoctorSeminar {
    dateTime?: Date;
}

const cache: {
    seminars: IDoctorSeminar[],
    user: string
} = {
    seminars: undefined,
    user: undefined
};

@Component({
    selector: 'app-doctor-dashboard-seminars',
    templateUrl: './doctor-dashboard-seminars.component.html'
})
export class DoctorDashboardSeminarsComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: DoctorService,
        private modal: NgbModal
    ) { }

    @Input() set academicYearId(value: string) {
        this._academicYearId = value;
        this.fetch();
    }

    /**
     * Seminar ID to automatically open attendace for
     */
    @Input() openAttendanceFor: string;

    readonly columns: ITableColumn[] = [
        { property: 'Theme', label: 'doctorDashboard_seminars_lblTheme', sorts: true },
        { property: 'DateTime', label: 'doctorDashboard_seminars_lblDateTime', sorts: true, type: 'date' },
        { property: 'Place', label: 'doctorDashboard_seminars_lblPlace', sorts: true },
        { property: 'Speciality', label: 'doctorDashboard_seminars_lblSpeciality', sorts: true },
        { width: '1px' }
    ];

    rows: IRow[] = [];
    canViewAttendance: boolean;

    filters: {
        year: {
            options: IOption<string>[],
            value?: string
        }
    } = {
            year: { options: [] }
        };

    get academicYearId(): string {
        return this._academicYearId;
    }

    private _academicYearId: string;

    ngOnInit() {
        this.canViewAttendance = this.app.currentUser.rights.includes('SEMINAR.VIEW_ATTENDANCE');
    }

    edit(seminar: IDoctorSeminar) {
        const ref = this.modal.open(DoctorDashboardSeminarEditComponent, { size: 'lg' });
        ref.componentInstance.data = seminar;
        ref.result.then(() => {
            this.fetch(true);
        }, () => { });
    }

    openAttendance(seminar: IDoctorSeminar) {
        const ref = this.modal.open(SeminarAttendanceModalComponent, { size: 'lg' });
        ref.componentInstance.seminarId = seminar.Id;
        ref.result.then(() => {
            // do nothing
        }, () => { });
    }

    private fetch(force: boolean = false) {
        const process = (data: IDoctorSeminar[]) => {
            const byYear = this.filterByAcademicYear(data);

            byYear.forEach(t => {
                t.DateTime = Utils.ensureDate(t.DateTime);
            });

            this.rows = byYear;

            if (this.canViewAttendance && this.openAttendanceFor) {
                const seminar = this.rows.find(t => t.Id == this.openAttendanceFor);

                if (seminar) {
                    this.openAttendance(seminar);
                }
            }
        };

        if (!force && cache.seminars && cache.user == store.getPerson()?.Upn) {
            process(cache.seminars);
        } else {
            cache.seminars = [];
            cache.user = store.getPerson()?.Upn;
            
            this.app.addLoading(this.service.getSeminars(store.getPerson()?.Upn)).subscribe(data => {
                cache.seminars = data;
                process(data);
            });
        }
    }

    private filterByAcademicYear(data: IDoctorSeminar[]) {
        return data.filter(t => t.AcademicYearId == this.academicYearId);
    }
}
