import { AfterViewInit, ContentChild, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgZone } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Utils } from '../../core/Utils';

export interface ITableColumn {
    property?: string;
    label?: string;
    sorts?: boolean;
    /**
     * Custom sort function.
     * @param {any} a Sorting item
     * @param {any} b Sorting item
     * @returns {number | number[]} Sorting result number (as ASC).
     * If you want the sorting item to be "sticky", return [1] to always put it at the end, [-1] to put at the start.
     * */
    sortFn?: (a, b) => number | number[];
    type?: 'string' | 'number' | 'date' | 'boolean';
    width?: string;
    cssClass?: string;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit, AfterViewInit {
    constructor(private zone: NgZone) { }

    @Input() page: number = 1;
    @Input() pageSize: number = 10;
    @Input() sortBy: string;
    @Input() sortDir: 'asc' | 'desc';
    @Input() columns: ITableColumn[] = [];
    @Input() rows: T[] = [];
    @Input() sticky?: 'left' | 'right';
    @Input() wrap: boolean = true;
    @Input() showTotal: boolean = true;

    @Output() pageChange = new EventEmitter<number>();

    @ContentChild('headTemplate', { static: true }) headTemplate: TemplateRef<any>;
    // TODO would be great to be able to set template for any header cell
    @ContentChild('firstHeadTemplate', { static: true }) firstHeadTemplate: TemplateRef<any>;
    @ContentChild('lastHeadTemplate', { static: true }) lastHeadTemplate: TemplateRef<any>;
    @ContentChild('rowTemplate', { static: true }) rowTemplate: TemplateRef<any>;

    @ViewChild('wrapper', { static: true }) private wrapperElement: ElementRef<HTMLElement>;

    scrolls?: boolean;

    ngOnInit() {
        if (this.sortBy) {
            const col = this.columns.find(t => t.property == this.sortBy);

            if (col) {
                this.sort(col);
            }
        }
    }

    ngAfterViewInit() {
        this.zone.onStable.subscribe(() => {
            this.checkScroll();
        });
    }

    onScroll(event) {
        this.checkScroll();
    }

    onPageChange() {
        this.pageChange.emit(this.page);
    }

    sort(column: ITableColumn, direction?: 'asc' | 'desc') {
        if (!column.sorts) return;

        if (column.property != this.sortBy) {
            this.sortBy = column.property;
            this.sortDir = direction || 'asc';
        } else {
            this.sortDir = direction || (this.sortDir == 'asc' ? 'desc' : 'asc');
        }

        let sortFn: (a, b) => number | number[] = column.sortFn;
        let sortItemAccessor: (item: T) => any;

        if (!sortFn) {
            sortItemAccessor = item => item[this.sortBy];

            switch (column.type) {
                case 'number': sortFn = (a: number, b: number) => a - b; break;
                case 'date': sortFn = (a: Date, b: Date) => Utils.ensureDate(a)?.getTime() - Utils.ensureDate(b)?.getTime(); break;
                case 'boolean': sortFn = (a: boolean, b: boolean) => +a - +b; break;
                case 'string':
                default: sortFn = (a: string, b: string) => (a || '').localeCompare(b || ''); break;
            }
        } else {
            sortItemAccessor = item => item;
        }

        this.rows = [...this.rows.sort((a, b) => {
            const pa = sortItemAccessor(a);
            const pb = sortItemAccessor(b);

            let res = sortFn(pa, pb);

            if (typeof res == 'object') {
                return res[0];
            }

            return res * (this.sortDir == 'desc' ? -1 : 1);
        })];
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        this.checkScroll();
    }

    private checkScroll() {
        if (!this.sticky) {
            return;
        }

        const el = this.wrapperElement?.nativeElement;

        if (el) {
            const cw = el.clientWidth;
            const sw = el.scrollWidth;

            this.scrolls = sw > cw;
        }
    }
}
