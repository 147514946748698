<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/tiles', text: 'tiles_listTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'tiles_listTitle' | translate}}</h1>
        <aside *ngIf="canEdit">
            <button type="button" class="btn btn-outline-primary" [routerLink]="['/tiles/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-table [rows]="items" [columns]="columns" [sticky]="'right'" [pageSize]="20">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    <div>{{translateProperty(row, 'Title')}}</div>
                    <div class="text-sub">{{translateProperty(row, 'Description')}}</div>
                </td>
                <td>
                    {{row.Category?.Value}}
                </td>
                <td>
                    <span class="badge"
                          [class.text-bg-success]="row.Status == 'Published'"
                          [class.text-bg-secondary]="row.Status == 'Draft'">
                        {{'tiles_status' + row.Status | translate}}
                    </span>
                </td>
                <td>{{'tiles_size' + tileSize[row.Size] | translate}}</td>
                <td>
                    <img *ngIf="row.Image" [src]="row.Image" class="tile-list-img" />
                </td>
                <td>
                    <span *ngIf="row.Url?.startsWith('#'); else url">{{row.Url}}</span>
                    <ng-template #url>
                        <a href="{{row.Url}}" target="_blank">{{row.Url}}</a>
                    </ng-template>
                </td>
                <td>
                    <div class="table-actions">
                        <button type="button" *ngIf="canEdit" [routerLink]="['/tiles', row.Id] | localize" class="btn btn-outline btn-icon"
                                [ngbTooltip]="'edit' | translate">
                            <i class="fi fi-rs-pencil"></i>
                        </button>

                        <button type="button" *ngIf="canEdit" (click)="delete(row)" class="btn btn-outline btn-icon"
                                [ngbTooltip]="'delete' | translate">
                            <i class="fi fi-rs-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
