import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeTableInfo, TimeTableProgram, TimeTableSemester, TimeTableView } from '../models/TimeTable';
import { UserType } from '../models/UserInfo';

import { environment as ENV } from '../../environments/environment';

/**
 * Time table service
 */
@Injectable({ providedIn: "root" })
export class TimeTableService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/timetable` }

    /**
     * Get programs.
     * @param email Target student email. If not specified or insufficient permissions, current user email will be used.
     */
    getPrograms(email?: string): Observable<TimeTableProgram[]> {
        const url = `${this.apiUrl}/programs${email ? `?email=${email}` : ''}`;
        return this.http.get<TimeTableProgram[]>(url);
    } 

    /**
     * Get semesters.
     * @param key Depending on selected user type, it's student program key or employee email
     * @param userType
     */
    getSemesters(key: string, userType: UserType): Observable<TimeTableSemester[]> {
        const url = `${this.apiUrl}/semesters`;
        return this.http.get<TimeTableSemester[]>(url, { params: { key, userType } });
    } 

    /**
     * Get time table info.
     * @param key Depending on selected user type, it's student program key or employee email
     * @param userType
     * @param periodId
     * @param view
     * @param full
     */
    getInfo(
        key: string,
        userType: UserType,
        periodId: string,
        view: TimeTableView = TimeTableView.Daily,
        full: boolean = false
    ): Observable<TimeTableInfo[]> {
        const url = `${this.apiUrl}/info`;
        const params: any = {
            key,
            userType,
            periodId,
            view,
            full
        };

        return this.http.get<TimeTableInfo[]>(url, { params });
    } 

    /**
     * Get time table as PDF.
     * @param data
     */
    getPdf(data: { fileName: string, title: string, subTitle: string, events: string }): Observable<any> {
        const url = `${this.apiUrl}/pdf`;
        return this.http.post(url, data, { responseType: 'blob' as 'json' });
    }
}
