<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/course-equalizations', text: 'courseEqualization_listTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'courseEqualization_listTitle' | translate}}</h1>
        <aside>
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()" *ngIf="!studentPickerOpened && (canPickStudent || student)">
                <i class="fi fi-rs-user"></i>
                {{'courseEqualization_btnPickStudent' | translate}}
            </button>

            <button type="button" class="btn btn-outline-primary" *ngIf="canEdit && !studentPickerOpened" [routerLink]="['/course-equalizations/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened">
        <div class="data-value" *ngIf="canPickStudent && student">
            <label>{{'courseEqualization_lblStudent' | translate}}</label>
            <div>{{student.FirstName}} {{student.LastName}}, {{student.Email}}</div>
        </div>

        <app-table [rows]="items" [columns]="columns" *ngIf="!canPickStudent || student">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        <span class="badge"
                              [class.text-bg-primary]="row.Status == 'Project'"
                              [class.text-bg-success]="row.Status == 'Approved'"
                              [class.text-bg-dark]="row.Status == 'Submitted'"
                              [class.text-bg-secondary]="row.Status == 'Draft'">
                            {{'courseEqualization_status' + row.Status | translate}}
                        </span>
                    </td>
                    <td class="hidden-md-down">{{row.Speciality}}</td>
                    <td class="hidden-md-down">{{row.StudyYear}}</td>
                    <td class="hidden-md-down">{{row.Created | appDate}}</td>
                    <td class="hidden-sm-down">
                        <div class="table-actions">
                            <button type="button" (click)="delete(row)" *ngIf="canDelete(row)" class="btn btn-outline-primary btn-icon"
                                    [ngbTooltip]="'delete' | translate">
                                <i class="fi fi-rs-trash"></i>
                            </button>

                            <button type="button" class="btn btn-outline btn-icon" [routerLink]="['/course-equalizations', row.Id] | localize"
                                    [ngbTooltip]="'open' | translate">
                                <i class="fi fi-rs-pencil"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </ng-container>
</div>
