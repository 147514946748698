import { Input, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { IAttachment, IPortfolio } from '../../../models/Portfolio';
import { AppService } from '../../../services/app.service';
import { PortfolioService } from '../../../services/portfolio.service';

type Expiry = 'OneDay' | 'OneWeek' | 'OneMonth';

@Component({
    selector: 'app-portfolio-share',
    templateUrl: './portfolio-share.component.html'
})
export class PortfolioShareComponent {
    constructor(
        private app: AppService,
        private service: PortfolioService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() portfolio: IPortfolio;

    readonly options: { expiry: { text: string, value: Expiry }[] } = {
        expiry: ['OneDay', 'OneWeek', 'OneMonth'].map((t: Expiry) => {
            return {
                text: this.app.translate(`portfolio_shareExpiry${t}`),
                value: t
            }
        })
    };

    readonly attachmentsModel: { [index: string]: boolean } = {};

    email: string;
    expiry: Expiry;
    purpose: string;

    isSubmitted: boolean;
    isLoading: boolean;

    get attachments(): IAttachment[] {
        const all: IAttachment[] = [];
        if (this.portfolio) {
            all.push(...this.portfolio.Attachments);
            this.portfolio.Reports.forEach(t => all.push(...t.Attachments));
        }
        return all;
    }

    get includedAttachments(): string[] {
        return Object.keys(this.attachmentsModel).map(t => t).filter(t => this.attachmentsModel[t]);
    }

    @ViewChild('form') form: NgForm;

    ok() {
        this.isSubmitted = true;

        if (!this.form.valid || !this.includedAttachments.length) return;

        let days = 0;

        switch (this.expiry) {
            case 'OneDay': days = 1; break;
            case 'OneWeek': days = 7; break;
            case 'OneMonth': days = 30; break;
        }

        this.isLoading = true;

        setTimeout(() => {
            this.app.addLoading(this.service.share({
                attachmentIndexes: this.includedAttachments,
                email: this.email,
                expiresInDays: days,
                portfolioId: this.portfolio.Id,
                purpose: this.purpose
            })).pipe(finalize(() => {
                this.isLoading = false;
            })).subscribe(result => {
                this.activeModal.close({
                    email: this.email,
                    attachments: this.portfolio.Attachments.filter(t => this.includedAttachments.includes(t.Index)),
                    purpose: this.purpose,
                    result
                });
            });
        }, 500);
    }

    cancel() {
        this.activeModal.dismiss();
    }

    prettyPath(path: string): string {
        return path.split('/').join(' / ');
    }
}
