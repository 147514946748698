import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Message, MessageTranslated } from '../models/Message';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: "root" })
export class MessageService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/messagetemplates` }

	private cache: { [key: string]: any } = {};

	get(): Observable<Message[]> {
		if (this.cache['getMessages'])
			return of(this.cache['getMessages']);

        return this.http.get<Message[]>(this.apiUrl).pipe(tap(res => {
            this.cache['getMessages'] = res;
		}));
	}

    findMessages(filter: { group?: string, code?: string, title?: string, textEn?: string, textLv?: string }): Observable<Message[]> {
        return this.http.get<Message[]>(this.apiUrl + '/filter', {
			params: filter
		});
	}

	add(message: Message): Observable<Message> {
		this.clearCache();
        return this.http.post<Message>(this.apiUrl, message);
	}

	update(id: number, message: Message): Observable<Message> {
		this.clearCache();
        return this.http.put<Message>(this.apiUrl + '/' + id, message);
	}

	delete(id: number): Observable<boolean> {
		this.clearCache();
        return this.http.delete<boolean>(this.apiUrl + '/' + id);
	}

	getById(id: number): Observable<Message> {
		if (this.cache['getMessage_' + id.toString()])
			return of(this.cache['getMessage_' + id.toString()]);

        return this.http.get<Message>(this.apiUrl + '/' + id).pipe(tap(res => {
            this.cache['getMessage_' + id.toString()] = res;
		}));
	}

    getByCode(code: string): Observable<Message> {
        if (this.cache['getMessage_c' + code])
            return of(this.cache['getMessage_c' + code]);

        const url = `${this.apiUrl}/getByCode(${code})`;

        return this.http.get<Message>(url).pipe(map(res => {
            let data = res || [];
            this.cache['getMessage_c' + code] = data[0];
            return data[0];
        }));
    }

    getByCodes(codes: string[]): Observable<Message[]> {
        const url = `${this.apiUrl}/getByCodes`;

        // post used to bypass max url length
        return this.http.post<Message[]>(url, codes);
    }

	getForPasswordLink(language: string): Observable<MessageTranslated> {
		const url = `${this.apiUrl}/forPasswordLink?language=${language}`;

        return this.http.get<MessageTranslated>(url);
	}

	getForApplicantConsent(language: string): Observable<any[]> {
		const cacheKey = 'getMessage_forApplicantConsent';

		if (this.cache[cacheKey])
			return of(this.cache[cacheKey]);

		const url = `${this.apiUrl}/forApplicantConsent?language=${language}`;

        return this.http.get<any[]>(url).pipe(tap(res => {
            this.cache[cacheKey] = res;
		}));
	}

    getFooterCopyright(language: string): Observable<string> {
		const cacheKey = 'getMessage_FooterCopyright';

		if (this.cache[cacheKey])
			return of(this.cache[cacheKey]);

		const url = `${this.apiUrl}/FooterCopyright?language=${language}`;

        return this.http.get<string>(url).pipe(tap(res => {
            this.cache[cacheKey] = res;
		}));
	}

	getITICTextWrap(language: string): Observable<{Code: string, Text: string}[]> {
		const cacheKey = 'getMessage_ITICTextWrap';

		if (this.cache[cacheKey])
			return of(this.cache[cacheKey]);

		const url = `${this.apiUrl}/ITICTextWrap?language=${language}`;

        return this.http.get<{ Code: string, Text: string }[]>(url).pipe(tap(res => {
			this.cache[cacheKey] = res;
		}));
	}

	getUnsupportedBrowser(language: string): Observable<string> {
		const cacheKey = 'getMessage_UnsupportedBrowser';

		if (this.cache[cacheKey])
			return of(this.cache[cacheKey]);

		const url = `${this.apiUrl}/UnsupportedBrowser?language=${language}`;

        return this.http.get<string>(url).pipe(tap(res => {
            this.cache[cacheKey] = res;
		}));
	}

    getForEmployeePasswordReset(language: string): Observable<any[]> {
        const cacheKey = 'getMessage_forEmployeePasswordReset';

        if (this.cache[cacheKey])
            return of(this.cache[cacheKey]);

        const url = `${this.apiUrl}/forEmployeePasswordReset?language=${language}`;

        return this.http.get<any[]>(url).pipe(tap(res => {
            this.cache[cacheKey] = res;
        }));
    }

    getForLoadControl(language: string): Observable<{ Code: string, Text: string }[]> {
        const url = `${this.apiUrl}/forLoadControl?language=${language}`;
        return this.http.get<{ Code: string, Text: string }[]>(url);
    }

    getTextByLanguage(message: Message, language: string): string {
        return message ? language === 'en' ? message.TextEN : message.TextLV : '';
    }

	private clearCache() {
		this.cache = {};
	}
}
