<div class="modal-header">
    <h4 class="modal-title">{{'certRequests_sendEmails' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="form-field required">
            <label class="form-label required">{{'certRequests_lblEventCode' | translate}}</label>
            <input class="form-control" [(ngModel)]="eventCode" name="eventCode" required maxlength="100">
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="close()" [disabled]="isSaving">{{'dialog_close' | translate}}</button>
    <button class="btn btn-primary" type="button" (click)="submit()" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'certRequests_btnSendEmails' | translate}}
    </button>
</div>
