<div class="modal-header">
    <h4 class="modal-title">{{'planSummary_addLecturer_title' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="data-value">
            <label>{{'planSummary_courses_lblCourse' | translate}}</label>
            <div>{{rotation?.Name}} ({{rotation?.Code}})</div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'planSummary_addLecturer_lblLecturer' | translate}}</label>
            <app-autocomplete [(ngModel)]="selected"
                              name="selected"
                              limit="10"
                              menuSize="fit"
                              [options]="lecturers"
                              [required]="true"
                              [displayFn]="displayFn"
                              (search)="getLecturers($event)">
                <ng-template #optionTemplate let-option>
                    <div>{{option.Name}} {{option.Surname}}</div>
                    <div class="text-sub text-truncate">{{option.Position}}</div>
                </ng-template>
            </app-autocomplete>
            <div class="feedback">{{'planSummary_addLecturer_hintLecturer' | translate}}</div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button class="btn btn-primary" (click)="submit()" type="button" [disabled]="isSaving || !form.valid">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'add' | translate}}
    </button>
</div>
