import { Injectable } from "@angular/core";
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class CustomNgbDateParserFormatter implements NgbDateParserFormatter {
    parse(value: string | NgbDateStruct): NgbDateStruct {
        if (!value) return undefined;

        if (typeof value == 'string') {
            const ymd = value.split('T')[0].split('.');

            if (ymd.length != 3) return undefined;

            const check = new Date(+ymd[2], +ymd[1] - 1, +ymd[0]);
            
            if (isNaN(check.getTime())) return undefined;

            return new NgbDate(+ymd[2], +ymd[1], +ymd[0]);
        }

        return value;
    }

    format(date: NgbDateStruct): string {
        if (!date) return '';
        return `${('0' + date.day).slice(-2)}.${('0' + date.month).slice(-2)}.${date.year}`;
    }
}

@Injectable()
export class CustomNgbDateAdapter extends NgbDateAdapter<Date> {
    fromModel(value: Date | null): NgbDateStruct | null {
        if (value) {
            let date: Date;

            if (typeof value === 'string') {
                // dates sent by the API (backend) are strings
                date = new Date(value);
            } else {
                date = value;
            }

            if (date instanceof Date) {
                return {
                    day: date.getDate(),
                    month: date.getMonth() + 1,
                    year: date.getFullYear()
                };
            }
        }

        return null;
    }

    toModel(date: NgbDateStruct | null): Date | null {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    }
}
