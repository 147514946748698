<app-alert level="danger" *ngIf="allowed === false">
    {{'personPicker_notAllowed' | translate}}
</app-alert>

<ng-container *ngIf="allowed === true">
    <form #filterForm="ngForm">
        <div class="row">
            <div class="col-4 form-field">
                <app-select [options]="filterTypes"
                            [placeholder]="'personPicker_lblFilterType' | translate"
                            [displayFn]="filterTypeDisplayFn"
                            [required]="true"
                            [(ngModel)]="filterType"
                            (changed)="filterValue = ''"
                            name="filterType">
                </app-select>
            </div>

            <div class="col-4 form-field">
                <input class="form-control" name="filterValue" [(ngModel)]="filterValue"
                       [placeholder]="'personPicker_lblFilterValue' | translate" required />
            </div>

            <div class="col-4 form-field">
                <button class="btn btn-outline" type="submit" (click)="search()" [disabled]="!filterForm.valid || isFiltering">
                    <i class="fi fi-rs-search" *ngIf="!isFiltering"></i>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isFiltering"></span>
                    {{findButtonText | translate}}
                </button>
            </div>
        </div>
    </form>

    <app-table [rows]="items" [columns]="studentColumns" sticky="right" *ngIf="personType == 'student'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>{{row.StudentId}}</td>
                <td>{{row.FirstName}}</td>
                <td>{{row.LastName}}</td>
                <td>{{row.Email}}</td>
                <td>
                    <div><strong>{{row.Faculty}}</strong></div>
                    <div>{{row.Group}}</div>
                    <div class="sub">{{row.ProgrammeCode}}</div>
                </td>
                <td>{{row.Status}}</td>
                <td>
                    <div class="table-actions">
                        <button type="button" class="btn btn-outline btn-icon" (click)="select(row)" [ngbTooltip]="(pickButtonText || 'personPicker_btnPick') | translate">
                            <i class="fi fi-rs-shuffle"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
    
    <app-table [rows]="items" [columns]="employeeColumns" sticky="right" *ngIf="personType == 'employee'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td><i class="fi fi-rs-lock-alt icon-user-ext" title="{{ 'personPicker_iconTitle' | translate }}" *ngIf="row.IsExternalUser"></i>{{row.FirstName}}</td>
                <td>{{row.LastName}}</td>
                <td>{{row.Email}}</td>
                <td>{{row.StructureName}}</td>
                <td>{{row.WorkloadName}}</td>
                <td>{{row.ProgrammeCode}}</td>
                <td>
                    <div class="table-actions">
                        <button type="button" class="btn btn-outline btn-icon" (click)="select(row)"
                                [ngbTooltip]="(pickButtonText || 'personPicker_btnPick') | translate">
                            <i class="fi fi-rs-shuffle"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</ng-container>
