<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/impersonation', text: 'impersonation_title' }]"></app-breadcrumbs>

    <header class="content-header" *ngIf="currentUser">
        <h1 class="h-md">
            {{(currentUser.impersonator ? 'impersonation_currentImpersonation' : 'impersonation_title') | translate}}
        </h1>
    </header>

    <div *ngIf="currentUser?.impersonator">
        <div class="data-value mb-3">
            <label>{{'impersonation_by_student_id' | translate}}</label>
            <div>{{getId(currentUser.email)}}</div>
        </div>

        <div class="data-value mb-3">
            <label>{{'impersonation_by_name' | translate}}</label>
            <div>{{currentUser.firstName}}</div>
        </div>

        <div class="data-value mb-3">
            <label>{{'impersonation_by_surname' | translate}}</label>
            <div>{{currentUser.lastName}}</div>
        </div>

        <div class="data-value mb-3">
            <label>{{'impersonation_by_rsu_email' | translate}}</label>
            <div>{{currentUser.email}}</div>
        </div>

        <div class="block text-end">
            <button class="btn btn-primary" type="button" (click)="remove()">
                {{'impersonation_remove' | translate}}
            </button>
        </div>
    </div>

    <app-person-picker (pick)="set($event)"
                       [pickButtonText]="'impersonation_set'"
                       *ngIf="currentUser && !currentUser.impersonator"></app-person-picker>
</div>
