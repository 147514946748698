export enum TileStatus {
    Draft = 'Draft',
    Published = 'Published'
}

export enum TileSize {
    Small = 1,
    Large = 2
}

export interface ITile {
    Id: number;
    Title: string;
    TitleEn: string;
    Size: TileSize;
    Description?: string;
    DescriptionEn?: string;
    Url: string;
    Image?: string;
    Status: TileStatus;
    SortOrder: number;
    Permission?: {
        Id: string,
        Code: string,
        Value: string
    };
    Category?: {
        Id: string,
        Code: string,
        Value: string
    };
}

export class TileEditModel {
    Title: string;
    TitleEn: string;
    Size: TileSize;
    Description?: string;
    DescriptionEn?: string;
    Url: string;
    Image?: string;
    Status: TileStatus = TileStatus.Draft;
    SortOrder: number;
    PermissionId: string;
    CategoryId?: string;
}

export interface IPublishedTile {
    Id: number;
    Title: string;
    Size: TileSize;
    Description?: string;
    Url: string;
    Image?: string;
    Category?: string;
    CategoryId?: string;
}
