import { IPersonSearchResultItem } from "../../models/Person";

const data: {
    student?: IPersonSearchResultItem
} = {};

export const store = {
    getStudent(): IPersonSearchResultItem {
        return data.student;
    },

    setStudent(student: IPersonSearchResultItem) {
        data.student = student;
    }
};
