<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">
        {{'seminar_attendance_title' | translate}}
    </h4>
</div>

<div class="modal-body">
    <app-seminar-attendance-form [seminarId]="seminarId" (load)="onLoad($event)"></app-seminar-attendance-form>
</div>

<div class="modal-footer">
    <ng-container *ngIf="canEdit">
        <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

        <button class="btn btn-primary" (click)="save()" type="button" [disabled]="isSaving || !hasChanges">
            <span class="spinner-border spinner-border-sm" *ngIf="isSaving"></span>
            {{'save' | translate}}
        </button>
    </ng-container>

    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" *ngIf="!canEdit">{{'close' | translate}}</button>
</div>
