import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { ISurvey, ISurveyBegin, ISurveyStart } from '../models/Survey';

/**
 * Student survey service
 */
@Injectable({ providedIn: "root" })
export class SurveyService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/surveys` }

    /**
     * Get survey list
     * @param email Target user email
     */
    get(email?: string): Observable<ISurvey[]> {
        const url = `${this.apiUrl}`;
        const params: any = {};

        if (email) params.email = email;

        return this.http.get<ISurvey[]>(url, { params });
    }

    /**
     * Get information to begin a survey.
     * @param token Survey token
     */
    begin(token: string): Observable<ISurveyBegin> {
        const url = `${this.apiUrl}/begin`;
        const params: any = { token };
        return this.http.get<ISurveyBegin>(url, { params });
    }

    /**
     * Get information to start filling a survey.
     * @param id Survey ID
     * @param token Survey token
     */
    start(id: string, token: string): Observable<ISurveyStart> {
        const url = `${this.apiUrl}/${id}/start`;
        const params: any = { token };

        return this.http.get<ISurveyStart>(url, { params });
        //return of(data2);
    }

    /**
     * Submit survey data.
     * @param id Survey ID
     * @param token Survey token
     * @param values Answers
     */
    submit(id: string, token: string, values: Record<string, string>): Observable<any> {
        const url = `${this.apiUrl}/${id}/start`;
        const params: any = { token };

        return this.http.post<any>(url, values, { params });
    }
}
