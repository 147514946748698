import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { UserInfo, UserType } from '../models/UserInfo';
import { IOAuthConfig, IAuthUser as IAuthUser } from '../models/Auth';

import { environment as ENV } from '../../environments/environment';

const keys = {
    user: 'user',
    consent: 'consent',
    sessionId: 'sid',
    sessionEnd: 'session.end'
};

@Injectable({ providedIn: "root" })
export class AuthService {
    constructor(private http: HttpClient) {
        this.user = this.userSubject.asObservable();
        this.setupListeners();
    }

    get apiUrl(): string { return `${ENV.apiUrl}/auth` }
    
    readonly user: Observable<UserInfo>;

    get currentUser(): UserInfo | null {
        return this.userSubject.getValue();
    }

    get sessionKey(): string {
        return this.currentUser?.sessionKey;
    }

    get isAuthenticated(): boolean {
        return !!this.currentUser;
    }

    //get isAuthenticationShared(): boolean {
    //    return !!localStorage.getItem(keys.sessionId);
    //}

    //loginAdfs(key: string): Observable<any> {
    //    return this.http.post(this.apiUrl + '/actions/confirm', { confirmKey: key }).pipe(tap((data: any) => {
    //        if (data) {
    //            this.setUser(this.mapUser(data));
    //        }
    //    }));
    //}

    private userSubject = new BehaviorSubject<UserInfo>(null);

    startSessionWithToken(user: string, time: string, name: string, hash: string, area: string, lang: string): Observable<Response> {
        return this.http.post(this.apiUrl + '/actions/startsession', {
            user,
            name,
            time,
            hash,
            area,
            lang
        }).pipe(tap((data: any) => {
            if (data) {
                const user = this.mapUser(data);
                this.setSession(user);
            }
        }));
    }

    startSessionWithCRMToken(custId: string, custIdType: string, adLogin: string, time: string, token: string, lang: string): Observable<Response> {
        return this.http.post(this.apiUrl + '/actions/startcrmsession', {
            custId,
            custIdType,
            adLogin,
            time,
            token,
            lang
        }).pipe(tap((data: any) => {
            if (data) {
                const user = this.mapUser(data);
                this.setSession(user);
            }
        }));
    }

    //getCryptoKey(cryptoKey: string): Observable<UserCrypto> {
    //    return this.http.get<UserCrypto>(this.apiUrl + '/actions/getCryptoKey?cryptoKey=' + cryptoKey);
    //}

    /**
     * Initiate the login process.
     * @param returnTo URL to redirect to after successfull login
     */
    login(returnTo?: string) {
        let redirecturl = '';

        if (returnTo) {
            redirecturl = '?redirecturl=' + encodeURIComponent(returnTo);
        }

        location.assign(ENV.adfsApiRoute + redirecturl);
    }

    /**
     * Logout current user.
     * @param returnTo URL to redirect to after successfull logout
     */
    logout(returnTo?: string) {
        const url = `${this.apiUrl}/logout`;

        if (!returnTo) {
            returnTo = `${location.origin}/${sessionStorage.getItem('language') || 'lv'}/${ENV.startRoute}?nologin=true`;
        }

        this.http.post(url, null).toPromise().then(() => {
            this.endSession({ emitUser: false, endAllSessions: true });
            const signoutUrl = `${ENV.adfsApiRoute}/signout?returnTo=${encodeURIComponent(returnTo)}`;
            location.assign(signoutUrl);
        });
    }

    // TODO this method is only used when starting a session from external system. Do we really need to clear it?
    /**
     * Logout current user and clear the session.
     */
    clear() {
        const url = `${this.apiUrl}/logout`;
        return this.http.post(url, null).pipe(finalize(() => {
            this.endSession({ emitUser: true, endAllSessions: true });
        }));
    }

    /**
     * Get OAuth configuration parameters
     */
    getOAuthConfiguration(): Observable<IOAuthConfig> {
        const url = `${this.apiUrl}/oauthConfig`;
        return this.http.get<IOAuthConfig>(url);
    }

    /**
     * Try to authenticate using browser available data.
     * @param sid Force authentication using provided session ID
     */
    authenticate(sid?: string): Promise<UserInfo> {
        const force = !!sid;

        if (!force) {
            if (this.isAuthenticated) {
                return Promise.resolve(this.currentUser);
            }

            const session = sessionStorage.getItem(keys.user);

            if (session) {
                try {
                    const user = new UserInfo();
                    const userData = JSON.parse(session);

                    Object.assign(user, userData);

                    this.setSession(user);

                    if (this.isAuthenticated) {
                        return Promise.resolve(user);
                    }
                } catch (err) {
                    // try getting user from the cookie
                }
            }

            sid = localStorage.getItem(keys.sessionId);
        }

        if (sid) {
            return new Promise(resolve => {
                const url = `${this.apiUrl}/user`;
                this.http.get(url, { params: { SessionKey: sid || '' } }).subscribe((data: IAuthUser) => {
                    if (data) {
                        const user = this.mapUser(data);

                        this.setSession(user);
                        resolve(user);
                    } else {
                        this.endSession({ emitUser: true, endAllSessions: true });
                        resolve(null);
                    }
                }, err => {
                    // end the bad session
                    this.endSession({ emitUser: true, endAllSessions: true });
                    resolve(null);
                });
            });
        }

        return Promise.resolve(null);
    }

    /**
     * Set user using specific user data.
     * @param user
     */
    setUser(user: IAuthUser) {
        const data = this.mapUser(user);
        this.setSession(data);
    }

    private setSession(user: UserInfo, emit: boolean = true) {
        if (user) {
            sessionStorage.setItem(keys.user, JSON.stringify(user));
            sessionStorage.removeItem(keys.consent);

            // share the session ID to authenticate in other tabs
            localStorage.setItem(keys.sessionId, user.sessionKey);
        } else {
            sessionStorage.removeItem(keys.user);
            sessionStorage.removeItem(keys.consent);

            // stop sharing the session ID
            localStorage.removeItem(keys.sessionId);
        }

        if (emit) {
            this.userSubject.next(user);
        }
    }

    private endSession(options?: { emitUser?: boolean, endAllSessions?: boolean }) {
        const opts = { emitUser: true, endAllSessions: true };
        Object.assign(opts, options);

        this.setSession(null, opts.emitUser);

        if (opts.endAllSessions) {
            this.fireEvent(keys.sessionEnd);
        }
    }

    private mapUser(data: IAuthUser): UserInfo {
        const user = new UserInfo();

        user.email = data.Email;
        user.firstName = data.FirstName;
        user.lastName = data.LastName;
        user.roles = data.Roles || [];
        user.sessionKey = data.SessionKey;
        user.startUrl = data.RedirectUrl ? data.RedirectUrl : ENV.startRoute;
        user.rights = data.Rights || [];
        user.username = data.UserName;
        user.type = data.UserType as UserType;
        user.personDetailsId = data.PersonDetailsId;

        if (data.Impersonator) {
            user.impersonator = this.mapUser(data.Impersonator);
        }

        return user;
    }

    private setupListeners() {
        window.addEventListener('storage', event => {
            if (event.key == keys.sessionEnd && event.newValue == '1') {
                this.endSession({ emitUser: true, endAllSessions: false });
            } else if (event.key == keys.sessionId && event.newValue && event.newValue != this.sessionKey) {
                this.authenticate(event.newValue);
            }
        });
    }

    private fireEvent(name: string) {
        localStorage.setItem(name, '1');
        localStorage.removeItem(name);
    }
}
