import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { IsicResponse, PersonStudentInfo, IsicHistory } from '../models/Isic';

@Injectable({ providedIn: "root" })
export class IsicService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/isic` }

    getStudentInfo(userEmail?: string): Observable<PersonStudentInfo> {
        const url = `${this.apiUrl}/actions/getstudentinfo`;
        return this.http.get<PersonStudentInfo>(url, { params: { userEmail: userEmail } });
    }

    getHistory(userEmail?: string): Observable<IsicHistory[]> {
        const url = `${this.apiUrl}/actions/gethistory`;
        return this.http.get<IsicHistory[]>(url, { params: { userEmail: userEmail } });
    }

    sendData(userEmail?: string): Observable<IsicResponse> {
        const url = `${this.apiUrl}/actions/send`;
        return this.http.post<IsicResponse>(url, null, {
            params: {
                userEmail: userEmail
            }
        });
    }
}
