import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Classifier } from '../../../models/Classifier';

@Component({
    selector: 'app-course-equalization-approval',
    templateUrl: './course-equalization-approval.component.html'
})
export class CourseEqualizationApprovalComponent {
    constructor(
        private activeModal: NgbActiveModal
    ) { }

    //@Input() id: number;

    @Input() decisionOptions: Classifier[] = [];

    decision: Classifier;
    comment: string;
    //isSaving: boolean;

    get isCommentRequired() {
        return this.decision?.Code == 'PassPartial' || this.decision?.Code == 'Other';
    }

    ok() {
        this.activeModal.close({
            decision: this.decision,
            comment: this.comment
        });
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
