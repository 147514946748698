<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/external/config', text: 'externalServiceConfig_listTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'externalServiceConfig_listTitle' | translate}}</h1>
        <aside>
            <button class="btn btn-outline-primary" type="button" [routerLink]="['/external/config/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-table [rows]="items" [columns]="columns" [sticky]="'right'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>{{row.Code}}</td>
                <td>
                    <span class="badge"
                          [class.text-bg-secondary]="row.Status == 'Draft'"
                          [class.text-bg-success]="row.Status == 'Published'"
                          [class.text-bg-danger]="row.Status == 'Deactivated'">
                        {{'externalServiceConfig_status' + row.Status | translate}}
                    </span>
                </td>
                <td>{{row.Version}}</td>
                <td>{{row.Name}}</td>
                <td>
                    <div class="table-actions">
                        <button [routerLink]="['/external/config', row.Id] | localize" type="button" class="btn btn-outline btn-icon"
                                [ngbTooltip]="'edit' | translate">
                            <i class="fi fi-rs-pencil"></i>
                        </button>

                        <button (click)="delete(row)" type="button" class="btn btn-outline btn-icon"
                                [ngbTooltip]="'delete' | translate" [disabled]="!canDelete(row)">
                            <i class="fi fi-rs-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
