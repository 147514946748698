import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';

import { ApplicationKind, IApplicationCustomField, IApplicationCustomForm, Student } from '../../models/Application';
import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ClassifierService } from '../../services/classifier.service';
import { ParameterService } from '../../services/parameter.service';
import { ApplicationComponentBase, IFileMultipleField } from './ApplicationComponentBase';
import { MessageService } from '../../services/message.service';
import { IFieldFile } from '../../models/IFieldFile';
import { map } from 'rxjs/operators';

export const parameterCodes = {
    attachmentExtensions: 'ApplicationFileExtensions',
    attachmentMaxSize: 'ApplicationFileMaxSize',
    attachmentLimit: 'ApplicationFileLimit'
};

interface IField {
    config: IApplicationCustomField;
    title: string;
    value?: any;
    choices?: any[];
    choicesFn?: (term: string) => Observable<{ value: any, text: string }[]>;
}

@Component({
    selector: 'app-custom-application',
    templateUrl: './custom-application.component.html',
    styleUrls: ['./application.component.scss']
})
export class CustomApplicationComponent extends ApplicationComponentBase {
    constructor(
        public app: AppService,
        protected service: ApplicationService,
        protected classifiers: ClassifierService,
        protected messages: MessageService,
        protected parameters: ParameterService,
        protected route: ActivatedRoute,
        protected router: Router
    ) {
        super(app, service, classifiers, messages, route, router);
    }

    get kind(): ApplicationKind {
        return ApplicationKind.Application;
    }

    fields: { [key: string]: IField } = {};
    fieldRows: IApplicationCustomField[][] = [];

    private initialForm: string;

    ngOnInit() {
        super.ngOnInit();

        this.item.RefLanguage = `AppRefLang${this.app.currentLanguage === 'en' ? 'En' : 'Lv'}`;
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (!this.isSuccess && this.itemChanged()) {
            const subj = new Subject<boolean>();
            this.app.confirm(this.app.translate('application_confirmUnsaved'), result => subj.next(result));
            return subj.asObservable();
        }

        return of(true);
    }

    protected onSubmit() {
        const subj = new Subject<boolean>();
        this.showFileRequired = false;

        this.confirm('application_confirmSubmit', ok => {
            if (ok) {
                const customFields = {};
                Object.keys(this.fields).forEach(t => {
                    customFields[t] = this.fields[t].value || null;
                });

                this.item.CustomFields = customFields;

                this.customFiles = this.fileFieldRows.reduce((arr, t) => {
                    arr.push(...t.files.filter(f => f.file?.size));
                    return arr;
                }, []);
            }

            subj.next(ok);
        });

        return subj.asObservable();
    }

    protected onSetProgramme(data: Student) {
        this.app.addLoading(this.service.getCustomFormByCode(this.item.Type, data.StudentId, data.StudyProgrammeId)).subscribe(form => {
            this.item.ConfigId = form.Id;
            this.buildForm(form);
            this.initialForm = JSON.stringify(this.fields);
        }, err => {
            this.isError = true;
        });
    }

    addAttachment(f: IFileMultipleField) {
        f.files.push(<IFieldFile>{
            field: f.config.Name
        });
    }

    removeAttachment(fileFieldIx: number, fileIx: number) {
        if (this.fileFieldRows[fileFieldIx].files.length != 1) {
            this.fileFieldRows[fileFieldIx].files.splice(fileIx, 1);
        }
    }

    onFileChange(event: File, fileFieldIx: number, fileIx: number) {
        if (!event) {
            this.removeAttachment(fileFieldIx, fileIx);
        }
    }

    private buildForm(form: IApplicationCustomForm) {
        const isEn = this.app.currentLanguage === 'en';

        this.fields = {};

        const fieldGroups: { [key: string]: IApplicationCustomField[] } = {};
        form.Fields.forEach(t => {
            const rowName = `r${t.Row}`; // prefix added to prevent from auto-sorting in case rows are named as numbers
            const field: IField = this.fields[t.Name] = {
                config: t,
                title: isEn ? t.NameEN : t.NameLV,
                choices: [],
                value: ''
            };

            if (t.Source) {
                const ds = form.DataSources.find(d => d.Name === t.Source.Name);

                if (ds) {
                    if (ds.Type == 'List') {
                        if (t.Type == 'Autocomplete') {
                            field.choicesFn = term => {
                                return this.service.getCustomFormChoices(
                                    this.item.Type,
                                    this.selectedStudent.StudentId,
                                    this.selectedStudent.StudyProgrammeId,
                                    ds.Name,
                                    term || '').pipe(map(data => {
                                        return (data || []).map(n => {
                                            return {
                                                text: <string>n[t.Source.DisplayField],
                                                value: n[t.Source.ValueField]
                                            };
                                        });
                                    }));
                            };
                        } else {
                            field.choices = ds.Data.map(c => {
                                return {
                                    text: c[t.Source.DisplayField],
                                    value: c[t.Source.ValueField]
                                };
                            });
                        }
                    } else if (ds.Type == 'Dictionary') {
                        if (ds.Data && ds.Data.length) {
                            field.value = ds.Data[0][t.Source.ValueField];
                        }
                    }
                }
            }

            if (!fieldGroups[rowName]) {
                fieldGroups[rowName] = [];
            }

            fieldGroups[rowName].push(t);
        });

        const fieldRows: IApplicationCustomField[][] = [];
        for (let g in fieldGroups) {
            fieldRows.push(fieldGroups[g]);
        }

        this.fieldRows = fieldRows;

        if (form.FileFields) {
            this.fileFieldRows = form.FileFields.map(t => {
                const obj = {
                    config: t,
                    title: isEn ? t.NameEN : t.NameLV,
                    files: []
                };

                this.addAttachment(obj);

                return obj;
            }).sort((a, b) => parseInt(a.config.Row) - parseInt(b.config.Row));
        }
    }

    private confirm(text: string, callback: (ok: boolean) => any) {
        this.app.confirm({
            text: this.app.translate(text)
        }, ok => callback(ok));
    }

    private itemChanged(): boolean {
        return !!(this.fileFieldRows.some(t => t.files.some(f => !!f.file)) || this.initialForm !== JSON.stringify(this.fields));
    }
}
