<section class="block">
	<app-alert level="danger" *ngIf="printAccountMissing">
		{{'print_noPrintAccount' | translate}}
	</app-alert>

	<div class="data-value">
		<label>{{'print_lblRemainingBalance' | translate}}</label>
		<div>{{remainingBalance}} {{'print_currency' | translate}}</div>
	</div>
</section>
