import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IQuickSearchItem, ISearchTextPart } from '../models/Search';
import { Utils } from '../core/Utils';

/**
 * Search service
 */
@Injectable({ providedIn: "root" })
export class SearchService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/search` }

    getQuickSearchItems(): Observable<IQuickSearchItem[]> {
        const url = `${this.apiUrl}/quickSearchItems`;
        return this.http.get<IQuickSearchItem[]>(url);
    }

    /**
     * Split text into matching parts.
     * E.g., if "a" is searched inside "Abba",
     * the result will be [{ text: "A", isMatch: true }, { text: "bb" }, { text: "a", isMatch: true }].
     * @param text Source text
     * @param term Search term
     */
    getTextMatchParts(text: string, term: string): ISearchTextPart[] {
        if (!text) return [];

        const matches = text.match(new RegExp(Utils.escapeRegExp(term), 'ig'));

        if (!matches || !matches.length) return [{ text }];

        const parts: { text: string, isMatch?: boolean }[] = [{ text }];

        matches.forEach(t => {
            const left = parts[parts.length - 1].text;
            const ix = left.indexOf(t);

            parts[parts.length - 1] = { text: left.slice(0, ix) };
            parts.push({ text: t, isMatch: true });
            parts.push({ text: left.slice(ix + t.length) });
        });

        return parts.filter(t => t.text != '');
    }
}
