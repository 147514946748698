<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/password/request', text: 'password_restore' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'password_restore' | translate}}</h1>
    </header>

    <div class="mb-1">
        <a [routerLink]="['/password/request-student'] | localize">{{'password_restoreStudent' | translate}}</a>
    </div>

    <div class="mb-3">
        <a [routerLink]="['/password/request-employee'] | localize">{{'password_restoreEmployee' | translate}}</a>
    </div>

    <div class="mb-3" [innerHTML]="'password_contactfooter' | translate | safeHtml"></div>
</div>
