import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GradeKind, IGrade, IGradeLecturer, IGradeReason, IGradesViewModel, IPossibleGrade } from '../../models/AddGrades';

import { AppService } from '../../services/app.service';
import { GradeService } from '../../services/grade.service';
import { ITableColumn } from '../../shared/table/table.component';
import { DeleteGradeComponent } from './delete-grade.component';

interface IPeriod {
    year: number;
    semester: number;
}

@Component({
    selector: 'app-add-grades',
    templateUrl: './add-grades.component.html',
    styleUrls: ['./add-grades.component.scss']
})
export class AddGradesComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: GradeService,
        private modal: NgbModal,
        private route: ActivatedRoute
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'Kind', label: 'addGrades_lblKind', cssClass: 'hidden-md-down', sorts: true },
        { property: 'TypeName', label: 'addGrades_lblType', cssClass: 'hidden-sm-down', sorts: true },
        { property: 'GradeName', label: 'addGrades_lblGrade', sorts: true },
        { property: 'LecturerName', label: 'addGrades_lblLecturer', sorts: true },
        { width: '1px' }
    ];

    readonly gradeKinds: GradeKind[] = ['Intermediate', 'Final'];

    rows: IGrade[] = [];
    data: IGradesViewModel;
    model: {
        kind?: GradeKind,
        period?: IPeriod,
        grades: {
            typeId: string,
            typeName: string,
            gradeId?: string,
            reasonId?: string,
            previous?: IGrade,
            isObsolete?: boolean,
            gradeOptions: IPossibleGrade[],
            reasonOptions: IGradeReason[]
        }[],
        lecturerId?: string,
        lecturers: IGradeLecturer[],
        rowId?: string
    } = {
            grades: [],
            lecturers: []
        };
    periods: IPeriod[];
    submitted: boolean;
    canDelete: boolean;

    readonly gradeKindDisplayFn = (option: GradeKind) => this.app.translate(`addGrades_kind${option}`);
    readonly lecturerDisplayFn = (option: IGradeLecturer) => `${option.Name} ${option.Surname}`;
    readonly periodDisplayFn = (option: IPeriod) => this.app.translate('addGrades_yearSemester')
        .replace('{{year}}', option.year)
        .replace('{{semester}}', option.semester);

    private studentId: string;
    private subjectId: string;
    private newGradeReasons: IGradeReason[] = [];
    private editGradeReasons: IGradeReason[] = [];
    private defaultReasonId?: string;
    private initStudyYear?: number;
    private initSemester?: number;

    @ViewChild('form', { static: false }) private form: NgForm;

    ngOnInit() {
        this.studentId = this.route.snapshot.queryParams['StudentId'];
        this.subjectId = this.route.snapshot.queryParams['SubjectId'];

        if (!this.studentId || !this.subjectId || !this.app.currentUser) {
            this.app.navigateToStart();
            return;
        }

        this.initSemester = this.route.snapshot.queryParams['SubjectSemesterNr'];
        this.initStudyYear = this.route.snapshot.queryParams['StudyYear'];

        this.canDelete = this.app.currentUser.rights.indexOf('GRADES.DELETE') > -1;

        this.loadGrades();
    }

    onKindChange(value: GradeKind) {
        const configs = this.data.Configs.filter(t => t.Kind == value);

        this.periods = configs.map(t => {
            return {
                year: t.StudyYear,
                semester: t.Semester
            };
        });

        this.model = {
            kind: value,
            grades: [],
            lecturers: []
        };

        let defaultPeriod: IPeriod;

        if (this.initStudyYear && this.initSemester) {
            defaultPeriod = this.periods.find(t => t.year == this.initStudyYear && t.semester == this.initSemester);
        } else if (this.periods.length == 1) {
            defaultPeriod = this.periods[0];
        }

        if (defaultPeriod) {
            this.onPeriodChange(defaultPeriod);
        }
    }

    onPeriodChange(value: IPeriod) {
        const config = this.data.Configs.find(t => t.Kind == this.model.kind && t.StudyYear == value.year && t.Semester == value.semester);
        const opGrades = this.data.Grades.filter(t => t.Kind == this.model.kind && t.LecturerId == this.data.OperatorId);

        this.model.period = value;
        this.model.grades = config.Grades.map(t => {
            const opGrade = opGrades.find(g => g.TypeId == t.TypeId);
            const reasons = opGrade ? this.editGradeReasons : this.newGradeReasons;

            return {
                typeId: t.TypeId,
                typeName: t.TypeName,
                gradeId: opGrade?.GradeId,
                gradeOptions: t.PossibleGrades,
                reasonId: reasons.some(n => n.Id == this.defaultReasonId) ? this.defaultReasonId : null,
                reasonOptions: opGrade ? this.editGradeReasons : this.newGradeReasons,
                previous: opGrade,
                isObsolete: opGrade && !t.PossibleGrades.some(n => n.Id == opGrade.GradeId)
            };
        });
        this.model.lecturers = config.Lecturers;
        this.model.lecturerId = this.data.OperatorId;
        this.model.rowId = opGrades.length ? opGrades[0].RowId : null;
    }

    onLecturerChange(lecturerId: string) {
        const opGrades = this.data.Grades.filter(t => t.Kind == this.model.kind && t.LecturerId == lecturerId);

        this.model.grades.forEach(t => {
            const opGrade = opGrades.find(g => g.TypeId == t.typeId);
            const reasons = opGrade ? this.editGradeReasons : this.newGradeReasons;

            t.gradeId = opGrade?.GradeId;
            t.reasonId = reasons.some(n => n.Id == this.defaultReasonId) ? this.defaultReasonId : null,
                t.reasonOptions = opGrade ? this.editGradeReasons : this.newGradeReasons;
            t.previous = opGrade;
            t.isObsolete = opGrade && !t.gradeOptions.some(n => n.Id == opGrade.GradeId);
        });

        this.model.rowId = opGrades.length ? opGrades[0].RowId : null;
    }

    save() {
        this.submitted = true;

        if (!this.form.valid) {
            this.app.alert.warning(this.app.translate('addGrades_formError'));
            return false;
        }

        this.app.addLoading(this.service.save(this.studentId, this.subjectId, {
            lecturerId: this.model.lecturerId,
            rowId: this.model.rowId,
            isFinal: this.model.kind == 'Final',
            semester: this.model.period.semester,
            studyYear: this.model.period.year,
            grades: this.model.grades.map(t => {
                return {
                    GradeId: t.gradeId,
                    ReasonId: t.reasonId,
                    TypeId: t.typeId
                };
            })
        })).subscribe(() => {
            this.app.notify(this.app.translate('addGrades_saved'));
            this.app.navigateToStart();
        });
    }

    delete(grade: IGrade) {
        const ref = this.modal.open(DeleteGradeComponent);
        ref.componentInstance.grade = grade;
        ref.result.then(() => {
            this.loadGrades();
        }, () => { });
    }

    private loadGrades() {
        this.app.addLoading(this.service.getByStudent(this.studentId, this.subjectId)).subscribe(data => {
            this.data = data;

            this.defaultReasonId = data.Reasons.find(t => t.IsDefault)?.Id;

            this.rows = data.Grades;
            this.newGradeReasons = data.Reasons.filter(t => t.IsDefault);
            this.editGradeReasons = data.Reasons.filter(t => !t.IsDefault);
        });
    }
}
