import { Component, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { ITableColumn } from '../../../shared/table/table.component';
import { Utils } from '../../../core/Utils';
import { IPersonSearchResultItem } from '../../../models/Person';
import { SurveyService } from '../../../services/survey.service';
import { ISurvey } from '../../../models/Survey';

@Component({
    selector: 'app-survey-list',
    templateUrl: './survey-list.component.html',
    styleUrls: ['../survey.component.scss']
})
export class SurveyListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: SurveyService
    ) { }

    canPickStudent: boolean;
    studentPickerOpened: boolean;
    student: IPersonSearchResultItem;
    currentPeriodName: string;
    hasActual: boolean;
    hasArchived: boolean;
    periodView: 'actual' | 'archive' = 'actual';
    selectedPeriodId: string;
    pastPeriods: { id: string, name: string }[] = [];

    readonly courseSurveyColumns: ITableColumn[] = [
        { property: 'SurveyName', label: 'surveys_lblStudyCourse', sorts: true },
        { property: 'FeedbackLink', label: 'surveys_lblFeedback', cssClass: 'hidden-md-down' },
        { property: 'Filling', label: 'surveys_lblFilling', sorts: true, type: 'number', cssClass: 'hidden-sm-down' },
        { property: 'StatusName', label: 'surveys_lblStatus', sorts: true, cssClass: 'hidden-sm-down' },
        { width: '1px' }
    ];

    readonly programmeSurveyColumns: ITableColumn[] = [
        { property: 'SurveyName', label: 'surveys_lblStudyCourse', sorts: true },
        { property: 'Filling', label: 'surveys_lblFilling', sorts: true, type: 'number', cssClass: 'hidden-sm-down' },
        { property: 'StatusName', label: 'surveys_lblStatus', sorts: true, cssClass: 'hidden-sm-down' },
        { width: '1px' }
    ];

    readonly groups: {
        type: 'CourseSurvey' | 'ProgrammeSurvey',
        rows: ISurvey[],
        columns: ITableColumn[]
    }[] = [
            {
                type: 'CourseSurvey',
                rows: [],
                columns: this.courseSurveyColumns
            },
            {
                type: 'ProgrammeSurvey',
                rows: [],
                columns: this.programmeSurveyColumns
            }];

    private allCourseSurveys: ISurvey[] = [];

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf('SURVEYS.SET_STUDENT') > -1;

            if (this.canPickStudent) {
                this.toggleStudentPicker();
            } else {
                this.loadItems();
            }
        }
    }

    toggleStudentPicker() {
        if (this.studentPickerOpened) {
            this.studentPickerOpened = false;
        } else {
            this.studentPickerOpened = true;
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.loadItems();
    }

    switchPeriodView(period: 'actual' | 'archive') {
        this.periodView = period;

        if (period == 'archive') {
            if (!this.selectedPeriodId) {
                this.selectedPeriodId = this.pastPeriods[0]?.id;
            }

            this.onPeriodChange();
        } else {
            this.updateRows(this.allCourseSurveys.filter(t => period == 'actual' ? t.IsActual : !t.IsActual));
        }
    }

    onPeriodChange() {
        this.updateRows(this.allCourseSurveys.filter(t => t.PlanningPeriodId == this.selectedPeriodId))
    }

    private loadItems() {
        this.app.addLoading(this.service.get(this.student?.Email)).subscribe(data => {
            data.forEach(t => {
                t.EndDate = Utils.getInputDate(t.EndDate);
                t.StartDate = Utils.getInputDate(t.StartDate);
            });

            const courseSurveys = data.filter(t => t.SurveyType == 'SUB');

            this.pastPeriods = courseSurveys.filter(t => !t.IsActual).reduce((all, t) => {
                if (!all.some(n => n.id == t.PlanningPeriodId)) {
                    all.push({
                        id: t.PlanningPeriodId,
                        name: t.PlanningPeriodName
                    });
                }

                return all;
            }, []);

            this.allCourseSurveys = this.groupAndSort(courseSurveys);

            const actual = this.allCourseSurveys.filter(t => t.IsActual);
            const past = this.allCourseSurveys.filter(t => !t.IsActual);

            this.hasActual = actual.length > 0;
            this.hasArchived = past.length > 0;

            this.currentPeriodName = actual[0]?.PlanningPeriodName;

            this.updateRows(actual, this.groupAndSort(data.filter(t => t.SurveyType == 'SP')))
        });
    }

    private groupAndSort(surveys: ISurvey[]): ISurvey[] {
        const sortFn = (a, b) => a.SurveyName.localeCompare(b.SurveyName, this.app.currentLanguage);

        const submitted = surveys.filter(t => t.StatusCode == 'SUBMITTED');
        const notSubmitted = surveys.filter(t => t.StatusCode != 'SUBMITTED');

        submitted.sort(sortFn);
        notSubmitted.sort(sortFn);

        return [...notSubmitted, ...submitted];
    }

    private updateRows(courseRows?: ISurvey[], programmeRows?: ISurvey[]) {
        if (courseRows) {
            this.groups.find(t => t.type == 'CourseSurvey').rows = courseRows;
        }

        if (programmeRows) {
            this.groups.find(t => t.type == 'ProgrammeSurvey').rows = programmeRows;
        }
    }
}
