import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { AppDatePipe } from '../../../pipes/date.pipe';

import { AppService } from '../../../services/app.service';
import { StudentBalanceService } from '../../../services/student-balance.service';
import { PrintBalanceGetResult } from '../../../models/StudentBalance';
import { numberFormat } from '../print-v2.component';
import { ParameterService } from '../../../services/parameter.service';

@Component({
    selector: 'app-print-pay-v2',
    templateUrl: './print-pay-v2.component.html'
})
export class PrintPayV2Component {
    constructor(
        private app: AppService,
        private service: StudentBalanceService,
        private paramService: ParameterService,
        private datePipe: AppDatePipe,
        private decimalPipe: DecimalPipe,
        private route: ActivatedRoute
    ) { }

    private _printBalance: PrintBalanceGetResult;

    @Input() set printBalance(printBalance: PrintBalanceGetResult) {
        this._printBalance = printBalance;
        if (printBalance) {
            this.app.addLoading(this.paramService.getValues()).subscribe(params => {
                let minAmountPar = params.find(p => p.Code === 'PrintMinPaymentAmount');
                if (minAmountPar && minAmountPar.Value) {
                    this.minAmount = +minAmountPar.Value;
                    this.amountStr = this.decimalPipe.transform(this.minAmount, this.numberFormat).replace(',', '.').replace(/\s/g, "");
                    this.minimalAmountText = this.app.translate('print_MinimalAmount')
                        .replace('{{amount}}', this.decimalPipe.transform(minAmountPar.Value, this.numberFormat).replace(',', '.'));
                }
            });

            if (printBalance.LastReplenishDate) {
                this.lastAmountAdded = this.app.translate('print_lastAmountAdded')
                    .replace('{{amount}}', this.decimalPipe.transform(printBalance.LastReplenishAmount, this.numberFormat).replace(',', '.'))
                    .replace('{{date}}', this.datePipe.transform(printBalance.LastReplenishDate))
            }
        }
    }

    get printBalance(): PrintBalanceGetResult {
        return this._printBalance;
    }

    amountStr: string = "0";
    lastAmountAdded: string;
    minimalAmountText: string;
    minAmount: number;
    numberFormat = numberFormat;
    payData: string;
    payFormUrl: string;
    @ViewChild('payForm', { static: true }) private payForm: ElementRef;

    ngOnInit() {
        const params = this.route.snapshot.params;
        const query = this.route.snapshot.queryParams;

        if (params['action'] === 'pay') {
            this.app.showLoading();
            const { amount } = query;
            this.pay(amount);
        }
    }

    clearAmount() {
        if (!this.parseAmount(this.amountStr) || this.parseAmount(this.amountStr) < this.minAmount) {
            return;
        }

        setTimeout(() => {
            this.app.alert.success(this.app.translate('print_PaymentSent'));
            this.amountStr = this.decimalPipe.transform(this.minAmount, this.numberFormat).replace(',', '.');
        }, 1000);
    }

    pay(amount: number) {
        this.app.addLoading(this.service.payForPrint(amount, 'PrintBalanceV2')).subscribe(response => {
            if (response && response.Data) {
                this.payData = response.Data;
                this.payFormUrl = response.Url;

                // does not work immediately
                setTimeout(() => {
                    this.payForm.nativeElement.submit();
                }, 100);
            }
        })
    }

    parseAmount(str: string): number {
        if (isNaN(+str.replace(',', '.')) || str === "") {
            return 0;
        } else {
            str = this.decimalPipe.transform(str.replace(',', '.'), this.numberFormat).replace(',', '.').replace(/\s/g, "");
            return +str;
        }
    }

    onAmountChange(event) {
        if (event.target) {
            if (event.target.value === "") {
                this.amountStr = this.decimalPipe.transform(this.minAmount, this.numberFormat).replace(',', '.').replace(/\s/g, "");
            }
            else if (!isNaN(event.target.value.replace(',', '.'))) {
                this.amountStr = this.decimalPipe.transform(event.target.value.replace(',', '.'), this.numberFormat).replace(',', '.').replace(/\s/g, "");
            }
        }
    }
}
