import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ICanDeactivateGuard } from '../../../core/CanDeactivateGuard';
import { Classifier } from '../../../models/Classifier';
import {
    ICourseEqualization, ICourseEqualizationEditModel, ICourseEqualizationFile, ICourseEqualizationInner, ICourseEqualizationInnerEditModel,
    ICourseEqualizationOuter, ICourseEqualizationResident, ICourseEqualizationSupervisor
} from '../../../models/CourseEqualization';
import { IPersonSearchResultItem } from '../../../models/Person';
import { IResidencyCourse, IResidencySeminar } from '../../../models/Residency';
import { AppService } from '../../../services/app.service';
import { ClassifierService } from '../../../services/classifier.service';
import { CourseEqualizationService } from '../../../services/course-equalization.service';
import { createFileStub } from '../../../shared/file/file.component';
import { ITableColumn } from '../../../shared/table/table.component';
import { CourseEqualizationApprovalComponent } from '../approval/course-equalization-approval.component';
import { CourseEqualizationInnerComponent, IInnerCourseEditOptions } from '../inner/course-equalization-inner.component';
import { CourseEqualizationOuterComponent, IOuterCourseEditOptions } from '../outer/course-equalization-outer.component';
import { store, util } from '../shared';




interface ISeminarRow extends IResidencySeminar {
    selected: boolean;
}

/**
 * !!! Be aware !!!
 * - seminars functionality is just hidden in the template until RSU decide to bring it back or remove completely
 * - main attachment functionality is just hidden in the template until RSU decide to bring it back or remove completely
 */

@Component({
    selector: 'app-course-equalization-form',
    templateUrl: './course-equalization-form.component.html',
    styleUrls: ['../course-equalization.component.scss']
})
export class CourseEqualizationFormComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        private app: AppService,
        private service: CourseEqualizationService,
        private classifiers: ClassifierService,
        private modal: NgbModal,
        private route: ActivatedRoute
    ) { }

    studentPickerOpened: boolean;
    canPickStudent: boolean;
    notAvailable: boolean;
    residentInfo: ICourseEqualizationResident[] = [];
    resident: ICourseEqualizationResident;
    supervisor: ICourseEqualizationSupervisor;
    supervisorLoaded: boolean;
    courseOptions: IResidencyCourse[] = [];
    gradeOptions: Classifier[] = [];
    courseTypeOptions: Classifier[] = [];
    isNew: boolean;
    isReady: boolean;

    model: ICourseEqualization = <ICourseEqualization>{
        Attachments: [],
        Courses: [],
        Seminars: []
    };

    student: IPersonSearchResultItem;
    seminars: ISeminarRow[] = [];

    attachmentExtensions: string = '';
    attachmentMaxSize: number = 0;
    attachmentLimit: number = 0;

    canEdit: boolean;
    canApprove: boolean;
    canCreateProject: boolean;

    formSubmitted: boolean;
    isUnavailable: boolean;

    readonly getAttachmentNameDisplay = util.getAttachmentNameDisplay;
    readonly specialityDisplayFn = (option: ICourseEqualizationResident) => `${option.SpecialityCode} - ${option.SpecialityName}`;

    readonly outerColumns: ITableColumn[] = [
        { label: 'courseEqualization_lblCourse' },
        { label: 'courseEqualization_lblWeeks' },
        { label: 'courseEqualization_lblEcts' },
        { label: 'courseEqualization_lblTheoryPractice' },
        { label: 'courseEqualization_lblAttachments' },
        { width: '1px' }
    ];

    readonly seminarColumns: ITableColumn[] = [
        { width: '1px', label: 'courseEqualization_lblEqualize' },
        { label: 'courseEqualization_lblSeminarName' },
        { label: 'courseEqualization_lblLecturer' },
        { label: 'courseEqualization_lblLocation' }
    ];

    get isPowerUser() {
        return this.canApprove || this.canCreateProject;
    }

    get selectedCourseCount() {
        return this.model.Courses.reduce((sum, n) => {
            sum += (n.Courses || []).length;
            return sum;
        }, 0);
    }

    get selectedSeminarCount() {
        return this.seminars.filter(t => t.selected).length;
    }

    get totalAttachments() {
        return this.model.Attachments.filter(t => !!t.FileName).length
            + this.model.Courses.reduce((sum, n) => {
                sum += n.Courses.reduce((s, k) => {
                    s += k.Attachments.filter(a => !!a.FileName).length;
                    return s;
                }, 0);

                return sum;
            }, 0);
    }

    get canSubmit() {
        return this.model.Status == 'Draft' && this.app.currentUser.rights.indexOf('COURSE_EQUALIZATION.EDIT') > -1 && this.model.Courses.length > 0;
    }

    private originalData: string;
    private originalAttachments: {
        id: number,
        name: string,
        innerCourse?: string,
        innerCourseId?: number,
        outerCourse?: string,
        outerCourseId?: number
    }[] = [];
    private isSaved: boolean;
    private decisionOptions: Classifier[] = [];

    @ViewChild('form', { static: false }) private form: NgForm;

    ngOnInit() {
        const user = this.app.currentUser;
        const id = this.route.snapshot.params['id'];

        this.student = store.getStudent();

        const classifierCodes = 'CourseEqualizationStudyCourseType,CourseEqualizationDecision,Grade';

        this.app.addLoading(this.classifiers.get(classifierCodes)).subscribe(data => {
            this.courseTypeOptions = data.filter(t => t.Type == 'CourseEqualizationStudyCourseType');
            this.decisionOptions = data.filter(t => t.Type == 'CourseEqualizationDecision');

            const getDecisionOrder = (n: Classifier) => {
                switch (n.Code) {
                    case 'PassFull': return 1;
                    case 'PassPartial': return 2;
                    case 'Fail': return 3;
                    case 'Other': return 100;
                    default: return 4;
                }
            };

            this.decisionOptions.sort((a, b) => getDecisionOrder(a) - getDecisionOrder(b));

            const grades = data.filter(t => t.Type == 'Grade');

            grades.sort((a, b) => {
                return this.getGradeSortingNumber(b) - this.getGradeSortingNumber(a);
            });

            this.gradeOptions = grades;
        });

        if (!id) {
            this.isNew = true;
            this.model.Status = 'Draft';

            this.canEdit = user.rights.indexOf('COURSE_EQUALIZATION.EDIT') > -1;
            this.canPickStudent = user.rights.indexOf('COURSE_EQUALIZATION.SET_STUDENT') > -1;

            if (this.canPickStudent) {
                this.isReady = true;

                if (this.student) {
                    this.initEdit(this.student.Email);
                } else {
                    this.toggleStudentPicker();
                }
            } else {
                this.initEdit();
            }
        } else {
            this.loadById(+id);
        }
    }

    canDeactivate(): Observable<boolean> {
        if (!this.studentPickerOpened && !this.isSaved && this.hasChanges()) {
            const subj = new Subject<boolean>();
            const text = this.isNew ? 'courseEqualization_confirmUnsavedNew' : 'courseEqualization_confirmUnsavedEdit';

            this.app.confirm(this.app.translate(text), result => {
                subj.next(result);
            });

            return subj.asObservable();
        }

        return of(true);
    }

    onSpecialityChange() {
        this.resident = this.residentInfo.find(t => t.SpecialityId == this.model.SpecialityId);
        this.model.StudentEmail = this.resident.Email;

        this.loadConfig(this.model.SpecialityId, this.model.StudentEmail);
    }

    toggleStudentPicker() {
        this.studentPickerOpened = !this.studentPickerOpened;

        if (this.studentPickerOpened) {
            this.model.StudentEmail = undefined;
            this.model.SpecialityId = undefined;

            this.resident = undefined;
            this.residentInfo = [];
            this.notAvailable = false;
        }
    }

    pickStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.isUnavailable = false;
        this.model.StudentEmail = student.Email;
        this.initEdit(student.Email);
    }

    addCourse(inner?: ICourseEqualizationInner) {
        let outer = <ICourseEqualizationOuter>{
            Attachments: []
        };

        const ref = this.modal.open(CourseEqualizationOuterComponent);

        ref.componentInstance.data = outer;
        ref.componentInstance.options = <IOuterCourseEditOptions>{
            attachmentExtensions: this.attachmentExtensions,
            attachmentMaxSize: this.attachmentMaxSize,
            innerCourses: this.courseOptions,
            courseTypes: this.courseTypeOptions,
            grades: this.gradeOptions
        };

        if (inner) {
            ref.componentInstance.target = this.courseOptions.find(t => t.Code == inner.Code);
        }

        ref.result.then((result: { innerCourse: IResidencyCourse, outerCourse: ICourseEqualizationOuter }) => {
            const innerAfter = result.innerCourse;
            const outerAfter = result.outerCourse;

            let inner = this.model.Courses.find(t => t.Code == innerAfter.Code);

            if (!inner) {
                const course = this.courseOptions.find(t => t.Code == innerAfter.Code);
                inner = {
                    AcquireEcts: undefined,
                    AcquireWeeks: undefined,
                    Code: course.Code,
                    Ects: course.Ects,
                    Id: undefined,
                    Name: course.Name,
                    StudyYear: course.StudyYear,
                    Weeks: course.Weeks,
                    //PracticeGrade: model.PracticeGrade,
                    //PracticeGradeId: model.PracticeGradeId,
                    //TheoryGrade: model.TheoryGrade,
                    //TheoryGradeId: model.TheoryGradeId,
                    Courses: []
                };
                this.model.Courses.push(inner);
            }

            inner.Courses.push(outerAfter);
        }, () => { });
    }

    viewCourse(inner: ICourseEqualizationInner, outer: ICourseEqualizationOuter) {
        const ref = this.modal.open(CourseEqualizationOuterComponent);

        ref.componentInstance.options = <IOuterCourseEditOptions>{
            innerCourses: this.courseOptions,
            courseTypes: this.courseTypeOptions,
            grades: this.gradeOptions,
            disabled: !this.canEdit
        };
        ref.componentInstance.innerCourse = this.courseOptions.find(t => t.Code == inner.Code);
        ref.componentInstance.data = outer;
    }

    editCourse(inner: ICourseEqualizationInner, outer?: ICourseEqualizationOuter) {
        if (outer) {
            const ref = this.modal.open(CourseEqualizationOuterComponent);

            ref.componentInstance.options = <IOuterCourseEditOptions>{
                attachmentExtensions: this.attachmentExtensions,
                attachmentMaxSize: this.attachmentMaxSize,
                innerCourses: this.courseOptions,
                courseTypes: this.courseTypeOptions,
                grades: this.gradeOptions
            };
            ref.componentInstance.data = outer;
            ref.componentInstance.innerCourse = this.courseOptions.find(t => t.Code == inner.Code);

            ref.result.then((result: { innerCourse: IResidencyCourse, outerCourse: ICourseEqualizationOuter }) => {
                const innerAfter = result.innerCourse;
                const outerAfter = result.outerCourse;

                // update values
                for (const k in outerAfter) {
                    outer[k] = outerAfter[k];
                }

                let exInner = this.model.Courses.find(t => t.Code == innerAfter.Code);

                if (!exInner) {
                    const course = this.courseOptions.find(t => t.Code == innerAfter.Code);

                    exInner = {
                        AcquireEcts: undefined,
                        AcquireWeeks: undefined,
                        Code: course.Code,
                        Ects: course.Ects,
                        Id: undefined,
                        Name: course.Name,
                        StudyYear: course.StudyYear,
                        Weeks: course.Weeks,
                        Courses: [outer]
                    };

                    this.model.Courses.push(exInner);
                } else if (innerAfter.Code != inner.Code) {
                    // move to selected inner course
                    exInner.Courses.push(outer);
                    this.removeCourse(inner, outer, false);
                }
            }, () => { });
        } else {
            const opts = <IInnerCourseEditOptions>{
                grades: this.gradeOptions,
                enrollYears: []
            };

            if (this.resident.SpecialityYears) {
                for (let i = 0; i < this.resident.SpecialityYears; i++) {
                    opts.enrollYears.push(i + 1);
                }
            }

            if (inner.Courses.length == 1) {
                opts.defaultPracticeGradeId = inner.Courses[0].PracticeGradeId;
                opts.defaultTheoryGradeId = inner.Courses[0].TheoryGradeId;
            }

            const ref = this.modal.open(CourseEqualizationInnerComponent, { size: 'lg' });

            ref.componentInstance.options = opts;
            ref.componentInstance.data = inner;

            ref.result.then(({ model }) => {
                for (const k in model) {
                    inner[k] = model[k];
                }
            }, () => { });
        }
    }

    removeCourse(inner: ICourseEqualizationInner, outer?: ICourseEqualizationOuter, confirm: boolean = true) {
        const proceed = () => {
            if (outer) {
                const ix = inner.Courses.indexOf(outer);
                inner.Courses.splice(ix, 1);
            } else {
                const ix = this.model.Courses.indexOf(inner);
                this.model.Courses.splice(ix, 1);
            }
        };

        if (confirm) {
            this.app.confirm({
                text: this.app.translate('courseEqualization_confirmCourseRemove').replace('{{course}}', outer ? outer.Name : inner.Name),
                title: this.app.translate('courseEqualization_removeCourse')
            }, ok => {
                if (ok) {
                    proceed();
                }
            });
        } else {
            proceed();
        }
    }

    save() {
        this.formSubmitted = true;
        if (!this.validate()) return;

        this.app.addLoading(this.doSave()).subscribe(complete => {
            if (complete) {
                this.app.notify(this.app.translate('courseEqualization_saved'));
                this.app.navigateByUrl('/course-equalizations');
            } else {
                this.reload();
            }
        });
    }

    submit() {
        this.formSubmitted = true;
        if (!this.validate()) return;

        this.app.addLoading(this.doSave()).subscribe(complete => {
            if (complete) {
                this.app.addLoading(this.service.submit(this.model.Id)).subscribe(() => {
                    this.app.notify(this.app.translate('courseEqualization_submitted'));
                    this.app.navigateByUrl('/course-equalizations');
                }, err => {
                    this.app.showError(err);
                    this.reload();
                });
            } else {
                this.reload();
            }
        });
    }

    approve() {
        this.formSubmitted = true;
        if (!this.validate()) return;

        this.app.addLoading(this.doSave()).subscribe(complete => {
            if (complete) {
                this.app.addLoading(this.service.approve(this.model.Id)).subscribe(() => {
                    this.app.notify(this.app.translate('courseEqualization_approved'));
                    this.app.navigateByUrl('/course-equalizations');
                }, err => {
                    this.app.showError(err);
                    this.reload();
                });
            } else {
                this.reload();
            }
        });
    }

    createProject() {
        this.formSubmitted = true;
        if (!this.validate()) return;

        const ref = this.modal.open(CourseEqualizationApprovalComponent);
        ref.componentInstance.decisionOptions = this.decisionOptions;
        ref.result.then((result: { decision: Classifier, comment: string }) => {
            if (result) {
                this.app.addLoading(this.doSave()).subscribe(complete => {
                    if (complete) {
                        this.app.addLoading(this.service.createProject(this.model.Id, result.decision.Id, result.comment)).subscribe(() => {
                            this.app.notify(this.app.translate('courseEqualization_projectCreated'));
                            this.app.navigateByUrl('/course-equalizations');
                        }, err => {
                            this.app.showError(err);
                            this.reload();
                        });
                    } else {
                        this.reload();
                    }
                });
            }
        }, () => { });
    }

    addAttachment() {
        this.model.Attachments.push(<ICourseEqualizationFile>{});
    }

    removeAttachment(file: ICourseEqualizationFile) {
        const i = this.model.Attachments.indexOf(file);

        if (i > -1) {
            this.model.Attachments.splice(i, 1);
        }
    }

    onFileChange(event: File, file: ICourseEqualizationFile) {
        if (!event) {
            this.removeAttachment(file);
        } else {
            file.Id = null;
            file.FileName = event.name;
        }
    }

    checkDuplicateFileName(file: ICourseEqualizationFile): boolean {
        if (!file.File) return false;
        return this.model.Attachments.filter(t => t.FileName == file.File.name).length > 1;
    }

    downloadAttachment(attachment: ICourseEqualizationFile) {
        util.downloadAttachment(this.app, this.service, attachment);
    }

    private validate() {
        if (!this.form.valid) {
            this.app.alert.error(this.app.translate('courseEqualization_formError'));
            return false;
        }

        if (this.totalAttachments > this.attachmentLimit) {
            this.app.alert.error(this.app.translate('courseEqualization_maxFileCountExceededDetailed').replace('{0}', this.attachmentLimit));
            return false;
        }

        return true;
    }

    private loadById(id: number) {
        this.isSaved = false;

        this.app.addLoading(this.service.getById(id)).subscribe(data => {
            const user = this.app.currentUser;

            this.model = data;

            this.canApprove = data.Status == 'Submitted'
                && (user.rights.indexOf('COURSE_EQUALIZATION.APPROVE') > -1 || user.rights.indexOf('COURSE_EQUALIZATION.PROJECT') > -1);

            this.canCreateProject = data.Status == 'Approved' && user.rights.indexOf('COURSE_EQUALIZATION.PROJECT') > -1;
            this.canEdit = this.canSubmit || this.canApprove || this.canCreateProject;

            data.Attachments.forEach(t => {
                t.File = createFileStub(t.FileName);
            });

            this.resident = {
                Email: data.StudentEmail,
                Name: data.PersonName,
                PersonCode: data.PersonCode,
                PersonId: data.PersonId,
                SpecialityCode: data.SpecialityCode,
                SpecialityId: data.SpecialityId,
                SpecialityName: data.SpecialityName,
                Status: data.Status,
                StudentId: data.StudentId,
                StudyYear: data.StudyYear,
                Surname: data.PersonSurname,
                ContactPersonId: data.ContactPersonId,
                ContactPersonName: data.ContactPersonName
            };

            this.supervisor = data.SupervisorId ? {
                FirstName: data.SupervisorFirstName,
                Id: data.SupervisorId,
                LastName: data.SupervisorLastName
            } : null;

            this.supervisorLoaded = true;

            this.seminars = data.Seminars.map(t => {
                return {
                    AdditionalInformation: undefined,
                    Id: t.ExternalId,
                    Instructor: t.Lecturer,
                    IsRemote: undefined,
                    Location: t.Location,
                    StudyYear: undefined,
                    Theme: t.Theme,
                    AttendancyStatus: undefined,
                    Date: undefined,
                    selected: true
                }
            });

            this.courseOptions = data.Courses.map(t => {
                return {
                    Code: t.Code,
                    Dates: undefined,
                    Ects: t.Ects,
                    FinalGrade: undefined,
                    Grades: undefined,
                    Lecturer: undefined,
                    Name: t.Name,
                    Place: undefined,
                    Status: undefined,
                    StudyYear: t.StudyYear,
                    Weeks: t.Weeks
                };
            });

            this.originalData = JSON.stringify(data);

            this.originalAttachments = data.Attachments.map(t => {
                return {
                    id: t.Id,
                    name: t.FileName
                };
            });

            data.Courses.forEach(c => {
                c.Courses.forEach(n => {
                    const outerFiles = n.Attachments.map(t => {
                        return {
                            id: t.Id,
                            name: t.FileName,
                            innerCourse: `${c.Code} ${c.Name}`,
                            innerCourseId: c.Id,
                            outerCourse: n.Name,
                            outerCourseId: n.Id
                        };
                    });
                    this.originalAttachments.push(...outerFiles);
                });
            });

            if (this.canEdit) {
                this.initEdit(data.StudentEmail, data.SpecialityId);
            } else {
                this.isReady = true;
            }
        });
    }

    private loadResident(email?: string, specialityId?: string) {
        const subj = new Subject<boolean>();

        this.app.addLoading(this.service.getResident(email)).subscribe(data => {
            let success = false;

            this.residentInfo = data;

            if (data.length) {
                let res: ICourseEqualizationResident;

                if (specialityId) {
                    res = data.filter(t => t.SpecialityId.toLowerCase() == specialityId.toLowerCase())[0];
                }

                if (!res) {
                    res = data[0];
                }

                this.model.StudentEmail = res.Email;
                this.model.SpecialityId = res.SpecialityId;
                this.resident = res;

                if (!this.student) {
                    this.student = <IPersonSearchResultItem>{
                        Email: res.Email,
                        FirstName: res.Name,
                        LastName: res.Surname
                    };
                    store.setStudent(this.student);
                }

                success = true;
            } else {
                this.isUnavailable = true;
            }

            subj.next(success);
        });

        return subj.asObservable();
    }

    private loadConfig(specialityId: string, email?: string) {
        const subj = new Subject();

        this.app.addLoading(this.service.getConfig(specialityId, email)).pipe(finalize(() => {
            this.supervisorLoaded = true;
        })).subscribe(data => {
            this.courseOptions = data.Courses;
            this.seminars = data.Seminars.map(t => {
                return {
                    ...t,
                    selected: this.model.Seminars.some(s => s.ExternalId == t.Id)
                }
            });
            this.supervisor = data.Supervisor;

            this.attachmentMaxSize = data.FileMaxSize;
            this.attachmentLimit = data.FileLimit;
            this.attachmentExtensions = '.' + data.FileExtensions.join(',.');

            subj.next();
        });

        return subj.asObservable();
    }

    private initEdit(email?: string, specialityId?: string) {
        this.loadResident(email, specialityId).subscribe((success) => {
            if (success) {
                this.loadConfig(this.model.SpecialityId, this.model.StudentEmail).subscribe(() => {
                    this.isReady = true;
                }, err => {
                    this.isReady = true;
                });
            }
        }, err => {
            this.isReady = true;
        });
    }

    /**
     * Save form values to the database.
     * @returns A subject indicating save status:
     * true means that everything was successfully saved, false means that an error occured while processing files.
     */
    private doSave() {
        let id: number;

        const subj = new Subject<boolean>();
        const model: ICourseEqualizationEditModel = {
            Courses: this.model.Courses.map(t => {
                return <ICourseEqualizationInnerEditModel>{
                    Id: t.Id,
                    Code: t.Code,
                    Courses: t.Courses.map(n => {
                        return {
                            Id: n.Id,
                            Ects: n.Ects,
                            FurtherEducationPoints: n.FurtherEducationPoints,
                            Name: n.Name,
                            Notes: n.Notes,
                            PracticeGradeId: n.PracticeGradeId,
                            Programme: n.Programme,
                            TheoryGradeId: n.TheoryGradeId,
                            TypeId: n.TypeId,
                            University: n.University,
                            Weeks: n.Weeks
                        };
                    }),
                    AcquireEcts: t.AcquireEcts,
                    AcquireWeeks: t.AcquireWeeks,
                    PracticeGradeId: t.PracticeGradeId,
                    TheoryGradeId: t.TheoryGradeId,
                    IsFullCredit: t.IsFullCredit,
                    IsEqualizedWithNoGrade: t.IsEqualizedWithNoGrade,
                    EnrollYear: t.EnrollYear
                };
            }),
            Seminars: this.seminars.filter(t => t.selected).map(t => {
                return {
                    ExternalId: t.Id
                };
            }),
            SpecialityId: this.model.SpecialityId,
            StudentEmail: this.model.StudentEmail
        };

        const primary = this.isNew ? this.service.create(model) : this.service.update(this.model.Id, model);

        primary.subscribe(result => {
            this.model.Id = id = result.Id;

            result.Courses.forEach((t, i) => {
                t.Courses.forEach((n, j) => {
                    const courseModel = this.model.Courses[i].Courses[j];

                    if (!courseModel) {
                        // returned courses must be the same user submitted
                        throw new Error(this.app.translate('courseEqualization_saveError'));
                    }

                    courseModel.Id = n.Id;
                });
            });

            const failedToAdd = this.app.translate('courseEqualization_fileAddFailed');
            const failedToRemove = this.app.translate('courseEqualization_fileRemoveFailed');

            const fileErrors: {
                innerCourse?: string,
                outerCourse?: string,
                name: string,
                error: string
            }[] = [];

            const showFileErrors = () => {
                const message = fileErrors.map((t, i) => {
                    const course = t.outerCourse ? `${t.outerCourse} / ${t.innerCourse}` : undefined;
                    return `<div class="mt-${i > 0 ? 1 : 0}">`
                        + (course ? `<div class="text-truncate" title="${course}"><small>${course}</small></div>` : '')
                        + `<div><strong>${t.name}</strong></div>`
                        + `<div class="text-danger">${t.error}</div></div>`;
                }).join('');

                this.app.showError(message);
            };

            const filesToRemove = this.originalAttachments.filter(oa => {
                let rem = true;

                if (this.model.Attachments.find(ma => ma.Id == oa.id)) {
                    // file still exists as main form attachment
                    rem = false;
                } else {
                    const ownerCourse = result.Courses.some(ic => ic.Courses.some(oc => oc.Id == oa.outerCourseId));

                    // ensure owner course still exists, as it might be already deleted with all included attachments by the primary save
                    if (ownerCourse) {
                        this.model.Courses.forEach(ic => {
                            ic.Courses.forEach(oc => {
                                if (oc.Attachments.find(a => a.Id == oa.id)) {
                                    // file still exists as outer course attachment
                                    rem = false;
                                }
                            });
                        });
                    } else {
                        rem = false;
                    }
                }

                return rem;
            }).map(t => {
                return this.app.addLoading(this.service.removeAttachment(t.id)).pipe(tap(() => {
                    const ix = this.originalAttachments.indexOf(t);
                    this.originalAttachments.splice(ix, 1);
                }), catchError(err => {
                    fileErrors.push({
                        name: t.name,
                        innerCourse: t.innerCourse,
                        outerCourse: t.outerCourse,
                        error: failedToRemove + ' ' + this.app.getHttpResponseError(err)
                    });
                    return of(0);
                }));
            });

            const filesToAdd = this.model.Attachments.filter(t => !!t.File?.size).map(t => {
                return this.app.addLoading(this.service.addAttachment(id, t.File)).pipe(tap(() => {
                    t.File = null;
                }), catchError(err => {
                    fileErrors.push({
                        name: t.File.name,
                        error: failedToAdd + ' ' + this.app.getHttpResponseError(err)
                    });
                    return of(0);
                }));
            });

            this.model.Courses.forEach(c => {
                c.Courses.forEach(n => {
                    const outerFiles = n.Attachments.filter(a => !!a.File?.size);

                    outerFiles.forEach(f => {
                        filesToAdd.push(this.app.addLoading(this.service.addAttachment(id, f.File, n.Id)).pipe(tap(() => {
                            f.File = null;
                        }), catchError(err => {
                            fileErrors.push({
                                name: f.File.name,
                                innerCourse: `${c.Code} ${c.Name}`,
                                outerCourse: n.Name,
                                error: failedToAdd + ' ' + this.app.getHttpResponseError(err)
                            });
                            return of(0);
                        })));
                    });
                });
            });

            const afterFileRemoval = () => {
                if (filesToAdd.length) {
                    forkJoin(filesToAdd).subscribe(() => {
                        if (fileErrors.length) {
                            showFileErrors();
                            subj.next(false);
                        } else {
                            subj.next(true);
                        }
                    });
                } else {
                    subj.next(true);
                }
            };

            if (filesToRemove.length) {
                forkJoin(filesToRemove).subscribe(() => {
                    if (fileErrors.length) {
                        showFileErrors();
                    }

                    afterFileRemoval();
                });
            } else {
                afterFileRemoval();
            }
        });

        return subj.asObservable();
    }

    private reload() {
        if (this.isNew) {
            this.app.navigateByUrl('/course-equalizations/' + this.model.Id);
        } else {
            this.loadById(this.model.Id);
        }
    }

    private getGradeSortingNumber(grade: Classifier): number {
        return grade.Code === 'Passed' ? -1 : grade.Code === 'Failed' ? -2 : +grade.Code;
    }

    private hasChanges() {
        const currentData = JSON.stringify(this.model, (key, value) => {
            return value instanceof File ? createFileStub(value.name) : value;
        });

        return currentData != this.originalData;
    }
}
