<div class="wrapper-lg">
    <app-breadcrumbs [items]="[
                 { url: '/messages', text: 'messages_title' },
                 { url: '/messages/' + (isNew ? 'add' : item.Id), text: isNew ? 'messages_newtitle' : title }
                 ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{(isNew ? 'messages_newtitle' : 'messages_edittitle') | translate}}</h1>
    </header>

    <form #form="ngForm">
        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'messages_code' | translate}}</label>
                <input class="form-control" required name="CodeInput" [(ngModel)]="item.Code" />
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'messages_coltitle' | translate}}</label>
                <input class="form-control" required name="TitleInput" [(ngModel)]="item.Title" />
            </div>
        </div>

        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'messages_colgroup' | translate}}</label>
                <input class="form-control" required name="GroupInput" [(ngModel)]="item.Group" />
            </div>

            <div class="col form-field">
                <label class="form-label">{{'messages_colUserRights' | translate}}</label>
                <app-select [(ngModel)]="item.UserRights"
                            [options]="userRights"
                            [valueProperty]="'Code'"
                            [displayProperty]="'Text'"
                            name="userRights"></app-select>
            </div>
        </div>

        <div class="form-field">
            <label class="form-label">{{'messages_colTextLv' | translate}}</label>
            <editor *ngIf="isRichEditor()" [(ngModel)]="item.TextLV" name="TextLVInput" [init]="editorConfig"></editor>
            <textarea *ngIf="!isRichEditor()" class="form-control" [(ngModel)]="item.TextLV" name="TextLVInput" rows="5"></textarea>
        </div>

        <div class="form-field">
            <label class="form-label">{{'messages_colTextEn' | translate}}</label>
            <editor *ngIf="isRichEditor()" [(ngModel)]="item.TextEN" name="TextENInput" [init]="editorConfig"></editor>
            <textarea *ngIf="!isRichEditor()" class="form-control" [(ngModel)]="item.TextEN" name="TextENInput" rows="5"></textarea>
        </div>

        <app-alert level="info">
            <div [innerHTML]="'messages_description' | translate | safeHtml"></div>
        </app-alert>

        <div class="form-actions">
            <button type="button" class="btn btn-outline" [routerLink]="['/messages'] | localize">
                {{'cancel' | translate}}
            </button>

            <button class="btn btn-primary" type="button" (click)="save()">
                {{'templates_Add' | translate}}
            </button>
        </div>
    </form>
</div>
