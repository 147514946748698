<div *ngIf="canDownloadXml" class="data-value">
    <label>{{'portfolio_lblSource' | translate}}</label>
    <div>
        <a href="#" (click)="$event.preventDefault(); downloadXml()">
            <i class="fi fi-rs-download"></i> ELMO (XML)
        </a>
    </div>
</div>

<div class="data-value">
    <label>{{'portfolio_lblAttachments' | translate}}</label>
    <div>
        <div *ngFor="let n of attachments">
            <a href="#" (click)="$event.preventDefault(); downloadAttachment(n)">
                <i class="fi fi-rs-download"></i> {{prettyPath(n.Path)}}
            </a>
        </div>
    </div>
</div>

<section class="block" *ngFor="let report of reports">
    <h5>{{report.data.Issuer}}</h5>

    <app-table [columns]="columns" [rows]="report.rows" pageSize="1000">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    <div class="spec-title">
                        <span class="pad">
                            <span *ngFor="let n of pads[row.level]">&mdash;</span>
                        </span>
                        <span>{{row.Title}}</span>
                    </div>
                </td>
                <td>{{row.Type}}</td>
                <td>{{row.Date | appDate}}</td>
                <td>{{row.Credits}} {{row.CreditType}}</td>
                <td>{{row.Result}}</td>
            </tr>
        </ng-template>
    </app-table>
</section>
