<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/rs-tickets', text: 'rsTickets_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'rsTickets_title'"></h1>
        <aside *ngIf="canPickStudent && !showStudentPicker && !isSubmit">
            <button type="button" class="btn btn-outline" (click)="showStudentPicker = !showStudentPicker">
                <i class="fi fi-rs-user"></i>
                {{'rsTickets_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <form [action]="formUrl" method="post" #form *ngIf="isSubmit && isLoaded && item">
        <input name="FIRST_NAME" type="hidden" [value]="item.FirstName">
        <input name="LAST_NAME" type="hidden" [value]="item.LastName">
        <input name="PERSON_ID" type="hidden" [value]="item.PersonCode">
        <input name="BIRTHDATE" type="hidden" [value]="item.Birthdate | date:'yyyy-MM-dd'">
        <input name="EXPIRATION_DATE" type="hidden" [value]="item.ExpirationDate | date:'yyyy-MM-dd'">
        <input name="ROLE" type="hidden" [value]="role">
        <input name="LANGUAGE" type="hidden" [value]="language">
        <input name="MAC" type="hidden" [value]="MAC">
    </form>

    <app-person-picker *ngIf="showStudentPicker" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="!showStudentPicker">
        <app-alert level="info" *ngIf="isSubmit">{{'rsTicketsMessage' | translate}}</app-alert>

        <app-alert level="warning" *ngIf="isUnavailable && !isSubmit">
            <div [innerHTML]="unavailableText | safeHtml"></div>
        </app-alert>

        <div *ngIf="!isUnavailable && !isSubmit && isLoaded">
            <app-alert level="danger" *ngIf="!item">
                {{'rsTickets_studentNotFound' | translate}}
            </app-alert>

            <section class="block" *ngIf="item">
                <div class="row">
                    <div class="data-value col-sm">
                        <label>
                            {{'rsTickets_lblFirstName' | translate}}
                        </label>
                        <div>
                            {{item.FirstName}}
                        </div>
                    </div>

                    <div class="data-value col-sm">
                        <label>
                            {{'rsTickets_lblLastName' | translate}}
                        </label>
                        <div>
                            {{item.LastName}}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-sm">
                        <label>
                            {{'rsTickets_lblPersonCode' | translate}}
                        </label>
                        <div>
                            {{item.PersonCode}}
                        </div>
                    </div>

                    <div class="data-value col-sm">
                        <label>
                            {{'rsTickets_lblBirthdate' | translate}}
                        </label>
                        <div>
                            {{item.Birthdate | appDate}}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-sm">
                        <label>
                            {{'rsTickets_lblStatus' | translate}}
                        </label>
                        <div>
                            {{'rsTickets_status_' + item.Status | translate}}
                        </div>
                    </div>

                    <div class="data-value col-sm">
                        <label>
                            {{'rsTickets_lblExpirationDate' | translate}}
                        </label>
                        <div>
                            {{item.ExpirationDate | appDate}}
                        </div>
                    </div>
                </div>

                <div *ngIf="item.CanApply" class="form-field">
                    <app-checkbox [(ngModel)]="userAgreed">
                        <span [innerHTML]="'rsTickets_personDataAgreement' | translate | safeHtml"></span>
                    </app-checkbox>
                </div>

                <app-alert level="danger" *ngIf="!item.CanApply">
                    <div [innerHTML]="'rsTickets_cannotApply' | translate | safeHtml"></div>
                </app-alert>

                <div class="block-actions" *ngIf="item.CanApply">
                    <button type="button"
                            [routerLink]="['/rs-tickets/submit'] | localize"
                            [queryParams]="{ email: getUserEmail() }"
                            target="_blank"
                            class="btn btn-primary btn-icon-last"
                            [disabled]="!userAgreed">
                        {{'rsTickets_btnSubmit' | translate}}
                        <i class="fi fi-rs-angle-right"></i>
                    </button>
                </div>
            </section>

            <section class="block" *ngIf="item">
                <h5>{{'rsTicketsHistory_title' | translate}}</h5>

                <app-table [rows]="history" [columns]="historyColumns">
                    <ng-template #rowTemplate let-row let-i="index">
                        <tr>
                            <td class="hidden-md-down">{{i + 1}}</td>
                            <td>{{row.SubmitTime | date:'dd.MM.yyyy HH:mm'}}</td>
                            <td>{{row.ExpirationDate | date:'dd.MM.yyyy'}}</td>
                        </tr>
                    </ng-template>
                </app-table>
            </section>
        </div>
    </ng-container>
</div>
