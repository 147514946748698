import { Component, Input, OnInit } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

import { ApplicationType } from '../../models/Application';
import { Classifier } from '../../models/Classifier';

import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ClassifierService } from '../../services/classifier.service';

interface IGroup {
    id: string;
    title: string;
    code: string;
    items: IEntry[];
    sortOrder: number;
    visible?: boolean;
}

interface IEntry {
    title: string;
    code: string;
    visible?: boolean;
    icon?: string;
    url: {
        route: string,
        params: { [key: string]: any }
    };
}

const cache: {
    types?: string[],
    classifiers?: Classifier[]
} = { };

let groupState: { [key: string]: boolean } = {};

@Component({
    selector: 'app-application-index-body',
    templateUrl: './index-body.component.html',
    styleUrls: ['./application.component.scss']
})
export class IndexBodyComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: ApplicationService,
        private classifiers: ClassifierService
    ) { }

    @Input() showNoItemAvailable: boolean;
    @Input() showNav: boolean;

    groups: IGroup[] = [];
    noItemAvailable: boolean;
    openedGroupIds: string[] = [];

    private readonly rightCodes = {
        [ApplicationType.Status]: 'STATUS',
        [ApplicationType.Applicant]: 'APPLICANT',
        [ApplicationType.Grades]: 'GRADES',
        [ApplicationType.MatriculationStatus]: 'MATRICULATION_STATUS',
        [ApplicationType.Skills]: 'SKILLS',
        [ApplicationType.AcademicLeave]: 'ACADEMIC_LEAVE',
        [ApplicationType.AcademicLeaveDoctor]: 'ACADEMIC_LEAVE_DOC',
        [ApplicationType.Exmatriculation]: 'EXMATRICULATION',
        [ApplicationType.JustifiedAbsence]: 'JUSTIFIED_ABSENCE',
        [ApplicationType.RepeatCourse]: 'REPEAT_COURSE',
        [ApplicationType.CourseAlignment]: 'COURSE_ALIGNMENT',
        [ApplicationType.Complaint]: 'COMPLAINT',
        [ApplicationType.AppealComplaint]: 'APPEAL_COMPLAINT',
        [ApplicationType.ScholarshipComplaint]: 'SCHOLARSHIP_COMPLAINT',
        [ApplicationType.FreeForm]: 'FREE_FORM',
        [ApplicationType.PersonData]: 'PERSON_DATA',
        [ApplicationType.PersonPhoto]: 'PERSON_PHOTO',
        [ApplicationType.PaymentTermExtension]: 'PAYMENT_TERM_EXTENSION',
        [ApplicationType.Discount]: 'DISCOUNT',
        [ApplicationType.Repayment]: 'REPAYMENT',
        [ApplicationType.AcademicReference]: 'ACADEMIC_REFERENCE'
    };

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            if (cache.types) {
                this.init(cache.types, cache.classifiers);
            } else {
                this.app.addLoading(forkJoin(
                    this.service.getAvailableTypes(),
                    this.classifiers.get('ApplicationGroup,ApplicationType')
                )).subscribe(result => {
                    const [availableTypes, data] = result;

                    cache.types = availableTypes;
                    cache.classifiers = data;

                    this.init(availableTypes, data);
                }, err => {
                    // do nothing
                });
            }
        }
    }

    onPanelChange(event: NgbPanelChangeEvent) {
        groupState[event.panelId] = event.nextState;
    }

    private init(availableTypes: string[], data: Classifier[]) {
        const rights = this.app.currentUser.rights || [];

        const groups: IGroup[] = data.filter(t => t.Type === 'ApplicationGroup').map(t => {
            let payload: any;

            try {
                payload = JSON.parse(t.Payload);
            } catch (err) { }

            if (!payload) {
                payload = { SortOrder: 0 };
            }

            return {
                id: t.Id,
                code: t.Code,
                items: [],
                sortOrder: +payload.SortOrder,
                title: t.Value,
                icon: payload?.Icon,
                visible: false
            };
        });

        groups.sort((a, b) => a.sortOrder - b.sortOrder);

        const appTypePrefixLen = 'ApplicationType'.length;

        data.filter(t => t.Type === 'ApplicationType').forEach(t => {
            let payload: any;

            try {
                payload = JSON.parse(t.Payload);
            } catch (err) { }

            if (!payload) {
                payload = {};
            }

            const group = groups.find(g => g.code === payload.Group);

            if (group) {
                const type = t.Code.substr(appTypePrefixLen);

                if (availableTypes.indexOf(type) > -1) {
                    const isSkills = t.Code === 'ApplicationTypeSkills';

                    group.items.push({
                        code: t.Code,
                        title: t.Value,
                        url: {
                            route: isSkills ? '/skills' : '/application',
                            params: isSkills ? {} : { type }
                        },
                        visible: rights.indexOf(`APPLICATION.LIST.${this.rightCodes[type] || type}`) > -1
                    });
                }
            }
        });

        groups.forEach(t => {
            t.visible = t.items.some(i => i.visible);
            t.items.sort((a, b) => a.title.localeCompare(b.title));

            if (groupState[t.id] === true) {
                this.openedGroupIds.push(t.id);
            }
        });

        this.groups = groups;
        this.noItemAvailable = !this.groups.some(t => t.visible);
    }
}
