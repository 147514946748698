<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/itic', text: 'itic_applyForITICCard' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'itic_applyForITICCard' | translate}}</h1>
    </header>

    <div class="content-desc" [innerHTML]="beforeText | safeHtml" *ngIf="beforeText"></div>

    <div class="itic-container" *ngIf="isReady">
        <section class="block">
            <app-alert level="warning" *ngIf="isUnavailable">
                <div [innerHTML]="unavailableText | safeHtml"></div>
            </app-alert>

            <ng-container *ngIf="!isUnavailable">
                <ng-container *ngIf="isInfoLoaded">
                    <div class="block" *ngIf="iticError==''">
                        <app-checkbox name="iticAccepted" [(ngModel)]="iticAccepted">
                            <span>{{'itic_acceptDataSend' | translate}} </span>
                            <a href="#" (click)="openRules($event)">{{'itic_iticLinkText' | translate}}</a>
                        </app-checkbox>
                    </div>

                    <div *ngFor="let g of personDisplayRows" class="row">
                        <div *ngFor="let n of g" class="data-value col-sm">
                            <label>{{n.label}}</label>
                            <div>{{n.value || '-'}}</div>
                        </div>
                    </div>

                    <div class="profile-picture mb-3" *ngIf="showPicture">
                        <img [src]="showPicture" />
                    </div>

                    <app-alert level="danger" *ngIf="iticError!=''">{{iticError}}</app-alert>

                    <div class="block-actions">
                        <button class="btn btn-primary" type="button" (click)="sendData()" [disabled]="!iticAccepted || !personInfoValid">
                            {{'itic_sendData' | translate}}
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </section>

        <section class="block" *ngIf="isHistoryLoaded && !isUnavailable">
            <h5 [translate]="'itic_iticRequestHistory'"></h5>

            <app-table [rows]="history" [columns]="historyColumns" [pageSize]="pageSize" [(page)]="historyPage">
                <ng-template #rowTemplate let-row let-i="index">
                    <tr>
                        <td class="hidden-md-down">{{i + 1}}</td>
                        <td>{{row.ApplicationDate | appDate}}</td>
                        <td class="hidden-sm-down">
                            <table class="history-info">
                                <tbody>
                                    <tr *ngFor="let info of row.ApplicationInfo">
                                        <td>{{info.StatusDate | appDate}}</td>
                                        <td>{{info.StatusInfo | translate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="hidden-md-up">
                            <div class="table-actions">
                                <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'itic_showDetails' | translate">
                                    <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isExpanded(row)" class="table-row-details hidden-md-up">
                        <td colspan="9">
                            <table class="history-info">
                                <tbody>
                                    <tr *ngFor="let info of row.ApplicationInfo">
                                        <td>{{info.StatusDate | appDate}}</td>
                                        <td>{{info.StatusInfo | translate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-template>
            </app-table>
        </section>

        <app-alert level="info" *ngIf="afterText">
            <div [innerHTML]="afterText | safeHtml"></div>
        </app-alert>
    </div>
</div>
