import { Component, OnInit } from '@angular/core';

import { IEbusSessionConfigListItem } from '../../models/Ebus';

import { AppService } from '../../services/app.service';
import { EbusService } from '../../services/ebus.service';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
    selector: 'app-ebus-session-config-list',
    templateUrl: './list.component.html'
})
export class EbusSessionConfigListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: EbusService
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'Code', label: 'ebusSessionConfig_lblCode', sorts: true },
        { property: 'Description', label: 'ebusSessionConfig_lblDescription', sorts: true },
        { width: '1px' }
    ];

    items: IEbusSessionConfigListItem[] = [];

    ngOnInit() {
        this.app.addLoading(this.service.getSessionConfigs()).subscribe(data => {
            this.items = data;
        });
    }
}
