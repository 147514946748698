import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import {
    IPlanSummaryDocument,
    IPlanSummaryDocumentFile,
    IPlanSummaryEventSession, IPlanSummaryLecturer, IPlanSummaryManipulation, IPlanSummaryResident,
    IPlanSummaryRotations, IPlanSummarySeminar, IPlanSummaryShifts, IPlanSummarySupervisor
} from '../models/PlanSummary';
import { AuthService } from './auth.service';

/**
 * Study plan summary service
 */
@Injectable({ providedIn: "root" })
export class PlanSummaryService {
    constructor(public http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/planSummary` }

    /**
     * Get rotations.
     * @param options
     */
    getRotations(options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<IPlanSummaryRotations> {
        const url = `${this.apiUrl}/rotations`;
        const params = this.buildQueryParams(options);

        return this.http.get<IPlanSummaryRotations>(url, { params });
    }

    /**
     * Get manipulations.
     * @param options
     */
    getManipulations(options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<IPlanSummaryManipulation[]> {
        const url = `${this.apiUrl}/manipulations`;
        const params = this.buildQueryParams(options);

        return this.http.get<IPlanSummaryManipulation[]>(url, { params });
    }

    /**
     * Get seminars.
     * @param options
     */
    getSeminars(options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<IPlanSummarySeminar[]> {
        const url = `${this.apiUrl}/seminars`;
        const params = this.buildQueryParams(options);

        return this.http.get<IPlanSummarySeminar[]>(url, { params });
    }

    /**
     * Get on-call shifts.
     * @param options
     */
    getShifts(options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<IPlanSummaryShifts> {
        const url = `${this.apiUrl}/shifts`;
        const params = this.buildQueryParams(options);

        return this.http.get<IPlanSummaryShifts>(url, { params });
    }

    /**
     * Get supervisor.
     * @param options
     */
    getSupervisor(options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<IPlanSummarySupervisor> {
        const url = `${this.apiUrl}/supervisor`;
        const params = this.buildQueryParams(options);

        return this.http.get<IPlanSummarySupervisor>(url, { params });
    }

    /**
     * Get resident by ID or email.
     * @param options
     */
    getResident(options: { studentId?: string, studentEmail?: string }): Observable<IPlanSummaryResident[]> {
        const url = `${this.apiUrl}/resident`;
        const params = this.buildQueryParams(options);

        return this.http.get<IPlanSummaryResident[]>(url, { params });
    }

    /**
     * Get plan summary report download URL.
     * @param fileId
     * @param options
     */
    getFileUrl(fileId: string, options?: { studentId?: string, studentEmail?: string }): string {
        let url = `${this.apiUrl}/files/${fileId}?SessionKey=${this.auth.sessionKey}`;

        if (options) {
            url += (options.studentId ? `&studentId=${options.studentId}` : options.studentEmail ? `&email=${options.studentEmail}` : '');
        }

        return url;
    }

    /**
     * Initialize an event session.
     * @param options
     */
    initEventSession(options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<IPlanSummaryEventSession> {
        const url = `${this.apiUrl}/eventSession`;
        const params = this.buildQueryParams(options);

        return this.http.post<IPlanSummaryEventSession>(url, null, { params });
    }

    /**
     * Get lecturers.
     * @param options
     */
    getLecturers(search: string, options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<IPlanSummaryLecturer[]> {
        const url = `${this.apiUrl}/lecturers`;
        const params = this.buildQueryParams({ search, ...options });

        return this.http.get<IPlanSummaryLecturer[]>(url, { params });
    }

    /**
     * Add a lecturer.
     * @param lecturerId
     * @param rotationId
     * @param options
     */
    addLecturer(lecturerId: string, lecturerName: string, lecturerSurname:string , rotationId, options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<any> {
        const url = `${this.apiUrl}/lecturers`;
        const params = this.buildQueryParams(options);

        return this.http.post<any>(url, { lecturerId, lecturerName, lecturerSurname, rotationId }, { params });
    }

    /**
     * Remove a lecturer.
     * @param lecturerId
     * @param rotationId
     * @param options
     */
    removeLecturer(lecturerId: string, lecturerName: string, lecturerSurname:string, rotationId, options: { programId?: string, studentId?: string, studentEmail?: string }): Observable<any> {
        const url = `${this.apiUrl}/lecturers`;
        const params = this.buildQueryParams(options);

        return this.http.delete<any>(url, { params, body: { lecturerId, lecturerName, lecturerSurname, rotationId } });
    }

    /**
     * Get student documents.
     * @param studentUpn 
     */
    getDocuments(studentUpn?: string) {
        const url = `${this.apiUrl}/documents`;
        const params = studentUpn ? { studentUpn } : {};
        
        return this.http.get<IPlanSummaryDocument[]>(url, { params });
    }

    /**
     * Get document file list.
     * @param documentId 
     * @param studentUpn 
     */
    getDocumentFiles(documentId: number, studentUpn?: string) {
        const url = `${this.apiUrl}/documents/${documentId}/files`;
        const params = studentUpn ? { studentUpn } : {};
        
        return this.http.get<IPlanSummaryDocumentFile[]>(url, { params });
    }

    /**
     * Get document file download URL.
     * @param documentId 
     * @param fileId 
     * @param fileName 
     * @param studentUpn 
     */
    getFileDownloadUrl(documentId: number, fileId: number, fileName: string, studentUpn?: string) {
        return `${this.apiUrl}/documents/${documentId}/files/${fileId}/download?name=${fileName}&studentUpn=${studentUpn}&SessionKey=${this.auth.sessionKey}`;
    }

    private buildQueryParams(options: any): any {
        const params: any = {};

        for (const k in options) {
            const val = options[k];

            if (val !== undefined && val !== null) {
                if (typeof val == 'string') {
                    if (val) params[k] = val;
                } else {
                    params[k] = val;
                }
            }
        }

        return params;
    }
}
