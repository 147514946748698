<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/password/request', text: 'password_restore' },
                     { url: '/password/request-student', text: 'password_studentRestore' }
                     ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{(requestResponse ? 'password_requestSentHeader' : 'password_studentRestore') | translate}}
        </h1>
    </header>

    <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate autocomplete="off">
        <ng-container *ngIf="requestResponse">
            <app-alert level="success">
                <div [innerHTML]="'password_requestSent' | translate:{email:getMaskedEmail(requestResponse.Email),username:requestResponse.Username}"></div>
            </app-alert>

            <div class="mb-1">
                <a [routerLink]="['/password','reset-student'] | localize" [translate]="'password_havecode'"></a>
            </div>

            <div class="mb-3">
                <a (click)="refreshRequest($event)" href="#" [translate]="'password_enterNewCode'"></a>
            </div>
        </ng-container>

        <div class="row" *ngIf="!requestResponse">
            <div class="col-sm-6">
                <div class="form-field">
                    <label class="form-label">{{'password_LastName' | translate}}</label>
                    <input class="form-control" type="text" name="lastName" [(ngModel)]="lastNameValue" #lastName="ngModel" required />
                    <div class="invalid-feedback" *ngIf="f.submitted && !lastNameValue">{{'password_LastNameError' | translate}}</div>
                </div>

                <div class="form-field">
                    <label class="form-label">{{'password_idOrCode' | translate}}</label>
                    <input class="form-control" type="text" name="personIdOrCode" [(ngModel)]="personIdOrCodeValue" #personIdOrCode="ngModel" required />
                    <div class="invalid-feedback" *ngIf="f.submitted && !personIdOrCodeValue">{{'password_personIdOrCodeError' | translate}}</div>
                </div>

                <div class="form-field">
                    <label class="form-label">{{'password_emailOrPhone' | translate}}</label>
                    <input class="form-control" type="text" name="emailOrPhone" [(ngModel)]="emailPhoneValue" #emailOrPhone="ngModel" required />
                    <div class="invalid-feedback" *ngIf="f.submitted && !emailPhoneValue">{{'password_emailOrPhoneError' | translate}}</div>
                    <div class="invalid-feedback" *ngIf="f.submitted && isRsuEmail">{{'password_emailNotPrivate' | translate}}</div>
                </div>

                <div class="recaptcha">
                    <re-captcha (resolved)="resolved($event)" #recaptchaRef required siteKey="{{reCaptchaSiteKey}}"></re-captcha>
                </div>
            </div>

            <div class="form-actions">
                <button class="btn btn-primary" type="submit" [disabled]="!reCaptchaResponse">
                    {{'password_Request' | translate}}
                </button>
            </div>

            <div class="mb-1">
                <a [routerLink]="['/password','reset-student'] | localize" [translate]="'password_havecode'"></a>
            </div>

            <div class="mb-3">
                <a [routerLink]="['/password/request-employee'] | localize">{{'password_restoreEmployee' | translate}}</a>
            </div>
        </div>

        <div class="mb-3" [innerHTML]="'password_contactfooter' | translate | safeHtml"></div>
    </form>
</div>
