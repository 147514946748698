import { Component, OnInit, Renderer2, ViewChildren } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';

import { AppService } from '../../services/app.service';

import { UserInfo } from '../../models/UserInfo';
import { ParameterService } from '../../services/parameter.service';
import { NgZone } from '@angular/core';
import { ElementRef } from '@angular/core';
import { AppDatePipe } from '../../pipes/date.pipe';
import { ImpersonationService } from '../../services/impersonation.service';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { QueryList } from '@angular/core';
import { AuthService } from '../../services/auth.service';

class NavItem {
    titleRes: string;
    isHidden: () => boolean;
    route?: string;
    children?: NavItem[] = [];
    isOpened?: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private parameters: ParameterService,
        private impersonation: ImpersonationService,
        private router: Router,
        private zone: NgZone,
        private renderer: Renderer2,
        private appDate: AppDatePipe,
        @Inject(DOCUMENT) private document: Document
    ) { }

    date = new Date();

    readonly showDebugBadge = this.app.env.showDebugMsg;

    readonly navItems: NavItem[] = [
        {
            titleRes: 'nav_impersonation',
            isHidden: () => !this.currentUser || !(this.currentUser.impersonator
                || (this.currentUser.rights.indexOf('ES_MAIN.IMPERSONATION.SET') > -1
                    && this.currentUser.rights.indexOf('ES_MAIN.STUDENTS.FIND') > -1)),
            route: '/impersonation'
        },
        {
            titleRes: 'nav_admin',
            isHidden: () => !this.navItems.find(t => t.titleRes === 'nav_admin').children.some(t => !t.isHidden || !t.isHidden()),
            children: [
                { titleRes: 'nav_userList', route: '/users', isHidden: () => !this.app.currentUser?.isAdmin },
                { titleRes: 'nav_classifiers', route: '/classifiers', isHidden: () => !this.app.currentUser?.isAdmin },
                { titleRes: 'nav_config', route: '/parameters', isHidden: () => !this.app.currentUser?.isAdmin },
                { titleRes: 'nav_log', route: '/log', isHidden: () => !this.app.currentUser?.isAdmin },
                { titleRes: 'nav_permissions', route: '/permissions', isHidden: () => !this.app.currentUser?.isAdmin },
                {
                    titleRes: 'nav_messages',
                    route: '/messages',
                    isHidden: () => !this.app.currentUser?.isAdmin && !this.currentUser?.rights.some(t => t.startsWith('UDR.CERT.'))
                },
                {
                    titleRes: 'nav_templates',
                    route: '/templates',
                    isHidden: () => !this.currentUser.rights.some(t => t.startsWith('ES_MAIN.TEMPLATES.') || t.startsWith('UDR.CERT.'))
                },
                { titleRes: 'nav_applicationConfig', route: '/applications/config', isHidden: () => this.currentUser.rights.indexOf('APPLICATION.CONFIG') === -1 },
                { titleRes: 'nav_externalConfig', route: '/external/config', isHidden: () => this.currentUser.rights.indexOf('EXTERNAL_SERVICE.CONFIG') === -1 },
                { titleRes: 'nav_certRequests', route: '/certificate-requests', isHidden: () => this.currentUser.rights.indexOf('CERTIFICATE_REQUESTS.VIEW') === -1 },
                { titleRes: 'nav_tiles', route: '/tiles', isHidden: () => this.currentUser.rights.indexOf('ES_MAIN.TILES.VIEW') === -1 },
                { titleRes: 'nav_ebusSessionConfig', route: '/ebus/session-config', isHidden: () => this.currentUser.rights.indexOf('EBUS.SESSION_CONFIG') === -1 }
            ]
        }
    ];

    userName: any = {};
    menuOpened: boolean;
    locale: string;
    rsuSiteUrl: string;
    myRsuSiteUrl: string;
    eStudiesSiteUrl: string;
    searchOpened: boolean;
    currentUser: UserInfo = this.app.currentUser;

    readonly showLanguageSwitch = this.app.env.showLanguageSwitch;

    get isStudent(): boolean {
        return this.app.currentUser?.isStudent;
    }

    @ViewChildren('currentDate') private currentDateEl: QueryList<ElementRef>;
    @ViewChildren('currentTime') private currentTimeEl: QueryList<ElementRef>;

    ngOnInit() {
        this.locale = this.app.currentLanguage || 'lv';

        this.auth.user.subscribe(user => {
            this.currentUser = user;
        });

        this.router.events.subscribe((e) => {
            this.interceptNavigation(e as RouterEvent);
        });

        this.app.onDocumentClick().subscribe((e) => {
            const opened = this.navItems.filter((t) => t.isOpened);

            if (!e.target.closest('.site-nav-toggle') && !e.target.closest('.site-nav-sub')) {
                if (opened.length) {
                    opened.forEach(t => t.isOpened = false);
                }
            
                if (this.menuOpened) {
                    this.menuOpened = false;
                }
            }
        });

        this.app.addLoading(this.parameters.getValues()).subscribe(data => {
            this.rsuSiteUrl = data.find(t => t.Code == 'RsuSiteUrl').Value;
            this.myRsuSiteUrl =  data.find(t => t.Code == 'MyRsuSiteUrl').Value;
            this.eStudiesSiteUrl = data.find(t => t.Code == 'EStudiesSiteUrl').Value;
        });

        this.zone.runOutsideAngular(() => {
            setInterval(() => {
                const d = new Date();

                const date = this.appDate.transform(d);
                const time = `0${d.getHours()}`.slice(-2) + ':' + `0${d.getMinutes()}`.slice(-2);

                this.currentDateEl?.forEach(t => {
                    this.renderer.setProperty(t.nativeElement, 'textContent', date);
                });

                this.currentTimeEl?.forEach(t => {
                    this.renderer.setProperty(t.nativeElement, 'textContent', time);
                });
            }, 100);
        });
    }

    openMenuItem(menuItem: any) {
        this.closeAll();

        if (menuItem.children && menuItem.children.length) {
            menuItem.isOpened = true;
        }
    }

    closeMenuItem(menuItem: any) {
        menuItem.isOpened = false;
    }

    logout() {
        this.app.showLoading();
        this.auth.logout();
    }

    changeLocale(locale: string) {
        if (event)
            event.preventDefault();

        let route = this.app.currentUrl.split('/');

        if (!route.length) {
            route.push(locale);
        } else {
            route[1] = locale;
        }

        // must reload because of pipes and LOCALE_ID
        location.href = route.join('/');
    }

    getVisibleNavItems(items?: NavItem[]): NavItem[] {
        return (items || []).filter(t => t.isHidden === undefined || !t.isHidden());
    }

    closeAll() {
        this.navItems.forEach(t => {
            t.isOpened = false;
        });

        this.menuOpened = false;
    }

    endImpersonation() {
        this.app.addLoading(this.impersonation.end()).subscribe(() => {
            location.reload();
        });
    }

    toggleMenu() {
        this.menuOpened = !this.menuOpened;
    }

    toggleSearch() {
        this.searchOpened = !this.searchOpened;

        const body = this.document.querySelector('#app-body');

        if (this.searchOpened) {
            body.classList.add('d-none');
        } else {
            body.classList.remove('d-none');
        }
    }

    private interceptNavigation(event: RouterEvent) {
        if (event instanceof NavigationStart) {
            this.closeAll();
        }
    }
}
