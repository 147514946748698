import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
    ICourseEqualization, ICourseEqualizationEditModel, ICourseEqualizationListItem,
    ICourseEqualizationResident, ICourseEqualizationSupervisor
} from '../models/CourseEqualization';
import { IResidencyCourse, IResidencySeminar } from '../models/Residency';

import { environment as ENV } from '../../environments/environment';
import { map } from 'rxjs/operators';

export interface ISaveResult {
    Id: number,
    Courses: {
        Id: number,
        Courses: {
            Id: number
        }[]
    }[],
    Seminars: {
        Id: number
    }[]
}

export interface IConfig {
    Courses: IResidencyCourse[];
    Seminars: IResidencySeminar[];
    Supervisor: ICourseEqualizationSupervisor;
    FileMaxSize: number;
    FileLimit: number;
    FileExtensions: string[];
}

/**
 * Study course equalization service
 */
@Injectable({ providedIn: "root" })
export class CourseEqualizationService {
    constructor(private http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/courseEqualizations` }

    get(studentEmail?: string): Observable<ICourseEqualizationListItem[]> {
        const url = this.apiUrl;
        const params = this.buildQueryParams({ studentEmail });
        return this.http.get<ICourseEqualizationListItem[]>(url, { params });
    }

    getById(id: number): Observable<ICourseEqualization> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<ICourseEqualization>(url);
    }

    getConfig(programId: string, studentEmail?: string): Observable<IConfig> {
        const url = `${this.apiUrl}/config`;
        const params = this.buildQueryParams({
            programId,
            studentEmail
        });

        return this.http.get<IConfig>(url, { params });
    }

    /**
     * Get resident by student email or ID.
     * @param options
     */
    getResident(studentEmail?: string): Observable<ICourseEqualizationResident[]> {
        const url = `${this.apiUrl}/resident`;
        const params = this.buildQueryParams({ studentEmail });

        return this.http.get<ICourseEqualizationResident[]>(url, { params });
    }

    addAttachment(id: number, file: File, outerCourseId?: number): Observable<number> {
        const url = `${this.apiUrl}/${id}/attachments` + (outerCourseId ? `?outerCourseId=${outerCourseId}` : '');
        const form = new FormData();

        form.append('file', file, file.name);

        return this.http.post<number>(url, form);
    }

    removeAttachment(attachmentId: number): Observable<any> {
        const url = `${this.apiUrl}/attachments/${attachmentId}`;
        return this.http.delete(url).pipe(map(res => res));
    }

    getAttachment(attachmentId: number): Observable<any> {
        const url = `${this.apiUrl}/attachments/${attachmentId}`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }

    create(model: ICourseEqualizationEditModel): Observable<ISaveResult> {
        const url = this.apiUrl;
        return this.http.post<ISaveResult>(url, model);
    }

    update(id: number, model: ICourseEqualizationEditModel): Observable<ISaveResult> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.put<ISaveResult>(url, model);
    }

    delete(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }

    submit(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/submit`;
        return this.http.post(url, null);
    }

    approve(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/approve`;
        return this.http.post(url, null);
    }

    createProject(id: number, decisionId: string, comment?: string): Observable<any> {
        const url = `${this.apiUrl}/${id}/project`;
        return this.http.post(url, { decisionId, comment });
    }

    private buildQueryParams(options: any): any {
        if (!options) return {};

        const params: any = {};

        for (const k in options) {
            const val = options[k];

            if (val !== undefined && val !== null) {
                if (typeof val == 'string') {
                    if (val) params[k] = val;
                } else {
                    params[k] = val;
                }
            }
        }

        return params;
    }
}
