import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { Utils } from '../../core/Utils';

export interface IPictureLabels {
    add: string;
    change: string;
}

export interface IPictureOutput {
    base64: string;
    fileName: string;
    fileType: string;
}

/**
 * Picture component
 */
@Component({
    selector: 'app-picture',
    templateUrl: './picture.component.html'
})
export class PictureComponent {
    @Input() maxSize: number;
    @Input() accept: string;
    @Input() labels: IPictureLabels;

    @Output() croppedImage: EventEmitter<IPictureOutput> = new EventEmitter<IPictureOutput>();

    currentCroppedImage: string;
    imageChangedEvent: any;

    size: number;
    maxSizeError: boolean;
    wrongExtensionError: boolean;

    file: File;

    get isError(): boolean {
        return this.maxSizeError || this.wrongExtensionError;
    }

    @ViewChild('profilePhotoChangeInput', { static: true }) private profilePhotoChangeInput: ElementRef;

    onFileChange(event) {
        this.file = event.target.files[0];
        if (this.file) {
            this.maxSizeError = false;
            this.wrongExtensionError = false;
            this.size = this.file.size;

            if (this.maxSize && this.file.size > this.maxSize) {
                this.maxSizeError = true;
            }

            const extension = this.file.name.substring(this.file.name.lastIndexOf('.') + 1);

            if (this.accept.indexOf(extension.toLowerCase()) < 0 && this.accept.indexOf('.' + extension.toLowerCase()) < 0) {
                this.wrongExtensionError = true;
            }

            if (!this.isError) {
                this.imageChangedEvent = event;
            }
        }
    }

    onImageCropped(evt) {
        const base64: string = evt.base64;
        const cutIndex = base64.indexOf(';base64,');

        this.currentCroppedImage = base64;

        this.croppedImage.emit({
            base64: base64.substr(cutIndex + 8/*;base64,*/),
            fileName: this.file.name,
            fileType: this.file.type
        });
    }

    resetProfilePhoto() {
        this.imageChangedEvent = null;
        this.currentCroppedImage = null;
        this.profilePhotoChangeInput.nativeElement.value = null;

        this.croppedImage.emit(null);
    }

    prettySize(size: number): string {
        return Utils.prettyFileSize(size);
    }
}
