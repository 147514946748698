<div *ngIf="question" class="block-em">
    <app-alert *ngIf="question.Description" level="info">{{question.Description}}</app-alert>

    <div [ngSwitch]="question.QuestionEvaluationType">
        <ng-container *ngSwitchCase="questionTypes.WithoutObject">
            <app-survey-question-form [question]="question"
                                      [key]="util.buildQuestionKey(question.ID)"
                                      [model]="model"
                                      [validate]="validate"></app-survey-question-form>
        </ng-container>

        <ng-container *ngSwitchCase="questionTypes.ObjectBasedOnAnswer">
            <app-survey-question-form [question]="question"
                                      [key]="util.buildQuestionKey(question.ID)"
                                      [model]="model"
                                      [validate]="validate"></app-survey-question-form>
        </ng-container>

        <ng-container *ngSwitchCase="questionTypes.ObjectList">
            <app-survey-question-form *ngFor="let n of question.Objects"
                                      [question]="question"
                                      [key]="util.buildQuestionKey(question.ID, o.ID)"
                                      [model]="model"
                                      [validate]="validate"></app-survey-question-form>
        </ng-container>

        <ng-container *ngSwitchCase="questionTypes.ObjectListWithAssociations">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let n of question.Objects">{{n.Title}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let a of question.AssociatedObjects">
                        <td>{{a.Title}}</td>
                        <td *ngFor="let o of n.Objects">
                            <app-survey-question-form [question]="question"
                                                      [key]="util.buildQuestionKey(question.ID, o.ID, a.ID)"
                                                      [model]="model"
                                                      [validate]="validate"></app-survey-question-form>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>

</div>
