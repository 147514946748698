import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { of, Subject } from 'rxjs';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';

import * as FileSaver from 'file-saver';

import {
    IOnCallShiftReportEditModel, IOnCallShiftReport, IOnCallShiftWorkplace,
    IOnCallShiftEditModel, IOnCallShiftResident, IOnCallShiftReviewer
} from '../../models/OnCallShift';
import { IPersonSearchResultItem } from '../../models/Person';

import { AppService } from '../../services/app.service';
import { OnCallShiftService } from '../../services/on-call-shift.service';
import { AppDatePipe } from '../../pipes/date.pipe';
import { Utils } from '../../core/Utils';
import { OnCallShiftEditComponent } from './edit.component';
import { store } from './store';
import { catchError, debounceTime, defaultIfEmpty, tap } from 'rxjs/operators';
import { createFileStub } from '../../shared/file/file.component';
import { ParameterService } from '../../services/parameter.service';

export const userRights = {
    setStudent: 'ON_CALL_SHIFT.SET_STUDENT'
};

export interface IOnCallShiftEditModelExt extends IOnCallShiftEditModel {
    WorkplaceName?: string;
}

interface IAttachment {
    id?: number;
    file?: File;
}

@Component({
    selector: 'app-on-call-shift-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.scss']
})
export class OnCallShiftFormComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        private app: AppService,
        private service: OnCallShiftService,
        private parameters: ParameterService,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private appDate: AppDatePipe
    ) { }

    report: IOnCallShiftReport;
    notes: string;
    specialityId: string;
    shifts: IOnCallShiftEditModelExt[] = [];
    workplaces: IOnCallShiftWorkplace[] = [];
    resident: IOnCallShiftResident;
    residentInfo: IOnCallShiftResident[] = [];
    reviewerOptions: IOnCallShiftReviewer[] = [];

    isNew: boolean;
    isReady: boolean;
    canPickStudent: boolean;
    studentPickerOpened: boolean;
    student: IPersonSearchResultItem;
    email: string;

    attachments: IAttachment[] = [];
    attachmentExtensions: string = '';
    attachmentMaxSize: number = 0;
    attachmentLimit: number = 0;

    reviewer: IOnCallShiftReviewer;

    readonly specialityDisplayFn = (option: IOnCallShiftResident) => `${option.SpecialityCode} - ${option.SpecialityName}`;
    readonly reviewerDisplayFn = (option: IOnCallShiftReviewer) => option ? `${option.Name} ${option.Surname}` : '';

    get isFinal(): boolean {
        return this.report?.Status == 'Submitted';
    }

    get periodFrom(): Date {
        const all = this.shifts.filter(t => !!t.Date).map(t => Utils.getInputDate(t.Date).getTime());

        if (all.length) {
            var min = new Date(Math.min(...all));
            min.setDate(1);

            return min;
        }

        return undefined;
    }

    get periodTo(): Date {
        const all = this.shifts.filter(t => !!t.Date).map(t => Utils.getInputDate(t.Date).getTime());

        if (all.length) {
            var max = new Date(Math.max(...all));
            max.setMonth(max.getMonth() + 1);
            max.setDate(0);

            return max;
        }

        return undefined;
    }

    private origShifts: string = '[]';
    private isSaved: boolean;

    private reviewersSubscription: Subscription;
    private readonly reviewersSubject = new Subject<string>();

    @ViewChild('form', { static: false }) private form: NgForm;

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf(userRights.setStudent) > -1;
        }

        const specId = this.route.snapshot.queryParams['specialityId'];
        const email = this.route.snapshot.queryParams['email'];

        this.reviewersSubscription = this.reviewersSubject.pipe(debounceTime(300)).subscribe(value => {
            this.service.getReviewers(value).subscribe(data => this.reviewerOptions = data);
        });

        this.student = store.getStudent();

        this.route.params.subscribe(para => {
            let id = para['id'];

            if (!id) {
                this.isNew = true;
                this.report = <IOnCallShiftReport>{
                    Status: 'Draft',
                    Attachments: []
                };

                if (this.canPickStudent) {
                    this.isReady = true;

                    if (this.student) {
                        this.loadResident(undefined, this.student.Email);
                    } else if (email) {
                        this.loadResident(undefined, email);
                    } else {
                        this.toggleStudentPicker();
                    }
                } else {
                    this.loadResident(specId);
                }
            } else {
                this.loadById(+id);
            }
        });

        this.parameters.getValues().subscribe(data => {
            this.attachmentMaxSize = this.parameters.findValue(data, 'ResidencyReportFileMaxSize', t => +t, 0);
            this.attachmentLimit = this.parameters.findValue(data, 'ResidencyReportFileLimit', t => +t, 0);
            this.attachmentExtensions = this.parameters.findValue(data, 'ResidencyReportFileExtensions',
                t => '.' + t.split(',').join(',.'), '');
        });
    }

    ngOnDestroy() {
        this.reviewersSubscription.unsubscribe();
    }

    canDeactivate(): Observable<boolean> {
        if (!this.isSaved && !this.isFinal && this.hasChanges()) {
            const subj = new Subject<boolean>();

            this.app.confirm(this.app.translate('onCallShift_confirmUnsaved'), result => {
                subj.next(result);
            });

            return subj.asObservable();
        }

        return of(true);
    }

    onSpecialityChange() {
        this.resident = this.residentInfo.find(t => t.SpecialityId == this.specialityId);
        this.report.Resident = this.resident;
    }

    toggleStudentPicker() {
        if (this.studentPickerOpened) {
            this.studentPickerOpened = false;
        } else {
            this.canDeactivate().subscribe(res => {
                if (res) {
                    this.studentPickerOpened = true;
                    this.resident = undefined;
                }
            });
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;

        this.loadResident(undefined, student.Email);

        store.setStudent(student);
    }

    addShift() {
        let model: IOnCallShiftEditModelExt;

        if (this.resident.WorkplaceId) {
            model = <IOnCallShiftEditModelExt>{
                WorkplaceId: this.resident.WorkplaceId,
                WorkplaceName: this.resident.WorkplaceName
            };
        } else {
            const latestShift = this.shifts.length ? this.shifts[this.shifts.length - 1] : null;

            model = <IOnCallShiftEditModelExt>{
                WorkplaceId: latestShift?.WorkplaceId,
                WorkplaceName: latestShift?.WorkplaceName
            };
        }
        const ref = this.modal.open(OnCallShiftEditComponent);
        ref.componentInstance.data = {
                model,
                selectedDates: this.shifts.map(t => Utils.getInputDate(t.Date))
            };
        ref.result.then(() => {
            this.shifts.push(model);
            this.sortShifts();
        }, () => { });
    }

    editShift(shift: IOnCallShiftEditModel) {
        const ref = this.modal.open(OnCallShiftEditComponent);
        ref.componentInstance.data = {
            model: shift,
            selectedDates: this.shifts.map(t => Utils.getInputDate(t.Date))
        };
        ref.result.then(() => {
            this.sortShifts();
        }, () => { });
    }

    removeShift(shift: IOnCallShiftEditModel) {
        const ix = this.shifts.indexOf(shift);

        if (ix > -1) {
            this.shifts.splice(ix, 1);
        }
    }

    validate(): boolean {
        if (!this.form.valid) {
            this.app.alert.error(this.app.translate('onCallShift_formError'));
            return false;
        }

        for (let i = 0; i < this.shifts.length; i++) {
            if (this.getTotalHoursByDate(this.shifts[i].Date) > 24) {
                this.app.alert.error(this.app.translate('onCallShift_dateHoursExceeded').replace('{date}', this.appDate.transform(this.shifts[i].Date)));
                return false;
            }
        }

        for (let i = 0; i < this.attachments.length; i++) {
            if (this.attachments[i].file) {
                for (let j = i + 1; j < this.attachments.length; j++) {
                    if (this.attachments[j] && this.attachments[i].file.name == this.attachments[j].file.name) {
                        this.app.alert.error(this.app.translate('onCallShift_fileNamesMustBeUnique'));
                        return false;
                    }
                }
            }
        }

        return true;
    }

    save() {
        if (!this.validate()) return;

        this.createOrUpdate().subscribe(id => {
            this.isSaved = true;
            this.app.notify(this.app.translate('onCallShift_saved'));
            this.app.navigate(['/on-call-shifts']);
        });
    }

    submit() {
        if (!this.validate()) return;

        if (!this.shifts.length) {
            this.app.alert.error(this.app.translate('onCallShift_shiftsEmpty'));
            return false;
        }

        const hours = this.shifts.reduce((sum, t) => { sum += +t.Hours; return sum; }, 0);

        if (hours == 0) {
            this.app.alert.error(this.app.translate('onCallShift_noHours'));
            return;
        }

        const body = this.app.translate('onCallShift_submitText')
            .replace('{from}', this.appDate.transform(this.periodFrom))
            .replace('{to}', this.appDate.transform(this.periodTo))
            .replace('{hours}', hours.toString());

        this.app.confirm({
            text: body,
            title: this.app.translate('onCallShift_submitTitle'),
            okText: this.app.translate('onCallShift_submitOk')
        }, result => {
            if (!result) return;

            this.createOrUpdate().subscribe(id => {
                this.isSaved = true;

                this.app.addLoading(this.service.submit(id)).subscribe(() => {    
                    this.app.notify(this.app.translate('onCallShift_submitted'));
                    this.app.navigate(['/on-call-shifts']);
                }, err => {
                    this.app.showError(err);
                    this.isNew = false;
                    this.loadById(id);
                });
            });
        });
    }

    downloadReport() {
        this.app.addLoading(this.service.getPdf(this.report.Id)).subscribe(result => {
            FileSaver.saveAs(result, 'atskaite.pdf');
        });
    }

    downloadAttachment(attachment: IAttachment) {
        this.app.addLoading(this.service.getAttachment(attachment.id)).subscribe(result => {
            FileSaver.saveAs(result, attachment.file.name);
        });
    }

    addAttachment() {
        this.attachments.push(<IAttachment>{});
    }

    removeAttachment(file: IAttachment) {
        let i = this.attachments.indexOf(file);

        if (i > -1)
            this.attachments.splice(i, 1);
    }

    onFileChange(event: File, file: IAttachment) {
        if (!event) {
            this.removeAttachment(file);
        }
    }

    checkDuplicateFileName(file: IAttachment): boolean {
        if (!file.file) return false;
        return this.attachments.filter(t => t.file?.name == file.file.name).length > 1;
    }

    getTotalHoursByDate(date: Date): number {
        const ymd = Utils.getInputDateString(date);

        return this.shifts.reduce((total, shift) => {
            if (Utils.getInputDateString(shift.Date) == ymd) {
                total += shift.Hours;
            }

            return total;
        }, 0);
    }

    filterReviewers(value: string) {
        if (value?.length < 3) {
            this.reviewerOptions = [];
        } else {
            this.reviewer = null;
            this.reviewersSubject.next(value);
        }
    }

    private loadById(id: number) {
        this.app.addLoading(this.service.getById(id)).subscribe(data => {
            this.report = data;
            this.notes = data.Notes;
            this.specialityId = data.Resident.SpecialityId;
            this.reviewer = data.Reviewer;

            if (this.reviewer) {
                this.reviewerOptions = [this.reviewer];
            }

            this.shifts = data.Shifts.map(t => {
                return {
                    Date: t.Date,
                    Hours: t.Hours,
                    Id: t.Id,
                    WorkplaceId: t.WorkplaceId,
                    WorkplaceName: t.WorkplaceName,
                    Comments: t.Comments
                };
            });

            this.sortShifts();

            this.attachments = data.Attachments.map(t => {
                return {
                    id: t.Id,
                    file: createFileStub(t.FileName)
                };
            });

            if (this.isFinal) {
                this.resident = data.Resident;
                this.isReady = true;
            } else {
                this.origShifts = JSON.stringify(this.shifts);
                this.loadResident(null, data.Resident.Email);
            }
        });
    }

    private loadResident(specialityId?: string, email?: string) {
        this.isReady = false;

        this.app.addLoading(this.service.getResident(email)).subscribe(data => {
            this.residentInfo = data;

            if (data.length) {
                let res: IOnCallShiftResident;

                if (specialityId) {
                    res = data.filter(t => t.SpecialityId.toLowerCase() == specialityId.toLowerCase())[0];
                }

                if (!res) {
                    res = data[0];
                }

                this.specialityId = res.SpecialityId;
                this.resident = res;
                this.report.Resident = this.resident;

                if (!this.student) {
                    this.student = <IPersonSearchResultItem>{
                        Email: res.Email,
                        FirstName: res.Name,
                        LastName: res.Surname
                    };
                    store.setStudent(this.student);
                }
            }

            this.isReady = true;
        }, err => {
            this.isReady = true;
        });
    }

    private createOrUpdate() {
        const subj = new Subject<number>();
        const model: IOnCallShiftReportEditModel = {
            Notes: this.notes,
            Shifts: this.shifts,
            SpecialityId: this.specialityId
        };

        if (this.reviewer) {
            model.Reviewer = `${this.reviewer.Name} ${this.reviewer.Surname}`;
            model.ReviewerId = this.reviewer.Id;
        }

        const saveAttachments = (id: number) => {
            this.app.addLoading(this.saveAttachments(id)).subscribe(files => {
                const errors = files.filter(f => f.error).map(f => {
                    return `<p><strong>${f.fileName}</strong><br>${f.error}</p>`;
                });

                if (errors.length) {
                    this.app.showError(errors.join(''));
                } else {
                    subj.next(id);
                }
            });
        };

        if (this.isNew) {
            this.app.addLoading(this.service.create(model, this.student?.Email)).subscribe(id => {
                saveAttachments(id);
            });
        } else {
            this.app.addLoading(this.service.update(this.report.Id, model)).subscribe(() => {
                saveAttachments(this.report.Id);
            });
        }

        return subj.asObservable();
    }

    private saveAttachments(id: number) {
        const results: {
            fileName: string,
            added?: boolean,
            removed?: boolean,
            error?: string
        }[] = [];

        const subj = new Subject<typeof results>();
        
        const remove = (this.report.Attachments || []).filter(t => !this.attachments.some(a => a.file?.name == t.FileName)).map(t => {
            results.push({ fileName: t.FileName });

            const entry = results[results.length - 1];

            return this.service.removeAttachment(t.Id).pipe(tap(() => {
                entry.removed = true;
            }), catchError(err => {
                entry.removed = false;
                entry.error = this.app.getHttpResponseError(err);

                return of(null);
            }));
        });

        const add = this.attachments.filter(t => !!t.file?.size).map(t => {
            results.push({ fileName: t.file.name });

            const entry = results[results.length - 1];

            return this.service.addAttachment(id, t.file).pipe(tap(() => {
                entry.added = true;

                // drop the file to prevent from re-upload if saving again after error
                t.file = createFileStub(t.file.name);
            }), catchError(err => {
                entry.added = false;
                entry.error = this.app.getHttpResponseError(err);

                return of(null);
            }));
        });

        if (!remove.length && !add.length) return of(results);

        forkJoin(...remove).pipe(
            defaultIfEmpty(null),
        ).subscribe(() => {
            forkJoin(...add).pipe(
                defaultIfEmpty(null),
            ).subscribe(() => {
                subj.next(results);
            });
        });

        return subj.asObservable();
    }

    private sortShifts() {
        this.shifts.sort((a, b) => {
            const ad = Utils.getInputDate(a.Date).getTime();
            const bd = Utils.getInputDate(b.Date).getTime();

            return ad - bd;
        });
    }

    private hasChanges(): boolean {
        if (!this.resident) return false;

        if (this.specialityId != this.resident.SpecialityId
            || this.notes != this.report.Notes
            || this.origShifts != JSON.stringify(this.shifts)
        ) {
            return true;
        }

        return false;
    }
}
