import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IPlanSummaryResident, IPlanSummarySupervisor } from '../../../models/PlanSummary';
import { AppService } from '../../../services/app.service';
import { ParameterService } from '../../../services/parameter.service';
import { PlanSummaryService } from '../../../services/plan-summary.service';
import { store, currentRequestParams } from '../plan-summary.component';

@Component({
    selector: 'app-plan-summary-base-info',
    templateUrl: './plan-summary-base-info.component.html',
    styleUrls: ['../plan-summary.component.scss']
})
export class PlanSummaryBaseInfoComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PlanSummaryService,
        private parameters: ParameterService
    ) { }

    @Input() resident: IPlanSummaryResident;
    @Input() supervisor: IPlanSummarySupervisor;

    workplaceAppUrl: string;
    clinicalBaseAppUrl: string;
    //supervisorLoaded: boolean;
    canEdit: boolean;

    get supervisorSet(): boolean {
        return this.supervisor != undefined;
    }

    ngOnInit() {
        if (this.app.currentUser) {
            const user = this.app.currentUser;
            const self = user.email == this.resident.Email;
            const canSet = user.rights.includes('APPLICATION.SET_STUDENT');

            this.canEdit = user.rights.includes('APPLICATION.LIST.PERSON_DATA') && (self || canSet);
        }

        //this.getData().subscribe(data => {
        //    this.supervisor = data.supervisor;
        //    this.supervisorLoaded = true;
        //});

        this.app.addLoading(this.parameters.getValues()).subscribe(data => {
            this.workplaceAppUrl = data.find(t => t.Code == 'WorkplaceChangeAppUrl').Value;
            this.clinicalBaseAppUrl = data.find(t => t.Code == 'ClinicalBaseChangeAppUrl').Value;
        });
    }

    //private getData() {
    //    if (store.supervisor) {
    //        return of({
    //            supervisor: store.supervisor
    //        });
    //    } else {
    //        return this.app.addLoading(this.service.getSupervisor(currentRequestParams)).pipe(tap(data => {
    //            store.supervisor = data;
    //        }), map(data => {
    //            return {
    //                supervisor: data
    //            };
    //        }));
    //    }
    //}
}
