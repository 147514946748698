import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { IParameter, IParameterValue } from '../models/Parameter';
import { environment as ENV } from '../../environments/environment';
import { tap } from 'rxjs/operators';

const cache: { values ?: IParameterValue[] } = { };

/**
 * System parameter service
 */
@Injectable({ providedIn: "root" })
export class ParameterService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/parameters` }

    get(): Observable<IParameter[]> {
        return this.http.get<IParameter[]>(this.apiUrl);
    }

    getById(id: number): Observable<IParameter> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<IParameter>(url);
    }

    update(id: number, value: string): Observable<IParameter> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.put<IParameter>(url, { value }).pipe(tap(() => {
            cache.values = undefined;
        }));
    }

    getValues(): Observable<IParameterValue[]> {
        if (cache.values) return of(cache.values);
        const url = `${this.apiUrl}/values`;
        return this.http.get<IParameterValue[]>(url).pipe(tap(data => {
            cache.values = data;
        }));
    }

    findValue<T>(values: IParameterValue[], code: string, convert?: (value: string) => T, fallback?: T): T {
        if (!convert) {
            convert = value => value as any;
        }

        const item = values.find(t => t.Code.toLowerCase() == code.toLowerCase());

        if (!item) {
            return fallback;
        }

        return item.Value ? convert(item.Value) : fallback;
    }
}
