import { Component, Input } from '@angular/core';

import { AppService } from '../../services/app.service';
import { ITableColumn } from '../../shared/table/table.component';
import { numberFormat } from './student-balance.component';

@Component({
    selector: 'app-student-balance-payments',
    templateUrl: './student-balance-payments.component.html',
    styleUrls: ['./student-balance.component.css']
})
export class StudentBalancePaymentsComponent {
    constructor(
        public app: AppService
    ) { }

    @Input() payments: any[] = [];    
    @Input() rowsPerPage: number = 10;

    readonly numberFormat = numberFormat;

    readonly columns: ITableColumn[] = [
        { property: 'Number', label: 'studentBalance_lblPaymentNumber', sorts: true },
        { property: 'Date', label: 'studentBalance_lblPaymentDate', sorts: true, type: 'date' },
        { property: 'Amount', label: 'studentBalance_lblPaymentAmount', sorts: true, type: 'number', cssClass: 'hidden-sm-down' },
        { property: 'Payer', label: 'studentBalance_lblPayer', sorts: true, cssClass: 'hidden-md-down' },
        { width: '1px' }
    ];

    private expanded: any[] = [];

    toggle(row: any) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: any): boolean {
        return this.expanded.indexOf(row) > -1;
    }

}
