<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/plan-summary', text: 'addGrades_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'addGrades_title' | translate}}</h1>
    </header>

    <form #form="ngForm" [class.was-validated]="submitted" *ngIf="data">
        <div class="row">
            <div class="data-value col-sm">
                <label>{{'addGrades_lblSpeciality' | translate}}</label>
                <div>{{data.SpecialityName}}</div>
            </div>

            <div class="data-value col-sm">
                <label>{{'addGrades_lblCourse' | translate}}</label>
                <div>{{data.SubjectName}}, {{data.SubjectCode}}</div>
            </div>

            <div class="data-value col-sm">
                <label>{{'addGrades_lblResident' | translate}}</label>
                <div>{{data.StudentName}} {{data.StudentSurname}}</div>
            </div>
        </div>

        <app-table [rows]="rows" [columns]="columns">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td class="hidden-md-down">
                        <span class="badge"
                              [class.text-bg-success]="row.Kind == 'Final'"
                              [class.text-bg-secondary]="row.Kind == 'Intermediate'">
                            {{'addGrades_kind' + row.Kind | translate}}
                        </span>
                    </td>
                    <td class="hidden-sm-down">{{row.TypeName}}</td>
                    <td>
                        <span class="grade-badge hidden-lg-up"
                              [ngbTooltip]="'addGrades_kind' + row.Kind | translate"
                              [class.border-success]="row.Kind == 'Final'"
                              [class.border-secondary]="row.Kind == 'Intermediate'">
                            {{row.GradeName}}
                        </span>
                        <div class="hidden-md-down">{{row.GradeName}}</div>
                        <div class="text-mute hidden-md-up">{{row.TypeName}}</div>
                    </td>
                    <td>{{row.LecturerName}} {{row.LecturerSurname}}</td>
                    <td>
                        <div class="table-actions">
                            <button type="button" *ngIf="canDelete" (click)="delete(row)" class="btn btn-outline btn-icon"
                                    [ngbTooltip]="'delete' | translate">
                                <i class="fi fi-rs-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>

        <section class="block">
            <h5>{{'addGrades_addGrade' | translate}}</h5>

            <div class="row">
                <div class="form-field col-sm-4 required">
                    <label class="form-label">{{'addGrades_lblKind' | translate}}</label>
                    <app-select [options]="gradeKinds"
                                [(ngModel)]="model.kind"
                                [required]="true"
                                [displayFn]="gradeKindDisplayFn"
                                (selectionChange)="onKindChange($event)"
                                name="kind">
                    </app-select>
                </div>

                <div class="form-field col-sm-4 required" *ngIf="model.kind">
                    <label class="form-label">{{'addGrades_lblYearSemester' | translate}}</label>
                    <app-select [options]="periods"
                                [(ngModel)]="model.period"
                                [required]="true"
                                [displayFn]="periodDisplayFn"
                                (selectionChange)="onPeriodChange($event)"
                                name="period">
                    </app-select>
                </div>

                <div class="form-field col-sm-4 required" *ngIf="model.period">
                    <label class="form-label">{{'addGrades_lblLecturer' | translate}}</label>
                    <app-select [options]="model.lecturers"
                                [(ngModel)]="model.lecturerId"
                                [required]="true"
                                [displayFn]="lecturerDisplayFn"
                                [valueProperty]="'Id'"
                                (selectionChange)="onLecturerChange($event)"
                                name="lecturerId">
                    </app-select>
                </div>
            </div>

            <ng-container *ngIf="model.kind">
                <div class="row" *ngFor="let g of model.grades; let i = index">
                    <div class="col-sm form-field required">
                        <label class="form-label">{{g.typeName}}</label>
                        <app-select [options]="g.gradeOptions"
                                    [(ngModel)]="g.gradeId"
                                    [required]="true"
                                    [displayProperty]="'Name'"
                                    [valueProperty]="'Id'"
                                    name="grade{{i}}">
                        </app-select>
                        <div class="feedback" *ngIf="g.previous">
                            {{'addGrades_lastGrade' | translate}}: <strong>{{g.previous.GradeName}}</strong>
                            <ng-container *ngIf="g.isObsolete"> ({{'addGrades_lastGradeObsolete' | translate}})</ng-container>
                        </div>
                    </div>

                    <div class="form-field col-sm required">
                        <label class="form-label">{{'addGrades_lblReason' | translate}}</label>
                        <app-select [options]="g.reasonOptions"
                                    [(ngModel)]="g.reasonId"
                                    [required]="true"
                                    [displayProperty]="'Name'"
                                    [valueProperty]="'Id'"
                                    name="reason{{i}}">
                        </app-select>
                    </div>
                </div>
            </ng-container>

            <div class="form-actions">
                <div class="form-actions-group">
                    <button class="btn btn-outline" type="button" [routerLink]="['/'] | localize">
                        {{'addGrades_btnCancel' | translate}}
                    </button>

                    <button class="btn btn-primary" type="button" (click)="save()">
                        {{'addGrades_btnSave' | translate}}
                    </button>
                </div>
            </div>
        </section>
    </form>
</div>
