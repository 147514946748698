<div class="wrapper-md">
    <app-breadcrumbs [items]="[
					 { url: '/applications/my', text: 'myApplications_title' }
					 ]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'myApplications_title'"></h1>
    </header>

    <section class="block">
        <h5>{{'myApplications_references' | translate}}</h5>

        <app-table [rows]="references" [columns]="referenceColumns" [pageSize]="rowsPerPage">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        {{getTypeDisplayName(row.Type)}}
                        <div class="text-sub hidden-md-up">{{row.Created | appDate}}</div>
                    </td>
                    <td class="hidden-sm-down">{{row.Created | appDate}}</td>
                    <td class="hidden-md-down">{{row.Registered | appDate}}</td>
                    <td class="hidden-xs-down">
                        <a href="{{getFileUrl(row.Id)}}" target="_blank" *ngIf="row.FileId">{{'myApplications_downloadFile' | translate}}</a>
                    </td>
                    <td class="hidden-lg-up">
                        <div class="table-actions">
                            <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)"
                                    [ngbTooltip]="'myApplications_showDetails' | translate">
                                <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="isExpanded(row)" class="table-row-details hidden-lg-up">
                    <td colspan="9">
                        <div class="dl">
                            <div class="hidden-lg-up">
                                <span [translate]="'myApplications_lblReferencePrepared'"></span>
                                <span>{{row.Registered | appDate}}</span></div>

                            <div class="hidden-sm-up">
                                <span [translate]="'myApplications_lblReferenceFile'"></span>
                                <span>
                                    <a href="{{getFileUrl(row.Id)}}" target="_blank" *ngIf="row.FileId">{{'myApplications_downloadFile' | translate}}</a>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </section>

    <section class="block">
        <h5>{{'myApplications_applications' | translate}}</h5>

        <app-table [rows]="applications" [columns]="applicationColumns" [pageSize]="rowsPerPage">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        {{getTypeDisplayName(row.Type)}}
                        <div class="text-sub hidden-md-up">{{row.Created | appDate}}</div>
                    </td>
                    <td class="hidden-sm-down">{{row.Created | appDate}}</td>
                    <td class="hidden-md-down">{{row.Registered | appDate}}</td>
                    <td class="hidden-xs-down">{{row.DvsStatus ? ('applicationDvsStatus_' + row.DvsStatus | translate) : ''}}</td>
                    <td class="hidden-lg-up">
                        <div class="table-actions">
                            <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)"
                                    [ngbTooltip]="'myApplications_showDetails' | translate">
                                <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="isExpanded(row)" class="table-row-details hidden-lg-up">
                    <td colspan="9">
                        <div class="dl">
                            <div class="hidden-lg-up">
                                <span [translate]="'myApplications_lblReferencePrepared'"></span>
                                <span>{{row.Registered | appDate}}</span>
                            </div>

                            <div class="hidden-sm-up">
                                <span [translate]="'myApplications_lblDecisionSigned'"></span>
                                <span>{{row.DvsStatus ? ('applicationDvsStatus_' + row.DvsStatus | translate) : ''}}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </section>
</div>
