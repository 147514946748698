import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';

import { StudentBalanceGetResult, PrintBalanceGetResult, PayResponse } from '../models/StudentBalance';

export type printPaymentType = 'PrintBalance' | 'PrintBalanceV2';

/**
 * Student Balance service
 */
@Injectable({ providedIn: "root" })
export class StudentBalanceService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/studentbalance` }
	
    /**
     * Get student balance data.
     */
    get(custId?: string, custIdType?: string): Observable<StudentBalanceGetResult> {
        return this.http.get<StudentBalanceGetResult>(this.apiUrl, {
            params: {
                custId: custId,
                custIdType: custIdType
            }
        });
    }

    getPrintBalance(userUpn?: string): Observable<PrintBalanceGetResult> {
        return this.http.get<PrintBalanceGetResult>(`${this.apiUrl}/print`, { params: { userUpn } });
	}

	getPrintBalanceV2(userUpn?: string): Observable<PrintBalanceGetResult> {
        return this.http.get<PrintBalanceGetResult>(`${this.apiUrl}/printV2`, { params: { userUpn } });
	}

    print(file: any): Observable<boolean> {
        const url = `${this.apiUrl}/print`;

        let formData = new FormData();
        formData.append('file[]', file, file.name);

        return this.http.post<boolean>(url, formData);
    }

    /**
     * Pay the contract.
     * @param contract
     * @param customerId
     * @param email
     */
    pay(contract: any, customerId?: string, email?: string): Observable<any> {
        const url = `${this.apiUrl}/pay`;
        return this.http.post(url, {
            amount: Math.abs(contract.Balance),
            customerId: customerId,
            email: email,
            contractNumber: contract.Number,
            paymentNumbers: contract.Invoices.filter(t => t.Status == 0).map(t => t.Number),
            paymentType: 'StudentBalance'
        });
    }

    payForPrint(amount: number, paymentType: printPaymentType): Observable<PayResponse> {
        const url = `${this.apiUrl}/pay`;
        return this.http.post<PayResponse>(url, {
            Amount: amount,
			PaymentType: paymentType
        });
	}
}
