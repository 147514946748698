import { ElementRef, forwardRef, ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ControlContainer, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../services/app.service';

let counter = 0;

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateComponent),
            multi: true
        }
    ]
})
export class DateComponent implements ControlValueAccessor  {
    constructor(
        private app: AppService,
        private adapter: NgbDateAdapter<Date>,
        private parser: NgbDateParserFormatter
    ) {
        this.id = `date-${counter++}`;
        this.placeholder = this.app.translate('dateFormatHint');
    }

    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() min: Date | string;
    @Input() max: Date | string;
    @Input() marked: Date[] = [];

    @Output() dateChange = new EventEmitter<Date>();

    readonly id: string;

    get ngbMinDate(): NgbDateStruct {
        return this.dateToNgb(this.min);
    }

    get ngbMaxDate(): NgbDateStruct {
        return this.dateToNgb(this.max);
    }

    get value(): Date {
        return this._value;
    }

    @ViewChild('input', { static: true }) private input: ElementRef;

    private _onChange = (obj: Date) => { };
    private _value: Date;

    //ControlValueAccessor
    writeValue(obj: Date): void {
        this._value = obj;
        this.input.nativeElement.value = this.value ? this.parser.format(this.dateToNgb(this.value)) : '';
    }

    registerOnChange(fn: (obj: Date) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //ControlValueAccessor

    onChange(event) {
        const val = this.parser.parse(event.target.value);
        
        this._value = val ? this.adapter.toModel(val) : null;
        this.emitChange();
    }

    onSelect(event: NgbDate) {
        this._value = this.adapter.toModel(event);
        this.emitChange();
    }

    isMarked(date: NgbDate): boolean {
        const d = new Date(date.year, date.month - 1, date.day).getTime();
        return this.marked.some(t => t.getTime() == d);
    }

    private dateToNgb(date: Date | string): NgbDateStruct {
        if (!date) return undefined;

        if (typeof date === 'string') {
            date = new Date(date);
        }

        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        };
    }

    private emitChange() {
        this._onChange(this._value);
        this.dateChange.emit(this._value);
    }
}
