<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/plan-summary', text: 'planSummary_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'planSummary_title' | translate}}</h1>
        <aside *ngIf="canPickStudent && !studentPickerOpened">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'planSummary_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="pickStudent($event)"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened">
        <app-alert level="warning" *ngIf="notAvailable">{{'eServicesNotAvailable' | translate}}</app-alert>

        <div class="row" *ngIf="residentInfo.length > 1">
            <div class="col-sm-6 form-field">
                <label class="form-label">{{'planSummary_lblChangeSpeciality' | translate}}</label>
                <app-select [(ngModel)]="specialityId"
                            [options]="residentInfo"
                            [valueProperty]="'SpecialityId'"
                            [displayFn]="specialityDisplayFn"
                            [required]="true"
                            (selectionChange)="onSpecialityChange()"
                            name="specialityId">
                </app-select>
            </div>
        </div>

        <ng-container *ngIf="resident">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
                <li [ngbNavItem]="i" *ngFor="let tab of tabs; let i = index">
                    <a ngbNavLink [class.hidden-xl-down]="tabs.length > 7">{{tab.title | translate}}</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngSwitch]="tab.id">
                            <div class="mt-3">
                                <app-plan-summary-base-info *ngSwitchCase="'base'"
                                                            [resident]="resident"
                                                            [supervisor]="supervisor"></app-plan-summary-base-info>

                                <app-plan-summary-courses *ngSwitchCase="'courses'"
                                                          [studyYear]="resident.StudyYear"></app-plan-summary-courses>

                                <app-plan-summary-manipulations *ngSwitchCase="'manipulations'"
                                                                [residentEmail]="resident.Email"></app-plan-summary-manipulations>

                                <app-plan-summary-shifts *ngSwitchCase="'shifts'"
                                                         [residentEmail]="resident.Email"
                                                         [studyYear]="resident.StudyYear"
                                                         [getFileUrlFn]="getFileUrlFn"></app-plan-summary-shifts>

                                <app-plan-summary-seminars *ngSwitchCase="'seminars'"
                                                           [studyYear]="resident.StudyYear"></app-plan-summary-seminars>

                                <app-plan-summary-documents *ngSwitchCase="'documents'"></app-plan-summary-documents>

                                <app-plan-summary-quality-evaluation *ngSwitchCase="'evaluation'"
                                                                     [resident]="resident"
                                                                     [supervisor]="supervisor"></app-plan-summary-quality-evaluation>

                                <app-plan-summary-events *ngSwitchCase="'events'"
                                                         [resident]="resident"></app-plan-summary-events>
                            </div>
                        </ng-container>
                    </ng-template>
                </li>
                <li ngbDropdown class="nav-item" placement="bottom-start" [class.hidden-lg-up]="tabs.length < 8">
                    <a class="nav-link" [class.active]="true" ngbDropdownToggle>
                        {{tabs[activeTabId].title | translate}}
                    </a>
                    <div ngbDropdownMenu class="more-tab-menu">
                        <button ngbDropdownItem *ngFor="let n of tabs; let i = index" (click)="nav.select(i)" [disabled]="i == activeTabId">
                            {{n.title | translate}}
                        </button>
                    </div>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>
    </ng-container>
</div>
