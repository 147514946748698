export enum EmailAliasStatus {
    Draft = 'Draft',
    Processed = 'Processed',
    Error = 'Error'
}

export class EmailEntry {
    Email: string;
    ApplicationDate: Date;
    IsPrimary: boolean;
    Status: EmailAliasStatus;
}

export class EmailAliasConfig {
    MinEmailSymbolCount: number;
    MaxEmailSymbolCount: number;
    MaxEmailChangeCount: number;
    MinDaysBetweenChanges: number;
    EmailDomain: string;
}