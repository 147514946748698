import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';

/**
 * Stream service
 */
@Injectable({ providedIn: "root" })
export class StreamsService {
    constructor(public http: HttpClient) { }

	public get apiUrl(): string { return `${ENV.apiUrl}/streams` }

    logStreamView(streamName: string): Observable<any> {
		const url = `${this.apiUrl}/log(${streamName})`;
        return this.http.get(url);
	}
}
