export interface IAddressUnit {
    Id?: number;
    Value?: string;
}

export class Address {
    Id: string;
    IsForeign: boolean;
    FullText: string;
    Country: IAddressUnit;
    Region: IAddressUnit;
    City: IAddressUnit;
    District: IAddressUnit;
    Parish: IAddressUnit;
    Village: IAddressUnit;
    Street: IAddressUnit;
    House: IAddressUnit;
    Apartment: IAddressUnit;
    PostCode: string;
}

export interface IAddressResponse {
    Id: string;
    FullText: string;
    Cities: IAddressUnit[];
    Districts: IAddressUnit[];
    Parishes: IAddressUnit[];
    Villages: IAddressUnit[];
    Streets: IAddressUnit[];
    Houses: IAddressUnit[];
    Apartments: IAddressUnit[];
    PostCodes: string[];
}

export class AddressFilter {
    CityId?: number;
    DistrictId?: number;
    ParishId?: number;
    VillageId?: number;
    StreetId?: number;
    HouseId?: number;
    ApartmentId?: number;
}

export interface ICountry {
    Code: string;
    Name: string;
}
