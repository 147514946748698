import { Component, OnInit } from '@angular/core';

import { PasswordService } from '../../../services/password.service';
import { AppService } from '../../../services/app.service';

import { messageCodes, languageCacheKey } from './shared';
import { MessageService } from '../../../services/message.service';

@Component({
    selector: 'app-password-request-employee',
    templateUrl: './request.component.html',
    styleUrls: ['../password.component.scss']
})
export class EmployeePasswordRequestComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: PasswordService,
        private messageService: MessageService
    ) { }

    consent: boolean;
    consentBody: string;
    consentCheckbox: string;

    ngOnInit() {
        this.app.addLoading(this.messageService.getForEmployeePasswordReset(this.app.currentLanguage)).subscribe(data => {
            const body = data.find(t => t.Code === messageCodes.consentBody) || { Text: messageCodes.consentBody };
            const checkbox = data.find(t => t.Code === messageCodes.consentCheckbox) || { Text: messageCodes.consentCheckbox };

            this.consentBody = body.Text;
            this.consentCheckbox = checkbox.Text;
        });
    }

    submit() {
        this.app.addLoading(this.service.requestEmployeePasswordReset(this.app.currentLanguage)).subscribe(result => {
            if (result.HasError) {
                this.app.showError(this.app.translate('password_employeeRequestFailed'), this.app.translate('password_notresettitle'));
            } else {
                sessionStorage.setItem(languageCacheKey, this.app.currentLanguage);
                location.href = result.RedirectUrl;
            }
        });
    }
}
