<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/manipulations', text: 'manipulation_listTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'manipulation_listTitle' | translate}}</h1>
        <aside>
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()" *ngIf="!studentPickerOpened && (canPickStudent || student)">
                <i class="fi fi-rs-user"></i>
                {{'manipulation_btnPickStudent' | translate}}
            </button>

            <button class="btn btn-outline-primary" type="button" *ngIf="canEdit && !studentPickerOpened" [routerLink]="['/manipulations/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened">
        <div class="data-value" *ngIf="canPickStudent && student">
            <label>{{'manipulation_lblStudent' | translate}}</label>
            <div>{{student.FirstName}} {{student.LastName}}, {{student.Email}}</div>
        </div>

        <app-table [rows]="items" [columns]="columns" *ngIf="!canPickStudent || student">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        <span class="badge"
                              [class.text-bg-success]="row.Status == 'Submitted'"
                              [class.text-bg-secondary]="row.Status == 'Draft'">
                            {{'manipulation_status' + row.Status | translate}}
                        </span>
                    </td>
                    <td class="hidden-md-down">{{row.StudyYear}}</td>
                    <td class="hidden-md-down">{{row.Speciality}}</td>
                    <td class="hidden-xs-down">{{row.From | appDate}}</td>
                    <td class="hidden-xs-down">{{row.To | appDate}}</td>
                    <td class="hidden-sm-up">{{row.From | appDate}} - {{row.To | appDate}}</td>
                    <td class="hidden-sm-down">
                        <div class="table-actions">
                            <button class="btn btn-outline btn-icon" type="button" [routerLink]="['/manipulations', row.Id] | localize"
                                    [ngbTooltip]="'open' | translate">
                                <i class="fi fi-rs-pencil" *ngIf="row.Status == 'Draft'"></i>
                                <i class="fi fi-rs-document" *ngIf="row.Status == 'Submitted'"></i>
                            </button>

                            <button type="button" (click)="delete(row)" *ngIf="canEdit && row.Status == 'Draft'" class="btn btn-outline-primary btn-icon"
                                    [ngbTooltip]="'delete' | translate">
                                <i class="fi fi-rs-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr class="hidden-lg-up table-row-details">
                    <td colspan="9">
                        <div><label>{{'manipulation_lblStudyYear' | translate}}:</label> {{row.StudyYear}}</div>
                        <div><label>{{'manipulation_lblSpeciality' | translate}}:</label> {{row.Speciality}}</div>

                        <div class="mt-3 text-end hidden-md-up">
                            <button class="btn btn-outline btn-icon-last" type="button" [routerLink]="['/manipulations', row.Id] | localize">
                                {{'open' | translate}}
                                <i class="fi fi-rs-angle-right"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </ng-container>
</div>
