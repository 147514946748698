import { Component, OnInit } from '@angular/core';
import { IExternalServiceConfigListItem } from '../../models/ExternalServiceConfig';

import { AppService } from '../../services/app.service';
import { ExternalServiceConfigService } from '../../services/external-service-config.service';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
    selector: 'app-external-service-config-list',
    templateUrl: './external-service-config-list.component.html'
})
export class ExternalServiceConfigListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: ExternalServiceConfigService
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'Code', label: 'externalServiceConfig_lblCode', sorts: true },
        { property: 'Status', label: 'externalServiceConfig_lblStatus', sorts: true },
        { property: 'Version', label: 'externalServiceConfig_lblVersion', sorts: true, type: 'number' },
        { property: 'Name', label: 'externalServiceConfig_lblName', sorts: true },
        { width: '1px' }
    ];

    items: IExternalServiceConfigListItem[] = [];

    ngOnInit() {
        this.loadItems();
    }

    delete(item: IExternalServiceConfigListItem) {
        this.app.confirm(this.app.translate('externalServiceConfig_deleteConfirm').replace('{name}', item.Name), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.loadItems();
            });
        });
    }

    canDelete(item: IExternalServiceConfigListItem): boolean {
        return item.Status === 'Draft';
    }

    private loadItems() {
        this.app.addLoading(this.service.get()).subscribe(data => {
            this.items = data;
        });
    }
}
