import { Component, Input, OnInit } from '@angular/core';

import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { IPlanSummaryManipulation } from '../../../models/PlanSummary';
import { AppService } from '../../../services/app.service';
import { PlanSummaryService } from '../../../services/plan-summary.service';
import { ITableColumn } from '../../../shared/table/table.component';
import { store, currentRequestParams } from '../plan-summary.component';

interface IManipulationRow extends IPlanSummaryManipulation {
    fullName: string;
}

@Component({
    selector: 'app-plan-summary-manipulations',
    templateUrl: './plan-summary-manipulations.component.html',
    styleUrls: ['../plan-summary.component.scss']
})
export class PlanSummaryManipulationsComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PlanSummaryService
    ) { }

    @Input() residentEmail: string;

    rows: IManipulationRow[] = [];
    total: number;
    error: string;
    isLoaded: boolean;
    canView: boolean;
    canAdd: boolean;

    get residentUpn(): string {
        return (this.residentEmail || '').split('@')[0];
    }

    readonly columns: ITableColumn[] = [
        { property: 'fullName', label: 'planSummary_manipulations_lblManipulation', sorts: true },
        { property: 'Level', label: 'planSummary_manipulations_lblLevel', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'CountPlanned', label: 'planSummary_manipulations_lblPlanned', sorts: true, cssClass: 'hidden-sm-down', type: 'number' },
        { property: 'CountFact', label: 'planSummary_manipulations_lblFact', sorts: true, cssClass: 'hidden-sm-down', type: 'number' },
        { property: 'CountRemaining', label: 'planSummary_manipulations_lblRemaining', sorts: true, cssClass: 'hidden-sm-down', type: 'number' },
        { property: 'CountPlanned', label: 'planSummary_manipulations_lblPlannedFactRemaining', sorts: true, cssClass: 'hidden-md-up', type: 'number' }
    ];

    private manipulations: IPlanSummaryManipulation[] = [];
    private allRows: IManipulationRow[] = [];

    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        if (this.app.currentUser) {
            const user = this.app.currentUser;
            const self = user.email == this.residentEmail;
            const canSet = user.rights.includes('MANIPULATION.SET_STUDENT');

            this.canView = user.rights.includes('MANIPULATION.VIEW') && (self || canSet);
            this.canAdd = user.rights.includes('MANIPULATION.EDIT') && (self || canSet);
        }

        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            if (!term) {
                this.rows = [...this.allRows];
            } else {
                const search = term.toLowerCase();
                this.rows = this.allRows.filter(t => {
                    return t.fullName?.toLowerCase().includes(search)
                        || t.Level?.toLowerCase().includes(search)
                        || t.CountFact?.toString().includes(search)
                        || t.CountPlanned?.toString().includes(search)
                        || t.CountRemaining?.toString().includes(search);
                });
            }
        });

        this.getData().subscribe(data => {
            this.isLoaded = true;
            this.manipulations = data;

            this.allRows = this.manipulations.map(t => {
                return {
                    ...t,
                    fullName: `${t.Code} ${t.Name}`
                }
            });

            this.rows = [...this.allRows];
            this.total = this.allRows.length;
        }, err => {
            this.error = this.app.getHttpResponseError(err);
        });
    }

    search(event) {
        this.searchSubj.next(event.target.value);
    }

    private getData() {
        if (store.manipulations) {
            return of(store.manipulations);
        } else {
            return this.app.addLoading(this.service.getManipulations(currentRequestParams)).pipe(tap(data => {
                store.manipulations = data;
            }));
        }
    }
}
