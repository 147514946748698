import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as FileSaver from 'file-saver';

import { TimeTableInfo, TimeTableProgram, TimeTableSemester, TimeTableView } from '../../models/TimeTable';

import { AppService } from '../../services/app.service';
import { TimeTableService } from '../../services/time-table.service';
import { ParameterService } from '../../services/parameter.service';
import { PersonService } from '../../services/person.service';

import { PersonType } from '../../shared/person-picker/person-picker.component';
import { IPersonSearchResultItem } from '../../models/Person';
import { UserType } from '../../models/UserInfo';
import { MessageService } from '../../services/message.service';

const dayNames = {
    lv: ['Pr', 'Ot', 'Tr', 'Ce', 'Pk', 'Se', 'Sv'],
    en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
};

@Component({
    selector: 'app-time-table',
    templateUrl: './time-table.component.html',
    styleUrls: ['./time-table.component.scss']
})
export class TimeTableComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: TimeTableService,
        private messageService: MessageService,
        private paramService: ParameterService,
        private studentService: PersonService,
        private route: ActivatedRoute
    ) { }

    isDenied: boolean;

    selectedProgram: TimeTableProgram;
    selectedSemester: TimeTableSemester;
    selectedView: TimeTableView = TimeTableView.Daily;
    fullSemester: boolean;

    programs: TimeTableProgram[] = [];
    semesters: TimeTableSemester[] = [];
    events: TimeTableInfo[] = [];

    readonly views = [TimeTableView.Daily, TimeTableView.Agenda];
    readonly days = [1, 2, 3, 4, 5, 6, 7];
    readonly dayNames = dayNames[this.app.currentLanguage] || dayNames.lv;

    viewData: any;

    personPickerOpened: boolean;
    canPickStudent: boolean;
    canPickEmployee: boolean;
    person: IPersonSearchResultItem;
    personPickerType: PersonType;

    isLoaded: boolean;
    noProgramsMessage: string;
    showOfficeCalendarLink: boolean;
	officeCalendarUrl: string;

	infoTextTop: string = null;
	infoTextBottom: string = null;

    get isStudent(): boolean {
        return this.person ? this.person.UserType == UserType.Student : this.app.currentUser.type == UserType.Student;
    }

    get isEmployee(): boolean {
        return this.person ? this.person.UserType == UserType.Employee : this.app.currentUser.type == UserType.Employee;
    }

    @ViewChild('eventTable', { static: false }) private eventTable: ElementRef;
    @ViewChild('top', { static: false }) private topAnchor: ElementRef;
    @ViewChild('scrollTopBtn', { static: false }) private scrollTopButton: ElementRef;

    private scrollButtonMoveTimeout;

    ngOnInit() {
        const lang = this.app.currentLanguage;
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf('TIME_TABLE.SET_STUDENT') > -1;
            this.canPickEmployee = user.rights.indexOf('TIME_TABLE.SET_EMPLOYEE') > -1;

            this.personPickerType = this.canPickStudent ? 'student' : this.canPickEmployee ? 'employee' : 'student';

            if (user.type == UserType.Employee) {
                this.selectedView = TimeTableView.Agenda;
            }

            const id = this.route.snapshot.queryParams['id'];

            if (id && !this.canPickStudent) {
                this.isDenied = true;
                return;
            }

            if (this.canPickStudent) {
                if (id) {
                    this.app.addLoading(this.studentService.findStudents('student_id', id)).subscribe(data => {
                        if (data.length) {
                            this.setPerson(data[0]);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                            this.togglePersonPicker();
                        }
                    });
                } else {
                    this.togglePersonPicker();
                }
            } else {
                this.init();
            }
        }

        {
            const codes = {
                empty: 'TIME_TABLE_NO_PROGRAMS',
                top: 'TIME_TABLE_INFO_TEXT_TOP',
                bottom: 'TIME_TABLE_INFO_TEXT_BOTTOM'
            };
            
            this.app.addLoading(this.messageService.getByCodes([codes.empty, codes.top, codes.bottom])).subscribe(data => {
                this.noProgramsMessage = this.messageService.getTextByLanguage(data.find(t => t.Code == codes.empty), lang);
                this.infoTextTop = this.messageService.getTextByLanguage(data.find(t => t.Code == codes.top), lang);
                this.infoTextBottom = this.messageService.getTextByLanguage(data.find(t => t.Code == codes.bottom), lang);
            });
        }

        {
            this.app.addLoading(this.paramService.getValues()).subscribe(data => {
                this.showOfficeCalendarLink = data.some(t => t.Code === 'TimeTableShowOfficeCal' && t.Value === '1');

                let officeCalUrlParam = data.find(t => t.Code === 'TimeTableOfficeCalUrl');
                this.officeCalendarUrl = officeCalUrlParam ? officeCalUrlParam.Value : undefined;
            });
        }
    }

    togglePersonPicker(personType: PersonType = 'student') {
        const typeChange = personType != this.personPickerType;

        this.personPickerType = personType;
        this.personPickerOpened = typeChange || !this.isLoaded || !this.personPickerOpened;
    }

    setPerson(person: IPersonSearchResultItem) {
        this.personPickerOpened = false;
        this.person = person;
        this.selectedProgram = undefined;
        this.viewData = undefined;
        this.init();
    }

    loadSemesters() {
        const user = this.getSelectedUser();

        if (!user.key) return;

        this.app.addLoading(this.service.getSemesters(user.key, user.type)).subscribe(data => {
            this.semesters = data;
            
            const selected = this.semesters.length === 1 ? this.semesters[0] : this.semesters.find(t => t.IsCurrent);
            
            if (selected) {
                this.selectedSemester = selected;
                this.loadInfo();
            }
        });
    }

    loadInfo() {
        const user = this.getSelectedUser();

        if (!user.key) return;

        this.viewData = undefined;

        this.app.addLoading(this.service.getInfo(
            user.key,
            user.type,
            this.selectedSemester.PeriodId,
            this.selectedView,
            this.fullSemester
        )).subscribe(data => {
            this.events = data;
            this.createViewData(data);
        });
    }

    pdf() {
        if (!this.viewData)
            return false;

        const user = this.getSelectedUser();

        const subTitle = user.type == UserType.Employee
            ? `${user.firstName} ${user.lastName} (${user.email})}`
            : this.selectedProgram.Info;

        this.app.addLoading(this.service.getPdf({
            fileName: this.selectedSemester.FullName,
            title: this.selectedSemester.FullName,
            subTitle: subTitle,
            events: this.eventTable.nativeElement.innerHTML
        })).subscribe(result => {
            FileSaver.saveAs(result, `${this.selectedSemester.FullName}.pdf`);
        });
    }

    switchView(view: TimeTableView) {
        if (this.selectedView != view) {
            this.selectedView = view;
            this.loadInfo();
        }
    }

    scrollTop() {
        window.scrollTo({ top: this.topAnchor.nativeElement.offsetTop, behavior: 'smooth' });
    }

    @HostListener('window:scroll', ['$event'])
    @HostListener('window:resize', ['$event'])
    onScroll(event) {
        if (this.scrollTopButton) {
            const scrollY = window.scrollY;
            const el = this.scrollTopButton.nativeElement;

            if (scrollY > 300 && scrollY > this.topAnchor.nativeElement.offsetTop) {
                if (this.scrollButtonMoveTimeout)
                    clearTimeout(this.scrollButtonMoveTimeout);

                this.scrollButtonMoveTimeout = setTimeout(() => {
                    const bottomSpace = document.body.scrollHeight - el.offsetTop;
                    let diff = document.body.scrollHeight - document.body.offsetHeight - bottomSpace - scrollY + el.offsetHeight + 20/* margin */;

                    if (diff < 0)
                        diff = 0;

                    el.style.transform = `translateY(-${diff}px)`;
                }, 100);
            } else {
                el.style.transform = `translateY(0)`;
            }
        }
    } 

    private createViewData(data: TimeTableInfo[]) {
        const colors = { even: 'even', odd: 'odd' };

        switch (this.selectedView) {
            case TimeTableView.Daily:
                let grouped: { [key: string]: any[] } = {};

                // first, group events by day
                // then by time, subject code, location and teacher
                data.forEach(t => {
                    let eventKey = `${t.Time}_${t.SubjectCode}_${t.Location}_${t.Teacher}`;

                    if (!grouped[t.DayNumber])
                        grouped[t.DayNumber] = [];

                    let dayTimeKey = `${t.DayNumber}_${t.Time}`;

                    let ex: any = grouped[t.DayNumber].find(x => x.key === eventKey);
                    let timeEx = grouped[t.DayNumber].filter(x => x.time === t.Time);

                    if (!ex) {
                        ex = {
                            key: eventKey,
                            dayTimeKey: dayTimeKey,
                            items: [], // unique event list
                            time: t.Time,
                            timeIndex: timeEx.length, // index by time
                            timeTotal: 0, // total items by time
                            get info() {
                                return this.items[0];
                            },
                            get dates() {
                                return this.items.map(t => t.Date);
                            },
                            get comments() {
                                return this.items.filter((t: TimeTableInfo) => !!t.Comment || !!t.EmployeeComment).map((t: TimeTableInfo) => {
                                    return {
                                        date: t.Date,
                                        comment: t.Comment,
                                        employeeComment: t.EmployeeComment
                                    }
                                });
                            }
                        };

                        grouped[t.DayNumber].push(ex);
                        timeEx.push(ex);
                    }

                    // update all events so that every one is aware of total event count in the given time
                    timeEx.forEach(x => x.timeTotal = timeEx.length);

                    ex.items.push(t);
                });

                let current = 0;
                for (let i in grouped) {
                    let color = current % 2 === 0 ? colors.even : colors.odd;
                    grouped[i].forEach(t => t._color = color);
                    current++;
                }

                this.viewData = grouped;
                break;

            case TimeTableView.Agenda:
                let currentDate: string;
                let dateIndex = 0;
                let dateFirst: TimeTableInfo;
                let color = 1;

                data.forEach(t => {
                    if (t.Date !== currentDate) {
                        color = -color;
                        dateIndex = 0;
                        dateFirst = t;
                        t['_dateIndex'] = dateIndex;
                        currentDate = t.Date;
                    }

                    t['_color'] = color === 1 ? colors.odd : colors.even;

                    dateIndex++;
                    dateFirst['_dateTotal'] = dateIndex;
                });

                this.viewData = data;

                break;

            default:
                throw new Error(`Unsupported time table view: ${this.selectedView}.`);
        }
    }

    private getSelectedUser(): {
        personId: string,
        email: string,
        type: UserType,
        firstName: string,
        lastName: string,
        key?: string
    } {
        let personId: string;
        let email: string;
        let type: UserType;
        let firstName: string;
        let lastName: string;
        let key: string;

        if (this.person) {
            personId = this.person.PersonDetailsId;
            email = this.person.Email;
            firstName = this.person.FirstName;
            lastName = this.person.LastName;
            type = this.person.UserType;
        } else {
            personId = this.app.currentUser.personDetailsId;
            email = this.app.currentUser.email;
            firstName = this.app.currentUser.firstName;
            lastName = this.app.currentUser.lastName;
            type = this.app.currentUser.type;
        }

        if (this.selectedProgram) {
            personId = this.selectedProgram.StudentId;
            key = this.selectedProgram.Key;
        } else {
            key = personId;
        }

        return {
            personId,
            email,
            type,
            firstName,
            lastName,
            key
        };
    }

    private loadPrograms() {
        this.app.addLoading(this.service.getPrograms(this.person ? this.person.Email : undefined)).subscribe(data => {
            this.programs = data;

            if (this.programs.length) {
                this.selectedProgram = this.programs[0];
                this.loadSemesters();
            }

            this.isLoaded = true;
        });
    }

    private init() {
        if (this.isStudent) {
            this.loadPrograms();
        } else {
            this.isLoaded = true;
            this.loadSemesters();
        }
    }
}
