import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IStudyQualityEvaluation, IStudyQualityEvaluationEditModel } from '../models/StudyQualityEvaluation';
import { Utils } from '../core/Utils';

/**
 * Study quality evaluation service
 */
@Injectable({ providedIn: "root" })
export class StudyQualityEvaluationService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/studyQualityEvaluations` }

    //getYearsByStudent(studentId: string): Observable<number[]> {
    //    const url = `${this.apiUrl}/students/${studentId}/years`;
    //    return this.http.get<number[]>(url);
    //}

    getByStudent(studentId: string): Observable<IStudyQualityEvaluation[]> {
        const url = `${this.apiUrl}/students/${studentId}`;
        return this.http.get<IStudyQualityEvaluation[]>(url);
    }

    saveEvaluation(studentId: string, model: IStudyQualityEvaluationEditModel): Observable<any> {
        model.Deadline = Utils.getInputDateString(model.Deadline) as any;

        const url = `${this.apiUrl}/students/${studentId}`;
        return this.http.put<any>(url, model);
    }

    saveResidencyEvaluation(studentId: string, model: IStudyQualityEvaluationEditModel): Observable<any> {
        model.ResidencyDeadline = Utils.getInputDateString(model.ResidencyDeadline) as any;

        const url = `${this.apiUrl}/students/${studentId}/residencyEvaluation`;
        return this.http.put<any>(url, model);
    }

    //private buildQueryParams(options: any): any {
    //    const params: any = {};

    //    for (const k in options) {
    //        const val = options[k];

    //        if (val !== undefined && val !== null) {
    //            if (typeof val == 'string') {
    //                if (val) params[k] = val;
    //            } else {
    //                params[k] = val;
    //            }
    //        }
    //    }

    //    return params;
    //}
}
