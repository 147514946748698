<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/permissions', text: 'permissions_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'permissions_title'"></h1>
        <aside>
            <button type="button" class="btn btn-outline-primary" (click)="add()">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <form #filterForm="ngForm">
        <div class="row">
            <div class="col form-field">
                <app-select [options]="roles"
                            [placeholder]="'permissions_lblRoleFilter' | translate"
                            [displayProperty]="'Value'"
                            name="roleFilter"
                            [(ngModel)]="filterRole"
                            [filter]="true"
                            [filterFn]="roleFilterFn"
                            (selectionChange)="filter()">
                    <ng-template #optionTemplate let-option>
                        <div>{{option.Value}}</div>
                        <div class="text-sub">{{option.Code}}</div>
                    </ng-template>
                </app-select>
            </div>

            <div class="col form-field">
                <app-select [options]="permissionGroups"
                            [placeholder]="'permissions_lblPermissionGroupFilter' | translate"
                            name="permissionGroupFilter"
                            [(ngModel)]="filterPermissionGroup"
                            [filter]="true"
                            (selectionChange)="filter()">
                </app-select>
            </div>
        </div>
    </form>

    <app-table [rows]="items" [columns]="columns" [sticky]="'right'" [(page)]="page">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    {{row.Role?.Value}}
                    <div class="text-sub">{{row.Role?.Code}}</div>
                </td>
                <td>
                    {{row.Permission?.Value}}
                    <div class="text-sub">{{row.Permission?.Code}}</div>
                </td>
                <td>
                    <div class="table-actions">
                        <button type="button" (click)="edit(row)" class="btn btn-outline btn-icon" [ngbTooltip]="'edit' | translate">
                            <i class="fi fi-rs-pencil"></i>
                        </button>

                        <button type="button" (click)="delete(row)" class="btn btn-outline btn-icon" [ngbTooltip]="'delete' | translate">
                            <i class="fi fi-rs-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
