<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/on-call-shifts', text: 'onCallShift_listTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'onCallShift_listTitle' | translate}}</h1>
        <aside>
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()" *ngIf="!studentPickerOpened && (canPickStudent || student)">
                <i class="fi fi-rs-user"></i>
                {{'onCallShift_btnPickStudent' | translate}}
            </button>

            <button type="button" class="btn btn-outline-primary" *ngIf="canEdit && !studentPickerOpened" [routerLink]="['/on-call-shifts/add'] | localize">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened">
        <div class="data-value" *ngIf="canPickStudent && student">
            <label>{{'onCallShift_lblStudent' | translate}}</label>
            <div>{{student.FirstName}} {{student.LastName}}, {{student.Email}}</div>
        </div>

        <app-table [rows]="items" [columns]="columns" *ngIf="!canPickStudent || student">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        <span class="badge"
                              [class.text-bg-secondary]="row.Status == 'Submitted'"
                              [class.text-bg-light]="row.Status == 'Draft'"
                              [class.text-bg-success]="row.Status == 'APST'"
                              [class.text-bg-warning]="row.Status == 'ATC'"
                              [class.text-bg-danger]="row.Status == 'NOR'">
                            {{row.StatusName || ('onCallShift_status' + row.Status | translate)}}
                        </span>
                    </td>
                    <td class="hidden-md-down">{{row.StudyYear}}</td>
                    <td class="hidden-md-down">{{row.Speciality}}</td>
                    <td class="hidden-sm-down">
                        <div *ngFor="let g of row.shiftsByMonth" class="shifts-by-month">
                            <div class="badge bg-light text-dark text-nowrap month" (click)="$event.stopPropagation(); toggleShiftsByMonth(g)">
                                <span>{{g.name}}&nbsp;&nbsp;</span>
                                <span>{{g.hours}}{{'hoursShortName' | translate}}.</span>
                            </div>

                            <div class="datepicker datepicker-readonly calendar shadow-md" *ngIf="g.isOpened">
                                <ngb-datepicker #d navigation="none" [startDate]="g.dates[0]" [dayTemplate]="dayTemplate"></ngb-datepicker>
                            </div>

                            <ng-template #dayTemplate let-date>
                                <div class="btn-light"
                                     [class.bg-primary]="isDateSelected(date, g.dates)">
                                    {{date.day}}
                                </div>
                            </ng-template>
                        </div>
                    </td>
                    <td>{{row.PeriodFrom | appDate}} - {{row.PeriodTo | appDate}}</td>
                    <td class="hidden-xs-down">{{row.TotalHours}}</td>
                    <td class="hidden-sm-down">
                        <div class="table-actions">
                            <button type="button" (click)="delete(row)" *ngIf="canEdit && row.Status == 'Draft'" class="btn btn-outline-primary btn-icon"
                                    [ngbTooltip]="'delete' | translate">
                                <i class="fi fi-rs-trash"></i>
                            </button>

                            <button type="button" class="btn btn-outline btn-icon" [routerLink]="['/on-call-shifts', row.Id] | localize"
                                    [ngbTooltip]="'open' | translate">
                                <i class="fi fi-rs-angle-right"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr class="hidden-lg-up table-row-details">
                    <td colspan="9">
                        <div><label>{{'onCallShift_lblStudyYear' | translate}}:</label> {{row.StudyYear}}</div>
                        <div><label>{{'onCallShift_lblSpeciality' | translate}}:</label> {{row.Speciality}}</div>
                        <div class="hidden-sm-up"><label>{{'onCallShift_lblTotalHours' | translate}}:</label> {{row.TotalHours}}</div>

                        <div class="mt-3 text-end hidden-md-up">
                            <button type="button" class="btn btn-outline btn-icon-last" [routerLink]="['/on-call-shifts', row.Id] | localize">
                                {{'open' | translate}}
                                <i class="fi fi-rs-angle-right"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </ng-container>
</div>
