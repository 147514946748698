import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { FinalPaper, FinalPaperFile, FinalPaperStatus, FinalPaperFileType, StudentData } from '../../models/FinalPaper';
import { Message } from '../../models/Message';

import { AppService } from '../../services/app.service';
import { FinalPaperService } from '../../services/final-paper.service';
import { ClassifierService } from '../../services/classifier.service';
import { ParameterService } from '../../services/parameter.service';
import { PersonService } from '../../services/person.service';

import { Utils } from '../../core/Utils';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';
import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { createFileStub } from '../../shared/file/file.component';
import { ITableColumn } from '../../shared/table/table.component';
import { IFieldFile } from '../../models/IFieldFile';

const authenticityLinkId = 'authenticityTextLink';

export const messageCodes = {
    // authenticity text shown with checkbox
    authenticity: 'FINAL_PAPER_AUTHENTICITY',
    // authenticity text shown in the dialog
    authenticityFull: 'FINAL_PAPER_AUTHENTICITY_FULL',
    plagiarismCheck: 'FINAL_PAPER_PLAGIARISM_CHECK',
    restriction: 'FINAL_PAPER_RESTRICTION',
    restrictionWarn: 'FINAL_PAPER_RESTRICTION_WARN',
    public: 'FINAL_PAPER_PUBLIC',
    publicYes: 'FINAL_PAPER_PUBLIC_YES',
    publicNo: 'FINAL_PAPER_PUBLIC_NO',
    alert: 'FINAL_PAPER_ALERT',
    warnDraft: 'FINAL_PAPER_WARN_DRAFT',
    warnSubmitted: 'FINAL_PAPER_WARN_SUBMITTED',
    warnFailed: 'FINAL_PAPER_WARN_FAILED',
    warnUploadedDspace: 'FINAL_PAPER_WARN_UPLOADEDDSPACE',
    info: 'FINAL_PAPER_INFO',
    errorWithData: 'FINAL_PAPER_ERROR_WITH_DATA',
    outdated: 'FINAL_PAPER_INFO_OUTDATED'
};

export const parameterCodes = {
    mainFileExtensions: 'FinalPaperMainFileExtensions',
	appendixExtensions: 'FinalPaperAppendixExtensions',
	permissionExtensions: 'FinalPaperPermissionExtensions',
    mainFileMaxSize: 'FinalPaperMainFileMaxSize',
	appendixMaxSize: 'FinalPaperAppendixMaxSize',
	permissionMaxSize: 'FinalPaperPermissionMaxSize',
    totalFileMaxSize: 'FinalPaperTotalFileMaxSize',
    uploadDueDays: 'FinalPaperUploadDueDays'
};

export const userRights = {
    admin: 'FINAL_PAPER.ADMIN',
    contact: 'FINAL_PAPER.CONTACT',
    setStudent: 'FINAL_PAPER.SET_STUDENT'
};

export class FileWrapper implements IFieldFile {
    fileName: string;
    url: string;
    file: File;
    item: FinalPaperFile;
    isLoading: boolean;

    get size(): number {
        return this.file ? this.file.size : this.item ? this.item.ContentLength : 0;
    }
}

export class ValidationResult {
    fields: { name: string, details: string[] }[] = [];
    errors: { text: string, fatal?: boolean }[] = [];

    get isValid(): boolean { return this.fields.length === 0 && this.errors.length === 0; }
    get isFatal(): boolean { return this.errors.some(t => t.fatal); }
}

@Component({
    selector: 'app-final-paper',
    templateUrl: './final-paper.component.html',
    styleUrls: ['./final-paper.component.css']
})
export class FinalPaperComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        public app: AppService,
        private service: FinalPaperService,
        private classifierService: ClassifierService,
        private messageService: MessageService,
        private paramService: ParameterService,
        private studentService: PersonService,
        private route: ActivatedRoute
    ) { }

    readonly annotationMinLength = 500;
    readonly annotationMaxLength = 3000;

    item: FinalPaper = new FinalPaper();

    studentPickerOpened: boolean;
    studentPickerFilter: string;
    canPickStudent: boolean;
    student: IPersonSearchResultItem;
    programme: StudentData;

    isLoaded: boolean;
    isActive: boolean;
    canEditStudentData: boolean;
    canEditOtherLang: boolean;
    baseInfoOutdated: boolean;

    studyPrograms: { studySubjectId: string, name: string }[] = [];
    languages: { code: string, name: string }[] = [];
    otherLanguages: { code: string, name: string }[] = [];
    mainFileExtensions: string;
	appendixExtensions: string;
	permissionExtensions: string;
    mainFileMaxSize: number;
	appendixMaxSize: number;
	permissionMaxSize: number;
    totalFileMaxSize: number;
    uploadDueDays: number;
    isReadonly: boolean;
    isSubmitted: boolean;
    selectedSubjectId: string;

    showBaseInfo: boolean = true;
    showAnnotationAndTags: boolean = true;
    showFileUpload: boolean = true;
    showChangeHistory: boolean;
    showFileChangeHistory: boolean;

    authenticityText: string;
    authenticityTextFull: string;
    plagiarismCheckText: string;
    restrictionText: string;
    restrictionWarnText: string;
    publicText: string;
    publicTextYes: string;

    alert: string;
    warning: string;
    info: string;
    infoOutdated: string;
	errorWithData: string;

    mainFile: FileWrapper = new FileWrapper();
	appendixes: FileWrapper[] = [];
	permissions: FileWrapper[] = [];
    affirmationFile: FinalPaperFile;

    submitted: boolean;
	dataValid: boolean = true;

    changeHistory: any[];
    fileChangeHistory: any[];

    readonly prettyFileSize = Utils.prettyFileSize;

    readonly confirmFileRemoval = (file: FileWrapper) => {
        let subj = new Subject<boolean>();
        this.confirm('finalPaper_confirmFileRemoval', () => subj.next(true));
        return subj.asObservable();
    };

    readonly changeHistoryColumns: ITableColumn[] = [
        { property: 'Modified', label: 'modif_lblModified', sorts: true, type: 'date' },
        { property: 'ModifiedByName', label: 'modif_lblUser', cssClass: 'hidden-sm-down' },
        { property: 'ModifAction', label: 'modif_lblAction', cssClass: 'hidden-md-down' },
        { property: 'Status', label: 'finalPaper_lblStatus', cssClass: 'hidden-md-down' },
        { width: '1px' }
    ];

    readonly fileChangeHistoryColumns: ITableColumn[] = [
        { property: 'Modified', label: 'modif_lblModified', sorts: true, type: 'date' },
        { property: 'ModifiedByName', label: 'modif_lblUser', cssClass: 'hidden-sm-down' },
        { property: 'ModifAction', label: 'modif_lblAction', cssClass: 'hidden-md-down' },
        { width: '1px' }
    ];

    get affirmationPdfUrl(): string {
        return this.affirmationFile ? this.service.getFileDownloadUrl(this.affirmationFile.Id) : null;
    }

    get isExpired(): boolean {
        if (!this.item.UploadDueDate)
            return false;

        const now = new Date();
        now.setHours(0, 0, 0, 0);

        return this.item.UploadDueDate < now;
    }

    get isLanguageEn(): boolean {
        const lang = this.item?.Language?.toLowerCase() || '';
        return lang === 'en' || lang.startsWith('en-');
    }

    get changeHistoryEnabled(): boolean {
        return this.item.Id && this.app.currentUser.rights.indexOf(userRights.admin) > -1;
    }

    private get impersonatedEmail(): string {
        return this.student ? this.student.Email : null;
    }

    @ViewChild('form', { static: true }) private form: NgForm;

    private removedFiles: number[] = [];
    private studentData: StudentData[] = [];
    private itemOriginal: string;
    private requestedStudySubjectId: string;
    private isAdmin: boolean;
    private isEn: boolean;
    private dataLossConfirmed: boolean;
    private messages: Message[];
    private paramsLoaded: boolean;
    private classifiersLoaded: boolean;
    private expanded: any[] = [];

    ngOnInit() {
        const user = this.app.currentUser;

        this.isEn = this.app.currentLanguage === 'en';
        this.itemOriginal = JSON.stringify(this.item);

        if (user) {
            this.isAdmin = user.rights.indexOf(userRights.admin) > -1 || user.rights.indexOf(userRights.contact) > -1;
            this.canPickStudent = user.rights.indexOf(userRights.setStudent) > -1;
            this.studentPickerFilter = user.rights.indexOf(userRights.admin) === -1 && user.rights.indexOf(userRights.contact) > -1 ? 'mystudents' : '';

            if (this.canPickStudent) {
                const queryUpn = this.route.snapshot.queryParams['id'];
                const queryStudySubjectId = this.route.snapshot.queryParams['studid'];

                if (queryUpn && queryStudySubjectId) {
                    this.requestedStudySubjectId = queryStudySubjectId;

                    this.app.addLoading(this.studentService.findStudents('student_id', queryUpn)).subscribe(data => {
                        if (data.length) {
                            this.setStudent(data[0]);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
                } else {
                    this.toggleStudentPicker();
                }
            } else {
                this.init();
            }
        }
    }

    canDeactivate() {
        if (this.dataValid && !this.isReadonly && !this.dataLossConfirmed && this.itemChanged()) {
            let subj = new Subject<boolean>();
            this.app.confirm(this.app.translate('finalPaper_confirmUnsaved'), result => {
                subj.next(result);
            });
            return subj.asObservable();
        }

        return of(true);
    }

    toggleStudentPicker() {
        if (this.studentPickerOpened) {
            this.studentPickerOpened = false;
        } else {
            this.canDeactivate().subscribe(res => {
                if (res) {
                    this.studentPickerOpened = true;
                    this.dataLossConfirmed = true;
					this.dataValid = true;
                }
            });
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.init();
    }

    onFileChange(event: File , wrapper: FileWrapper) {
        if (event) {
            this.removeFile(wrapper);
            wrapper.item = undefined;
        } else {
            this.removeFile(wrapper);
        }
    }

    addAppendix() {
        this.appendixes.push(this.createFileWrapper());
	}

	addPermission() {
		this.permissions.push(this.createFileWrapper());
	}

    removeFile(file: FileWrapper) {
        if (file.item && this.removedFiles.indexOf(file.item.Id) === -1)
            this.removedFiles.push(file.item.Id);
    }

    save(isFinal: boolean = false): boolean {
        let validation = this.validate();
        const fatalErrors = validation.errors.filter(t => t.fatal);

        if (fatalErrors.length) {
            this.submitted = true;
            this.app.showError(fatalErrors.map(t => `<p>${t.text}</p>`).join(''));
            return false;
        }

		let allFiles = [this.mainFile].concat(this.appendixes).concat(this.permissions);
		let appendixesCount = this.appendixes.length;
        let alertFired = false;
        let itemId: number;

        const removeFiles = (next: () => any) => {
            let totalToRemove = this.removedFiles.length;

            if (totalToRemove === 0) {
                next();
            } else {
                const removed = [].concat(this.removedFiles);
                this.removedFiles = [];

                removed.forEach((f, i) => {
                    this.app.addLoading(this.service.removeFile(f)).subscribe(() => {
                        totalToRemove--;

                        if (totalToRemove === 0)
                            next();
                    }, err => {
                        // not removed
                        this.removedFiles.push(f);
                        throw err;
                    });
                });
            }
        };

        const addFiles = (next: () => any) => {
            let totalToAdd = allFiles.filter(t => t && t.file?.size).length;

            if (totalToAdd === 0) {
                next();
            } else {
                allFiles.forEach((t, i) => {
                    if (t && t.file?.size) {
                        t.isLoading = true;

                        const isAppendix = i > 0 && i <= appendixesCount;
                        const isPermission = i > appendixesCount;
                        const type = isAppendix ? 'Appendix' : isPermission ? 'Permission' : 'FinalPaper';

						this.app.addLoading(this.service.addFile(itemId, t.file, type)).subscribe(() => {
                            totalToAdd--;
                            t.isLoading = false;
                            // drop the file to prevent from re-upload if saving again after error
                            t.file = createFileStub(t.file.name);

                            if (totalToAdd === 0)
                                next();
                        }, err => {
                            t.isLoading = false;
                            throw err;
                        });
                    }
                });
            }
        };

        const done = () => {
            if (alertFired)
                return;

            alertFired = true;

            this.loadItem().subscribe(() => {
                this.app.alert.success(this.app.translate('finalPaper_saved')
                    + (isFinal
                        ? '<br>' + this.parsePrintPdfReminderText(this.app.translate('finalPaper_remindToPrintPdf'))
                        : ''
                    )
                );
            });
        }

        this.app.addLoading(this.service.save(this.item)).subscribe(res => {
            itemId = res;
            removeFiles(() => {
                addFiles(() => {
                    if (isFinal) {
                        this.loadItem().subscribe(() => {
                            validation = this.validate();

                            if (validation.isValid) {
                                this.confirm('finalPaper_confirmSubmit', () => {
                                    this.app.addLoading(this.service.submit(itemId)).subscribe(() => done());
                                });
                            } else {
                                let err = '';

                                if (validation.fields.length)
                                    err += this.app.translate('finalPaper_fieldValidationFailDetailed')
                                        .replace('{{fields}}', validation.fields.map(t => `<li>${t.name}${t.details
                                            ? t.details.map(x => `<div><small>${x}</small></div>`).join('') : ''}</li>`).join(''));

                                if (validation.errors.length)
                                    err += validation.errors.map(t => `<p>${t.text}</p>`).join('');

                                this.app.alert.warning(err);
                            }
                        });
                    } else {
                        done();
                    }
                });
            });
        });

        return true;
    }

    cancel() {
        this.confirm('finalPaper_confirmCancel', () => {
            this.dataLossConfirmed = true;
            this.app.navigateToStart();
        });
    }

	validateFileSize() {
        const total = this.mainFile.size
            + this.appendixes.map(t => t.size).reduce((a, b) => a + b, 0)
            + this.permissions.map(t => t.size).reduce((a, b) => a + b, 0);

        return total <= this.totalFileMaxSize;
    }

    @HostListener('click', ['$event'])
    onClick(event) {
        if (event.target.id === authenticityLinkId) {
            event.preventDefault();
            event.stopPropagation();

            this.app.openDialog({
                content: `<span style="white-space: pre-line">${this.authenticityTextFull}</span>`,
                title: this.app.translate('finalPaper_authenticityTitle'),
                button: this.app.translate('finalPaper_authenticityButton')
            });
        }
    }

    init() {
        this.dataLossConfirmed = false;

        this.app.addLoading(forkJoin(
            this.loadStudentData(),
            this.loadMessages(),
            this.loadParameters(),
            this.loadClassifiers()
        )).subscribe(results => {
            this.isLoaded = true;

            // no programs found
            if (!this.isActive) {
                this.setMessages(this.messages, false);
                return;
            }

            if (this.requestedStudySubjectId) {
                let data = this.studentData.find(t => t.StudySubjectId.toLowerCase() === this.requestedStudySubjectId.toLocaleLowerCase());
                this.requestedStudySubjectId = undefined;

                if (data) {
                    this.selectedSubjectId = data.StudySubjectId;
                }

                this.programme = data;
            } else if (this.studentData.length) {
                this.programme = this.studentData[0];
                this.selectedSubjectId = this.programme.StudySubjectId;
            }

            if (this.selectedSubjectId) {
                this.setBySubject();
                this.loadItem().subscribe();
            } else {
				this.studentPickerOpened = false;
				this.dataValid = false;
				this.setMessages(this.messages, false);
            }
        });
    }

    parseAuthenticityText(text: string): string {
        return (text || '')
            .replace(/\[\[[^\[]+\]\]/, t => `<a href="#" id="${authenticityLinkId}">${t.slice(2, -2)}</a>`);
    }

    parsePlagiarismCheckText(text: string): string {
        const url = this.service.getTurnitinEulaUrl();

        return (text || '')
            .replace(/\[\[[^\[]+\]\]/, t => `<a href="${url}" target="_blank">${t.slice(2, -2)}</a>`);
    }

    parsePrintPdfReminderText(text: string): string {
        return (text || '').replace(/\[\[[^\[]+\]\]/, t =>
            `<a href="${this.affirmationPdfUrl}" target="_blank"><i class="fi fi-download"></i> ${t.slice(2, -2)}</a>`);
    }

    createFileWrapper(item?: FinalPaperFile): FileWrapper {
        const wrapper = new FileWrapper();

        if (item) {
            wrapper.item = item;
            wrapper.file = createFileStub(item.FileName);
            wrapper.url = this.service.getFileDownloadUrl(item.Id);
        }

        return wrapper;
    }

    onProgramChange(event, input) {
        this.canDeactivate().subscribe(res => {
            if (res) {
                this.selectedSubjectId = event;
                this.setBySubject();
                this.loadItem().subscribe();
            } else {
                input.value = this.selectedSubjectId;
            }
        });
    }

    onIsRestrictedChange() {
        if (this.item.IsRestricted) {
            this.item.IsPublic = false;
            this.app.alert.warning(this.restrictionWarnText);
        }
    }

    onIsPublicChange() {
       this.item.IsRestricted = false;
    }

    toTextValue(input) {
        const el = document.createElement('input');
        el.value = input.value || '';
        input.value = el.value;
    }

    validate(): ValidationResult {
        const result = new ValidationResult();

        for (let i in this.form.controls) {
            let c = this.form.controls[i];

            if (c.errors) {
                let err = '';
                const label = this.app.translate(`finalPaper_lbl${i[0].toUpperCase()}${i.substr(1)}`);

                if (c.errors.pattern && ['annotationLV', 'annotationEN', 'annotationOther'].indexOf(i) > -1) {
                    err = Utils.formatString(this.app.translate('fieldError_lengthMinMax'), [this.annotationMinLength, this.annotationMaxLength]);

                    result.errors.push({
                        text: `<div><strong>${label}</strong></div>${err}`,
                        fatal: true
                    });
                }

                result.fields.push({
                    name: label,
                    details: err ? [err] : []
                });
            }
        }

        if (!this.mainFile.item)
            result.errors.push({
                text: this.app.translate('finalPaper_mainFileEmpty')
            });

        if (!this.item.IsAuthentic)
            result.errors.push({
                text: this.app.translate('finalPaper_authenticityRequired')
            });

        if (!this.item.IsPlagiarismCheckAccepted)
            result.errors.push({
                text: this.app.translate('finalPaper_plagiarismCheckRequired')
            });

        if (!this.item.IsRestricted && !this.item.IsPublic)
            result.errors.push({
                text: this.app.translate('finalPaper_accessTypeRequired')
            });

        if (!this.validateFileSize())
            result.errors.push({
                text: this.app.translate('finalPaper_fileMaxSizeExceeded'),
                fatal: true
            });

        return result;
    }

    validateAnnotation(value: string): boolean {
        if (!value) return true;
        return value.length >= this.annotationMinLength && value.length <= this.annotationMaxLength;
    }

    findLanguageByCode(code: string): string {
        const lang = this.languages.find(t => t.code === code) || this.otherLanguages.find(t => t.code === code);
        return lang ? lang.name : '';
    }

    yesno(value: boolean): string {
        return (!value && value !== false) ? '' : this.app.translate('value_' + value);
    }

    onToggleChangeHistory() {
        this.showChangeHistory = !this.showChangeHistory;

        if (this.showChangeHistory && !this.changeHistory) {
            this.app.addLoading(this.service.getChangeHistory(this.item.Id)).subscribe(history => {
                this.changeHistory = history;
            });
        }
    }

    onToggleFileChangeHistory() {
        this.showFileChangeHistory = !this.showFileChangeHistory;

        if (this.showFileChangeHistory && !this.fileChangeHistory) {
            this.app.addLoading(this.service.getFileChangeHistory(this.item.Id)).subscribe(history => {
                this.fileChangeHistory = history;
            });
        }
    }

    getHistoryFileUrl(historyId: number): string {
        return this.service.getHistoryFileDownloadUrl(historyId);
    }

    getAnnotationValidationPattern(value: string): string {
        if (!value || (value.length >= this.annotationMinLength && value.length <= this.annotationMaxLength)) {
            return '';
        }

        return '$^';
    }

    toggle(row: any) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: any): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    private loadItem() {
        if (!this.selectedSubjectId)
            return of(false);

        this.showChangeHistory = false;
        this.showFileChangeHistory = false;
        this.changeHistory = undefined;
        this.fileChangeHistory = undefined;

        return this.app.addLoading(this.service.getBySubject(this.selectedSubjectId, this.impersonatedEmail)).pipe(map(data => {
            if (data) {
                this.item = data;
            } else {
                this.item = new FinalPaper();
                this.item.StudySubjectId = this.selectedSubjectId;
                this.item.Email = this.impersonatedEmail;
            }

            this.setWarning();

            this.programme = this.studentData.find(t => t.StudySubjectId === this.selectedSubjectId);

            this.mainFile = new FileWrapper();

			this.appendixes = [];
			this.permissions = [];
            this.affirmationFile = undefined;

            this.itemOriginal = JSON.stringify(this.item);
            this.isSubmitted = this.item.Status !== FinalPaperStatus.Draft;
            this.isReadonly = !this.isAdmin && this.isSubmitted;

            const examType = this.isEn ? this.programme.FinalExamTypeEN : this.programme.FinalExamType;
            const defenseDate = Utils.ensureDate(this.programme.DefenseDate);

            this.baseInfoOutdated = this.item.Language !== this.programme.FinalWorkLanguage
                || this.item.LanguageOther !== this.programme.FinalWorkLanguageOther
                || this.item.TitleEN !== this.programme.FinalWorkNameEN
                || this.item.TitleLV !== this.programme.FinalWorkName
                || this.item.TitleOther !== this.programme.FinalWorkNameOther
                || this.item.ThesisType !== examType
                || this.item.DefenseDate !== defenseDate;

            this.item.Language = this.programme.FinalWorkLanguage;
            this.item.LanguageOther = this.programme.FinalWorkLanguageOther;
            this.item.TitleLV = this.programme.FinalWorkName;
            this.item.TitleEN = this.programme.FinalWorkNameEN;
            this.item.TitleOther = this.programme.FinalWorkNameOther;
            this.item.ThesisType = examType;
            this.item.DefenseDate = defenseDate;

            if (!this.isReadonly) {
                if (!this.item.DefenseDate) {
                    this.dataValid = false;
                    return;
                }

                if (this.item.AuthenticityText !== this.authenticityTextFull)
                    this.item.IsAuthentic = false;

                if (this.item.RestrictionText !== this.restrictionText)
                    this.item.IsRestricted = false;

                if (this.item.PublicText !== this.publicText)
                    this.item.IsPublic = false;

                this.item.UploadDueDate = new Date(this.item.DefenseDate.setDate(this.item.DefenseDate.getDate() - this.uploadDueDays));
                this.item.UploadDueDate.setHours(0, 0, 0, 0);

                if (!this.programme.CanEdit) {
					if (!this.item.Language ||
						!this.item.TitleLV || !this.item.TitleEN) {
						this.dataValid = false;
						return;
					}
                }
            } else {
                this.item.UploadDueDate = Utils.ensureDate(this.item.UploadDueDate);

                this.authenticityTextFull = this.item.AuthenticityText;
                this.restrictionText = this.item.RestrictionText;
                this.publicText = this.item.PublicText;
            }

            this.item.Files.forEach(t => {
                let file = this.createFileWrapper(t);

                switch (t.Type) {
                    case FinalPaperFileType.Affirmation: this.affirmationFile = t; break;
					case FinalPaperFileType.Appendix: this.appendixes.push(file); break;
					case FinalPaperFileType.Permission: this.permissions.push(file); break;
                    case FinalPaperFileType.FinalPaper: this.mainFile = file; break;
                }
            });

            return true;
        }));
    }

    private loadParameters() {
        if (this.paramsLoaded)
            return of(true);

        return this.app.addLoading(this.paramService.getValues()).pipe(map(values => {
            this.paramsLoaded = true;

            this.mainFileMaxSize = this.paramService.findValue(values, parameterCodes.mainFileMaxSize, t => +t, 0);
			this.appendixMaxSize = this.paramService.findValue(values, parameterCodes.appendixMaxSize, t => +t, 0);
			this.permissionMaxSize = this.paramService.findValue(values, parameterCodes.permissionMaxSize, t => +t, 0);
            this.totalFileMaxSize = this.paramService.findValue(values, parameterCodes.totalFileMaxSize, t => +t, 0);

            this.mainFileExtensions = this.paramService.findValue(values, parameterCodes.mainFileExtensions,
                t => '.' + t.split(',').join(',.'), '');
            this.appendixExtensions = this.paramService.findValue(values, parameterCodes.appendixExtensions,
				t => '.' + t.split(',').join(',.'), '');
			this.permissionExtensions = this.paramService.findValue(values, parameterCodes.permissionExtensions,
				t => '.' + t.split(',').join(',.'), '');

            this.uploadDueDays = this.paramService.findValue(values, parameterCodes.uploadDueDays, t => +t, 0);

            return true;
        }));
    }

    private loadMessages() {
        if (this.messages)
            return of(true);

        return this.app.addLoading(this.messageService.getByCodes([
            messageCodes.authenticity,
            messageCodes.authenticityFull,
            messageCodes.plagiarismCheck,
            messageCodes.restriction,
            messageCodes.restrictionWarn,
            messageCodes.public,
            messageCodes.publicYes,
            messageCodes.publicNo,
            messageCodes.alert,
            messageCodes.warnDraft,
            messageCodes.warnSubmitted,
            messageCodes.warnFailed,
            messageCodes.warnUploadedDspace,
            messageCodes.info,
            messageCodes.errorWithData,
            messageCodes.outdated
        ])).pipe(map(data => {
            this.messages = data;
            this.infoOutdated = this.getMessage(messageCodes.outdated);
            return true;
        }));
    }

    /**
     * Translate and set messages.
     * @param data
     * @param programIsEn Program language is english (affects only affirmations).
     */
    private setMessages(data: Message[], programIsEn: boolean) {
        const values = Utils.arrayToDictionary(data, item => item.Code);
        const getText = (key: string, english: boolean) =>
            values[key] ? this.messageService.getTextByLanguage(values[key], english ? 'en' : 'lv') : `[[${key}]]`;

		this.alert = getText(messageCodes.alert, this.isEn);
        this.info = getText(messageCodes.info, this.isEn);
        this.errorWithData = getText(messageCodes.errorWithData, this.isEn);

        this.authenticityText = this.parseAuthenticityText(getText(messageCodes.authenticity, programIsEn));
        this.authenticityTextFull = getText(messageCodes.authenticityFull, programIsEn);
        this.plagiarismCheckText = this.parsePlagiarismCheckText(getText(messageCodes.plagiarismCheck, programIsEn));
        this.restrictionText = getText(messageCodes.restriction, programIsEn);
        this.restrictionWarnText = getText(messageCodes.restrictionWarn, programIsEn);
        this.publicText = getText(messageCodes.public, programIsEn);
        this.publicTextYes = getText(messageCodes.publicYes, programIsEn);
    }

    private setWarning() {
        let code: string;

        switch (this.item.Status) {
            case FinalPaperStatus.Draft: code = messageCodes.warnDraft; break;
            case FinalPaperStatus.Submitted: code = messageCodes.warnSubmitted; break;
            case FinalPaperStatus.Failed: code = messageCodes.warnFailed; break;
            case FinalPaperStatus.UploadedDspace: code = messageCodes.warnUploadedDspace; break;
        }

        this.warning = this.getMessage(code);
    }

    private getMessage(code: string): string {
        const message = this.messages.find(t => t.Code === code);
        return message ? this.messageService.getTextByLanguage(message, this.isEn ? 'en' : 'lv') : null;
    }

    private loadClassifiers() {
        if (this.classifiersLoaded)
            return of(true);

        const types = {
            language: 'FinalPaperLanguage',
            languageOther: 'FinalPaperLanguageOther'
        };

        return this.app.addLoading(this.classifierService.get(Object.keys(types).map(t => types[t]).join(','))).pipe(map(data => {
            this.classifiersLoaded = true;

            const getValues = (type: string) => data.filter(t => t.Type === type).map(t => {
                return {
                    code: t.Code.split('.').pop(),
                    name: this.isEn ? t.ValueEN : t.Value
                }
            });

            this.languages = getValues(types.language);
            this.otherLanguages = [{ code: null, name: '-' }].concat(getValues(types.languageOther));

            return true;
        }));
    }

    private loadStudentData() {
        return this.app.addLoading(this.service.getStudentData(this.impersonatedEmail)).pipe(map(data => {
            this.studentData = data;
            this.isActive = data.length > 0;

            this.studyPrograms = data.map(t => {
                return {
                    studySubjectId: t.StudySubjectId,
                    name: this.isEn ? t.ProgramNameEN : t.ProgramName
                };
            });
        }));
    }

    private confirm(text: string, callback: () => any) {
        this.app.confirm({
            text: this.app.translate(text),
            okText: this.app.translate('finalPaper_confirmBtnOk'),
            cancelText: this.app.translate('finalPaper_confirmBtnCancel')
        }, res => res && callback());
    }

    private setBySubject() {
        this.item.StudySubjectId = this.selectedSubjectId;
        const program = this.studentData.find(t => t.StudySubjectId === this.selectedSubjectId);

        this.canEditStudentData = program.CanEdit;
        this.canEditOtherLang = program.CanEditOtherLanguage;

        this.setMessages(this.messages, program.ProgramLanguage === 'EN');
    }

    private itemChanged(): boolean {
        const orig: FinalPaper = JSON.parse(this.itemOriginal);

        return this.removedFiles.length > 0
            || !!this.mainFile.file?.size
            || this.appendixes.some(t => !!t.file?.size)
            || this.permissions.some(t => !!t.file?.size)
            || orig.AnnotationEN != this.item.AnnotationEN
            || orig.AnnotationLV != this.item.AnnotationLV
            || orig.AnnotationOther != this.item.AnnotationOther
            || !!orig.IsAuthentic != !!this.item.IsAuthentic
            || !!orig.IsPublic != !!this.item.IsPublic
            || !!orig.IsRestricted != !!this.item.IsRestricted
            || !!orig.IsPlagiarismCheckAccepted != !!this.item.IsPlagiarismCheckAccepted
            || orig.Language != this.item.Language
            || orig.LanguageOther != this.item.LanguageOther
            || orig.TagsEN != this.item.TagsEN
            || orig.TagsLV != this.item.TagsLV
            || orig.TagsOther != this.item.TagsOther
            || orig.TitleEN != this.item.TitleEN
            || orig.TitleLV != this.item.TitleLV
            || orig.TitleOther != this.item.TitleOther;
    }
}
