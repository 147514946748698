import { Component, Input } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { ITableColumn } from '../../../shared/table/table.component';
import { IDoctorStudent } from '../../../models/Doctor';
import { DoctorService } from '../../../services/doctor.service';
import { AppDatePipe } from '../../../pipes/date.pipe';
import { store } from '../store';

const cache: {
    students: IRow[],
    user: string
} = {
    students: undefined,
    user: undefined
};

interface IRow extends IDoctorStudent {
    fullName: string;
    courseFullName: string;
    studyYearDisplay: string;
    dateFromToDisplay: string;
}

@Component({
    selector: 'app-doctor-dashboard-students',
    templateUrl: './doctor-dashboard-students.component.html'
})
export class DoctorDashboardStudentsComponent {
    constructor(
        private app: AppService,
        private service: DoctorService,
        private appDate: AppDatePipe
    ) { }

    @Input() set academicYearId(value: string) {
        this._academicYearId = value;
        this.init();
    }

    readonly columns: ITableColumn[] = [
        { property: 'fullName', label: 'doctorDashboard_students_lblStudent', sorts: true },
        { property: 'SpecialityName', label: 'doctorDashboard_students_lblSpeciality', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'StudyYear', label: 'doctorDashboard_students_lblStudyYear', sorts: true, type: 'number', cssClass: 'hidden-sm-down' },
        { property: 'courseFullName', label: 'doctorDashboard_students_lblCourse', sorts: true },
        { property: 'DateFrom', label: 'doctorDashboard_students_lblDateFromTo', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { width: '1px', property: 'DateFrom', label: 'doctorDashboard_students_lblGrades' }
    ];

    rows: IRow[] = [];
    specialityOptions: { text: string, value: string }[] = [];

    filterSpeciality: any;

    readonly filterValues: {
        search?: string,
        specialityId?: string[],
        hideGraded?: boolean
    } = {
            specialityId: [],
            hideGraded: true
        };

    get academicYearId(): string {
        return this._academicYearId;
    }

    private allRows: IRow[] = [];
    private filterInterval: any;
    private _academicYearId: string;

    getPlanSummaryUrl(row: IRow): string {
        return `/${this.app.currentLanguage}/plan-summary?StudentId=${row.Id}`;
    }

    getGradesUrl(row: IRow) {
        return `/${this.app.currentLanguage}/add-grades?StudentId=${row.Id}&SubjectId=${row.CourseId}`;
    }

    filter(delay: boolean = false) {
        if (delay) {
            if (this.filterInterval) {
                clearInterval(this.filterInterval);
            }

            this.filterInterval = setTimeout(() => {
                this.filter();
            }, 300);
        } else {
            const search = this.filterValues.search?.toLowerCase();
            const specId = this.filterValues.specialityId || [];
            const hideGraded = this.filterValues.hideGraded == true;

            this.rows = this.allRows.filter(t => {
                if (hideGraded && t.IsDoctorGraded) return false;
                if (specId.length && !specId.includes(t.SpecialityId)) return false;
                if (search
                    && !t.fullName.toLowerCase().includes(search)
                    && !t.courseFullName.toLowerCase().includes(search)
                    && !t.Speciality.toLowerCase().includes(search)
                    && !t.studyYearDisplay.includes(search)
                    && !t.dateFromToDisplay.includes(search)
                ) return false;

                return true;
            });
        }
    }

    private init() {
        const process = (data: IRow[]) => {
            const byYear = this.filterByAcademicYear(data);

            this.specialityOptions = byYear.reduce((all, n) => {
                if (!all.some(t => t.value == n.SpecialityId)) {
                    all.push({ text: n.Speciality, value: n.SpecialityId });
                }
                return all;
            }, []);

            this.allRows = byYear;
            this.filter();
        };
        
        if (cache.students && cache.user == store.getPerson()?.Upn) {
            process(cache.students);
        } else {
            cache.students = [];
            cache.user = store.getPerson()?.Upn;

            this.app.addLoading(this.service.getStudents(store.getPerson()?.Upn)).subscribe(data => {
                const rows = data.map(t => {
                    return {
                        ...t,
                        fullName: `${t.Name} ${t.Surname}`,
                        courseFullName: `${t.CourseName} (${t.CourseCode})`,
                        studyYearDisplay: `${t.StudyYear}.`,
                        dateFromToDisplay: `${this.appDate.transform(t.DateFrom)}. - ${this.appDate.transform(t.DateTo)}.`
                    }
                });

                cache.students = rows;

                process(rows);
            });
        }
    }

    private filterByAcademicYear(data: IRow[]) {
        return data.filter(t => t.AcademicYearId == this.academicYearId);
    }
}
