import { ClassifierService } from './../../services/classifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';
import { NgForm } from '@angular/forms';
import { MessageService } from '../../services/message.service';
import { Message } from '../../models/Message';

export const simpleTextEditorCodes: string[] = [
    'FINAL_PAPER_AUTHENTICITY',
    'FINAL_PAPER_AUTHENTICITY_FULL',
    'FINAL_PAPER_RESTRICTION',
    'FINAL_PAPER_RESTRICTION_WARN',
    'FINAL_PAPER_PUBLIC'
];

@Component({
  selector: 'app-message-edit',
    templateUrl: './message-edit.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessageEditComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: MessageService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private classifier: ClassifierService
    ) { }

    item: Message = new Message();
    title: string;
    userRights: { Code: string, Text: string }[] = [];
    isNew: boolean;
    submitted: boolean;

    readonly editorConfig = {
        convert_urls: false,
        forced_root_block: '',
        entity_encoding: 'raw',
        plugins: 'link textcolor table code lists',
        toolbar:
            'undo redo | formatselect | bold italic forecolor backcolor'
            + ' | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            + ' | table link | code'
    };


    @ViewChild('form', { static: false }) private form: NgForm;

    ngOnInit() {
        this.app.addLoading(this.classifier.getUDR(this.translate.currentLang)).subscribe(data => {
            this.userRights = data.filter(r => r.Code.startsWith("UDR"));
        });

        this.route.params.subscribe(para => {
            const id = para['id'];

            if (!id) {
                this.isNew = true;
            } else {
                this.app.addLoading(this.service.getById(+id)).subscribe(data => {
                    this.item = data;
                    this.title = data.Title;
                });
            }
        });
    }

    isRichEditor(): boolean {
        return simpleTextEditorCodes.indexOf(this.item.Code) === -1;
    }

    save() {
        if (!this.form.valid) {
            this.submitted = true;
            this.app.alert.warning(this.app.translate('invalidFormWarning'));
            return;
        }

        const call = this.item.Id
            ? this.service.update(this.item.Id, this.item)
            : this.service.add(this.item);

        this.app.addLoading(call).subscribe(() => {
            this.app.navigateByUrl('/messages');
        });
    }

    getTextForUDR(code: string) {
        let arr = this.userRights.filter(u => u.Code == code);
        if(arr.length != 0){
            return arr[0].Text
        }else {
            return '';
        }
    }
}
