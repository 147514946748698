import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IFinalPaperFeedbackEditModel, IFinalPaperReview, IFinalPaperReviewList, IFinalPaperYear } from '../models/FinalPaperReview';
import { AuthService } from './auth.service';
import { environment as ENV } from '../../environments/environment';

/**
 * Final paper review service
 */
@Injectable({ providedIn: "root" })
export class FinalPaperReviewService {
    constructor(private http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/finalPaperReviews` }
    get(yearId: string, upn?: string): Observable<IFinalPaperReviewList> {
        const url = this.apiUrl;
        const params = upn ? { yearId, upn } : { yearId };
        return this.http.get<IFinalPaperReviewList>(url, { params });
    }

    getById(id: number, upn?: string): Observable<IFinalPaperReview> {
        const url = `${this.apiUrl}/${id}`;
        const params = upn ? { upn } : {};
        return this.http.get<IFinalPaperReview>(url, { params });
    }

    getYears(): Observable<IFinalPaperYear[]> {
        const url = `${this.apiUrl}/years`;
        return this.http.get<IFinalPaperYear[]>(url);
    }

    saveFeedback(id: number, data: IFinalPaperFeedbackEditModel, file?: File, upn?: string): Observable<number> {
        const url = `${this.apiUrl}/${id}/feedbacks`;
        const form = new FormData();
        const params = upn ? { upn } : {};

        if (file) {
            form.append('file', file, file.name);
        }

        form.append('feedback', encodeURIComponent(JSON.stringify(data)));

        return this.http.put<number>(url, form, { params });
    }

    approve(id: number, upn?: string): Observable<any> {
        const url = `${this.apiUrl}/${id}/approve`;
        const params = upn ? { upn } : {};
        return this.http.post(url, null, { params });
    }

    reject(id: number, upn?: string): Observable<any> {
        const url = `${this.apiUrl}/${id}/reject`;
        const params = upn ? { upn } : {};
        return this.http.post(url, null, { params });
    }

    getFileDownloadUrl(fileId: number, upn?: string): string {
        return `${this.apiUrl}/files/${fileId}?upn=${upn}&SessionKey=${this.auth.sessionKey}`;
    }

    getFeedbackFileDownloadUrl(feedbackId: number, upn?: string): string {
        return `${this.apiUrl}/feedbacks/${feedbackId}/file?upn=${upn}&SessionKey=${this.auth.sessionKey}`;
    }

    getReportUrl(id: number, upn?: string): Observable<string> {
        const url = `${this.apiUrl}/${id}/reportUrl`;
        const params = upn ? { upn } : {};
        return this.http.get<string>(url, { params });
    }
}
