import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Address, AddressFilter, IAddressUnit, ICountry } from '../../models/Address';

import { AddressService } from '../../services/address.service';
import { AppService } from '../../services/app.service';

type UnitType = 'city' | 'district' | 'parish' | 'village' | 'street' | 'house' | 'apartment';

class AddressUnits {
    constructor(public type: UnitType) { }

    items: IAddressUnit[] = [];
    _items: IAddressUnit[] = [];

    setItems(items: IAddressUnit[]) {
        this.items = [...items];
        this._items = items;
    }

    readonly filterFn = (item: IAddressUnit, value: string): boolean => {
        return !value || item.Value?.toLowerCase().includes(value.toLowerCase());
    }
}

@Component({
    templateUrl: './address.component.html'
})
export class AddressComponent implements OnInit {
    constructor(
        private activeModal: NgbActiveModal,
        private app: AppService,
        private service: AddressService
    ) {
    }

    countries: ICountry[] = [];

    cities = new AddressUnits('city');
    districts = new AddressUnits('district');
    parishes = new AddressUnits('parish');
    villages = new AddressUnits('village');
    streets = new AddressUnits('street');
    houses = new AddressUnits('house');
    apartments = new AddressUnits('apartment');

    country: ICountry;
    model = new Address();

    readonly countryFilterFn = (item: ICountry, value: string): boolean => {
        return !value || item.Name?.toLowerCase().includes(value.toLowerCase());
    }

    readonly addressUnitDisplayFn = (item: IAddressUnit): string => {
        return item ? (item.Value || '-') : '';
    }

    get isValid(): boolean {
        return this.model && (this.model.IsForeign || !!this.model.Id);
    }

    private timeout: any;
    private prevModel: Address;
    private _countries: ICountry[] = [];
    private static countryCache: ICountry[];

    ngOnInit() {
        this.loadCountries();
    }

    ok() {
        if (this.model.IsForeign) {
            const model = this.model;
            
            model.FullText = [
                model.Street && model.Street.Value,
                model.Apartment && model.Apartment.Value,
                model.City && model.City.Value,
                model.Region && model.Region.Value,
                model.PostCode,
                model.Country && model.Country.Value
            ].filter(t => !!t).join(', ');
        }

        this.activeModal.close(this.model);
    }

    cancel() {
        this.activeModal.dismiss();
    }

    onUnitChange(unitType: UnitType) {
        const model = this.model;

        this.prevModel = new Address();
        Object.assign(this.prevModel, model);

        this.delayAndLoad();
    }

    onCountryChange() {
        this.model = new Address();
        this.model.IsForeign = this.country.Code !== 'LV';
        this.model.Country = { Value: this.country.Name };

        if (!this.model.IsForeign) {
            this.delayAndLoad();
        } else {
            this.model.Region = {};
            this.model.City = {};
            this.model.Street = {};
            this.model.Apartment = {};
        }
    }

    filterCountries(value: string) {
        if (!value) {
            this.countries = this._countries;
        } else {
            const val = value.toLowerCase();
            this.countries = this._countries.filter(t => t.Name.toLowerCase().indexOf(val) > -1);
        }
    }

    private load() {
        const filter = new AddressFilter();
        const model = this.model;

        filter.ApartmentId = this.getUnitId(model.Apartment);
        filter.CityId = this.getUnitId(model.City);
        filter.DistrictId = this.getUnitId(model.District);
        filter.HouseId = this.getUnitId(model.House);
        filter.ParishId = this.getUnitId(model.Parish);
        filter.StreetId = this.getUnitId(model.Street);
        filter.VillageId = this.getUnitId(model.Village);

        this.app.addLoading(this.service.get(filter))
            .subscribe(data => {
                data.Cities && this.cities.setItems(data.Cities);
                data.Districts && this.districts.setItems(data.Districts);
                data.Parishes && this.parishes.setItems(data.Parishes);
                data.Villages && this.villages.setItems(data.Villages);
                data.Streets && this.streets.setItems(data.Streets);
                data.Apartments && this.apartments.setItems(data.Apartments);
                data.Houses && this.houses.setItems(data.Houses);

                const prev = this.prevModel || new Address();

                const findCurrentOrPrev = (currentId: number, prev: IAddressUnit, data: IAddressUnit[]) => {
                    return this.findUnit(currentId, data) || (prev ? this.findUnit(prev.Id, data) : undefined);
                };

                model.City = findCurrentOrPrev(filter.CityId, prev.City, this.cities.items);
                model.District = findCurrentOrPrev(filter.DistrictId, prev.District, this.districts.items);
                model.Parish = findCurrentOrPrev(filter.ParishId, prev.Parish, this.parishes.items);
                model.Village = findCurrentOrPrev(filter.VillageId, prev.Village, this.villages.items);
                model.Street = findCurrentOrPrev(filter.StreetId, prev.Street, this.streets.items);
                model.House = findCurrentOrPrev(filter.HouseId, prev.House, this.houses.items);
                model.Apartment = findCurrentOrPrev(filter.ApartmentId, prev.Apartment, this.apartments.items);

                model.Id = data.Id;
                model.PostCode = (data.PostCodes || []).length && data.PostCodes[0];
                model.FullText = data.FullText;
            });
    }

    private delayAndLoad() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => this.load(), 500);
    }

    private loadCountries() {
        if (AddressComponent.countryCache) {
            this.countries = this._countries = AddressComponent.countryCache;
            this.setDefaultCountry();
        } else {
            this.app.addLoading(this.service.getCountries())
                .subscribe(data => {
                    this.countries = this._countries = AddressComponent.countryCache = data;
                    this.setDefaultCountry();
                });
        }
    }

    private findUnit(unitId: number, units: IAddressUnit[]): IAddressUnit {
        return !unitId ? undefined : units.find(t => t.Id === unitId);
    }

    private setDefaultCountry() {
        this.country = this.countries.find(t => t.Code === 'LV');
        this.onCountryChange();
    }

    private getUnitId(unit: IAddressUnit): number {
        return unit && unit.Id;
    }
}
