<div class="base-info" *ngIf="student">
    <ngb-accordion #acc="ngbAccordion">
        <ngb-panel [title]="'application_personData' | translate">
            <ng-template ngbPanelContent>
                <ul class="accordion-list">
                    <li>
                        <ng-container *ngIf="!isSkills">
                            <div class="row">
                                <div class="data-value col-sm">
                                    <label>{{'application_lblFullName' | translate}}</label>
                                    <div>{{student.FullName}}</div>
                                </div>

                                <div class="data-value col-sm">
                                    <label>{{'application_lblCertNumber' | translate}}</label>
                                    <div>{{student.CertNumber}}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="data-value col-sm">
                                    <label>{{'application_lblStudyYear' | translate}}</label>
                                    <div>{{student.StudyYear}}</div>
                                </div>

                                <div class="data-value col-sm">
                                    <label>{{'application_lblStudyLevel' | translate}}</label>
                                    <div>{{student.StudyLevel}}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="data-value col-sm">
                                    <label>{{'application_lblStudentStatus' | translate}}</label>
                                    <div>{{student.StudentStatus}}</div>
                                </div>

                                <div class="data-value col-sm">
                                    <label>{{'application_lblEmail' | translate}}</label>
                                    <div>{{student.Email}}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="data-value col-sm">
                                    <label>{{'application_lblContactPhone' | translate}}</label>
                                    <div>{{student.ContactPhone}}</div>
                                </div>

                                <div class="data-value col-sm">
                                    <label>{{'application_lblDeclaredAddress' | translate}}</label>
                                    <div>{{student.DeclaredAddress}}</div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isSkills">
                            <div class="row">
                                <div class="data-value col-sm">
                                    <label>{{'application_lblFullName' | translate}}</label>
                                    <div>{{student.FullName}}</div>
                                </div>

                                <div class="data-value col-sm">
                                    <label>{{'application_lblEmail' | translate}}</label>
                                    <div class="col-6">{{student.Email}}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="data-value col-sm">
                                    <label>{{'application_lblContactPhone' | translate}}</label>
                                    <div>{{student.ContactPhone}}</div>
                                </div>
                                <div class="data-value col-sm">
                                    <label>{{'application_lblDeclaredAddress' | translate}}</label>
                                    <div>{{student.DeclaredAddress}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </li>
                </ul>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
