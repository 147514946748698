import { IPictureOutput } from '../shared/picture/picture.component';
import { ApplicationDataSourceResultType, ApplicationFormFieldType } from './ApplicationConfig';

export enum ApplicationKind {
    Application = 'Application',
    Reference = 'Reference'
}

export enum ApplicationType {
    AcademicLeave = 'AcademicLeave',
    AcademicLeaveDoctor = 'AcademicLeaveDoctor',
    Exmatriculation = 'Exmatriculation',
    RepeatCourse = 'RepeatCourse',
    CourseAlignment = 'CourseAlignment',
    Discount = 'Discount',
    Repayment = 'Repayment',
    Grades = 'Grades',
    Status = 'Status',
    Applicant = 'Applicant',
    MatriculationStatus = 'MatriculationStatus',
    Skills = 'Skills',
    JustifiedAbsence = 'JustifiedAbsence',
    Complaint = 'Complaint',
    AppealComplaint = 'AppealComplaint',
    ScholarshipComplaint = 'ScholarshipComplaint',
    FreeForm = 'FreeForm',
    PersonData = 'PersonData',
    PersonPhoto = 'PersonPhoto',
    PaymentTermExtension = 'PaymentTermExtension',
    AcademicReference = 'AcademicReference'
}

export enum DvsStatus {
    Deleted,
    Approved,
    Rejected
}

export class Student {
    FullName: string;
    CertNumber: string;
    StudyProgramme: string;
    StudyProgrammeId: string;
    StudyYear: number;
    StudyLevel: string;
    StudentStatus: string;
    StudentStatusType: string;
    StudentId: string;
    Email: string;
    ContactPhone: string;
    DeclaredAddress: string;
    FirstSemester: number;
    CurrentSemester: number;
    FacultyId: string;
    OwnerEmail: string;
    OwnerFullName: string;
    Dean: string;
    Addressee: string;
    IsValid?: boolean;
    IsForeign: boolean;
}

export class Application {
    Type: ApplicationType | string;
    Kind: ApplicationKind;
    Addressee: string;
    AddresseeType: string;
    RefLanguage: string;
    TypeOfReceipt: string;
    NumberOfCopies: number;
    ReceiptAddress: string;
    Comments: string;
    StudentId: string;
    StudyProgramme: string;
    StudyProgrammeId: string;
    Semester: number;
    Content: string;
    Topic: string;
    TopicId: string;
    Reason: string;
    ReasonId: string;
    DateFrom: Date;
    DateTo: Date;
    SkillIds: string[] = [];
    DocumentNumber?: string;
    AbsenceType?: string;
    AbsenceTypeId?: string;
    ClosingSheet?: string;
    ProlongsPeriod?: boolean;
    StudyCourse?: string;
    AppealCounter?: number;
    AcademicYear?: string;
    Scholarship?: string;
    ScholarshipId?: string;
    BankAccount?: BankAccount;
    CourseRound?: string;
    CourseRoundId?: string;
    ContractNumber?: string;
    PersonData: PersonDataField[] = [];
    ExtraCourses: ExtraCourseInfo[] = [];
    LatePayments?: string;
    LatePaymentSchedule: PaymentScheduleEntry[] = [];
    Photo: IPictureOutput;
    ConfigId?: number;
    CustomFields: { [key: string]: any };
}

export class MyApplication {
    Id: number;
    Type: ApplicationType;
    Kind: ApplicationKind;
    Created: Date;
    Registered: Date;
    DecisionSigned: Date;
    FileId: number;
    DvsStatus?: DvsStatus;
    DvsRegDate?: Date;
}

export class ApplicationResult {
    ApplicationId: number;
    DvsResult: DvsResult;
}

export class DvsResult {
    Success: boolean;
    Error: string;
}

export class AbsenceInfo {
    IsResident: boolean;
    Absence: string;
    AvailableTypes: string[] = [];
}

export class ExtraCourseInfo {
    Code: string;
    Name: string;
    CreditPoints?: number;
    Semester?: number;
}

export class BankAccount {
    isForeign: boolean;
    holder: string;
    personCode: string;
    number: string;
    swift: string;
    bank: string;
}

export class PersonDataField {
    Type: string;
    Code: string;
    Name: string;
    Value?: string;
    DisplayValue?: string;
}

export class PaymentScheduleEntry {
    Amount: number;
    Date: Date;
}

export class ComplaintCourse {
    Code: string;
    Name: string;
}

export class Person {
    Id: string;
    Name: string;
    Surname: string;
    Phone: string;
    Email: string;
    Address: string;
    CertNumber: string;
}

export class PersonPhoto {
    Photo: string;
}

export interface IApplicationCustomForm {
    Id: number;
    NameLV: string;
    NameEN: string;
    DataSources: IApplicationDataSource[];
    Fields: IApplicationCustomField[];
    FileFields: IApplicationCustomFileField[];
}

export interface IApplicationDataSource {
    Name: string;
    Data: any[];
    Type: ApplicationDataSourceResultType;
}

export interface IApplicationCustomField {
    Name: string;
    NameLV: string;
    NameEN: string;
    Type: ApplicationFormFieldType;
    Row: string;
    Size: number;
    Required: boolean;
    Editable: boolean;
    Source: IApplicationFieldSource;
}

export interface IApplicationCustomFileField {
    Name: string;
    Group: string;
    NameLV: string;
    NameEN: string;
    Row: string;
    Extensions: string;
    MaxSize: number;
    MaxFileCount: number;
    Required: boolean;
}

export interface IApplicationFieldSource {
    Name: string;
    ValueField: string;
    DisplayField: string;
}
