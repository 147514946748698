import { Component, OnInit } from '@angular/core';

import { PasswordService } from '../../../services/password.service';
import { AppService } from '../../../services/app.service';
import { ActivatedRoute } from '@angular/router';

import { UserCrypto } from '../../../models/UserInfo';
import { ResetResponse } from '../../../models/Password';
import { IOAuthToken } from '../../../models/Auth';
import { AES, mode, pad, enc } from 'crypto-ts';
import { messageCodes, languageCacheKey } from './shared';
import { MessageService } from '../../../services/message.service';
import { Utils } from '../../../core/Utils';
import { AuthService } from '../../../services/auth.service';

const tokenStorageKey = 'rsu_eservices.oauth.token';

@Component({
    selector: 'app-password-reset-employee',
    templateUrl: './reset.component.html',
    styleUrls: ['../password.component.scss']
})
export class EmployeePasswordResetComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private service: PasswordService,
        private route: ActivatedRoute,
        private messageService: MessageService
    ) { }

    canReset: boolean;
    successText: string;
    newPasswordValue: string;
    newPasswordRepeatValue: string;
    resetResponse: ResetResponse;
    responseError: string;
    responseSuccess: boolean;
    userInfo: string;

    get isValidPassword() {
        return Utils.validatePassword(this.newPasswordValue, {
            minLength: this.app.env.minPasswordLength,
            maxLength: this.app.env.maxPasswordLength,
            digit: true,
            lowercase: true,
            special: true,
            uppercase: true
        });
    }

    private token: IOAuthToken;
    private cryptoKey: string;
    private crypto: UserCrypto;

    private errorTemplate: string;
    private username: string;
    private email: string;

    ngOnInit() {
        const savedToken = sessionStorage.getItem(tokenStorageKey);
        sessionStorage.removeItem(tokenStorageKey);

        if (savedToken) {
            this.init(<IOAuthToken>JSON.parse(savedToken));
        } else {
            const hash = this.route.snapshot.fragment;

            if (!hash) {
                this.app.redirect('/password/request-employee');
                return;
            }

            const token = <IOAuthToken>{};

            hash.split('&').forEach(t => {
                const [key, value] = t.split('=');
                token[key] = decodeURIComponent(value);
            });

            sessionStorage.setItem(tokenStorageKey, JSON.stringify(token));

            this.app.addLoading(this.auth.getOAuthConfiguration()).subscribe(oauth => {
                const redirectUrl = encodeURIComponent(window.location.href.replace(window.location.hash, ''));
                location.assign(`${oauth.AuthServerUri}${oauth.SignoutPath}?redirectUrl=${redirectUrl}`);
            });
        }
    }

    showError(message: string) {
        this.app.showError(this.app.translate(message), this.app.translate('password_notresettitle'));
    }

    submit() {
        if (!this.isValidPassword) {
            this.showError('password_passwordmissingsymbols');
        } else if (this.containsName(this.newPasswordValue, this.username) || this.containsName(this.newPasswordValue, this.email)) {
            this.showError('password_containsname');
        } else if (this.newPasswordValue != this.newPasswordRepeatValue) {
            this.showError('password_dontmatch');
        } else {
            const key = enc.Utf8.parse(this.crypto.key);
            const iv = enc.Utf8.parse(this.crypto.iv);
            const cryptedObjNew = AES.encrypt(this.newPasswordValue, key, {
                iv: iv,
                mode: mode.CBC,
                padding: pad.PKCS7
            });

            this.app.addLoading(this.service.resetEmployeePassword(
                this.token,
                enc.Hex.stringify(cryptedObjNew.ciphertext),
                this.cryptoKey,
                this.app.currentLanguage
            )).subscribe(result => {
                this.responseSuccess = result.Succeeded;

                if (result.Error) {
                    this.setResponseError(result.Error);
                }
            }, err => {
                this.setResponseError(err);
            });
        }
    }

    private init(token: IOAuthToken) {
        this.token = token;

        const requestLang = sessionStorage.getItem(languageCacheKey);
        sessionStorage.removeItem(languageCacheKey);

        if (requestLang && this.app.currentLanguage !== requestLang) {
            sessionStorage.setItem(tokenStorageKey, JSON.stringify(token));
            this.app.changeLanguage(requestLang);
            return;
        }

        this.app.addLoading(this.messageService.getForEmployeePasswordReset(this.app.currentLanguage)).subscribe(data => {
            const error = data.find(t => t.Code === messageCodes.resetError) || { Text: messageCodes.resetError };
            const success = data.find(t => t.Code === messageCodes.resetSuccess) || { Text: messageCodes.resetSuccess };
            const info = data.find(t => t.Code === messageCodes.userInfo) || { Text: '' };

            this.errorTemplate = error.Text;
            this.successText = success.Text;

            this.app.addLoading(this.service.canEmployeeResetPassword(this.token)).subscribe(result => {
                if (result.Error) {
                    this.setResponseError(result.Error);
                } else {
                    this.canReset = result.CanReset;
                    this.username = result.Username;
                    this.email = result.Email;
                    this.userInfo = info.Text
                        .replace('{{LoginName}}', this.username)
                        .replace('{{Email}}', this.email);

                    if (this.canReset) {
                        this.cryptoKey = new Date().getTime() + '_' + Math.floor(Math.random() * 100);
                        this.service.getCrypto(this.cryptoKey).subscribe(response => this.crypto = response);
                    } else {
                        this.setResponseError('password_cannotReset');
                    }
                }
            });
        });
    }

    private setResponseError(error: string) {
        this.responseError = this.errorTemplate.replace('{error_text}', this.app.translate(error));
    }

    private containsName(password: string, usernameOrEmail: string): boolean {
        if (usernameOrEmail.indexOf('\\') > -1)
            usernameOrEmail = usernameOrEmail.split('\\')[1];
        else if (usernameOrEmail.indexOf('@') > -1)
            usernameOrEmail = usernameOrEmail.split('@')[0];

        return (password || '').toLowerCase().indexOf(usernameOrEmail.toLowerCase()) > -1;
    }
}
