<app-alert level="danger" *ngIf="error">{{error}}</app-alert>

<ng-container *ngIf="isLoaded">
    <div class="row">
        <div class="col-6 form-field">
            <input class="form-control"
                   (input)="search($event)"
                   [placeholder]="'planSummary_lblSearch' | translate"
                   maxlength="50"
                   [disabled]="total < 1">
        </div>

        <div class="col-6 mb-3 text-end">
            <a *ngIf="canAdd"
               [routerLink]="['/manipulations', 'add'] | localize"
               [queryParams]="residentEmail ? { email: residentEmail } : {}"
               [ngbTooltip]="'planSummary_manipulations_add' | translate"
               class="btn btn-outline-primary">
                <span class="hidden-lg-up">
                    <i class="fi fi-rs-plus me-0"></i>
                </span>
                <i class="fi fi-rs-plus hidden-md-down"></i>
                <span class="hidden-md-down">
                    {{'planSummary_manipulations_add' | translate}}
                </span>
            </a>

            <a *ngIf="canView"
               [routerLink]="['/manipulations'] | localize"
               [queryParams]="residentUpn ? { id: residentUpn } : {}"
               [ngbTooltip]="'planSummary_manipulations_list' | translate"
               class="btn btn-outline-primary ms-1">
                <span class="hidden-md-up">
                    <i class="fi fi-rs-list me-0"></i>
                </span>
                <i class="fi fi-rs-list hidden-sm-down"></i>
                <span class="hidden-sm-down">
                    {{'planSummary_manipulations_list' | translate}}
                </span>
            </a>
        </div>
    </div>

    <app-alert level="info" *ngIf="total == 0">{{'planSummary_manipulations_empty' | translate}}</app-alert>

    <app-table [columns]="columns" [rows]="rows" pageSize="20" sortBy="fullName" *ngIf="total > 0">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    <div class="hidden-sm-down">{{row.fullName}}</div>
                    <div class="hidden-md-up">
                        <div><strong>{{row.Code}}</strong></div>
                        <div>{{row.Name}}</div>
                        <div class="text-sub">{{row.Level}}</div>
                    </div>
                </td>
                <td class="hidden-sm-down">{{row.Level}}</td>
                <td class="hidden-sm-down">{{row.CountPlanned}}</td>
                <td class="hidden-sm-down">{{row.CountFact}}</td>
                <td class="hidden-sm-down">{{row.CountRemaining}}</td>
                <td class="hidden-md-up text-nowrap">{{row.CountPlanned}} / {{row.CountFact}} / {{row.CountRemaining}}</td>
            </tr>
        </ng-template>
    </app-table>
</ng-container>
