import { Utils } from './core/Utils';
import { Component, HostListener, OnInit } from '@angular/core';
import {
    Router,
    Event as RouterEvent,
    NavigationStart,
    NavigationCancel,
    NavigationError,
    NavigationEnd,
    ResolveEnd
} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AlertMessage, AlertMessageType } from './services/alert.service';
import { AppService } from './services/app.service';
import { ParameterService } from './services/parameter.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from './services/message.service';
import { CommonDialogComponent } from './shared/dialog/common-dialog.component';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/dialog/alert-dialog.component';
import { ParameterValue } from './models/Password';
import { store as onCallShiftStore } from './features/on-call-shift/store';
import { store as courseEqualizationStore } from './features/course-equalization/shared';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private paramService: ParameterService,
        private messageService: MessageService,
        private userService: UserService,
        private router: Router,
        private modal: NgbModal
    ) { }

    title = 'eServicesApp';
    errors: string[] = [];
    toast: string;
    toastVisible: boolean;
    isReady: boolean;

    get isLoading(): boolean {
        return this.app.isLoading();
    }

    private hasErrorDialog: boolean;
    private isAuthenticated: boolean;
    private getParameterValuesObs: Observable<ParameterValue[]>;
    private toastTimeout: any;

    ngOnInit() {
        const autoLogin = this.shouldLogin();

        sessionStorage.setItem('language', this.app.currentLanguage);

        this.router.events.subscribe((e: any) => {
            this.interceptNavigation(e);

            //if (!this.app.auth.isAuthenticated && !this.anonymousAllowed(e.url)) {
            //    // should auth
            //    this.app.navigate(['/login/adfs'], { queryParams: { redirecturl: e.url } });
            //}
        });

        this.auth.user.subscribe(user => {
            if (user) {
                this.isAuthenticated = true;
            } else {
                if (this.isAuthenticated) {
                    this.app.navigateToStart();
                }

                this.isAuthenticated = false;
            }
        });

        this.app.getAlert().subscribe(msg => msg && this.showAlert(msg));

        this.app.getDialog().subscribe(data => {
            const ref = this.modal.open(CommonDialogComponent, {
                size: data.size
            });
            ref.componentInstance.title = data.title;
            ref.componentInstance.body = data.content;
            ref.componentInstance.button = data.button;

            ref.result.then(result => {
                // do nothing
            }, () => { });
        });

        this.app.getConfirm().subscribe(args => {
            const ref = this.modal.open(ConfirmDialogComponent, {
                modalDialogClass: 'alert-modal'
            });

            ref.componentInstance.title = args.dialogOptions.title;
            ref.componentInstance.body = args.dialogOptions.text;
            ref.componentInstance.okButton = args.dialogOptions.okText;
            ref.componentInstance.cancelButton = args.dialogOptions.cancelText;

            ref.result.then(result => {
                args.callback(result);
            }, () => { });
        });

        this.app.getNotification().subscribe(text => {
            this.showToast(text);
            // TODO implement a non-material snackbar
            //this.snackBar.open(text, ' ', {
            //    duration: 5000,
            //    panelClass: 'app-notification',
            //    verticalPosition: 'top'
            //});
        });

        if (autoLogin) {
            this.isReady = true;
            this.app.navigate(['/login/adfs'], {
                queryParams: {
                    redirecturl: this.app.normalizeRoute(location.pathname + location.search)
                }
            });
        } else {
            this.app.addLoading(this.checkObligations()).subscribe(result => {
                if (result.count > 0) {
                    this.showAlert({
                        text: result.message,
                        type: AlertMessageType.Warning,
                        title: ''
                    }, 'lg', () => {
                        if (result.url) {
                            location.assign(result.url);
                        }
                    });
                } else {
                    this.isReady = true;

                    if (result.error) {
                        this.app.showError(result.error);
                    }

                    this.checkBrowserSupport();

                    this.getParameterValues().subscribe(data => {
                        let appNamePara;

                        if (this.app.currentLanguage == 'en')
                            appNamePara = data.find(t => t.Code === 'AppNameEn');
                        else
                            appNamePara = data.find(t => t.Code === 'AppNameLv');

                        if (appNamePara) {
                            document.title = appNamePara.Value;

                            if (!sessionStorage.getItem('title')) {
                                sessionStorage.setItem('title', document.title);
                            }
                        }

                        const refreshMinInterval = this.paramService.findValue(data, 'RefreshMinIntervalMs', v => +v, 0);
                        const refreshTreshold = this.paramService.findValue(data, 'RefreshThreshold', v => +v, 0);
                        const refreshSleep = this.paramService.findValue(data, 'RefreshSleepMs', v => +v, 0);

                        sessionStorage.setItem('refresh_min_interval_ms', refreshMinInterval.toString());
                        sessionStorage.setItem('refresh_threshold', refreshTreshold.toString());
                        sessionStorage.setItem('refresh_sleep_ms', refreshSleep.toString());
                    });
                }
            });
        }
    }

    showToast(text: string) {
        if (this.toastTimeout) {
            clearTimeout(this.toastTimeout);
        }

        this.toast = text;
        this.toastVisible = true;

        this.toastTimeout = setTimeout(() => {
            this.toastVisible = false;

            setTimeout(() => {
                this.toast = null;
            }, 200);
        }, 5000);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event) {
        this.app.documentClick(event);
    }

    private checkObligations() {
        const subj = new Subject<{
            count?: number,
            message?: string,
            url?: string,
            error?: string
        }>();

        const onError = (error: HttpErrorResponse) => {
            if (error.status != 0) {
                subj.next({ error: error.message });
            }
        };

        const proceed = (count?: number, message?: string, url?: string) => {
            subj.next({ count, message, url });
        };

        if (!this.auth.isAuthenticated || sessionStorage.getItem('obligations_check')) {
            setTimeout(() => {
                proceed();
            }, 10);
        } else {
            this.userService.checkObligations().subscribe(data => {
                sessionStorage.setItem('obligations_check', (data.count || 0).toString());
                proceed(data.count, data.message, data.url);
            }, onError);
        }

        return subj.asObservable();
    }

    private shouldLogin() {
        if (!this.auth.isAuthenticated && document.visibilityState == 'visible' && document.URL.indexOf('nologin=true') == -1) {
            const leftPart = (location.pathname || '').toLowerCase().split('?')[0];

            return (leftPart
                //&& leftPart != '/'
                //&& leftPart != '/' + this.app.currentLanguage
                && leftPart.indexOf('/login') == -1
                && leftPart.indexOf('/password') == -1
                && leftPart.indexOf('/home') == -1
                && leftPart.indexOf('/surveys/begin') == -1
                && leftPart.indexOf('/surveys/start') == -1
            );
        }

        return false;
    }

    private showAlert(message: AlertMessage, size: 'sm' | 'lg' | 'xl' = 'sm', onClose: () => void = null) {
        // Show only one error dialog at a time
        const isError = message.type === AlertMessageType.Error;

        if (!isError || !this.hasErrorDialog) {
            this.app.hideLoading();

            const ref = this.modal.open(AlertDialogComponent, {
                modalDialogClass: 'alert-modal',
                size
            });
            ref.componentInstance.message = message;

            if (isError) {
                this.hasErrorDialog = true;
                ref.result.then(() => {
                    this.hasErrorDialog = false;

                    if (this.app.isError404) {
                        this.app.isError404 = false;
                        this.router.navigate([this.app.localizeRoute('/')]);
                    }
                }, () => { });
            } else if (onClose) {
                ref.result.then(onClose);
            }
        }
    }

    private checkBrowserSupport = () => {
        this.getParameterValues().subscribe(data => {
            const parameter = data.find(d => d.Code === 'UnsupportedBrowsers');

            if (parameter) {
                const unssuportedBrowsers = parameter.Value.split(';');
                const browserSpecs = Utils.getBrowserInfo();

                for (let i = 0; i < unssuportedBrowsers.length; i++) {
                    const element = unssuportedBrowsers[i].split('-');
                    const browser = element[0];
                    const min = parseInt(element[1]);
                    const max = parseInt(element[2]);

                    if (browser.toLocaleLowerCase().indexOf(browserSpecs.name.toLocaleLowerCase()) !== -1) {
                        const currentBrowserVersion = parseInt(browserSpecs.version);

                        if (min <= currentBrowserVersion && currentBrowserVersion <= max) {
                            this.messageService.getUnsupportedBrowser(this.app.currentLanguage).subscribe(data => {
                                this.app.alert.warning(data);
                            });
                        }
                    }
                }
            }
        });
    }

    private interceptNavigation(event: RouterEvent) {
        if (event instanceof ResolveEnd) {
            this.app.addPageContainer();
        }

        if (event instanceof NavigationStart) {
            this.errors = [];
            this.app.clearLoading();
        }

        if (event instanceof NavigationCancel) {
            this.app.clearLoading();
        }

        if (event instanceof NavigationError) {
            this.app.clearLoading();
        }

        if (event instanceof NavigationEnd) {
            if (!event.url.toLowerCase().includes('/on-call-shifts')) {
                // reset the selected student when user navigates away from on-call shifts
                onCallShiftStore.setStudent(null);
            }

            if (!event.url.toLowerCase().includes('/course-equalizations')) {
                // reset the selected student when user navigates away from on-call shifts
                courseEqualizationStore.setStudent(null);
            }

            window.scroll(0, 0);
        }
    }

    private getParameterValues() {
        if (!this.getParameterValuesObs) {
            this.getParameterValuesObs = this.paramService.getValues().pipe(shareReplay());
        }

        return this.getParameterValuesObs;
    }
}
