<div class="select {{id}}"
     [class.select-single]="!multiple"
     [class.select-multiple]="multiple"
     [class.select-pos-after]="!alignBefore"
     [class.select-pos-before]="alignBefore"
     [class.select-menu-in]="isMenuReady"
     [class.is-disabled]="disabled"
     [title]="title || displayValue"
     (keydown.arrowup)="!disabled && onArrowUp($event)"
     (keydown.arrowdown)="!disabled && onArrowDown($event)"
     (keydown.enter)="!disabled && onEnter($event)"
     (keydown.escape)="!disabled && onEscape($event)"
     #wrapper>
    <div class="select-input fc-icons-end" (click)="!disabled && toggle()">
        <input #trigger
               class="form-control text-truncate"
               [name]="id"
               [id]="id"
               [class.is-focused]="isOpened"
               [class.is-invalid]="required && !displayValue"
               [value]="displayValue"
               [placeholder]="placeholder"
               [disabled]="disabled"
               readonly />
        <i class="fi fi-rs-angle-{{isOpened ? 'up' : 'down'}} fc-icon-end" tabindex="-1"></i>
    </div>
    <div *ngIf="isOpened && (options.length || !required)"
         #menu
         class="select-menu"
         tabindex="-1">
        <div *ngIf="filterEnabled"
             class="select-filter"
             id="{{id}}-filter"
             tabindex="-1">
            <input #filter
                   class="form-control"
                   autocomplete="off"
                   [placeholder]="'selectSearch_label' | translate"
                   (keyup)="onFilter($event)"
                   (keydown.arrowup)="onArrowUp($event)"
                   (keydown.arrowdown)="onArrowDown($event)"
                   (keydown.tab)="onFilterTab($event)" />
        </div>

        <div *ngIf="clearEnabled"
             class="select-option select-option-none"
             id="{{id}}-option-none"
             [class.select-option-hover]="isClearActive"
             (mousedown)="$event.preventDefault(); clear()"
             (keydown.tab)="onOptionTab($event)"
             tabindex="0">
            {{'select_none' | translate}}
            <i class="fi fi-rs-cross"></i>
        </div>

        <div class="select-options">
            <div *ngFor="let option of visibleOptions; let i = index"
                 class="select-option select-option-{{i}}"
                 id="{{id}}-option-{{i}}"
                 [title]="getOptionDisplay(option)"
                 [class.select-option-hover]="option == currentOption"
                 [class.select-option-selected]="isSelected(option)"
                 (mousedown)="$event.preventDefault(); select(option)"
                 (keydown.tab)="onOptionTab($event)"
                 tabindex="0">

                <div *ngIf="optionTemplate; else defaultOptionTemplate">
                    <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
                </div>

                <ng-template #defaultOptionTemplate>
                    {{getOptionDisplay(option)}}
                </ng-template>

                <i class="fi fi-rs-check" *ngIf="multiple && isSelected(option)"></i>
            </div>
        </div>
    </div>
</div>
