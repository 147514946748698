import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SkillDetails } from '../../models/Skill';

@Component({
    templateUrl: './skill-details.component.html'
})
export class SkillDetailsComponent {
    constructor(
        private activeModal: NgbActiveModal
    ) { }

    @Input() skill: SkillDetails = new SkillDetails();

    close() {
        this.activeModal.close();
    }
}
