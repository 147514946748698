import { Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IOnCallShiftWorkplace } from '../../models/OnCallShift';
import { OnCallShiftService } from '../../services/on-call-shift.service';
import { IOnCallShiftEditModelExt } from './form.component';

export const userRights = {
    setStudent: 'ON_CALL_SHIFT.SET_STUDENT'
};

export interface IShiftEditData {
    model: IOnCallShiftEditModelExt;
    selectedDates: Date[];
}

@Component({
    selector: 'app-on-call-shift-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./form.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OnCallShiftEditComponent implements OnInit, OnDestroy {
    constructor(
        private activeModal: NgbActiveModal,
        private service: OnCallShiftService
    ) { }

    @Input() set data(value: IShiftEditData) {
        this._data = value;

        if (value.model.WorkplaceId)
            this.workplaceOptions.push(<IOnCallShiftWorkplace>{
                Id: value.model.WorkplaceId,
                Name: value.model.WorkplaceName
            });

        this.date = value.model.Date;
        this.hours = value.model.Hours?.toString();
        this.workplaceId = value.model.WorkplaceId;
        this.comments = value.model.Comments;

        this.selectedDates = value.selectedDates;
    };

    get data(): IShiftEditData {
        return this._data;
    }

    workplaceOptions: IOnCallShiftWorkplace[] = [];
    date: Date;
    hours: string;
    workplaceId: string;
    comments: string;
    selectedDates: Date[] = [];

    private _data: IShiftEditData = {
        model: undefined,
        selectedDates: []
    };
    private _workplacesSubscription: Subscription;
    private readonly _workplacesSubject = new Subject<string>();

    ngOnInit() {
        this._workplacesSubscription = this._workplacesSubject.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value =>
            this.loadWorkplaceOptions(value?.toString())
        );
    }

    ngOnDestroy() {
        this._workplacesSubscription.unsubscribe();
    }

    ok() {
        this.data.model.Date = this.date;
        this.data.model.Hours = +this.hours.replace(',', '.');
        this.data.model.WorkplaceId = this.workplaceId;
        this.data.model.Comments = this.comments;

        if (this.workplaceId) {
            this.data.model.WorkplaceName = this.workplaceOptions.find(t => t.Id == this.workplaceId).Name;
        }

        this.activeModal.close();
    }

    cancel() {
        this.activeModal.dismiss();
    }

    filterWorkplaces(value: string) {
        if (value?.length < 3)
            this.workplaceOptions = [];
        else {
            this.workplaceId = null;
            this._workplacesSubject.next(value);
        }
    }

    private loadWorkplaceOptions(filter: string) {
        this.service.getWorkplaces(filter).subscribe(data => this.workplaceOptions = data);
    }
}
