<ng-container *ngIf="isLoaded">
	<app-alert level="danger" *ngIf="isError">{{'application_error' | translate}}</app-alert>
	<app-alert level="success" *ngIf="isSuccess">{{'application_success' | translate}}</app-alert>
	<app-alert level="danger" *ngIf="student.IsValid === false">{{'application_studentDataNotValid' | translate}}</app-alert>

	<ng-container *ngIf="!isError && !isSuccess">
		<form class="form" #form="ngForm">
			<section *ngIf="isSkillsRef" class="block">
				<h5>{{'application_selectedSkills' | translate}}</h5>

				<app-table *ngIf="selectedSkills.length" [rows]="selectedSkills" [columns]="skillColumns" [pageSize]="10">
					<ng-template #rowTemplate let-row>
						<tr>
							<td class="hidden-sm-down">{{row.SelectedNumber}}</td>
							<td>
								<strong class="hidden-md-up">{{row.SelectedNumber}}&nbsp;</strong>
								{{row.Name}}
							</td>
							<td class="hidden-sm-down">{{row.Category}}</td>
							<td class="hidden-md-down">{{row.Level}}</td>
							<td class="hidden-md-down">{{row.LearnDate | appDate}}</td>
							<td class="hidden-lg-up">
								<div class="table-actions">
									<button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)"
											[ngbTooltip]="'myApplications_showDetails' | translate">
										<i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
									</button>
								</div>
							</td>
						</tr>
						<tr *ngIf="isExpanded(row)" class="table-row-details hidden-lg-up">
							<td colspan="9">
								<div class="dl">
									<div class="hidden-md-up">
										<span [translate]="'skills_lblCategory'"></span>
										<span>{{row.Category}}</span>
									</div>

									<div class="hidden-lg-up">
										<span [translate]="'skills_lblLevel'"></span>
										<span>{{row.Level}}</span>
									</div>

									<div class="hidden-lg-up">
										<span [translate]="'skills_lblAcquiredDate'"></span>
										<span>{{row.LearnDate | appDate}}</span>
									</div>
								</div>
							</td>
						</tr>
					</ng-template>
				</app-table>

				<app-alert level="warning" *ngIf="!selectedSkills.length">
					{{'application_noSkillSelected' | translate}}
					<div>
						<a [routerLink]="['/skills'] | localize" [queryParams]="{ id: studentUpn ? studentUpn : '' }">
							{{'application_noSkillSelectedLink' | translate}}
						</a>
					</div>
				</app-alert>
			</section>

			<div class="form-field required" *ngIf="showAddresseeField">
				<label class="form-label">{{'application_lblAddressee' | translate}}</label>
				<app-select [(ngModel)]="addresseeType"
							(selectionChange)="onAddresseeTypeChange($event)"
							name="addresseeType"
							[options]="addresseeTypeItems"
							[displayFn]="classifierDisplayValueFn"
							[required]="true">
				</app-select>
			</div>

			<div class="row">
				<div class="form-field col-sm-6" [class.required]="!addresseeType || addresseeType.Code !== 'AppAddresseeTypeNone'"
					 *ngIf="showAddresseeField">
					<label class="form-label">{{'application_lblAddressee' | translate}}</label>
					<input class="form-control"
						   [(ngModel)]="addressee"
						   name="addressee"
						   maxlength="200"
						   [required]="!addresseeType || addresseeType.Code !== 'AppAddresseeTypeNone'"
						   [disabled]="!addresseeType || addresseeType.Code === 'AppAddresseeTypeNone'"
						   [readonly]="!addresseeType || addresseeType.Code !== 'AppAddresseeTypeOther'" />
				</div>

				<div class="form-field col-sm-6 required" *ngIf="showSemesterField">
					<label class="form-label">{{'application_lblSemester' | translate}}</label>
					<app-select [(ngModel)]="semester"
								name="semester"
								[required]="true"
								[options]="semesterItems"
								[displayFn]="semesterOptionDisplayFn">
					</app-select>
				</div>

				<div class="form-field col-sm-6 required">
					<label class="form-label">{{'application_lblRefLanguage' | translate}}</label>
					<app-select [(ngModel)]="refLanguage"
								name="refLanguage"
								[required]="true"
								[disabled]="languageLocked"
								[options]="refLanguageItems"
								[displayFn]="classifierDisplayValueFn"
								(selectionChange)="onRefLanguageChange($event)">
					</app-select>
				</div>
			</div>

			<div class="row">
				<div class="form-field col-sm-6 required">
					<label class="form-label">{{'application_lblTypeOfReceipt' | translate}}</label>
					<app-select [(ngModel)]="typeOfReceipt"
								name="typeOfReceipt"
								[required]="true"
								[options]="typeOfReceiptItems"
								[displayFn]="classifierDisplayValueFn"
								(selectionChange)="onTypeOfReceiptChange($event)">
					</app-select>
				</div>

				<div class="form-field col-sm-6 required">
					<label class="form-label">{{'application_lblReceiptAddress' | translate}}</label>
					<input class="form-control"
						   [(ngModel)]="receiptAddress"
						   name="receiptAddress"
						   required
						   [disabled]="!isReceiptAddressEnabled()"
						   maxlength="200"
						   [readonly]="typeOfReceipt && typeOfReceipt.Code === 'AppTypeOfReceiptPersonally'">
				</div>

				<div class="form-field col-sm-6 required">
					<label class="form-label">{{'application_lblNumberOfCopies' | translate}}</label>
					<input class="form-control"
						   [(ngModel)]="numberOfCopies"
						   name="numberOfCopies"
						   pattern="^[1-9]$"
						   required
						   #numberOfCopiesInput="ngModel" />
					<div class="invalid-feedback" *ngIf="numberOfCopiesInput.errors">{{'application_numberOfCopiesError' | translate}}</div>
				</div>
			</div>

			<div class="form-field" [class.required]="commentsRequired">
				<label class="form-label">{{'application_lblComments' | translate}}</label>
				<textarea class="form-control" [(ngModel)]="comments" name="comments" maxlength="1975" rows="5" [required]="commentsRequired"
						  #commentsInput="ngModel"></textarea>
				<div class="invalid-feedback" *ngIf="commentsInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 1975]}}</div>
			</div>

			<app-alert level="info" *ngIf="textAfter">
				<div [innerHTML]="textAfter | safeHtml"></div>
			</app-alert>

			<app-application-student [student]="student" [isSkillsApp]="item.Type == 'Skills'"></app-application-student>

			<div>
				<app-checkbox [(ngModel)]="agreed" name="agreed">{{'application_lblAgree' | translate}}</app-checkbox>
			</div>

			<div class="form-actions">
				<button type="button" class="btn btn-outline" (click)="cancel()">
					{{'application_btnCancel' | translate}}
				</button>

				<button type="button" class="btn btn-primary" (click)="submit()">
					{{'application_btnSubmit' | translate}}
				</button>
			</div>
		</form>
	</ng-container>
</ng-container>
