import { BaseEntity } from './BaseEntity';

export class Message extends BaseEntity {
	Group: string;
    Code: string;
    Title: string;
    TextLV: string;
    TextEN: string;
    UserRights: string;
    EditTextOnly: boolean;
}

export class MessageTranslated extends BaseEntity {
    Title: string;
    Text: string;
}
