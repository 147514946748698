import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppService } from '../../../../services/app.service';
import { IStudyQualityEvaluationEditModel, StudyQualityEvaluationStatus } from '../../../../models/StudyQualityEvaluation';
import { StudyQualityEvaluationService } from '../../../../services/study-quality-evaluation.service';
import { IPlanSummaryResident } from '../../../../models/PlanSummary';

@Component({
  selector: 'app-study-quality-evaluation-change-condition',
  templateUrl: './study-quality-evaluation-change-condition.component.html',
})
export class StudyQualityEvaluationChangeConditionComponent {
    constructor(
        private app: AppService,
        private service: StudyQualityEvaluationService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() resident: IPlanSummaryResident;
    @Input() model: IStudyQualityEvaluationEditModel;

    submitted: boolean;
    isSaving: boolean;
    isSubmitting: boolean;

    @ViewChild('form', { static: true }) private form: NgForm;

    save(submit: boolean = false) {
        if (this.form.invalid) {
            this.submitted = true;
            return;
        }

        const success = () => {
            this.activeModal.close(true);
        };


        if (submit) {
            this.isSubmitting = true;

            this.model.Status = StudyQualityEvaluationStatus.Submitted;
            this.model.ResidencyAccepted = true;
        } else {
            this.isSaving = true;

            this.model.Status = StudyQualityEvaluationStatus.Residency;
        }

        this.model.ResidencyComment = null;

        const call = this.service.saveResidencyEvaluation(this.resident.StudentId, this.model);

        this.app.addLoading(call).pipe(finalize(() => submit ? this.isSubmitting = false : this.isSaving = false)).subscribe(success);
    }

    cancel() {
        this.activeModal.close(false);
    }
}
