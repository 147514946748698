import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';

import { ITableColumn } from '../../shared/table/table.component';
import { TileService } from '../../services/tile.service';
import { ITile, TileSize } from '../../models/Tile';

@Component({
    selector: 'app-tile-list',
    templateUrl: './tile-list.component.html',
    styleUrls: ['./tiles.component.scss']
})
export class TileListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: TileService
    ) { }

    readonly tileSize = TileSize;

    readonly columns: ITableColumn[] = [
        { property: 'Title', label: 'tiles_lblTitle', sorts: true },
        {
            property: 'Category',
            label: 'tiles_lblCategory',
            sorts: true,
            sortFn: (a: ITile, b: ITile) => {
                const ca = a?.Category?.Value || '';
                const cb = b?.Category?.Value || '';

                return ca.localeCompare(cb);
            }
        },
        { property: 'Status', label: 'tiles_lblStatus', sorts: true },
        { property: 'Size', label: 'tiles_lblSize', sorts: true, type: 'number' },
        { property: 'Image', label: 'tiles_lblImage' },
        { property: 'Url', label: 'tiles_lblUrl' },
        { width: '1px' }
    ];

    items: ITile[] = [];
    canEdit: boolean = false;
    canDelete: boolean = false;

    ngOnInit() {
        const currentUser = this.app.currentUser;

        if (currentUser) {
            this.canEdit = currentUser.rights.indexOf('ES_MAIN.TILES.EDIT') > -1;
        }

        this.loadItems();
    }

    delete(item: ITile) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.loadItems();
            });
        });
    }

    translateProperty(item: ITile, property: keyof ITile): string {
        return item[property + (this.app.currentLanguage == 'en' ? 'En' : '')];
    }

    private loadItems() {
        this.app.addLoading(this.service.get()).subscribe(data => {
            this.items = data;
        });
    }
}
