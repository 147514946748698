import { ActivatedRoute, Router } from "@angular/router";
import { LocalizeParser, LocalizeRouterService, LocalizeRouterSettings } from "@gilsdav/ngx-translate-router";

/**
 * Custom route localization service which just adds a language prefix to the path and does not translate the path parts.
 */
export class CustomLocalizeRouterService extends LocalizeRouterService {
    constructor(parser: LocalizeParser, settings: LocalizeRouterSettings, router: Router, route: ActivatedRoute) {
        super(parser, settings, router, route);
    }

    translateRoute(path: string | any[]): string | any[] {
        const lang = this.parser.currentLang;

        if (typeof path === 'string') {
            if (path[0] == '/') {
                path = '/' + lang + path;
            }
        } else if (path.length) {
            if (path[0][0] == '/') {
                path[0] = '/' + lang + path[0];
            }
        }

        return path;
    }
}
