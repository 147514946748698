export enum UserType {
    Student = 'Student',
    Employee = 'Employee'
}

export class Claim {
    ClaimType: string;
    Value: string;
    ValueType: string;
}

export class UserInfo {
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    roles: string[] = [];
    sessionKey: string;
    startUrl: string;
    rights: string[] = [];
    type?: UserType;
    personDetailsId?: string;
    impersonator: UserInfo;

    get isStudent(): boolean {
        return this.hasRole('student');
    }

    get isAdmin(): boolean {
        return this.hasRole('administrator');
    }

    get isAnonymous(): boolean {
        return this.hasRole('anonymous');
    }

    get isImpersonator(): boolean {
        return this.hasRight('ES_MAIN.IMPERSONATION.SET');
    }

    hasRole(role: string): boolean {
        if (!role) return false;

        const found = this.roles.find((r) => { return r.toLowerCase() === role.toLowerCase() });
        return (found != null && found != '');
    }

    hasRight(right: string): boolean {
        if (!right) return false;

        const found = this.rights.find((r) => { return r.toLowerCase() === right.toLowerCase() });
        return (found != null && found != '');
    }
}

export class UserCrypto {
    key: string;
    iv: string;
}
export class UserListItem {
    Id: string;
    UserName: string
    Name: string;
    LastName: string;
    Roles: string;
    Created:Date;
    LastLogin:Date;
}

export class OAuthRequest {
    UserInfoPath: string;
    AuthPath: string;
    AuthServerUri: string;
    ClientId: string;
    ReturnUri: string;
    Nonce: string;
    Scope: string;
}
