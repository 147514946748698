export enum StudyQualityEvaluationSemester {
    First = 1,
    Second = 2
};

export enum StudyQualityEvaluationStatus {
    Draft = 'Draft',
    Submitted = 'Submitted',
    Residency = 'Residency'
};

export interface IStudyQualityEvaluation {
    Id: number;
    StudyYear: number;
    SupervisorComment?: string;
    ResidencyComment?: string;
    Results: IStudyQualityEvaluationResult[];
}

export interface IStudyQualityEvaluationResult {
    Id: number;
    Semester: StudyQualityEvaluationSemester;
    Knowledge: string;
    KnowledgeId: string;
    KnowledgeComment?: string;
    Skills: string;
    SkillsId: string;
    SkillsComment?: string;
    Competence: string;
    CompetenceId: string;
    CompetenceComment?: string;
    Tasks: string;
    TasksId: string;
    TasksComment?: string;
    PreviouslyUnachieved: string;
    PreviouslyUnachievedId: string;
    PreviouslyUnachievedComment?: string;
    NextPriorities: string;
    Status: StudyQualityEvaluationStatus;
    DecisionId?: string;
    DecisionComment?: string;
    Deadline?: Date;
    ResidencyDeadline?: Date;
    ResidencyAccepted?: boolean;
    ResidencyComment?: string;
    ResidencyCondition?: string;
}

export interface IStudyQualityEvaluationEditModel {
    StudyYear: number;
    Semester: StudyQualityEvaluationSemester;
    KnowledgeId: string;
    KnowledgeComment?: string;
    SkillsId: string;
    SkillsComment?: string;
    CompetenceId: string;
    CompetenceComment?: string;
    TasksId?: string;
    TasksComment?: string;
    PreviouslyUnachievedId?: string;
    PreviouslyUnachievedComment?: string;
    NextPriorities: string;
    Status: StudyQualityEvaluationStatus;
    DecisionId?: string;
    DecisionComment?: string;
    Deadline?: Date;
    ResidencyDeadline?: Date;
    ResidencyAccepted?: boolean;
    ResidencyComment?: string;
    ResidencyCondition?: string;
}
