import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { Skill, SkillDetails } from '../../models/Skill';

import { AppService } from '../../services/app.service';
import { SkillService } from '../../services/skill.service';
import { PersonService } from '../../services/person.service';
import { ParameterService } from '../../services/parameter.service';

import { SkillDetailsComponent } from './skill-details.component';

import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { ITableColumn } from '../../shared/table/table.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export const userRights = {
    setStudent: 'SKILLS.SET_STUDENT'
};

let selectedSkillBuffer: Skill[] = [];

/**
 * Read selected skills from the buffer once.
 */
export function getSelectedSkills(): Skill[] {
    const val = [...selectedSkillBuffer]
    selectedSkillBuffer = [];
    return val;
}

/**
 * Set selected skills into the buffer.
 * @param skills
 */
export function setSelectedSkills(skills: Skill[]) {
    selectedSkillBuffer = skills;
}

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: SkillService,
        private studentService: PersonService,
        private modal: NgbModal,
        private router: Router,
        private route: ActivatedRoute,
		private parameters: ParameterService,
		private messageService: MessageService
    ) { }

    skills: Skill[] = [];
    showOnlyHighestLevel: boolean = true;
    showOnlyAcquired: boolean;
    categories: string[] = [];
    levels: string[] = [];
    filterCategory: string;
    filterLevel: string;

    showStudentPicker: boolean;
    studentPickerFilter: string;
    canPickStudent: boolean;
    student: IPersonSearchResultItem;

	rowsPerPage: number = 10;
	infoTextTop: string = null;
	infoTextBottom: string = null;

    readonly columns: ITableColumn[] = [
        { width: '1px' },
        { property: 'Name', label: 'skills_lblName', sorts: true },
        { property: 'Level', label: 'skills_lblLevel', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Code', label: 'skills_lblSkillCode', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Category', label: 'skills_lblCategory', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'SubjectCode', label: 'skills_lblSubjectCode', sorts: true, cssClass: 'hidden-md-down' },
        { width: '1px' }
    ];

    get hasSelected(): boolean {
        return this.selected.length > 0;
    }

    private all: Skill[] = [];
    private selected: Skill[] = [];
    private details: { [key: string]: SkillDetails } = {};
    private expanded: SkillDetails[] = [];

    private get currentStudentEmail(): string {
        return this.student ? this.student.Email : null;
    }

    ngOnInit() {
        const user = this.app.currentUser;

		if (user) {
            this.canPickStudent = user.rights.indexOf(userRights.setStudent) > -1;

            if (this.canPickStudent) {
                const queryUpn = this.route.snapshot.queryParams['id'];

                if (queryUpn) {
                    this.app.addLoading(this.studentService.findStudents('student_id', queryUpn)).subscribe(data => {
                        if (data.length) {
                            this.setStudent(data[0]);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
                } else {
                    this.showStudentPicker = true;
                }
            } else {
                this.init();
			}
        }
    }

    init() {
        const isEn = this.app.currentLanguage === 'en';

		this.app.addLoading(forkJoin(
            this.service.getSkills(this.currentStudentEmail),
            this.parameters.getValues(),
            this.messageService.getByCodes(['SKILLS_INFO_TEXT_TOP', 'SKILLS_INFO_TEXT_BOTTOM'])
        )).subscribe(data => {
			let [skills, para, infoTexts] = data;

			this.rowsPerPage = this.parameters.findValue(para, 'SkillsPageSize', t => +t, 10);

			this.all = skills;

            this.categories = Array.from((new Set(skills.map(t => t.Category))).values());
            this.levels = Array.from((new Set(skills.map(t => t.Level))).values());

            this.categories.sort();
            this.levels.sort();

            const selectedIds = getSelectedSkills().map(t => t.Id);
            this.selected = this.all.filter(t => selectedIds.indexOf(t.Id) > -1);

			this.filter();

            const infoTop = infoTexts.find(t => t.Code === 'SKILLS_INFO_TEXT_TOP');
            const infoBottom = infoTexts.find(t => t.Code === 'SKILLS_INFO_TEXT_BOTTOM');

            this.infoTextTop = isEn ? infoTop.TextEN : infoTop.TextLV;
            this.infoTextBottom = isEn ? infoBottom.TextEN : infoBottom.TextLV;
		});
    }

    toggle(row: SkillDetails) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: SkillDetails): boolean {
        return this.expanded.indexOf(row) > -1;
    }

	allRowsSelected: boolean = false;
    checkAllRowsSelected() {
		let array = this.skills.filter(t => t.IsAcquirable);
		
		if (array.length == 0)
			this.allRowsSelected = false;
		else {
			for (let skill of array) {
				var index = this.selected.indexOf(skill);
				if (index == -1) {
					this.allRowsSelected = false;
					return;
				}
			}
			this.allRowsSelected = true;
		}
    }

    rowSelected(row: Skill): boolean {
        return this.selected.indexOf(row) > -1;
    }

    selectRow(row: Skill) {
        const index = this.selected.indexOf(row);

        if (index > -1)
            this.selected.splice(index, 1);
        else {
            this.selected.push(row);
			this.selected.sort((a, b) => (a.Number > b.Number) ? 2 : ((b.Number > a.Number) ? -2 : (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)));
        }

        this.checkAllRowsSelected();
    }

    selectAllRows(event: boolean) {
		if (event) {
			this.skills.filter(t => t.IsAcquirable).forEach(skill => {
				const index = this.selected.indexOf(skill);
				if (index == -1) {
					this.selected.push(skill);
				}
			});
			this.selected.sort((a, b) => (a.Number > b.Number) ? 2 : ((b.Number > a.Number) ? -2 : (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)));
			this.checkAllRowsSelected();
        } else {
			this.skills.filter(t => t.IsAcquirable).forEach(skill => {
				const index = this.selected.indexOf(skill);
				if (index > -1)
					this.selected.splice(index, 1);
			});
        }
    }

    filter() {
        this.skills = this.all.filter(t =>
            (!this.showOnlyHighestLevel || t.ShowOnlyHighestLevel)
            && (!this.showOnlyAcquired || t.ValueIsPositive)
            && (!this.filterCategory || t.Category === this.filterCategory)
            && (!this.filterLevel || t.Level === this.filterLevel));
		this.checkAllRowsSelected();
    }

    clearFilters() {
        this.showOnlyAcquired = false;
        this.showOnlyHighestLevel = false;
        this.filterCategory = undefined;
        this.filterLevel = undefined;

        this.skills = [...this.all];
		this.checkAllRowsSelected();
    }

	private resetFilters() {
        this.showOnlyAcquired = false;
        this.showOnlyHighestLevel = true;
        this.filterCategory = undefined;
        this.filterLevel = undefined;

        this.skills = [...this.all];
		this.selected = [];
		this.checkAllRowsSelected();
    }

    showDetails(skill: Skill) {
        let cached = this.details[skill.Id];

        if (cached) {
            this.showDetailsDialog(cached);
        } else {
            this.app.addLoading(this.service.getDetails(skill.Id, this.currentStudentEmail)).subscribe(data => {
                this.details[skill.Id] = data;
                this.showDetailsDialog(data);
            });
        }
    }

    createReference() {
        // copy selected skills into the buffer before the redirect
        selectedSkillBuffer = JSON.parse(JSON.stringify(this.selected));

        const params: any = { type: 'Skills' };

        if (this.student)
            params.id = this.student.StudentId;

        this.router.navigate([this.app.localizeRoute('/application')], {
            queryParams: params
        });
    }

    setStudent(student: IPersonSearchResultItem) {
        this.showStudentPicker = false;
		this.resetFilters();
        this.student = student;
        this.init();
    }

    private showDetailsDialog(data: SkillDetails) {
        const ref = this.modal.open(SkillDetailsComponent, {
            size: 'lg'
        });

        ref.componentInstance.skill = data;
    }
}
