<div class="search-input fc-icons-start fc-icons-end">
    <span class="fc-icon-start">
        <i class="fi fi-rs-search"></i>
    </span>
    <input #control
           autofocus
           autocomplete="off"
           class="form-control text-truncate"
           [placeholder]="'search' | translate"
           (input)="search()" />
    <button type="button" class="fc-icon-end" (click)="clear()" *ngIf="control.value">
        <i class="fi fi-rs-cross"></i>
    </button>
</div>

<div class="search-results">
    <div *ngFor="let c of results" class="result-block">
        <div *ngIf="c.category" class="category">{{c.category}}</div>
        <ul>
            <li *ngFor="let n of c.items" class="search-result">
                <a href="#" (click)="$event.preventDefault(); go(n)">
                    <div *ngIf="n.title" class="title">
                        <span *ngFor="let p of n.match.title" [class.match]="p.isMatch">{{p.text}}</span>
                    </div>
                    <div *ngIf="n.description" class="description text-sub">
                        <span *ngFor="let p of n.match.description" [class.match]="p.isMatch">{{p.text}}</span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</div>
