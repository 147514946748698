export type ApplicationStatusType = 'Draft' | 'Published' | 'Deactivated';
export type ApplicationDataSourceType = 'SIS' | 'IDM' | 'DVS';
export type ApplicationDataSourceResultType = 'List' | 'Dictionary';
export type ApplicationOrderProcessType = 'None' | 'DocPort' | 'DVS';
export type ApplicationFormFieldType = 'Date' | 'Integer' | 'Select' | 'Autocomplete' | 'Text' | 'Multiline' | 'Money';

export class ApplicationConfig {
    Id: number;
    Code: string;
    Status: ApplicationStatusType;
    Version: number;
    NameLV: string;
    NameEN: string;
    Group: string;
    GroupId: string;
    OrderProcess: ApplicationOrderProcessType;
    DataSources: ApplicationDataSource[] = [];
    FormFields: ApplicationFormField[] = [];
    FormFileFields: ApplicationFormFileField[] = [];
    Template: { Id: number, FileNameLV: string, FileNameEN: string };
    OrderTemplate: { Id: number, FileNameLV: string, FileNameEN: string };
}

export class ApplicationConfigSaveModel {
    Id?: number;
    Code: string;
    NameLV: string;
    NameEN: string;
    GroupId: string;
    OrderProcess: ApplicationOrderProcessType;
    DataSources: ApplicationDataSource[] = [];
    FormFields: ApplicationFormField[] = [];
    FormFileFields: ApplicationFormFileField[] = [];
    Template: {
        Id?: number,
        LV?: { Base64: string },
        EN?: { Base64: string }
    };
    OrderTemplate: {
        Id?: number,
        LV?: { Base64: string },
        EN?: { Base64: string }
    };
}

export interface IApplicationConfigListItem {
    Id: number;
    Code: string;
    Status: ApplicationStatusType;
    Version: number;
    Name: string;
}

export class ApplicationDataSource {
    Name: string;
    Database: ApplicationDataSourceType;
    Procedure: string;
    ResultType: ApplicationDataSourceResultType;
}

export class ApplicationFormField {
    Name: string;
    NameLV: string;
    NameEN: string;
    Type: ApplicationFormFieldType;
    Row: string;
    Size: number;
    Required: boolean;
    Editable: boolean;
    Source: string;
}


export class ApplicationFormFileField {
    Name: string;
    NameLV: string;
    NameEN: string;
    Row: string;
    Extensions: string;
    MaxSize: number;
    MaxFileCount: number;
    Required: boolean;
}
