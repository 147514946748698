import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';

const actions = {
    done: 'done',
    token: 'token',
    crmToken: 'crmtoken',
    confirmLogin: 'confirmlogin',
    adfs: 'adfs',
    error: 'error',
    retry: 'retry',
    noRights: 'no-rights',
    success: 'success'
};

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private messages: MessageService,
        private route: ActivatedRoute
    ) { }

    action: string;
    accessDenied: boolean;
    noRightsWarning: string;

    ngOnInit() {
        this.accessDenied = this.route.snapshot.queryParams['accessDenied'] == 'true';

        this.route.params.subscribe(() => {
            this.init();
        });
    }

    logout() {
        this.app.showLoading();
        this.auth.logout();
    }

    private processLoginResponse(query: { [key: string]: any }, response: any) {
        if (response) {
            this.checkCookie(query.redirecturl || response.RedirectUrl);
        } else {
            this.redirectToError();
        }
    }

    private checkCookie(redirectUrl?: string, key?: string) {
        const proceed = () => {
            if (redirectUrl) {
                this.app.navigateByUrl(decodeURIComponent(redirectUrl));
            } else {
                this.app.navigateToStart();
            }
        };

        if (this.auth.isAuthenticated) {
            if (!this.app.currentUser.rights.length) {
                this.app.navigateByUrl(`/login/${actions.noRights}`);
            } else {
                proceed();
            }
        } else {
            if (key) {
                this.auth.authenticate(key).then(() => {
                    proceed();
                });
            } else {
                proceed();
            }
        }
    }

    private redirectToError() {
        this.app.navigateByUrl(`/login/${actions.error}`);
    }

    private init() {
        const params = this.route.snapshot.params;
        const query = this.route.snapshot.queryParams;

        this.action = params['type'];

        const done = () => {
            this.action = actions.done;
            this.checkCookie(query['redirecturl']);
        };

        if (!this.action || this.action == actions.done) {
            done();
        } else {
            if (this.action == actions.success) {
                done();
            } else if (this.action == actions.retry) {
                let redirecturl = '';

                if (query['redirecturl'])
                    redirecturl = '?returnto=' + encodeURIComponent(query['redirecturl']);

                location.href = this.app.env.adfsApiRoute + '/signout' + redirecturl;
            } else if (this.action == actions.noRights) {
                this.app.addLoading(this.messages.getByCode('NO_RIGHTS')).subscribe(data => {
                    this.action = actions.done;
                    this.noRightsWarning = this.messages.getTextByLanguage(data, this.app.currentLanguage);
                });
            } else {
                if (!this.auth.isAuthenticated || this.action === actions.token || this.action === actions.crmToken) {
                    switch (this.action) {
                        case actions.confirmLogin:
                            this.checkCookie(query['redirecturl'], params['confirmKey']);
                            break;

                        case actions.token:
                            if (query['user'] && query['time'] && query['hash'] && query['area']) {
                                this.app.addLoading(this.auth.clear()).subscribe(() => {
                                    this.auth.startSessionWithToken(
                                        query['user'],
                                        decodeURIComponent(query['time']),
                                        query['name'] || query['user'],
                                        query['hash'],
                                        query['area'],
                                        query['lang'] || this.app.currentLanguage
                                    ).subscribe(response => {
                                        this.processLoginResponse(query, response);
                                    }, () => {
                                        this.redirectToError();
                                    });
                                });
                            } else {
                                this.redirectToError();
                            }
                            break;

                        case actions.crmToken:
                            if (query['CustId'] && query['CustIdType'] && query['AdLogin'] && query['Token'] && query['Time']) {
                                this.app.addLoading(this.auth.clear()).subscribe(() => {
                                    this.auth.startSessionWithCRMToken(
                                        query['CustId'],
                                        query['CustIdType'],
                                        query['AdLogin'],
                                        decodeURIComponent(query['Time']),
                                        query['Token'],
                                        query['lang'] || this.app.currentLanguage
                                    ).subscribe(response => {
                                        this.processLoginResponse(query, response);
                                    }, () => {
                                        this.redirectToError();
                                    });
                                });
                            } else {
                                this.redirectToError();
                            }
                            break;

                        case actions.adfs:
                            this.auth.login(query['redirecturl']);
                            break;

                        case actions.error:
                            break;
                    }
                } else {
                    done();
                }
            }
        }
    }
}
