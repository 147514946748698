import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, concatMap, delay, finalize, first, retryWhen, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import { IWaitDialogData, WaitDialogComponent } from '../shared/dialog/wait-dialog.component';
import { AppService } from '../services/app.service';

import { environment as ENV } from '../../environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

const log = ENV.production ? (...data: any[]) => { } : console.log;

let currentDialog: NgbModalRef;

class RequestWrapper {
    constructor(public id: string) { }

    // pending - request is in progress
    // idle - request is waiting to be retried or cancelled
    // completed - request is completed
    status: 'pending' | 'idle' | 'completed' = 'pending';
    attempt: number = 1; // current attempt
    maxAttempts: number = 1; // max attempts per interception
    totalAttempts: number = 1; // total attempts taken
    ignore: boolean = true; // used to ignore non-429 requests
    url?: string;
    error?: any;
    
    private readonly cancelSubj = new Subject<void>();
    private readonly retrySubj = new Subject<any>();
    private readonly completeSubj = new Subject<void>();

    cancel() {
        this.cancelSubj.next();
    }

    retry() {
        if (this.status == 'idle' && this.error) {
            this.status = 'pending';
            this.attempt = 1;

            this.retrySubj.next(this.error);
        }
    }

    complete() {
        if (this.status != 'completed') {
            this.status = 'completed';
            this.completeSubj.next();
        }
    }

    getCancel() {
        return this.cancelSubj.asObservable().pipe(first());
    }

    getRetry() {
        return this.retrySubj.asObservable().pipe(first());
    }

    getComplete() {
        return this.completeSubj.asObservable().pipe(first());
    }
}

class WaitingDialogWrapper {
    constructor() {
        log('requests', this._requests);
    }

    private closeDialogTimeout: any;
    private _requests: RequestWrapper[] = [];

    get requests() {
        return this._requests.filter(t => !t.ignore);
    }

    data: IWaitDialogData = {
        onRetry: () => {
            // retry when requested
            this.retry();
        },
        waitWhen: () => {
            // show waiting when there is at least one pending request
            return !this.requests.length || this.requests.some(t => t.status == 'pending');
        },
        cancelWhen: () => {
            // show cancel when at least one request has already failed
            return !this.requests.length || this.requests.some(t => t.totalAttempts > 1);
        },
        retryWhen: () => {
            // show retry when there are no pending requests
            return this.requests.length && this.requests.filter(t => t.status == 'idle').length == this.requests.length;
        }
    };

    addRequest(id: string): RequestWrapper {
        const rw = new RequestWrapper(id);

        this._requests.push(rw);

        rw.getComplete().subscribe(() => {
            // when request completed, it should notify the dialog that it can be closed
            // (the dialog automatically closes only when all requests are marked as completed)
            this.close();
        });

        return rw;
    }

    removeRequest(request: RequestWrapper) {
        const ix = this._requests.indexOf(request);
        this._requests.splice(ix, 1);
    }

    cancel() {
        log('dialog.cancel');

        this.requests.forEach(t => {
            t.cancel();
        });
    }

    retry() {
        this.requests.filter(t => t.status != 'completed').forEach(t => {
            t.retry();
        });
    }

    open(modal: NgbModal, onCancel: () => void) {
        currentDialog = modal.open(WaitDialogComponent, {
            modalDialogClass: 'wait-dialog'
        });

        currentDialog.componentInstance.data = dialogWrapper.data;

        currentDialog.result.then(result => {
            if (result?.cancelled) {
                this.cancel();
                this.close();

                onCancel();
            }
        }, () => { });
    }

    close() {
        if (!currentDialog) return;

        if (this.closeDialogTimeout) {
            clearTimeout(this.closeDialogTimeout);
        }

        this.closeDialogTimeout = setTimeout(() => {
            // since a single dialog instance is shown for all requests,
            // we must check the pending request count before closing the dialog
            const pendingRequestCount = this.requests.filter(t => t.status == 'pending').length;

            log(`dialog.close (pending ${pendingRequestCount})`);

            if (currentDialog && pendingRequestCount == 0) {
                currentDialog.close();
                currentDialog = undefined;

                this._requests = [];
            };
        }, 500);
    }
};

const dialogWrapper = new WaitingDialogWrapper();

@Injectable()
export class LoadControlInterceptor implements HttpInterceptor {
    constructor(
        private app: AppService,
        private router: Router,
        private modal: NgbModal
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let waitMs: number;
        let maxAttempts: number; // max attempts per current interception
        let hasWaitingError = false;

        const waitingHttpStatus = 429;
        const id = Date.now().toString();

        const reqLog = text => {
            log(`(${id}) ${text}`);
        };

        const requestWrapper = dialogWrapper.addRequest(id);
        requestWrapper.url = request.url;

        let originalError: any;

        return next.handle(request).pipe(
            catchError(error => {
                originalError = error;

                if (hasWaitingError) {
                    reqLog('catchError - already done');
                    throw error;
                }

                reqLog('catchError - new');

                if (error.status == waitingHttpStatus) {
                    reqLog('429');

                    hasWaitingError = true;

                    waitMs = error.error?.WaitMs || 0;
                    maxAttempts = error.error?.MaxAttempts || 0;

                    if (waitMs <= 0) {
                        waitMs = 5000;
                    }

                    if (maxAttempts < 0) {
                        maxAttempts = 1;
                    }

                    requestWrapper.ignore = false;
                    requestWrapper.maxAttempts = maxAttempts;

                    this.app.hideLoading();

                    if (!currentDialog) {
                        reqLog('open the dialog');

                        dialogWrapper.open(this.modal, () => {
                            this.router.navigateByUrl('/');
                        });
                    }
                } else {
                    dialogWrapper.removeRequest(requestWrapper);
                }

                throw error;
            }),
            retryWhen(error => {
                reqLog('retryWhen');

                if (!hasWaitingError) {
                    reqLog('auto-retry - throw');
                    return throwError(originalError);
                }

                requestWrapper.status = 'pending';
                requestWrapper.error = error;

                return error.pipe(
                    delay(waitMs),
                    concatMap((error, count) => {
                        reqLog(`auto-retry - attempt ${requestWrapper.attempt}/${maxAttempts}`);

                        if (error.status == waitingHttpStatus) {
                            if (requestWrapper.attempt < maxAttempts) {
                                requestWrapper.attempt++;
                                requestWrapper.totalAttempts++;

                                // retry
                                return of(error);
                            } else {
                                reqLog(`auto-retry - max attempts (${maxAttempts}) reached`);

                                requestWrapper.status = 'idle';

                                return requestWrapper.getRetry();
                            }
                        } else {
                            reqLog('auto-retry - not 429');
                        }

                        reqLog('auto-retry - close the dialog and throw');

                        dialogWrapper.close();

                        return throwError(error);
                    })
                );
            }),
            takeUntil(requestWrapper.getCancel()),
            finalize(() => {
                if (hasWaitingError) {
                    reqLog('finalize');
                    requestWrapper.complete();
                } else {
                    dialogWrapper.removeRequest(requestWrapper);
                }
            })
        );
    }
}
