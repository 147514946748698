<div class="row">
    <div class="col-md-3">
        <label class="form-label" [class.required]="isEdit">{{'studyQualityEvaluation_lblKnowledge' | translate}}</label>
    </div>

    <div class="form-field required col-md-3">
        <app-select [(ngModel)]="model.KnowledgeId"
                    name="knowledge"
                    [required]="true"
                    [options]="options.result"
                    [valueProperty]="'Id'"
                    [displayProperty]="'Value'"
                    *ngIf="isEdit"></app-select>

        <ng-container *ngIf="!isEdit">{{data?.Knowledge || '-'}}</ng-container>
    </div>

    <div class="form-field col-md-6">
        <textarea class="form-control" [(ngModel)]="model.KnowledgeComment" name="knowledgeComment" maxlength="4000" rows="4"
                  [placeholder]="'studyQualityEvaluation_lblComment' | translate" required
                  *ngIf="isEdit"></textarea>

        <ng-container *ngIf="!isEdit">{{data?.KnowledgeComment || '-'}}</ng-container>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <label class="form-label" [class.required]="isEdit">{{'studyQualityEvaluation_lblSkills' | translate}}</label>
    </div>

    <div class="form-field required col-md-3">
        <app-select [(ngModel)]="model.SkillsId"
                    name="skills"
                    [required]="true"
                    [options]="options.result"
                    [valueProperty]="'Id'"
                    [displayProperty]="'Value'"
                    *ngIf="isEdit"></app-select>

        <ng-container *ngIf="!isEdit">{{data?.Skills || '-'}}</ng-container>
    </div>

    <div class="form-field col-md-6">
        <textarea class="form-control" [(ngModel)]="model.SkillsComment" name="skillsComment" maxlength="4000" rows="4"
                  [placeholder]="'studyQualityEvaluation_lblComment' | translate" required
                  *ngIf="isEdit"></textarea>

        <ng-container *ngIf="!isEdit">{{data?.SkillsComment || '-'}}</ng-container>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <label class="form-label" [class.required]="isEdit">{{'studyQualityEvaluation_lblCompetence' | translate}}</label>
    </div>

    <div class="form-field required col-md-3">
        <app-select [(ngModel)]="model.CompetenceId"
                    name="competence"
                    [required]="true"
                    [options]="options.result"
                    [valueProperty]="'Id'"
                    [displayProperty]="'Value'"
                    *ngIf="isEdit"></app-select>

        <ng-container *ngIf="!isEdit">{{data?.Competence || '-'}}</ng-container>
    </div>

    <div class="form-field col-md-6">
        <textarea class="form-control" [(ngModel)]="model.CompetenceComment" name="competenceComment" maxlength="4000" rows="4"
                  [placeholder]="'studyQualityEvaluation_lblComment' | translate" required
                  *ngIf="isEdit"></textarea>

        <ng-container *ngIf="!isEdit">{{data?.CompetenceComment || '-'}}</ng-container>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <label class="form-label" [class.required]="isEdit && studyYear > 1">{{'studyQualityEvaluation_lblTasks' | translate}}</label>
    </div>

    <div class="form-field required col-md-3">
        <app-select [(ngModel)]="model.TasksId"
                    name="tasks"
                    [required]="studyYear > 1"
                    [options]="options.task"
                    [valueProperty]="'Id'"
                    [displayProperty]="'Value'"
                    *ngIf="isEdit"></app-select>

        <ng-container *ngIf="!isEdit">{{data?.Tasks || '-'}}</ng-container>
    </div>

    <div class="form-field col-md-6">
        <textarea class="form-control" [(ngModel)]="model.TasksComment" name="tasksComment" maxlength="4000" rows="4"
                  [placeholder]="'studyQualityEvaluation_lblComment' | translate" [required]="studyYear > 1"
                  *ngIf="isEdit"></textarea>

        <ng-container *ngIf="!isEdit">{{data?.TasksComment || '-'}}</ng-container>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <label class="form-label" [class.required]="isEdit && studyYear > 1">{{'studyQualityEvaluation_lblPreviouslyUnachieved' | translate}}</label>
    </div>

    <div class="form-field required col-md-3">
        <app-select [(ngModel)]="model.PreviouslyUnachievedId"
                    name="previouslyUnachieved"
                    [required]="studyYear > 1"
                    [options]="options.achievement"
                    [valueProperty]="'Id'"
                    [displayProperty]="'Value'"
                    *ngIf="isEdit"></app-select>

        <ng-container *ngIf="!isEdit">{{data?.PreviouslyUnachieved || '-'}}</ng-container>
    </div>

    <div class="form-field col-md-6">
        <textarea class="form-control" [(ngModel)]="model.PreviouslyUnachievedComment" name="previouslyUnachievedComment" maxlength="4000" rows="4"
                  [placeholder]="'studyQualityEvaluation_lblComment' | translate" [required]="model.PreviouslyUnachievedId == unachievedId"
                  *ngIf="isEdit"></textarea>

        <ng-container *ngIf="!isEdit">{{data?.PreviouslyUnachievedComment || '-'}}</ng-container>

        <div *ngIf="isEdit && model.Semester == 1 && previous?.DecisionComment" class="feedback">
            <div class="text-toggle" [class.text-toggle-in]="showPreviousData">
                <a href="#" (click)="$event.preventDefault(); showPreviousData = !showPreviousData">
                    {{'studyQualityEvaluation_btnPreviousRequirements' | translate}}
                </a>
            </div>
            <div *ngIf="showPreviousData">{{previous.DecisionComment}}</div>
        </div>

        <div *ngIf="isEdit && model.Semester == 2 && previous?.NextPriorities" class="feedback">
            <div class="text-toggle" [class.text-toggle-in]="showPreviousData">
                <a href="#" (click)="$event.preventDefault(); showPreviousData = !showPreviousData">
                    {{'studyQualityEvaluation_btnPreviousPriorities' | translate}}
                </a>
            </div>
            <div *ngIf="showPreviousData">{{previous.NextPriorities}}</div>
        </div>
    </div>
</div>

<ng-container *ngIf="isSecondSemester">
    <div class="row">
        <div class="col-md-3">
            <label class="form-label" [class.required]="isEdit">{{'studyQualityEvaluation_lblDecision' | translate}}</label>
        </div>

        <div class="form-field required col-md-3">
            <app-select [(ngModel)]="model.DecisionId"
                        name="decision"
                        [required]="true"
                        [options]="options.decision"
                        [valueProperty]="'Id'"
                        [displayProperty]="'Value'"
                        *ngIf="isEdit"></app-select>

            <ng-container *ngIf="!isEdit">{{data?.Decision || '-'}}</ng-container>
        </div>

        <div class="form-field col-md-6">
            <textarea class="form-control" [(ngModel)]="model.DecisionComment" name="decisionComment" maxlength="4000" rows="4"
                      [placeholder]="model.DecisionId != doNotMoveToNextYearId ? ('studyQualityEvaluation_lblConditions' | translate) : ('studyQualityEvaluation_lblJustification' | translate)"
                      [required]="model.DecisionId == moveToNextYearWithConditionId || model.DecisionId == doNotMoveToNextYearId"
                      *ngIf="isEdit"></textarea>

            <ng-container *ngIf="!isEdit">{{data?.DecisionComment || '-'}}</ng-container>
        </div>
    </div>
    <div class="row" *ngIf="(!isEdit && data?.DecisionId == moveToNextYearWithConditionId) || (isEdit && model?.DecisionId == moveToNextYearWithConditionId)">
        <div class="col-md-3">
            <label class="form-label" [class.required]="isEdit && model?.DecisionId == moveToNextYearWithConditionId">{{ 'studyQualityEvaluation_lblDeadline' | translate }}</label>
        </div>

        <div class="form-field col-md-3">
            <app-date [(ngModel)]="model.Deadline" name="deadline" [required]="model.DecisionId == moveToNextYearWithConditionId" *ngIf="isEdit"></app-date>
            <ng-container *ngIf="!isEdit">{{(data?.Deadline | appDate) || '-'}}</ng-container>
        </div>
    </div>
</ng-container>


<div class="row">
    <div class="col-md-3">
        <label class="form-label" [class.required]="isEdit && model.DecisionId != doNotMoveToNextYearId">{{'studyQualityEvaluation_lblNextPriorities' | translate}}</label>
    </div>

    <div class="form-field col-md-9">
        <textarea class="form-control" [(ngModel)]="model.NextPriorities" name="nextPriorities" [required]="model.DecisionId != doNotMoveToNextYearId" maxlength="4000" rows="4"
                  *ngIf="isEdit"></textarea>
        <ng-container *ngIf="!isEdit">{{data?.NextPriorities || '-'}}</ng-container>
    </div>
</div>

<ng-container *ngIf="showResidencyDecision()">
    <div class="row">
        <div class="col-md-3">
            <label class="form-label">{{ 'studyQualityEvaluation_lblResidencyDecision' | translate}}</label>
        </div>
        <div class="form-field required col-md-3">
            {{ data?.ResidencyAccepted === true ? 'Pieņemts' : (data?.ResidencyAccepted === false ? 'Noraidīts' : '-') }}
        </div>
    </div>

    <div class="row" *ngIf="data?.ResidencyComment">
        <div class="col-md-3">
            <label class="form-label">{{ 'studyQualityEvaluation_lblResidencyComment' | translate}}</label>
        </div>
        <div class="form-field col-md-6">
            {{ data?.ResidencyComment}}
        </div>
    </div>

    <ng-container *ngIf="data?.ResidencyCondition">
        <div class="row">
            <div class="col-md-3">
                <label class="form-label">{{ 'studyQualityEvaluation_lblResidencyCondition' | translate}}</label>
            </div>
            <div class="form-field col-md-6">
                {{ data?.ResidencyCondition }}
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <label class="form-label">{{ 'studyQualityEvaluation_lblResidencyDeadline' | translate}}</label>
            </div>
            <div class="form-field col-md-6">
                {{ data?.ResidencyDeadline | appDate }}
            </div>
         </div>
    </ng-container>
</ng-container>
