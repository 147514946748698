import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';
import { HospitalService } from '../../services/hospital.service';
import { IHospital } from '../../models/Hospital';
import { IAcademicYear } from '../../models/AcademicYear';
import { IPersonSearchResultItem } from 'src/app/models/Person';
import { PersonType } from 'src/app/shared/person-picker/person-picker.component';
import { store } from './store';

@Component({
    selector: 'app-hospital-dashboard',
    templateUrl: './hospital-dashboard.component.html'
})
export class HospitalDashboardComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: HospitalService,
        private route: ActivatedRoute
    ) { }
    
    tabs: { id: string, title: string }[] = [];

    tabsReady: boolean;
    activeTabId: number = 1;
    academicYears: IAcademicYear[] = [];
    companies: IHospital[] = [];
    selectedCompanyId: string;
    selectedYearId: string;
    seminarId: string;
    seminarTrainer: string;

    personPickerOpened: boolean;
    canPickEmployee: boolean;
    person: IPersonSearchResultItem;
    readonly personPickerType: PersonType = "employee";

    ngOnInit() {
        const user = this.app.currentUser;

        this.canPickEmployee = user?.hasRight('HOSPITAL.SET_EMPLOYEE');
        // if (this.canPickEmployee)
        //     this.personPickerOpened = true;

        if (!this.personPickerOpened)
            this.init();
    }

    openSeminars(event: { trainer?: string, id?: string }) {
        const seminarsTab = this.tabs.find(t => t.id == 'seminars');

        if (seminarsTab) {
            this.activeTabId = this.tabs.indexOf(seminarsTab) + 1;

            if (event.trainer) {
                this.seminarTrainer = event.trainer;
            }

            if (event.id) {
                this.seminarId = event.id;
            }
        }
    }

    onTabChange(tab) {
        const seminarsTab = this.tabs.find(t => t.id == 'seminars');

        if (seminarsTab && tab.nextId != this.tabs.indexOf(seminarsTab) + 1) {
            this.seminarTrainer = undefined;
            this.seminarId = undefined;
        }
    }

    setPerson(person: IPersonSearchResultItem) {
        this.personPickerOpened = false;
        this.person = person;
        store.setPerson(person);
        this.init();
    }

    togglePersonPicker() {
        this.personPickerOpened = !this.personPickerOpened;
    }

    private init() {
        this.reset();

        this.selectedYearId = this.route.snapshot.queryParams['YearId'];

        if (this.app.currentUser.rights.includes('HOSPITAL.VIEW_STUDENTS')) {
            this.tabs.push({ id: 'students', title: 'hospitalDashboard_students' });
        }

        if (this.app.currentUser.rights.includes('HOSPITAL.VIEW_SEMINARS')) {
            this.tabs.push({ id: 'seminars', title: 'hospitalDashboard_seminars' });

            this.seminarId = this.route.snapshot.queryParams['SeminarId'];

            if (this.seminarId) {
                this.openSeminars({
                    id: this.seminarId
                });
            }
        }

        if (this.app.currentUser.rights.includes('HOSPITAL.VIEW_DOCTORS')) {
            this.tabs.push({ id: 'doctors', title: 'hospitalDashboard_doctors' });
        }

        if (this.app.currentUser.rights.includes('HOSPITAL.VIEW_ROTATIONS')) {
            this.tabs.push({ id: 'rotations', title: 'hospitalDashboard_rotations' });
        }

        this.tabsReady = true;

        if (this.tabs.length) {
            this.app.addLoading(this.service.get(store.getPerson()?.Upn)).subscribe(data => {
                this.companies = data;

                if (data.length == 1) {
                    this.selectedCompanyId = data[0].Id;
                }
            });

            this.app.addLoading(this.service.getAcademicYears(store.getPerson()?.Upn)).subscribe(data => {
                this.academicYears = data;

                const curr = data.find(t => t.IsCurrent);

                if (!this.selectedYearId) {
                    this.selectedYearId = curr?.Id;
                }
            });
        }
    }

    private reset() {
        this.tabs = [];
        this.tabsReady = false;
        this.activeTabId = 1;
        this.academicYears = [];
        this.companies = [];
        this.selectedCompanyId = null;
        this.selectedYearId = null;
        this.seminarId = null;
        this.seminarTrainer = null;
    }
}
