export interface ISurvey {
    SurveyType: 'SUB' | 'SP';
    SurveyName: string;
    SurveyLink: string;
    IsActual: boolean;
    IsFeedback: boolean;
    PlanningPeriodId?: string;
    PlanningPeriodName?: string;
    StartDate: Date;
    EndDate: Date;
    StatusCode: 'NOTBEGUN' | 'OPEN' | 'SUBMITTED' | 'CLOSED' | 'HASRESULTS';
    StatusName: string;
    TokensAll: number;
    TokensFinished: number;
    FeedbackLink: string;
    PreviousFeedbackLink: string;
    PreviousFeedbackLinkName: string;
    StudyProgramme: string;
    StudyProgrammeDirector: string;
    Faculty: string;
    Filling: number;
}

export interface ISurveyBegin {
    Survey: {
        ID: string,
        EvalObjID: string,
        EvalObjCode: string,
        Title: string,
        Description: string,
        Startup: Date,
        Finished: Date,
        Status: SurveyStatus,
        Type: SurveyType
    };
    Token: {
        Key: string,
        Value: string
    };
}

export interface ISurveyStart {
    Answers: ISurveyAnswer[];
    IsForced: boolean;
    Survey: {
        ID: string,
        Description: string,
        EvalObjCode: string,
        EvalObjID: string,
        Finished: Date,
        Startup: Date,
        Status: SurveyStatus,
        Title: string,
        Type: SurveyType
    };
    Questions: ISurveyQuestion[];
    QuestionBlocks: {
        ID: string,
        Sequence: number,
        Type: SurveyQuestionBlockType
    }[];
    QuestionGroups: ISurveyQuestionGroup[];
    Token: ISurveyToken;
}

export interface ISurveyAnswer {
    ID: string;
    SurveyID: string;
    TokenID: string;
    QuestionID: string;
    EvaluationgObject: string;//typo
    AssociatedObject: string;
    AnswerType: SurveyAnswerEvaluationType;
    AnswerValue: string;
    AnswerFreeForm: string;
    AnswerScope: string[];
}

export interface ISurveyQuestion {
    ID: string;
    Answers: {
        ID: string,
        PID: string,
        Title: string,
        DecimalValue: number,
        TextValue: string,
        QuestionComment: string,
        LinkWithEvaluatingObject: string
    }[];
    AssociatedObjects: ISurveyQuestionAssociatedObject[];
    Description: string;
    DisplayType?: SurveyQuestionDisplayType;
    EvaluationObjectBaseQuestion: string;
    GroupID: string;
    HasNoOpinion: boolean;
    Mandatory: boolean;
    Objects: ISurveyQuestionObject[];
    QID: string;
    QuestionEvaluationType?: SurveyQuestionEvaluationType;
    AnswerEvaluationType?: SurveyAnswerEvaluationType;
    Sequence?: number;
    Title: string;
}

export interface ISurveyQuestionObject {
    ID: string;
    Title: string;
    EntityName: string;
    EvalObjID: string;
}

export interface ISurveyQuestionAssociatedObject {
    ID: string;
    Title: string;
}

export interface ISurveyQuestionGroup {
    ID: string;
    Code: string;
    ComposeAllInOne: boolean;
    DependencyRules: ISurveyDependencyRule[];
    Description: string;
    Name: string;
    Questions: string[];
    QuestionsPrefixText: string;
    Sequence: number;
    Type: SurveyQuestionGroupType;
}

export interface ISurveyDependencyRule {
    ID: string;
    Order: number;
    ConditionInGroup: SurveyConditionInGroup;
    GroupsOperation: SurveyGroupsOperation;
    TestingAnswer: string;
    TestingOperator: SurveyTestingOperator;
    TestingValue: string;
}

export interface ISurveyToken {
    ID: string;
    EntityName: string;
    Status: SurveyTokenStatus;
    SurveyID: string;
    TokenID: string;
    Type: SurveyTokenType;
}

export enum SurveyStatus {
    Open,
    Finished,
    Preparing
}

export enum SurveyType {
    Anonymous,
    Tokenized,
    Hybrid
}

export enum SurveyTokenType {
    Anonymous,
    Defined,
    Hybrid
}

export enum SurveyTokenStatus {
    New,
    Started,
    Finished
}

export enum SurveyQuestionGroupType {
    Sequential,
    Dependent,
}

export enum SurveyConditionInGroup {
    And,
    Or
}

export enum SurveyGroupsOperation {
    And,
    Or
}

export enum SurveyTestingOperator {
    TextValueEqual,
    TextValueStartWith,
    TextValueContains,
    TextValueEndsWith,
    DecimalValueEqual,
    DecimalValueGreaterThan,
    DecimalValueLessThan
}

export enum SurveyAnswerEvaluationType {
    YesNo,
    OneToFive,
    OneToTen,
    OneToHundred,
    FreeFormText,
    OneFromScope,
    MultipleFromScope,
    Satisfaction,
    Level5
}

export enum SurveyQuestionEvaluationType {
    WithoutObject,
    ObjectBasedOnAnswer,
    ObjectList,
    ObjectListWithAssociations
}

export enum SurveyQuestionDisplayType {
    Radio,
    Combo
}

export enum SurveySatisifedType {
    FullySatisfied = 4,
    MoreSatisfiedThanDissatisfied = 3,
    MoreDissatisfiedThanSatisfied = 2,
    CompletelyDissatisfied = 1
}

export enum SurveyLevel5Type {
    Excellent = 5,
    Good = 4,
    Avarage = 3,
    BelowAverage = 2,
    Poor = 1
}

export enum SurveyQuestionBlockType {
    Question,
    GroupedQuestions
}

export const NoOpinionValue = 'N';
export const YesValue = '1';
export const NoValue = '0';
