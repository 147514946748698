import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FinalPaper, StudentData } from '../models/FinalPaper';

import { AuthService } from './auth.service';

import { environment as ENV } from '../../environments/environment';

/**
 * Final paper service
 */
@Injectable({ providedIn: "root" })
export class FinalPaperService {
    constructor(public http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/finalPapers` }

    getById(id: number): Observable<FinalPaper> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<FinalPaper>(url);
    }

    getBySubject(subjectId: string, email?: string): Observable<FinalPaper> {
        const url = `${this.apiUrl}/getBySubject(${subjectId})`;
        let params = email ? { params: { email } } : {};
        return this.http.get<FinalPaper>(url, params);
    }

    getStudentData(email?: string): Observable<StudentData[]> {
        const url = `${this.apiUrl}/studentData`;
        let params = email ? { params: { email } } : {};
        return this.http.get<StudentData[]>(url, params);
    }

    getChangeHistory(id: number): Observable<any[]> {
        const url = `${this.apiUrl}/${id}/history`;
        return this.http.get<any[]>(url);
    }

    getFileChangeHistory(id: number): Observable<any[]> {
        const url = `${this.apiUrl}/${id}/files/history`;
        return this.http.get<any[]>(url);
    }

    /**
     * Save final paper.
     * @param finalPaper
     */
    save(finalPaper: FinalPaper): Observable<number> {
        const isUpdate = !!finalPaper.Id;
        const url = this.apiUrl;

        let request = isUpdate
            ? this.http.put<number>(url + `/${finalPaper.Id}`, finalPaper)
            : this.http.post<number>(url, finalPaper);

        return request;
    }

    /**
     * Submit final paper.
     * @param finalPaper
     */
    submit(id: number): Observable<number> {
        const url = `${this.apiUrl}/${id}/submit`;
        return this.http.put<number>(url, {});
    }

    /**
     * Save final paper file.
     * @param finalPaperId
     * @param file
     * @param type
     */
    addFile(finalPaperId: number, file: File, type: 'FinalPaper' | 'Appendix' | 'Permission' = 'FinalPaper'): Observable<number> {
        const url = `${this.apiUrl}/${finalPaperId}/files`;
        const form = new FormData();

        form.append('file', file, file.name);

        return this.http.post<number>(url, form, { params: { type } });
    }

    /**
     * Remove final paper file.
     * @param fileId
     */
    removeFile(fileId: number): Observable<any> {
        const url = `${this.apiUrl}/files/${fileId}`;
        return this.http.delete(url).pipe(map(res => res));
    }

    getFileDownloadUrl(fileId: number): string {
        return `${this.apiUrl}/files/${fileId}/download?SessionKey=${this.auth.sessionKey}`;
    }

    getHistoryFileDownloadUrl(historyId: number): string {
        return `${this.apiUrl}/files/history/${historyId}/download?SessionKey=${this.auth.sessionKey}`;
    }

    getAffirmationDownloadUrl(finalPaperId: number): string {
        return `${this.apiUrl}/${finalPaperId}/affirmation?SessionKey=${this.auth.sessionKey}`;
    }

    getTurnitinEulaUrl(): string {
        return `${this.apiUrl}/turnitin/eula`;
    }
}
