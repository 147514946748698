import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserListItem } from '../models/UserInfo';

import { environment as ENV } from '../../environments/environment';

export const userStorageKey: string = 'rsu_eservices_user';
export const consentStorageKey: string = 'rsu_eservices_consent';

@Injectable({ providedIn: "root" })
export class UserService {
    constructor(private http: HttpClient) { }

    get apiUrl(): string { return `${ENV.apiUrl}/users` }

    findUsers(options?: any): Observable<UserListItem[]> {
        return this.http.get<UserListItem[]>(this.apiUrl + '/active', {
            params: options
        });
    }

    checkObligations(): Observable<{
        count?: number,
        message?: string,
        url?: string
    }> {
        const url = `${this.apiUrl}/checkObligations`;
        return this.http.get<{
            count?: number,
            message?: string,
            url?: string
        }>(url);
    }
}
