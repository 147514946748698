<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/log', text: 'log_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 class="h-md" [translate]="'log_title'"></h1>
    </header>

    <form #filterForm="ngForm">
        <div class="row">
            <div class="col-6">
                <div class="d-flex form-field">
                    <div class="required">
                        <app-date name="filterDate" [(ngModel)]="filterDate" [required]="true"
                                  [placeholder]="'log_lblDate' | translate" [max]="filterMaxDate"></app-date>
                    </div>

                    <div class="ms-3">
                        <input class="form-control w-100" [(ngModel)]="filterTimeFrom" name="filterTimeFrom" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$"
                               [placeholder]="'log_lblTimeFrom' | translate" [title]="'log_lblTimeFrom' | translate">
                    </div>

                    <div class="ms-3">
                        <input class="form-control w-100" [(ngModel)]="filterTimeTo" name="filterTimeTo" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$"
                               [placeholder]="'log_lblTimeTo' | translate" [title]="'log_lblTimeTo' | translate">
                    </div>
                </div>

                <div class="form-field">
                    <input class="form-control" [(ngModel)]="searchValue" name="searchValue" maxlength="100"
                           [placeholder]="'log_lblSearch' | translate" [title]="'log_lblSearch' | translate">
                </div>
            </div>

            <div class="col-4">
                <div class="form-field">
                    <app-select [options]="filterLevelOptions"
                                name="filterLevel"
                                [multiple]="true"
                                [placeholder]="'log_lblLevel' | translate"
                                [title]="'log_lblLevel' | translate"
                                [(ngModel)]="filterLevel">
                    </app-select>
                </div>

                <div class="form-field">
                    <app-select [options]="filterRequestMethodOptions"
                                name="filterRequestMethod"
                                [multiple]="true"
                                [placeholder]="'log_lblRequestMethod' | translate"
                                [title]="'log_lblRequestMethod' | translate"
                                [(ngModel)]="filterRequestMethod">
                    </app-select>
                </div>
            </div>

            <div class="col-2 form-field d-flex align-items-end">
                <button type="button" class="btn btn-outline" (click)="filter()" [disabled]="!filterForm.valid || isFiltering">
                    <i class="fi fi-rs-filter" *ngIf="!isFiltering"></i>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isFiltering"></span>
                    {{'filter' | translate}}
                </button>
            </div>
        </div>
    </form>

    <app-table [rows]="items" [columns]="columns" [(page)]="page" [pageSize]="20" [sticky]="'left'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>
                    <div class="table-actions">
                        <button (click)="showFullMessage(row);" type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'log_toggleRow' | translate">
                            <i class="fi fi-rs-document"></i>
                        </button>
                    </div>
                </td>
                <td>
                    <div>{{row.Date.split('T')[0]}}</div>
                    <div>{{row.Date.split('T')[1]}}</div>
                </td>
                <td [title]="row.Message">
                    <ng-container *ngIf="shouldSplitMessage(row); else fullMessage">
                        <div *ngFor="let n of splitMessage(row); let i = index" class="text-nowrap">
                            {{n}}
                        </div>
                    </ng-container>
                    <ng-template #fullMessage>
                        {{row.Message}}
                    </ng-template>
                </td>
                <td>
                    <span *ngIf="row.Level"
                          class="badge"
                          [title]="row.Level"
                          [class.text-bg-secondary]="row.Level == 'DEBUG'"
                          [class.text-bg-light]="row.Level == 'INFO'"
                          [class.text-bg-danger]="row.Level == 'ERROR'"
                          [class.text-bg-warning]="row.Level == 'WARN'"
                          [class.text-bg-dark]="row.Level == 'FATAL'">{{row.Level}}</span>
                </td>
                <td>
                    <div>{{row.UserName}}</div>
                    <small *ngIf="row.Impersonator">({{row.Impersonator}})</small>
                </td>
                <td [title]="row.RequestUrl">
                    <span class="badge"
                          [class.text-bg-light]="row.RequestMethod == 'GET'"
                          [class.text-bg-primary]="row.RequestMethod == 'POST'"
                          [class.text-bg-info]="row.RequestMethod == 'PUT'"
                          [class.text-bg-danger]="row.RequestMethod == 'DELETE'">{{row.RequestMethod}}</span>
                    <div class="text-truncate" style="max-width: 150px;">
                        {{row.RequestUrl}}
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
