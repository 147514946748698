<div class="wrapper-lg">
    <app-breadcrumbs [items]="[
                 { url: '/external/config', text: 'externalServiceConfig_listTitle' },
                 { url: '/external/config/' + (isNew ? 'new' : item?.Id), text: isNew ? 'externalServiceConfig_newTitle' : title }
                 ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{(isNew ? 'externalServiceConfig_newTitle' : 'externalServiceConfig_editTitle') | translate}}</h1>
    </header>

    <form #form="ngForm">
        <div class="d-flex">
            <div class="data-value">
                <label>{{'externalServiceConfig_lblStatus' | translate}}</label>
                <div>
                    <span *ngIf="item.Status">{{'externalServiceConfig_status' + item.Status | translate}}</span>
                </div>
            </div>

            <div class="data-value ms-5">
                <label>{{'externalServiceConfig_lblVersion' | translate}}</label>
                <div>
                    <span *ngIf="item.Version">{{item.Version}}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6 form-field required">
                <label class="form-label">{{'externalServiceConfig_lblCode' | translate}}</label>
                <input class="form-control" required name="Code" [(ngModel)]="model.Code" [disabled]="!isDraft" />
            </div>

            <div class="col-3 form-field required">
                <label class="form-label">{{'externalServiceConfig_lblDateFrom' | translate}}</label>
                <app-date [(ngModel)]="model.DateFrom" [max]="model.DateTo" [required]="true" name="dateTo"></app-date>
            </div>

            <div class="col-3 form-field">
                <label class="form-label">{{'externalServiceConfig_lblDateTo' | translate}}</label>
                <app-date [(ngModel)]="model.DateTo" [min]="model.DateFrom" name="dateFrom"></app-date>
            </div>
        </div>

        <div class="row">
            <div class="col form-field required">
                <label class="form-label">{{'externalServiceConfig_lblNameLV' | translate}}</label>
                <input class="form-control" required name="NameLV" [(ngModel)]="model.NameLV" [disabled]="!isDraft" />
            </div>

            <div class="col form-field required">
                <label class="form-label">{{'externalServiceConfig_lblNameEN' | translate}}</label>
                <input class="form-control" required name="NameEN" [(ngModel)]="model.NameEN" [disabled]="!isDraft" />
            </div>
        </div>

        <div class="form-field">
            <label class="form-label">{{'externalServiceConfig_formContent' | translate}}</label>
            <editor [(ngModel)]="model.Content" name="Content" [init]="editorConfig"></editor>
        </div>

        <app-alert level="info" *ngIf="howTo">
            <div [innerHTML]="howTo | safeHtml"></div>
        </app-alert>

        <div class="form-actions">
            <span class="form-actions-group">
                <button type="button" class="btn btn-secondary" (click)="export()">
                    <i class="fi fi-rs-download"></i>
                    {{'externalServiceConfig_btnExport' | translate}}
                </button>

                <button type="button" class="btn btn-secondary" (click)="importFile.click()">
                    <i class="fi fi-rs-upload"></i>
                    {{'externalServiceConfig_btnImport' | translate}}
                </button>
            </span>

            <span class="form-actions-group" *ngIf="isDeactivated || isPublished || (isDraft && !isNew)">
                <button type="button" class="btn btn-outline-primary" (click)="activate()" *ngIf="isDeactivated">
                    <i class="fi fi-rs-check"></i>
                    {{'externalServiceConfig_btnActivate' | translate}}
                </button>

                <button type="button" class="btn btn-outline-primary" (click)="deactivate()" *ngIf="isPublished">
                    <i class="fi fi-rs-ban"></i>
                    {{'externalServiceConfig_btnDeactivate' | translate}}
                </button>

                <button type="button" class="btn btn-outline-primary" (click)="publish()" *ngIf="isDraft && !isNew" [disabled]="!form.valid">
                    <i class="fi fi-rs-paper-plane"></i>
                    {{'externalServiceConfig_btnPublish' | translate}}
                </button>

                <button type="button" class="btn btn-outline-primary" (click)="unpublish()" *ngIf="isPublished">
                    <i class="fi fi-rs-undo"></i>
                    {{'externalServiceConfig_btnUnpublish' | translate}}
                </button>
            </span>

            <span class="form-actions-group">
                <button type="button" class="btn btn-outline" [routerLink]="['/external/config'] | localize">{{'externalServiceConfig_btnCancel' | translate}}</button>
                <button type="button" class="btn btn-primary" (click)="save()">{{'externalServiceConfig_btnSave' | translate}}</button>
            </span>
        </div>

        <input #importFile name="importFile" type="file" accept=".json,.txt" style="display: none;"
               (change)="import($event.target.files[0]); importFile.value = ''" />
    </form>
</div>
