<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'studentBalance_invoiceTitlePrefix' | translate}}{{item.Number}}</h4>
</div>

<div class="modal-body">
    <div class="data-value">
        <label>{{'studentBalance_lblInvoiceDate' | translate}}</label>
        <div>{{item.Date | appDate}}</div>
    </div>

    <div class="data-value">
        <label>{{'studentBalance_lblInvoiceReason' | translate}}</label>
        <div>{{item.Reason}}</div>
    </div>

    <div class="data-value">
        <label>{{'studentBalance_lblPaymentAmount' | translate}}</label>
        <div>{{item.Amount | number:numberFormat}}</div>
    </div>

    <div class="data-value">
        <label>{{'studentBalance_lblPaymentDueDate' | translate}}</label>
        <div>{{item.DueDate | appDate}}</div>
    </div>

    <div class="data-value">
        <label>{{'studentBalance_lblPaymentStatus' | translate}}</label>
        <div>{{status}}</div>
    </div>

    <app-table [rows]="item.Payments" [columns]="columns">
        <ng-template #rowTemplate let-row>
            <tr>
                <td class="hidden-md-up">
                    <div>{{row.Number}}</div>
                    <div class="text-sub">{{row.Date | appDate}}</div>
                </td>
                <td class="hidden-sm-down">{{row.Number}}</td>
                <td class="hidden-sm-down">{{row.Date | appDate}}</td>
                <td class="hidden-sm-down">{{row.Amount | number:numberFormat}}</td>
                <td class="hidden-md-down">{{row.AssignedAmount | number:numberFormat}}</td>
                <td class="hidden-md-down">{{row.Payer}}</td>
                <td>
                    <div class="table-actions">
                        <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)" [ngbTooltip]="'studentBalance_showDetails' | translate">
                            <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                        </button>
                    </div>
                </td>
            </tr>
            <tr *ngIf="isExpanded(row)" class="table-row-details">
                <td colspan="9">
                    <div class="dl">
                        <div class="hidden-md-up">
                            <span [translate]="'studentBalance_lblInvoiceAmount'"></span>
                            <span>{{row.Amount | number:numberFormat}}</span>
                        </div>

                        <div class="hidden-lg-up">
                            <span [translate]="'studentBalance_lblPaymentAssignedAmount'"></span>
                            <span>{{row.AssignedAmount | number:numberFormat}}</span>
                        </div>

                        <div class="hidden-lg-up">
                            <span [translate]="'studentBalance_lblPayer'"></span>
                            <span>{{row.Payer}}</span>
                        </div>

                        <div>
                            <span [translate]="'studentBalance_lblInvoiceReason'"></span>
                            <span>{{row.Reason}}</span>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">{{'dialog_close' | translate}}</button>
</div>
