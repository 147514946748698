<div class="app-page" id="app-page">
    <app-header></app-header>

    <main class="app-body" id="app-body">
        <div class="app-progress" *ngIf="isLoading">
            <div class="app-progress-bar"></div>
        </div>

        <div class="app-curtain" *ngIf="isLoading"></div>

        <div id="app-container" class="container" *ngIf="isReady">
            <router-outlet></router-outlet>
        </div>
    </main>

    <div class="app-toast" [class.in]="toastVisible">
        <ngb-toast [autohide]="false">{{toast}}</ngb-toast>
    </div>
</div>

<app-footer></app-footer>
