<section class="block">
    <h5>{{'print_lblPay' | translate}}</h5>

    <app-alert level="success" *ngIf="lastAmountAdded">
        {{lastAmountAdded}}
    </app-alert>

    <form #form="ngForm">
        <div class="row">
            <div class="form-field col-sm-6">
                <label class="form-label">{{'print_lblSum' | translate}}</label>
                <div class="fc-icons-end" [class.is-invalid]="!amountInput.valid">
                    <input class="form-control" name="amountInput" [(ngModel)]="amountStr" required type="text"
                           pattern="^[0-9]+([.,][0-9]{1,2})?$" (change)="onAmountChange($event)" #amountInput="ngModel">
                    <i class="fc-icon-end">&euro;</i>
                </div>
                <div class="feedback">{{minimalAmountText}}</div>
                <div class="invalid-feedback" *ngIf="!amountInput.valid">{{'print_amountError' | translate}}</div>
            </div>

            <div class="col-sm-6">
                <label class="form-label hidden-xs-down">&nbsp;</label>
                <button class="btn btn-primary"
                        disabled
                        *ngIf="!form.valid || !parseAmount(amountStr) || parseAmount(amountStr) < minAmount; else activePayButton">
                    {{'print_lblPayButton' | translate}}
                </button>
                <ng-template #activePayButton>
                    <a class="btn btn-primary"
                       [routerLink]="['/print-v2/pay'] | localize"
                       [queryParams]="{ amount: parseAmount(amountStr) }"
                       href="#"
                       target="_blank"
                       (click)="clearAmount()">
                        {{'print_lblPayButton' | translate}}
                    </a>
                </ng-template>
            </div>
        </div>
    </form>
</section>

<form [action]="payFormUrl" method="post" #payForm>
    <input name="payment_data" type="hidden" [value]="payData">
</form>
