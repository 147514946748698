<div class="modal-header">
    <h4 class="modal-title">{{(id ? 'edit' : 'add') | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="form-field required">
            <label for="role" class="form-label">{{'permissions_lblRoleChoose' | translate}}</label>
            <app-select [options]="roles"
                        [name]="'role'"
                        [displayProperty]="'Value'"
                        [required]="true"
                        [filter]="true"
                        [filterFn]="classifierFilterFn"
                        [(ngModel)]="role">
                <ng-template #optionTemplate let-option>
                    <div>{{option.Value}}</div>
                    <div class="text-sub">{{option.Code}}</div>
                </ng-template>
            </app-select>
            <div class="feedback" *ngIf="role?.Code">{{role.Code}}</div>
        </div>

        <div class="form-field required">
            <label for="permission" class="form-label">{{'permissions_lblPermissionChoose' | translate}}</label>
            <app-select [options]="permissions"
                        [name]="'permission'"
                        [displayProperty]="'Value'"
                        [required]="true"
                        [filter]="true"
                        [filterFn]="classifierFilterFn"
                        [(ngModel)]="permission">
                <ng-template #optionTemplate let-option>
                    <div>{{option.Value}}</div>
                    <div class="text-sub">{{option.Code}}</div>
                </ng-template>
            </app-select>
            <div class="feedback" *ngIf="permission?.Code">{{permission.Code}}</div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'save' | translate}}
    </button>
</div>
