import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { PurchaseType } from '../../models/Purchase';
import { UserInfo } from '../../models/UserInfo';
import { AppService } from '../../services/app.service';
import { ClassifierService } from '../../services/classifier.service';

interface IPurchaseListItem {
    name: string;
    title?: string;
}

const cache: { types?: IPurchaseListItem[] } = {};

export function getAvailablePurchaseTypes(user: UserInfo): PurchaseType[] {
    const types = Object.keys(PurchaseType).map(t => PurchaseType[t]).filter(t => typeof t === 'string');
    const rights = user.rights || [];

    return types.filter(t => rights.indexOf(`PURCHASE.${t}.VIEW`) > -1);
}

@Component({
    selector: 'app-purchase-index-body',
    templateUrl: './index-body.component.html',
    styleUrls: ['./purchase.component.scss']
})
export class IndexBodyComponent implements OnInit {
    constructor(private app: AppService, private classifiers: ClassifierService) { }

    @Input() showNoItemAvailable: boolean;
    @Input() showNav: boolean;

    types: IPurchaseListItem[] = [];
    ready: boolean;

    ngOnInit() {
        const langEn = this.app.currentLanguage === 'en';

        this.types = getAvailablePurchaseTypes(this.app.currentUser).map(t => {
            return {
                name: t
            };
        });

        if (cache.types) {
            this.types = cache.types;
            this.ready = true;
        } else {
            this.app.addLoading(this.classifiers.get('PurchaseType')).subscribe(data => {
                this.types = cache.types = getAvailablePurchaseTypes(this.app.currentUser).map(t => {
                    const clTitle = data.find(n => n.Code == `PurchaseType${t}`);
                    const title = clTitle ? (langEn ? clTitle.ValueEN : clTitle.Value) : t;

                    return {
                        name: t,
                        title
                    };
                });

                this.ready = true;
            });
        }
    }
}
