<div class="row">
    <div class="form-field col">
        <label class="form-label">{{'finalPaperProtocol_lblSearch' | translate}}</label>
        <input name="search" class="form-control" [(ngModel)]="searchValue" (input)="search()">
    </div>

    <div class="form-field col">
        <label class="form-label">{{'finalPaperProtocol_lblAcademicYear' | translate}}</label>
        <app-select [(ngModel)]="selectedYear"
                    [options]="options.year"
                    (selectionChange)="filter()"
                    name="year">
        </app-select>
    </div>

    <div class="form-field col">
        <label class="form-label">{{'finalPaperProtocol_lblType' | translate}}</label>
        <app-select [(ngModel)]="selectedType"
                    [options]="options.type"
                    [displayProperty]="'text'"
                    [valueProperty]="'value'"
                    (selectionChange)="filter()"
                    name="type">
        </app-select>
    </div>
</div>

<app-table [rows]="items" [columns]="columns" [sticky]="'right'">
    <ng-template #rowTemplate let-row>
        <tr>
            <td>{{row.AcademicYearName}}</td>
            <td>{{row.Name}}</td>
            <td>{{'finalPaperProtocol_type' + row.TypeGroupCode | translate}}</td>
            <td>{{row.RegNumber}}</td>
            <td>
                <div class="table-actions">
                    <button type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'open' | translate" (click)="open(row)">
                        <i class="fi fi-rs-document"></i>
                    </button>
                </div>
            </td>
        </tr>
    </ng-template>
</app-table>
