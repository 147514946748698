import { Component, OnInit } from '@angular/core';

import { IPermission } from '../../models/Permission';
import { Classifier } from '../../models/Classifier';

import { PermissionsService } from '../../services/permissions.service';
import { AppService } from '../../services/app.service';
import { ClassifierService } from '../../services/classifier.service';
import { ActivatedRoute } from '@angular/router';

import { ITableColumn } from '../../shared/table/table.component';
import { PermissionEditComponent } from './permission-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface IRow extends IPermission {
    roleValue: string;
    permissionValue: string;
}

@Component({
    selector: 'app-permission-list',
    templateUrl: './permission-list.component.html'
})
export class PermissionListComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: PermissionsService,
        private classifierService: ClassifierService,
        private route: ActivatedRoute,
        private modal: NgbModal
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'roleValue', label: 'permissions_lblRole', sorts: true },
        { property: 'permissionValue', label: 'permissions_lblPermission', sorts: true },
        { width: '1px' }
    ];

    items: IRow[] = [];
    roles: Classifier[] = [];
    permissions: Classifier[] = [];
    permissionGroups: string[] = [];
    filterPermissionGroup: string;
    filterRole: Classifier;
    page = 1;
    
    readonly roleFilterFn = (option: Classifier, term: string) => option.Value.toLowerCase().includes(term.toLowerCase());

    private allItems: IRow[] = [];

    ngOnInit() {
        this.app.addLoading(this.classifierService.get('UserRole,Permissions')).subscribe(data => {
            this.roles = data.filter(t => t.Type == 'UserRole').sort((a, b) => {
                return a.Value?.localeCompare(b.Value);
            });

            this.permissions = data.filter(t => t.Type == 'Permissions').sort((a, b) => {
                return a.Code > b.Code ? 1 : a.Code < b.Code ? -1 : 0;
            });

            this.permissionGroups = this.permissions.reduce((arr, n) => {
                const group = n.Code.split('.')[0];

                if (!arr.includes(group)) arr.push(group);

                return arr;
            }, []);

            this.loadItems();
        });

        this.route.params.subscribe(params => {
            const role = params['role'];
            const userRole = this.roles.find(ur => ur.Code.toLowerCase() == role);

            if (userRole) {
                this.filterRole = userRole;
                this.filter();
            }
        });
    }

    add() {
        this.edit(<IRow>{});
    }

    edit(item: IRow) {
        if (item.Id) {
            item = JSON.parse(JSON.stringify(item));
        }

        const ref = this.modal.open(PermissionEditComponent);

        ref.componentInstance.data = item;
        ref.componentInstance.roles = this.roles;
        ref.componentInstance.permissions = this.permissions;
        ref.result.then(() => {
            this.loadItems(true);
        }, () => { });
    }

    delete(item: IRow) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.loadItems(true);
            });
        });
    }

    filter() {
        if (!this.filterRole && !this.filterPermissionGroup) {
            this.items = [...this.allItems];
        } else {
            const role = this.filterRole?.Id;
            const group = this.filterPermissionGroup?.toLowerCase();

            this.items = this.allItems.filter(t => {
                return (!role || t.Role?.Id == role)
                    && (!group || t.Permission.Code.toLowerCase().startsWith(group));
            });
        }

        this.page = 1;
    }

    private loadItems(applyFilter?: boolean) {
        this.app.addLoading(this.service.get()).subscribe(data => {
            this.allItems = data.map(t => {
                return {
                    ...t,
                    roleValue: t.Role?.Value,
                    permissionValue: t.Permission?.Value
                }
            });
            this.items = this.allItems;

            if (applyFilter) this.filter();
        });
    }
}
