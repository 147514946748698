import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';

import { IticService } from '../../services/itic.service';
import { ParameterService } from '../../services/parameter.service';
import { Message } from '../../models/Message';
import { ITICData, IticHistory } from '../../models/Itic';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from '../../services/message.service';
import { AppDatePipe } from '../../pipes/date.pipe';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
  selector: 'app-itic',
  templateUrl: './itic.component.html',
  styleUrls: ['./itic.component.scss']
})
export class IticComponent implements OnInit {
    constructor(
        private service: IticService,
        private param: ParameterService,
        public app: AppService,
        public messages: MessageService,
        public sanitization: DomSanitizer,
        private appDate: AppDatePipe
    ) {
    }

    history: IticHistory[] = [];
    personDisplay = [];
    personDisplayRows = [];
    personInfo: ITICData;
    personInfoValid: boolean = false;
    iticAccepted: boolean = false;
    showPicture: string;
    pageSize: number = 5;
    iticError: string = '';
    iticRulesMessageCode = 'ITIC_RULES';
    iticRulesMessage: Message;
    isHistoryLoaded: boolean = false;
    historyPage: number = 1;
    isInfoLoaded: boolean = false;
    isReady: boolean;
    neededStatusses = [1, 2, 7];
    waitPageUrl: string = '/?showWaitMessage=itic_pleaseWaitForIticRedirect';
    showPersonAttributes = ['firstName', 'lastName', 'personCode', 'email', 'personPhoneNumber'];

    unavailableText: string;
    isUnavailable: boolean;

    beforeText: string;
    afterText: string;

    readonly historyColumns: ITableColumn[] = [
        { label: 'itic_orderNumber', cssClass: 'hidden-md-down' },
        { label: 'itic_applicationDate' },
        { label: 'itic_applicationInfo', cssClass: 'hidden-sm-down' },
        { width: '1px', cssClass: 'hidden-md-up' },
    ];

    private expanded: IticHistory[] = [];

    ngOnInit() {
        const user = this.app.currentUser;

        const isEn = this.app.currentLanguage === 'en';

        this.app.addLoading(this.messages.getByCodes(['ITIC_UNAVAILABLE', this.iticRulesMessageCode])).subscribe(data => {
            const unav = data.find(t => t.Code === 'ITIC_UNAVAILABLE');
            this.unavailableText = unav ? isEn ? unav.TextEN : unav.TextLV : undefined;

            const rules = data.find(t => t.Code === 'ITIC_RULES');
            this.iticRulesMessage = data.find(t => t.Code === 'ITIC_RULES');
        });

        this.app.addLoading(this.messages.getITICTextWrap(this.app.currentLanguage)).subscribe(data => {
            this.beforeText = data.find(t => t.Code === 'ITIC_BEFORE_TEXT').Text;
            this.afterText = data.find(t => t.Code === 'ITIC_AFTER_TEXT').Text;
        });

        this.waitPageUrl = '/' + this.app.currentLanguage + this.waitPageUrl;

        this.init();
    }

    // setStudent(student: StudentSearchResult) {
    //     this.showStudentPicker = false;
    //     this.student = student;
    //     this.init();
    // }

    getHistory(forcelastpage: boolean) {
        this.app.addLoading(this.param.getValues()).subscribe(params => {
            if (params && params.find(p => p.Code == 'ITICHistoryPageSize')) {
                this.pageSize = +params.find(p => p.Code == 'ITICHistoryPageSize').Value;
            }

            this.app.addLoading(this.service.getHistory()).subscribe((history) => {
                this.history = history;
                this.isHistoryLoaded = true;
                this.personInfoValid = this.isValidPersonInfo();
                if (forcelastpage) {
                    let mod = this.history.length % this.pageSize;
                    let page = Math.floor(this.history.length / this.pageSize);
                    if (mod > 0)
                        page += 1;

                    this.historyPage = page;
                }
            });
        });
    }

    sendData() {
        var iticWindow = window.open(this.waitPageUrl, "_blank");
        iticWindow.blur();
        window.focus();
        this.app.addLoading(this.service.sendData()).subscribe((result) => {
            if (result && result.registrationUrl && result.success) {
                this.iticError = '';
                this.getHistory(true);

                iticWindow.location.href = result.registrationUrl;
                iticWindow.focus();
            }
            else if (result.message) {
                iticWindow.close();
                var msg = result.message;
                if (this.app.translate(msg))
                    msg = this.app.translate(msg);
                this.getHistory(true);
                this.iticError = this.app.translate('itic_sendHandledError').replace('{errorText}', msg);
            }
            else {
                iticWindow.close();
                this.getHistory(true);
                this.iticError = this.app.translate('sendError');

            }
        }, err => {
            iticWindow.close();
            this.app.showError(err);
        });
    }

    openRules(e) {
        e.preventDefault();
        let msgTxt = this.iticRulesMessage.TextLV;
        if (this.app.currentLanguage == 'en')
            msgTxt = this.iticRulesMessage.TextEN;
        this.app.alert.info(msgTxt, this.app.translate('itic_rulesTitle'));
    }

    isValidPersonInfo() {
        this.iticError = '';
        if (this.isInfoLoaded && this.isHistoryLoaded) {

            let missingInfo = this.validatePersonInfo(this.personInfo, this.personDisplay);
            if (missingInfo == '') {
                return true;
            }
            else {
                if (missingInfo == 'notfound')
                    this.iticError = this.app.translate('itic_missingPersonInfo');
                else
                    this.iticError = this.app.translate('itic_missingPersonInfo')
                        + ' ' + this.app.translate('itic_missingPersonFields') + missingInfo;
                return false;
            }
        }
        else
            return false;
    }

    validatePersonInfo(person: ITICData, display: any[]): string {
        let missingInfo = '';
        if (person) {
            if (!person.picture || person.picture == '')
                missingInfo = this.app.translate('itic_picture');
            var emptyProps = display.filter((d, i) => {
                if ((d.value && d.value != '') ||
                    d.label == this.app.translate('itic_personPhoneNumber'))
                    return false;
                else
                    return true;
            });
            for (let i = 0; i < emptyProps.length; i++) {
                missingInfo += (missingInfo != '') ? ', ' + emptyProps[i].label : emptyProps[i].label;
            }
            return missingInfo;
        }
        return 'notfound';
    }

    toggle(row: IticHistory) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: IticHistory): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    private init() {
        this.app.addLoading(this.service.getITICData()).subscribe((info) => {
            this.personDisplay = [];

            if (info) {
                this.personInfo = info;
                for (var attr in info) {
                    let obj = { label: this.app.translate('itic_' + attr), value: info[attr] };
                    if (this.showPersonAttributes.indexOf(attr) > -1) {
                        this.personDisplay.push(obj)
                    }
                }

                this.personDisplayRows = [];

                const allFields = [...this.personDisplay];
                allFields.push({
                    label: this.app.translate('itic_birthDate'),
                    value: this.appDate.transform(info.birthDate)
                });

                let personDisplayRow = [];

                allFields.forEach(t => {
                    personDisplayRow.push(t);

                    if (personDisplayRow.length == 2) {
                        this.personDisplayRows.push([...personDisplayRow]);
                        personDisplayRow = [];
                    }
                });

                if (personDisplayRow.length) {
                    this.personDisplayRows.push([...personDisplayRow]);
                }

                if (info.picture)
                    this.showPicture = 'data:image/jpg;base64,' + info.picture;

                this.isInfoLoaded = true;
                this.personInfoValid = this.isValidPersonInfo();
            } else {
                this.isUnavailable = true;
            }

            this.isReady = true;
        }, err => {
            this.isReady = true;
            this.isUnavailable = true;
        });

        this.getHistory(false);
    }
}
