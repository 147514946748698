import { Input, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Classifier } from '../../../models/Classifier';
import { ICourseEqualizationFile, ICourseEqualizationInner, ICourseEqualizationOuter } from '../../../models/CourseEqualization';
import { IResidencyCourse } from '../../../models/Residency';
import { AppService } from '../../../services/app.service';
import { CourseEqualizationService } from '../../../services/course-equalization.service';
import { createFileStub } from '../../../shared/file/file.component';

import { util } from '../shared';

export interface IOuterCourseEditOptions {
    innerCourses: IResidencyCourse[];
    grades: Classifier[];
    courseTypes: Classifier[];
    attachmentExtensions: string;
    attachmentMaxSize: number;
    disabled: boolean;
}

enum TypeCode {
    RsuStudyProgramme = 'RsuStudyProgramme',
    Other = 'Other',
    Erasmus = 'Erasmus',
    OtherStudyProgramme = 'OtherStudyProgramme'
}

@Component({
    selector: 'app-course-equalization-outer',
    templateUrl: './course-equalization-outer.component.html'
})
export class CourseEqualizationOuterComponent {
    constructor(
        private app: AppService,
        private service: CourseEqualizationService,
        private activeModal: NgbActiveModal
    ) { }
    
    @Input() innerCourse: IResidencyCourse;

    @Input() set options(value: IOuterCourseEditOptions) {
        this.innerCourseOptions = value.innerCourses || [];
        this.gradeOptions = value.grades || [];
        this.typeOptions = value.courseTypes || [];
        this.attachmentExtensions = value.attachmentExtensions || '';
        this.attachmentMaxSize = value.attachmentMaxSize || 0;
        this.disabled = value.disabled;
    }

    @Input() set data(value: ICourseEqualizationOuter) {
        this._data = value;

        const files = value.Attachments.map(t => t.File);

        value.Attachments.forEach(t => {
            t.File = null;
        });

        this.type = this.typeOptions.find(t => t.Id == value.TypeId);

        this.model = JSON.parse(JSON.stringify(value));
        this.model.Attachments = value.Attachments.map((t, i) => {
            return {
                File: files[i] || createFileStub(t.FileName),
                FileName: t.FileName,
                Id: t.Id
            };
        });

        value.Attachments.forEach((t, i) => {
            t.File = files[i];
        });

        if (this.type) {
            this.onTypeChange(this.type);
        }
    }

    get data() {
        return this._data;
    }

    model: ICourseEqualizationOuter = <ICourseEqualizationOuter>{
        Attachments: []
    };
    type: Classifier;
    innerCourseOptions: IResidencyCourse[] = [];
    gradeOptions: Classifier[] = [];
    typeOptions: Classifier[] = [];

    attachmentExtensions: string = '';
    attachmentMaxSize: number = 0;
    universityVisible: boolean;
    programmeVisible: boolean;
    notesVisible: boolean;
    disabled: boolean;

    readonly pointValidationPattern = '^[1-9][0-9]{0,2}(\.[0-9][0-9]?)?$';
    readonly courseDisplayFn = (option: IResidencyCourse) => `${option.Code} ${option.Name}`;
    readonly courseFilterFn = (option: IResidencyCourse, term: string) => {
        term = term.toLowerCase()
        return option.Name.toLowerCase().includes(term)
            || option.Code.toLowerCase().includes(term);
    }

    get isValid(): boolean {
        return this.form.valid && this.model.Attachments.filter(t => t.FileName).length > 0;
    }

    @ViewChild('form', { static: true }) private form: NgForm;

    private _data: ICourseEqualizationOuter = <ICourseEqualizationOuter>{};

    onTypeChange(option: Classifier) {
        this.universityVisible = option.Code == TypeCode.OtherStudyProgramme || option.Code === TypeCode.Erasmus;
        this.programmeVisible = option.Code == TypeCode.RsuStudyProgramme || option.Code == TypeCode.OtherStudyProgramme || option.Code === TypeCode.Erasmus;
        this.notesVisible = option.Code == TypeCode.Other || option.Code === TypeCode.Erasmus;

        if (!this.universityVisible) this.model.University = null;
        if (!this.programmeVisible) this.model.Programme = null;
        if (!this.notesVisible) this.model.Notes = null;

        this.model.Type = option.Value;
        this.model.TypeId = option.Id;
    }

    ok() {
        this.model.TheoryGrade = this.gradeOptions.find(t => t.Id == this.model.TheoryGradeId)?.Value;
        this.model.PracticeGrade = this.gradeOptions.find(t => t.Id == this.model.PracticeGradeId)?.Value;

        this.activeModal.close({
            innerCourse: this.innerCourse,
            outerCourse: this.model
        });
    }

    cancel() {
        this.activeModal.dismiss();
    }

    addAttachment() {
        this.model.Attachments.push(<ICourseEqualizationFile>{});
    }

    removeAttachment(file: ICourseEqualizationFile) {
        let i = this.model.Attachments.indexOf(file);

        if (i > -1)
            this.model.Attachments.splice(i, 1);
    }

    downloadAttachment(attachment: ICourseEqualizationFile) {
        util.downloadAttachment(this.app, this.service, attachment);
    }

    onFileChange(event: File, file: ICourseEqualizationFile) {
        if (!event) {
            this.removeAttachment(file);
        } else {
            file.Id = null;
            file.FileName = event.name;
        }
    }

    checkDuplicateFileName(file: ICourseEqualizationFile): boolean {
        if (!file.File) return false;
        return this.model.Attachments.filter(t => t.FileName == file.File.name).length > 1;
    }
}
