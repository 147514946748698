import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';

import { IsicService } from '../../services/isic.service';
import { ParameterService } from '../../services/parameter.service';
import { PersonService } from '../../services/person.service';
import { Message } from '../../models/Message';
import { PersonStudentInfo, IsicHistory } from '../../models/Isic';
import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { AppDatePipe } from '../../pipes/date.pipe';
import { ITableColumn } from '../../shared/table/table.component';

@Component({
    selector: 'app-isic',
    templateUrl: './isic.component.html',
    styleUrls: ['./isic.component.scss']
})
export class IsicComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: IsicService,
        private messages: MessageService,
        private param: ParameterService,
        private route: ActivatedRoute,
        private studentService: PersonService,
        private appDate: AppDatePipe
    ) {
    }

    studentDisplay = [];
    studentDisplayRows = [];
    personInfo: PersonStudentInfo;
    personInfoValid: boolean = false;
    isicAccepted: boolean = false;
    showPicture: string;
    pageSize: number = 5;
    isicError: string = '';
    isicRulesMessageCode = 'ISIC_RULES';
    isicRulesMessage: Message;
    isHistoryLoaded: boolean = false;
    isInfoLoaded: boolean = false;
    neededStatusses = [1, 2, 7];
    waitPageUrl: string = '/?showWaitMessage=isic_pleaseWaitForIsicRedirect';
    showPersonAttributes = ['firstName', 'lastName', 'personCode', 'email', 'personPhoneNumber', 'studyType'];
    student: IPersonSearchResultItem;
    showStudentPicker: boolean;
    canPickStudent: boolean;
    history: IsicHistory[] = [];
    historyPage: number = 1;
    isReady: boolean;

    unavailableText: string;
    isUnavailable: boolean;

    readonly historyColumns: ITableColumn[] = [
        { label: 'isic_orderNumber', cssClass: 'hidden-md-down' },
        { label: 'isic_applicationDate' },
        { label: 'isic_applicationInfo', cssClass: 'hidden-sm-down' },
        { width: '1px', cssClass: 'hidden-md-up' },
    ];

    private expanded: IsicHistory[] = [];

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf('ISIC.SET_STUDENT') > -1;
        }

        const isEn = this.app.currentLanguage === 'en';

        this.app.addLoading(this.messages.getByCodes(['ISIC_UNAVAILABLE', this.isicRulesMessageCode])).subscribe(data => {
            const unav = data.find(t => t.Code === 'ISIC_UNAVAILABLE');
            this.unavailableText = unav ? isEn ? unav.TextEN : unav.TextLV : undefined;

            const rules = data.find(t => t.Code === 'ISIC_RULES');
            this.isicRulesMessage = data.find(t => t.Code === 'ISIC_RULES');
        });

        this.waitPageUrl = '/' + this.app.currentLanguage + this.waitPageUrl;

        if (this.canPickStudent) {
            const queryUpn = this.route.snapshot.queryParams['id'];

            if (queryUpn) {
                // can only view for students other than self
                //this.canSubmit = false;

                this.app.addLoading(this.studentService.findStudents('student_id', queryUpn)).subscribe(data => {
                    if (data.length) {
                        this.setStudent(data[0]);
                    } else {
                        this.app.showError(this.app.translate('studentNotFound'));
                    }
                });
            } else {
                this.showStudentPicker = true;
            }
        } else {
            this.init();
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.showStudentPicker = false;
        this.student = student;
        this.init();
    }

    getHistory(forcelastpage: boolean) {
        this.app.addLoading(this.param.getValues()).subscribe(params => {
            if (params && params.find(p => p.Code == 'ISICHistoryPageSize'))
                this.pageSize = +params.find(p => p.Code == 'ISICHistoryPageSize').Value;

            this.app.addLoading(this.service.getHistory(this.getUserEmail())).subscribe((history) => {
                this.history = history;
                this.isHistoryLoaded = true;
                this.personInfoValid = this.isValidPersonInfo();
                if (forcelastpage) {
                    let mod = this.history.length % this.pageSize;
                    let page = Math.floor(this.history.length / this.pageSize);
                    if (mod > 0)
                        page += 1;

                    this.historyPage = page;
                }
            });
        });
    }

    sendData() {
        var isicWindow = window.open(this.waitPageUrl, "_blank");
        isicWindow.blur();
        window.focus();
        this.app.addLoading(this.service.sendData(this.getUserEmail())).subscribe((result) => {
            if (result && result.registrationUrl && result.success) {
                this.isicError = '';
                this.getHistory(true);

                isicWindow.location.href = result.registrationUrl;
                isicWindow.focus();
            }
            else if (result.message) {
                isicWindow.close();
                var msg = result.message;
                if (this.app.translate(msg))
                    msg = this.app.translate(msg);
                this.getHistory(true);
                this.isicError = this.app.translate('isic_sendHandledError').replace('{errorText}', msg);
            }
            else {
                isicWindow.close();
                this.getHistory(true);
                this.isicError = this.app.translate('sendError');

            }
        }, err => {
            isicWindow.close();
            this.app.showError(err);
        });
    }

    openRules(e) {
        e.preventDefault();
        let msgTxt = this.isicRulesMessage.TextLV;
        if (this.app.currentLanguage == 'en')
            msgTxt = this.isicRulesMessage.TextEN;
        this.app.alert.info(msgTxt, this.app.translate('isic_rulesTitle'));
    }

    isValidPersonInfo() {
        this.isicError = '';
        
        if (this.isInfoLoaded && this.isHistoryLoaded) {
            let missingInfo = this.validatePersonInfo(this.personInfo, this.studentDisplay);
            if (missingInfo == '') {
                if (!this.personInfo.status || this.neededStatusses.indexOf(this.personInfo.status) === -1) {
                    this.isicError = this.app.translate('isic_wrongStudentStatus');
                    return false;
                }
                return true;
            }
            else {
                if (missingInfo == 'notfound')
                    this.isicError = this.app.translate('isic_missingPersonInfo');
                else
                    this.isicError = this.app.translate('isic_missingPersonInfo')
                        + ' ' + this.app.translate('isic_missingPersonFields') + missingInfo;
                return false;
            }
        }
        else
            return false;
    }

    validatePersonInfo(person: PersonStudentInfo, display: any[]): string {
        let missingInfo = '';
        if (person) {
            if (!person.picture || person.picture == '')
                missingInfo = this.app.translate('isic_picture');
            var emptyProps = display.filter((d, i) => {
                if (d.value && d.value != '')
                    return false;
                else
                    return true;
            });
            for (let i = 0; i < emptyProps.length; i++) {
                missingInfo += (missingInfo != '') ? ', ' + emptyProps[i].label : emptyProps[i].label;
            }
            return missingInfo;
        }
        return 'notfound';
    }

    toggle(row: IsicHistory) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: IsicHistory): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    private init() {
        this.app.addLoading(this.service.getStudentInfo(this.getUserEmail())).subscribe((info) => {
            this.studentDisplay = [];

            if (info) {
                this.personInfo = info;

                for (var attr in info) {
                    let obj = { label: this.app.translate('isic_' + attr), value: info[attr] };
                    if (attr == 'studyType') {
                        obj.value = this.app.translate('isic_' + info[attr]);
                        this.studentDisplay.push(obj);
                    }
                    else if (this.showPersonAttributes.indexOf(attr) > -1) {
                        this.studentDisplay.push(obj)
                    }
                }

                this.studentDisplayRows = [];

                const allFields = [...this.studentDisplay];
                allFields.push({
                    label: this.app.translate('isic_birthDate'),
                    value: this.appDate.transform(info.birthDate)
                });
                
                let studentDisplayRow = [];

                allFields.forEach(t => {
                    studentDisplayRow.push(t);

                    if (studentDisplayRow.length == 2) {
                        this.studentDisplayRows.push([...studentDisplayRow]);
                        studentDisplayRow = [];
                    }
                });

                if (studentDisplayRow.length) {
                    this.studentDisplayRows.push([...studentDisplayRow]);
                }

                if (info.picture) {
                    this.showPicture = 'data:image/jpg;base64,' + info.picture;
                }
                this.isInfoLoaded = true;
                this.personInfoValid = this.isValidPersonInfo();
            } else {
                this.isUnavailable = true;
            }

            this.isReady = true;
        }, err => {
            this.isReady = true;
            this.isUnavailable = true;
        });

        this.getHistory(false);
    }

    private getUserEmail(): string {
        return this.student ? this.student.Email : undefined;
    }
}
