<header class="site-header">
    <div class="site-header-top">
        <div class="container">
            <div class="wrapper-lg">
                <div class="site-header-top-body">
                    <div class="site-header-top-area site-header-top-left">
                        <div class="site-header-top-item hidden-md-up">
                            <a href="#" class="site-nav-toggle" (click)="$event.preventDefault(); toggleMenu()">
                                <i class="fi fi-rs-menu-burger"></i>
                            </a>

                            <ul *ngIf="menuOpened" class="site-menu site-nav-sub">
                                <li>
                                    <a href="{{myRsuSiteUrl}}" target="_blank">
                                        {{'nav_myRsu' | translate}}
                                    </a>
                                </li>

                                <li>
                                    <a href="{{eStudiesSiteUrl}}" target="_blank">
                                        {{'nav_eStudies' | translate}}
                                    </a>
                                </li>

                                <li>
                                    <a [routerLink]="['/'] | localize">
                                        {{'nav_eServices' | translate}}
                                    </a>
                                </li>

                                <li class="site-nav-sub-divider" *ngIf="currentUser?.impersonator || showLanguageSwitch || currentUser"></li>

                                <li *ngIf="currentUser?.impersonator">
                                    <a href="#" class="site-impersonated" (click)="$event.preventDefault(); endImpersonation();">
                                        <i class="fi fi-rs-users site-impersonated-default"></i>
                                        <i class="fi fi-rs-delete-user site-impersonated-hover"></i>
                                        {{currentUser.firstName}} {{currentUser.lastName}}
                                    </a>
                                </li>

                                <li *ngIf="showLanguageSwitch">
                                    <a href="#" *ngIf="locale != 'en'" (click)="$event.preventDefault(); changeLocale('en')" hreflang="en">
                                        <i class="fi fi-rs-globe"></i> English
                                    </a>

                                    <a href="#" *ngIf="locale != 'lv'" (click)="$event.preventDefault(); changeLocale('lv')" hreflang="lv">
                                        <i class="fi fi-rs-globe"></i> Latviešu
                                    </a>
                                </li>

                                <li *ngIf="currentUser">
                                    <a href="#" (click)="$event.preventDefault(); logout()">
                                        <i class="fi fi-rs-sign-out-alt"></i> {{'logoutlabel' | translate}}
                                    </a>
                                </li>

                                <li class="site-nav-sub-divider"></li>

                                <li>
                                    <span>
                                        <i class="fi fi-rs-calendar-lines"></i><span #currentDate></span>
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        <i class="fi fi-rs-clock-three"></i><span #currentTime></span>&nbsp;{{'nav_inLatvia' | translate}}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div class="site-header-top-item hidden-sm-down">
                            <a href="{{eStudiesSiteUrl}}" target="_blank">
                                {{'nav_eStudies' | translate}}
                            </a>
                        </div>

                        <div class="site-header-top-item hidden-sm-down">
                            <a [routerLink]="['/'] | localize">
                                {{'nav_eServices' | translate}}
                            </a>
                        </div>

                        <div class="site-header-top-item hidden-md-down" *ngIf="showDebugBadge">
                            <span class="site-env">
                                {{'devenvironment' | translate}}
                            </span>
                        </div>
                    </div>

                    <div class="site-header-top-area site-header-top-center">
                        <span class="site-logo"></span>
                    </div>

                    <div class="site-header-top-area site-header-top-right">
                        <div class="site-header-top-item hidden-lg-down" *ngIf="currentUser?.impersonator">
                            <a href="#" class="site-impersonated" (click)="$event.preventDefault(); endImpersonation();">
                                <i class="fi fi-rs-users site-impersonated-default"></i>
                                <i class="fi fi-rs-delete-user site-impersonated-hover"></i>
                                {{currentUser.firstName}} {{currentUser.lastName}}
                            </a>
                        </div>

                        <div class="site-header-top-item hidden-sm-down" *ngIf="showLanguageSwitch">
                            <a href="#" class="site-lang" *ngIf="locale != 'en'" (click)="$event.preventDefault(); changeLocale('en')" hreflang="en">
                                <i class="fi fi-rs-globe hidden-lg-down"></i>
                                <span class="hidden-sm-up">EN</span>
                                <span class="hidden-xs-down">English</span>
                            </a>

                            <a href="#" class="site-lang" *ngIf="locale != 'lv'" (click)="$event.preventDefault(); changeLocale('lv')" hreflang="lv">
                                <i class="fi fi-rs-globe hidden-lg-down"></i>
                                <span class="hidden-sm-up">LV</span>
                                <span class="hidden-xs-down">Latviešu</span>
                            </a>
                        </div>

                        <div class="site-header-top-item hidden-sm-down site-top-user">
                            <a href="#" (click)="$event.preventDefault(); logout()" *ngIf="currentUser">
                                <i class="fi fi-rs-sign-out-alt"></i>
                                <span class="hidden-sm-down">
                                    {{'logoutlabel' | translate}}
                                </span>
                            </a>

                            <a [routerLink]="['/login/adfs'] | localize" *ngIf="!currentUser">
                                <i class="fi fi-rs-sign-in-alt"></i>
                                <span class="hidden-sm-down">
                                    {{'loginlabel' | translate}}
                                </span>
                            </a>
                        </div>

                        <div class="site-header-top-item site-top-search hidden-md-up">
                            <a href="#" (click)="$event.preventDefault(); toggleSearch()" *ngIf="currentUser">
                                <i class="fi fi-rs-search" *ngIf="!searchOpened"></i>
                                <i class="fi fi-rs-cross" *ngIf="searchOpened"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="site-header-bottom">
        <div class="container">
            <div class="wrapper-lg">
                <div class="site-header-bottom-body">
                    <a href="{{myRsuSiteUrl}}" class="site-myrsu" target="_blank" *ngIf="isStudent"></a>
                    <a [routerLink]="['/'] | localize" class="site-myrsu" *ngIf="!isStudent"></a>
                    <nav class="site-nav" role="navigation" *ngIf="currentUser">
                        <ul class="site-nav-primary">
                            <li [class.opened]="n.isOpened" *ngFor="let n of getVisibleNavItems(navItems)">
                                <a [routerLink]="[n.route] | localize" *ngIf="n.route" [translate]="n.titleRes" routerLinkActive="active"></a>
                                <a *ngIf="!n.route" [translate]="n.titleRes" (click)="openMenuItem(n)" class="site-nav-toggle"></a>

                                <ul *ngIf="n.isOpened && n.children && n.children.length" class="site-nav-sub">
                                    <li *ngFor="let c of getVisibleNavItems(n.children)">
                                        <a *ngIf="c.route"
                                           [routerLink]="[(n.childrenRoutePrefix || '') + c.route] | localize"
                                           [translate]="c.titleRes"
                                           routerLinkActive="active"></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <div class="site-header-aux">
                        <span class="site-date"><i class="fi fi-rs-calendar-lines"></i><span #currentDate></span></span>
                        <span class="site-time"><i class="fi fi-rs-clock-three"></i><span #currentTime></span>&nbsp;{{'nav_inLatvia' | translate}}</span>
                        <span class="site-search-btn" (click)="toggleSearch()">
                            <i class="fi fi-rs-search" *ngIf="!searchOpened"></i>
                            <i class="fi fi-rs-cross" *ngIf="searchOpened"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="site-search" *ngIf="searchOpened">
    <div class="container">
        <div class="wrapper-md">
            <app-search (go)="toggleSearch()"></app-search>
        </div>
    </div>
</div>
