<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/final-paper', text: 'finalPaper_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'finalPaper_title' | translate}}</h1>
        <aside *ngIf="canPickStudent && (!studentPickerOpened || isLoaded)">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'finalPaper_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-alert *ngIf="!dataValid && !studentPickerOpened && errorWithData" level="warning">
        <div [innerHTML]="errorWithData | safeHtml"></div>
    </app-alert>

    <app-person-picker *ngIf="studentPickerOpened" [additionalFilter]="studentPickerFilter" (pick)="setStudent($event)"></app-person-picker>

    <form #form="ngForm">
        <ng-container *ngIf="!studentPickerOpened && isLoaded && dataValid">
            <div class="data-value" *ngIf="student">
                <label>{{'finalPaper_student' | translate}}</label>
                <div>{{student.FirstName}} {{student.LastName}} ({{student.StudentId}})</div>
            </div>

            <app-alert *ngIf="alert && !isActive" level="danger">
                <div [innerHTML]="alert | safeHtml"></div>
            </app-alert>

            <div class="data-value" *ngIf="isActive">
                <label>{{'finalPaper_lblStatus' | translate}}</label>
                <div>{{'finalPaper_status' + item.Status | translate}}</div>
            </div>

            <app-alert *ngIf="warning && isActive" level="warning">
                <div [innerHTML]="warning | safeHtml"></div>
            </app-alert>

            <app-alert *ngIf="info && isActive" level="info">
                <div [innerHTML]="info | safeHtml"></div>
            </app-alert>
        </ng-container>

        <ng-container *ngIf="!studentPickerOpened && isLoaded && isActive && dataValid">
            <section class="block">
                <header class="block-header">
                    <h5 [translate]="'finalPaper_baseInfo' | translate"></h5>
                    <aside>
                        <app-toggle-button [(value)]="showBaseInfo">{{'finalPaper_btnToggleSection' | translate}}</app-toggle-button>
                    </aside>
                </header>

                <div [hidden]="!showBaseInfo">
                    <app-alert level="danger" *ngIf="!isReadonly && baseInfoOutdated && infoOutdatedWarning">
                        <div [innerHTML]="infoOutdatedWarning | safeHtml"></div>
                    </app-alert>

                    <div class="row" *ngIf="studyPrograms.length > 1">
                        <div class="form-field required col-sm-6">
                            <label class="form-label">{{'finalPaper_lblStudyProgram' | translate}}</label>
                            <app-select [ngModel]="selectedProgramCode"
                                        (ngModelChange)="onProgramChange($event, programInput)"
                                        name="studyProgram"
                                        [required]="true"
                                        [options]="studyPrograms"
                                        [valueProperty]="'studySubjectId'"
                                        displayProperty="name"
                                        #programInput>
                            </app-select>
                        </div>
                    </div>

                    <div class="data-value" *ngIf="studyPrograms.length === 1">
                        <label>{{'finalPaper_lblStudyProgram' | translate}}</label>
                        <div>{{studyPrograms[0].name}}</div>
                    </div>

                    <div class="data-value">
                        <label>{{'finalPaper_lblThesisType' | translate}}</label>
                        <div>{{item.ThesisType}}</div>
                    </div>

                    <div class="data-value">
                        <label>{{'finalPaper_lblUploadDueDate' | translate}}</label>
                        <div>{{item.UploadDueDate | appDate}}</div>
                    </div>

                    <div class="data-value" *ngIf="isReadonly || !canEditStudentData; else languageInput">
                        <label>{{'finalPaper_lblLanguage' | translate}}</label>
                        <div>{{findLanguageByCode(item.Language) || '-'}}</div>
                    </div>

                    <ng-template #languageInput>
                        <div class="row">
                            <div class="form-field required col-sm-6">
                                <label class="form-label">{{'finalPaper_lblLanguage' | translate}}</label>
                                <app-select [(ngModel)]="item.Language"
                                            name="language"
                                            [required]="true"
                                            [options]="languages"
                                            [valueProperty]="'code'"
                                            [displayProperty]="'name'">
                                </app-select>
                            </div>
                        </div>
                    </ng-template>

                    <div class="data-value" *ngIf="isReadonly || !canEditOtherLang; else languageOtherInput">
                        <label>{{'finalPaper_lblLanguageOther' | translate}}</label>
                        <div>{{findLanguageByCode(item.LanguageOther) || '-'}}</div>
                    </div>

                    <ng-template #languageOtherInput>
                        <div class="row">
                            <div class="form-field col-sm-6">
                                <label>{{'finalPaper_lblLanguageOther' | translate}}</label>
                                <app-select [(ngModel)]="item.LanguageOther"
                                            name="languageOther"
                                            [options]="otherLanguages"
                                            [valueProperty]="'code'"
                                            [displayProperty]="'name'">
                                </app-select>
                            </div>
                        </div>
                    </ng-template>

                    <div class="data-value" *ngIf="programme?.Supervisors.length">
                        <label>{{'finalPaper_lblSupervisor' | translate}}</label>
                        <div>
                            <ng-container *ngFor="let n of programme.Supervisors; let i = index">
                                <ng-container *ngIf="i > 0">, </ng-container>
                                {{n.Name}} {{n.Surname}}
                            </ng-container>
                        </div>
                    </div>

                    <div class="data-value" *ngIf="programme?.SecondSupervisors.length">
                        <label>{{'finalPaper_lblSecondSupervisor' | translate}}</label>
                        <div>
                            <ng-container *ngFor="let n of programme.SecondSupervisors; let i = index">
                                <ng-container *ngIf="i > 0">, </ng-container>
                                {{n.Name}} {{n.Surname}}
                            </ng-container>
                        </div>
                    </div>

                    <div class="data-value" *ngIf="programme?.Consultants.length">
                        <label>{{'finalPaper_lblConsultant' | translate}}</label>
                        <div>
                            <ng-container *ngFor="let n of programme.Consultants; let i = index">
                                <ng-container *ngIf="i > 0">, </ng-container>
                                {{n.Name}} {{n.Surname}}
                            </ng-container>
                        </div>
                    </div>

                    <div class="form-field required">
                        <label class="form-label">{{'finalPaper_lblTitleLV' | translate}}</label>
                        <textarea [(ngModel)]="item.TitleLV" class="form-control" required name="titleLV" #titleLvInput (input)="toTextValue(titleLvInput)"
                                  rows="2" maxlength="300" [readonly]="isReadonly || !canEditStudentData"></textarea>
                    </div>

                    <div class="form-field required">
                        <label class="form-label">{{'finalPaper_lblTitleEN' | translate}}</label>
                        <textarea [(ngModel)]="item.TitleEN" class="form-control" required name="titleEN" #titleEnInput (input)="toTextValue(titleEnInput)"
                                  rows="2" maxlength="300" [readonly]="isReadonly || !canEditStudentData"></textarea>
                    </div>

                    <div class="form-field">
                        <label class="form-label">{{'finalPaper_lblTitleOther' | translate}}</label>
                        <textarea [(ngModel)]="item.TitleOther" class="form-control" name="titleOther" #titleOtherInput (input)="toTextValue(titleOtherInput)"
                                  rows="2" maxlength="300" [readonly]="isReadonly || !canEditOtherLang"></textarea>
                    </div>
                </div>
            </section>

            <section class="block">
                <header class="block-header">
                    <h5 [translate]="'finalPaper_annotationAndTags' | translate"></h5>
                    <aside>
                        <app-toggle-button [(value)]="showAnnotationAndTags">{{'finalPaper_btnToggleSection' | translate}}</app-toggle-button>
                    </aside>
                </header>

                <div [hidden]="!showAnnotationAndTags">
                    <div class="form-field" [class.required]="!isLanguageEn">
                        <label class="form-label">{{'finalPaper_lblAnnotationLV' | translate}}</label>
                        <textarea [(ngModel)]="item.AnnotationLV" class="form-control" [required]="!isLanguageEn" name="annotationLV" [readonly]="isReadonly"
                                  rows="5" [pattern]="getAnnotationValidationPattern(item.AnnotationLV)" #annotationLv="ngModel"></textarea>
                        <div class="invalid-feedback" *ngIf="item.AnnotationLV && annotationLv.invalid">
                            {{'fieldError_lengthMinMax' | translate | formatString:[annotationMinLength, annotationMaxLength]}}
                        </div>
                    </div>

                    <div class="form-field required">
                        <label class="form-label">{{'finalPaper_lblAnnotationEN' | translate}}</label>
                        <textarea [(ngModel)]="item.AnnotationEN" class="form-control" required name="annotationEN" [readonly]="isReadonly"
                                  rows="5" [pattern]="getAnnotationValidationPattern(item.AnnotationEN)" #annotationEn="ngModel"></textarea>
                        <div class="invalid-feedback" *ngIf="item.AnnotationEN && annotationEn.invalid">
                            {{'fieldError_lengthMinMax' | translate | formatString:[annotationMinLength, annotationMaxLength]}}
                        </div>
                    </div>

                    <div class="form-field">
                        <label class="form-label">{{'finalPaper_lblAnnotationOther' | translate}}</label>
                        <textarea [(ngModel)]="item.AnnotationOther" class="form-control" name="annotationOther" [readonly]="isReadonly"
                                  rows="5" [pattern]="getAnnotationValidationPattern(item.AnnotationOther)" #annotationOther="ngModel"></textarea>
                        <div class="invalid-feedback" *ngIf="item.AnnotationOther && annotationOther.invalid">
                            {{'fieldError_lengthMinMax' | translate | formatString:[annotationMinLength, annotationMaxLength]}}
                        </div>
                    </div>

                    <div class="form-field" [class.required]="!isLanguageEn">
                        <label class="form-label">{{'finalPaper_lblTagsLV' | translate}}</label>
                        <textarea [(ngModel)]="item.TagsLV" #tagsLvInput (input)="toTextValue(tagsLvInput)" class="form-control" [required]="!isLanguageEn" name="tagsLV"
                                  maxlength="300" [readonly]="isReadonly" rows="2"></textarea>
                    </div>

                    <div class="form-field required">
                        <label class="form-label">{{'finalPaper_lblTagsEN' | translate}}</label>
                        <textarea [(ngModel)]="item.TagsEN" #tagsEnInput (input)="toTextValue(tagsEnInput)" class="form-control" required name="tagsEN"
                                  maxlength="300" [readonly]="isReadonly" rows="2"></textarea>
                    </div>

                    <div class="form-field">
                        <label class="form-label">{{'finalPaper_lblTagsOther' | translate}}</label>
                        <textarea [(ngModel)]="item.TagsOther" #tagsOtherInput (input)="toTextValue(tagsOtherInput)" class="form-control" name="tagsOther"
                                  maxlength="300" [readonly]="isReadonly" rows="2"></textarea>
                    </div>
                </div>
            </section>

            <section class="block">
                <header class="block-header">
                    <h5 [translate]="'finalPaper_fileUpload' | translate"></h5>
                    <aside>
                        <app-toggle-button [(value)]="showFileUpload">{{'finalPaper_btnToggleSection' | translate}}</app-toggle-button>
                    </aside>
                </header>

                <div [hidden]="!showFileUpload">
                    <div class="form-field required" *ngIf="!isReadonly">
                        <label class="form-label">{{'finalPaper_lblFinalPaper' | translate}}</label>
                        <app-file [(ngModel)]="mainFile.file"
                                  [accept]="mainFileExtensions"
                                  [maxSize]="mainFileMaxSize"
                                  [onRemove]="confirmFileRemoval"
                                  [required]="true"
                                  (fileChange)="onFileChange($event, mainFile)"
                                  name="mainFile"
                                  #mainFileInput="ngModel"></app-file>
                        <div class="invalid-feedback" *ngIf="mainFileInput.errors?.maxSize">{{mainFileInput.errors?.maxSize}}</div>
                    </div>

                    <div class="data-value" *ngIf="isReadonly">
                        <label>{{'finalPaper_lblFinalPaper' | translate}}</label>
                        <div>
                            <a href="{{mainFile.url}}" target="_blank">{{mainFile.fileName}}</a>
                        </div>
                    </div>

                    <div *ngFor="let f of appendixes; let i = index">
                        <div class="form-field" *ngIf="!isReadonly">
                            <label class="form-label">{{'finalPaper_lblAppendix' | translate}}</label>
                            <app-file [(ngModel)]="f.file"
                                      name="appendix-{{i}}"
                                      [accept]="appendixExtensions"
                                      [maxSize]="appendixMaxSize"
                                      [onRemove]="confirmFileRemoval"
                                      (fileChange)="onFileChange($event, f)"></app-file>
                        </div>

                        <div class="data-value" *ngIf="isReadonly">
                            <label>{{'finalPaper_lblAppendix' | translate}}</label>
                            <div>
                                <a href="{{f.url}}" target="_blank">{{f.fileName}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3" *ngIf="!isReadonly">
                        <button type="button" class="btn btn-outline" (click)="addAppendix()">
                            <i class="fi fi-rs-plus"></i>
                            {{'finalPaper_btnAddAppendix' | translate}}
                        </button>
                    </div>

                    <div *ngFor="let f of permissions; let i = index">
                        <div class="form-field" *ngIf="!isReadonly">
                            <label class="form-label">{{'finalPaper_lblPermission' | translate}}</label>
                            <app-file [(ngModel)]="f.file"
                                      name="permission-{{i}}"
                                      [accept]="permissionExtensions"
                                      [maxSize]="permissionMaxSize"
                                      [onRemove]="confirmFileRemoval"
                                      (select)="onFileChange($event, f)"></app-file>
                        </div>

                        <div class="data-value" *ngIf="isReadonly">
                            <label>{{'finalPaper_lblPermission' | translate}}</label>
                            <div>
                                <a href="{{f.url}}" target="_blank">{{f.fileName}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3" *ngIf="!isReadonly">
                        <button type="button" class="btn btn-outline" (click)="addPermission()">
                            <i class="fi fi-rs-plus"></i>
                            {{'finalPaper_btnAddPermission' | translate}}
                        </button>
                    </div>

                    <app-alert level="danger" *ngIf="!isReadonly && !validateFileSize()">
                        {{'finalPaper_totalFileMaxSizeExceeded' | translate}}
                    </app-alert>
                </div>
            </section>

            <section class="block">
                <header class="block-header">
                    <h5 [translate]="'finalPaper_affirmations' | translate"></h5>
                </header>

                <div class="mb-1">
                    <app-checkbox [(ngModel)]="item.IsAuthentic" name="isAuthentic" [disabled]="isReadonly">
                        <span [innerHTML]="authenticityText | safeHtml"></span>
                        &nbsp;<a href="{{affirmationPdfUrl}}" target="_blank" class="text-nowrap" *ngIf="affirmationPdfUrl">
                            <i class="fi fi-rs-download"></i>
                            {{'finalPaper_downloadAffirmationPdf' | translate}}
                        </a>
                    </app-checkbox>
                </div>

                <div class="mb-1">
                    <app-checkbox [(ngModel)]="item.IsPlagiarismCheckAccepted" name="isPlagiarismCheckAccepted" [disabled]="isReadonly">
                        <span [innerHTML]="plagiarismCheckText | safeHtml"></span>
                    </app-checkbox>
                </div>

                <div class="mb-1">
                    <app-checkbox [(ngModel)]="item.IsRestricted" (stateChange)="onIsRestrictedChange()" name="isRestricted" [disabled]="isReadonly">
                        <span [innerHTML]="restrictionText | safeHtml"></span>
                    </app-checkbox>
                </div>

                <div class="mb-1">
                    <app-checkbox [(ngModel)]="item.IsPublic" (stateChange)="onIsPublicChange()" name="isPublic" [disabled]="isReadonly">
                        <span [innerHTML]="(publicTextYes + publicText) | safeHtml"></span>
                    </app-checkbox>
                </div>
            </section>

            <section class="block" *ngIf="changeHistoryEnabled">
                <header class="block-header">
                    <h5>
                        {{'finalPaper_changeHistory' | translate}}
                    </h5>
                    <aside>
                        <app-toggle-button [value]="showChangeHistory" (valueChange)="onToggleChangeHistory()">
                            {{'finalPaper_btnToggleSection' | translate}}
                        </app-toggle-button>
                    </aside>
                </header>

                <div [hidden]="!showChangeHistory">
                    <app-table [rows]="changeHistory" [columns]="changeHistoryColumns" [pageSize]="100">
                        <ng-template #rowTemplate let-row>
                            <tr>
                                <td>
                                    {{row.Modified | appDate:'datetime'}}
                                    <div class="hidden-md-up text-sub">{{row.ModifiedByName}}</div>
                                </td>
                                <td class="hidden-sm-down">{{row.ModifiedByName}}</td>
                                <td class="hidden-md-down">{{'modif_action_' + row.ModifAction | translate}}</td>
                                <td class="hidden-md-down">{{'finalPaper_status' + row.Status | translate}}</td>
                                <td>
                                    <div class="table-actions">
                                        <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)"
                                                [ngbTooltip]="'finalPaper_showDetails' | translate">
                                            <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="isExpanded(row)" class="table-row-details">
                                <td colspan="9">
                                    <div class="dl">
                                        <div class="hidden-md-up">
                                            <span>{{'modif_lblAction' | translate}}</span>
                                            <span>{{'modif_action_' + row.ModifAction | translate}}</span>
                                        </div>

                                        <div>
                                            <span>{{'finalPaper_lblName' | translate}}</span>
                                            <span>{{row.Name}}</span>
                                        </div>

                                        <div>
                                            <span>{{'finalPaper_lblSurname' | translate}}</span>
                                            <span>{{row.Surname}}</span>
                                        </div>

                                        <div>
                                            <span>{{'finalPaper_lblEmail' | translate}}</span>
                                            <span>{{row.Email}}</span>
                                        </div>

                                        <div class="hidden-lg-up">
                                            <span>{{'finalPaper_lblStatus' | translate}}</span>
                                            <span>{{'finalPaper_status' + row.Status | translate}}</span>
                                        </div>

                                        <div><span [translate]="'finalPaper_lblFaculty'"></span><span>{{row.Faculty}}</span></div>
                                        <div><span [translate]="'finalPaper_lblStudyProgram'"></span><span>{{row.StudyProgram}}</span></div>
                                        <div><span [translate]="'finalPaper_lblStudyProgramCode'"></span><span>{{row.StudyProgramCode}}</span></div>
                                        <div><span [translate]="'finalPaper_lblStudyDirection'"></span><span>{{row.StudyDirection}}</span></div>
                                        <div><span [translate]="'finalPaper_lblThesisType'"></span><span>{{row.ThesisType}}</span></div>
                                        <div><span [translate]="'finalPaper_lblDefenseDate'"></span><span>{{row.DefenseDate | appDate}}</span></div>
                                        <div><span [translate]="'finalPaper_lblUploadDueDate'"></span><span>{{row.UploadDueDate | appDate}}</span></div>
                                        <div><span [translate]="'finalPaper_lblLanguage'"></span><span>{{row.Language}}</span></div>
                                        <div><span [translate]="'finalPaper_lblLanguageOther'"></span><span>{{row.LanguageOther}}</span></div>
                                        <div>
                                            <span>{{'finalPaper_lblSupervisor' | translate}}, {{'finalPaper_lblSupervisorName' | translate}}</span>
                                            <span>{{row.SupervisorName}}</span>
                                        </div>
                                        <div>
                                            <span>{{'finalPaper_lblSupervisor' | translate}}, {{'finalPaper_lblSupervisorSurname' | translate}}</span>
                                            <span>{{row.SupervisorSurname}}</span>
                                        </div>
                                        <div>
                                            <span>{{'finalPaper_lblSecondSupervisor' | translate}}, {{'finalPaper_lblSecondSupervisorName' | translate}}</span>
                                            <span>{{row.SecondSupervisorName}}</span>
                                        </div>
                                        <div>
                                            <span>{{'finalPaper_lblSecondSupervisor' | translate}}, {{'finalPaper_lblSecondSupervisorSurname' | translate}}</span>
                                            <span>{{row.SecondSupervisorSurname}}</span>
                                        </div>
                                        <div><span [translate]="'finalPaper_lblTitleLV'"></span><span>{{row.TitleLV}}</span></div>
                                        <div><span [translate]="'finalPaper_lblTitleEN'"></span><span>{{row.TitleEN}}</span></div>
                                        <div><span [translate]="'finalPaper_lblTitleOther'"></span><span>{{row.TitleOther}}</span></div>
                                        <div><span [translate]="'finalPaper_lblAnnotationLV'"></span><span>{{row.AnnotationLV}}</span></div>
                                        <div><span [translate]="'finalPaper_lblAnnotationEN'"></span><span>{{row.AnnotationEN}}</span></div>
                                        <div><span [translate]="'finalPaper_lblAnnotationOther'"></span><span>{{row.AnnotationOther}}</span></div>
                                        <div><span [translate]="'finalPaper_lblTagsLV'"></span><span>{{row.TagsLV}}</span></div>
                                        <div><span [translate]="'finalPaper_lblTagsEN'"></span><span>{{row.TagsEN}}</span></div>
                                        <div><span [translate]="'finalPaper_lblTagsOther'"></span><span>{{row.TagsOther}}</span></div>
                                        <div><span [translate]="'finalPaper_lblIsAuthentic'"></span><span>{{yesno(row.IsAuthentic)}}</span></div>
                                        <div><span [translate]="'finalPaper_lblIsRestricted'"></span><span>{{yesno(row.IsRestricted)}}</span></div>
                                        <div><span [translate]="'finalPaper_lblIsPublic'"></span><span>{{yesno(row.IsPublic)}}</span></div>
                                        <div><span [translate]="'finalPaper_lblAuthenticityText'"></span><span>{{row.AuthenticityText}}</span></div>
                                        <div><span [translate]="'finalPaper_lblRestrictionText'"></span><span>{{row.RestrictionText}}</span></div>
                                        <div><span [translate]="'finalPaper_lblPublicText'"></span><span>{{row.PublicText}}</span></div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </app-table>
                </div>
            </section>

            <section class="block" *ngIf="changeHistoryEnabled">
                <header class="block-header">
                    <h5>
                        {{'finalPaper_fileChangeHistory' | translate}}
                    </h5>
                    <aside>
                        <app-toggle-button [value]="showFileChangeHistory" (valueChange)="onToggleFileChangeHistory()">
                            {{'finalPaper_btnToggleSection' | translate}}
                        </app-toggle-button>
                    </aside>
                </header>

                <div [hidden]="!showFileChangeHistory">
                    <app-table [rows]="fileChangeHistory" [columns]="fileChangeHistoryColumns" [pageSize]="100">
                        <ng-template #rowTemplate let-row>
                            <tr>
                                <td>
                                    {{row.Modified | appDate:'datetime'}}
                                    <div class="hidden-md-up text-sub">{{row.ModifiedByName}}</div>
                                </td>
                                <td class="hidden-sm-down">{{row.ModifiedByName}}</td>
                                <td class="hidden-md-down">{{'modif_action_' + row.ModifAction | translate}}</td>
                                <td>
                                    <div class="table-actions">
                                        <button type="button" class="btn btn-outline btn-icon" (click)="toggle(row)"
                                                [ngbTooltip]="'finalPaper_showDetails' | translate">
                                            <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="isExpanded(row)" class="table-row-details">
                                <td colspan="9">
                                    <div class="dl">
                                        <div class="hidden-lg-up">
                                            <span>{{'modif_lblAction' | translate}}</span>
                                            <span>{{'modif_action_' + row.ModifAction | translate}}</span>
                                        </div>

                                        <div>
                                            <span>{{'finalPaper_lblFileName' | translate}}</span>
                                            <span>
                                                <a href="{{getHistoryFileUrl(row.ModifID)}}">{{row.FileName}}</a>
                                            </span>
                                        </div>

                                        <div>
                                            <span>{{'finalPaper_lblFileType' | translate}}</span>
                                            <span>{{'finalPaper_fileType' + row.Type | translate}}</span>
                                        </div>

                                        <div>
                                            <span>{{'finalPaper_lblFileSize' | translate}}</span>
                                            <span>{{prettyFileSize(row.ContentLength)}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </app-table>
                </div>
            </section>

            <div class="form-actions" *ngIf="!isReadonly">
                <div class="form-actions-group" *ngIf="!isSubmitted">
                    <button class="btn btn-outline-primary" type="button" (click)="save(true)" id="btn-submit">
                        <i class="fi fi-rs-paper-plane"></i>
                        {{'finalPaper_btnSubmit' | translate}}
                    </button>
                    <div *ngIf="item.UploadDueDate">
                        <small>
                            {{'finalPaper_termDueDate' | translate}} {{item.UploadDueDate | appDate}} 23:59:59
                        </small>
                    </div>
                </div>

                <div class="form-actions-group">
                    <button class="btn btn-outline" type="button" (click)="$event.preventDefault(); cancel();">
                        {{'finalPaper_btnCancel' | translate}}
                    </button>

                    <button class="btn btn-primary" type="button" (click)="save()" id="btn-save">
                        {{'finalPaper_btnSave' | translate}}
                    </button>
                </div>
            </div>
        </ng-container>
    </form>
</div>
