import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IManipulationReportListItem } from '../../models/Manipulation';
import { IPersonSearchResultItem } from '../../models/Person';
import { AppService } from '../../services/app.service';
import { ManipulationService } from '../../services/manipulation.service';
import { Utils } from '../../core/Utils';
import { userRights } from './edit.component';
import { store } from './store';
import { ITableColumn } from '../../shared/table/table.component';
import { PersonService } from '../../services/person.service';

@Component({
    selector: 'app-manipulatioins-list',
    templateUrl: './list.component.html',
    styleUrls: ['./shared.css']
})
export class ManipulationListComponent {
    constructor(
        private app: AppService,
        private service: ManipulationService,
        private persons: PersonService,
        private route: ActivatedRoute
    ) { }

    items: IManipulationReportListItem[] = [];
    canPickStudent: boolean;
    studentPickerOpened: boolean;
    student: IPersonSearchResultItem;

    readonly canEdit = this.app.currentUser?.rights.includes('MANIPULATION.EDIT');

    readonly columns: ITableColumn[] = [
        { property: 'Status', label: 'manipulation_lblStatus', sorts: true },
        { property: 'StudyYear', label: 'manipulation_lblStudyYear', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'Speciality', label: 'manipulation_lblSpeciality', sorts: true, cssClass: 'hidden-md-down' },
        { property: 'From', label: 'manipulation_lblFrom', sorts: true, type: 'date', cssClass: 'hidden-xs-down' },
        { property: 'To', label: 'manipulation_lblTo', sorts: true, type: 'date', cssClass: 'hidden-xs-down' },
        { property: 'From', label: 'manipulation_lblPeriod', sorts: true, type: 'date', cssClass: 'hidden-sm-up' },
        { width: '1px', cssClass: 'hidden-sm-down' }
    ];

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf(userRights.setStudent) > -1;

            if (this.canPickStudent) {
                const queryUpn = this.route.snapshot.queryParams['id'];

                if (queryUpn) {
                    this.app.addLoading(this.persons.findStudents('student_id', queryUpn)).subscribe(data => {
                        if (data.length) {
                            this.setStudent(data[0]);
                            this.loadItems();
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
                } else {
                    this.student = store.getStudent();

                    if (this.student) {
                        this.loadItems();
                    } else {
                        this.toggleStudentPicker();
                    }
                }
            } else {
                this.loadItems();
            }
        }
    }

    toggleStudentPicker() {
        if (this.studentPickerOpened) {
            this.studentPickerOpened = false;
        } else {
            this.studentPickerOpened = true;
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.loadItems();

        store.setStudent(student);
    }

    delete(item: IManipulationReportListItem) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.loadItems();
            });
        });
    }

    private loadItems() {
        this.app.addLoading(this.service.get(this.student?.Email)).subscribe(data => {
            data.forEach(t => {
                t.From = Utils.getInputDate(t.From);
                t.To = Utils.getInputDate(t.To);
            });

            this.items = data;
        });
    }
}
