import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IPublishedTile, ITile, TileEditModel } from '../models/Tile';

/**
 * Tile service
 */
@Injectable({ providedIn: "root" })
export class TileService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/tiles` }

    get(): Observable<ITile[]> {
        return this.http.get<ITile[]>(this.apiUrl);
    }

    getPublished(): Observable<IPublishedTile[]> {
        const url = `${this.apiUrl}/published`;
        return this.http.get<IPublishedTile[]>(url);
    }

    getById(id: number): Observable<ITile> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<ITile>(url);
    }

    create(model: TileEditModel): Observable<number> {
        const url = `${this.apiUrl}`;
        return this.http.post<number>(url, model);
    }

    update(id: number, model: TileEditModel): Observable<number> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.put<number>(url, model);
    }

    delete(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }
}
