import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PermissionsService } from '../../services/permissions.service';
import { IPermission, IPermissionEditModel } from '../../models/Permission';
import { Classifier } from '../../models/Classifier';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-permission-edit',
  templateUrl: './permission-edit.component.html'
})
export class PermissionEditComponent {
    constructor(
        private app: AppService,
        private service: PermissionsService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() data?: IPermission;
    @Input() roles: Classifier[] = [];
    @Input() permissions: Classifier[] = [];

    id?: number;
    submitted: boolean;
    isSaving: boolean;

    permission: Classifier;
    role: Classifier;

    readonly classifierFilterFn = (item: Classifier, value: string) => {
        const s = value.toLowerCase();
        return item.Value?.toLowerCase().includes(s)
            || item.Code?.toLowerCase().includes(s);
    }

    @ViewChild('form', { static: true }) private form: NgForm;

    ngOnInit() {
        if (this.data) {
            if (this.data.Id) {
                this.id = this.data.Id;
            }

            if (this.data.Permission?.Id) {
                this.permission = this.permissions.find(t => t.Id == this.data.Permission.Id);
            }

            if (this.data.Role?.Id) {
                this.role = this.roles.find(t => t.Id == this.data.Role.Id);
            }
        }
    }

    save() {
        if (this.form.invalid) {
            this.submitted = true;
            this.isSaving = false;
            return;
        }

        const success = () => {
            this.activeModal.close();
        };

        const model: IPermissionEditModel = {
            PermissionId: this.permission.Id,
            RoleId: this.role.Id
        };

        this.isSaving = true;

        const call = this.id
            ? this.service.update(this.id, model)
            : this.service.create(model);

        this.app.addLoading(call, 500).subscribe(success);
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
