import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
    <a href="#" style="display: none"></a>
    <div class="modal-header" *ngIf="title">
        <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body">
	    <div [innerHTML]="body | safeHtml"></div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-outline-secondary" (click)="close()">{{button || ('commonDialog_ok' | translate)}}</button>
    </div>
    `
})
export class CommonDialogComponent {
    constructor(private modal: NgbActiveModal) { }

    @Input() title: string;
    @Input() body: string;
    @Input() button: string;

    close() {
        this.modal.close();
    }
}
