import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { EmployeeFilterType, IPersonSearchResultItem, StudentFilterType } from '../models/Person';
import { UserType } from '../models/UserInfo';

@Injectable({ providedIn: "root" })
export class PersonService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/persons` }

    find(
        userType: UserType,
        filterType: StudentFilterType | EmployeeFilterType,
        filterValue: string,
        additionalFilter: string = ''
    ): Observable<IPersonSearchResultItem[]> {
        const url = `${this.apiUrl}/find`;
        const params: any = {
            userType,
            filterType,
            filterValue,
            additionalFilter
        };
        return this.http.get<IPersonSearchResultItem[]>(url, { params });
    }

    findStudents(filterType: StudentFilterType, filterValue: string, additionalFilter: string = ''): Observable<IPersonSearchResultItem[]> {
        return this.find(UserType.Student, filterType, filterValue, additionalFilter);
    }

    findEmployees(filterType: EmployeeFilterType, filterValue: string, additionalFilter: string = ''): Observable<IPersonSearchResultItem[]> {
        return this.find(UserType.Employee, filterType, filterValue, additionalFilter);
    }
}
