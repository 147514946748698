import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CourseEvent } from '../../models/BC_courses';

@Component({
    templateUrl: './events-dialog.component.html'
})
export class EventsDialogComponent {
    constructor(private activeModal: NgbActiveModal) {}

    @Input() set data(value: { events: CourseEvent[], info?: string, type: string }) {
        this.events = value.events;
        this.info = value.info;
        this.type = value.type;
        if (this.events.length) {
            this.event = this.events[0];
            this.singleAddress = new Set(this.events.map(t => t.AddressId)).size > 1;
        }
    }

    info: string;
    type: string;
    events: CourseEvent[] = [];
    singleAddress: boolean;
    event: CourseEvent;

    close() {
        this.activeModal.close();
    }
}
