import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../../services/app.service';

import { PortfolioService } from '../../../services/portfolio.service';
import { IPortfolio } from '../../../models/Portfolio';

import { PortfolioShareComponent } from '../share/portfolio-share.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDatePipe } from '../../../pipes/date.pipe';

const cache: { [id: number]: IPortfolio } = {};

@Component({
    selector: 'app-portfolio-view',
    templateUrl: './portfolio-view.component.html',
    styleUrls: ['../portfolio.component.scss']
})
export class PortfolioViewComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PortfolioService,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private appDate: AppDatePipe
    ) { }

    data: IPortfolio;
    shared: string;

    ngOnInit() {
        const id = +this.route.snapshot.params['id'];

        if (cache[id]) {
            this.data = cache[id];
        } else {
            this.app.addLoading(this.service.getById(id)).subscribe(data => {
                this.data = data;
                cache[id] = this.data;
            });
        }
    }

    share() {
        const ref = this.modal.open(PortfolioShareComponent);
        ref.componentInstance.portfolio = this.data;
        ref.result.then((result: {
            email: string,
            purpose: string,
            attachments: string[],
            result: { Url: string, Expires: Date }
        }) => {
            this.shared = this.app.translate('portfolio_shareSuccess')
                .replace('{{url}}', result.result.Url)
                .replace('{{expires}}', this.appDate.transform(result.result.Expires, 'datetime'))
                .replace('{{email}}', result.email);
        }, () => { });
    }
}
