import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CourseEvent, PersonMainDataItem, StudentCoursesItem } from '../../models/BC_courses';

import { AppService } from '../../services/app.service';
import { BC_CoursesService } from '../../services/bc_courses.service';
import { PersonService } from '../../services/person.service';

import { Utils } from '../../core/Utils';
import { Helpers } from './helpers';
import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { EventsDialogComponent } from './events-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bc_courses',
    templateUrl: './bc_courses.component.html',
    styleUrls: ['./bc_courses.component.scss']
})

export class BC_coursesComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public app: AppService,
        public service: BC_CoursesService,
        private messagesService: MessageService,
        private studentService: PersonService,
        private modal: NgbModal
    ) { }

    helpers = Helpers;
    title: string = null;
    pointsInfoHtml: string = null;
    formInfoHtml: string = null;
    formExplanationsHtml: string = null;
    rights = { view: false };
    isDenied: boolean;
    unavailableText: string;
    isUnavailable: boolean;

    studentPickerOpened: boolean;
    canPickStudent: boolean;
    student: IPersonSearchResultItem;
    studentEmail: string;
    studentUpn: string;
    studentFullName: string;
    isLoaded: boolean;
    noSelectionNeeded: boolean;
    isSetKPInfoTextIsOn: boolean;
    KPInfoTextIsOnText: string;

    personDataList: PersonMainDataItem[];
    selectedStudentCourses: StudentCoursesItem[] = [];

    selectedStudent_guid: string;
    selectedPlanning_period_GUID: string;

    showPlanningPeriodDropDown: boolean = false;
    getPersonMainDataHadResult: boolean = false;

    filterValue: PersonMainDataItem = null;

    get isAdmin(): boolean {
        return this.app.currentUser?.isAdmin;
    }

    private courseEventCache: { [key: string]: CourseEvent[] } = {};

    ngOnInit() {
        const isEn = this.app.currentLanguage === 'en';

        this.app.addLoading(this.messagesService.getByCodes(['BC_COURSES_EXPLANATIONS', 'BC_COURSES_UNAVAILABLE'])).subscribe(data => {
            const expl = data.find(t => t.Code === 'BC_COURSES_EXPLANATIONS');
            this.formExplanationsHtml = expl ? isEn ? expl.TextEN : expl.TextLV : undefined;

            const unav = data.find(t => t.Code === 'BC_COURSES_UNAVAILABLE');
            this.unavailableText = unav ? isEn ? unav.TextEN : unav.TextLV : undefined;
        });

        const user = this.app.currentUser;
        const params = this.route.snapshot.params;
        const query = this.route.snapshot.queryParams;

        if (user) {
            this.rights.view = user.rights.indexOf('BC_COURSES.VIEW') > -1 || user.rights.indexOf('BC_COURSES.ADMIN') > -1;
            this.canPickStudent = user.rights.indexOf('BC_COURSES.ADMIN') > -1;

            const id = query['id'];

            if (id && !this.canPickStudent) {
                this.isDenied = true;
                return;
            }

            this.selectedStudent_guid = params['Student_guid'];
            this.selectedPlanning_period_GUID = params['Planning_period_GUID'];

            if (this.rights.view && !this.canPickStudent) {
                this.studentEmail = user.email;
                this.studentFullName = `${user.firstName} ${user.lastName}`;
            } else {
                this.studentEmail = params['studentEmail'];
                this.studentFullName = params['studentFullName'];
            }

            if (this.studentEmail) {
                if (this.studentEmail.indexOf('@') > -1)
                    this.studentUpn = this.studentEmail.substring(0, this.studentEmail.indexOf('@'));

                this.init();
            } else if (this.canPickStudent) {
                if (id) {
                    this.app.addLoading(this.studentService.findStudents('student_id', id)).subscribe(data => {
                        if (data.length) {
                            this.student = data[0];
                            this.pickStudent(this.student);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                            this.toggleStudentPicker();
                        }
                    });
                } else {
                    this.toggleStudentPicker();
                }
            }
        }
    }

    toggleStudentPicker() {
        this.studentPickerOpened = !this.studentPickerOpened;
        this.isSetKPInfoTextIsOn = false;
        this.noSelectionNeeded = false;
    }

    pickStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.studentEmail = student.Email;
        this.studentFullName = student.FirstName + " " + student.LastName;

        if (this.studentEmail && this.studentEmail.indexOf('@') > -1)
            this.studentUpn = this.studentEmail.substring(0, this.studentEmail.indexOf('@'));

        this.selectedStudent_guid = null;
        this.selectedPlanning_period_GUID = null;
        this.selectedStudentCourses = [];

        this.init();
    }

    removeProgram(application_ID: string) {
        this.app.addLoading(this.service.deleteProgram(application_ID)).subscribe(data => {
            if (data.result_name == "OK") {
                this.init();
            }
            else {
                this.selectedStudentCourses.find(t => t.Application_ID === application_ID).error = data.Message;
            }
        });
    }

    addProgram() {
        if (this.canPickStudent)
            this.router.navigate([this.app.localizeRoute(`/bc_courses_add/${this.selectedStudent_guid}/${this.selectedPlanning_period_GUID}/${this.studentEmail}/${this.studentFullName}`)]);
        else {
            this.router.navigate([this.app.localizeRoute(`/bc_courses_add/${this.selectedStudent_guid}/${this.selectedPlanning_period_GUID}`)]);
        }
    }

    getCourseDateTimeString(course: StudentCoursesItem, groupedInfo: StudentCoursesItem): string {
        if (course.detailed_planning)
            return this.app.translate('BC_courses_calendar');

        if (groupedInfo.Group_day && groupedInfo.Time_from && groupedInfo.Time_to)
            return `${groupedInfo.Group_day}, ${groupedInfo.Time_from} - ${groupedInfo.Time_to}`;

        if (groupedInfo.Group_day && !groupedInfo.Time_from && !groupedInfo.Time_to)
            return groupedInfo.Group_day;

        if (!groupedInfo.Group_day && !groupedInfo.Time_from && !groupedInfo.Time_to)
            return this.app.translate('BC_courses_time_info');

        return '';
    }

    loadCourses(Student_guid: string, Planning_period_GUID: string) {
        this.isLoaded = false;
        this.selectedStudent_guid = Student_guid;
        this.selectedPlanning_period_GUID = Planning_period_GUID;

        const msg = this.app.translate('BC_courses_points_info');

        if (msg) {
            let currentPersonDataList = this.personDataList.find(p => p.Student_guid == this.selectedStudent_guid && p.Planning_period_GUID == this.selectedPlanning_period_GUID);
            let val = [
                currentPersonDataList.Planned_credit_points_B != null ? currentPersonDataList.Planned_credit_points_B : 0,
                currentPersonDataList.Creditpoints_B != null ? currentPersonDataList.Creditpoints_B : 0,
                currentPersonDataList.Planned_credit_points_C != null ? currentPersonDataList.Planned_credit_points_C : 0,
                currentPersonDataList.Creditpoints_C != null ? currentPersonDataList.Creditpoints_C : 0
            ];
            this.pointsInfoHtml = Utils.formatString(msg, val);
            this.noSelectionNeeded = currentPersonDataList.Planned_credit_points_B == 0 && currentPersonDataList.Planned_credit_points_C == 0;
            this.isSetKPInfoTextIsOn = currentPersonDataList.KP_InfoText_is_on == 1;
            this.KPInfoTextIsOnText = currentPersonDataList.KP_InfoText;
        }
        else {
            this.pointsInfoHtml = null;
        }

        this.app.addLoading(this.service.getStudentCourses(this.selectedStudent_guid, this.selectedPlanning_period_GUID))
            .subscribe(studentCourses => {
                this.selectedStudentCourses = studentCourses;
                this.isLoaded = true;

                let groupedInfo: StudentCoursesItem[] = [];
                let fullyBooked: boolean[] = [];

                if (studentCourses.length) {
                    let item = studentCourses[0];
                    let nr = item.Nr;

                    groupedInfo.push(item);
                    fullyBooked.push(item.Used_places >= item.Max_students);

                    if (studentCourses.length == 1) {
                        const couseItem = studentCourses[0];
                        this.pushToCourses(couseItem, groupedInfo, fullyBooked);
                    } else {
                        for (let i = 1; i < studentCourses.length; i++) {
                            item = studentCourses[i];

                            if (item.Nr == nr) {
                                groupedInfo.push(item);
                                fullyBooked.push(item.Used_places >= item.Max_students);
                            } else {
                                const couseItem = studentCourses[i - 1];
                                this.pushToCourses(couseItem, groupedInfo, fullyBooked);

                                groupedInfo = [item];
                                nr = item.Nr;
                                fullyBooked = [item.Used_places >= item.Max_students];
                            }

                            if (i == studentCourses.length - 1) {
                                this.pushToCourses(item, groupedInfo, fullyBooked);
                            }
                        }
                    }
                }
            });
    }

    onFilterValueChange(event) {
        this.loadCourses(event.Student_guid, event.Planning_period_GUID);
    }

    openEvents(course: StudentCoursesItem, groupedInfo: StudentCoursesItem, type: string) {
        const show = (events: CourseEvent[]) => {
            const ref = this.modal.open(EventsDialogComponent);

            ref.componentInstance.data = {
                events: events,
                info: course.Info_text,
                type: type
            };
        };

        let cached = this.courseEventCache[groupedInfo.Group_id];

        if (cached && cached.length) {
            show(this.courseEventCache[groupedInfo.Group_id]);
        } else {
            if (groupedInfo.detailed_planning) {
                this.app.addLoading(this.service.getCourseEvents(groupedInfo.Group_id)).subscribe(events => {
                    this.courseEventCache[groupedInfo.Group_id] = events;
                    show(events);
                });
            } else {
                show([<CourseEvent>{
                    Address: groupedInfo.Travel_dest_name,
                    DateTime: this.getCourseDateTimeString(course, groupedInfo),
                }]);
            }
        }
    }

    private init() {
        this.isLoaded = false;

        this.app.addLoading(this.service.getPersonMainData(this.studentEmail)).subscribe(persons => {
            this.personDataList = persons;

            if (this.personDataList.length > 0) {
                const studentId = persons[0].Student_guid;
                const periodId = persons[0].Planning_period_GUID;

                this.getPersonMainDataHadResult = true;

                if (this.selectedStudent_guid && this.selectedPlanning_period_GUID) {
                    this.loadCourses(this.selectedStudent_guid, this.selectedPlanning_period_GUID);
                    this.filterValue = this.personDataList.find(p => p.Student_guid == this.selectedStudent_guid && p.Planning_period_GUID == this.selectedPlanning_period_GUID);
                } else {
                    this.loadCourses(this.personDataList[0].Student_guid, this.personDataList[0].Planning_period_GUID);
                    this.filterValue = this.personDataList[0];
                }

                this.formInfoHtml = this.filterValue.Info_txt != "" ? this.filterValue.Info_txt : null;

                if (this.canPickStudent)
                    this.app.navigateByUrl(`/bc_courses/${studentId}/${periodId}/${this.studentEmail}/${this.studentFullName}`);
                else {
                    this.app.navigateByUrl(`/bc_courses/${studentId}/${periodId}`);
                }
            } else {
                this.isLoaded = true;
                this.pointsInfoHtml = null;
                this.formInfoHtml = null;
                this.getPersonMainDataHadResult = false;
                this.isUnavailable = true;
            }
        }, err => {
            this.isUnavailable = true;
        });
    }

    private pushToCourses(courseItem: StudentCoursesItem, groupedInfo: StudentCoursesItem[], fullyBooked: boolean[]) {
        courseItem.groupedInfo = groupedInfo;
        courseItem.fullyBooked = fullyBooked.findIndex(b => b == false) >= 0 ? false : true;
    }
}
