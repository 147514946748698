<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/final-paper-reviews', text: 'finalPaperReview_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'finalPaperReview_title' | translate}}</h1>
        <!-- Izslēgts līdz RSU sagatavo api/PersonData
        <aside *ngIf="canPickEmployee"> 
            <button type="button" class="btn btn-outline" (click)="togglePersonPicker()">
                <i class="fi fi-rs-badge"></i>
                {{'finalPaperReview_btnPickEmployee' | translate}} 
            </button>
        </aside> -->
    </header>

    <app-person-picker *ngIf="personPickerOpened" [personType]="personPickerType" (pick)="setPerson($event)"></app-person-picker>

    <ng-container *ngIf="!personPickerOpened">
        <div class="form-field required">
            <label class="form-label">{{'finalPaperReview_lblAcademicYear' | translate}}</label>
            <app-select [(ngModel)]="selectedYear"
                        [options]="options.year"
                        [displayProperty]="'Name'"
                        [required]="true"
                        (selectionChange)="onYearChange()"
                        name="year">
            </app-select>
        </div>

        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink>{{'finalPaperReview_finalPapers' | translate}}</a>
                <ng-template ngbNavContent>
                    <div class="mt-3">
                        <app-final-paper-review-list [data]="finalPapers"></app-final-paper-review-list>
                    </div>
                </ng-template>
            </li>

            <li ngbNavItem *ngIf="showProtocols">
                <a ngbNavLink>{{'finalPaperReview_protocols' | translate}}</a>
                <ng-template ngbNavContent>
                    <div class="mt-3">
                        <app-final-paper-protocol-list></app-final-paper-protocol-list>
                    </div>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav"></div>
    </ng-container>
</div>
