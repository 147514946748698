import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';
import { DoctorService } from '../../services/doctor.service';
import { IAcademicYear } from '../../models/AcademicYear';
import { ActivatedRoute } from '@angular/router';
import { IPersonSearchResultItem } from 'src/app/models/Person';
import { PersonType } from 'src/app/shared/person-picker/person-picker.component';
import { store } from './store';

@Component({
    selector: 'app-doctor-dashboard',
    templateUrl: './doctor-dashboard.component.html'
})
export class DoctorDashboardComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: DoctorService,
        private route: ActivatedRoute
    ) { }

    tabs: { id: string, title: string }[] = [];

    tabsReady: boolean;
    activeTabId: number = 1;
    academicYears: IAcademicYear[] = [];
    selectedYearId: string;
    seminarId: string;

    personPickerOpened: boolean;
    canPickEmployee: boolean;
    person: IPersonSearchResultItem;
    readonly personPickerType: PersonType = "employee";

    ngOnInit() {
        const user = this.app.currentUser;

        this.canPickEmployee = user?.hasRight('DOCTOR.SET_EMPLOYEE');
        // if (this.canPickEmployee)
        //     this.personPickerOpened = true;

        if (!this.personPickerOpened)
            this.init();
    }

    setPerson(person: IPersonSearchResultItem) {
        this.personPickerOpened = false;
        this.person = person;
        store.setPerson(person);
        this.init();
    }

    togglePersonPicker() {
        this.personPickerOpened = !this.personPickerOpened;
    }

    private openSeminars(id: string) {
        const seminarsTab = this.tabs.find(t => t.id == 'seminars');

        if (seminarsTab) {
            this.activeTabId = this.tabs.indexOf(seminarsTab) + 1;
            this.seminarId = id;
        }
    }

    private init() {
        this.reset();

        this.selectedYearId = this.route.snapshot.queryParams['YearId'];

        if (this.app.currentUser.rights.includes('DOCTOR.VIEW_TASKS')) {
            this.tabs.push({ id: 'tasks', title: 'doctorDashboard_tasks' });
        }

        if (this.app.currentUser.rights.includes('DOCTOR.VIEW_STUDENTS')) {
            this.tabs.push({ id: 'students', title: 'doctorDashboard_students' });
        }

        if (this.app.currentUser.rights.includes('DOCTOR.VIEW_SEMINARS')) {
            this.tabs.push({ id: 'seminars', title: 'doctorDashboard_seminars' });

            this.seminarId = this.route.snapshot.queryParams['SeminarId'];

            if (this.seminarId) {
                this.openSeminars(this.seminarId);
            }
        }

        if (this.app.currentUser.rights.includes('DOCTOR.VIEW_ROTATIONS')) {
            this.tabs.push({ id: 'rotations', title: 'doctorDashboard_rotations' });
        }

        this.tabsReady = true;

        if (this.tabs.length) {
            this.app.addLoading(this.service.getAcademicYears(store.getPerson()?.Upn)).subscribe(data => {
                this.academicYears = data;

                const curr = data.find(t => t.IsCurrent);

                if (!this.selectedYearId) {
                    this.selectedYearId = curr?.Id;
                }
            });
        }
    }

    private reset() {
        this.tabs = [];
        this.tabsReady = false;
        this.activeTabId = 1;
        this.academicYears = [];
        this.selectedYearId = null;
        this.seminarId = null;
    }
}
