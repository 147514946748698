import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { PlanSummaryService } from 'src/app/services/plan-summary.service';
import { currentRequestParams } from '../plan-summary.component';
import { IPlanSummaryDocument } from 'src/app/models/PlanSummary';
import { ITableColumn } from 'src/app/shared/table/table.component';
import { finalize } from 'rxjs/operators';

interface DocumentRow extends IPlanSummaryDocument {
	IsExpanded?: boolean;
	IsLoading?: boolean;
}

@Component({
	selector: 'app-plan-summary-documents',
	templateUrl: './plan-summary-documents.component.html',
	styleUrls: ['./plan-summary-documents.component.scss']
})
export class PlanSummaryDocumentsComponent implements OnInit {

	constructor(
		private app: AppService,
        private service: PlanSummaryService
	) { }

	documents: DocumentRow[] = [];
	searchValue: string;
	isLoaded: boolean = false;

	readonly columns: ITableColumn[] = [
		{ property: 'Date', label: 'planSummary_documents_lblDate', sorts: true, type: "date" },
		{ property: 'RegNr', label: 'planSummary_documents_lblRegNrType', sorts: true },
		{ property: 'Name', label: 'planSummary_documents_lblName', sorts: true },
		{ property: 'StartDate', label: 'planSummary_documents_lblStartEndDate', sorts: true, type: "date" },
		{ property: 'StatusName', label: 'planSummary_documents_lblStatusName', sorts: true },
		{ property: 'Source', label: 'planSummary_documents_lblSource', sorts: true },
		{ width: '1px'}
	];

	private allDocuments: DocumentRow[] = [];

	ngOnInit() {
		this.app.addLoading(this.service.getDocuments(currentRequestParams.studentEmail)).subscribe(data => {
			this.allDocuments = this.documents = data;
			this.isLoaded = true;
		})
	};

	getDocumentFiles(documentId: number) {
		
		const document = this.documents.find(t => t.Id == documentId);
		if (document.Files) return;

		document.IsLoading = true;
		this.service.getDocumentFiles(documentId, currentRequestParams.studentEmail).pipe(finalize(() => {
			document.IsLoading = false;
		})).subscribe(data => {
			document.Files = data;
		});
	}
	
	getFileDownloadUrl(documentId: number, fileId: number, fileName: string) {
		return this.service.getFileDownloadUrl(documentId, fileId, fileName, currentRequestParams.studentEmail);
	}

	search() {
		if (!this.searchValue) {
			this.documents = this.allDocuments;
		} else {
			const sv = this.searchValue?.toLowerCase();

			this.documents = this.allDocuments.filter(t => {
				return (t.Name?.toLowerCase().includes(sv)
					|| t.RegNr?.toLowerCase().includes(sv)
					|| t.RegNr?.toLowerCase().includes(sv)
					|| t.TypeName?.toLowerCase().includes(sv)
					|| t.Source?.toLowerCase().includes(sv)
					|| t.StatusName?.toLowerCase().includes(sv))
			})
		}
    }
}
