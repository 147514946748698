<div class="form-field" [class.required]="required" *ngIf="type === 'Text' || type === 'Integer' || type === 'Money'">
	<label class="form-label">{{label}}</label>
	<input class="form-control"
		   [ngModel]="value"
		   (ngModelChange)="change($event)"
		   (blur)="onBlur()"
		   [name]="name"
		   [required]="required"
		   [disabled]="disabled"
		   [pattern]="pattern" />
</div>

<div class="form-field" [class.required]="required" *ngIf="type === 'Multiline'">
	<label class="form-label">{{label}}</label>
	<textarea class="form-control"
			  [ngModel]="value"
			  (ngModelChange)="change($event)"
			  [name]="name"
			  [required]="required"
			  [disabled]="disabled"></textarea>
</div>

<div class="form-field" [class.required]="required" *ngIf="type === 'Date'">
	<label class="form-label">{{label}}</label>
	<app-date [ngModel]="value"
			  (ngModelChange)="change($event)"
			  [name]="name"
			  [required]="required"
			  [disabled]="disabled"></app-date>
</div>

<div class="form-field" [class.required]="required" *ngIf="type === 'Select'">
	<label class="form-label">{{label}}</label>
	<app-select [ngModel]="value"
				[name]="name"
				[required]="required"
				[disabled]="disabled"
				[options]="choices"
				[displayProperty]="'text'"
				[valueProperty]="'value'"
				(ngModelChange)="change($event)"></app-select>
</div>

<div class="form-field" [class.required]="required" *ngIf="type === 'Autocomplete'">
	<label class="form-label">{{label}}</label>
	<app-autocomplete [ngModel]="value"
					  [name]="name"
					  limit="100"
					  [required]="required"
					  [options]="choices"
					  [displayProperty]="'text'"
					  [valueProperty]="'value'"
					  (search)="onSearch($event)"
					  (ngModelChange)="change($event)"></app-autocomplete>
</div>
