import { Component, Input } from '@angular/core';

import { ISurveyQuestion, SurveyQuestionEvaluationType } from '../../../models/Survey';
import { SurveyUtils } from '../SurveyUtils';

@Component({
    selector: 'app-survey-question',
    templateUrl: './survey-question.component.html',
    styleUrls: ['../survey.component.scss']
})
export class SurveyQuestionComponent {
    @Input() question: ISurveyQuestion;
    @Input() model: Record<string, any> = {};
    @Input() validate: boolean;

    readonly util = SurveyUtils;
    readonly questionTypes = SurveyQuestionEvaluationType;
}
