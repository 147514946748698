import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';

import { IExternalServiceConfig, IExternalServiceConfigSaveModel } from '../../models/ExternalServiceConfig';
import { AppService } from '../../services/app.service';
import { ExternalServiceConfigService } from '../../services/external-service-config.service';
import { MessageService } from '../../services/message.service';

@Component({
    selector: 'app-external-service-config-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class ExternalServiceConfigFormComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        private app: AppService,
        private service: ExternalServiceConfigService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    item = <IExternalServiceConfig>{};
    model = <IExternalServiceConfigSaveModel>{};

    isNew: boolean;
    submitted: boolean;
    howTo: string;
    title: string;

    readonly editorConfig = {
        convert_urls: false,
        forced_root_block: '',
        entity_encoding: 'raw',
        plugins: 'link textcolor table code lists',
        toolbar:
            'undo redo | formatselect | bold italic forecolor backcolor'
            + ' | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            + ' | table link | code'
    };

    get isDraft(): boolean {
        return this.item.Status === 'Draft';
    }

    get isPublished(): boolean {
        return this.item.Status === 'Published';
    }

    get isDeactivated(): boolean {
        return this.item.Status === 'Deactivated';
    }

    @ViewChild('form', { static: true }) private form: NgForm;

    private origModel: IExternalServiceConfigSaveModel;
    private unsavedConfirmation?: boolean;
    private dataSaved: boolean;

    ngOnInit() {
        const isEn = this.app.currentLanguage === 'en';

        this.route.params.subscribe(para => {
            const id = para['id'];

            if (!id) {
                this.isNew = true;

                this.item.Status = 'Draft';
                this.item.Version = 1;

                this.origModel = JSON.parse(JSON.stringify(this.model));
            } else {
                this.isNew = false;

                this.app.addLoading(this.service.getById(+id)).subscribe(data => {
                    this.title = data.Code;
                    this.item = data;

                    this.model.Id = data.Id;
                    this.model.Code = data.Code;
                    this.model.NameEN = data.NameEN;
                    this.model.NameLV = data.NameLV;
                    this.model.Content = data.Content;
                    this.model.DateFrom = data.DateFrom;
                    this.model.DateTo = data.DateTo;

                    this.origModel = JSON.parse(JSON.stringify(this.model));
                });
            }
        });

        this.app.addLoading(this.messageService.getByCode('EXTERNAL_SERVICE_CONFIG_HOW_TO')).subscribe(data => {
            this.howTo = data && (isEn ? data.TextEN : data.TextLV);
        });
    }

    canDeactivate(): Observable<boolean> {
        if (!this.dataSaved) {
            if (this.unsavedConfirmation !== undefined) {
                return of(this.unsavedConfirmation);
            } else if (this.hasChanges()) {
                const subj = new Subject<boolean>();

                this.app.confirm(this.app.translate('externalServiceConfig_confirmUnsaved'), result => {
                    // for some reason, canDeactivate fires twice if there are changes
                    // store user confirmation result for a short period of time to prevent duplicate popups
                    this.unsavedConfirmation = result;
                    setTimeout(() => {
                        this.unsavedConfirmation = undefined;
                    }, 100);

                    subj.next(result);
                });

                return subj.asObservable();
            }
        }

        return of(true);
    }

    save() {
        if (!this.form.valid) {
            this.submitted = true;
            this.app.alert.warning(this.app.translate('invalidFormWarning'));
            return;
        }

        this.app.addLoading(this.service.save(this.model)).subscribe(id => {
            this.dataSaved = true;
            this.app.alert.success(this.app.translate('externalServiceConfig_saved'));
            this.router.navigate(['/external/config', id]);
        }, this.handleHttpError);
    }

    export() {
        const data = JSON.stringify({
            NameEN: this.model.NameEN,
            NameLV: this.model.NameLV,
            Content: this.model.Content,
            DateFrom: this.model.DateFrom,
            DateTo: this.model.DateTo
        });
        const a = document.createElement('a');
        a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`;
        a.download = `external-service-config.${this.model.Code}.json`;
        a.click();
    }

    import(file: File) {
        const onError = (err) => {
            console.error(err);
            this.app.showError(this.app.translate('externalServiceConfig_importFailed'));
        };

        const reader = new FileReader();
        reader.onload = e => {
            try {
                const json = JSON.parse(<string>e.target.result) as IExternalServiceConfig;

                if (this.item.Status == 'Draft') {
                    this.model.Code = json.Code;
                    this.model.NameEN = json.NameEN;
                    this.model.NameLV = json.NameLV;
                }
                
                this.model.Content = json.Content;
                this.model.DateFrom = json.DateFrom;
                this.model.DateTo = json.DateTo;

                this.app.alert.info(this.app.translate('externalServiceConfig_importSucceeded'));
            } catch (err) {
                onError(err);
            }
        };
        reader.onerror = e => onError(e);
        reader.readAsText(file);
    }

    async publish() {
        await this.saveInternal(id => {
            this.app.addLoading(this.service.publish(id)).subscribe(() => {
                this.router.navigate(['/external/config', id]);
                this.app.alert.success(this.app.translate('externalServiceConfig_publishSucceeded'));
            }, this.handleHttpError);
        });
    }

    unpublish() {
        this.app.confirm({
            text: this.app.translate('externalServiceConfig_confirmUnpublish')
        }, result => {
            if (!result) return;

            this.app.addLoading(this.service.unpublish(this.item.Id)).subscribe(() => {
                this.item.Status = 'Draft';
                this.app.alert.success(this.app.translate('externalServiceConfig_unpublishSucceeded'));
            }, this.handleHttpError);
        });
    }

    activate() {
        this.app.addLoading(this.service.activate(this.item.Id)).subscribe(() => {
            this.item.Status = 'Published';
            this.app.alert.success(this.app.translate('externalServiceConfig_activateSucceeded'));
        }, this.handleHttpError);
    }

    deactivate() {
        this.app.addLoading(this.service.deactivate(this.item.Id)).subscribe(() => {
            this.item.Status = 'Deactivated';
            this.app.alert.success(this.app.translate('externalServiceConfig_deactivateSucceeded'));
        }, this.handleHttpError);
    }

    private async saveInternal(callback: (id: number) => void) {
        this.app.addLoading(this.service.save(this.model)).subscribe(id => {
            callback(id);
        }, this.handleHttpError);
    }

    private hasChanges(): boolean {
        if (!this.origModel) return false;

        const m = this.model;
        const o = this.origModel;

        return m.Code !== o.Code
            || m.NameEN !== o.NameEN
            || m.NameLV !== o.NameLV
            || m.Content !== o.Content
            || m.DateFrom !== m.DateFrom
            || m.DateTo !== m.DateTo;
    }

    private readonly handleHttpError = (err: any) => {
        let handled = false;

        if (err.json) {
            const text: string = err.json();
            const parts = text.split(':')

            if (parts.length === 2 && parts[0] === 'externalServiceConfig_codeTaken') {
                handled = true;
                this.app.showError(this.app.translate(parts[0]).replace('{code}', parts[1]));
                return;
            }
        }

        if (!handled) {
            throw err;
        }
    }
}
