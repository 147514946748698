import { Input } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-alert',
    template: `
<div class="alert {{level ? 'alert-' + level : ''}}">
    <div class="alert-icon {{level ? 'alert-icon-' + level : ''}}">
        <i class="fi fi-rs-{{icon}}"></i>
    </div>
    <div class="alert-body">
        <ng-content></ng-content>
    </div>
</div>
`
})
export class AlertComponent {
    @Input() level: 'info' | 'warning' | 'danger' | 'success';

    get icon(): string {
        switch (this.level) {
            case 'danger':
            case 'warning':
                return 'exclamation';
            case 'success':
                return 'check';
            case 'info':
            default: return 'info';
        }
    }
}
