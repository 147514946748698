import { Component, OnInit } from '@angular/core';
import { DocumentTemplate, DocumentTemplateType } from '../../models/Template';
import { UserInfo } from '../../models/UserInfo';

import { AppService } from '../../services/app.service';
import { TemplateService } from '../../services/template.service';

import { TranslateService } from '@ngx-translate/core';
import { ClassifierService } from '../../services/classifier.service';
import { ITableColumn } from '../../shared/table/table.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplateListComponent implements OnInit {
    constructor(public app: AppService,
        private service: TemplateService,
        private translate: TranslateService,
        private classifier: ClassifierService
    ) { }

    readonly columns: ITableColumn[] = [
        { property: 'TemplateCode', label: 'templates_lblCodeTitle', sorts: true },
        {
            property: 'Type', label: 'templates_lblType', sorts: true, sortFn: (a: DocumentTemplate, b: DocumentTemplate) => {
                const at: string = this.app.translate(`documentTemplateType_${a.Type}`);
                const bt: string = this.app.translate(`documentTemplateType_${b.Type}`);
                
                return at.localeCompare(bt);
            }
        },
        { property: 'UseCode', label: 'templates_lblUseCode', sorts: true },
        { property: 'DateFrom', label: 'templates_lblDateFrom', sorts: true, type: 'date' },
        { property: 'DateTo', label: 'templates_lblDateTo', sorts: true, type: 'date' },
        { width: '1px' }
    ];

    readonly typeOptions = Object.keys(DocumentTemplateType);
    readonly typeDisplayFn = (type: DocumentTemplateType) => this.app.translate(`documentTemplateType_${type}`);

    items: DocumentTemplate[] = [];
    currentUser: UserInfo;
    canEdit: boolean = false;
    canDelete: boolean = false;
    userRights: { Code: string, Text: string }[] = [];
    selectedTypes: DocumentTemplateType[] = [];
    searchValue: string = '';

    private allItems: DocumentTemplate[] = [];
    private readonly searchSubj = new Subject<string>();
    

    ngOnInit() {
        this.currentUser = this.app.currentUser;

        if (this.currentUser) {
            this.canEdit = (this.currentUser.rights.indexOf('ES_MAIN.TEMPLATES.EDIT') > -1);
            this.canDelete = (this.currentUser.rights.indexOf('ES_MAIN.TEMPLATES.DELETE') > -1);
        }

        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            if (!term) {
                this.searchValue = '';
            } else if (term.length > 2) {
                this.searchValue = term.toLowerCase();
            }

            this.applyFilters();
        });

        this.loadItems();

        this.app.addLoading(this.classifier.getUDR(this.translate.currentLang)).subscribe(data => {
            let userRights = data.filter(r => r.Code.startsWith("UDR"));
            this.userRights = this.userRights.concat(userRights);
        });
    }

    onTypeFilterChange(event: DocumentTemplate[]) {
        this.applyFilters();
    }

    search(event) {
        this.searchSubj.next(event.target.value);
    }

    getFileUrl(item: DocumentTemplate, language: string) {
        return this.service.getFileUrl(item.Id, language);
    }

    delete(item: DocumentTemplate) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(item.Id)).subscribe(() => {
                this.loadItems();
            });
        });
    }

    private loadItems() {
        this.app.addLoading(this.service.getAll()).subscribe(data => {
            this.items = this.allItems = data;         
        });
    }

    private applyFilters() {
        this.items = this.allItems.filter(t => {
            if (this.selectedTypes.length) {
                if (!this.selectedTypes.includes(t.Type)) return false;
            }

            if (this.searchValue.length > 2) {
                if (!t.TemplateCode?.toLowerCase().includes(this.searchValue)
                    && !t.Title?.toLowerCase().includes(this.searchValue)
                ) {
                    return false;
                }
            }

            return true;
        });
    }
}
