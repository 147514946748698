<div class="index-body">
    <main *ngIf="ready">
        <app-alert level="info" *ngIf="!types.length && showNoItemAvailable">
            {{'purchases_noItemAvailable' | translate}}
        </app-alert>

        <ul class="link-list" *ngIf="types.length">
            <ng-container *ngFor="let t of types">
                <li>
                    <a [routerLink]="['/purchases', t.name] | localize">{{t.title}}</a>
                </li>
            </ng-container>
        </ul>
    </main>

    <aside *ngIf="showNav">
        <a class="btn btn-primary btn-icon-last" [routerLink]="['/purchases/my'] | localize">
            {{'myPurchases_title' | translate}}
            <i class="fi fi-rs-angle-right"></i>
        </a>
    </aside>
</div>
