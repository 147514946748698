import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IHospital, IHospitalDoctor, IHospitalRotation, IHospitalSeminar, IHospitalStudent } from '../models/Hospital';
import { IAcademicYear } from '../models/AcademicYear';

/**
 * Hospital service
 */
@Injectable({ providedIn: "root" })
export class HospitalService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/hospitals` }

    get(upn?: string): Observable<IHospital[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}`;
        return this.http.get<IHospital[]>(url, { params });
    }

    getStudents(id: string, upn?: string): Observable<IHospitalStudent[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/${id}/students`;
        return this.http.get<IHospitalStudent[]>(url, {params});
    }

    getSeminars(id: string, upn?: string): Observable<IHospitalSeminar[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/${id}/seminars`;
        return this.http.get<IHospitalSeminar[]>(url, {params});
    }

    getDoctors(id: string, upn?: string): Observable<IHospitalDoctor[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/${id}/doctors`;
        return this.http.get<IHospitalDoctor[]>(url, {params});
    }

    getRotations(id: string, upn?: string): Observable<IHospitalRotation[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/${id}/rotations`;
        return this.http.get<IHospitalRotation[]>(url, {params});
    }

    getAcademicYears(upn?: string): Observable<IAcademicYear[]> {
        const params = upn ? { upn } : {};
        const url = `${this.apiUrl}/academicYears`;
        return this.http.get<IAcademicYear[]>(url, {params});
    }
}
