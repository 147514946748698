import {
    Component, EventEmitter, Input, Output, ViewChild,
    ElementRef, HostListener, ContentChild, TemplateRef, forwardRef
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ControlContainer, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';

let counter = 0;

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ]
})
export class SelectComponent<T> implements ControlValueAccessor {
    constructor() {
        this.id = `select-${counter++}`;
    }

    @Input() placeholder: string = '';
    @Input() title: string = '';
    @Input() disabled: boolean;
    @Input() options: T[] = [];
    @Input() multiple: boolean;
    @Input() required: boolean;
    @Input('clear') showClearOption?: boolean;
    @Input() valueFn: (item: T) => any;
    @Input() valueProperty: string;
    @Input() displayFn: (item: T) => string;
    @Input() displayProperty: string;
    @Input('filter') filterEnabled: boolean;
    @Input() filterFn: (item: T, value: string) => boolean = (i, v) => i?.toString().toLowerCase().includes(v?.toLowerCase());

    @Output() selectionChange = new EventEmitter<T | T[] | any>();

    @ContentChild('optionTemplate', { static: true }) optionTemplate: TemplateRef<any>;

    readonly id: string;

    currentOption: T;
    filterValue: string;
    isFilterActive: boolean;
    isClearActive: boolean;
    isMenuReady: boolean;
    alignBefore: boolean;

    get clearEnabled(): boolean {
        return (this.showClearOption == true || this.showClearOption == false) ? this.showClearOption : !this.required;
    }

    get displayValue(): string {
        if (this.multiple) {
            const arr = this._value as T[] || [];

            if (arr.length) {
                const selected = (this.options || []).filter(t => arr.includes(this.getOptionValue(t)));
                return selected.map(t => this.getOptionDisplay(t)).join('; ');
            }
        } else {
            const selected = (this.options || []).find(t => this.getOptionValue(t) == this._value);
            return (selected && this.getOptionDisplay(selected)) || '';
        }

        return '';
    }

    get visibleOptions(): T[] {
        if (this.filterValue) {
            return this.options.filter(t => this.filterFn(t, this.filterValue));
        }

        return this.options;
    }

    get isOpened(): boolean {
        return this._isOpened;
    }

    @ViewChild('wrapper', { static: true }) private wrapperElement: ElementRef;
    @ViewChild('trigger', { static: true }) private triggerElement: ElementRef;
    @ViewChild('filter', { static: false }) private filterElement: ElementRef;
    @ViewChild('menu', { static: false }) private menuElement: ElementRef;

    private _onChange = (obj: T | T[] | any) => { };
    private _onTouched: any = () => { };
    private _value: T | T[] | any;
    private _isOpened: boolean;

    //ControlValueAccessor
    writeValue(obj: T | T[] | any): void {
        this._value = obj;
    }

    registerOnChange(fn: (obj: T | T[] | any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //ControlValueAccessor

    toggle() {
        this._onTouched();

        if (this.isOpened) {
            this.close();
        } else {

            this.open();

            setTimeout(() => {
                if (this.filterEnabled) {
                    this.filterElement.nativeElement.focus();
                } else {
                    this.triggerElement.nativeElement.focus();
                }
            }, 1);
        }
    }

    open() {
        if (this.isOpened) {
            return;
        }

        const rect = this.wrapperElement.nativeElement.getBoundingClientRect();
        const spaceBefore = rect.top;
        const spaceAfter = document.body.clientHeight - rect.bottom;

        this._isOpened = true;

        setTimeout(() => {
            const menuRect = this.menuElement?.nativeElement.getBoundingClientRect();

            if (menuRect) {
                this.alignBefore = spaceAfter < menuRect.height && spaceBefore > spaceAfter && spaceBefore > menuRect.height;
            }

            this.isMenuReady = true;
        }, 10);

        this.filterValue = undefined;
        this.isFilterActive = false;
        this.isClearActive = false;
        this.currentOption = undefined;
    }

    close() {
        if (!this.isOpened) {
            return;
        }

        this._isOpened = false;
        this.isMenuReady = false;
        this.currentOption = undefined;
    }

    clear() {
        if (this.multiple) {
            this._value = [];
        } else {
            this._value = undefined;
            this.close();
        }

        this.emitChange();
    }

    select(option: T) {
        this.updateValue(option);

        if (!this.multiple) {
            this.close();
            this.focus();
        }

        this.emitChange();
    }

    isSelected(option: T) {
        const val = this.getOptionValue(option);
        return this.multiple ? (this._value as T[]).includes(val) : val == this._value;
    }

    onFilter(event) {
        this.cancelEvent(event);

        if (this.filterValue != event.target.value) {
            this.filterValue = event.target.value;
            this.currentOption = undefined;
        }
    }

    onEnter(event: KeyboardEvent) {
        this.cancelEvent(event);

        if (this.isOpened) {
            if (this.currentOption) {
                const ix = this.visibleOptions.indexOf(this.currentOption);

                this.select(this.currentOption);
                this.focus(`option-${ix}`);
            } else if (this.isClearActive) {
                this.clear();
                this.focus('option-none');
            }
        } else {
            const selectedOption = this.options.find(t => this._value == this.getOptionValue(t));

            this.open();

            if (selectedOption) {
                this.updateValue(selectedOption);
                this._onChange(this._value);
            } else {
                setTimeout(() => {
                    if (this.filterEnabled) {
                        this.isFilterActive = true;
                        this.filterElement.nativeElement.focus();
                    } else if (this.clearEnabled) {
                        this.isClearActive = true;
                    }
                }, 1);
            }
        }
    }

    onEscape(event: KeyboardEvent) {
        this.cancelEvent(event);
        this.close();
        this.focus();
    }

    onArrowDown(event) {
        this.cancelEvent(event);

        if (this.isOpened) {
            const ix = this.visibleOptions.indexOf(this.currentOption);

            let toFilter = false;
            let toClear = false;

            if (ix == -1) {
                if (!this.isClearActive && !this.isClearActive) {
                    toFilter = !this.isFilterActive && this.filterEnabled;

                    if (!toFilter) {
                        toClear = !this.isClearActive && this.clearEnabled;
                    }
                }
            } else {
                // last option
                if (ix == this.visibleOptions.length - 1) {
                    return;
                }
            }

            // wait for the panel to be opened
            setTimeout(() => {
                if (toFilter) {
                    this.isFilterActive = true;
                    this.filterElement.nativeElement.focus();
                } else if (toClear) {
                    this.isFilterActive = false;
                    this.isClearActive = true;
                    this.currentOption = undefined;
                } else {
                    this.isFilterActive = false;
                    this.isClearActive = false;
                    this.currentOption = this.visibleOptions[ix + 1];
                    this.focus(`option-${ix + 1}`);
                }
            }, 1);
        } else if (this.multiple) {
            this.open();
        } else {
            const selectedOptionIx = this.options.findIndex(t => this._value == this.getOptionValue(t));
            let nextIx = selectedOptionIx;

            if (selectedOptionIx == -1) {
                nextIx = 0;
            } else if (selectedOptionIx < this.options.length - 1) {
                nextIx = selectedOptionIx + 1;
            }

            if (nextIx != selectedOptionIx) {
                const next = this.options[nextIx];
                this.updateValue(next);
                this.emitChange();
            }
        }
    }

    onArrowUp(event) {
        this.cancelEvent(event);

        if (this.isOpened) {
            const ix = this.visibleOptions.indexOf(this.currentOption);

            if (ix == 0) {
                // first option can go to clear or filter
                this.currentOption = undefined;
                this.isClearActive = !this.required;
                this.isFilterActive = this.filterEnabled && !this.isClearActive;
            } else if (ix > 0) {
                // another option can go to the previous option
                this.currentOption = this.visibleOptions[ix - 1];
                this.focus(`option-${ix - 1}`);
            } else if (this.isFilterActive) {
                // filter can only close
                this.currentOption = undefined;
            } else if (this.isClearActive) {
                // clear can go only to filter
                this.isClearActive = !this.filterEnabled;
                this.isFilterActive = this.filterEnabled;
                this.currentOption = undefined;
            }

            if (this.isClearActive) {
                this.focus('option-none');
            } else if (this.isFilterActive) {
                if (this.filterElement.nativeElement != document.activeElement) {
                    this.filterElement.nativeElement.focus();
                }
            }
        } else {
            const selectedOptionIx = this.options.findIndex(t => this._value == this.getOptionValue(t));
            let nextIx = selectedOptionIx;

            if (selectedOptionIx != -1) {
                if (selectedOptionIx > 0) {
                    nextIx = selectedOptionIx - 1;
                } else if (this.clearEnabled) {
                    this.clear();
                }

                if (nextIx != selectedOptionIx) {
                    const next = this.options[nextIx];
                    this.updateValue(next);
                    this.emitChange();
                }
            }
        }
    }

    onOptionTab(event) {
        this.close();
    }

    onFilterTab(event) {
        this.close();
    }

    getOptionDisplay(option: T): string {
        let fn = this.displayFn;

        if (!fn) {
            if (this.displayProperty) {
                fn = (item: T) => item ? item[this.displayProperty] : '';
            }
        }

        if (!fn) {
            fn = (item: T) => item?.toString();
        }

        return fn(option);
    }

    getOptionValue(option: T): any {
        let value: any;

        if (this.valueFn) {
            value = this.valueFn(option);
        } else if (this.valueProperty) {
            value = option ? option[this.valueProperty] : null;
        } else {
            value = option;
        }

        return value;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event) {
        if (this.isOpened) {
            if (!event.target.closest(`.${this.id}`)) {
                this.close();
            }
        }
    }

    private updateValue(option: T) {
        this.currentOption = option;

        const val = this.getOptionValue(option);

        if (this.multiple) {
            if (!this._value) this._value = [];

            const arr = this._value as T[];
            const ix = arr.indexOf(val);

            if (ix == -1) {
                arr.push(val);
            } else {
                // if multiple allowed, remove the option if it's already there
                arr.splice(ix, 1);
            }

            this._value = [...arr];
        } else {
            this._value = val;
        }
    }

    private focus(elementPartialId?: string) {
        if (elementPartialId) {
            const el = document.getElementById(`${this.id}-${elementPartialId}`);

            if (el) {
                el.focus();
            }
        } else {
            this.triggerElement.nativeElement.focus();
        }
    }

    private cancelEvent(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    private emitChange() {
        this._onChange(this._value);
        this.selectionChange.emit(this._value);
    }
}
