import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApplicationConfig, ApplicationConfigSaveModel, IApplicationConfigListItem } from '../models/ApplicationConfig';

import { environment as ENV } from '../../environments/environment';

/**
 * Application configuration service
 */
@Injectable({ providedIn: "root" })
export class ApplicationConfigService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/applicationConfig` }

    get(): Observable<IApplicationConfigListItem[]> {
        return this.http.get<IApplicationConfigListItem[]>(this.apiUrl);
    }

    getById(id: number): Observable<ApplicationConfig> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<ApplicationConfig>(url);
    }

    save(item: ApplicationConfigSaveModel): Observable<number> {
        return this.http.put<number>(this.apiUrl, item)
    }

    publish(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/publish`;
        return this.http.post(url, null);
    }

    unpublish(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/unpublish`;
        return this.http.post(url, null);
    }

    activate(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/activate`;
        return this.http.post(url, null);
    }

    deactivate(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/deactivate`;
        return this.http.post(url, null);
    }

    delete(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }

    testDataSource(database: string, procedure: string): Observable<any[]> {
        const url = `${this.apiUrl}/testDataSource`;
        return this.http.get<any[]>(url, {
            params: {
                database,
                procedure
            }});
    }

    /**
     * Get available base template tags.
     */
    getBaseTemplateTags(): Observable<string[]> {
        const url = `${this.apiUrl}/baseTemplateTags`;
        return this.http.get<string[]>(url);
    }

    getDatabases(): Observable<string[]> {
        const url = `${this.apiUrl}/databases`;
        return this.http.get<string[]>(url);
    }
}
