import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IFinalPaperProtocol, IFinalPaperProtocolFile } from '../models/FinalPaperProtocol';
import { AuthService } from './auth.service';

/**
 * Final paper protocol service
 */
@Injectable({ providedIn: "root" })
export class FinalPaperProtocolService {
    constructor(private http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/finalPaperProtocols` }

    get(upn?: string): Observable<IFinalPaperProtocol[]> {
        const url = this.apiUrl;
        const params = upn ? { upn } : {};
        return this.http.get<IFinalPaperProtocol[]>(url, { params });
    }

    getFiles(id: number, upn?: string): Observable<IFinalPaperProtocolFile[]> {
        const url = `${this.apiUrl}/${id}/files`;
        const params = upn ? { upn } : {};
        return this.http.get<IFinalPaperProtocolFile[]>(url, { params });
    }

    getFileDownloadUrl(protocolId: number, fileId: number, upn?: string): string {
        return `${this.apiUrl}/${protocolId}/files/${fileId}/download?upn=${upn}&SessionKey=${this.auth.sessionKey}`;
    }
}
