import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SchedulerProConfig } from '@bryntum/schedulerpro';
import { AppDatePipe } from '../../../pipes/date.pipe';
import { Utils } from '../../../core/Utils';
import { DoctorService } from '../../../services/doctor.service';
import { IDoctorRotation } from '../../../models/Doctor';
import { store } from '../store';

const cache: {
    rotations: IDoctorRotation[],
    user: string
} = {
    rotations: undefined,
    user: undefined
};

@Component({
    selector: 'app-doctor-dashboard-rotations',
    templateUrl: './doctor-dashboard-rotations.component.html'
})
export class DoctorDashboardRotationsComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: DoctorService,
        private appDate: AppDatePipe
    ) { }

    @Input() set academicYearId(value: string) {
        this._academicYearId = value;
        this.init();
    }

    readonly schedulerModel = {
        resources: [],
        events: [],
        assignments: []
    };

    readonly schedulerConfig: Partial<SchedulerProConfig> = {
        columns: [
            { text: 'Name', field: 'name', width: 160 },
            { text: 'Surname', field: 'surname', width: 160 }
        ],
        features: {
            cellMenu: false,
            eventDrag: false,
            eventCopyPaste: false,
            eventEdit: false,
            eventMenu: false,
            eventResize: false,
            taskEdit: false,
            dependencies: false,
            scheduleMenu: false,
            eventTooltip: {
                template: data => {
                    const rec: IDoctorRotation = data.eventRecord['originalData'].data;

                    const df = this.appDate.transform(rec.DateFrom);
                    const dt = this.appDate.transform(rec.DateTo);
                    const lecturers = (rec.Lecturers || []).map(t => t.Name + ' ' + t.Surname).join(', ');

                    return `<div><small>${rec.SubjectCode}</small></div>`
                        + `<div class="mb-2">${rec.SubjectName}</div>`
                        + `<div>${df} - ${dt}</div>`
                        + `<div class="mb-2">${rec.WeekCount} ${this.app.translate('doctorDashboard_weeksPostfix')}</div>`
                        + `<div><i class="fi fi-rs-user"></i> ${lecturers}</div>`
                        + `<div><i class="fi fi-rs-marker"></i> ${rec.CompanyName || ''}</div>`;
                }
            }
        },
        autoHeight: true,
        readOnly: true,
        weekStartDay: 1
    };

    searchTerm: string;

    get academicYearId(): string {
        return this._academicYearId;
    }

    private _academicYearId: string;
    private rotations: IDoctorRotation[] = [];

    private readonly eventColors = ['#669F2A', '#15B79E', '#0BA5EC', '#2970FF', '#6172F3', '#7A5AF8', '#D444F1', '#F63D68', '#EF6820', '#EAAA08'];
    private readonly schedulerFilterSubj = new Subject<string>();

    ngOnInit() {
        (this.schedulerConfig.columns as any[]).forEach(t => {
            t.text = this.app.translate(`doctorDashboard_rotations_lbl${t.text}`);
        });

        this.schedulerFilterSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            this.buildScheduler(this.rotations, term);
        });
    }

    filter() {
        this.schedulerFilterSubj.next(this.searchTerm);
    }

    private init() {
        const process = (data: IDoctorRotation[]) => {
            const byYear = this.filterByAcademicYear(data);

            this.rotations = byYear;

            this.buildScheduler(byYear);
        };

        if (cache.rotations && cache.user == store.getPerson()?.Upn) {
            process(cache.rotations);
        } else {
            cache.user = store.getPerson()?.Upn;
            this.app.addLoading(this.service.getRotations(store.getPerson()?.Upn)).subscribe(data => {
                cache.rotations = data;
                process(data);
            });
        }
    }

    private filterByAcademicYear(data: IDoctorRotation[]) {
        return data.filter(t => t.AcademicYearId == this.academicYearId);
    }

    private buildScheduler(data: IDoctorRotation[], filter?: string) {
        let minDate: Date;
        let maxDate: Date;

        const events = [];
        const assignments = [];
        const resources = [];

        filter = (filter || '').toLowerCase();

        let currentColorIndex = 0;

        data.forEach(t => {
            const subjectName = `${t.SubjectCode} ${t.SubjectName}`;
            const studentName = `${t.Student.Name} ${t.Student.Surname}`;
            const programName = `${t.Student.ProgramCode} ${t.Student.ProgramName}`;

            if (!filter
                || subjectName.toLowerCase().includes(filter)
                || studentName.toLowerCase().includes(filter)
                || programName.toLowerCase().includes(filter)
            ) {
                const bd = Utils.ensureDate(t.DateFrom);
                const ad = Utils.ensureDate(t.DateTo);

                if (!minDate || bd < minDate) {
                    minDate = bd;
                }

                if (!maxDate || ad > maxDate) {
                    maxDate = ad;
                }

                events.push({
                    id: t.Id,
                    startDate: bd,
                    endDate: ad,
                    name: subjectName,
                    data: t,
                    eventColor: this.eventColors[currentColorIndex]
                });

                if (!resources.some(n => n.id == t.Student.Id)) {
                    resources.push({
                        id: t.Student.Id,
                        name: t.Student.Name,
                        surname: t.Student.Surname,
                        fullName: studentName
                    });
                }

                assignments.push({
                    event: t.Id,
                    resource: t.Student.Id
                });

                currentColorIndex++;

                if (currentColorIndex == this.eventColors.length) {
                    currentColorIndex = 0;
                }
            }
        });

        this.schedulerConfig.startDate = minDate;
        this.schedulerConfig.endDate = maxDate;

        this.schedulerModel.resources = resources;
        this.schedulerModel.assignments = assignments;
        this.schedulerModel.events = events;
    }
}
