import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { AppService } from '../../services/app.service';
import { StudentBalanceService } from '../../services/student-balance.service';
import { PersonService } from '../../services/person.service';

import { StudentBalanceInvoiceComponent } from './student-balance-invoice.component';
import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { ITableColumn } from '../../shared/table/table.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const contractViewName = 'Contracts';
const paymentViewName = 'Payments';

export const numberFormat: string = '1.2-2';

@Component({
    selector: 'app-student-balance',
    templateUrl: './student-balance.component.html',
    styleUrls: ['./student-balance.component.css']
})
export class StudentBalanceComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: StudentBalanceService,
        private studentService: PersonService,
        private messages: MessageService,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private decimalPipe: DecimalPipe
    ) { }

    view: string = contractViewName;
    otherContractsVisible: boolean;
    isLoaded: boolean;
    isDenied: boolean;
    data: any;
    info: string;
	horizonIdError: string;
    paymentsRowsPerPage: number;
    maxVisibleInvoices: number;
    actualContractCount: number = 0;
    today: Date = new Date();
    hasHiddenContracts: boolean;
    numberFormat = numberFormat;

    studentPickerOpened: boolean;
    canPickStudent: boolean;
    student: IPersonSearchResultItem;
    contractTempData = {};
    payData: string;
    payFormUrl: string;
    isAction: boolean;

    customerId: string;
    customerIdType: string;

    unavailableText: string;
    isUnavailable: boolean;

    rights = {
        view: false
    };

    readonly invoiceColumns: ITableColumn[] = [
        { property: 'Number', label: 'studentBalance_lblInvoiceNumberStatus', sorts: true, cssClass: 'hidden-md-up' },
        { property: 'Number', label: 'studentBalance_lblInvoiceNumber', sorts: true, cssClass: 'hidden-sm-down' },
        { property: 'Date', label: 'studentBalance_lblInvoiceDate', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { property: 'DueDate', label: 'studentBalance_lblInvoiceDueDate', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { property: 'Amount', label: 'studentBalance_lblInvoiceAmount', sorts: true, type: 'number', cssClass: 'hidden-md-down' },
        { property: 'PaymentDate', label: 'studentBalance_lblPaymentDate', sorts: true, type: 'date', cssClass: 'hidden-md-down' },
        { property: 'Status', label: 'studentBalance_lblPaymentStatus', sorts: true, cssClass: 'hidden-sm-down' },
        { width: '1px' }
    ];

    get showCustomer(): boolean {
        return !!this.explicitCustomerId;
    }

    @ViewChild('payForm', { static: true }) private payForm: ElementRef;

    private explicitCustomerId: string;
    private explicitCustomerEmail: string;
    private expanded: any[] = [];

    ngOnInit() {
        const params = this.route.snapshot.params;
        const query = this.route.snapshot.queryParams;
        const user = this.app.currentUser;

        if (params['action'] === 'pay') {
            this.isAction = true;

            let { contractId, customerId, customerIdType, customerEmail } = query;

            this.handlePay(contractId, customerId, customerIdType, customerEmail);

            return;
        }

        const isEn = this.app.currentLanguage === 'en';

        this.app.addLoading(this.messages.getByCodes(['STUDENT_BALANCE_UNAVAILABLE'])).subscribe(data => {
            const unav = data.find(t => t.Code === 'STUDENT_BALANCE_UNAVAILABLE');
            this.unavailableText = unav ? isEn ? unav.TextEN : unav.TextLV : undefined;
        });

        if (user) {
            this.rights.view = user.rights.indexOf('STUDENT_BALANCE.VIEW') > -1;
            this.canPickStudent = user.rights.indexOf('STUDENT_BALANCE.ADMIN') > -1 && user.rights.indexOf('ES_MAIN.STUDENTS.FIND') > -1;

            const id = query['id'];

            if (id && !this.canPickStudent) {
                this.isDenied = true;
                return;
            }

            this.customerId = query['CustId'];
            this.customerIdType = query['CustIdType'];

            if (this.customerId) {
                this.init(this.customerId, this.customerIdType);
            } else {
                if (this.canPickStudent) {
                    if (id) {
                        this.app.addLoading(this.studentService.findStudents('student_id', id)).subscribe(data => {
                            if (data.length) {
                                this.pickStudent(data[0]);
                            } else {
                                this.app.showError(this.app.translate('studentNotFound'));
                                this.toggleStudentPicker();
                            }
                        });
                    } else {
                        this.toggleStudentPicker();
                    }
                } else {
                    this.init();
                }
            }
        }        
    }

    switchView(view?: string) {
        if (view) {
            this.view = view;
        } else {
            this.view = this.view === contractViewName ? paymentViewName : contractViewName;
        }
    }

    openInvoice(invoice) {
        const ref = this.modal.open(StudentBalanceInvoiceComponent, {
            size: 'lg'
        });

        ref.componentInstance.item = invoice;
        ref.componentInstance.status = this.getInvoiceStatus(invoice);
        ref.componentInstance.numberFormat = numberFormat;
    }

    isSaldo(invoice): boolean {
        return invoice.Reason === 'Parāds/Pārmaksa uz 01.01.2015' || invoice.Reason === 'Saldo';
    }

    isActualContract(contract): boolean {
        return contract.Status != 2 || (contract.Debt || 0) != 0 || (contract.Balance || 0) > 0;
    }

    getInvoiceStatus(invoice): string {
        return invoice.Status == 0
            ? this.decimalPipe.transform(invoice.UnpaidAmount, this.numberFormat)
            : this.app.translate('studentBalance_invoiceStatus' + invoice.Status);
    }

    formatNumber(value): string {
        if (value === null || typeof value === 'undefined' || value === '')
            return '';

        return value.toFixed(2);
    }

    getVisibleInvoices(contract): any[] {
        let invoices = [];

        for (let i = 0; i < contract.Invoices.length; i++) {
            if (contract.Invoices[i].UnpaidAmount || i < this.maxVisibleInvoices)
                invoices.push(contract.Invoices[i]);
        }

        return invoices;
    }

    getContractInvoices(contract): any[] {
        return contract.otherInvoicesVisible ? contract.nonSaldoAll : contract.nonSaldoVisible;
    }

    toggleStudentPicker() {
        this.studentPickerOpened = !this.studentPickerOpened;
    }

    pickStudent(student: IPersonSearchResultItem) {
        const adfsLoginType = 'ADFSLogin';

        this.studentPickerOpened = false;
        this.student = student;
        this.init(student.StudentId, adfsLoginType);
    }

    pay(contract: any) {
        this.app.addLoading(this.service.pay(contract, this.explicitCustomerId, this.explicitCustomerEmail)).subscribe(response => {
            this.payData = response.Data;
            this.payFormUrl = response.Url;

            contract._paid = true;

            // does not work immediately
            setTimeout(() => {
                this.payForm.nativeElement.submit();
            }, 100);
        });
    }

    toggle(row: any) {
        const ix = this.expanded.indexOf(row);

        if (ix == -1) {
            this.expanded.push(row);
        } else {
            this.expanded.splice(ix, 1);
        }
    }

    isExpanded(row: any): boolean {
        return this.expanded.indexOf(row) > -1;
    }

    private handlePay(contractId: string, customerId?: string, customerIdType?: string, customerEmail?: string) {
        this.explicitCustomerId = customerId;
        this.explicitCustomerEmail = customerEmail;

        this.app.addLoading(this.service.get(customerId, customerIdType)).subscribe(result => {
			if (result.Data == null) {
				this.horizonIdError = result.HorizonIdError;
				return;
			}
			else
				this.horizonIdError = null;

            let data = result.Data;
            let payments = data.Payments || [];
            let contract = data.Contracts.find(t => t.Id == contractId);

            if (contract) {
                this.pay(contract);
            } else {
                this.app.showError(this.app.translate('studentBalance_contractNotFound'));
            }
        });
    }

    private init(studentId?: string, studentType?: string) {
        this.explicitCustomerId = studentId;
        this.customerId = studentId;
        this.customerIdType = studentType;

        this.app.addLoading(this.service.get(studentId, studentType)).subscribe(result => {
            if (result.Data == null) {
                this.horizonIdError = result.HorizonIdError;
                this.isUnavailable = true;
                return;
            }
            else {
                this.horizonIdError = null;
            }

            this.data = result.Data;
            this.info = result.Info;
            this.maxVisibleInvoices = result.MaxVisibleInvoices;
            this.paymentsRowsPerPage = result.PaymentsRowsPerPage;

            this.data.Payments = this.data.Payments || [];

            for (let i = 0; i < this.data.Contracts.length; i++) {
                let contract = this.data.Contracts[i];

                if (!contract.Invoices)
                    contract.Invoices = [];

                contract.Invoices.sort((a, b) => {
                    if (this.isSaldo(a) || this.isSaldo(b))
                        return 1;

                    let ad = Date.parse(a.DueDate);
                    let bd = Date.parse(b.DueDate);

                    return bd - ad;
                });
            }

            this.data.Contracts.sort((a, b) => {
                let an = a.Type === 'STL' ? 100 : a.Number ? 10 : 0,
                    bn = b.Type === 'STL' ? 100 : b.Number ? 10 : 0;

                return bn - an;
            });

            this.data.Payments.sort((a, b) => {
                let ad = Date.parse(a.Date);
                let bd = Date.parse(b.Date);
                return bd - ad;
            });

            for (let i = 0; i < this.data.Contracts.length; i++) {
                let contract = this.data.Contracts[i];

                if (this.isActualContract(contract))
                    this.actualContractCount++;

                contract.visibleInvoices = [];

                for (var j = 0; j < contract.Invoices.length; j++) {
                    let invoice = contract.Invoices[j];

                    if (invoice.UnpaidAmount || i < this.maxVisibleInvoices)
                        contract.visibleInvoices.push(invoice);

                    if (this.isSaldo(invoice)) {
                        this.data.Contracts[i].saldo = invoice;
                        break;
                    }
                }

                contract.hasOtherInvoices = contract.Invoices.length !== contract.visibleInvoices.length;

                // needed for ngx datatable detail rows to work (they do not work with live filtered arrays)
                contract.nonSaldoVisible = contract.visibleInvoices.filter(t => !this.isSaldo(t));
                contract.nonSaldoAll = contract.Invoices.filter(t => !this.isSaldo(t));
            }

            this.hasHiddenContracts = this.actualContractCount !== this.data.Contracts.length;

            this.isLoaded = true;
        }, err => {
            this.isUnavailable = true;
        });
    }
}
