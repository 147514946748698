import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../../services/app.service';

import { PortfolioService } from '../../../services/portfolio.service';
import { IIssuer, IPortfolio } from '../../../models/Portfolio';
import { reset } from '../portfolio.component';

@Component({
    selector: 'app-portfolio-add',
    templateUrl: './portfolio-add.component.html',
    styleUrls: ['../portfolio.component.scss']
})
export class PortfolioAddComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PortfolioService,
        private route: ActivatedRoute
    ) { }

    readonly options: {
        country: string[],
        issuer: IIssuer[]
    } = {
            country: [],
            issuer: []
        };

    country: string;
    issuer: IIssuer;
    sessionId: string;
    returnUrl: string;
    status: 'success' | 'error';
    message: string;
    report: IPortfolio;
    pads: { [count: number]: number[] } = {};
    isConfirmed: boolean;

    private issuers: IIssuer[] = [];

    @ViewChild('requestForm', { static: true }) private requestForm: ElementRef;

    ngOnInit() {
        this.route.queryParams.subscribe(queryParams => {
            this.sessionId = queryParams['session'];
            this.status = queryParams['status'];
            this.message = queryParams['message'];

            if (this.status) {
                switch (this.status) {
                    case 'success':
                        this.app.addLoading(this.service.getPending(this.sessionId)).subscribe(data => {
                            if (data) {
                                this.report = data;
                            } else {
                                this.status = 'error';
                                this.message = this.app.translate('portfolio_sessionExpired');
                            }
                        }, err => {
                            this.status = 'error';
                            this.message = this.app.translate('portfolio_sessionExpired');
                        });
                        break;
                }
            } else {
                this.app.addLoading(this.service.getIssuers()).subscribe(data => {
                    this.issuers = data;

                    const countries: string[] = data.reduce((arr, n) => {
                        if (!arr.includes(n.CountryName)) {
                            arr.push(n.CountryName);
                        }

                        return arr;
                    }, []);

                    countries.sort((a, b) => a.localeCompare(b, this.app.currentLanguage));

                    this.options.country = countries;
                });
            }
        });
    }

    onCountryChange(country: string) {
        this.options.issuer = this.issuers.filter(t => t.CountryName == country);

        if (this.options.issuer.length == 1) {
            this.issuer = this.options.issuer[0];
        }
    }

    request() {
        this.app.addLoading(this.service.initSession()).subscribe(data => {
            this.sessionId = data.sessionId;
            this.returnUrl = data.returnUrl;

            setTimeout(() => {
                this.requestForm.nativeElement.submit();
            }, 100);
        });
    }

    submit() {
        this.app.addLoading(this.service.submitPending(this.sessionId)).subscribe(id => {
            reset();
            this.app.navigate(['/portfolio']);
        });
    }

    cancel() {
        this.app.confirm(this.app.translate('portfolio_confirmCancel'), ok => {
            if (ok) {
                this.app.addLoading(this.service.cancelPending(this.sessionId)).subscribe(() => {
                    this.app.navigate(['/portfolio']);
                });
            }
        });
    }
}
