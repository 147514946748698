<div class="modal-header">
    <h4 class="modal-title">{{'addGrades_deleteGrade' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="data-value">
            <label>{{'addGrades_lblGrade' | translate}}</label>
            <div>{{grade.GradeName}}</div>
        </div>

        <div class="data-value">
            <label>{{'addGrades_lblType' | translate}}</label>
            <div>{{grade.TypeName}}</div>
        </div>

        <div class="form-field required">
            <label class="form-label">{{'addGrades_lblReason' | translate}}</label>
            <textarea class="form-control" name="payload" rows="5" [(ngModel)]="reason" required></textarea>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()" [disabled]="isSaving">{{'cancel' | translate}}</button>

    <button class="btn btn-primary" (click)="delete()" type="button" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSaving"></span>
        {{'delete' | translate}}
    </button>
</div>
