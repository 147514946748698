<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/certificate-requests', text: 'certRequests_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'certRequests_title'"></h1>
        <aside>
            <button type="button" class="btn btn-outline" color="accent" (click)="getUploadTemplate()" *ngIf="rights.edit">
                <i class="fi fi-rs-download"></i>
                {{'certRequests_btnDownloadTemplate' | translate}}
            </button>
        </aside>
    </header>

    <div class="row">
        <div class="form-field col-4">
            <app-select [placeholder]="'certRequests_lblArea' | translate"
                        [(ngModel)]="certificateArea"
                        [options]="userCertificateAreas"
                        [valueProperty]="'Code'"
                        [displayProperty]="'Text'"
                        name="certificateArea"
                        (selectionChange)="load()">
            </app-select>
        </div>

        <div class="form-field col-8">
            <button type="button" class="btn btn-secondary" (click)="fileSelect(fileInput)" *ngIf="rights.edit">
                <i class="fi fi-rs-upload"></i>
                {{'certRequests_btnUpload' | translate}}
            </button>

            <button type="button" class="btn btn-outline" color="accent" (click)="previewEmail()">
                <i class="fi fi-rs-envelope"></i>
                {{'certRequests_btnPreviewEmail' | translate}}
            </button>

            <button type="button" class="btn btn-outline-primary" (click)="sendEmails()" *ngIf="rights.edit">
                <i class="fi fi-rs-paper-plane"></i>
                {{'certRequests_btnSendEmails' | translate}}
            </button>
        </div>
    </div>

    <input name="file" type="file" #fileInput (change)="upload($event.target.files[0], $event); fileInput.value = '';" accept=".xlsx" style="display: none">

    <form #filterForm="ngForm">
        <div class="row">
            <div class="form-field col-4">
                <input class="form-control" [(ngModel)]="searchValue" name="searchValue"
                       [placeholder]="'certRequests_lblSearch' | translate" maxlength="100">
            </div>

            <div class="form-field col-2">
                <app-date [(ngModel)]="filterAvailableFrom"
                          name="filterAvailableFrom"
                          [placeholder]="'certRequests_lblAvailableFrom' | translate"
                          [max]="filterAvailableTo"></app-date>
            </div>

            <div class="form-field col-2">
                <app-date [(ngModel)]="filterAvailableTo"
                          name="filterAvailableTo"
                          [placeholder]="'certRequests_lblAvailableTo' | translate"
                          [min]="filterAvailableTo"></app-date>
            </div>

            <div class="form-field col-4">
                <button type="button" (click)="search()" class="btn btn-outline" [disabled]="isFiltering">
                    <i class="fi fi-rs-search" *ngIf="!isFiltering"></i>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isFiltering"></span>
                    {{'certRequests_btnSearch' | translate}}
                </button>

                <button type="reset" class="btn btn-outline" [disabled]="isFiltering">
                    <i class="fi fi-rs-cross"></i>
                    {{'certRequests_btnReset' | translate}}
                </button>
            </div>
        </div>
    </form>

    <app-table [rows]="items" [columns]="columns" sticky="right">
        <ng-template #rowTemplate let-row>
            <tr>
                <td class="hidden-sm-down">{{row.CertId}}</td>
                <td class="hidden-md-up">
                    <div><strong>{{row.CertId}}</strong></div>
                    <div>{{row.Email}}</div>
                    <div class="text-sub">{{row.AvailableTill | appDate}}</div>
                </td>
                <td class="hidden-sm-down">{{row.Email}}</td>
                <td class="hidden-sm-down">{{row.AvailableTill | appDate}}</td>
                <td class="hidden-lg-down">{{row.FirstName}}</td>
                <td class="hidden-lg-down">{{row.LastName}}</td>
                <td class="hidden-lg-down">{{row.EventCode}}</td>
                <td class="hidden-md-down">{{'certRequests_status' + row.Status | translate}}</td>
                <td class="hidden-md-down">{{'certRequests_emailStatus' + row.EmailStatus | translate}}</td>
                <td>
                    <div class="table-actions">
                        <button type="button" (click)="delete(row)" class="btn btn-outline-primary btn-icon"
                                *ngIf="rights.edit" [ngbTooltip]="'delete' | translate">
                            <i class="fi fi-rs-trash"></i>
                        </button>

                        <a href="{{getDownloadUrl(row)}}" target="_blank" *ngIf="rights.edit"
                           [ngbTooltip]="'download' | translate" class="btn btn-outline btn-icon">
                            <i class="fi fi-rs-download"></i>
                        </a>

                        <button type="button" class="btn btn-outline btn-icon hidden-xl-up" (click)="toggle(row)"
                                [ngbTooltip]="'finalPaper_showDetails' | translate">
                            <i class="fi fi-rs-angle-small-{{isExpanded(row) ? 'up' : 'down'}}"></i>
                        </button>
                    </div>
                </td>
            </tr>
            <tr *ngIf="isExpanded(row)" class="table-row-details hidden-xl-up">
                <td colspan="9">
                    <div class="dl">
                        <div class="hidden-xl-up">
                            <span>{{'certRequests_lblFirstName' | translate}}</span>
                            <span>{{row.FirstName}}</span>
                        </div>

                        <div class="hidden-xl-up">
                            <span>{{'certRequests_lblLastName' | translate}}</span>
                            <span>{{row.LastName}}</span>
                        </div>

                        <div class="hidden-xl-up">
                            <span>{{'certRequests_lblEventCode' | translate}}</span>
                            <span>{{row.EventCode}}</span>
                        </div>

                        <div class="hidden-lg-up">
                            <span>{{'certRequests_lblStatus' | translate}}</span>
                            <span>{{'certRequests_status' + row.Status | translate}}</span>
                        </div>

                        <div class="hidden-lg-up">
                            <span>{{'certRequests_lblEmailStatus' | translate}}</span>
                            <span>{{'certRequests_emailStatus' + row.EmailStatus | translate}}</span>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
