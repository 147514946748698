import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { IEbusSessionConfig, IEbusSessionConfigListItem, IEbusSessionConfigSaveModel } from '../models/Ebus';

/**
 * E-bus service
 */
@Injectable({ providedIn: "root" })
export class EbusService {
    constructor(private http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/ebus` }

    getSessionConfigs(): Observable<IEbusSessionConfigListItem[]> {
        const url = `${this.apiUrl}/sessionConfig`;
        return this.http.get<IEbusSessionConfig[]>(url);
    }

    getSessionConfig(id: number): Observable<IEbusSessionConfig> {
        const url = `${this.apiUrl}/sessionConfig/${id}`;
        return this.http.get<IEbusSessionConfig>(url);
    }

    updateSessionConfig(id: number, item: IEbusSessionConfigSaveModel): Observable<any> {
        const url = `${this.apiUrl}/sessionConfig/${id}`;
        return this.http.put(url, item);
    }
}
