<table class="table question-grid hidden-sm-down" *ngIf="questions.length">
    <thead>
        <tr>
            <th class="question-grid-label"></th>
            <ng-container [ngSwitch]="questions[0].AnswerEvaluationType">
                <ng-container *ngSwitchCase="answerTypes.YesNo">
                    <th>Yes</th>
                    <th>No</th>
                </ng-container>
                <ng-container *ngSwitchCase="answerTypes.OneToFive">
                    <th *ngFor="let a of util.sequence(5, 1)">{{a}}</th>
                </ng-container>
                <ng-container *ngSwitchCase="answerTypes.OneToTen">
                    <th *ngFor="let a of util.sequence(10, 1)" class="hidden-sm-down">{{a}}</th>
                </ng-container>
                <ng-container *ngSwitchCase="answerTypes.OneFromScope">
                    <th *ngFor="let a of questions[0].Answers">{{a.TextValue}}</th>
                </ng-container>
                <ng-container *ngSwitchCase="answerTypes.MultipleFromScope">
                    <th *ngFor="let a of questions[0].Answers">{{a.TextValue}}</th>
                </ng-container>
                <ng-container *ngSwitchCase="answerTypes.Satisfaction">
                    <th *ngFor="let a of util.satisfiedTypes">{{('survey_satisfiedType' + a.key) | translate}}</th>
                </ng-container>
                <ng-container *ngSwitchCase="answerTypes.Level5">
                    <th *ngFor="let a of util.level5Types">{{('survey_level5Type' + a.key) | translate}}</th>
                </ng-container>
            </ng-container>
            <th *ngIf="questions[0].HasNoOpinion" class="hidden-sm-down">
                {{'survey_valueNoOpinion' | translate}}
            </th>
        </tr>
    <tbody>
        <tr *ngFor="let q of questions">
            <td class="question-grid-label">
                <label class="form-label" [class.invalid]="validate && !util.validateAnswer(q, model[keyFn(q)])">
                    {{q.Title}}
                </label>
                <span class="required-mark" *ngIf="q.Mandatory"></span>
                <i class="fi fi-rs-info ms-1" *ngIf="q.Description" [ngbTooltip]="q.Description"></i>
            </td>

            <td *ngFor="let n of forms[q.ID].options" [class.no-opinion]="n.value == valueNoOpinion" class="hidden-sm-down">
                <app-radio [(ngModel)]="model[keyFn(q)]" [value]="n.value" name="question-{{keyFn(q)}}" *ngIf="!forms[q.ID].isMulti"></app-radio>
                <app-checkbox [(ngModel)]="model[keyFn(q)]" [value]="n.value" name="question-{{keyFn(q)}}" *ngIf="forms[q.ID].isMulti"></app-checkbox>
            </td>
        </tr>
    </tbody>
</table>

<div class="form-field hidden-md-up" *ngFor="let q of questions">
    <label class="form-label" [class.invalid]="validate && !util.validateAnswer(q, model[keyFn(q)])">
        {{q.Title}}
        <span class="required-mark" *ngIf="q.Mandatory"></span>
        <i class="fi fi-rs-info ms-1" *ngIf="q.Description" [ngbTooltip]="q.Description"></i>
    </label>
    <div>
        <app-select [(ngModel)]="model[keyFn(q)]"
                    [options]="forms[q.ID].options"
                    [multiple]="forms[q.ID].isMulti"
                    [clear]="false"
                    displayProperty="text"
                    valueProperty="value"
                    name="question-{{keyFn(q)}}">
        </app-select>
    </div>
</div>
