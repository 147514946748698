import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IParameter } from '../../models/Parameter';
import { ParameterService } from '../../services/parameter.service';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-parameter-edit',
    templateUrl: './parameter-edit.component.html'
})
export class ParameterEditComponent {
    constructor(
        private app: AppService,
        private service: ParameterService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() model: IParameter;

    submitted: boolean;
    isSaving: boolean;

    @ViewChild('form', { static: true }) private form: NgForm;

    save() {
        if (this.form.invalid) {
            this.submitted = true;
            this.isSaving = false;
            return;
        }

        const success = () => {
            this.activeModal.close();
        };

        this.isSaving = true;

        this.app.addLoading(this.service.update(this.model.Id, this.model.Value), 500).subscribe(success);
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
