<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: backUrl, text: 'said_events_title' },
                     { url: currentUrl, text: 'said_events_addTitle' }
                     ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'said_events_addTitle' | translate}}</h1>
    </header>

    <app-alert level="info" *ngIf="pointsInfoHtml" [innerHTML]="pointsInfoHtml | safeHtml"></app-alert>

    <section class="block">
        <div class="row">
            <div class="form-field col-md-4">
                <label class="form-label">{{'said_events_FilterAll' | translate}}</label>
                <input class="form-control" autofocus [(ngModel)]="currentFilter.All" name="currentFilter.All">
            </div>

            <div class="form-field col-md-4">
                <label class="form-label">{{'said_events_FilterEventType' | translate}}</label>
                <app-select [(ngModel)]="currentFilter.EventType" name="currentFilter.EventType" #eventType
                            [options]="FilterEventType"
                            (selectionChange)="reloadEventTypeFilter()">
                </app-select>
            </div>
            <div class="form-field col-md-4">
                <label class="form-label">{{'said_events_FilterEventAddedType' | translate}}</label>
                <app-select [(ngModel)]="currentFilter.EventAdded"
                            [options]="FilterEventAddedType"
                            name="currentFilter.EventAdded" #eventType>
                </app-select>
            </div>
        </div>

        <ng-container *ngIf="extendedFilter">
            <div class="row">
                <div class="form-field col-md-4">
                    <label class="form-label">{{'said_events_FilterDateFrom' | translate}}</label>
                    <app-date [(ngModel)]="currentFilter.DateFrom" name="currentFilter.DateFrom"></app-date>
                </div>

                <div class="form-field col-md-4">
                    <label class="form-label">{{'said_events_FilterDateTo' | translate}}</label>
                    <app-date [(ngModel)]="currentFilter.DateTo" name="currentFilter.DateTo"></app-date>
                </div>
            </div>

            <div class="row">
                <div class="form-field col-md-4">
                    <label class="form-label">{{'said_events_filter_lblForTimepickerFrom' | translate}}</label>

                    <div class="d-flex">
                        <div>
                            <app-select [(ngModel)]="currentFilter.TimeFromHours"
                                        name="currentFilter.TimeFromHours"
                                        [options]="hourOptions"></app-select>
                        </div>

                        <div class="ms-1">
                            <app-select [(ngModel)]="currentFilter.TimeFromMinutes"
                                        name="currentFilter.TimeFromMinutes"
                                        [options]="minuteOptions"></app-select>
                        </div>
                    </div>
                </div>

                <div class="form-field col-md-4">
                    <label class="form-label">{{'said_events_filter_lblForTimepickerTo' | translate}}</label>

                    <div class="d-flex">
                        <div>
                            <app-select [(ngModel)]="currentFilter.TimeToHours"
                                        name="currentFilter.TimeToHours"
                                        [options]="hourOptions"></app-select>
                        </div>

                        <div class="ms-1">
                            <app-select [(ngModel)]="currentFilter.TimeToMinutes"
                                        name="currentFilter.TimeToMinutes"
                                        [options]="minuteOptions"></app-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-field col-md-8">
                    <label class="form-label">{{'said_events_FilterAddress' | translate}}</label>
                    <input class="form-control" [(ngModel)]="currentFilter.Address" name="currentFilter.Address">
                </div>

                <div class="form-field col-md-4">
                    <label class="form-label">{{'said_events_FilterSkillCategory' | translate}}</label>
                    <app-select [(ngModel)]="currentFilter.SkillCategory" name="currentFilter.SkillCategory"
                                [options]="FilterSkillCategory">
                    </app-select>
                </div>
            </div>
        </ng-container>

        <div class="mb-3">
            <button type="button" class="btn btn-outline" (click)="extendedFilter = !extendedFilter">
                <i [class]="extendedFilter ? 'fi fi-rs-angle-up' : 'fi fi-rs-angle-down'"></i>
                {{'said_events_extended_filter' | translate}}
            </button>

            <button type="button" class="btn btn-outline" (click)="ClearFilter()">
                <i class="fi fi-rs-cross"></i>
                {{'said_events_clear_filter' | translate}}
            </button>
        </div>
    </section>

    <!--<div class="form-field">
        <div>
            <label class="form-label">{{'said_events_filter_lblForCheckboxes' | translate}}</label>

            <div>
                <app-checkbox [(ngModel)]="currentFilter.onlyAdded" name="onlyAdded">
                    {{'said_events_lblOnlyAdded' | translate}}
                </app-checkbox>
            </div>

            <div class="mt-1">
                <app-checkbox [(ngModel)]="currentFilter.onlyNonAdded" name="onlyAllowed">
                    {{'said_events_lblOnlyNonAdded' | translate}}
                </app-checkbox>
            </div>

            <div class="mt-1">
                <app-checkbox [(ngModel)]="currentFilter.onlySupervised" name="onlySupervised">
                    {{'said_events_lblSupervised' | translate}}
                </app-checkbox>
            </div>

            <div class="mt-1">
                <app-checkbox [(ngModel)]="currentFilter.onlyNonSupervised" name="onlyNonSupervised">
                    {{'said_events_lblNonSupervised' | translate}}
                </app-checkbox>
            </div>
        </div>
    </div>-->

    <ng-container *ngIf="isLoaded">
        <app-alert level="warning" *ngIf="!events.length">
            {{'said_events_noProgramCanBeAdded' | translate}}
        </app-alert>

        <app-alert level="warning" *ngIf="events.length && !filteredEvents.length">
            {{'said_events_noProgramFound' | translate}}
        </app-alert>

        <section class="block-em" *ngFor="let c of filteredEvents; let i = index;">
            <h5>
                {{language == 'en' ? c.EventTypeNameEn : c.EventTypeNameLv}}
                <i *ngIf="c.IsSupervised" class="fi fi-rs-graduation-cap"></i>
            </h5>

            <app-alert *ngIf="!c.StudentTestIsPassed && !alreadyAddedEvents[c.EventId] && c.IsSupervised" level="info">{{'said_events_test_not_passed' | translate}}</app-alert>
            <app-alert *ngIf="c.GroupIsFull" level="warning">{{'said_events_group_is_full' | translate}}</app-alert>
            <app-alert *ngIf="c.Error" level="danger">{{c.Error}}</app-alert>

            <div class="data-value">
                <label>{{'said_events_lblSkills' | translate}}</label>
                <div>
                    <ul>
                        <li *ngFor="let sk of c.Skills; let j = index;">
                            <a href="#" (click)="$event.preventDefault(); openSkill(sk)" [title]="'said_events_card' | translate">
                                <input type="hidden" name="skill_id" value="{{sk.Id}}" />
                                <span>
                                    {{sk.Name}}, {{sk.Level}}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div id="anchor_{{c.EventId}}">
                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_lblAddress' | translate}}</label>
                        <div><i class="fi fi-rs-marker"></i> {{c.EventAddress}}</div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_type' | translate}}</label>
                        <div><i class="fi fi-rs-book"></i> {{getEventTypeName(c.EventType-1)}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_room' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-building"></i>
                            {{c.EventRoom && c.EventRoom.trim().length>0 ? c.EventRoom : '-'}}
                        </div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_lblParticipantCount' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-user"></i> {{c.ActualStudentCount}}/{{c.MaxStudentCount}}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_min_students_count' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-arrow-down"></i> {{c.MinStudentCount}}
                        </div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_max_students_count' | translate}}</label>
                        <div><i class="fi fi-rs-arrow-up"></i> {{c.MaxStudentCount}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_lblDateTime' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-clock"></i> {{c.EventTimeFrom | date:'short':'':'lv'}} - {{c.EventTimeTo | date:'shortTime':'':'lv'}}
                        </div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_application_period' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-exchange"></i>
                            {{c.RegistrationOpenDate | date:'shortDate':'':'lv'}} - {{c.RegistrationCloseDate | date:'shortDate':'':'lv'}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-actions">
                <button *ngIf="!this.alreadyAddedEvents[c.EventId]" type="button"
                        class="btn btn-outline-primary" (click)="enroll(c.EventId)"
                        [disabled]="(!isAdmin && !c.StudentTestIsPassed && c.IsSupervised) || helpers.validateDate(c.RegistrationOpenDate, c.RegistrationCloseDate) || c.GroupIsFull || c.Error!= null">
                    {{'said_events_enroll' | translate}}
                </button>

                <button *ngIf="this.alreadyAddedEvents[c.EventId]" type="button"
                        class="btn btn-secondary" (click)="removeProgram(c.EventId)" [disabled]="helpers.validateDate(c.RegistrationOpenDate, c.RegistrationCloseDate) || c.Error!= null">
                    {{'said_events_remove' | translate}}
                </button>
            </div>
        </section>
    </ng-container>

    <div class="form-actions">
        <button type="button" class="btn btn-primary" [routerLink]="backUrl | localize">
            {{'said_events_finish' | translate}}
        </button>
    </div>
</div>
