
<div class="row">
    <div class="col-md-4 form-field">
        <input class="form-control"
               [placeholder]="'doctorDashboard_students_search' | translate"
               [(ngModel)]="filterValues.search"
               (input)="filter(true)">
    </div>

    <div class="col-md-4 form-field">
        <app-select [options]="specialityOptions"
                    [placeholder]="'doctorDashboard_students_lblSpeciality' | translate"
                    [displayProperty]="'text'"
                    [valueProperty]="'value'"
                    [multiple]="true"
                    [(ngModel)]="filterValues.specialityId"
                    (selectionChange)="filter()">
        </app-select>
    </div>

    <div class="col-md-4 form-field d-flex align-items-center">
        <app-checkbox [(ngModel)]="filterValues.hideGraded" (stateChange)="filter()">
            {{'doctorDashboard_students_hideGraded' | translate}}
        </app-checkbox>
    </div>
</div>

<app-table [rows]="rows" [columns]="columns" pageSize="50">
    <ng-template #rowTemplate let-row>
        <tr>
            <td>
                <a href="{{getPlanSummaryUrl(row)}}" target="_blank">{{row.fullName}}</a>
                <div class="hidden-lg-up text-sub">{{row.Speciality}}</div>
                <div class="hidden-md-up">
                    <small><em>{{row.studyYearDisplay}} {{'doctorDashboard_students_studyYear' | translate}}</em></small>
                </div>
            </td>
            <td class="hidden-md-down">{{row.Speciality}}</td>
            <td class="hidden-sm-down">{{row.studyYearDisplay}}</td>
            <td>
                {{row.courseFullName}}
                <div class="hidden-lg-up text-sub">{{row.dateFromToDisplay}}</div>
            </td>
            <td class="hidden-md-down">{{row.dateFromToDisplay}}</td>
            <td>
                <div *ngIf="row.IsDoctorGraded" class="text-nowrap"><i class="fi fi-rs-check"></i> {{'doctorDashboard_students_isGraded' | translate}}</div>
                <div *ngIf="!row.IsDoctorGraded" class="text-nowrap"><i class="fi fi-rs-hourglass-end"></i> {{'doctorDashboard_students_isWaiting' | translate}}</div>
                <a href="{{getGradesUrl(row)}}" target="_blank">[ {{'doctorDashboard_students_btnGrades' | translate}} ]</a>
            </td>
        </tr>
    </ng-template>
</app-table>
