import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { AppService } from '../../services/app.service';
import { TileService } from '../../services/tile.service';
import { ITile, TileEditModel, TileSize, TileStatus } from '../../models/Tile';
import { ClassifierService } from '../../services/classifier.service';
import { Classifier, ClassifierType } from '../../models/Classifier';
import { createFileStub } from '../../shared/file/file.component';

@Component({
    selector: 'app-tile-edit',
    templateUrl: './tile-edit.component.html',
    styleUrls: ['./tiles.component.scss']
})
export class TileEditComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: TileService,
        private classifierService: ClassifierService,
        private route: ActivatedRoute
    ) { }

    tile: ITile;
    model = new TileEditModel();
    imageFile: File;
    imageDimensionsWarning: string;
    isNew: boolean;
    permissions: Classifier[] = [];
    categories: Classifier[] = [];
    submitted: boolean;

    readonly sizes = [
        { value: TileSize.Small, label: this.app.translate('tiles_sizeSmall') },
        { value: TileSize.Large, label: this.app.translate('tiles_sizeLarge') }
    ];

    readonly statuses = [
        { value: TileStatus.Draft, label: this.app.translate('tiles_status' + TileStatus.Draft) },
        { value: TileStatus.Published, label: this.app.translate('tiles_status' + TileStatus.Published) }
    ];

    readonly maxImageSize = 20000;
    readonly imageFormats = ['.jpg', '.png'];

    readonly permissionFilterFn = (item: Classifier, value: string) => {
        const val = value.toLowerCase();
        return item.Value?.toLowerCase().includes(val) || item.Code?.toLowerCase().includes(val);
    };

    private currentImage: HTMLImageElement;

    @ViewChild('form', { static: false }) private form: NgForm;

    ngOnInit() {
        this.route.params.subscribe(para => {
            let id = para['id'];

            if (!id) {
                this.isNew = true;
            } else {
                this.loadById(+id);
            }
        });

        this.app.addLoading(this.classifierService.get(`${ClassifierType.TileCategory},${ClassifierType.Permissions}`)).subscribe(data => {
            this.categories = data.filter(t => t.Type == ClassifierType.TileCategory && t.Code != 'Applications');
            this.permissions = data.filter(t => t.Type == ClassifierType.Permissions);
        });
    }

    onSizeChange() {
        this.validateImageDimensions();
    }

    onImageChange(file: File) {
        this.currentImage = undefined;

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                const base64 = <string>reader.result;

                this.model.Image = base64;

                this.validateImageDimensions();
            };
        } else {
            this.model.Image = undefined;
            this.validateImageDimensions();
        }
    }

    save() {
        if (!this.form.valid) {
            this.submitted = true;
            this.app.alert.warning(this.app.translate('invalidFormWarning'));
            return;
        }

        this.createOrUpdate().subscribe(id => {
            this.app.notify(this.app.translate('tiles_saved'));
            this.app.navigate(['/tiles']);
        });
    }

    getPreviewModel(): TileEditModel {
        const preview = { ...this.model };
        preview.Url = null;
        return preview;
    }

    private validateImageDimensions() {
        if (!this.model.Image) {
            this.imageDimensionsWarning = null;
            return;
        }

        const validate = () => {
            const height = this.currentImage.height;
            const width = this.currentImage.width;

            this.imageDimensionsWarning = null;

            if (this.model.Size == TileSize.Small) {
                if (height != width || width < 56) {
                    this.imageDimensionsWarning = this.app.translate('tiles_smallImageWarning');
                }
            } else if (this.model.Size == TileSize.Large) {
                if (width < 280) {
                    this.imageDimensionsWarning = this.app.translate('tiles_largeImageWarning');
                }
            }
        };

        if (this.currentImage) {
            validate();
        } else {
            this.currentImage = new Image();
            this.currentImage.src = this.model.Image;
            this.currentImage.onload = () => {
                validate();
            };
        }
    }

    private loadById(id: number) {
        this.app.addLoading(this.service.getById(id)).subscribe(data => {
            this.tile = data;

            this.model.Description = data.Description;
            this.model.DescriptionEn = data.DescriptionEn;
            this.model.Image = data.Image;
            this.model.PermissionId = data.Permission.Id;
            this.model.CategoryId = data.Category?.Id;
            this.model.Size = data.Size;
            this.model.SortOrder = data.SortOrder;
            this.model.Status = data.Status;
            this.model.Title = data.Title;
            this.model.TitleEn = data.TitleEn;
            this.model.Url = data.Url;

            if (data.Image) {
                this.imageFile = createFileStub('image.' + data.Image.split('data:image/').pop().split(';')[0]);
            }
        });
    }

    private createOrUpdate() {
        const subj = new Subject<number>();

        if (this.isNew) {
            this.app.addLoading(this.service.create(this.model)).subscribe(id => {
                subj.next(id);
            });
        } else {
            this.app.addLoading(this.service.update(this.tile.Id, this.model)).subscribe(() => {
                subj.next(this.tile.Id);
            });
        }

        return subj.asObservable();
    }
}
