<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/said-events', text: 'said_events_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'said_events_title' | translate}}</h1>
        <aside *ngIf="canPickStudent && !isDenied">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker(true)">
                <i class="fi fi-rs-user"></i>
                {{'said_events_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <div class="content-desc" *ngIf="filterValue">
        {{filterValue.Planning_period_name}}
    </div>

    <app-person-picker *ngIf="studentPickerOpened" (pick)="pickStudent($event)"></app-person-picker>

    <ng-container *ngIf="!studentPickerOpened">
        <app-alert level="warning" *ngIf="isUnavailable">
            <div [innerHTML]="unavailableText | safeHtml"></div>
        </app-alert>

        <div *ngIf="!isUnavailable">
            <app-alert level="danger" *ngIf="isDenied || (isLoaded && (!personDataList || !personDataList.length))">
                {{'eServiceNotAvailable' | translate}}
            </app-alert>

            <ng-container *ngIf="!isDenied">
                <ng-container *ngIf="!isUnavailable">
                    <app-alert level="warning" *ngIf="isLoaded && noSelectionNeeded && !isSetKPInfoTextIsOn">
                        {{'said_events_noSelectionNeeded' | translate}}
                    </app-alert>

                    <ng-container *ngIf="isLoaded">
                        <div class="data-value" *ngIf="studentFullName && (isAdmin || isImpersonator)">
                            <label>{{'said_events_student' | translate}}</label>
                            <div>{{studentFullName}} ({{studentUpn}})</div>
                        </div>

                        <div *ngIf="personDataList && personDataList.length > 1" class="mb-3">
                            <app-select [placeholder]="'said_events_lblDropType' | translate"
                                        [(ngModel)]="filterValue"
                                        name="filterValue"
                                        [options]="personDataList"
                                        [displayProperty]="'Study_program'"
                                        (selectionChange)="loadEvents($event.value.Student_guid, $event.value.Planning_period_GUID)">
                            </app-select>
                        </div>

                        <app-alert level="info">
                            <div><strong>{{'said_events_skills_under_supervision' | translate}}</strong></div>
                            <div *ngIf="pointsInfoHtml" [innerHTML]="pointsInfoHtml | safeHtml"></div>
                            <div>
                                <a href="#" (click)="$event.preventDefault(); navigateToFilterSupervised()">
                                    {{'said_events_supervised_filter_title' | translate}}
                                </a>
                            </div>
                        </app-alert>

                        <app-alert level="info" *ngIf="formInfoHtml">
                            <div [innerHTML]="formInfoHtml | safeHtml"></div>
                        </app-alert>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>

    <div class="block" *ngIf="rights.view && !studentPickerOpened && isLoaded && !isUnavailable">
        <header class="block-header">
            <h3 [translate]="'said_events_AddedEvents_title' | translate"></h3>
            <aside>
                <button type="button" (click)="addEvent()" class="btn btn-outline-primary"
                        *ngIf="!studentPickerOpened && isLoaded" [disabled]="!getPersonMainDataHadResult">
                    <i class="fi fi-rs-plus"></i>
                    {{'said_events_addCource' | translate}}
                </button>
            </aside>
        </header>

        <app-alert level="warning" *ngIf="!selectedStudentEvents.length">
            {{'said_events_noProgramAdded' | translate}}
        </app-alert>

        <section class="block-em" *ngFor="let c of selectedStudentEvents; let i = index;">
            <h5>
                {{language == 'en' ? c.EventTypeNameEn : c.EventTypeNameLv}}
                <i *ngIf="c.IsSupervised" class="fi fi-rs-graduation-cap"></i>
            </h5>

            <app-alert *ngIf="c.GroupIsFull" level="warning">{{'said_events_group_is_full' | translate}}</app-alert>
            <app-alert *ngIf="c.Error" level="danger">{{c.Error}}</app-alert>

            <div class="data-value">
                <label>{{'said_events_lblSkills' | translate}}</label>
                <div>
                    <ul>
                        <li *ngFor="let sk of c.Skills; let j = index;">
                            <a href="#" (click)="$event.preventDefault(); openSkill(sk)" [title]="'said_events_card' | translate">
                                <input type="hidden" name="skill_id" value="{{sk.Id}}" />
                                <span>
                                    {{sk.Name}}, {{sk.Level}}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div id="anchor_{{c.EventId}}">
                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_lblAddress' | translate}}</label>
                        <div><i class="fi fi-rs-marker"></i> {{c.EventAddress}}</div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_type' | translate}}</label>
                        <div><i class="fi fi-rs-book"></i> {{getEventTypeName(c.EventType-1)}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_room' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-building"></i>
                            {{c.EventRoom && c.EventRoom.trim().length>0 ? c.EventRoom : '-'}}
                        </div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_lblParticipantCount' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-user"></i> {{c.ActualStudentCount}}/{{c.MaxStudentCount}}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_min_students_count' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-arrow-down"></i> {{c.MinStudentCount}}
                        </div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_max_students_count' | translate}}</label>
                        <div><i class="fi fi-rs-arrow-up"></i> {{c.MaxStudentCount}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-md">
                        <label>{{'said_events_lblDateTime' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-clock"></i> {{c.EventTimeFrom | date:'short':'':'lv'}} - {{c.EventTimeTo | date:'shortTime':'':'lv'}}
                        </div>
                    </div>

                    <div class="data-value col-md">
                        <label>{{'said_events_application_period' | translate}}</label>
                        <div>
                            <i class="fi fi-rs-exchange"></i>
                            {{c.RegistrationOpenDate | date:'shortDate':'':'lv'}} - {{c.RegistrationCloseDate | date:'shortDate':'':'lv'}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-actions">
                <button type="button"
                        class="btn btn-secondary"
                        (click)="removeEvent(c.EventId)"
                        [disabled]="helpers.validateDate(c.RegistrationOpenDate, c.RegistrationCloseDate) || c.Error!= null">
                    {{'said_events_remove' | translate}}
                </button>
            </div>
        </section>
    </div>

    <app-alert level="info" *ngIf="rights.view && !studentPickerOpened && isLoaded && formExplanationsHtml && !isUnavailable">
        <div><strong>{{'said_events_explanations' | translate}}</strong></div>
        <div [innerHTML]="formExplanationsHtml | safeHtml"></div>
    </app-alert>
</div>
