import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { AppService } from '../../../services/app.service';

import { IPlanSummaryResident, IPlanSummarySupervisor } from '../../../models/PlanSummary';
import { ClassifierService } from '../../../services/classifier.service';
import { Classifier } from '../../../models/Classifier';
import { StudyQualityEvaluationService } from '../../../services/study-quality-evaluation.service';
import {
    IStudyQualityEvaluation, IStudyQualityEvaluationEditModel,
    IStudyQualityEvaluationResult, StudyQualityEvaluationSemester, StudyQualityEvaluationStatus
} from '../../../models/StudyQualityEvaluation';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StudyQualityEvaluationChangeConditionComponent } from './study-quality-evaluation-change-condition/study-quality-evaluation-change-condition.component';
import { StudyQualityEvaluationRejectEvaluationComponent } from './study-quality-evaluation-reject-evaluation/study-quality-evaluation-reject-evaluation.component';

type NavId = 'firstHalf' | 'secondHalf' | 'supervisorComment' | 'residencyComment';

interface ITab {
    id: NavId;
    title: string;
    canEdit: () => boolean;
    canSubmit: () => boolean;
    save: (submit: boolean) => Observable<any>;
    info: () => string;
}

interface IModel {
    studyYear: number;
    firstHalf?: IStudyQualityEvaluationEditModel;
    secondHalf?: IStudyQualityEvaluationEditModel;
    prevYearHalf?: IStudyQualityEvaluationResult;
    supervisorComment?: string;
    residencyComment?: string;
};

const classifierCodes = {
    result: 'StudyQualityEvaluationResult',
    task: 'StudyQualityEvaluationTask',
    achievement: 'StudyQualityEvaluationAchievement',
    decision: 'StudyQualityEvaluationDecision'
};

@Component({
    selector: 'app-plan-summary-quality-evaluation',
    templateUrl: './plan-summary-quality-evaluation.component.html',
    styleUrls: ['../plan-summary.component.scss']
})
export class PlanSummaryQualityEvaluationComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: StudyQualityEvaluationService,
        private classifiers: ClassifierService,
        private route: ActivatedRoute,
        private modal: NgbModal
    ) { }

    @Input() resident: IPlanSummaryResident;
    @Input() supervisor: IPlanSummarySupervisor;

    readonly options: {
        result: Classifier[],
        task: Classifier[],
        achievement: Classifier[],
        decision: Classifier[]
    } = {
            result: [],
            task: [],
            achievement: [],
            decision: []
        };

    model: IModel = {
        studyYear: 0
    };

    residentNumber: string;
    yearOptions: number[] = [];
    selectedYear: number;
    currentEvaluation: IStudyQualityEvaluation;
    currentEvaluationFirstHalf: IStudyQualityEvaluationResult;
    currentEvaluationSecondHalf: IStudyQualityEvaluationResult;
    activeNavId: number = 1;
    canEdit: boolean;
    isEdit: boolean;
    isValid: boolean;
    isAdmin: boolean = false;
    isSupervisor: boolean = false;

    readonly tabs: ITab[] = [
        {
            id: 'firstHalf',
            title: 'studyQualityEvaluation_firstHalf',
            canEdit: () => !this.currentEvaluationFirstHalf || this.currentEvaluationFirstHalf.Status == StudyQualityEvaluationStatus.Draft,
            canSubmit: () => true,
            save: (submit) => {
                this.model.firstHalf.Status = submit ? StudyQualityEvaluationStatus.Submitted : StudyQualityEvaluationStatus.Draft;
                return this.service.saveEvaluation(this.resident.StudentId, this.model.firstHalf);
            },
            info: () => this.currentEvaluationFirstHalf?.Status == StudyQualityEvaluationStatus.Submitted
                ? this.app.translate('studyQualityEvaluation_submitted') : undefined
        },
        {
            id: 'secondHalf',
            title: 'studyQualityEvaluation_secondHalf',
            canEdit: () => !this.currentEvaluationSecondHalf || this.currentEvaluationSecondHalf.Status == StudyQualityEvaluationStatus.Draft,
            canSubmit: () =>  true,
            save: (submit) => {
                if (!submit)
                    this.model.secondHalf.Status = StudyQualityEvaluationStatus.Draft;
                else
                    this.model.secondHalf.Status = (this.model.secondHalf.DecisionId == this.moveToNextYearId()) ? StudyQualityEvaluationStatus.Submitted : StudyQualityEvaluationStatus.Residency;

                return this.service.saveEvaluation(this.resident.StudentId, this.model.secondHalf);
            },
            info: () => this.currentEvaluationSecondHalf?.Status == StudyQualityEvaluationStatus.Submitted
                ? this.app.translate('studyQualityEvaluation_submitted')
                : this.currentEvaluationSecondHalf?.Status == StudyQualityEvaluationStatus.Residency
                    ? this.app.translate('studyQualityEvaluation_residency') : undefined
        }
    ];

    get activeTab() {
        return this.tabs[this.activeNavId - 1];
    }

    get isActiveHalf(): boolean {
        const id = this.tabs[this.activeNavId - 1].id;
        return id == 'firstHalf' || id == 'secondHalf';
    }

    get isForResidencyReview(): boolean {
        return this.activeTab.id == 'secondHalf' && this.isAdmin && this.currentEvaluationSecondHalf?.Status == StudyQualityEvaluationStatus.Residency;
    }

    get movedToNextYear() {
        return this.currentEvaluationSecondHalf.DecisionId != this.doNotMoveToNextYearId();
    }

    @ViewChild('form', { static: true }) private form: NgForm;

    private data: IStudyQualityEvaluation[] = [];
    private validationTimeout: any;

    ngOnInit() {
        this.isAdmin = this.app.currentUser.hasRight('STUDY_QUALITY_EVALUATION.ADMIN');
        this.isSupervisor = this.isAdmin || this.app.currentUser.hasRight('STUDY_QUALITY_EVALUATION.SUPERVISOR');

        this.form.valueChanges.subscribe(t => {
            if (this.validationTimeout) {
                clearTimeout(this.validationTimeout);
            }

            this.validationTimeout = setTimeout(() => {
                this.isValid = this.form.valid;
            }, 0);
        });

        this.app.addLoading(this.classifiers.get(Object.values(classifierCodes).join(','))).subscribe(data => {
            this.options.result = data.filter(t => t.Type == classifierCodes.result);
            this.options.task = data.filter(t => t.Type == classifierCodes.task);
            this.options.achievement = data.filter(t => t.Type == classifierCodes.achievement);
            this.options.decision = data.filter(t => t.Type == classifierCodes.decision);
        });

        this.residentNumber = this.resident.Email.split('@')[0];
        this.selectedYear = this.resident.StudyYear || 1;

        const maxYear = this.resident.StudyYear;

        for (let i = 1; i <= maxYear; i++) {
            this.yearOptions.push(i);
        }

        this.handleQueries();

        this.refresh();

        this.canEdit = this.isSupervisor;
    }

    changeYear(year: number) {
        if (year == this.selectedYear) return;

        this.selectedYear = year;
        this.setDataByYear(year);
    }

    edit() {
        this.isEdit = true;
        this.buildModel();
    }

    cancel() {
        this.isEdit = false;
        this.model = { studyYear: undefined };
    }

    save(submit: boolean = false) {
        let request: Observable<any> = this.activeTab.save(submit);

        this.app.addLoading(request).subscribe(() => {
            this.app.notify(this.app.translate('studyQualityEvaluation_dataSaved'));
            this.refresh(() => {
                this.isEdit = false;
            });
        });
    }

    changeCondition() {
        this.buildModel();
        const ref = this.modal.open(StudyQualityEvaluationChangeConditionComponent, {
            size: 'lg'
        });
        ref.componentInstance.model = this.model.secondHalf;
        ref.componentInstance.resident = this.resident;

        ref.result.then((result) => {
            if (result) {
                this.app.notify(this.app.translate('studyQualityEvaluation_dataSaved'));
                this.refresh();
            }
        })
    }

    rejectEvaluation() {
        this.buildModel();
        const ref = this.modal.open(StudyQualityEvaluationRejectEvaluationComponent, {
            size: 'lg'
        });
        ref.componentInstance.model = this.model.secondHalf;
        ref.componentInstance.resident = this.resident;

        ref.result.then((result) => {
            if (result) {
                this.app.notify(this.app.translate('studyQualityEvaluation_dataSaved'));
                this.refresh();
            }
        })
    }

    acceptEvaluation() {
        this.app.confirm(this.app.translate('studyQualityEvaluation_confirmAccept'), result => {
            if (!result) return;

            this.buildModel();
            this.model.secondHalf.ResidencyAccepted = true;

            this.model.secondHalf.ResidencyComment = null;
            this.model.secondHalf.ResidencyCondition = null;

            this.model.secondHalf.Status = StudyQualityEvaluationStatus.Submitted;

            let request: Observable<any> = this.service.saveResidencyEvaluation(this.resident.StudentId, this.model.secondHalf);

            this.app.addLoading(request).subscribe(() => {
                this.app.notify(this.app.translate('studyQualityEvaluation_dataSaved'));
                this.refresh();
            });
        });
    }

    moveToNextYearWithConditionId(): string {
        return this.options.decision.find(t => t.Code == 'MoveToNextYearWithCondition')?.Id;
    }

    doNotMoveToNextYearId(): string {
        return this.options.decision.find(t => t.Code == 'DoNotMoveToNextYear')?.Id;
    }

    moveToNextYearId(): string {
        return this.options.decision.find(t => t.Code == 'MoveToNextYear')?.Id;
    }

    private handleQueries() {
        const queryYear = this.route.snapshot.queryParams['year'];
        const queryHalf = this.route.snapshot.queryParams['half'];

        const maxYear = this.resident.StudyYear;
        if (queryYear <= maxYear && queryYear >= 1) {
            this.selectedYear = queryYear;
        }

        // this.activeNavId = queryHalf nestrada nezinu kapec
        if (queryHalf == 1)
            this.activeNavId = 1;
        else if (queryHalf == 2)
            this.activeNavId = 2;
    }

    private refresh(callback?: () => void) {
        this.app.addLoading(this.service.getByStudent(this.resident.StudentId)).subscribe(data => {
            this.data = data;

            if (this.selectedYear) {
                this.setDataByYear(this.selectedYear);
            } else {
                this.changeYear(1);
            }

            if (callback) {
                callback();
            }
        });
    }

    private setDataByYear(year: number) {
        this.currentEvaluation = this.data.find(t => t.StudyYear == year);
        this.currentEvaluationFirstHalf = this.currentEvaluation?.Results.find(t => t.Semester == 1)
            || <IStudyQualityEvaluationResult>{
                Semester: StudyQualityEvaluationSemester.First,
                Status: StudyQualityEvaluationStatus.Draft
            };
        this.currentEvaluationSecondHalf = this.currentEvaluation?.Results.find(t => t.Semester == 2)
            || <IStudyQualityEvaluationResult>{
                Semester: StudyQualityEvaluationSemester.Second,
                Status: StudyQualityEvaluationStatus.Draft
            };
    }

    //private hasChanges() {
    //    const o = this.currentEvaluation || <IStudyQualityEvaluation>{};
    //    const c = this.model;
    //    const tab = this.tabs[this.activeNavId - 1].id;

    //    switch (tab) {
    //        case 'firstHalf':
    //        case 'secondHalf':
    //            const semester = tab == 'firstHalf' ? 1 : 2;

    //            const a = (semester == 2 ? this.currentEvaluationSecondHalf : this.currentEvaluationFirstHalf) || <IStudyQualityEvaluationResult>{};
    //            const b = semester == 1 ? c.firstHalf : c.secondHalf;

    //            return a.CompetenceComment != b.CompetenceComment || a.CompetenceId != b.CompetenceId
    //                || a.KnowledgeComment != b.KnowledgeComment || a.KnowledgeId != b.KnowledgeId
    //                || a.SkillsComment != b.SkillsComment || a.SkillsId != b.SkillsId
    //                || a.TasksComment != b.TasksComment || a.TasksId != b.TasksId
    //                || a.NextPriorities != b.NextPriorities;

    //        case 'supervisorComment': return c.supervisorComment != o.SupervisorComment;
    //        case 'residencyComment': return c.residencyComment != o.ResidencyComment;
    //        default: return false;
    //    }
    //}

    private buildModel() {
        const firstHalf = this.currentEvaluation?.Results.find(t => t.Semester == 1);
        const secondHalf = this.currentEvaluation?.Results.find(t => t.Semester == 2);
        const prevYearHalf = this.data.find(t => t.StudyYear == this.selectedYear - 1)?.Results.find(t => t.Semester == 2);

        this.model = {
            studyYear: this.selectedYear,
            firstHalf: this.mapResultToModel(firstHalf, <IStudyQualityEvaluationEditModel>{ Semester: 1, StudyYear: this.selectedYear }),
            secondHalf: this.mapResultToModel(secondHalf, <IStudyQualityEvaluationEditModel>{ Semester: 2, StudyYear: this.selectedYear }),
            prevYearHalf,
            supervisorComment: this.currentEvaluation?.SupervisorComment,
            residencyComment: this.currentEvaluation?.ResidencyComment
        };
    }

    private mapResultToModel(result: IStudyQualityEvaluationResult, model: IStudyQualityEvaluationEditModel): IStudyQualityEvaluationEditModel {
        if (result) {
            Object.keys(result).forEach(k => {
                if (model[k] == undefined) {
                    model[k] = result[k];
                }
            });
        }

        return model;
    }
}
