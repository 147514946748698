import { BaseEntity } from './BaseEntity';

export enum DocumentTemplateType {
    Application = 'Application',
    Purchase = 'Purchase',
    CertificateRequest = 'CertificateRequest',
    FinalPaperAffirmation = 'FinalPaperAffirmation',
    OnCallShiftReport = 'OnCallShiftReport',
    ManipulationReport = 'ManipulationReport',
    AddGradesReport = 'AddGradesReport'
}

export class DocumentTemplate extends BaseEntity {
    Title: string;
    DateFrom: Date;
    DateTo: Date;
    UseCode: string;
    TemplateCode: string;
    FileLv: Blob;
    FileEn: Blob;
    FileName: string;
    Type: DocumentTemplateType;
    UserRights: string;
}
