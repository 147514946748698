<div class="wrapper-lg">
    <app-breadcrumbs [items]="[{ url: '/classifiers', text: 'classifiers_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'classifiers_title' | translate}}</h1>
        <aside>
            <input class="form-control"
                   (input)="search($event)"
                   [placeholder]="'classifiers_search' | translate"
                   maxlength="50">
        </aside>
    </header>

    <ngb-accordion #acc="ngbAccordion" (panelChange)="onPanelChange($event)" [activeIds]="openedGroupIds">
        <ng-container *ngFor="let g of getVisibleGroups()">
            <ngb-panel [title]="g.title" [id]="g.id">
                <ng-template ngbPanelContent>
                    <ul class="accordion-list">
                        <li *ngFor="let n of getVisibleItems(g)">
                            <a [routerLink]="['/classifiers', n.code] | localize">{{n.title || n.code}}</a>
                        </li>
                    </ul>
                </ng-template>
            </ngb-panel>
        </ng-container>
    </ngb-accordion>
</div>
