import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../../services/app.service';
import { IForm } from './seminar-attendance-form.component';

@Component({
    selector: 'app-seminar-attendance',
    templateUrl: './seminar-attendance.component.html'
})
export class SeminarAttendanceComponent implements OnInit {
    constructor(
        private app: AppService,
        private route: ActivatedRoute
    ) { }

    seminarId: string;

    get canEdit(): boolean {
        return this.form?.canEdit;
    }

    get hasChanges(): boolean {
        return this.form.rows.some(t => t.IsAttended != t.wasAttended);
    }

    private form: IForm;

    ngOnInit() {
        this.seminarId = this.route.snapshot.params['id'];
    }

    onLoad(event: IForm) {
        this.form = event;
    }

    save() {
        const loading = this.app.showLoading();
        this.form.save().then(() => {
            this.app.hideLoading(loading);
            this.app.notify(this.app.translate('seminar_attendance_saved'));
            this.app.navigateToStart();
        }, () => {
            this.app.hideLoading(loading);
        });
    }
}
