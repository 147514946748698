<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: 'timetable', text: 'timeTable_title_extended' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'timeTable_title_extended' | translate"></h1>
        <aside *ngIf="!isDenied && (canPickStudent || canPickEmployee)">
            <button type="button" class="btn btn-outline{{personPickerOpened && personPickerType == 'student' ? '-primary' : ''}}"
                    (click)="togglePersonPicker('student')" *ngIf="canPickStudent">
                <i class="fi fi-rs-user"></i>
                {{'timeTable_btnPickStudent' | translate}}
            </button>

            <button type="button" class="btn btn-outline{{personPickerOpened && personPickerType == 'employee' ? '-primary' : ''}}"
                    (click)="togglePersonPicker('employee')" *ngIf="canPickEmployee">
                <i class="fi fi-rs-badge"></i>
                {{'timeTable_btnPickEmployee' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="personPickerOpened" [personType]="personPickerType" (pick)="setPerson($event)"></app-person-picker>

    <div *ngIf="!personPickerOpened">
        <app-alert level="danger" *ngIf="isDenied">{{'eServiceNotAvailable' | translate}}</app-alert>

        <ng-container *ngIf="isLoaded">
            <span #top></span>

            <div class="data-value" *ngIf="person">
                <label *ngIf="isStudent">{{'timeTable_student' | translate}}</label>
                <label *ngIf="isEmployee">{{'timeTable_employee' | translate}}</label>
                <div *ngIf="isStudent">{{person.FirstName}} {{person.LastName}} ({{person.StudentId}})</div>
                <div *ngIf="isEmployee">{{person.FirstName}} {{person.LastName}} ({{person.Email}})</div>
            </div>

            <app-alert level="warning"
                       *ngIf="isStudent && !programs.length && noProgramsMessage"
                       [innerHTML]="noProgramsMessage | safeHtml"></app-alert>

            <ng-container *ngIf="isEmployee || programs.length">
                <app-alert level="info" *ngIf="infoTextTop">
                    <div [innerHTML]="infoTextTop | safeHtml"></div>
                </app-alert>

                <form #filterForm="ngForm">
                    <div class="row">
                        <div class="form-field col-md-6">
                            <app-select [placeholder]="'timeTable_lblFilterSemester' | translate"
                                        [disabled]="!selectedProgram && isStudent"
                                        [(ngModel)]="selectedSemester"
                                        [options]="semesters"
                                        [displayProperty]="'Name'"
                                        name="selectedSemester"
                                        (selectionChange)="loadInfo()"
                                        [required]="true">
                            </app-select>
                        </div>

                        <div class="form-field col-md-6 d-flex align-items-center">
                            <app-checkbox [(ngModel)]="fullSemester" name="fullSemester" (stateChange)="loadInfo()">
                                {{'timeTable_lblFullSemester' | translate}}
                            </app-checkbox>
                        </div>
                    </div>
                </form>

                <div class="row">
                    <div class="col-md mb-3">
                        <div class="btn-group" role="group">
                            <button *ngFor="let v of views"
                                    type="button"
                                    class="btn btn-outline"
                                    [class.is-active]="selectedView == v"
                                    (click)="switchView(v)">
                                {{'timeTable_view' + v | translate}}
                            </button>
                        </div>
                    </div>

                    <div class="col-md mb-3 text-md-end">
                        <button type="button" class="btn btn-outline-primary" (click)="$event.preventDefault(); pdf();"
                                [disabled]="!viewData || !filterForm.valid">
                            <i class="fi fi-rs-download"></i>
                            {{'timeTable_btnPdf' | translate}}
                        </button>
                    </div>
                </div>

                <div #eventTable class="table-container" *ngIf="viewData">
                    <table class="table event-table">
                        <tbody class="table-headers" *ngIf="selectedView == 'Daily'">
                            <!-- use tbody here because of pdf rendering issues -->
                            <tr>
                                <!--<th>{{(selectedView == 'Daily' ? 'timeTable_thDay' : 'timeTable_thDate') | translate}}</th>-->
                                <th>{{'timeTable_thTimeDay' | translate}}</th>
                                <th>{{'timeTable_thCourseLocationTeacher' | translate}}</th>
                                <th *ngIf="selectedView == 'Daily'" style="width: 30%">{{'timeTable_thDates' | translate}}</th>
                                <th>{{'timeTable_thComment' | translate}}</th>
                            </tr>
                        </tbody>

                        <tbody *ngIf="selectedView == 'Daily'" class="view-daily">
                            <ng-container *ngFor="let day of days">
                                <ng-container *ngIf="viewData[day]">
                                    <tr *ngFor="let n of viewData[day]; let i = index" class="row-{{n._color}} row-{{n.timeIndex === 0 ? 'primary' : 'adjacent'}}">
                                        <!--<td [attr.rowspan]="viewData[day].length" *ngIf="i === 0" class="day-cell">{{n.info.Day}}</td>-->
                                        <td [attr.rowspan]="n.timeTotal" class="time-cell" *ngIf="n.timeIndex === 0">
                                            <div class="text-top">{{n.timeIndex === 0 ? n.info.Time : ''}}</div>
                                            <div class="text-mute">{{n.info.Day}}</div>
                                        </td>
                                        <td class="info-cell">
                                            <div>
                                                <div *ngIf="n.info.SubjectCode" class="text-top">
                                                    <a href="{{n.info.SubjectCodeLink}}" target="_blank" *ngIf="n.info.SubjectCodeLink">
                                                        <span>{{n.info.SubjectCode}} </span>
                                                        <i class="fi fi-rs-arrow-circle-right"></i>
                                                    </a>
                                                    <span *ngIf="!n.info.SubjectCodeLink">{{n.info.SubjectCode}}</span>
                                                </div>
                                                <div *ngIf="n.info.SubjectName">
                                                    <a href="{{n.info.MoodleLink}}" target="_blank" *ngIf="n.info.MoodleLink">
                                                        <span>{{n.info.SubjectName}} </span>
                                                        <i class="fi fi-rs-arrow-circle-right"></i>
                                                    </a>
                                                    <span *ngIf="!n.info.MoodleLink">{{n.info.SubjectName}}</span>
                                                </div>
                                                <div *ngIf="n.info.EventType">{{n.info.EventType}}</div>
                                            </div>

                                            <div class="text-sub" *ngIf="n.info.Location">
                                                <i class="fi fi-rs-marker"></i>
                                                <a *ngIf="n.info.LocationLink" href="{{n.info.LocationLink}}" target="_blank">{{n.info.Location}}</a>
                                                <span *ngIf="!n.info.LocationLink">{{n.info.Location}}</span>
                                            </div>

                                            <div class="text-sub" *ngIf="n.info.Teacher">
                                                <i class="fi fi-rs-user"></i> {{n.info.Teacher}}
                                            </div>
                                        </td>
                                        <td class="date-cell">
                                            <i class="fi fi-rs-calendar-check date-icon"></i>
                                            {{n.dates.join(', ')}}
                                        </td>
                                        <td class="comment-cell" [class.empty-cell]="!n.comments.length">
                                            <div class="comment" *ngFor="let c of n.comments">
                                                <i class="fi fi-rs-comment comment-icon"></i>
                                                <div>
                                                    <div><strong>{{c.date}}</strong> {{c.comment}}</div>
                                                    <div *ngIf="c.employeeComment" class="employee-comment">{{c.employeeComment}}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr class="row-empty" *ngIf="!viewData[day]">
                                    <td class="day-cell">{{dayNames[day - 1]}}</td>
                                    <td colspan="9" class="day-empty-msg">{{'timeTable_nothingPlanned' | translate}}</td>
                                </tr>
                            </ng-container>
                        </tbody>

                        <tbody class="table-headers" *ngIf="selectedView == 'Agenda'">
                            <!-- use tbody here because of pdf rendering issues -->
                            <tr>
                                <th>{{'timeTable_thDate' | translate}}</th>
                                <th>{{'timeTable_thTime' | translate}}</th>
                                <th>{{'timeTable_thCourseLocationTeacher' | translate}}</th>
                                <th>{{'timeTable_thComment' | translate}}</th>
                            </tr>
                        </tbody>

                        <tbody *ngIf="selectedView == 'Agenda'" class="view-agenda">
                            <tr *ngFor="let n of viewData; let i = index" class="row-{{n._color}} row-{{n._dateIndex === 0 ? 'primary' : 'adjacent'}}">
                                <td [attr.rowspan]="n._dateTotal" *ngIf="n._dateIndex === 0" class="day-cell">
                                    <div class="text-top">{{n.Day}}</div>
                                    <div class="text-mute">{{n.Date}}</div>
                                </td>
                                <td class="time-cell">
                                    <span class="time-value">{{n.Time}}</span>
                                    <div class="mobile-header">
                                        <div class="text-top">{{n.Date}}, {{n.Day}}</div>
                                        <div>{{n.Time}}</div>
                                    </div>
                                </td>
                                <td class="info-cell">
                                    <div>
                                        <div class="text-top" *ngIf="n.SubjectCode">
                                            <a href="{{n.SubjectCodeLink}}" target="_blank" *ngIf="n.SubjectCodeLink">
                                                <span>{{n.SubjectCode}} </span>
                                                <i class="fi fi-rs-arrow-circle-right"></i>
                                            </a>
                                            <span *ngIf="!n.SubjectCodeLink">
                                                <span>{{n.SubjectCode}} </span>
                                                <i class="fi fi-rs-arrow-circle-right"></i>
                                            </span>
                                        </div>
                                        <div *ngIf="n.SubjectName">
                                            <a href="{{n.MoodleLink}}" target="_blank" *ngIf="n.MoodleLink">
                                                <span>{{n.SubjectName}} </span>
                                                <i class="fi fi-rs-arrow-circle-right"></i>
                                            </a>
                                            <span *ngIf="!n.MoodleLink">{{n.SubjectName}}</span>
                                        </div>
                                        <div *ngIf="n.EventType">{{n.EventType}}</div>
                                    </div>
                                    <div class="text-sub" *ngIf="n.Location">
                                        <i class="fi fi-rs-marker"></i>
                                        <a *ngIf="n.LocationLink" href="{{n.LocationLink}}" target="_blank">{{n.Location}}</a>
                                        <span *ngIf="!n.LocationLink">{{n.Location}}</span>
                                    </div>
                                    <div class="text-sub" *ngIf="n.Teacher">
                                        <i class="fi fi-rs-user"></i> {{n.Teacher}}
                                    </div>
                                </td>
                                <td class="comment-cell"
                                    [class.empty-cell]="!n.Comment && !n.IsSent && !n.EmployeeComment && n.IsPublished !== false"
                                    [class.has-icons]="n.IsSent || n.IsPublished === false">
                                    <div class="comment">
                                        <i class="fi fi-rs-comment comment-icon"></i>
                                        <div>
                                            {{n.Comment}}
                                            <div *ngIf="n.EmployeeComment" class="employee-comment">{{n.EmployeeComment}}</div>
                                        </div>
                                        <span class="comment-icons">
                                            <span *ngIf="n.IsSent" [ngbTooltip]="'timeTable_isSentTooltip' | translate">
                                                <i class="fi fi-rs-cloud is-sent"></i>
                                            </span>

                                            <span *ngIf="n.IsPublished === false" [ngbTooltip]="'timeTable_notPublishedTooltip' | translate">
                                                <i class="fi fi-rs-eye-crossed"></i>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr class="row-empty" *ngIf="!viewData.length">
                                <td colspan="9" class="day-empty-msg">{{'timeTable_nothingPlanned' | translate}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row mb-3">
                    <div class="col-10">
                        <a href="{{officeCalendarUrl}}" target="_blank" class="o365-link" *ngIf="showOfficeCalendarLink && officeCalendarUrl">
                            <img src="../../assets/img/office-365.svg" />
                            {{'timeTable_openOfficeCalendar' | translate}}
                        </a>
                    </div>

                    <div class="col-2 go-top">
                        <span (click)="scrollTop();" class="scroll-top-btn" #scrollTopBtn>
                            <i class="fi fi-rs-arrow-circle-up" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

                <app-alert level="info" *ngIf="infoTextBottom">
                    <div [innerHTML]="infoTextBottom | safeHtml"></div>
                </app-alert>
            </ng-container>
        </ng-container>
    </div>
</div>
