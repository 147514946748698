<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'courseEqualization_approvalTitle' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="form-field required">
            <label class="form-label">{{'courseEqualization_lblApprovalDecision' | translate}}</label>
            <app-select [(ngModel)]="decision"
                        [options]="decisionOptions"
                        [displayProperty]="'Value'"
                        [required]="true"
                        name="decision">
            </app-select>
        </div>

        <div class="form-field" [class.required]="isCommentRequired">
            <label class="form-label">{{'courseEqualization_lblApprovalComment' | translate}}</label>
            <textarea class="form-control"
                      [(ngModel)]="comment"
                      rows="5"
                      name="comment"
                      maxlength="4000"
                      [required]="isCommentRequired"
                      #commentInput="ngModel">
            </textarea>
            <div class="invalid-feedback" *ngIf="commentInput.errors?.maxlength">
                {{'fieldError_lengthMinMax' | translate | formatString:[0, 4000]}}
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()">{{'dialog_cancel' | translate}}</button>
    <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="!form.valid">{{'dialog_ok' | translate}}</button>
</div>
