import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';
import { LogService } from '../services/log.service';

let isAccessDenied = false;

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    private _app: AppService;
    private get app(): AppService {
        if (!this._app)
            this._app = this.injector.get(AppService);

        return this._app;
    }

    private _auth: AuthService;
    private get auth(): AuthService {
        if (!this._auth)
            this._auth = this.injector.get(AuthService);

        return this._auth;
    }

    private _log: LogService;
    private get log(): LogService {
        if (!this._log)
            this._log = this.injector.get(LogService);

        return this._log;
    }

    constructor(private injector: Injector, private ngZone: NgZone) { }

    handleError(error) {
        let logToConsole = false;
        let showError = false;
        let text: string;

        if (error instanceof HttpErrorResponse) {
            let errorText = error.error;

            if (error.status == 0) {
                // ignore aborted requests
                return;
            } else {
                showError = true;
                logToConsole = true;

                if (error.status == 401) {
                    if (!this.app.currentUser) {
                        logToConsole = false;
                    }

                    if (errorText === 'anonymousnotallowed' && !isAccessDenied) {
                        // prevent from logging out multiple times since many requests may throw access denied simultaneously
                        isAccessDenied = true;
                        showError = false;

                        this.app.showLoading();
                        this.auth.logout();
                    }
                }

                if (!errorText) {
                    errorText = `httpStatus_${error.status}`;
                }

                try {
                    text = this.app.translate(errorText);
                } catch (ex) {
                    text = this.app.translate('unexpectedErrorContactAdmin');
                }
            }
        } else {
            text = this.app.translate('unexpectedErrorContactAdmin');
            showError = true;
            logToConsole = true;
        }

        if (this.auth.isAuthenticated && error) {
            try {
                const isObj = typeof error === 'object';
                let errorData = isObj && (error.stack || error.error || error.message);

                if (!errorData) {
                    if (isObj) {
                        try {
                            errorData = JSON.stringify(error);
                        } catch (ex) { }
                    } else {
                        errorData = error.toString();
                    }
                }

                if (errorData) {
                    this.log.logError(errorData).subscribe(() => { }, () => { });
                }
            } catch (ex) { }
        }

        logToConsole && console.error(error);

        this.ngZone.run(() => {
            showError && this.app.showError(text);   
        });
    }
}
