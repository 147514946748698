import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';
import {
    ISurveyQuestion, SurveyQuestionDisplayType, SurveyAnswerEvaluationType,
    NoValue, YesValue, NoOpinionValue
} from '../../../models/Survey';
import { SurveyUtils } from '../SurveyUtils';

@Component({
    selector: 'app-survey-question-form',
    templateUrl: './survey-question-form.component.html',
    styleUrls: ['../survey.component.scss']
})
export class SurveyQuestionFormComponent implements OnInit {
    constructor(
        private app: AppService
    ) { }

    @Input() question: ISurveyQuestion;
    @Input() key: string;
    @Input() model: Record<string, any> = {};
    @Input() validate: boolean;

    readonly util = SurveyUtils;
    readonly answerTypes = SurveyAnswerEvaluationType;
    readonly valueYes = YesValue;
    readonly valueNo = NoValue;
    readonly valueNoOpinion = NoOpinionValue;

    isGrid: boolean;
    isCombo: boolean;
    isRadio: boolean;
    isText: boolean;
    isNumber: boolean;

    hasNoOpinion: boolean;
    options: { text: string, value: any }[] = [];

    ngOnInit() {
        this.hasNoOpinion = this.question.HasNoOpinion;

        if (SurveyUtils.isGridAnswer(this.question.AnswerEvaluationType)) {
            this.isGrid = true;
        } else if (SurveyUtils.isChoiceAnswer(this.question.AnswerEvaluationType)) {
            let displayType = SurveyQuestionDisplayType.Combo;

            if (this.question.DisplayType != undefined) {
                displayType = this.question.DisplayType;
            }

            this.isCombo = displayType == SurveyQuestionDisplayType.Combo;
            this.isRadio = displayType == SurveyQuestionDisplayType.Radio;
        }

        this.isText = SurveyUtils.isTextAnswer(this.question.AnswerEvaluationType);
        this.isNumber = SurveyUtils.isNumberAnswer(this.question.AnswerEvaluationType);

        this.options = SurveyUtils.buildAnswerOptions(this.question, key => this.app.translate(key));
    }

    getQuestionKeyBuilder() {
        return (question: ISurveyQuestion) => SurveyUtils.buildQuestionKey(question.ID);
    }
}
