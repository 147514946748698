import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { IEbusEventSession } from '../../../models/Ebus';

import { AppService } from '../../../services/app.service';
import { DoctorService } from '../../../services/doctor.service';
import { store } from '../store';

let currentSession: { data: IEbusEventSession, user: string };

@Component({
    selector: 'app-doctor-dashboard-tasks',
    templateUrl: './doctor-dashboard-tasks.component.html'
})
export class DoctorDashboardTasksComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: DoctorService
    ) { }

    ready: boolean;
    sessionUrl: string;

    ngOnInit() {
        this.initSession().pipe(finalize(() => {
            this.ready = true;
        })).subscribe(data => {
            this.sessionUrl = data?.Url;
        }, err => { });
    }

    private initSession() {
        if (currentSession?.data && currentSession?.user == store.getPerson()?.Upn) {
            return of(currentSession.data);
        } else {
            currentSession = {
                data: null,
                user: store.getPerson()?.Upn
            };

            return this.app.addLoading(this.service.initTaskSession(store.getPerson()?.Upn)).pipe(tap(data => {
                currentSession.data = data;
            }));
        }
    }
}
