<ul class="treeview" *ngIf="nodes.length">
    <li *ngFor="let node of nodes">
        <div>
            <span *ngIf="node[childrenAttr].length" class="treeview-expcol" (click)="click(node)">
                <i class="fi fi-rs-{{node[collapseAttr] ? 'plus' : 'minus'}}"></i>
            </span>

            <span *ngIf="!node[childrenAttr].length" class="treeview-padding"></span>

            <app-checkbox [(ngModel)]="node[selectAttr]" (stateChange)="onModelChange(node)" [name]="node[idAttr]">
                {{node[nameAttr]}}
            </app-checkbox>
        </div>

        <div *ngIf="!node[collapseAttr]">
            <app-treeview *ngIf="node[childrenAttr].length"
                          [data]="node[childrenAttr]"
                          [parent]="node"
                          [nameAttr]="nameAttr"
                          [prepareData]="false"
                          [parentAttr]="parentAttr"
                          [allowMultiple]="allowMultiple"
                          [idAttr]="idAttr"
                          [collapseAll]="collapseAll"
                          [selectAll]="selectAll"
                          (onChange)="change($event)">
            </app-treeview>
        </div>
    </li>
</ul>
