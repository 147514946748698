import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';

import { PersonMainDataItem, StudentCoursesItem, CourseEvent } from '../models/BC_courses';

/**
 * BC courses service
 */
@Injectable({ providedIn: "root" })
export class BC_CoursesService {
	constructor(public http: HttpClient) { }

	public get apiUrl(): string { return `${ENV.apiUrl}/bc_courses` }

	getPersonMainData(email?: string): Observable<PersonMainDataItem[]> {
        return this.http.get<PersonMainDataItem[]>(`${this.apiUrl}/personMainData`, {
			params: {
				email
			}
		});
	}

    getStudentCourses(studentId: string, planPer: string, pietStatuss: boolean = false): Observable<StudentCoursesItem[]> {
        const params: any = {
            studentId,
            planPer,
            pietStatuss
        };

        return this.http.get<StudentCoursesItem[]>(`${this.apiUrl}/courses`, { params });
	}

    addProgram(data: {
        studentGuid: string,
        subjectSemester: string,
        applicantFullname: string,
        planningPeriodId: string,
        planningGroupGuid?: string
    }): Observable<any> {
        return this.http.post(`${this.apiUrl}/programs`, data);
	}

	deleteProgram(applicationId: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/programs/${applicationId}`);
    }

    getCourseEvents(groupId: string): Observable<CourseEvent[]> {
        return this.http.get<CourseEvent[]>(`${this.apiUrl}/groups/${groupId}/events`);
    }
}
