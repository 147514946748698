import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';

import { PortfolioService } from '../../../services/portfolio.service';
import { IAttachment, IPortfolio, IReport, ISpecification } from '../../../models/Portfolio';
import { ITableColumn } from '../../../shared/table/table.component';

import * as FileSaver from 'file-saver';

interface IRow extends ISpecification {
    level: number;
}

@Component({
    selector: 'app-portfolio-data',
    templateUrl: './portfolio-data.component.html',
    styleUrls: ['../portfolio.component.scss']
})
export class PortfolioDataComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PortfolioService
    ) { }

    @Input() data: IPortfolio;
    @Input() sessionId: string;

    readonly columns: ITableColumn[] = [
        { property: 'Title', label: 'portfolio_lblTitle' },
        { property: 'Type', label: 'portfolio_lblType' },
        { property: 'Date', label: 'portfolio_lblDate' },
        { property: 'Credits', label: 'portfolio_lblCredits' },
        { property: 'Result', label: 'portfolio_lblResult' }
    ];

    pads: { [count: number]: number[] } = {};
    reports: { data: IReport, rows: IRow[] }[] = [];
    attachments: IAttachment[] = [];
    canDownloadXml: boolean;

    ngOnInit() {
        this.canDownloadXml = this.app.currentUser.isAdmin;

        const addRow = (rows: IRow[], item: ISpecification, level: number) => {
            rows.push({
                ...item,
                level
            });

            if (!this.pads[level]) {
                this.pads[level] = Array(level).fill(0);
            }

            item.Parts.forEach(t => addRow(rows, t, level + 1));
        };

        this.attachments = [...this.data.Attachments];

        this.data.Reports.forEach(t => {
            this.attachments.push(...t.Attachments);

            const rows: IRow[] = [];

            t.Specifications.forEach(s => {
                addRow(rows, s, 0);
            });

            this.reports.push({
                data: t,
                rows
            });
        });
    }

    downloadAttachment(attachment: IAttachment) {
        const ix = attachment.Index;
        const request = this.data.Id
            ? this.service.downloadAttachment(this.data.Id, ix)
            : this.service.downloadPendingAttachment(this.sessionId, ix);

        this.app.addLoading(request).subscribe(result => {
            let title = attachment.Path.split('/').join('_');

            if (title.length > 250) {
                title = title.substring(0, 250);
            }

            FileSaver.saveAs(result, `${title}.pdf`);
        });
    }

    downloadXml() {
        const request = this.data.Id
            ? this.service.downloadXml(this.data.Id)
            : this.service.downloadPendingXml(this.sessionId);

        this.app.addLoading(request).subscribe(result => {
            FileSaver.saveAs(result, `portfolio-${this.data.Id || this.sessionId}.xml`);
        });
    }

    prettyPath(path: string): string {
        return path.split('/').join(' / ');
    }
}
