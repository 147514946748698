import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApplicationKind, ApplicationType, Student } from '../../models/Application';

import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { PersonService } from '../../services/person.service';

import { ApplicationComponentBase } from './ApplicationComponentBase';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';
import { ClassifierService } from '../../services/classifier.service';
import { IPersonSearchResultItem } from '../../models/Person';

@Component({
    selector: 'app-application-form',
    styleUrls: ['./application.component.scss'],
    templateUrl: './form.component.html'
})
export class FormComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        private app: AppService,
        private service: ApplicationService,
        private studentService: PersonService,
        private classifierService: ClassifierService,
        private route: ActivatedRoute
    ) { }

    isDenied: boolean;
    ready: boolean;
    title: string;
    description: string;
    type: ApplicationType | string;
    kind: ApplicationKind;
    studentPickerOpened: boolean;
    currentStudentEmail: string;
    canPickStudent: boolean;
    studentUpn: number;
    isCustomApplication: boolean;
    studentData: Student[];
    selectedProgramme: Student;
    finished: boolean;

    get pickerAdditionalFilter(): string {
        return this.type && this.type === ApplicationType.Applicant ? 'applicant' : '';
    }

    get isSkillsRef(): boolean {
        return this.type === ApplicationType.Skills;
    }

    @ViewChild('appForm', { static: true }) private appForm: ApplicationComponentBase;

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user && user.rights.indexOf('APPLICATION.SET_STUDENT') > -1;

            const para = this.route.snapshot.queryParams;
            const id = para['id'];

            if (id && !this.canPickStudent) {
                this.isDenied = true;
                return;
            }

            const reqType = para['type'];

            this.app.addLoading(this.classifierService.get('ApplicationType')).subscribe(types => {
                const typeCode = `ApplicationType${reqType}`;
                const resolvedType = types.find(t => t.Code === typeCode);

                if (resolvedType) {
                    if (Object.keys(ApplicationType).map(t => ApplicationType[t]).indexOf(reqType) > -1) {
                        this.type = <ApplicationType>reqType;
                        this.kind = this.service.getKindByType(<ApplicationType>this.type);
                    } else {
                        this.isCustomApplication = true;
                        this.type = reqType;
                        this.kind = ApplicationKind.Application;
                    }
                }

                if (!this.type) {
                    this.app.showError(this.app.translate('application_typeUnknown'));
                } else if (this.canPickStudent) {
                    if (id) {
                        this.studentUpn = id;

                        this.app.addLoading(this.studentService.findStudents('student_id', id)).subscribe(data => {
                            this.ready = true;

                            if (data.length) {
                                this.pickStudent(data[0]);
                            } else {
                                this.app.showError(this.app.translate('studentNotFound'));
                                this.toggleStudentPicker();
                            }
                        });
                    } else {
                        this.ready = true;
                        this.toggleStudentPicker();
                    }
                } else {
                    this.loadStudentData();
                    this.ready = true;
                }
            });
        }
    }

    canDeactivate() {
        return this.appForm ? this.appForm.canDeactivate() : true;
    }

    toggleStudentPicker() {
        this.studentPickerOpened = !this.studentPickerOpened;
    }

    pickStudent(student: IPersonSearchResultItem) {
        this.currentStudentEmail = student.Email;
        this.studentPickerOpened = false;
        this.selectedProgramme = undefined;
        this.finished = false;

        this.loadStudentData(this.currentStudentEmail);
    }

    onTitleChange(event: string) {
        this.title = event;
    }

    onDescriptionChange(event: string) {
        this.description = event;
    }

    private loadStudentData(email?: string) {
        this.app.addLoading(this.service.getStudent(this.kind, email)).subscribe(data => {
            if (this.type === ApplicationType.AcademicLeave) {
                data = data.filter(t => t.StudentStatusType === 'Active' || t.StudentStatusType === 'AcademicLeave');
            }

            this.studentData = data;
            this.selectedProgramme = data.length && data[0];
        }, err => {
            this.studentData = null;
        });
    }
}
