<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/email-alias', text: 'emailAlias_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'emailAlias_title'"></h1>
        <aside *ngIf="canPickStudent && !showStudentPicker">
            <button type="button" class="btn btn-outline" (click)="showStudentPicker = !showStudentPicker">
                <i class="fi fi-rs-user"></i>
                {{'emailAlias_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <app-person-picker *ngIf="showStudentPicker" (pick)="setStudent($event)"></app-person-picker>

    <ng-container *ngIf="isLoaded && !showStudentPicker">
        <div class="data-value" *ngIf="student">
            <label>{{'emailAlias_student' | translate}}</label>
            <div>{{student.FirstName}} {{student.LastName}} ({{student.StudentId}})</div>
        </div>

        <app-alert level="danger" *ngIf="isError">
            {{'unknownError' | translate}}
        </app-alert>

        <ng-container *ngIf="!isError">
            <app-alert level="warning" *ngIf="nextChangeDate || changeCountReached">
                <ng-container *ngIf="nextChangeDate">
                    {{'emailAlias_nextChangeNotification' | translate | formatString:[nextChangeDate | appDate]}}
                </ng-container>
                <ng-container *ngIf="changeCountReached">
                    {{'emailAlias_maxChangeCountReached' | translate}}
                </ng-container>
            </app-alert>

            <app-alert level="info" *ngIf="rules && (changeCountReached || nextChangeDate)">
                <div [innerHTML]="rules | safeHtml"></div>
            </app-alert>

            <div class="ea-form" *ngIf="rights.add && canChange">
                <div class="row">
                    <div class="col-md form-field">
                        <div class="email-field">
                            <input class="form-control" [ngModel]="email"
                                   (ngModelChange)="onEmailChange($event)"
                                   name="email"
                                   [placeholder]="'emailAlias_lblEmail' | translate"
                                   [maxlength]="config.MaxEmailSymbolCount"
                                   (blur)="sanitizeEmail()" />
                            <span>@{{config.EmailDomain}}</span>
                        </div>
                        <div *ngIf="isAvailable === true" class="feedback text-success">{{'emailAlias_emailAvailable' | translate}}</div>
                        <div *ngIf="isAvailable === false" class="feedback text-danger">{{'emailAlias_emailUnavailable' | translate}}</div>
                        <div *ngIf="isValid === false" class="feedback text-danger">{{'emailAlias_emailInvalid' | translate}}</div>
                    </div>

                    <div class="col-md form-field">
                        <button type="button" class="btn btn-outline" (click)="checkAvailability()" [disabled]="!email || isChecking">
                            <i class="fi fi-rs-text-check" *ngIf="!isChecking"></i>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isChecking"></span>
                            {{'emailAlias_btnCheckAvailability' | translate }}
                        </button>
                    </div>
                </div>

                <ng-container *ngIf="isAvailable">
                    <div class="mb-2">
                        <app-checkbox [(ngModel)]="isPrimary">{{'emailAlias_lblUseAsPrimaryEmail' | translate}}</app-checkbox>
                    </div>

                    <div class="mb-3">
                        <button class="btn btn-primary" type="button" (click)="addEmail()">
                            <i class="fi fi-rs-plus"></i>
                            {{'emailAlias_btnAddEmail' | translate}}
                        </button>
                    </div>
                </ng-container>

                <app-alert level="info" *ngIf="rules">
                    <div [innerHTML]="rules | safeHtml"></div>
                </app-alert>
            </div>

            <section class="block">
                <h5>{{'emailAlias_appliedEmails' | translate}}</h5>

                <app-table [rows]="emails" [columns]="columns" sticky="right">
                    <ng-template #rowTemplate let-row let-i="index">
                        <tr>
                            <td class="hidden-sm-down">{{i + 1}}</td>
                            <td>
                                <div>{{row.Email}}</div>
                                <div class="hidden-sm-up text-sub">{{row.ApplicationDate | appDate}}</div>
                                <div class="hidden-md-up">
                                    <span *ngIf="row.Status === statusEnum.Error" class="text-danger">{{'emailAlias_emailError' | translate}}</span>
                                    <strong *ngIf="row.Status !== statusEnum.Error && row.IsPrimary" class="text-info">{{'emailAlias_lblIsPrimary' | translate}}</strong>
                                    <a *ngIf="rights.setPrimary && !row.IsPrimary && row.Status !== statusEnum.Error"
                                       href="#"
                                       (click)="$event.preventDefault(); setPrimary(row)"
                                       [title]="'emailAlias_btnSetPrimary' | translate">
                                        {{'emailAlias_btnSetPrimary' | translate}}
                                    </a>
                                </div>
                            </td>
                            <td class="hidden-xs-down">{{row.ApplicationDate | appDate}}</td>
                            <td class="hidden-sm-down">
                                <span *ngIf="row.Status === statusEnum.Error" class="text-danger">{{'emailAlias_emailError' | translate}}</span>
                                <strong *ngIf="row.Status !== statusEnum.Error && row.IsPrimary" class="text-info">{{'emailAlias_lblIsPrimary' | translate}}</strong>
                                <a *ngIf="rights.setPrimary && !row.IsPrimary && row.Status !== statusEnum.Error"
                                   href="#"
                                   (click)="$event.preventDefault(); setPrimary(row)"
                                   [title]="'emailAlias_btnSetPrimary' | translate">
                                    {{'emailAlias_btnSetPrimary' | translate}}
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </app-table>
            </section>
        </ng-container>
    </ng-container>
</div>
