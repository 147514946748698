import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Utils } from '../../core/Utils';

import { ApplicationFormFieldType } from '../../models/ApplicationConfig';

@Component({
    selector: 'app-application-custom-field',
    templateUrl: './custom-field.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ApplicationCustomFieldComponent implements OnInit {
    @Input() value: any;
    @Input() type: ApplicationFormFieldType;
    @Input() name: string;
    @Input() label: string;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() choices: { value: any, text: string }[];
    @Input() choicesFn: (term: string) => Observable<{ value: any, text: string }[]>;
    
    @Output() valueChange = new EventEmitter<any>();

    get pattern(): string {
        switch (this.type) {
            case 'Integer': return '^(0|[1-9][0-9]*)$';
            case 'Money': return '^(0|[1-9][0-9]*)[\.,][0-9]{2}$';
        }

        return '';
    }

    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        if (this.type === 'Money') {
            this.value = this.convertToMoney(this.value);
        }

        if (this.choicesFn) {
            this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
                this.choicesFn(term).subscribe(data => {
                    this.choices = data || [];
                });
            });
        }
    }

    change(value: any) {
        if (value && this.type == 'Date') {
            value = Utils.getInputDateString(value);
        }

        this.value = value;
        this.valueChange.emit(this.value);
    }

    onSearch(value: string) {
        this.searchSubj.next(value);
    }

    onBlur() {
        if (this.type === 'Money') {
            this.value = this.convertToMoney(this.value);
        }
    }

    private convertToMoney(value: string | number): string {
        if (value === '') return '';

        let out = value + '';

        if (!isNaN(+out) && out.indexOf('.') === -1 && out.indexOf(',') === -1) {
            out += '.00';
        }

        return out;
    }
}
