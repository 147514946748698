import { Component, Input, OnInit } from '@angular/core';

import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Utils } from '../../../core/Utils';
import { IPlanSummaryShiftDetails, IPlanSummaryShiftHeader } from '../../../models/PlanSummary';
import { AppDatePipe } from '../../../pipes/date.pipe';
import { AppService } from '../../../services/app.service';
import { PlanSummaryService } from '../../../services/plan-summary.service';
import { ITableColumn } from '../../../shared/table/table.component';
import { store, currentRequestParams } from '../plan-summary.component';
import { Util } from '../util';

interface IShiftsByMonth {
    month: number;
    monthName: string;
    hoursApproved: number;
    companiesApproved: string[];
    shifts: IPlanSummaryShiftDetails[];
}

interface IShiftsByYear {
    year: number;
    yearName: string;
    hoursPlanned: number;
    hoursApproved: number;
    shifts: IShiftsByMonth[];
};

@Component({
    selector: 'app-plan-summary-shifts',
    templateUrl: './plan-summary-shifts.component.html',
    styleUrls: ['../plan-summary.component.scss']
})
export class PlanSummaryShiftsComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PlanSummaryService,
        private appDate: AppDatePipe
    ) { }

    @Input() studyYear: number;
    @Input() residentEmail: string;
    @Input() getFileUrlFn: (fileId: string) => string = (fileId) => {
        return this.service.getFileUrl(fileId);
    };

    shiftsByYear: IShiftsByYear[] = [];
    total: number;
    error: string;
    isLoaded: boolean;
    canView: boolean;
    canAdd: boolean;

    readonly util = Util;

    readonly columns: ITableColumn[] = [
        { property: 'month', label: 'planSummary_shifts_lblMonth', sorts: true, type: 'number' },
        { property: 'hoursApproved', label: 'planSummary_shifts_lblHours', sorts: true, type: 'number' },
        { property: 'CompanyName', label: 'planSummary_shifts_lblWorkplace', cssClass: 'hidden-sm-down', width: '50%' }
    ];

    get residentUpn(): string {
        return (this.residentEmail || '').split('@')[0];
    }

    private headers: IPlanSummaryShiftHeader[] = [];
    private details: IPlanSummaryShiftDetails[] = [];
    private allShiftsByYear: IShiftsByYear[] = [];

    private readonly searchSubj = new Subject<string>();

    ngOnInit() {
        if (this.app.currentUser) {
            const user = this.app.currentUser;
            const self = user.email == this.residentEmail;
            const canSet = user.rights.includes('ON_CALL_SHIFT.SET_STUDENT');

            this.canView = user.rights.includes('ON_CALL_SHIFT.VIEW') && (self || canSet);
            this.canAdd = user.rights.includes('ON_CALL_SHIFT.EDIT') && (self || canSet);
        }

        this.searchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            if (!term) {
                this.shiftsByYear = [...this.allShiftsByYear];
            } else {
                const search = term.toLowerCase();
                const byYear: IShiftsByYear[] = [];

                this.allShiftsByYear.forEach(t => {
                    const year = { ...t };
                    year.shifts = [];

                    t.shifts.forEach(s => {
                        const month = { ...s };

                        if (month.monthName?.toLowerCase().includes(search)
                            || month.hoursApproved?.toString().includes(search)) {
                            month.shifts = s.shifts;
                        } else {
                            month.shifts = s.shifts.filter(sh => {
                                return sh.CompanyName?.toLowerCase().includes(search)
                                    || sh.HoursStatusName?.toLowerCase().includes(search)
                                    || this.appDate.transform(sh.Date).includes(search);
                            });
                        }

                        if (month.shifts.length) {
                            this.util.toggle(month);
                            year.shifts.push(month);
                        }
                    });

                    if (year.shifts.length) {
                        this.util.toggle(year);
                        byYear.push(year);
                    }
                });

                this.shiftsByYear = byYear;
            }
        });

        this.getData().subscribe(data => {
            this.isLoaded = true;
            this.headers = data.Headers;
            this.details = data.Details;

            this.headers.forEach(t => {
                let g = this.allShiftsByYear.find(g => g.year == t.StudyYear);

                if (!g) {
                    g = {
                        year: t.StudyYear,
                        yearName: t.StudyYearName,
                        hoursApproved: t.HoursApproved,
                        hoursPlanned: t.HoursPlanned,
                        shifts: []
                    };

                    this.details.filter(d => d.StudyYear == g.year && d.HoursStatusCode != 'ATC').forEach(d => {
                        let m = g.shifts.find(mg => mg.month == d.Month);

                        if (!m) {
                            m = {
                                month: d.Month,
                                monthName: d.MonthName,
                                hoursApproved: 0,
                                companiesApproved: [],
                                shifts: []
                            };

                            g.shifts.push(m);
                        }

                        if (d.HoursStatusCode == 'APST') {
                            m.hoursApproved += (d.Hours || 0);

                            if (!m.companiesApproved.includes(d.CompanyName)) {
                                m.companiesApproved.push(d.CompanyName);
                            }
                        }

                        m.shifts.push(d);
                    });

                    this.allShiftsByYear.push(g);
                }
            });

            this.shiftsByYear = [...this.allShiftsByYear];

            if (this.shiftsByYear.length) {
                this.shiftsByYear.forEach(y => {
                    y.shifts.forEach(m => {
                        m.shifts.sort((a, b) => Utils.ensureDate(a.Date).getTime() - Utils.ensureDate(b.Date).getTime());
                    });
                });

                const currentYear = this.shiftsByYear.find(t => t.year == this.studyYear);
                this.util.toggle(currentYear || this.shiftsByYear[0]);
            }

            this.total = this.allShiftsByYear.length;
        }, err => {
            this.error = this.app.getHttpResponseError(err);
        });
    }

    search(event) {
        this.searchSubj.next(event.target.value);
    }

    getFileUrl(shift: IPlanSummaryShiftDetails): string {
        return this.getFileUrlFn(shift.ReportId);
    }

    private getData() {
        if (store.shifts) {
            return of(store.shifts);
        } else {
            return this.app.addLoading(this.service.getShifts(currentRequestParams)).pipe(tap(data => {
                store.shifts = data;
            }));
        }
    }
}
