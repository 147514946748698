<div class="modal-header">
    <h4 class="modal-title">{{'certRequests_emailPreview' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="row">
            <div class="form-field col-sm required">
                <input class="form-control" [(ngModel)]="eventCode" name="eventCode" required
                       [placeholder]="'certRequests_lblEventCode' | translate" maxlength="100">
            </div>

            <div class="form-field col-sm required">
                <input class="form-control" [(ngModel)]="email" name="email" required
                       [placeholder]="'certRequests_lblEmail' | translate" maxlength="100">
            </div>

            <div class="form-field col-sm required">
                <button type="button" (click)="load()" class="btn btn-outline" [disabled]="!form.valid || isFiltering">
                    <i class="fi fi-rs-filter" *ngIf="!isFiltering"></i>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isFiltering"></span>
                    {{'certRequests_btnView' | translate}}
                </button>
            </div>
        </div>
    </form>

    <app-alert level="danger" *ngIf="error">{{error}}</app-alert>
    <div [innerHTML]="body | safeHtml" class="email-preview-body" *ngIf="body"></div>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="close()" [disabled]="isFiltering">
        {{'dialog_close' | translate}}
    </button>
</div>
