import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';
import { ClassifierService } from '../../services/classifier.service';
import { ParameterService } from '../../services/parameter.service';

import { FinalPaperReviewService } from '../../services/final-paper-review.service';
import { FinalPaperFeedbackType, IFinalPaperFeedbackEditModel, IFinalPaperFile, IFinalPaperReview, IFinalPaperUser } from '../../models/FinalPaperReview';
import { Classifier } from '../../models/Classifier';
import { createFileStub } from '../../shared/file/file.component';
import { FinalPaper, FinalPaperStatus } from '../../models/FinalPaper';
import { store } from './store';

interface IFeedback {
    id?: number;
    author: IFinalPaperUser;
    content?: string;
    fileName?: string;
    grade?: string;
    type: FinalPaperFeedbackType;
    my: boolean;
    isReadOnly: boolean;
    isObsolete: boolean;
    isEditing?: boolean;
}

type NavId = 'finalPaper' | 'approval' | 'review';

@Component({
    selector: 'app-final-paper-review',
    templateUrl: './final-paper-review.component.html',
    styleUrls: ['./final-paper-review.component.scss']
})
export class FinalPaperReviewComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: FinalPaperReviewService,
        private classifiers: ClassifierService,
        private paramService: ParameterService,
        private route: ActivatedRoute
    ) { }

    tabs: { id: NavId, title: string }[] = [];
    data: IFinalPaperReview = <IFinalPaperReview>{};
    title: string = '...';
    mainFile: IFinalPaperFile;
    auxFiles: IFinalPaperFile[] = [];
    activeTabId: number = 1;
    fileAccept: string = '';
    fileMaxSize: number = 0;
    gradeOptions: Classifier[] = [];
    recommendedGrade: Classifier;
    programme: string;
    supervisors: IFinalPaperUser[] = [];
    reviewers: IFinalPaperUser[] = [];
    error: string;

    editModel: IFinalPaperFeedbackEditModel;
    editFile: File;

    approvals: IFeedback[] = [];
    reviews: IFeedback[] = [];

    readonly decision = {
        visible: false,
        enabled: false,
        message: '',
        level: 'info'
    };

    dspaceCardUrl: string;
    dspaceCardVisible: boolean;
    notApprovedCantReview: boolean;
    notSubmittedCantApprove: boolean;

    emptyValue = '';

    get isEditing(): boolean {
        return this.approvals.some(t => t.isEditing) || this.reviews.some(t => t.isEditing);
    }

    private id: number;
    private isAdmin: boolean;
    private isResponsiblePerson: boolean;

    ngOnInit() {
        this.isAdmin = this.app.currentUser.rights.includes('FINAL_PAPER_REVIEW.ADMIN');
        this.id = +this.route.snapshot.params['id'];

        // load grades only if current user can review
        this.app.addLoading(this.classifiers.get('Grade')).subscribe(data => {
            const grades = data.filter(t => t.Code == (+t.Code).toString());
            grades.sort((a, b) => {
                return +b.Code - +a.Code;
            });
            this.gradeOptions = grades;
        });

        this.app.addLoading(this.paramService.getValues()).subscribe(values => {
            this.fileMaxSize = this.paramService.findValue(values, 'FinalPaperReviewMaxFileSize', t => +t, 0);
            this.fileAccept = this.paramService.findValue(values, 'FinalPaperReviewFileExtensions',
                t => '.' + t.split(',').join(',.'), '');
            this.dspaceCardUrl = this.paramService.findValue(values, 'DSpaceCardUrl');

            return true;
        });

        this.load();
    }

    getFinalPaperFileDownloadUrl(fileId: number): string {
        return this.service.getFileDownloadUrl(fileId, store.getPerson()?.Upn);
    }

    getFeedbackFileDownloadUrl(feedbackId: number): string {
        return this.service.getFeedbackFileDownloadUrl(feedbackId);
    }


    edit(feedback: IFeedback) {
        this.editModel = {
            Content: feedback.content,
            Type: feedback.type,
            // admin can save feedback for anyone
            AuthorId: this.isAdmin || this.isResponsiblePerson ? feedback.author.Id : null
        };

        this.editFile = feedback?.fileName ? createFileStub(feedback.fileName) : null;

        feedback.isEditing = true;
    }

    save() {
        this.editModel.RemoveFile = !this.editFile?.name;
        this.app.addLoading(this.service.saveFeedback(this.id, this.editModel, this.editFile?.size && this.editFile, store.getPerson()?.Upn)).subscribe(() => {
            this.app.notify(this.app.translate(this.editModel.Type == 'Approval' ? 'finalPaperReview_approvalSaved' : 'finalPaperReview_reviewSaved'));
            this.load();
        });
    }

    cancel(feedback: IFeedback) {
        feedback.isEditing = false;
    }

    approve() {
        this.app.confirm({
            text: this.app.translate('finalPaperReview_confirmApprove')
        }, result => {
            if (result) {
                this.app.addLoading(this.service.approve(this.id, store.getPerson()?.Upn)).subscribe(() => {
                    this.app.notify(this.app.translate('finalPaperReview_approved'));
                    this.load();
                });
            }
        });
    }

    reject() {
        this.app.confirm({
            text: this.app.translate('finalPaperReview_confirmReject')
        }, result => {
            if (result) {
                this.app.addLoading(this.service.reject(this.id, store.getPerson()?.Upn)).subscribe(() => {
                    this.app.notify(this.app.translate('finalPaperReview_rejected'));
                    this.load();
                });
            }
        });
    }

    getUserDisplayString(user: IFinalPaperUser): string {
        let str = `${user.Name} ${user.Surname}`;

        if (user.Position) str += ', ' + user.Position;
        if (user.Organization) str += ', ' + user.Organization;

        return str;
    }

    openReport() {
        this.app.addLoading(this.service.getReportUrl(this.data.Id, store.getPerson()?.Upn)).subscribe(url => {
            window.open(url, '_blank');
        });
    }

    openDspaceCard() {
        window.open(this.dspaceCardUrl, '_blank');
    }

    private load() {
        this.app.addLoading(this.service.getById(this.id, store.getPerson()?.Upn)).subscribe(data => {
            this.data = data;

            this.isResponsiblePerson = data.CurrentUser.IsResponsiblePerson;

            // set only after getting data to be sure that something is empty not because the data is not loaded yet
            this.emptyValue = '-';

            this.title = this.app.translate('finalPaperReview_itemTitleFormat').replace('{{title}}', data.Title);
            this.mainFile = data.Attachments.find(t => t.Type == 'FinalPaper');
            this.auxFiles = data.Attachments.filter(t => t.Type != 'FinalPaper');
            this.programme = data?.StudyProgram + (data.StudyProgramCode ? ', ' + data.StudyProgramCode : '');
            this.supervisors = data.Users.filter(t => !t.IsObsolete && (t.Role == 'Supervisor' || t.Role == 'SecondSupervisor' || t.Role == 'Consultant'));
            this.reviewers = data.Users.filter(t => !t.IsObsolete && t.Role == 'Reviewer');
            this.notApprovedCantReview = data.CurrentUser.IsReviewer && (data.Status == FinalPaperStatus.Draft || data.Status == FinalPaperStatus.Submitted);
            this.notSubmittedCantApprove = (data.CurrentUser.IsSupervisor || data.CurrentUser.IsConsultant || data.CurrentUser.IsSecondSupervisor) && data.Status == FinalPaperStatus.Draft;

            this.dspaceCardVisible = data.SentToDspace && data.Status == FinalPaperStatus.UploadedDspace;
            if (this.dspaceCardVisible) this.dspaceCardUrl = this.dspaceCardUrl.replace('{DSpaceHandle}', data.DspaceHandle);

            const currentAuthor = data.Users.find(t => t.Id == data.CurrentUser.Id);

            const isImpersonatingAndNotAdmin = store.getPerson() && !this.app.currentUser.hasRight('FINAL_PAPER_REVIEW.SET_EMPLOYEE_ADMIN');

            this.reviews = [];
            this.approvals = [];
            this.tabs = [];

            this.tabs.push({ id: 'finalPaper', title: 'finalPaperReview_finalPaper' });

            if (this.isAdmin
                || data.CurrentUser.IsSupervisor
                || data.CurrentUser.IsSecondSupervisor
                || data.CurrentUser.IsConsultant
                || data.CurrentUser.IsCommissioner
                || data.CurrentUser.IsContactPerson
                || data.CurrentUser.IsResponsiblePerson
                || data.CurrentUser.IsReviewer
            ) {
                this.tabs.push({ id: 'approval', title: 'finalPaperReview_approval' });

                let decisionVisible = false;
                let decisionEnabled = false;

                this.supervisors.forEach(t => {
                    const feed = data.Feedbacks.find(n => n.AuthorId == t.Id && !n.IsObsolete && n.Type == 'Approval');
                    const my = this.isAdmin || t.Id == currentAuthor?.Id || this.isResponsiblePerson;

                    if (t.Role == 'Supervisor') {
                        if (my) {
                            decisionVisible = true;
                        }

                        switch (data.Decision) {
                            case 'Approve':
                                this.decision.message = this.app.translate('finalPaperReview_isApproved');
                                this.decision.level = 'success';
                                break;
                            case 'Reject':
                                this.decision.message = this.app.translate('finalPaperReview_isRejected');
                                this.decision.level = 'danger';
                                break;
                            default:
                                this.decision.message = feed ? null : this.app.translate('finalPaperReview_decisionFeedbackRequired');
                                this.decision.level = feed ? '' : 'warning';
                                decisionEnabled = !!feed;
                                break;
                        }
                    }
                    
                    this.approvals.push({
                        author: t,
                        type: 'Approval',
                        my: my,
                        content: feed?.Content,
                        fileName: feed?.FileName,
                        id: feed?.Id,
                        isReadOnly: isImpersonatingAndNotAdmin ? true : feed?.IsReadOnly || !my,
                        isObsolete: feed?.IsObsolete
                    });
                });

                this.decision.visible = decisionVisible;
                this.decision.enabled = decisionEnabled;

                if (isImpersonatingAndNotAdmin)
                    this.decision.enabled = false;
            }

            if (data.Decision == 'Approve' || data.Decision == 'Reject') {
                const isPowerUser = this.isAdmin
                    || data.CurrentUser.IsSupervisor
                    || data.CurrentUser.IsSecondSupervisor
                    || data.CurrentUser.IsConsultant
                    || data.CurrentUser.IsCommissioner
                    || data.CurrentUser.IsResponsiblePerson
                    || data.CurrentUser.IsContactPerson;

                if (isPowerUser || data.CurrentUser.IsReviewer) {
                    this.tabs.push({ id: 'review', title: 'finalPaperReview_review' });
                
                    this.reviewers.forEach(t => {
                        if (isPowerUser || currentAuthor?.Id == t.Id) {
                            const feed = data.Feedbacks.find(n => n.AuthorId == t.Id && !n.IsObsolete && n.Type == 'Review');
                            const my = this.isAdmin || t.Id == currentAuthor?.Id || this.isResponsiblePerson;

                            this.reviews.push({
                                author: t,
                                type: 'Review',
                                my: my,
                                content: feed?.Content,
                                fileName: feed?.FileName,
                                grade: feed?.RecommendedGradeString,
                                id: feed?.Id,
                                isReadOnly: isImpersonatingAndNotAdmin ? true : feed?.IsReadOnly || !my,
                                isObsolete: feed?.IsObsolete
                            });
                        }
                    });
                }
            }

            data.Feedbacks.forEach(t => {
                if (t.IsObsolete) {
                    let author = data.Users.find(n => n.Id == t.AuthorId);
                    if (!author) author = <any>{};

                    switch (t.Type) {
                        case 'Approval':
                            this.approvals.push({
                                author: author,
                                type: 'Approval',
                                my: this.isAdmin,
                                content: t.Content,
                                fileName: t.FileName,
                                id: t.Id,
                                isReadOnly: true,
                                isObsolete: true
                            });
                            break;

                        case 'Review':
                            this.reviews.push({
                                author: author,
                                type: 'Review',
                                my: this.isAdmin,
                                content: t.Content,
                                fileName: t.FileName,
                                id: t.Id,
                                isReadOnly: true,
                                isObsolete: true
                            });
                            break;
                    }
                }
            });

            this.error = null;
        }, err => {
            this.error = this.app.getHttpResponseError(err);
        });
    }
}
