import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PersonMainDataItem, SkillDetailItem, StudentEventItem, StudentEventSkill } from '../../models/Said_events';

import { AppService } from '../../services/app.service';
import { PersonService } from '../../services/person.service';
import { ClassifierService } from '../../services/classifier.service';

import { Utils } from '../../core/Utils';
import { Helpers } from './helpers';
import { Said_eventsService } from '../../services/said-events.service';
import { EventsDialogComponent } from './events-dialog.component';
import { IPersonSearchResultItem } from '../../models/Person';
import { MessageService } from '../../services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-said-events',
    templateUrl: './said-events.component.html',
    styleUrls: ['./said-events.component.css']
})
export class SaidEventsComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public app: AppService,
        public service: Said_eventsService,
        private messagesService: MessageService,
        private studentService: PersonService,
        private modal: NgbModal,
        protected classifiers: ClassifierService
    ) {
        this.getScreenSize();
    }

    readonly language = (this.app.currentLanguage || 'lv').toLowerCase();

    helpers = Helpers;
    title: string = null;
    //pointsInfoHtml: string = null;
    formInfoHtml: string = null;
    formExplanationsHtml: string = null;
    rights = { view: false };
    isDenied: boolean;
    unavailableText: string;
    isUnavailable: boolean;

    studentPickerOpened: boolean;
    canPickStudent: boolean;
    student: IPersonSearchResultItem;
    studentEmail: string;
    studentUpn: string;
    studentFullName: string;
    isLoaded: boolean;
    noSelectionNeeded: boolean;
    isSetKPInfoTextIsOn: boolean;
    //NoAvailMessage: string;

    personDataList: PersonMainDataItem[];
    selectedStudentEvents: StudentEventItem[] = [];

    selectedStudent_guid: string;
    selectedPlanning_period_GUID: string;

    showPlanningPeriodDropDown: boolean = false;
    getPersonMainDataHadResult: boolean = false;

    filterValue: PersonMainDataItem = null;

    showSkillInfoTable: string[] = [];

    screenHeight: number;
    screenWidth: number;
    isMobile: boolean;

    get isAdmin(): boolean {
        return this.app.currentUser?.isAdmin;
    }

    get isImpersonator(): boolean {
        return this.app.currentUser?.isImpersonator;
    }

    get pointsInfoHtml(): string {
        const msg = this.app.translate('said_events_skills_info');

        if (msg && this.personDataList?.length) {
            const currentPersonData = this.personDataList.find(p => p.Student_guid == this.selectedStudent_guid
                && p.Planning_period_GUID == this.selectedPlanning_period_GUID);

                const selCount = new Set();

                this.selectedStudentEvents.filter(s => s.IsSupervised).forEach(function (event) {
                    event.Skills.forEach(function (skill) {
                        selCount.add(skill.Id);
                    })
                });

                const val = [
                    currentPersonData?.Planned_Skills != null ? currentPersonData.Planned_Skills : 0,
                    selCount.size
                ];

                return Utils.formatString(msg, val);
        }

        return null;
    }

    private skillDetailCache: { [key: string]: SkillDetailItem[] } = {};

    //private setPointsInfoHtml() {
    //    const msg = this.app.translate('said_events_skills_info');

    //    if (msg) {
    //        this.app.addLoading(this.service.getPersonMainData(this.studentEmail)).subscribe(persons => {
    //            const currentPersonData = persons.find(p => p.Student_guid == this.selectedStudent_guid && p.Planning_period_GUID == this.selectedPlanning_period_GUID);

    //            const selCount = new Set();

    //            this.selectedStudentEvents.filter(s => s.IsSupervised).forEach(function (event) {
    //                event.Skills.forEach(function (skill) {
    //                    selCount.add(skill.Id);
    //                })
    //            });

    //                const val = [
    //                    currentPersonData.Planned_Skills != null ? currentPersonData.Planned_Skills : 0,
    //                    selCount.size
    //            ];

    //                this.pointsInfoHtml = Utils.formatString(msg, val);
    //        });
    //    } else {
    //        this.pointsInfoHtml = null;
    //    }
    //}

    ngOnInit() {
        const isEn = this.app.currentLanguage === 'en';

        const user = this.app.currentUser;
        const params = this.route.snapshot.params;
        const query = this.route.snapshot.queryParams;

        if (user) {
            this.rights.view = user.rights.indexOf('SAID_EVENTS.VIEW') > -1 || user.rights.indexOf('SAID_EVENTS.ADMIN') > -1;
            this.canPickStudent = user.rights.indexOf('SAID_EVENTS.ADMIN') > -1;

            const id = query['id'];

            if (id && !this.canPickStudent) {
                this.isDenied = true;
                return;
            }

            this.selectedStudent_guid = params['Student_guid'];
            this.selectedPlanning_period_GUID = params['Planning_period_GUID'];

            if (this.rights.view && !this.canPickStudent) {
                this.studentEmail = user.email;
                this.studentFullName = decodeURIComponent(`${user.firstName} ${user.lastName}`);
            } else {
                this.studentEmail = params['studentEmail'];
                this.studentFullName = decodeURIComponent(params['studentFullName']);
            }

            if (this.studentEmail) {
                if (this.studentEmail.indexOf('@') > -1)
                    this.studentUpn = this.studentEmail.substring(0, this.studentEmail.indexOf('@'));
                this.init(false);
            } else if (this.canPickStudent) {
                if (id) {
                    this.app.addLoading(this.studentService.findStudents('student_id', id)).subscribe(data => {
                        if (data.length) {
                            this.student = data[0];
                            this.pickStudent(this.student);
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                            this.toggleStudentPicker();
                        }
                    });
                } else {
                    this.toggleStudentPicker();
                }
            }
        }

        this.app.addLoading(this.messagesService.getByCodes([
            'SAID_EVENTS_EXPLANATIONS',
            'SAID_EVENTS_SUPERVISED_EXPLANATIONS',
            'SAID_EVENTS_NOAVAIL_MESSAGE'
        ])).subscribe(data => {
            const expl = data.find(t => t.Code === 'SAID_EVENTS_EXPLANATIONS');
            this.formExplanationsHtml = expl ? isEn ? expl.TextEN : expl.TextLV : undefined;

            const supevrExpl = data.find(t => t.Code === 'SAID_EVENTS_SUPERVISED_EXPLANATIONS');
            this.formInfoHtml = supevrExpl ? isEn ? supevrExpl.TextEN : supevrExpl.TextLV : undefined;

            const noAvailMsg = data.find(t => t.Code === 'SAID_EVENTS_NOAVAIL_MESSAGE');
            this.unavailableText = noAvailMsg ? isEn ? noAvailMsg.TextEN : noAvailMsg.TextLV : undefined;
        });
    }

    toggleStudentPicker(refreshUrl?: boolean) {
        if (refreshUrl)
            this.reloadStudent('/' + this.app.currentLanguage + '/said-events/');
        this.studentPickerOpened = !this.studentPickerOpened;
        this.isSetKPInfoTextIsOn = false;
        this.noSelectionNeeded = false;
        this.isUnavailable = false;
    }

    pickStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.studentEmail = student.Email;
        this.studentFullName = student.FirstName + " " + student.LastName;
        if (this.studentEmail && this.studentEmail.indexOf('@') > -1)
            this.studentUpn = this.studentEmail.substring(0, this.studentEmail.indexOf('@'));
        this.selectedStudent_guid = null;
        this.selectedPlanning_period_GUID = null;
        this.init(true);
    }

    private init(redirect: boolean) {
        this.isLoaded = false;

        this.app.addLoading(this.service.getPersonMainData(this.studentEmail)).subscribe(persons => {
            this.personDataList = persons;

            if (this.personDataList.length > 0) {
                this.getPersonMainDataHadResult = true;
                if (this.selectedStudent_guid && this.selectedPlanning_period_GUID) {
                    this.loadEvents(this.selectedStudent_guid, this.selectedPlanning_period_GUID);
                    this.filterValue = this.personDataList.find(p => p.Student_guid == this.selectedStudent_guid && p.Planning_period_GUID == this.selectedPlanning_period_GUID);
                }
                else {
                    this.loadEvents(this.personDataList[0].Student_guid, this.personDataList[0].Planning_period_GUID);
                    this.filterValue = this.personDataList[0];
                }
            } else {
                this.isLoaded = true;
                //this.pointsInfoHtml = null;
                this.formInfoHtml = null;
                this.getPersonMainDataHadResult = false;

                this.isUnavailable = true;
            }

            if (redirect) {
                this.reloadStudent('/' + this.app.currentLanguage + `/said-events/${this.selectedStudent_guid}/${this.selectedPlanning_period_GUID}/${this.studentEmail}/${decodeURIComponent(this.studentFullName)}`);
            }
        }, err => {
            this.isUnavailable = true;
        });
    }

    removeEvent(eventId: number) {
        const ctx = this;
        let currentPersonData = this.personDataList.find(p => p.Student_guid == this.selectedStudent_guid && p.Planning_period_GUID == this.selectedPlanning_period_GUID);
        let studentId = currentPersonData.Student_guid;
        let lang = "";

        this.app.addLoading(this.service.deleteEventApplication({ studentId, eventId, lang })).subscribe(data => {
            if (!data) {
                ctx.init(false);
                let currentEvent = ctx.selectedStudentEvents.find(t => t.EventId === eventId);
                currentEvent.Error = null;
                ctx.reloadComponent("anch_" + eventId);
            }
            else {
                ctx.selectedStudentEvents.find(t => t.EventId === eventId).Error = data;
            }
        });
    }


    addEvent() {
        if (this.canPickStudent)
            this.router.navigate([this.app.localizeRoute(`/said_events_add/${this.selectedStudent_guid}/${this.selectedPlanning_period_GUID}/${this.studentEmail}/${decodeURIComponent(this.studentFullName)}`)]);
        else {
            this.router.navigate([this.app.localizeRoute(`/said_events_add/${this.selectedStudent_guid}/${this.selectedPlanning_period_GUID}`)]);
        }
    }

    navigateToFilterSupervised() {
        if (this.canPickStudent) {
            let link = this.app.localizeRoute(`/said_events_add/${this.selectedStudent_guid}/${this.selectedPlanning_period_GUID}/${this.studentEmail}/${decodeURIComponent(this.studentFullName)}/true`);
            this.router.navigate([link]);
        }
        else {
            this.router.navigate([this.app.localizeRoute(`/said_events_add/${this.selectedStudent_guid}/${this.selectedPlanning_period_GUID}/true`)]);
        }
    }

    loadEvents(Student_guid: string, Planning_period_GUID: string) {
        this.isLoaded = false;
        this.selectedStudent_guid = Student_guid;
        this.selectedPlanning_period_GUID = Planning_period_GUID;

        this.app.addLoading(this.service.getStudentEvents(this.selectedStudent_guid))
            .subscribe(studentEvents => {
                this.selectedStudentEvents = studentEvents.filter(t => t.IsSelected).sort((f, s) => <any>new Date(f.EventTimeFrom) - <any>new Date(s.EventTimeFrom));
                this.isLoaded = true;
                //this.setPointsInfoHtml();
            });
    }

    openSkill(skill: StudentEventSkill) {
        const show = (details: SkillDetailItem[]) => {
            const ref = this.modal.open(EventsDialogComponent, {
                size: 'lg'
            });
            ref.componentInstance.data = { details };
        };

        let cached = this.skillDetailCache[skill.Id];

        if (cached && cached.length) {
            show(this.skillDetailCache[skill.Id]);
        } else {
            this.app.addLoading(this.service.getSkillDetails(skill.Id)).subscribe(details => {
                this.skillDetailCache[skill.Id] = details;
                show(details);
            });
        }
    }

    reloadComponent(anchor: string) {

        let currentUrl = this.router.url;// + "#" + anchor;
        let anchPos = currentUrl.indexOf("#") > 0 ? currentUrl.indexOf("#") : currentUrl.length;
        let clearUrl = currentUrl.substring(0, anchPos);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([decodeURIComponent(clearUrl)], { fragment: anchor });
    }

    reloadStudent(url: string) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url]);
    }

    getEventTypeName(eventType: number) {
        let etEn = ["", "Remote, under supervision", "Independent acquisition of skills", "Not applicable to the study process"];
        let etLv = ["", "Attālināti, docētāja uzraudzībā", "Patstāvīga prasmju apguve", "Nav attiecināms uz studiju pocesu"];
        return this.language == "en" ? etEn[eventType] : etLv[eventType];
    }

    toggleSkillInfo(siTable: string) {
        let idx = this.showSkillInfoTable.indexOf(siTable);
        if (idx > -1) {
            this.showSkillInfoTable.splice(idx, 1);
        } else {
            this, this.showSkillInfoTable.push(siTable);
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        this.isMobile = this.screenWidth <= 800;
    }

}
