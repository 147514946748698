import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AppService } from '../services/app.service';

@Injectable({ providedIn: "root" })
export class UserRoleGuard implements CanActivate {
    constructor(private app: AppService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = this.app.currentUser;

        if (!user)
            return false;

        const roles = (<Array<string>>(route.data['roles'] || [])).map(t => t.toLowerCase());
        const rights = (<Array<string>>(route.data['rights'] || [])).map(t => t.toLowerCase());

        const userRoles = user.roles.map(r => r.toLowerCase());
        const userRights = user.rights.map(r => r.toLowerCase());

        let hasAccess = (!roles.length || userRoles.some(r => roles.indexOf(r) !== -1))
            && (!rights.length || userRights.some(r => rights.indexOf(r) !== -1));

        if (!hasAccess) {
            const wildcardRights = rights.filter(t => t.endsWith('*')).map(t => t.slice(0, -1));          
            hasAccess = wildcardRights.some(w => userRights.some(r => r.startsWith(w)));
        }

        if (!hasAccess)
            this.app.alert.error(this.app.translate('eServiceNotAvailable'));

        return hasAccess;
    }
}
