import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { ISurveyQuestion, SurveyAnswerEvaluationType, YesValue, NoValue, NoOpinionValue } from '../../../models/Survey';
import { SurveyUtils } from '../SurveyUtils';

@Component({
    selector: 'app-survey-question-grid',
    templateUrl: './survey-question-grid.component.html',
    styleUrls: ['../survey.component.scss']
})
export class SurveyQuestionGridComponent implements OnInit {
    constructor(
        private app: AppService
    ) { }

    @Input() questions: ISurveyQuestion[] = [];
    @Input() model: Record<string, any> = {};
    @Input() keyFn: (question: ISurveyQuestion) => string;
    @Input() validate: boolean;

    readonly forms: Record<string, {
        options: { text: string, value: any }[],
        isMulti: boolean
    }> = {};

    readonly util = SurveyUtils;

    readonly answerTypes = SurveyAnswerEvaluationType;
    readonly valueYes = YesValue;
    readonly valueNo = NoValue;
    readonly valueNoOpinion = NoOpinionValue;

    ngOnInit() {
        this.questions.forEach(t => {
            this.forms[t.ID] = {
                options: SurveyUtils.buildAnswerOptions(t, key => this.app.translate(key)),
                isMulti: t.AnswerEvaluationType == SurveyAnswerEvaluationType.MultipleFromScope
            };
        });
    }
}
