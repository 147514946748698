import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { IPlanSummaryEventSession, IPlanSummaryResident } from '../../../models/PlanSummary';

import { AppService } from '../../../services/app.service';
import { PlanSummaryService } from '../../../services/plan-summary.service';

let currentSession: { key: string, data: IPlanSummaryEventSession };

@Component({
    selector: 'app-plan-summary-events',
    templateUrl: './plan-summary-events.component.html'
})
export class PlanSummaryEventsComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PlanSummaryService
    ) { }

    @Input() resident: IPlanSummaryResident;

    ready: boolean;
    sessionUrl: string;

    ngOnInit() {
        this.initSession().pipe(finalize(() => {
            this.ready = true;
        })).subscribe(data => {
            this.sessionUrl = data?.Url;
        }, err => { });
    }

    private initSession() {
        const email = this.resident.Email;
        const programId = this.resident.SpecialityId;
        const key = `${email}/${programId}`;

        if (currentSession?.key == key) {
            return of(currentSession.data);
        } else {
            currentSession = {
                key,
                data: null
            };

            return this.app.addLoading(this.service.initEventSession({
                programId,
                studentEmail: email
            })).pipe(tap(data => {
                currentSession.data = data;
            }));
        }
    }
}
