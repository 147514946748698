<ng-container *ngIf="group && questions.length">
    <div *ngIf="group.ComposeAllInOne" class="block-em">
        <app-alert level="info" *ngIf="group.QuestionsPrefixText">
            {{group.QuestionsPrefixText}}
        </app-alert>

        <div class="text-sub mb-3" *ngIf="group.Description">
            {{group.Description}}
        </div>

        <ng-container *ngIf="firstQuestion.QuestionEvaluationType == questionTypes.ObjectBasedOnAnswer
                      || firstQuestion.QuestionEvaluationType == questionTypes.WithoutObject">
            <ng-container *ngIf="isGrid; else nonGrid">
                <app-survey-question-grid [questions]="gridQuestions"
                                          [keyFn]="getQuestionKeyBuilder()"
                                          [model]="model"
                                          [validate]="validate"></app-survey-question-grid>

                <app-survey-question-form *ngFor="let q of textQuestions"
                                          [question]="q"
                                          [key]="util.buildQuestionKey(q.ID)"
                                          [model]="model"
                                          [validate]="validate"></app-survey-question-form>
            </ng-container>

            <ng-template #nonGrid>
                <app-survey-question-form *ngFor="let q of questions"
                                          [question]="q"
                                          [key]="util.buildQuestionKey(q.ID)"
                                          [model]="model"
                                          [validate]="validate"></app-survey-question-form>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="firstQuestion.QuestionEvaluationType == questionTypes.ObjectList">
            <table class="table" *ngIf="comboQuestions.length">
                <thead>
                    <tr>
                        <th></th>
                        <!--<th *ngIf="isForcedInObjectList">@Resources.Resource.FillLecturer</th>-->
                        <th *ngFo="let q of questions">
                            {{q.Title}}
                            <span class="required-mark" *ngIf="q.Mandatory"></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let n of firstQuestion.Objects">
                        <td>{{n.Title}}</td>
                        <!--<td *ngIf="isForcedInObjectList">
                            <input type="checkbox" class="uncheck_lector" />
                        </td>-->
                        <td *ngFor="let q of questions">
                            <app-survey-question-form [question]="q"
                                                      [key]="util.buildQuestionKey(q.ID, getQuestionObjectByTitle(q, n.Title)?.ID || noObjectId)"
                                                      [model]="model"
                                                      [validate]="validate"></app-survey-question-form>
                        </td>
                    </tr>
                </tbody>
            </table>

            <ng-container *ngIf="!comboQuestions.length">
                <div *ngFor="let n of firstQuestion.Objects">
                    <h5>
                        {{n.Title}}
                        <!--<span *ngIf="isForcedInObjectList">
                            @Resources.Resource.FillLecturer: <input type="checkbox" class="uncheck_lector" />
                        </span>-->
                    </h5>
                    <div>
                        <app-survey-question-grid [questions]="gridQuestions"
                                                  [keyFn]="getQuestionKeyBuilder(n)"
                                                  [model]="model"
                                                  [validate]="validate"></app-survey-question-grid>

                        <app-survey-question-form *ngFor="let q of textQuestions"
                                                  [question]="q"
                                                  [key]="util.buildQuestionKey(q.ID, getQuestionObjectByTitle(q, n.Title)?.ID || noObjectId)"
                                                  [model]="model"
                                                  [validate]="validate"></app-survey-question-form>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="!group.ComposeAllInOne">
        <app-survey-question *ngFor="let q of questions"
                             [question]="q"
                             [model]="model"
                             [validate]="validate"
                             [title]="group.QuestionsPrefixText + ' ' + q.Title"></app-survey-question>
    </ng-container>
</ng-container>
