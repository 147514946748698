import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../../services/app.service';
import { BC_CoursesService } from '../../services/bc_courses.service';

import { PersonMainDataItem, StudentCoursesItem, CourseEvent } from '../../models/BC_courses';
import { EventsDialogComponent } from './events-dialog.component';

import { Utils } from '../../core/Utils';
import { Helpers } from './helpers';
import { MessageService } from '../../services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bc_courses-add',
    templateUrl: './bc_courses-add.component.html',
    styleUrls: ['./bc_courses-add.component.scss']
})
export class BC_CoursesAddComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: BC_CoursesService,
        private messagesService: MessageService,
        private route: ActivatedRoute,
        private router: Router,
        private modal: NgbModal
    ) { }

    helpers = Helpers;
    isCourseAdmin: boolean = false;
    Student_guid: string;
    Planning_period_GUID: string;
    studentEmail: string;
    studentFullName: string;

    currentPersonData: PersonMainDataItem;
    pointsInfoHtml: string = null;
    registerInfoHtml: string = null;
    courses: StudentCoursesItem[] = [];
    isLoaded: boolean = false;

    currentFilter = {
        All: null,
        Lang: null,
        Block: null,
        Address: null,
        CourseManager: null,
        Examination: null,
        onlyAdded: false,
        onlyAllowed: false
    };

    extendedFilter: boolean = false;

    FilterLang: string[] = [];
    FilterBlock: string[] = [];
    FilterExamination: string[] = [];

    canEnrollCourses: { [key: number]: StudentCoursesItem } = {};
    alreadyAddedCourses: { [key: number]: StudentCoursesItem } = {};

    backUrl: string;
    currentUrl: string;

    get isAdmin(): boolean {
        return this.app.currentUser?.isAdmin;
    }

    private courseEventCache: { [key: string]: CourseEvent[] } = {};

    pointTooMuch(id: number) {
        let pointTooMuch = false;
        let course = this.courses[id - 1];
        if (course && this.currentPersonData) {
            if (course.Block == 'B')
                pointTooMuch = this.currentPersonData.Creditpoints_B + course.Credit_points > this.currentPersonData.Planned_credit_points_B;
            else
                pointTooMuch = this.currentPersonData.Creditpoints_C + course.Credit_points > this.currentPersonData.Planned_credit_points_C;
        }
        return pointTooMuch;
    }

    setPointsInfoHtml() {
        this.app.addLoading(this.service.getPersonMainData(this.studentEmail)).subscribe(persons => {
            this.currentPersonData = persons.find(p => p.Planning_period_GUID == this.Planning_period_GUID && p.Student_guid == this.Student_guid);

            const msg = this.app.translate('BC_courses_points_info');

            if (msg) {
                let val = [
                    this.currentPersonData?.Planned_credit_points_B != null ? this.currentPersonData.Planned_credit_points_B : 0,
                    this.currentPersonData?.Creditpoints_B != null ? this.currentPersonData.Creditpoints_B : 0,
                    this.currentPersonData?.Planned_credit_points_C != null ? this.currentPersonData.Planned_credit_points_C : 0,
                    this.currentPersonData?.Creditpoints_C != null ? this.currentPersonData.Creditpoints_C : 0
                ];
                this.pointsInfoHtml = Utils.formatString(msg, val);
            }
            else
                this.pointsInfoHtml = null;
        });
    }

    setRegisterInfoHtml() {
        const isEn = this.app.currentLanguage === 'en';

        this.app.addLoading(this.messagesService.getByCode('BC_COURSES_REGISTER_INFO')).subscribe(data => {
            this.registerInfoHtml = data ? isEn ? data.TextEN : data.TextLV : null;
        });
    }

    ngOnInit() {
        this.isLoaded = false;
        this.route.params.subscribe(params => {
            this.Student_guid = params['Student_guid'];
            this.Planning_period_GUID = params['Planning_period_GUID'];
            this.studentEmail = params['studentEmail'];
            this.studentFullName = params['studentFullName'];

            this.currentUrl = (this.isAdmin
                ? ['/bc_courses_add', this.Student_guid, this.Planning_period_GUID, this.studentEmail, this.studentFullName]
                : ['/bc_courses_add', this.Student_guid, this.Planning_period_GUID]).join('/');

            this.backUrl = (this.isAdmin
                ? ['/bc_courses', this.Student_guid, this.Planning_period_GUID, this.studentEmail, this.studentFullName]
                : ['/bc_courses', this.Student_guid, this.Planning_period_GUID]).join('/');

            const user = this.app.currentUser;
            this.isCourseAdmin = user.hasRight('BC_COURSES.ADMIN');

            if (!this.studentEmail || !this.studentFullName) {
                if (this.isCourseAdmin) {
                    this.router.navigate([this.app.localizeRoute(`/bc_courses`)]);
                } else {
                    this.studentEmail = user.email;
                    this.studentFullName = user.firstName + " " + user.lastName;

                    this.setPointsInfoHtml();
                    this.setRegisterInfoHtml();
                }
            } else {
                this.setPointsInfoHtml();
                this.setRegisterInfoHtml();
            }

            if (this.Student_guid && this.Planning_period_GUID)
                this.load();
        });
    }

    load() {
        let groupedInfo: StudentCoursesItem[] = [];
        let alreadyAdded: StudentCoursesItem = null;
        let fullyBooked: boolean[] = [];

        this.app.addLoading(this.service.getStudentCourses(this.Student_guid, this.Planning_period_GUID, true))
            .subscribe(studentCourses => {
                if (studentCourses.length > 0) {
                    let item = studentCourses[0];
                    groupedInfo.push(item);
                    let nr = item.Nr;

                    fullyBooked.push(item.Used_places >= item.Max_students);
                    if (item.Application_ID)
                        alreadyAdded = item;

                    if (item.Examination_type)
                        this.FilterExamination.push(item.Examination_type);
                    if (item.Block)
                        this.FilterBlock.push(item.Block);
                    if (item.Subject_semester_lang)
                        this.FilterLang.push(item.Subject_semester_lang);

                    if (studentCourses.length == 1) {
                        let couseItem = studentCourses[0];
                        this.pushToCourses(couseItem, groupedInfo, fullyBooked, nr, alreadyAdded);
                    }
                    else {
                        for (let i = 1; i < studentCourses.length; i++) {
                            item = studentCourses[i];

                            if (item.Nr == nr) {
                                groupedInfo.push(item);
                                fullyBooked.push(item.Used_places >= item.Max_students);
                            }
                            else {
                                let couseItem = studentCourses[i - 1];
                                this.pushToCourses(couseItem, groupedInfo, fullyBooked, nr, alreadyAdded);

                                groupedInfo = [item];
                                nr = item.Nr;
                                fullyBooked = [item.Used_places >= item.Max_students];
                                alreadyAdded = null;
                            }

                            if (item.Application_ID)
                                alreadyAdded = item;

                            if (item.Examination_type && this.FilterExamination.findIndex(e => e == item.Examination_type) == -1)
                                this.FilterExamination.push(item.Examination_type);
                            if (item.Block && this.FilterBlock.findIndex(b => b == item.Block) == -1)
                                this.FilterBlock.push(item.Block);
                            if (item.Subject_semester_lang && this.FilterLang.findIndex(l => l == item.Subject_semester_lang) == -1)
                                this.FilterLang.push(item.Subject_semester_lang);

                            if (i == studentCourses.length - 1) {
                                this.pushToCourses(item, groupedInfo, fullyBooked, nr, alreadyAdded);
                            }
                        }
                    }
                }

                this.isLoaded = true;
            });
    }

    openEvents(course: StudentCoursesItem, groupedInfo: StudentCoursesItem, type: string) {
        const show = (events: CourseEvent[]) => {
            const ref = this.modal.open(EventsDialogComponent);

            ref.componentInstance.data = {
                events: events,
                info: course.Info_text,
                type: type
            };
        };

        let cached = this.courseEventCache[groupedInfo.Group_id];

        if (cached && cached.length) {
            show(this.courseEventCache[groupedInfo.Group_id]);
        } else {
            if (groupedInfo.detailed_planning) {
                this.app.addLoading(this.service.getCourseEvents(groupedInfo.Group_id)).subscribe(events => {
                    this.courseEventCache[groupedInfo.Group_id] = events;
                    show(events);
                });
            } else {
                show([<CourseEvent>{
                    Address: groupedInfo.Travel_dest_name,
                    DateTime: this.getCourseDateTimeString(course, groupedInfo),
                }]);
            }
        }
    }

    onEnrollCourse(enroll: boolean, course: StudentCoursesItem, info: StudentCoursesItem) {
        if (enroll) {
            this.canEnrollCourses[course.Nr] = info;
        } else {
            this.canEnrollCourses[course.Nr] = undefined;
        }
    }

    isCourseEnrolled(course: StudentCoursesItem, info: StudentCoursesItem) {
        return this.canEnrollCourses[course.Nr] == info;
    }

    public getCourseDateTimeString(course: StudentCoursesItem, groupedInfo: StudentCoursesItem): string {
        if (course.detailed_planning)
            return this.app.translate('BC_courses_calendar');

        if (groupedInfo.Group_day && groupedInfo.Time_from && groupedInfo.Time_to)
            return `${groupedInfo.Group_day}, ${groupedInfo.Time_from} - ${groupedInfo.Time_to}`;

        if (groupedInfo.Group_day && !groupedInfo.Time_from && !groupedInfo.Time_to)
            return groupedInfo.Group_day;

        if (!groupedInfo.Group_day && !groupedInfo.Time_from && !groupedInfo.Time_to)
            return this.app.translate('BC_courses_time_info');

        return '';
    }

    private pushToCourses(courseItem: StudentCoursesItem, groupedInfo: StudentCoursesItem[], fullyBooked: boolean[], nr: number, alreadyAdded: StudentCoursesItem) {
        courseItem.groupedInfo = groupedInfo;
        courseItem.fullyBooked = fullyBooked.findIndex(b => b == false) >= 0 ? false : true;
        this.courses.push(courseItem);

        this.canEnrollCourses[nr] = groupedInfo.length == 1 ? groupedInfo[0] : alreadyAdded != null ? alreadyAdded : undefined;
        this.alreadyAddedCourses[nr] = alreadyAdded != null ? alreadyAdded : undefined;
    }

    get filteredCourses(): StudentCoursesItem[] {
        let list = this.courses;

        let all = (this.currentFilter.All || '').toLowerCase();
        let lang = (this.currentFilter.Lang || '').toLowerCase();
        let block = (this.currentFilter.Block || '').toLowerCase();
        let address = (this.currentFilter.Address || '').toLowerCase();;
        let courseManager = (this.currentFilter.CourseManager || '').toLowerCase();
        let examination = (this.currentFilter.Examination || '').toLowerCase();

        if (this.currentFilter.onlyAdded) {
            list = list.filter(t => this.alreadyAddedCourses[t.Nr]);
        }
        if (this.currentFilter.onlyAllowed) {
            list = list.filter(t => !t.fullyBooked && t.IsAvailable);
        }
        if (all) {
            list = list.filter(t =>
                (t.Subject_semester_lang || '').toLowerCase().indexOf(all) > -1 ||
                (t.Block || '').toLowerCase().indexOf(all) > -1 ||
                (t.groupedInfo.findIndex(g => (g.Travel_dest_name || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (t.Course_manager || '').toLowerCase().indexOf(all) > -1 ||
                (t.groupedInfo.findIndex(g => (g.Group_day || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (t.groupedInfo.findIndex(g => (g.Examination_type || '').toLowerCase().indexOf(all) > -1) > -1) ||
                (t.Subject_code || '').toLowerCase().indexOf(all) > -1 ||
                (t.Subject_name || '').toLowerCase().indexOf(all) > -1 ||
                (t.Department || '').toLowerCase().indexOf(all) > -1 ||
                (t.Credit_points ? t.Credit_points.toString() : '').toLowerCase().indexOf(all) > -1
            );
        }
        if (lang) {
            list = list.filter(t => (t.Subject_semester_lang || '').toLowerCase().indexOf(lang) > -1);
        }
        if (block) {
            list = list.filter(t => (t.Block || '').toLowerCase().indexOf(block) > -1);
        }
        if (address) {
            list = list.filter(t => t.groupedInfo.findIndex(g => (g.Travel_dest_name || '').toLowerCase().indexOf(address) > -1) > -1);
        }
        if (courseManager) {
            list = list.filter(t => (t.Course_manager || '').toLowerCase().indexOf(courseManager) > -1);
        }
        if (examination) {
            list = list.filter(t => (t.Examination_type || '').toLowerCase().indexOf(examination) > -1);
        }

        return list;
    }

    ClearFilter() {
        this.currentFilter = {
            All: null,
            Lang: null,
            Block: null,
            Address: null,
            CourseManager: null,
            Examination: null,
            onlyAdded: false,
            onlyAllowed: false
        };
    }

    canEnrollProgram(program: StudentCoursesItem): boolean {
        return program.groupedInfo.some(t => t.IsAvailable);
    }

    enroll(nr: number) {
        const course = this.courses.find(t => t.Nr === nr);

        let courseOption = this.canEnrollCourses[nr];

        if (!courseOption) {
            course.error = this.app.translate('BC_courses_mustSelect');
        } else {
            this.app.addLoading(this.service.addProgram({
                studentGuid: this.Student_guid,
                subjectSemester: courseOption.subsemID,
                applicantFullname: this.studentFullName,
                planningPeriodId: this.Planning_period_GUID,
                planningGroupGuid: course.Block == 'C' ? courseOption.Group_id : null
            })).subscribe(data => {
                if (data.result_name == "OK") {
                    course.error = null;

                    courseOption.Application_ID = data.applicationid;
                    courseOption.Used_places += 1;
                    courseOption.CanWithdraw = true;
                    courseOption.Application_statuss_code = 866560002;

                    this.alreadyAddedCourses[courseOption.Nr] = courseOption;

                    this.setPointsInfoHtml();
                    this.setRegisterInfoHtml();
                } else {
                    course.error = data.Message;
                }
            }, err => {
                const message = this.app.getHttpResponseError(err);
                this.app.alert.warning(message, this.app.translate('bc_serviceerror'));
            });
        }
    }

    canRemoveProgram(program: StudentCoursesItem): boolean {
        //return program.Application_statuss_code == 866560002 && program.CanWithdraw;
        return program.groupedInfo.some(t => t.Application_statuss_code == 866560002 && t.CanWithdraw);
    }

    removeProgram(nr: number) {
        let course = this.alreadyAddedCourses[nr];

        this.app.addLoading(this.service.deleteProgram(course.Application_ID)).subscribe(data => {
            if (data.result_name == "OK") {
                this.courses.find(t => t.Nr === course.Nr).error = null;
                this.setPointsInfoHtml();
                this.setRegisterInfoHtml();
                let curentCourse = this.courses.find(t => t.Nr === course.Nr).groupedInfo
                    .find(i => i.Group_id == course.Group_id);

                curentCourse.Application_ID = null
                curentCourse.Used_places -= 1;

                this.alreadyAddedCourses[course.Nr] = undefined;
            }
            else {
                this.courses.find(t => t.Nr === course.Nr).error = data.Message;
            }
        });
    }
}
