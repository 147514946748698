import { Component, OnInit, Input } from '@angular/core';

import { UserInfo } from '../../models/UserInfo';
import { ImpersonationFoundStudent } from '../../models/Impersonation';
import { AppService } from '../../services/app.service';
import { ImpersonationService } from '../../services/impersonation.service';

@Component({
    selector: 'app-impersonation',
    templateUrl: './impersonation.component.html',
    styleUrls: ['./impersonation.component.css']
})
export class ImpersonationComponent implements OnInit {
    constructor(
        public app: AppService,
        private impersonation: ImpersonationService
    ) { }

    @Input() showBreadcrumb: boolean = true;
    @Input() showImpersonationInfo: boolean = true;

    impersonationError: string = '';
    currentUser = new UserInfo();

    ngOnInit() {
        this.setUser(this.app.currentUser);
    }

    setUser(user: UserInfo) {
        this.currentUser = user;
    }

    set(student: ImpersonationFoundStudent) {
        this.app.addLoading(this.impersonation.start(student)).subscribe(data => {
            this.setUser(this.app.currentUser);
            location.reload();
        });
    }

    remove() {
        this.app.addLoading(this.impersonation.end()).subscribe(data => {
            this.setUser(this.app.currentUser);
            location.reload();
        });
    }

    getId(email: string) {
        if (email && email.indexOf('@') > -1) {
            return email.substring(0, email.indexOf('@'));
        } else {
            return email;
        }
    }
}
