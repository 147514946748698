<form #filterForm="ngForm">
    <div class="row">
        <div class="col-md-6 form-field">
            <input class="form-control"
                   [placeholder]="'doctorDashboard_rotations_search' | translate"
                   [(ngModel)]="searchTerm"
                   name="searchTerm"
                   (input)="filter()">
        </div>
    </div>
</form>

<bryntum-scheduler-pro [columns]="schedulerConfig.columns"
                       [features]="schedulerConfig.features"
                       [startDate]="schedulerConfig.startDate"
                       [endDate]="schedulerConfig.endDate"
                       [autoHeight]="schedulerConfig.autoHeight"
                       [weekStartDay]="schedulerConfig.weekStartDay"
                       [readOnly]="schedulerConfig.readOnly"
                       [resources]="schedulerModel.resources"
                       [events]="schedulerModel.events"
                       [assignments]="schedulerModel.assignments"></bryntum-scheduler-pro>
