<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: backUrl, text: 'BC_courses_title' },
                     { url: currentUrl, text: 'BC_courses_addTitle' }
                     ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'BC_courses_addTitle' | translate}}</h1>
    </header>

    <app-alert level="info" *ngIf="pointsInfoHtml">
        <div [innerHTML]="pointsInfoHtml | safeHtml"></div>
    </app-alert>

    <app-alert level="info" *ngIf="registerInfoHtml">
        <div [innerHTML]="registerInfoHtml | safeHtml"></div>
    </app-alert>

    <section class="block">
        <form>
            <div class="row">
                <div class="form-field col-md-6">
                    <input class="form-control" autofocus [placeholder]="'BC_courses_FilterAll' | translate"
                           [(ngModel)]="currentFilter.All" name="currentFilter.All">
                </div>

                <div class="form-field col-md-3">
                    <app-select [placeholder]="'BC_courses_FilterLang' | translate"
                                [options]="FilterLang"
                                [(ngModel)]="currentFilter.Lang" name="currentFilter.Lang">
                    </app-select>
                </div>

                <div class="form-field col-md-3">
                    <app-select [placeholder]="'BC_courses_FilterBlock' | translate"
                                [options]="FilterBlock"
                                [(ngModel)]="currentFilter.Block" name="currentFilter.Block">
                    </app-select>
                </div>
            </div>

            <ng-container *ngIf="extendedFilter">
                <div class="row">
                    <div class="form-field col-md-6">
                        <input class="form-control" [placeholder]="'BC_courses_FilterAddress' | translate"
                               [(ngModel)]="currentFilter.Address" name="currentFilter.Address">
                    </div>

                    <div class="form-field col-md-6">
                        <input class="form-control" [placeholder]="'BC_courses_FilterCourseManager' | translate"
                               [(ngModel)]="currentFilter.CourseManager" name="currentFilter.CourseManager">
                    </div>
                </div>

                <div class="row">
                    <div class="form-field col-md-6">
                        <app-select [placeholder]="'BC_courses_FilterExamination' | translate"
                                    [options]="FilterExamination"
                                    [(ngModel)]="currentFilter.Examination" name="currentFilter.Examination">
                        </app-select>
                    </div>
                </div>
            </ng-container>

            <div class="form-field">
                <label class="form-label">{{'BC_courses_filter_lblForCheckboxes' | translate}}</label>

                <div>
                    <app-checkbox [(ngModel)]="currentFilter.onlyAdded" name="onlyAdded">
                        {{'BC_courses_lblOnlyAdded' | translate}}
                    </app-checkbox>
                </div>

                <div class="mt-1">
                    <app-checkbox [(ngModel)]="currentFilter.onlyAllowed" name="onlyAllowed">
                        {{'BC_courses_lblonlyAllowed' | translate}}
                    </app-checkbox>
                </div>
            </div>

            <div class="mb-3">
                <button class="btn btn-outline" type="button" (click)="extendedFilter = !extendedFilter">
                    <i [class]="extendedFilter ? 'fi fi-rs-angle-up' : 'fi fi-rs-angle-down'"></i>
                    {{'BC_courses_extended_filter' | translate}}
                </button>

                <button class="btn btn-outline" type="button" (click)="ClearFilter()">
                    <i class="fi fi-rs-cross"></i>
                    {{'BC_courses_clear_filter' | translate}}
                </button>
            </div>
        </form>
    </section>

    <ng-container *ngIf="isLoaded">
        <app-alert level="warning" *ngIf="!courses.length">
            {{'BC_courses_noProgramCanBeAdded' | translate}}
        </app-alert>

        <app-alert level="warning" *ngIf="courses.length && !filteredCourses.length">
            {{'BC_courses_noProgramFound' | translate}}
        </app-alert>

        <section class="block-em" *ngFor="let c of filteredCourses; let i = index;">
            <h5>
                <strong>{{c.Block}}</strong>
                <small>{{'BC_courses_part_lbl' | translate}}</small>
                <a href="{{c.M3}}" target="_blank">
                    {{c.Subject_code}}, {{c.Subject_name}}
                    <i class="fi fi-rs-angle-double-small-right"></i>
                </a>
            </h5>

            <app-alert level="warning" *ngIf="!c.IsAvailable && !alreadyAddedCourses[c.Nr] && c.IsAvailableComment">{{c.IsAvailableComment}}</app-alert>
            <app-alert level="warning" *ngIf="pointTooMuch(c.Nr) && !alreadyAddedCourses[c.Nr]">{{'BC_courses_PointsTooMuch' | translate}}</app-alert>
            <app-alert level="info" *ngIf="!canRemoveProgram(c) && alreadyAddedCourses[c.Nr] && c.CanWithdrawComment">{{c.CanWithdrawComment}}</app-alert>
            <app-alert level="danger" *ngIf="c.error">{{c.error}}</app-alert>

            <div class="row">
                <div class="col-sm data-value">
                    <label>{{'BC_courses_language_lbl' | translate}}</label>
                    <div>
                        {{c.Subject_semester_lang}}
                        
                    </div>
                </div>

                <div class="col-sm data-value">
                    <label>{{'BC_courses_credits_lbl' | translate}}</label>
                    <div>
                        {{c.Credit_points}}
                        <span *ngIf="c.Semester_nr" class="badge text-bg-primary">{{'BC_courses_longCourseLbl' | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm data-value">
                    <label>{{'BC_courses_Examination_typeLbl' | translate}}</label>
                    <div>{{c.Examination_type ? c.Examination_type : ' - '}}</div>
                </div>

                <div class="col-sm data-value">
                    <label>{{'BC_courses_Course_managerLbl' | translate}}</label>
                    <div>{{c.Course_manager ? c.Course_manager : ' - '}}</div>
                </div>
            </div>

            <div class="data-value">
                <label>{{'BC_courses_implemented_lbl' | translate}}</label>
                <div>{{c.Department}}</div>
            </div>

            <hr class="block-sep mb-3" />

            <div class="form-field" *ngIf="c.groupedInfo && c.groupedInfo.length === 1">
                <label class="form-label">{{'BC_courses_infoAll_lbl' | translate}}</label>
                <div>
                    <div class="info-row mb-1">
                        <div class="info-row-check">
                            <app-checkbox [ngModel]="true" [disabled]="true"></app-checkbox>
                        </div>

                        <div class="info-row-data">
                            <span class="info-row-item">
                                <i class="fi fi-rs-marker"></i>
                                <span>
                                    {{c.Travel_dest_name}}
                                </span>
                            </span>

                            <span class="info-row-item">
                                <i class="fi fi-rs-clock"></i>

                                <span *ngIf="c.detailed_planning">
                                    <a href="#" (click)="$event.preventDefault(); openEvents(c, c.groupedInfo[0], 'calendar')">
                                        {{getCourseDateTimeString(c, c.groupedInfo[0])}}
                                    </a>
                                </span>

                                <span *ngIf="!c.detailed_planning && c.Group_day && c.Time_from && c.Time_to">
                                    {{c.Group_day}}, {{c.Time_from}} - {{c.Time_to}}
                                </span>

                                <span *ngIf="!c.detailed_planning && (!c.Group_day || !c.Time_from || !c.Time_to)">
                                    {{'BC_courses_time_info' | translate}}
                                </span>
                            </span>

                            <span class="info-row-item">
                                <i class="fi fi-rs-user"></i>
                                <span>
                                    {{c.Used_places}} {{'BC_courses_OutOf' | translate}} {{c.Max_students}}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-field" *ngIf="c.groupedInfo && c.groupedInfo.length > 1">
                <label class="form-label">{{'BC_courses_infoAll_lbl' | translate}}</label>
                <div>
                    <div class="info-row mb-1" *ngFor="let info of c.groupedInfo">
                        <div class="info-row-check">
                            <app-checkbox [ngModel]="isCourseEnrolled(c, info)"
                                          [disabled]="(!isAdmin && pointTooMuch(c.Nr)) || alreadyAddedCourses[c.Nr]"
                                          (stateChange)="onEnrollCourse($event, c, info)"
                                          name="course-{{c.Nr}}"></app-checkbox>
                        </div>

                        <div class="info-row-data">
                            <span class="info-row-item">
                                <i class="fi fi-rs-marker"></i>
                                <span>
                                    <a *ngIf="c.detailed_planning && c.Info_text" href="#" (click)="$event.preventDefault(); openEvents(c, info,'plan')">
                                        {{'BC_courses_showDesc' | translate}}
                                    </a>
                                    {{info.Travel_dest_name}}
                                </span>
                            </span>

                            <span class="info-row-item">
                                <i class="fi fi-rs-clock"></i>
                                <a href="#" (click)="$event.preventDefault(); openEvents(c, info,'calendar')">
                                    {{getCourseDateTimeString(c, info)}}
                                </a>
                            </span>

                            <span class="info-row-item">
                                <i class="fi fi-rs-user"></i>
                                <span>
                                    {{info.Used_places}} {{'BC_courses_OutOf' | translate}} {{info.Max_students}}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-actions">
                <button *ngIf="!alreadyAddedCourses[c.Nr]" type="button"
                        class="btn btn-outline-primary" (click)="enroll(c.Nr)" [disabled]="!isAdmin && (!canEnrollProgram(c) || pointTooMuch(c.Nr))">
                    {{'BC_courses_enroll' | translate}}
                </button>

                <button *ngIf="alreadyAddedCourses[c.Nr]" type="button"
                        class="btn btn-secondary" (click)="removeProgram(c.Nr)" [disabled]="!isAdmin && !canRemoveProgram(c)">
                    {{'BC_course_remove' | translate}}
                </button>
            </div>
        </section>
    </ng-container>

    <div class="form-actions">
        <button type="button" class="btn btn-primary" [routerLink]="backUrl | localize">
            {{'BC_courses_finish' | translate}}
        </button>
    </div>
</div>
