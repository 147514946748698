import { OnInit, ViewChild, Input, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { Classifier } from '../../models/Classifier';
import { Application, ApplicationKind, ApplicationType, IApplicationCustomFileField, Student } from '../../models/Application';
import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ClassifierService } from '../../services/classifier.service';
import { Utils } from '../../core/Utils';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';
import { MessageService } from '../../services/message.service';
import { IFieldFile } from '../../models/IFieldFile';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

export const addresseeTypeOther = 'AppAddresseeTypeOther';
export const addresseeTypeNone = 'AppAddresseeTypeNone';
export const typeOfReceiptMail = 'AppTypeOfReceiptMail';
export const typeOfReceiptEmail = 'AppTypeOfReceiptEmail';
export const typeOfReceiptPersonally = 'AppTypeOfReceiptPersonally';
export const refLanguageLv = 'AppRefLangLv';
export const refLanguageEn = 'AppRefLangEn';

export interface IFileMultipleField {
    title: string;
    config: IApplicationCustomFileField;
    files?: IFieldFile[];
}

@Directive()
export abstract class ApplicationComponentBase implements OnInit, ICanDeactivateGuard {
    constructor(
        public app: AppService,
        protected service: ApplicationService,
        protected classifiers: ClassifierService,
        protected messages: MessageService,
        protected route: ActivatedRoute,
        protected router: Router
    ) { }

    abstract get kind(): ApplicationKind;

    @Input() set type(value: ApplicationType | 'string') {
        this.item.Type = value;
    }

    @Input() studentEmail: string;
    @Input() studentData: Student[] = [];

    @Input() set programme(value: Student) {
        this.selectedStudent = value;

        if (value) {
            this.onSetProgramme(value);
        }
    }

    @Output('title') titleChangeEvent = new EventEmitter<string>();
    @Output('description') descriptionChangeEvent = new EventEmitter<string>();
    @Output('done') doneEvent = new EventEmitter();

    title: string;
    textBefore: string;
    textAfter: string;

    item: Application = new Application();
    files: File[] = [];
    customFiles: IFieldFile[];
    fileFieldRows: IFileMultipleField[] = [];

    showFileRequired: boolean;

    agreed: boolean;
    langEn: boolean;

    isLoaded: boolean;
    isError: boolean;
    isSuccess: boolean;

    get student(): Student {
        return this.selectedStudent || new Student();
    }

    readonly classifierDisplayValueFn = (item: Classifier) => {
        return item ? this.langEn ? item.ValueEN : item.Value : '';
    }

    protected selectedStudent: Student;

    @ViewChild('form', { static: false }) protected form: NgForm;

    ngOnInit() {
        this.langEn = this.app.currentLanguage === 'en';
        
        this.app.addLoading(this.classifiers.getByCode(`ApplicationType${this.item.Type}`)).subscribe(data => {
            this.title = data && (this.langEn ? data.ValueEN : data.Value);
            this.titleChangeEvent.emit(this.title);
        });

        this.app.addLoading(this.messages.getByCode(`Application${this.item.Type}Before`)).subscribe(data => {
            this.textBefore = data && (this.langEn ? data.TextEN : data.TextLV);
            this.descriptionChangeEvent.emit(this.textBefore);
        });

        this.app.addLoading(this.messages.getByCode(`Application${this.item.Type}After`)).subscribe(data => {
            this.textAfter = data && (this.langEn ? data.TextEN : data.TextLV);
        });

        if (!this.studentData || !this.studentData.length) {
            this.isLoaded = true;
            this.isError = true;
        } else {
            this.app.addLoading(this.onSetStudentData(this.studentData)).subscribe(data => {
                if (!data || !data.length) {
                    this.isError = true;
                }

                this.isLoaded = true;
            }, err => {
                this.isLoaded = true;
                this.isError = true;
            });
        }
    }

    canDeactivate(): Observable<boolean> | boolean {
        return true;
    }

    /**
     * Actions to take before submitting an application.
     */
    protected onSubmit(): boolean | Observable<boolean> {
        return of(true);
    }

    /**
     * Actions to take before setting the actual student data.
     * @param data
     */
    protected onSetStudentData(data: Student[]): Observable<Student[]> {
        return of(data);
    }

    /**
     * Actions to take after selecting student programme.
     * @param data
     */
    protected onSetProgramme(data: Student) { }

    protected getFormErrors(): { label?: string, description?: string, error: string }[] {
        const errors: { label?: string, description?: string, error: string }[] = [];

        const valueError = this.app.translate('application_fieldValueError');

        if (this.selectedStudent.IsValid === false) {
            errors.push({ error: this.app.translate('application_studentDataInvalid') });
        }

        const required: string[] = [];
        const other: { label?: string, description?: string, error: string }[] = [];

        if (!this.form.valid) {
            const controls = this.form.controls;

            for (const name in controls) {
                const control = controls[name];

                if (control.errors) {
                    const input = document.querySelector(`[name="${name}"`);

                    if (input) {
                        const field = input.closest('.form-field')

                        if (field) {
                            const label = field.querySelector('.form-label')?.textContent
                                || this.app.translate(`application_lbl${name[0].toUpperCase()}${name.substr(1)}`);

                            if (control.errors.required) {
                                required.push(label);
                            } else {
                                const error = field.querySelector('.invalid-feedback');

                                if (error) {
                                    other.push({ label, error: error.textContent });
                                } else {
                                    other.push({ label, error: valueError });
                                }
                            }
                        }
                    }
                }
            }
        }

        for (let i = 0; i < this.fileFieldRows.length; i++) {
            const field = this.fileFieldRows[i];
            const hasFileAdded = field.files.some(f => !!f.file);

            if (field.config.Required && !hasFileAdded) {
                this.showFileRequired = true;
                required.push(this.getFileFieldName(field))
            }
        }

        if (required.length) {
            errors.push({
                description: this.app.translate('application_requiredFieldsEmpty'),
                error: `<ul><li>${required.join('</li><li>')}</li></ul>`
            });
        }

        if (other.length) {
            errors.push(...other);
        }

        if (!errors.length && !this.form.valid) {
            // failed to collect field errors - show generic warning at least
            errors.push({ error: this.app.translate('application_formHasErrors') });
        }

        return errors;
    }

    submit() {
        this.item.StudentId = this.selectedStudent.StudentId;
        this.item.StudyProgramme = this.selectedStudent.StudyProgramme;
        this.item.StudyProgrammeId = this.selectedStudent.StudyProgrammeId;

        const errors = this.getFormErrors();

        if (!this.agreed) {
            errors.push({ error: this.app.translate('application_mustAgree') });
        }

        if (errors.length) {
            this.app.alert.error(errors.map(t => {
                const lbl = t.label ? `<div class="text-top">${t.label}</div>` : '';
                const desc = t.description ? `<div>${t.description}</div>` : '';

                return `<div class="mb-2">${lbl}${desc}${t.error}</div>`;
            }).join(''), this.app.translate('application_notSubmittedTitle'));
        } else {
            const result = this.onSubmit();
            const done: Observable<boolean> = result instanceof Observable ? result : of(result);

            done.subscribe(result => {
                if (!result)
                    return;

                const files = this.customFiles ? this.customFiles.filter(t => t.file?.size).map(t => {
                    return {
                        data: t.file,
                        name: `${t.field}/////${t.file.name}`
                    }
                }) : this.files.map(t => {
                    return {
                        data: t
                    };
                });

                this.app.addLoading(this.service.create(this.item, files, this.studentEmail)).subscribe(result => {
                    if (result.DvsResult.Success) {
                        this.isSuccess = true;
                        this.doneEvent.emit();
                    } else {
                        let errDetails = (result.DvsResult.Error || 'unknownError').split(':');
                        this.app.showError(this.app.translate(errDetails.length > 1
                            ? Utils.formatString(errDetails[0], [errDetails[1]])
                            : errDetails[0]));
                    }
                });
            });
        }
    }

    cancel() {
        this.app.navigateByUrl('/');
    }

    getClassifierDisplayValue(item: Classifier): string {
        return this.langEn ? item.ValueEN : item.Value;
    }

    getFileFieldName(obj: IFileMultipleField) {
        const isEn = this.app.currentLanguage === 'en';
        return isEn ? obj.config.NameEN : obj.config.NameLV;
    }

    getFileExtensions(obj: IFileMultipleField) {
        if (!obj.config.Extensions || obj.config.Extensions == "") {
            return null;
        } else {
            return "." + obj.config.Extensions.split(',').join(',.');
        }

    }
}
