<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{skill.Name}}</h4>
</div>

<div class="modal-body">
    <div class="data-value">
        <label>{{'skills_lblCode' | translate}}</label>
        <div>{{skill.Code || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblName' | translate}}</label>
        <div>{{skill.Name || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblDescription' | translate}}</label>
        <div>{{skill.Description || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblCategoryName' | translate}}</label>
        <div>{{skill.CategoryName || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblKeywords' | translate}}</label>
        <div>{{skill.Keywords || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblLevel' | translate}}</label>
        <div>{{skill.Level || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblCriteria' | translate}}</label>
        <div>
            <ng-container *ngIf="skill.Criteria.length === 1">{{skill.Criteria[0]}}</ng-container>
            <ol *ngIf="skill.Criteria.length > 1">
                <li *ngFor="let c of skill.Criteria">{{c}}</li>
            </ol>
        </div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblResources' | translate}}</label>
        <div>{{skill.Resources || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblFrom' | translate}}</label>
        <div>{{skill.From || '-'}}</div>
    </div>

    <div class="data-value">
        <label>{{'skills_lblTo' | translate}}</label>
        <div>{{skill.To || '-'}}</div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">{{'dialog_close' | translate}}</button>
</div>
