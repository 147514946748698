import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { EmailEntry, EmailAliasConfig, EmailAliasStatus } from '../../models/EmailAlias';
import { AppService } from '../../services/app.service';
import { EmailAliasService } from '../../services/email-alias.service';
import { PersonService } from '../../services/person.service';
import { Utils } from '../../core/Utils';
import { IPersonSearchResultItem } from '../../models/Person';
import { ITableColumn } from '../../shared/table/table.component';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-email-alias',
    templateUrl: './email-alias.component.html',
    styleUrls: ['./email-alias.component.scss']
})
export class EmailAliasComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: EmailAliasService,
        private route: ActivatedRoute,
        private studentService: PersonService
    ) { }

    statusEnum = EmailAliasStatus;
    nextChangeDate: Date;
    email: string;
    isAvailable: boolean;
    isValid: boolean;
    isPrimary: boolean;
    emails: EmailEntry[] = [];
    config: EmailAliasConfig = new EmailAliasConfig();
    rules: string;
    isLoaded: boolean;
    canChange: boolean;
    changeCountReached: boolean;
    isError: boolean;
    canPickStudent: boolean;
    showStudentPicker: boolean;
    student: IPersonSearchResultItem;
    isChecking: boolean;

    rights = {
        view: false,
        add: false,
        setPrimary: false
    };

    readonly columns: ITableColumn[] = [
        { label: 'emailAlias_lblRowNumber', cssClass: 'hidden-sm-down' },
        { label: 'emailAlias_lblEmail' },
        { label: 'emailAlias_lblApplicationDate', cssClass: 'hidden-xs-down' },
        { cssClass: 'hidden-lg-down' }
    ];

    private validEmailCount: number = 0;

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.rights.view = user.rights.indexOf('EMAIL_ALIAS.VIEW') > -1;
            this.rights.add = user.rights.indexOf('EMAIL_ALIAS.ADD') > -1;
            this.rights.setPrimary = user.rights.indexOf('EMAIL_ALIAS.SET_PRIMARY') > -1;

            this.canPickStudent = user.rights.indexOf('EMAIL_ALIAS.SET_STUDENT') > -1;
        }

        if (this.canPickStudent) {
            const queryUpn = this.route.snapshot.queryParams['id'];

            if (queryUpn) {
                this.app.addLoading(this.studentService.findStudents('student_id', queryUpn)).subscribe(data => {
                    if (data.length) {
                        this.setStudent(data[0]);
                    } else {
                        this.app.showError(this.app.translate('studentNotFound'));
                    }
                });
            } else {
                this.showStudentPicker = true;
            }
        } else if (this.rights.view) {
            this.init();
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.showStudentPicker = false;
        this.student = student;
        this.init();
    }

    validateEmail() {
        this.app.addLoading(this.service.validate(this.email, this.getUserEmail())).subscribe(errors => {
            this.isValid = errors.length === 0;
        });
    }

    checkAvailability() {
        this.isChecking = true;
        this.app.addLoading(this.service.validate(this.email, this.getUserEmail()))
            .pipe(finalize(() => this.isChecking = false))
            .subscribe(errors => {
                if (!errors.length) {
                    this.app.addLoading(this.service.isAvailabile(this.email)).subscribe(result => {
                        this.isAvailable = result;
                    });
                } else {
                    this.isValid = false;
                    this.showErrors(errors);
                }
            });
    }

    addEmail() {
        let body = '';

        const email = `${this.email}@${this.config.EmailDomain}`;

        const isLast = this.validEmailCount + 1 === this.config.MaxEmailChangeCount;
        const isLastTpl = this.app.translate(isLast ? 'emailAlias_confirmationTextLast' : 'emailAlias_confirmationText');

        const isPrimaryTpl = this.app.translate(this.isPrimary ? 'emailAlias_confirmationTextIsPrimary' : 'emailAlias_confirmationTextNotPrimary');

        body += `<div>${isLastTpl.replace('{0}', this.config.MinDaysBetweenChanges.toString())}</div>`;
        body += `<div>${isPrimaryTpl.replace('{0}', email)}</div>`;

        this.app.confirm({
            title: this.app.translate('emailAlias_confirmEmailTitle'),
            okText: this.app.translate('emailAlias_btnConfirm'),
            cancelText: this.app.translate('emailAlias_btnCancel'),
            text: body
        }, result => {
            if (!result) return;

            this.app.addLoading(this.service.add(this.email, this.isPrimary, this.getUserEmail())).subscribe(result => {
                this.init();
            }, err => {
                const errors = err.json && err.json();

                if (errors instanceof Array)
                    this.showErrors(errors);
                else
                    this.app.showError(err);
            });
        });
    }

    setPrimary(email: EmailEntry) {
        this.app.addLoading(this.service.setPrimary(email.Email, this.getUserEmail())).subscribe(result => {
            this.emails.forEach(t => t.IsPrimary = false);
            email.IsPrimary = true;
        });
    }

    onEmailChange(event) {
        this.isAvailable = undefined;
        this.isValid = undefined;
        this.email = event;
    }

    sanitizeEmail() {
        this.email = (this.email || '').replace(/\s/g, '');
    }

    private init() {
        this.app.addLoading(forkJoin(
            this.service.get(this.getUserEmail()),
            this.service.getConfig(),
            this.service.getRules())
        ).subscribe(data => {
            [this.emails, this.config, this.rules] = data;

            let errorEmailCount = 0;
            let hasPrimary = this.emails.some(t => t.IsPrimary);
            let latestDate: Date;
            let user = this.app.currentUser;

            // initial user email is not stored in the database
            this.emails.unshift({
                ApplicationDate: undefined,
                Email: (this.student && this.student.Email) || (user && user.email),
                IsPrimary: !hasPrimary,
                Status: undefined
            });

            for (let i = 0; i < this.emails.length; i++) {
                let email = this.emails[i];

                if (email.Status === EmailAliasStatus.Error) {
                    errorEmailCount++;
                } else {
                    if (email.ApplicationDate) {
                        let appDate = new Date(email.ApplicationDate);

                        if (!latestDate || appDate > latestDate)
                            latestDate = appDate;
                    }
                }
            }

            this.validEmailCount = this.emails.length - errorEmailCount - 1;
            this.changeCountReached = this.validEmailCount >= this.config.MaxEmailChangeCount;

            if (latestDate && !this.changeCountReached) {
                let minDate = new Date();
                minDate.setDate(minDate.getDate() - this.config.MinDaysBetweenChanges);

                if (latestDate > minDate) {
                    this.nextChangeDate = new Date(latestDate);
                    this.nextChangeDate.setDate(this.nextChangeDate.getDate() + this.config.MinDaysBetweenChanges);
                }
            }

            // exclude an original email
            this.canChange = !this.changeCountReached && !this.nextChangeDate;

            this.isLoaded = true;
        }, err => {
            this.isLoaded = true;
            this.isError = true;
        });
    }

    private showErrors(errors: string[]) {
        let translatedErrors = [];

        errors.forEach(e => {
            let [text, params] = e.split(':');
            translatedErrors.push(Utils.formatString(this.app.translate('emailAlias_err' + text), JSON.parse(params || '[]')));
        });

        this.app.showError(translatedErrors.join('<br>'));
    }

    private getUserEmail(): string {
        return this.student ? this.student.Email : undefined;
    }
}
