<div class="wrapper-lg">
    <app-breadcrumbs [items]="[
                 { url: '/classifiers', text: 'classifiers_title' },
                 { url: '/classifiers/' + type, text: title }
                 ]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{title}}</h1>
        <aside>
            <button class="btn btn-outline-primary" type="button" (click)="add()">
                <i class="fi fi-rs-plus"></i>
                {{'add' | translate}}
            </button>
        </aside>
    </header>

    <app-table [rows]="items" [columns]="columns" [sticky]="'right'">
        <ng-template #rowTemplate let-row>
            <tr>
                <td>{{row.Code}}</td>
                <td>{{row.LegacyCode}}</td>
                <td>{{row.ValueLV}}</td>
                <td>{{row.ValueEN}}</td>
                <td>{{row.Payload}}</td>
                <td>
                    <div class="table-actions">
                        <button (click)="edit(row)" type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'edit' | translate">
                            <i class="fi fi-rs-pencil"></i>
                        </button>

                        <button (click)="delete(row)" type="button" class="btn btn-outline btn-icon" [ngbTooltip]="'delete' | translate">
                            <i class="fi fi-rs-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </app-table>
</div>
