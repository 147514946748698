import { Component, OnInit, Input } from '@angular/core';
import { PrintBalanceGetResult } from '../../../models/StudentBalance';
import { AppService } from '../../../services/app.service';
import { MessageService } from '../../../services/message.service';
import { ParameterService } from '../../../services/parameter.service';

@Component({
	selector: 'app-print-action-v2',
	templateUrl: './print-action-v2.component.html'
})
export class PrintActionV2Component implements OnInit {
    private _printBalance: PrintBalanceGetResult;

    @Input() set printBalance(printBalance: PrintBalanceGetResult) {
        this._printBalance = printBalance;
        if (printBalance) {
			this.printAccountMissing = printBalance.Status !== 1;
            if (!this.printAccountMissing) {
                this.app.addLoading(this.paramService.getValues()).subscribe(params => {
					let printPortalURL = params.find(p => p.Code === 'PrintPortalURL');
					if (printPortalURL && printPortalURL.Value) {
						this.printPortalURL = printPortalURL.Value;
					}
                });
            }
        }
    }

    get printBalance(): PrintBalanceGetResult {
        return this._printBalance;
    }

    printAccountMissing: boolean = false;
	infoMsg: string = null;
	printPortalURL: string;

    constructor(
		public app: AppService,
        private paramService: ParameterService,
		private messagesService: MessageService
    ) { }

    ngOnInit() {
		this.getMsg('PRINT_SEND_FILE_INFO_V2');
	}

	getMsg(code: string) {
		this.messagesService.getByCode(code).subscribe(msg => {
			if (msg) {
                this.infoMsg = this.app.currentLanguage === 'lv' ? msg.TextLV : msg.TextEN;
			}
			else {
				this.infoMsg = null;
			}
		});
	}

	showInfoMsg() {
		setTimeout(() => {
			this.app.alert.success(this.app.translate('print_FileRedirect'));
		}, 1000);
	}
}
