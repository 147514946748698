import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecaptchaLoaderService, RecaptchaComponent } from 'ng-recaptcha';

import { PasswordService } from '../../../services/password.service';
import { AppService } from '../../../services/app.service';


import { UserCrypto } from '../../../models/UserInfo';
import { ResetResponse } from '../../../models/Password';
import { AES, mode, pad, enc } from 'crypto-ts';
import { MessageService } from '../../../services/message.service';
import { Utils } from '../../../core/Utils';

@Component({
    selector: 'app-password-student-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['../password.component.scss']
})
export class StudentPasswordResetComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: PasswordService,
        private route: ActivatedRoute,
        private messagesService: MessageService
    ) { }

    @ViewChild('recaptchaRef', { static: false }) recaptchaRef: RecaptchaComponent;

    reCaptchaService: RecaptchaLoaderService;
    reCaptchaResponse: string;
    reCaptchaSiteKey: string;

    cryptoKey: string = '';
    userCryptoKey: string;
    crypto: UserCrypto;
    linkText: string;
    newPasswordValue: string;
    newPasswordRepeatValue: string;
    userNameValue: string;
    securityCodeValue: string;
    resetResponse: ResetResponse;
    isRsuEmail: boolean;

    get isValidPassword() {
        return Utils.validatePassword(this.newPasswordValue, {
            minLength: this.app.env.minPasswordLength,
            maxLength: this.app.env.maxPasswordLength,
            digit: true,
            lowercase: true,
            special: true,
            uppercase: true
        });
    }

    ngOnInit() {
        this.linkText = '<a href="' + this.app.env.adfsApiRoute + '">' + this.app.env.adfsApiRoute + '</a>';
        this.resetResponse = null;

        this.app.addLoading(this.messagesService.getForPasswordLink(this.app.currentLanguage)).subscribe(msg => {
            if (msg) {
                this.linkText = msg.Text;
            }
        });

        this.reCaptchaSiteKey = this.app.env.reCaptchaSiteKey;

        this.route.queryParams.subscribe(query => {
            this.securityCodeValue = query['t'] || query['token'];
            this.userCryptoKey = new Date().getTime() + '_' + Math.floor(Math.random() * 100);
            this.app.addLoading(this.service.getCrypto(this.userCryptoKey)).subscribe(responseObj => {
                this.crypto = responseObj;
            });
        });
    }

    resolved(captchaResponse: string) {
        this.reCaptchaResponse = captchaResponse;
    }

    showError(message: string | any) {
        this.app.showError(typeof message == 'string' ? this.app.translate(message) : message, this.app.translate('password_notresettitle'));
        this.reCaptchaResponse = '';
        this.recaptchaRef.reset();
    }

    submit() {
        const username = (this.userNameValue || '');

        if (username.indexOf('@') != -1) {
            this.isRsuEmail = username.toLowerCase().indexOf('@rsu.') != -1;

            if (!this.isRsuEmail) {
                this.showError('password_externalEmailError');
                return;
            }
        }

        if (!this.isValidPassword) {
            this.showError('password_passwordmissingsymbols');
        } else if (this.containsName(this.newPasswordValue)) {
            this.showError('password_containsname');
        } else if (this.newPasswordValue != this.newPasswordRepeatValue) {
            this.showError('password_dontmatch');
        } else {
            let key = enc.Utf8.parse(this.crypto.key);
            let iv = enc.Utf8.parse(this.crypto.iv);
            let cryptedObjNew = AES.encrypt(this.newPasswordValue, key, {
                iv: iv,
                mode: mode.CBC,
                padding: pad.PKCS7
            });

            this.app.addLoading(this.service.resetPassword(
                this.userNameValue,
                enc.Hex.stringify(cryptedObjNew.ciphertext),
                this.securityCodeValue.trim(),
                this.userCryptoKey,
                this.reCaptchaResponse,
                this.app.currentLanguage
            )).subscribe((response) => {
                if (response && response.Success) {
                    this.resetResponse = response;
                } else {
                    this.showError('password_reseterror');
                }
            }, err => {
                this.showError(err);
            });
        }
    }

    containsName(value: string): boolean {
        let name = this.userNameValue;
        if (this.userNameValue.indexOf('\\') > -1)
            name = this.userNameValue.split('\\')[1];
        else if (this.userNameValue.indexOf('@') > -1)
            name = this.userNameValue.split('@')[0];
        if (value.indexOf(name) > -1) {
            return true;
        }
        else
            return false;
    }
}
