<app-table [rows]="rows" [columns]="columns">
    <ng-template #rowTemplate let-row>
        <tr>
            <td>{{row.Theme}}</td>
            <td>
                {{row.Date ? (row.Date | appDate) : ('doctorDashboard_seminars_noDate' | translate)}}
                <ng-container *ngIf="row.Time"> {{row.Time}}</ng-container>
            </td>
            <td>{{row.Place}}</td>
            <td>{{row.Speciality}}</td>
            <td>
                <div class="table-actions">
                    <button *ngIf="canViewAttendance" type="button" class="btn btn-icon btn-outline" (click)="openAttendance(row)"
                            [ngbTooltip]="'doctorDashboard_seminars_openAttendance' | translate"
                            [disabled]="!row.Date">
                        <i class="fi fi-rs-list-check"></i>
                    </button>

                    <button type="button" class="btn btn-icon btn-outline" (click)="edit(row)"
                            [ngbTooltip]="'doctorDashboard_seminars_edit' | translate">
                        <i class="fi fi-rs-pencil"></i>
                    </button>
                </div>
            </td>
        </tr>
    </ng-template>
</app-table>
