<div class="wrapper-md">
    <app-breadcrumbs [items]="[{ url: '/applications', text: 'applications_title' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1 [translate]="'applications_title'"></h1>
        <aside>
            <a [routerLink]="['/applications/my'] | localize" class="btn btn-primary btn-icon-last">
                {{'myApplications_title' | translate}}
                <i class="fi fi-rs-angle-right"></i>
            </a>
        </aside>
    </header>

    <div class="body-fix">
        <app-application-index-body [showNoItemAvailable]="true"></app-application-index-body>
    </div>
</div>
