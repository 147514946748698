<app-alert level="danger" *ngIf="error">{{error}}</app-alert>

<ng-container *ngIf="isLoaded">
    <div class="row">
        <div class="col-9 col-sm-6 form-field">
            <input class="form-control"
                   (input)="search($event)"
                   [placeholder]="'planSummary_lblSearch' | translate"
                   maxlength="50"
                   [disabled]="total < 1">
        </div>
    </div>

    <app-alert level="info" *ngIf="total == 0">{{'planSummary_seminars_empty' | translate}}</app-alert>

    <div *ngFor="let g of seminarsByYear">
        <div class="text-top mb-2">
            <span (click)="util.toggle(g)" class="seminar-year-label">
                {{g.year}}{{'planSummary_yearPostfix' | translate}}
                <i class="fi fi-rs-angle-circle-{{util.isExpanded(g) ? 'down' : 'right'}}"></i>
            </span>
        </div>

        <app-table [columns]="columns" [rows]="g.seminars" pageSize="20" sortBy="Date" *ngIf="util.isExpanded(g)">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        {{row.Theme}}
                        <div class="hidden-lg-up">
                            <strong>
                                {{row.Date ? (row.Date | appDate) : ('planSummary_seminars_noDate' | translate)}}
                                <ng-container *ngIf="row.Time"> {{row.Time}}</ng-container>
                            </strong>
                        </div>
                        <div class="text-sub hidden-md-up">{{row.Instructor}}</div>
                        <div class="text-sub hidden-md-up">
                            <a href="{{row.Location}}" target="_blank" *ngIf="row.IsRemote">{{row.Location}}</a>
                            <ng-container *ngIf="!row.IsRemote">{{row.Location}}</ng-container>
                        </div>
                    </td>
                    <td class="hidden-md-down">
                        {{row.Date ? (row.Date | appDate) : ('planSummary_seminars_noDate' | translate)}}
                        <ng-container *ngIf="row.Time"> {{row.Time}}</ng-container>
                    </td>
                    <td class="hidden-sm-down">
                        {{row.Instructor}}
                        <div class="text-sub hidden-lg-up">{{row.Location}}</div>
                    </td>
                    <td class="hidden-md-down">
                        <a href="{{row.Location}}" target="_blank" *ngIf="row.IsRemote">{{row.Location}}</a>
                        <ng-container *ngIf="!row.IsRemote">{{row.Location}}</ng-container>
                    </td>
                    <td>
                        <span class="badge"
                              *ngIf="row.attendancyStatusName"
                              [class.bg-success]="row.AttendancyStatus === true"
                              [class.bg-danger]="row.AttendancyStatus === false">
                            {{row.attendancyStatusName}}
                        </span>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </div>
</ng-container>
