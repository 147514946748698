import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toggle-button',
    template: `
    <button class="btn btn-outline" type="button" (click)="click()">
        <i *ngIf="showIcon" class="fi fi-rs-eye{{value ? '-crossed' : ''}}"></i>
        {{showIcon ? ' ' : ''}}
        <span class="hidden-sm-down">
            <ng-content></ng-content>
        </span>
    </button>
    `
})
export class ToggleButtonComponent {
    @Input() showIcon: boolean = true;
    @Input() value: boolean;

    @Output() valueChange = new EventEmitter();

    click() {
        this.value = !this.value;
        this.valueChange.emit(this.value);
    }
}
