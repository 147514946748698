export class Utils {
    static prettyFileSize(bytes: number): string {
        const div = 1024;
        const units = ['B', 'KB', 'MB', 'GB'];

        let unit = units[0];
        let size = bytes;
        let curr: number;

        while ((curr = size / div) > 1) {
            size = curr;
            units.shift();
        }

        return `${+size.toFixed(2)}${units.length ? units[0] : unit}`;
    }

    /**
     * Replace tokens in a string.
     * @param {string} input
     * @param {string[]} replacements
     * @returns {string}
     */
    static formatString(input: string, replacements: any): string {
        replacements = [].concat(replacements);
        return input.replace(/{(\d+)}/g, (match, n) => replacements[n]);
    };

    /**
     * Convert base64 string to a blob.
     * @param base64
     * @param mimeType
     * @returns {Blob}
     */
    static base64ToBlob(base64: string, mimeType: string = '') {
        let sliceSize = 1024;
        let byteChars = window.atob(base64);
        let byteArrays = [];

        for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
            var slice = byteChars.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
    }

    /**
     * Get a date instance from an input.
     * @param date
     */
    static getInputDate(date: any): Date {
        if (!date)
            return null;

        if (date instanceof Date)
            return date;

        if (date['toDate'])
            return date.toDate();

        return new Date(date);
    }

    /**
     * Get input date string in a "yyyy-mm-dd" format.
     * @param date
     */
    static getInputDateString(date: any): string {
        let t = Utils.getInputDate(date);

        if (!t) return '';

        return `${t.getFullYear()}-${('0' + (t.getMonth() + 1)).slice(-2)}-${('0' + (t.getDate())).slice(-2)}`;
    }

    /**
     * Get input date string in a "yyyy-mm-dd HH:mm" format.
     * @param date
     */
    static getInputDateTimeString(dateTime: any): string {
        let t = Utils.getInputDate(dateTime);

        if (!t) return '';

        return `${t.getFullYear()}-${('0' + (t.getMonth() + 1)).slice(-2)}-${('0' + (t.getDate())).slice(-2)}`
            + `T${('0' + t.getHours()).slice(-2)}:${('0' + t.getMinutes()).slice(-2)}:${('0' + t.getSeconds()).slice(-2)}`;
    }

    static arrayToDictionary<TItem>(array: TItem[], getKey: (item: TItem) => string): { [key: string]: TItem } {
        let dict: { [key: string]: TItem } = {};

        array.forEach(t => {
            let key = getKey(t);
            dict[key] = t;
        });

        return dict;
    }

    /**
     * Get the date object from the value. Useful when dealing with moment dates bound to material datepicker.
     * @param value
     */
    static ensureDate(value: any): Date {
        if (!value) return undefined;
        if (value instanceof Date) return value;
        if (value.toDate) return value.toDate();
        if (typeof value === 'string') return new Date(value);
    }

    static getBrowserInfo = () => {
        const ua = navigator.userAgent;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        let tem: string[] = [];

        if(/trident/i.test(M[1])){
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return {
                name:'IE',
                version:(tem[1] || '')
            };
        }
        if(M[1]=== 'Chrome'){
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem != null) {
                return {
                    name:tem[1].replace('OPR', 'Opera'),
                    version:tem[2]
                };
            }
        }

        M = M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];

        if((tem = ua.match(/version\/(\d+)/i))!= null && M[0]!== 'Chrome'){
            M.splice(1, 1, tem[1]);
        }
        return {
            ua: ua,
            name:M[0],
            version:M[1]
        };
    }

    /**
     * Escape regular expression special symbols.
     * @param pattern
     */
    static escapeRegExp(pattern: string) {
        return pattern.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    /**
     * Validate a password.
     * @param password
     * @param options
     */
    static validatePassword(password: string, options: {
        minLength: number,
        maxLength: number,
        uppercase?: boolean,
        lowercase?: boolean,
        digit?: boolean,
        special?: boolean
    } = { minLength: 8, maxLength: 16 }): boolean {
        if (!password || !options) return false;

        if (password.length < options.minLength) return false;
        if (password.length > options.maxLength) return false;

        if (options.uppercase && /[A-Z]/.test(password) == false) return false;
        if (options.lowercase && /[a-z]/.test(password) == false) return false;
        if (options.digit && /[0-9]/.test(password) == false) return false;

        const special = '!"#$%&\'()*+,-./:;<=>?@[\]^_`{|}~';
        if (options.special && password.split('').some(t => special.indexOf(t) != -1) == false) return false;

        return true;
    }

    /**
     * Sanitize object parameter values.
     * @param data
     */
    //static sanitizeObjectData<T>(data: T): T {
    //    let result = JSON.parse(JSON.stringify(data));

    //    Object.keys(result).forEach(k => {
    //        if (result[k]) {
    //            if (typeof result[k] === 'string')
    //                result[k] = encodeURIComponent(result[k]);
    //            else if (typeof result[k] === 'object')
    //                result[k] = Utils.sanitizeObjectData(result[k]);
    //        }
    //    });

    //    return result;
    //}
}
