<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/portfolio', text: 'portfolio_title' },
                     { text: 'portfolio_sharedTitle' }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>{{'portfolio_sharedTitle' | translate}}</h1>
    </header>

    <app-alert *ngIf="error" level="danger">{{error}}</app-alert>

    <ng-container *ngIf="data">
        <div class="content-desc" [innerHTML]="intro | safeHtml"></div>

        <div class="data-value">
            <label>{{'portfolio_lblPurpose' | translate}}</label>
            <div>{{data.Purpose}}</div>
        </div>

        <div class="data-value">
            <label>{{'portfolio_lblAttachments' | translate}}</label>
            <div>
                <div *ngFor="let n of data.Attachments">&mdash; {{n}}</div>
            </div>
        </div>

        <app-checkbox [(ngModel)]="isConfirmed" name="isConfirmed" [disabled]="isDownloaded">
            {{'portfolio_shareConfirmDownload' | translate}}
        </app-checkbox>

        <div class="block text-end">
            <button class="btn btn-primary" type="button" (click)="download()" [disabled]="!isConfirmed || isDownloaded">
                <i class="fi fi-rs-download"></i>
                {{'portfolio_btnDownload' | translate}}
            </button>
        </div>

        <app-alert level="success" *ngIf="isDownloaded">{{'portfolio_shareDownloaded' | translate}}</app-alert>
    </ng-container>
</div>
