import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '../../environments/environment';
import { ISeminarAttendace } from '../models/Seminar';

/**
 * Seminar service
 */
@Injectable({ providedIn: "root" })
export class SeminarService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/seminars` }

    getAttendance(id: string): Observable<ISeminarAttendace> {
        const url = `${this.apiUrl}/${id}/attendance`;
        return this.http.get<ISeminarAttendace>(url);
    }

    saveAttendance(id: string, data: {
        students: {
            id: string,
            isAttended: boolean
        }[]
    }): Observable<any> {
        const url = `${this.apiUrl}/${id}/attendance`;
        return this.http.put<void>(url, data);
    }
}
