import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ICourseEqualizationListItem } from '../../../models/CourseEqualization';
import { IPersonSearchResultItem } from '../../../models/Person';
import { AppService } from '../../../services/app.service';
import { CourseEqualizationService } from '../../../services/course-equalization.service';
import { PersonService } from '../../../services/person.service';
import { ITableColumn } from '../../../shared/table/table.component';
import { store } from '../shared';

@Component({
    selector: 'app-course-equalization-list',
    templateUrl: './course-equalization-list.component.html'
})
export class CourseEqualizationListComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: CourseEqualizationService,
        private persons: PersonService,
        private route: ActivatedRoute
    ) { }

    items: ICourseEqualizationListItem[] = [];

    canPickStudent: boolean;
    canEdit: boolean;
    studentPickerOpened: boolean;
    student: IPersonSearchResultItem;

    readonly columns: ITableColumn[] = [
        { property: 'Status', label: 'courseEqualization_lblStatus' },
        { property: 'Speciality', label: 'courseEqualization_lblSpeciality' },
        { property: 'StudyYear', label: 'courseEqualization_lblStudyYear' },
        { property: 'Created', label: 'courseEqualization_lblCreated' },
        { width: '1px' }
    ];

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canEdit = user.rights.indexOf('COURSE_EQUALIZATION.EDIT') > -1;
            this.canPickStudent = user.rights.indexOf('COURSE_EQUALIZATION.SET_STUDENT') > -1;

            if (this.canPickStudent) {
                const queryUpn = this.route.snapshot.queryParams['id'];

                if (queryUpn) {
                    this.app.addLoading(this.persons.findStudents('student_id', queryUpn)).subscribe(data => {
                        if (data.length) {
                            this.setStudent(data[0]);
                            this.loadItems();
                        } else {
                            this.app.showError(this.app.translate('studentNotFound'));
                        }
                    });
                } else {
                    this.student = store.getStudent();

                    if (this.student) {
                        this.loadItems();
                    } else {
                        this.toggleStudentPicker();
                    }
                }
            } else {
                this.loadItems();
            }
        }
    }

    toggleStudentPicker() {
        if (this.studentPickerOpened) {
            this.studentPickerOpened = false;
        } else {
            this.studentPickerOpened = true;
            this.items = [];
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.loadItems();

        store.setStudent(student);
    }

    delete(row: ICourseEqualizationListItem) {
        this.app.confirm(this.app.translate('confirmItemDelete'), result => {
            if (!result) return;

            this.app.addLoading(this.service.delete(row.Id)).subscribe(() => {
                this.loadItems();
            });
        });
    }

    canDelete(row: ICourseEqualizationListItem) {
        return (this.canEdit && row.Status == 'Draft');
    }

    private loadItems() {
        this.app.addLoading(this.service.get(this.student?.Email)).subscribe(data => {
            this.items = data;
        });
    }
}
