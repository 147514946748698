<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'onCallShift_editShiftTitle' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="block required">
            <label class="form-label">{{'onCallShift_lblDate' | translate}}</label>
            <app-date [(ngModel)]="date"
                      name="date"
                      [required]="true"
                      [marked]="selectedDates"></app-date>
        </div>

        <div class="block required">
            <label class="form-label">{{'onCallShift_lblHours' | translate}}</label>
            <input class="form-control" required [(ngModel)]="hours" name="hours" pattern="^(0|[1-9][0-9]*)([,\.][0-9][0-9]?)?$" />
        </div>

        <div class="form-field required">
            <label class="form-label">{{'onCallShift_lblWorkplace' | translate}}</label>
            <app-autocomplete [(ngModel)]="workplaceId"
                              name="workplaceId"
                              limit="100"
                              [options]="workplaceOptions"
                              [displayProperty]="'Name'"
                              [valueProperty]="'Id'"
                              [required]="true"
                              (search)="filterWorkplaces($event)">
            </app-autocomplete>
            <div class="feedback">
                {{'onCallShift_workplaceFilterAlert' | translate}}
            </div>
        </div>

        <div class="form-field">
            <label class="form-label">{{'onCallShift_lblComments' | translate}}</label>
            <textarea class="form-control" [(ngModel)]="comments" name="comments" [maxlength]="500" rows="5" #commentsInput="ngModel"></textarea>
            <div class="invalid-feedback" *ngIf="commentsInput.errors?.maxlength">{{'fieldError_lengthMinMax' | translate | formatString:[0, 500]}}</div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()">{{'dialog_cancel' | translate}}</button>

    <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="!form.valid">
        {{'dialog_ok' | translate}}
    </button>
</div>
